<template lang="pug">
.toolbar-container
  .toolbar
    v-btn.toolbar__button(icon, title='Move (M)', @click='$emit("onMoveMode")')
      v-icon open_with
    v-btn.toolbar__button(icon, title='Crop (C)', @click='$emit("onCropMode")')
      v-icon crop
    v-btn.toolbar__button(icon, title='Zoom In (I)', @click='$emit("onZoomIn")')
      v-icon zoom_in_map
    v-btn.toolbar__button(icon, title='Zoom Out (O)', @click='$emit("onZoomOut")')
      v-icon zoom_out_map
    v-btn.toolbar__button(icon, title='Rotate Left (L)', @click='$emit("onRotateLeft")')
      v-icon rotate_left
    v-btn.toolbar__button(icon, title='Rotate Right (R)', @click='$emit("onRotateRight")')
      v-icon rotate_right
    v-btn.toolbar__button(icon, title='Flip Horizontal (H)', @click='$emit("onFlipHorizontal")')
      v-icon swap_horiz
    v-btn.toolbar__button(icon, title='Flip Vertical (V)', @click='$emit("onFlipVertical")')
      v-icon swap_vert
</template>
<script>
export default {
  name: 'LdImageEditorToolbar',
};
</script>
<style lang="scss" scoped>
.toolbar-container {
  position: relative;
}

.toolbar {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  height: 2rem;
  left: 50%;
  top: -35px;
  position: absolute;
  margin-left: -8rem;
  width: 16rem;
  z-index: 2015;
}

.toolbar__button {
  margin: 0;
  border-radius: 0;
  background-color: transparent;
  border-width: 0;
  color: #fff;
  cursor: pointer;
  display: block;
  float: left;
  font-size: 0.875rem;
  height: 2rem;
  text-align: center;
  width: 2rem;

  &:focus {
    outline: none;
  }

  &:hover {
    color: #fff;
    background-color: var(--v-primary-base);
  }
}
</style>
