/*
 * @Author: Ethan Dinnen
 * @Date:   2018-12-07 10:23:53
 * @Last modified by:   kleberdasilva
 * @Last modified time: 2019-03-01T14:33:19-08:00
 */
import { HC_PROPS } from 'lion-vue';
import Vue from 'vue';
import axios from 'axios';

export async function checkUser() {
  return axios.post('session/create_user').then(user => {
      if (process.env.NODE_ENV === 'production') {
        // TODO MW - no user name or email returned
        window.FS.identify(user.id);
      }
      return user;
    });
}

const setCookie = (key, value) => {
  Vue.cookies.set(key, value, null, null, HC_PROPS.MAIN_DOMAIN);
};

export async function validate() {
  const LD_AUTH = Vue.cookies.get('LD_AUTH');
  if (!LD_AUTH) {
    const redirect = encodeURIComponent(window.location.href);
    const newLocation = `${process.env.LOGIN_URL}/signin?ref=${redirect}`;
    window.location.href = newLocation;
  }
  // Send validation check
  return axios
    .get(process.env.VALIDATE_TOKEN_URL)
    .then(() => {
      setCookie('LD_TRANSACTIONS_APP_AUTHORIZED', true);
      return true;
    })
    .catch(err => {
      const errorMsg = err.message;
      const redirect = encodeURIComponent(window.location.href);
      let newLocation;
      if (errorMsg && errorMsg.includes('ERR001')) {
        // This user has been blocked in the whitelist feature
        // Do not destroy his session
        setCookie('LD_REDIRECT_URL', redirect);
        setCookie('LD_TRANSACTIONS_APP_AUTHORIZED', false);
        newLocation = `${process.env.LOGIN_URL}/home?errorMsg=${errorMsg}`;
        window.location.href = newLocation;
      }
      if (errorMsg && errorMsg.includes('ERR002')) {
        // This user does not have a valid subscription
        // Do not destroy his session
        setCookie('LD_REDIRECT_URL', redirect);
        setCookie('LD_TRANSACTIONS_APP_AUTHORIZED', false);
        newLocation = `${process.env.LOGIN_URL}/settings?errorMsg=${errorMsg}`;
        window.location.href = newLocation;
      }
    });
}
