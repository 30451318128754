const found = (state, response) => {
  state.listings = response;
};

const totalFound = (state, total) => {
  state.total = total;
};

const foundOne = (state, response) => {
  if (response.length) {
    state.listing = response[0];
  }
};

const foundSimilar = (state, response) => {
  state.similarListings = response;
};

const isLoading = (state, bool) => {
  state.isLoading = bool;
};

const select = (state, { listing, add }) => {
  if (add) {
    state.selected = [...state.selected, listing];
    return;
  }
  const i = state.selected.findIndex(s => s._id === listing._id);
  state.selected = [
    ...state.selected.slice(0, i),
    ...state.selected.slice(i + 1, state.selected.length),
  ];
};

const selectAll = state => {
  state.selected = state.listings;
};

const deselectAll = state => {
  state.selected = [];
};

const selectSort = (state, value) => {
  state.sortSelected = value;
};

const selectOrder = (state, value) => {
  state.orderSelected = value;
};

const addShowingtime = (state, value) => {
  state.showingTime = [...state.showingTime, value];
};

const findCaller = (state, caller) => {
  state.findCaller = caller;
};

export default {
  found,
  totalFound,
  foundOne,
  isLoading,
  foundSimilar,
  select,
  selectAll,
  deselectAll,
  selectSort,
  selectOrder,
  addShowingtime,
  findCaller,
};
