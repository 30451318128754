<template>
  <router-link v-bind="$props" @click="onAction($event, 'click')" :to="gatedTo" :active-class="activeClass" :class="gatedClass">
    <slot></slot>
  </router-link>
</template>

<script>
import featureGate from 'lion-vue-src/Shared/mixins/featureGate';

export default {
  props: [ 'disabled', 'href', 'tabindex', 'to', 'activeClass' ],
  mixins: [featureGate],
};
</script>
