<template>
  <svg 
    data-icon="microsoft-flat" 
    role="img" 
    viewBox="0 0 29 29"
  >

    <title>Microsoft_icon</title>
    <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Upgrade-CN---01-Copy-15" transform="translate(-629.000000, -272.000000)" fill="currentColor"
        fill-rule="nonzero">
        <g id="Microsoft_icon" transform="translate(629.000000, 272.000000)">
          <polygon id="Path" points="0 0 14 0 14 14 0 14"></polygon>
          <polygon id="Path" points="15 0 29 0 29 14 15 14"></polygon>
          <polygon id="Path" points="0 15 14 15 14 29 0 29"></polygon>
          <polygon id="Path" points="15 15 29 15 29 29 15 29"></polygon>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      default: '$vuetify.icons.microsoft-flat',
    },
  },
};
</script>
