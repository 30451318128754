/*
 * @Author: Ethan Dinnen
 * @Date:   2018-12-04 10:53:51
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-01-21 17:15:36
 */

import axios from 'axios';

/**
 * Creates a new transaction
 * @param {object} data The new transaction object
 * @return {boolean} Returns true if success
 */
export const create = async data => {
  return await axios
    .post('transactions', data)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.stack);
    });
};

/**
 * Updates a transaction
 * @param {object} data The transaction object
 * @return {boolean} Returns true if success
 */
export const update = async data => {
  return await axios
    .put(`transactions/${data.id}`, { transaction: data })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.stack);
    });
};

/**
 * Deletes a transaction
 * @param {number} id The id of the transaction to delete
 * @return {boolean} Returns true if success
 */
export const deleteOne = async id => {
  return await axios
    .delete(`transactions/${id}`)
    .then(() => {
      return true;
    })
    .catch(err => {
      throw new Error(err.stack);
    });
};

/**
 * Retrieve a single transaction
 * @param {number} id The transaction id to retrieve
 * @return {object} The transaction
 */
export const get = async id => {
  return await axios
    .get(`transactions/${id}`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.data);
    });
};

/**
 * Retrieve all transactions
 * @param {number} rows The number of transactions to return
 * @param {number} page The page to return;
 * @param {string} sort The database column to sort by
 * @param {string} desc The direction to sort by; 'asc' | 'desc'
 * @param {string} q The query to search for in the database
 * @return {array} The array of all transactions
 */
export const getAll = async (rows, page, sort, desc, q, filter, type) => {
  const params = {
    page,
    rows,
    sort,
    desc,
    q,
    filter,
    type,
  };
  return await axios
    .get('transactions', { params })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.stack);
    });
};

/**
 * Get all transaction types for a market
 * @param {number|string} market the marketID
 * @return {array} The transaction types, formatted for select menus
 */
export const getTypes = async market => {
  return await axios
    .get(`markets/${market}/transaction_types`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.stack);
    });
};

/**
 * Updates transaction forms for a specified transaction
 * @param {number|string} id The transaction id
 * @param {object} forms The object holding the transaction_forms array
 * @return {object} The newly modified transaction
 */
export const updateForms = async (id, forms) => {
  return await axios
    .post(`transactions/${id}/transaction_forms`, forms)
    .then(res => {
      return res.data[0];
    })
    .catch(err => {
      throw new Error(err.stack);
    });
};
