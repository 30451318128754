import filter from 'lodash/filter';
import trim from 'lodash/trim';
// eslint-disable-next-line
import vmiFeathers from 'vmi-feathers-client';
import request from 'request';
import initialState from './initialState';
import { findCallers } from '../../../../helpers/constants';

// TODO MW - make this configurable with other boards later
const DEFAULT_MLS_BOARD_INDEX = { ESPropertyIndex: 'rets-properties-realcomp' };

const find = async ({ commit, rootState, dispatch }, params = {}) => {
  commit('isLoading', true);

  const { mlsNumberSelected = null, caller } = params;
  const { sortSelected } = rootState.listing;
  const {
    searchText,
    searchType,
    coordinates,
    savedSearch,
    mapBounds,
  } = rootState.search;

  const { ESPropertyIndex, boardCoordinates } = rootState.userMlsInfo ? rootState.userMlsInfo.selectedMlsBoard
    : DEFAULT_MLS_BOARD_INDEX;

  await commit('findCaller', caller);

  if (mlsNumberSelected) {
    await dispatch('search/updateSearchText', mlsNumberSelected, { root: true });
    await dispatch('search/updateSearchType', 'mls_number', { root: true });
  }

  let mapCoords;
  if (!searchText && mapBounds) {
    mapCoords = [
      [mapBounds.NE.lng, mapBounds.NE.lat],
      [mapBounds.NW.lng, mapBounds.NW.lat],
      [mapBounds.SE.lng, mapBounds.SE.lat],
      [mapBounds.SW.lng, mapBounds.SW.lat],
    ];
  }

  const defaultQuery = {
    searchText,
    searchType,
    coordinates,
    conditions: savedSearch.conditions,
    sort: sortSelected,
    mapCoords,
    ESIndex: ESPropertyIndex,
  };

  const conditionalQuery = params.query || defaultQuery;

  try {
    const listingResults = await vmiFeathers()
      .service('listings')
      .find({ query: conditionalQuery });

    if (listingResults.aggregations && caller !== findCallers.MAP_DRAG_SEARCH) {
      const {centroid: {location: {lat, lon}}} = listingResults.aggregations;
      dispatch('search/updateMapCenter', {lat, lng: lon}, { root: true });
    }

    commit('found', listingResults.hits.hits);
    commit('totalFound', listingResults.hits.total);
    commit('isLoading', false);
    return listingResults;

  } catch (error) {
    commit('isLoading', false);
    console.log('listing find() failed:', error);
  }
};


const loadMls = async ({ commit, rootState, dispatch }, { mlsNumber, isPublic, isUserLoanOfficer }) => {
  await vmiFeathers()
    .service('listings')
    .get(mlsNumber, { query: { isPublic, isUserLoanOfficer } })
    .then(async result => {
      await commit('foundOne', result);
    })
    .catch(err => {
      console.log('listing find() failed:', err);
    });
};

const findSimilar = ({ commit, rootState }, { listing, resultsSize }) => {
  commit('isLoading', true);
  const { BedroomsTotal, BathroomsTotal, MinListPrice, MaxListPrice, City } = listing._source;
  const sort = {
    sort: initialState().sortSelected,
  };
  vmiFeathers()
    .service('listings')
    .find({
      query: {
        LocationValue: { searchText: City, searchType: 'city' },
        BedroomsTotal,
        BathroomsTotal,
        MinListPrice,
        MaxListPrice,
        sort,
        resultsSize,
      },
    })
    .then(async results => {
      const filteredResults = filter(results.hits.hits, result => {
        return trim(result._id) !== trim(listing._id);
      });
      await commit('foundSimilar', filteredResults);
      commit('isLoading', false);
    })
    .catch(err => {
      console.log('listing find() failed:', err);
    });
};

const select = ({ commit }, target) => {
  commit('select', target);
};

const selectAll = ({ commit }) => {
  commit('selectAll');
};

const selectSort = async ({ commit, dispatch, state }, value) => {
  await commit('selectSort', value);
  dispatch('find', { mlsNumberSelected: state.mlsNumberSelected });
};

const deselectAll = ({ commit }, value) => {
  commit('deselectAll');
};

const setFindCaller = ({ commit }, caller) => {
  commit('findCaller', caller);
};

function makeRequest(url) {
  return new Promise((resolve, reject) => {
    request(url, (error, res, body) => {
      if (!error && res.statusCode === 200) {
        resolve(body);
      } else {
        reject(error);
      }
    });
  });
}

const hasShowingtime = async ({ commit }, { listing, MemberMlsId }) => {
  const { StandardStatus, ListOfficeMlsId, ListAgentMlsId, ListingId } = listing._source;

  const url = `https://icons.showingtime.com/sw/SW?p=LIONDESK.REALCOMP.I~S~31214~REALCOMP~0;${ListingId}~${
    StandardStatus
  }~${ListAgentMlsId}~${ListOfficeMlsId}~REALCOMP`;

  let show = false;

  const hasIcon = await makeRequest(url);
  if (!hasIcon) return;
  show = !!+hasIcon[5];
  if (show) {
    const scheduleURL = `https://schedulingsso.showingtime.com/external/?siteid=LIONDESK.REALCOMP.I&raid=${MemberMlsId}&mlsid=REALCOMP&listingid=${ListingId}`;
    await commit('addShowingtime', { ListingId, scheduleURL });
  }
};

export default {
  find,
  loadMls,
  findSimilar,
  select,
  selectAll,
  deselectAll,
  selectSort,
  hasShowingtime,
  setFindCaller,
};
