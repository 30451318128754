<!--
  @Author: Ethan Dinnen
  @Date:   2018-11-30 13:43:16
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-02-07 11:15:27
-->

<template>
  <v-select
    :items="options"
    :label="title"
    :chips="chips"
    :attach="attach"
    :multiple="multiple"
    :placeholder="placeholder"
    v-model="selectedValue"
    @change="handleEmit"
    :rules="rules"
    :required="required"
    :disabled="disabled"
    :append-icon="icon"
    :error-messages="errors"
    :outline="outline"
  />
</template>

<script>
export default {
  props: {
    placeholder: String,
    title: String,
    options: Array,
    chips: {
      type: Boolean,
      required: false,
      default: false,
    },
    attach: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    errors: {
      type: Array,
      required: false,
      default: () => [],
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    selected: {
      required: false,
      default: '',
    },
    fieldId: {
      type: String,
      required: false,
      default: null,
    },
    icon: {
      type: String,
      required: false,
      default: '$vuetify.icons.dropdown',
    },
    outline: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedValue: this.selected,
    };
  },
  methods: {
    handleEmit() {
      const { selectedValue, fieldId } = this;
      this.$emit(
        'onInput',
        fieldId ? { value: selectedValue, fieldId } : selectedValue
      );
    },
  },
};
</script>
