<!--
  @Author: Ethan Dinnen
  @Date:   2018-12-11 15:52:47
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-02-07 12:49:55
-->
<template lang="pug">
  tr(
    class="list-data-row"
  )
    td.ml-2.text-xs-center
      v-checkbox(
        @click.native='selectOne'
        :active='data.selected'
        v-model='data.selected'
        primary
        hide-details
      )
    template(v-for='(text, key) in item')
      template(v-if='key !== "id"')
        td.text-xs-left(
          @click='expand'
          :key='key'
        )
          component(:is='dataCellComponent(getValue(key))' :data='text' :plans="plans")
    td.text-xs-left(
      class="action-container"
    )
      data-table-menu(
        v-if="!showDeleteOnly"
        offset-y
        :actions='actions'
        icon='more_vert'
        :transactionId="id"
      )
      v-btn(
        class="delete-btn"
        v-else
        icon
        @click="handleDelete(item[0].id)"
      )
        v-icon delete
      v-btn(
        class="edit-btn"
        v-if="showEdit"
        icon
        @click="handleEdit(item[0].id)"
      )
        v-icon edit
</template>

<script>
import DataTableMenu from '@/Core/components/DataTableMenu';
import ListDataLogic from '@/Core/mixins/ListDataLogic';
import Title from './TransactionsTitle';
import Item from './TransactionsTableItem';
import StartPlan from './StartPlanModal';

export default {
  components: {
    Title,
    Item,
    DataTableMenu,
  },
  props: {
    data: {
      type: Object,
    },
    handleDelete: Function,
    handleEdit: Function,
    selected: {
      type: Array,
    },
    showDeleteOnly: Boolean,
    showEdit: Boolean,
    plans: Boolean,
    showPlanModal: Function,
    index: Number,
  },
  computed: {
    actions() {
      return [
        {
          title: 'Start Plan',
          func: () => this.showPlanModal(this.data.item.id),
        },
        {
          title: 'Edit Transaction',
          path: `/edit/${this.data.item.id}`,
        },
        {
          title: 'Delete Transaction',
          func: this.handleDelete,
        },
      ];
    }
  },
  mixins: [ListDataLogic],
};
</script>

<style lang="scss">
.list-data-row {
  .delete-btn {
    display: none;
    position: absolute;
    top: 5px;
  }
  .edit-btn {
    display: none;
    position: absolute;
    top: 5px;
    left: -10px;
  }
  .action-container {
    position: relative;
  }
}

.list-data-row:hover {
  .delete-btn,
  .edit-btn {
    display: block;
  }
}
</style>
