/*
 * @Author: Ethan Dinnen
 * @Date:   2018-11-30 11:03:26
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-01-18 12:54:16
 */
import { Plan } from 'api';

const reset = async ({ commit }) => await commit('reset');

const getPlan = async ({ commit }, id) => {
  const plan = await Plan.get(id);
  await commit('setPlan', plan);
};

const deleteTask = async ({ dispatch, state }, id) => {
  await Plan.destroyTask(id);
  await dispatch('getPlan', state.plan.id);
};

const updatePlan = async ({ dispatch }, plan) => {
  await Plan.update(plan);
  await dispatch('getPlan', plan.id);
};

export default {
  reset,
  getPlan,
  deleteTask,
  updatePlan,
};
