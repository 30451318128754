<template>
  <ld-persistent-banner
    v-if="visible"
    :error="isAccessBlocked"
    :text="text"
    :link="upgradeLink"
    :buttonText="upgradeText">
  </ld-persistent-banner>
</template>

<script>
import LdPersistentBanner from './LdPersistentBanner';

const trialStatusList = ['trial', 'trial_expired'];

export default {
  components: { LdPersistentBanner },
  props: {
    user: {
      type: Object,
      default: null,
    },
    upgradeLink: {
      type: String,
    },
    upgradeText: {
      type: String,
    },
  },
  computed: {
    text() {
      if (this.subscription) {
        const { expires_in_days } = this.user.subscriptionOverview;
        const units = expires_in_days === 1 ? 'day' : 'days';
        if (expires_in_days > 0) {
          return `You have <strong>${expires_in_days} ${units}</strong> remaining to access LionDesk`;
        }
        return 'Your account is no longer active';
      }
    },
    isAccessBlocked() {
      if (this.subscription) {
        return this.subscription.expires_in_days < 1;
      }
      return false;
    },
    isTrial() {
      return this.user &&
        this.user.subscriptionOverview &&
        trialStatusList.includes(this.user.subscriptionOverview.txt_status);
    },
    subscription() {
      if (this.isTrial) {
        return this.user.subscriptionOverview;
      }
      return null;
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  watch: {
    user: {
      handler(value) {
        // show banner only for trial accounts
        this.visible = this.isTrial;
        if (this.visible) {
          // notify parent whether or not this banner is visible
          this.$emit('show', this.visible);
          // notify parent whether or not user access should be disabled
          this.$emit('block', this.isAccessBlocked);
        }
      },
      immediate: true,
      deep: true,
    }
  },
};
</script>