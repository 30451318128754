<template>
<svg 
data-icon ="qualify" version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1000 800" style="enable-background:new 0 0 1000 800;" xml:space="preserve">
<path fill="currentColor" d="M502.7,519.2L446.1,412l0,0l0-0.1c-9.7,4.3-20.1,7.3-31,8.9c-4.4,0.6-8.8,1-13.3,1.1c-0.9,0-1.8,0.1-2.7,0.1
	c-65.2,0-118.1-54.1-118.1-120.8c0-2.6,0.1-5.1,0.3-7.7l-58.1-41.7c-4.2,15.7-6.5,32.3-6.5,49.4c0,101.3,78.9,183.8,177.2,186.6
	l103.3,159.6c193.3-279.9,392-338.5,392-338.5C734.9,316.7,502.7,519.2,502.7,519.2"/>
<path fill="currentColor" d="M399.4,120.9c-72.4,0-134.9,42.3-164.3,103.5l53.2,37.4c16.8-45.1,60.3-77.3,111.3-77.3
	c65.6,0,118.7,53.1,118.7,118.7c0,37.5-17.4,71-44.6,92.7l30.7,55.8c46.6-33,77.1-87.3,77.1-148.7
	C581.5,202.5,499.9,120.9,399.4,120.9z"/>
</svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '$vuetify.icons.qualify',
    },
  },
};
</script>