<template>
  <v-layout align-top="align-top" justify-center="justify-center">
    <v-flex>
      <v-navigation-drawer
        class="ld-navigation"
        fixed="fixed"
        stateless="stateless"
        app="app"
        :mini-variant="!navBarExpanded"
        :value="true"
        :width="210"
      >
        <v-spacer class="unified-header-spacer" />
        <v-list class="resizable">
          <div :class="`unified-header-${logoClass}`">
            <div :style="{ backgroundImage: `url('${logoURL}')` }"></div>
          </div>
          <v-list-tile>
            <v-list-tile-action>
              <v-btn icon="icon" @click.stop="$emit('toggle:drawer')" id="expand_btn">
                <v-icon v-if="!navBarExpanded">chevron_right</v-icon>
                <v-icon v-else>chevron_left</v-icon>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>
          <div class="scrollable" :class="{ v2: v2Only }">
            <template v-for="item in navigation">
              <template v-if="showLeadsPlusButton(item)">
                <ld-gated-router-link
                  :feature="item.feature"
                  v-if="activeApp === item.app"
                  :to="item.route"
                  :key="item.id"
                  active-class="header-router-link-active"
                  class="router-link"
                >
                  <v-list-tile>
                    <v-list-tile-action>
                      <v-tooltip right :disabled="navBarExpanded">
                        <span>{{ item.title }}</span>
                        <v-icon slot="activator" v-text="item.icon"></v-icon>
                      </v-tooltip>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title class="header-nav-title">{{ item.title }}</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </ld-gated-router-link>
                <ld-gated-link
                  v-else-if="showV1Button(item)"
                  :class="['router-link', isActive(item.app)]"
                  :href="item.route.href"
                  :key="item.id"
                  :target="item.route.target"
                  :feature="item.feature"
                >
                  <v-list-tile>
                    <v-list-tile-action>
                      <v-tooltip right :disabled="navBarExpanded">
                        <span>{{ item.title }}</span>
                        <v-icon slot="activator" v-text="item.icon"></v-icon>
                      </v-tooltip>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title class="header-nav-title">{{ item.title }}</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </ld-gated-link>
              </template>
            </template>
          </div>
        </v-list>
      </v-navigation-drawer>
      <v-layout align-top="align-top" justify-center="justify-center">
        <v-flex>
          <unified-header
              :user="user"
              :show-beta-toggles="showBetaToggles"
              :show-payments-settings="v2Only"
              :show-account-management="hasAccountManagementFF"
              :profile-picture="profilePicture"
              @logout="$emit('logout')"
              @updateProfilePicture="$emit('updateProfilePicture')"
          >
            <template slot="search">
              <slot name="search"></slot>
            </template>
            <template v-if="showBetaToggles" slot="betaToggles">
              <ul>
                <li class="lwt-unified-navbar__dropdown-menu-section-header">Faster Search and Filtering</li>
              </ul>
              <v-layout column style="{ width: '175px' }">
                <v-flex v-if="shouldSeeV2ContactsToggle">
                  <v-switch
                    class="ml-2 v-input--reverse"
                    hide-details
                    data-cy="toggle-contacts-v2-switch"
                    :label="'Contacts ' + v2ContactStatus"
                    color="secondary"
                    :disabled="isUpdatingV2ContactsFlag"
                    :input-value="shouldLoadV2Contacts"
                    @change="setShouldLoadV2Contacts"
                  />
                </v-flex>
                <v-flex v-if="shouldSeeV2EventsToggle">
                  <v-switch
                    class="ml-2 mb-2 v-input--reverse"
                    hide-details
                    data-cy="toggle-events-v2-switch"
                    :label="'Activity ' + v2EventsStatus"
                    color="secondary"
                    :disabled="isUpdatingV2EventsFlag"
                    :input-value="shouldLoadV2Events"
                    @change="setShouldLoadV2Events"
                  />
                </v-flex>
              </v-layout>
              <ul>
                <li class="lwt-unified-navbar__dropdown-menu-section-header">Integrations</li>
              </ul>
              <v-layout column style="{ width: '175px' }">
                <v-flex>
                  <v-switch
                    class="ml-2 mb-2 v-input--reverse"
                    hide-details
                    data-cy="toggle-qualify-switch"
                    :label="'Qualify ' + qualifyStatus"
                    color="secondary"
                    :disabled="isUpdatingQualifyFlag"
                    :input-value="shouldLoadQualify"
                    @change="setShouldLoadQualify"
                  />
                </v-flex>
              </v-layout>
            </template>
          </unified-header>
          <v-layout :style="unifiedHeaderBannerStyle" column>
            <v-flex>
              <ld-trial-banner
                :user="user"
                :upgradeLink="upgradeLink"
                @show="updateHeight($event, 'trialBanner')"
                @block="accessControlClass = $event ? 'no-access' : ''"
              />
              <ld-outstanding-invoice-banner
                :user="user"
                :upgradeLink="upgradeLink"
                :updateLink="updateLink"
                @show="updateHeight($event, 'outstandingInvoiceBanner')"
                @block="accessControlClass = $event ? 'no-access' : ''"
              />
              <ld-verify-email-banner
                :user="user"
                :resendVerifyLink="resendVerifyLink"
                @show="updateHeight($event, 'verifyEmailBanner')"
                @click:resend="$emit('click:resendVerifyEmail')"
              />
              <ld-exceeding-daily-text-limits-banner
                :smsUsage="smsUsage"
                :user="user"
                @show="updateHeight($event, 'dailyTextLimitsBanner')"
                @click:a2pRegistration="$emit('click:a2pRegistration')"
              />
              <ld-registration-failed-a2p-banner
                :registrationStandardInfo="registrationStandardInfo"
                :registrationStarterInfo="registrationStarterInfo"
                :registrationLowStandardInfo="registrationLowStandardInfo"
                @show="updateHeight($event, 'registrationFailedA2pBanner')"
              />
              <ld-dismissable-banners
                :user="user"
                :upgradeLink="upgradeLink"
                :customNumberLink="customNumberLink"
                :featureMeteredUsageKey="featureMeteredUsageKey"
                @accept:addon="$emit('accept:addon', featureMeteredUsageKey)"
                @toggle="updateHeight($event, 'dismissableBanners')"
              ></ld-dismissable-banners>

              <ld-email-integrations-banner
                :alreadyConnected="hasAtLeastOneEmailAccount"
                :user="user"
                @show="updateHeight($event, 'emailIntegrationsBanner', 56)"
              ></ld-email-integrations-banner>
               <ld-custom-number-banner
                :user="user"
                @show="updateHeight($event, 'customNumberBanner', 56)"
              ></ld-custom-number-banner>
              <ld-change-password-banner
                :alreadyConnected="hasAtLeastOneEmailAccount"
                :user="user"
                @show="updateHeight($event, 'changePasswordBanner')"
              ></ld-change-password-banner>
              <ld-email-microsoft-migration-banner
                :needReconnection="hasAtLeastOneOutlookAccountToReconnect"
                @show="updateHeight($event, 'emailMicrosoftMigrationBanner', 56)"
              ></ld-email-microsoft-migration-banner>
            </v-flex>
          </v-layout>
          <v-spacer :style="{ height: `${extensionHeight}px` }" />

          <LdPaymentCta
            :value="banner"
            :dismissBanner="dismissBanner"
            :bannerText="bannerText"
            :upgradeLink="upgradeLink"
          />

          <v-content>
            <v-container
              :class="[
                accessControlClass,
                banner ? 'banner-offset' : '',
                'pa-0',
              ]"
              fluid="fluid"
            >
              <slot></slot>
              <ld-upgrade-product-modal
                :addonPurchaseResult="addonPurchaseResult"
                :feature="featureGate"
                :featureMeteredUsageKey="featureMeteredUsageKey"
                :plan="plan"
                :upgradeLink="upgradeLink"
                :customNumberLink="customNumberLink"
                :user="user"
                @accept:addon="$emit('accept:addon', featureMeteredUsageKey)"
                @input="$emit('toggle:gated', $event)"
              />
              <ld-verify-email-modal
                :value="verifyEmail"
                @input="$emit('toggle:verifyEmail', $event)"
                @click:resend="$emit('click:resendVerifyEmail')"
              />
              <free-trial-modal :user="user"/>   
            </v-container>
          </v-content>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import LdUtility from './LdUtilityContainer';
import LDQuickMenu from './LDQuickMenu';
import LdDismissableBanners from './LdDismissableBanners';
import LdEmailIntegrationsBanner from './LdEmailIntegrationsBanner';
import LdChangePasswordBanner from './LdChangePasswordBanner';
import LdVerifyEmailBanner from './LdVerifyEmailBanner';
import LdTrialBanner from './LdTrialBanner';
import LdOutstandingInvoiceBanner from './LdOutstandingInvoiceBanner';
import LdPaymentCta from './LdPaymentCta';
import LdCustomNumberBanner from './LdCustomNumberBanner';
import LdUpgradeProductModal from 'lion-vue-src/Shared/components/LdUpgradeProductModal';
import LdVerifyEmailModal from 'lion-vue-src/Shared/components/LdVerifyEmailModal';
import LdExceedingDailyTextLimitsBanner from './LdExceedingDailyTextLimitsBanner';
import LdRegistrationFailedA2pBanner from './LdRegistrationFailedA2pBanner';
import LdGatedLink from 'lion-vue-src/Shared/components/LdFeatureGate/LdGatedLink';
import LdGatedRouterLink from 'lion-vue-src/Shared/components/LdFeatureGate/LdGatedRouterLink';
import baseLogo from '../../assets/liondesk-logo.png';
import UnifiedHeader from './UnifiedHeader.vue';
import LdEmailMicrosoftMigrationBanner from "./LdEmailMicrosoftMigrationBanner";
import FreeTrialModal from "./FreeTrialModal.vue";
import { mapGetters } from 'vuex';

const isCoBrandStatus = 1;
const logoClass = 'logo';
const coBrandLogoClass = 'co-brand-logo';
const unifiedHeaderLogoClass = 'lwolf-logo';

export default {
  components: {
    LdUtility,
    LDQuickMenu,
    LdUpgradeProductModal,
    LdDismissableBanners,
    LdVerifyEmailModal,
    LdVerifyEmailBanner,
    LdTrialBanner,
    LdOutstandingInvoiceBanner,
    LdPaymentCta,
    LdGatedLink,
    LdGatedRouterLink,
    LdEmailIntegrationsBanner,
    LdCustomNumberBanner,
    LdExceedingDailyTextLimitsBanner,
    LdChangePasswordBanner,
    LdRegistrationFailedA2pBanner,
    UnifiedHeader,
    LdEmailMicrosoftMigrationBanner,
    FreeTrialModal,
  },
  props: {
    items: Array,
    smsUsage: {
      type: Object,
      default: null,
    },
    registrationStandardInfo: {
      type: Object,
      default: null,
    },
    registrationStarterInfo: {
      type: Object,
      default: null,
    },
    registrationLowStandardInfo: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
    title: String,
    navigation: Array,
    navBarExpanded: Boolean,
    activeApp: String,
    v2Only: Boolean,
    plan: String,
    featureGate: String,
    featureMeteredUsageKey: String,
    addonPurchaseResult: Object,
    upgradeLink: String,
    updateLink: String,
    customNumberLink: String,
    resendVerifyLink: String,
    banner: Boolean,
    dismissBanner: Function,
    bannerText: String,
    trialWarning: Boolean,
    verifyEmail: Boolean,
    isPromoVisible: Boolean,
    shouldLoadV2Contacts: Boolean,
    shouldLoadQualify: Boolean,
    shouldSeeV2ContactsToggle: Boolean,
    isUpdatingV2ContactsFlag: Boolean,
    hasAtLeastOneEmailAccount: Boolean,
    hasAtLeastOneOutlookAccountToReconnect: Boolean,
    shouldLoadV2Events: Boolean,
    shouldSeeV2EventsToggle: Boolean,
    isUpdatingV2EventsFlag: Boolean,
    isUpdatingQualifyFlag: Boolean,
    setShouldLoadV2Contacts: Function,
    setShouldLoadV2Events: Function,
    setShouldLoadQualify: Function,
    hasAccountManagementFF: Boolean,
    hasBrokersSalesDemoFF: Boolean,
    profilePicture: Object,
  },
  watch: {
    user: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!newValue) return;

        this.setLogoURL();
      },
    },
  },
  computed: {
    v2ContactStatus() {
      return this.shouldLoadV2Contacts ? 'On' : 'Off';
    },
    v2EventsStatus() {
      return this.shouldLoadV2Events ? 'On' : 'Off';
    },
    qualifyStatus() {
      return this.shouldLoadQualify ? 'On' : 'Off';
    },
    unifiedHeaderBannerStyle() {
      return {
        paddingLeft: this.navBarExpanded ? '210px' : '80px',
        paddingTop: '60px',
        position: 'fixed',
        zIndex: 2,
        width: '100%',
      };
    },
    showBetaToggles() {
      return this.shouldSeeV2ContactsToggle || this.shouldSeeV2EventsToggle;
    },
  },
  methods: {
    isActive(app) {
      return app === this.activeApp ? 
        'header-router-link-active' : '';
    },
    setLogoURL() {
      if (!this.user) {
        this.logoURL = '';
        return;
      }

      const { brokerCobranded, reseller } = this.user;
      // brokerCobranded.intCoBrand 1 means that the user should have the
      // the co brand logo shown.
      if (
        brokerCobranded &&
        brokerCobranded.intCoBrand === isCoBrandStatus &&
        brokerCobranded.txtBrokerLogo !== ''
      ) {
        this.logoURL = brokerCobranded.txtBrokerLogo;
        this.logoClass = coBrandLogoClass;
        return;
      } else if (reseller && reseller.txtResellerLogo !== '') {
        this.logoURL = reseller.txtResellerLogo;
        this.logoClass = coBrandLogoClass;
        return;
      }

      this.logoURL = baseLogo;
      this.logoClass = unifiedHeaderLogoClass;
    },
    showV1Button(navigation) {
      if (navigation.app !== 'v1') return true;
      return !this.v2Only;
    },
    showLeadsPlusButton(navigation) {
      if (navigation.id !== 'leadsplusreporting') return true;
      return !this.hasBrokersSalesDemoFF;
    },
    updateHeight(event, banner, height=30) {
      event ?  this.setActiveBanner(banner, height) : this.removeActiveBanner(banner, height);
      this.extensionHeight = this.banners30px.size * 30 + (this.dismissibleBanners + this.banners56px.size) * 56;
    },
    setActiveBanner(banner, height) {
      if (banner === "dismissableBanners") {
        this.dismissibleBanners++;
      } else if (height == 56) {
        this.banners56px.add(banner)
      } else {
        this.banners30px.add(banner);
      }
    },
    removeActiveBanner(banner, height) {
      if (banner === "dismissableBanners") {
        this.dismissibleBanners--;
      }else if (height == 56) {
        this.banners56px.delete(banner)
      } else {
        this.banners30px.delete(banner);
      }
    }
  },
  data() {
    return {
      // toolbar extension height
      // must be set programmatically
      // in order for layout top offset
      // to get recalculated correctly
      extensionHeight:0,
      banners30px: new Set(),
      banners56px: new Set(),
      dismissibleBanners: 0,
      accessControlClass: '',
      logoURL: '',
      logoClass: unifiedHeaderLogoClass,
    };
  },
};
</script>
<style lang="scss">
$lwolf-dark: #111827;

.container {
  &.banner-offset {
    padding: 84px 0 0 0 !important;
  }
}

.feature-toggle {
  display: inline-block;
}

.beta-toggles {
  width: 175px;
}

%router-hover {
  background-color: rgba(56, 120, 194, 0.1);
}

.banners .v-toolbar__extension {
  flex-direction: column;
  padding: 0;
  background: white;
}

.toggle-title {
  margin-left: 30px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  display: inline-block;
  position: relative;
  top: 1px;
}

.no-access {
  filter: blur(1px);
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.75;
    background-color: white;
    z-index: 9999;
  }
}

.ld-gated-feature {
  position: relative;
  &.v-btn:not(.v-btn--icon) {
    &:after {
      font-family: 'Material Icons';
      font-weight: normal;
      font-size: 14px;
      line-height: 1;
      text-transform: none;
      -webkit-font-feature-settings: 'liga';
      -webkit-font-smoothing: antialiased;
      content: 'lock';
      display: block;
      position: absolute;
      color: white;
      bottom: -8px;
      padding: 3px;
      border-radius: 50%;
      background: var(--v-primary-darken2);
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.28);
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    }
    &:hover:after {
      color: var(--v-primary-darken2);
      background: var(--v-primary-lighten5);
    }
    &.v-btn--disabled {
      &:after {
        background: var(--v-background-lighten2);
      }
    }
  }
  &.v-btn--icon {
    &:after {
      font-family: 'Material Icons';
      font-weight: normal;
      font-size: 10px;
      line-height: 1;
      text-transform: none;
      -webkit-font-feature-settings: 'liga';
      -webkit-font-smoothing: antialiased;
      content: 'lock';
      display: block;
      position: absolute;
      color: white;
      bottom: 0;
      right: 0;
      padding: 3px;
      border-radius: 50%;
      background: var(--v-text-base);
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.28);
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    }
    &:hover:after {
      color: var(--v-background-darken4);
      background: var(--v-background-lighten3);
    }
  }
  & > .v-tabs__item,
  &.v-input--switch .v-input__slot {
    &:after {
      font-family: 'Material Icons';
      font-weight: normal;
      font-size: 12px;
      text-transform: none;
      -webkit-font-feature-settings: 'liga';
      -webkit-font-smoothing: antialiased;
      content: 'lock';
      color: var(--v-text-darken1);
      padding-left: 2px;
    }
  }
  &.router-link {
    .v-list__tile__title {
      &:after {
        font-family: 'Material Icons';
        font-weight: normal;
        font-size: 12px;
        text-transform: none;
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
        content: 'lock';
        color: var(--v-primary-base);
        padding-left: 2px;
        bottom: 1px;
        position: absolute;
      }
    }
    .v-icon {
      &:after {
        font-family: 'Material Icons';
        font-weight: normal;
        font-size: 10px;
        line-height: 1;
        text-transform: none;
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
        content: 'lock';
        display: block;
        position: absolute;
        color: white;
        bottom: 6px;
        left: 28px;
        padding: 3px;
        border-radius: 50%;
        background: var(--v-background-darken2);
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.28);
        transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        transition-property: transform, width, -webkit-transform, opacity;
        opacity: 0;
      }
      .v-navigation-drawer--mini-variant & {
        &:after {
          opacity: 1;
          left: 40px;
        }
      }
    }
  }
  .upgrade-banner {
    background-color: rgba(56, 120, 194, 0.1);
    border-radius: 4px;
    padding: 16px;
    max-width: 640px;
    span {
      padding: 0 4px;
      vertical-align: middle;
    }
    i {
      color: var(--v-primary-base);
      vertical-align: middle;
    }
  }
}

a.ld-gated-feature {
  &:not(.router-link) {
    &:after {
      font-family: 'Material Icons';
      font-weight: normal;
      font-size: 12px;
      text-transform: none;
      -webkit-font-feature-settings: 'liga';
      -webkit-font-smoothing: antialiased;
      content: 'lock';
      color: var(--v-text-darken1);
      padding-left: 2px;
    }
  }
  &:not(.v-tabs__item) {
    &:after {
      position: absolute;
      text-decoration: none;
      color: var(--v-primary-base);
      bottom: 1px;
    }
  }
}

.ld-navigation {
  &.v-navigation-drawer--mini-variant {
    .unified-header-co-brand-logo {
      div {
        height: 45px;
      }
    }
  }
  .router-link,
  .router-link-active {
    text-decoration: none;
    .v-list__tile {
      height: 48px;
      transition: background-color 0.3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
    }
  }

  .router-link,
  .header-router-link-active {
    text-decoration: none;
    .v-list__tile {
      height: 48px;
      transition: background-color 0.3s cubic-bezier(0.45, 0.05, 0.55, 0.95);
    }
  }

  .router-link {
    height: 43px;
    display: block;
    .v-list__tile{
      height: 43px;
    }
    &:hover {
      .v-list__tile {
        @extend %router-hover;
      }
    }
  }
  .v-list__tile__action {
    min-width: 40px;
  }

  .router-link-active {
    border-right: solid 3px var(--v-primary-base);
    .v-list__tile {
      font-size: 14px;
      @extend %router-hover;
      i {
        color: var(--v-primary-base);
      }
    }
  }


  .header-router-link-active {
    border-right: solid 3px $lwolf-dark;
    .v-list__tile {
      font-size: 14px;
      @extend %router-hover;
      i {
        color: $lwolf-dark;
      }
    }
  }

  #expand_btn {
    z-index: 1;
    i {
      z-index: -1;
    }
  }

  .lwolf-logo  {
    white-space: nowrap;
    height: 60px;
    padding: 4px 12px 0;
    border-bottom: solid 1px #eee;
    div {
      position: absolute;
      height: 64px;
      width: 64px;
      vertical-align: middle;
      margin-left: -15px;
      top: 4px;
      left: 15px;
      background-size: contain;
    }
  }

  .unified-header-co-brand-logo {
    white-space: nowrap;
    height: 60px;
    padding: 4px 12px 0;
    div {
      position: relative;
      margin: 0 auto;
      height: 60px;
      vertical-align: middle;
      background-size: contain;
      background-position: center;
    }
  }

 .unified-header-spacer {
    height: 60px;
 }

  .back-to-v1 {
    position: absolute;
    .v-list__tile__title {
      white-space: normal;
    }
  }
  .resizable {
    height: calc(100% - 64px);
  }
  .scrollable {
    overflow-y: auto;
    height: calc(100% - 175px);
    border-bottom: solid 1px var(--v-background-lighten4);
  }
  .v2 {
    height: calc(100% - 140px);
  }
}

.promotion-box {
  align-items: center;
  // background-color: var(--v-primary-lighten1);
  border-radius: 25px;
  display: flex;
  height: 46px;
  justify-content: center;
  margin-left: 80px;
  padding-left: 30px;
  padding-right: 30px;

  .promo {
    display: inherit;
    text-decoration: none;
    color: var(--v-background-lighten5);
    // padding-top: 16px;

    .pro-message {
      text-transform: uppercase;
      font-weight: 800;
      margin-left: 10px;
      margin-right: 10px;
      font-size: 12px;
    }
  }

  img {
    width: 305px;
  }
}
/*Custom Icons*/

.v-list svg.svg-inline--fa.fa-bullhorn.v-icon.v-icon--is-component.theme--light {
  max-width: 22px;
}

.layout-container {
  z-index: 1;
}

.v-input__control {
  width: 100% !important;
}

.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
}

.header-nav-title {
  color: $lwolf-dark;
}

</style>
