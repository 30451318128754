<template>
  <svg
    aria-hidden="true"
    focusable="false"
    data-icon="gmail"
    role="img"
    viewBox="0 0 43 34"
  >
    <path
      fill="currentColor"
      d="M38.7,29.75 L34.4,29.75 L34.4,11.15625 L21.5,19.125 L8.6,11.15625 L8.6,29.75 L4.3,29.75 L4.3,4.25 L6.88,4.25 L21.5,13.28125 L36.12,4.25 L38.7,4.25 M38.7,0 L4.3,0 C1.9135,0 0,1.89125 0,4.25 L0,29.75 C0,32.0972102 1.92517558,34 4.3,34 L38.7,34 C41.0748244,34 43,32.0972102 43,29.75 L43,4.25 C43,1.89125 41.065,0 38.7,0 Z"    />
  </svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '$vuetify.icons.gmail',
    },
  },
};
</script>