<template>
    <svg 
        aria-hidden="true"
        focusable="false"
        data-icon="outlook"
        role="img"
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 21 21"
    >
        <title>MS-SymbolLockup</title>
        <rect x="1" y="1" width="9" height="9" fill="#f25022"/>
        <rect x="1" y="11" width="9" height="9" fill="#00a4ef"/>
        <rect x="11" y="1" width="9" height="9" fill="#7fba00"/>
        <rect x="11" y="11" width="9" height="9" fill="#ffb900"/>
    </svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '$vuetify.icons.microsoft',
    },
  },
};
</script>
