/*
 * @Author: Ethan Dinnen
 * @Date:   2019-01-30 14:59:40
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-02-19 15:37:33
 */
import stageTypes from './stageTypes';
import transactionInputTypes from './transactionInputTypes';
import formTemplateNames from './formTemplateNames';
import transactionWizardSteps from './transactionWizardSteps';
import templateIds from './templateIds';
import kcdRelativeTypes from './kcdRelativeTypes';

export default {
  WIZARD_WATCH_HEIGHT: 'wizard-watch-height',
  ...stageTypes,
  ...transactionInputTypes,
  ...formTemplateNames,
  ...transactionWizardSteps,
  ...templateIds,
  ...kcdRelativeTypes,
};
