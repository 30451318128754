<template>
<svg 
  data-icon="advertise-real-state"
  role="img"
  viewBox="0 0 89 77"
>
<path fill-rule="evenodd" clip-rule="evenodd" d="M75 75H70.9333V33.3898L44.5 14.5277L18.0667 33.3898V75H14V32.3438C14 31.6877 14.3172 31.0722 14.852 30.6903L43.3186 10.3778C44.0262 9.87406 44.9738 9.87406 45.6814 10.3778L74.148 30.6903C74.6828 31.0722 75 31.6877 75 32.3438V75Z" fill="#3C6697"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M86.9774 34H72.8182V30H80.6664L44.5 4.458L8.33357 30H16.1818V34H2.02265C1.14276 34 0.366029 33.438 0.0970062 32.61C-0.172017 31.782 0.131393 30.878 0.847439 30.372L43.3248 0.372C44.0287 -0.124 44.9713 -0.124 45.6752 0.372L88.1526 30.372C88.8686 30.878 89.172 31.782 88.903 32.61C88.634 33.438 87.8572 34 86.9774 34Z" fill="#3C6697"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M77 24H73V8H65V14H61V6C61 4.896 61.894 4 63 4H75C76.106 4 77 4.896 77 6V24Z" fill="#3C6697"/>
<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="89" height="77">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 77H89V0H0V77Z" fill="white"/>
</mask>
<g mask="url(#mask0)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4 77H85V73H4V77Z" fill="#3C6697"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M44 49C37.738 49 34 46.01 34 41C34 35.486 38.486 31 44 31C49.514 31 54 35.486 54 41H50C50 37.692 47.308 35 44 35C40.692 35 38 37.692 38 41C38 41.99 38 45 44 45V49Z" fill="#3C6697"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M44 63C38.486 63 34 58.514 34 53H38C38 56.308 40.692 59 44 59C47.308 59 50 56.308 50 53C50 52.012 50 49 44 49V45C50.262 45 54 47.99 54 53C54 58.514 49.514 63 44 63Z" fill="#3C6697"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M42 32H46V26H42V32Z" fill="#3C6697"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M42 67H46V61H42V67Z" fill="#3C6697"/>
</g>
</svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '$vuetify.icons.advertise-real-state',
    },
  },
};
</script>
