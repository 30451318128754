import axios from 'axios';

const uploadFile = async (type, file, options) => {
    const data = {
        type,
        file_name: file.fileName,
        file_size: file.fileSize,
        folder_name: options.folderName,
        is_private: options.isPrivate,
    }
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('file', file.file, file.fileName);
    const response = await axios.post(`${process.env.VUE_APP_FILE_UPLOADER_API_BASE_URL}/upload`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data;
};

export default { uploadFile };
