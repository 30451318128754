<!--
  @Author: Kleber Pinel
  @Date:   2018-12-14 13:40:21
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-02-26 14:45:07
-->

<template>
  <v-layout align-space-between justify-space-between fill-height row>
    <v-flex xs12>
      <div class="title-actions">
        <title-bar class="tx-type-title"> Transaction Types </title-bar>
        <v-tooltip bottom>
          <ld-gated-button
            icon
            slot="activator"
            @click="onCreateTransTypeClick()"
            :feature="LdFeatures.TransactionPipelines"
          >
            <v-icon class="btn-create" >add</v-icon>
          </ld-gated-button>
          <span>Create New Transaction Type</span>
        </v-tooltip>
      </div>
      <v-card-text class="overflow-hidden">
        <v-layout row align-content-center class="horiz-scroll">
          <draggable
            :key="`widgets-${updatedTimes}`"
            v-model="typesGroup"
            :options="dragOptions"
            @start="isDragging = true;"
            @end="isDragging = false;"
            :id="'0'"
            style="width: 100%"
          >
            <transition-group
              type="transition"
              :name="'flip-list'"
              class="draggable-group"
              style="display: flex;"
              :id="'0'"
            >
              <v-flex
                v-for="(item, index) in transactionTypesWidget"
                :key="item.transactionType.id"
                class="transaction-type-card-drag-wrapper"
              >
                <TransactionTypeCard
                  :item="item"
                  :index="index"
                  :selected="selectedTransactionTypeId"
                  :onClick="setSelectedTransactionTypeId"
                />
              </v-flex>
            </transition-group>
          </draggable>
        </v-layout>
      </v-card-text>
      <v-card-text v-if="selectedTransactionTypeId" class="stages-pipeline">
        <v-card class="transaction-type-pipeline">
          <StagesPipeline
            :transactionTypesWidget="transactionTypesWidget"
            :selectedId="selectedTransactionTypeId"
          />
        </v-card>
      </v-card-text>
    </v-flex>
  </v-layout>
</template>

<script>
import { LdGatedButton, LdFeatures } from 'lion-vue';
import TransactionTypeCard from 'transactionsComponents/components/TransactionTypeCard';
import StagesPipeline from 'transactionsComponents/components/StagesPipeline';
import TitleBar from '@/Core/components/TitleBar';
import draggable from 'vuedraggable';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';

export default {
  components: {
    draggable,
    TitleBar,
    TransactionTypeCard,
    StagesPipeline,
    LdGatedButton,
  },
  props: {
    transactionTypesWidget: {
      required: true,
    },
    selectedTransactionTypeId: Number,
    setSelectedTransactionTypeId: Function,
    updateLevel: Function,
  },
  data() {
    return {
      LdFeatures,
      updatedTimes: 0,
      isDragging: false,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 50,
        group: 'stages',
        ghostClass: 'ghost',
      };
    },
    typesGroup: {
      get() {
        return this.$store.state.transactionTypesWidget.transactionTypesWidget;
      },
      set(value) {
        // Calculate only the changed values to reduce API calls
        const oldOrder = value.map(val => {
          return {
            id: val.transactionType.id,
            level: val.transactionType.level,
          };
        });
        const newOrder = value.map((val, i) => {
          return {
            id: val.transactionType.id,
            level: i + 1,
          };
        });
        const diff = differenceWith(newOrder, oldOrder, isEqual);

        const newValue = value.map(val => {
          const item = diff.find(x => x.id === val.transactionType.id);
          return {
            ...val,
            transactionType: {
              ...val.transactionType,
              level: item ? item.level : val.transactionType.level,
            },
          };
        });

        // If pipeline is open, close it
        this.setSelectedTransactionTypeId(null);
        this.updatedTimes += 1;

        // Update the order by sending newOrder array to API
        return this.updateLevel({ newValue, diff });
      },
    },
  },
  beforeMount() {
    this.setSelectedTransactionTypeId(null);
  },
  methods: {
    onCreateTransTypeClick() {
      this.$router.push('/create/type');
    },
  }
};
</script>

<style lang="scss">
.tx-type-title {
  padding-left: 28px !important;
  padding-right: 0px !important;
  width: unset !important;
}
.transaction-type-card-drag-wrapper {
  padding: 0 !important;
  margin: 12px;
  margin-right: 8px;
  &:first-of-type {
    margin-left: 1px;
  }
  &:last-of-type {
    margin-right: 1px;
  }
}
.stages-pipeline {
  padding: 0px;
}
.v-card__text {
  padding: 16px 0px;
}
.transaction-type-pipeline {
  padding: 10px;
  border-radius: 5px 5px 5px 5px;
  margin-bottom: 10px;
  background-color: white;
}
.overflow-hidden {
  overflow: hidden;
  background-color: #fafafa;
}
.horiz-scroll {
  overflow-y: hidden;
  overflow-x: auto;
  margin-left: 0px !important;
  width: 100%;
}
.ghost {
  opacity: 0.2;
}
.title-actions {
  display: flex;
  align-items: center;
}
.create-type-btn {
  padding: 0;
  position: relative;
  top: 5px;
  right: 47px;
  &__link {
    color: #000;
  }
  &__opt {
    &:hover {
      background-color: rgba(0, 0, 0, 0.06);
    }
    .v-list__tile__title {
      padding: 0 22px;
    }
  }
}
.btn-create {
  background: var(--v-background-lighten3);
  border-radius: 50%;
  padding: 7px;
  &:hover {
    background: var(--v-background-lighten2);
  }
}
</style>
