<template  lang="pug">
v-dialog(
  v-model="isModalVisible" 
  persistent 
  :width="width")
    v-card.free-trial-card()
      v-card-title.card-title.pa-0
        v-btn.close-icon.ma-0(icon @click="closeModal")
          v-icon(small) close
      v-card-text.pt-1.px-5
        v-layout(column align-start justify-center)
          p.header-text.ma-0 Your Free Trial will expire in
            span.ml-1.font-weight-bold {{ daysToExpire }} days
        p.mt-2 {{ subtitleMessage() }}
        v-layout(v-if="shouldShowFreeCreditsList()" column)
          ul(v-for="listItem in freeCreditsList" :key="listItem").pl-3
              li(:class="listItemsInBold(listItem)") {{ listItem }}
        v-layout(v-html="bottomActionMsg")
        v-layout(column align-center justify-center)
          v-btn.upgrade-btn(
            color="primary"
            @click="goToManageSubscriptionsModal"
          ) Upgrade Plan
        
</template>

<script>
import { HC_PROPS } from 'lion-vue';
import { addDays } from 'date-fns';

const trialStatusList = ['trial', 'trial_expired'];

export default {
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isModalVisible: false,
      width: '480px',
      freeCreditsList: [
        '150 emails',
        'Advanced contact filtering',
        'Task management',
        'Email click and open tracking',
        'Inbound lead creation and parsing',
        'Custom contact fields',
        ],
      daysToExpire: '',
      uploadContactURL: `${process.env.VUE_APP_CRM_URL}/contacts/import/process`,
      contactRelationshipsURL: 'https://lonewolf.my.site.com/s/article/LD-Create-contact-relationships-LionDesk',
      upgradePlanURL: `${process.env.VUE_APP_ACCOUNTS_APP_URL}/settings?modal=subscription-modal`,
    };
  },
  watch: {
    user: {
      handler(value) {
        if (value && this.isTrial) {
          this.daysToExpire = this.user.subscriptionOverview.expires_in_days.toString();
          this.showModal(this.user.subscriptionOverview.expires_in_days);
        }
      },
      immediate: true,
      deep: true,
    }
  },
  computed: {
    bottomActionMsg() {
      const expiresInDays = this.user.subscriptionOverview.expires_in_days;
      if (expiresInDays === 13) {
        return `<p class="mt-4"> Ready to get started? Upload your contact<a class="ml-1 font-weight-bold" href=${this.uploadContactURL} target="_blank">here</a>.</p>`;
      } else if (expiresInDays === 7) {
        return `<p class="mt-4"> In the meantime, have you checked out<a class="ml-1 font-weight-bold" href=${this.contactRelationshipsURL} target="_blank">contact relationships</a>?</p>`;
      } else if (expiresInDays === 2) {
        return `<p class="mt-4"> Plus, did you know? With a full LionDesk account, you can access pre-built marketing campaigns made to save you time.</p>`;
      } else {
        return '';
      }
    },
    isTrial() {
      return (this.user && this.user.subscriptionOverview && trialStatusList.includes(this.user.subscriptionOverview.txt_status));
    },
  },
  methods: {
    goToManageSubscriptionsModal() {
      window.location.href = this.upgradePlanURL;
      window.$cookies.set('dismissFreeTrialModal', new Date(), null, null, HC_PROPS.MAIN_DOMAIN);
    },
    subtitleMessage() {
      const expiresInDays = this.user.subscriptionOverview.expires_in_days;
      if (!expiresInDays) return;
      if (expiresInDays === 13) {
        return 'Try out LionDesk with free credits for:';
      } else if (expiresInDays === 7) {
        return 'We hope you`ve been enjoying LionDesk! Time is running out on your trial, so if you`d like to keep your CRM going with one of our plans, check them out below.';
      } else if (expiresInDays === 2) {
        return 'We hope you`ve been enjoying LionDesk! Don`t forget to check out our plans before your trial expires to avoid any interruption to your account.';
      } else {
        return '';
      }
    },
    shouldShowFreeCreditsList() { 
      return (this.user.subscriptionOverview.expires_in_days === 13);      
    },
    listItemsInBold(listItem) {
      const r = /\d+/;
      if (listItem.match(r)) {
        return 'font-weight-bold'
      }
    },
    showModal(daysToExpire) {
      const shouldDisplayModal = (daysToExpire === 13 || daysToExpire === 7 || daysToExpire === 2);
      const modalState = window.$cookies.get('dismissFreeTrialModal'); 
      if (!modalState) {
        this.isModalVisible = shouldDisplayModal;
        return;
      }

      const displayDate = addDays(new Date(modalState), 1);
      if (new Date() >= displayDate) {
        window.$cookies.remove('dismissFreeTrialModal', null, HC_PROPS.MAIN_DOMAIN);
        this.isModalVisible = shouldDisplayModal;
        return;
      }
        this.isModalVisible = false;
    },
    closeModal() {
      this.isModalVisible = false;
      window.$cookies.set('dismissFreeTrialModal', new Date(), null, null, HC_PROPS.MAIN_DOMAIN);
    },
  },
};
</script>

<style lang="scss" scoped>
.free-trial-card {
  border-radius: 8px;
}
.card-title {
  justify-content: flex-end;
}
.header-text {
  font-size: 23px;
  font-weight: 100;
  color: var(--v-primary-base);
}
.upgrade-btn {
  font-weight: 500;
  text-transform: capitalize;
}
</style>
