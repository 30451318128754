<!--
      @Author: Ethan Dinnen
      @Date:   2019-01-03 10:00:08
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-01-15 12:19:00
-->
<template>
  <div class="tx-header-flex">
    <v-layout row>
      <v-layout column>
        <h1>{{ title }}</h1>
        <span>Created on {{ formattedDate }}</span>
      </v-layout>
      <v-layout column shrink align-end>
        <router-link :to="`/edit/${$route.params.id}`">
          <v-btn flat outline block color="primary">EDIT TRANSACTION</v-btn>
        </router-link>
        <v-layout row justify-center align-center v-if="hasPlan">
          <v-icon class="my-3 mx-2" color="success">check_circle_outline</v-icon>
          <span>{{ planName }}</span>
        </v-layout>
        <v-layout class="fill" v-else>
          <v-menu offset-y left>
            <template #activator="{ on: menu }">
              <v-tooltip bottom :disabled="plansAvailable">
                <template #activator="{ on: tooltip }">
                  <v-btn
                    block
                    color="primary"
                    :loading="loading"
                    v-on="{ ...tooltip, ...menu }"
                  >{{ hasPlan ? planName : 'START PLAN' }}
                    <v-icon right dark>arrow_drop_down</v-icon>
                  </v-btn>
                </template>
                <span>No plans available for this transaction type</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-tile
                v-for="(item, index) in formattedPlans"
                :key="index"
                @click="handlePlanSelection(item.value)"
              >
                <v-list-tile-title>{{ item.text }}</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </v-layout>
      </v-layout>
    </v-layout>
    <div class="row mt-4">
      <div>
        <h2>TRANSACTION STATUS</h2>
        <div class="row mt-2">
          <div class="row status-row">
            <span>Type</span>
            <Tag :text="type" color="#2497BB" class="header-tag" />
          </div>
          <div class="row status-row">
            <span>Stage</span>
            <Tag
              :selected="stage"
              class="header-tag"
              color="#105C93"
              :options="stages"
              @stage-change="handleStageChange"
              :returnLastStage="stagePlanMixin_returnLastStage"
            />
          </div>
        </div>
      </div>
    </div>
    <LdConfirmation
      v-model="contactError"
      width="580"
      @click:cancel="cancelPlanSelect"
      @click:ok="confirmToComplete"
      confirmText="Start Anyways"
    >
      <template slot="header">
        Contact Error
      </template>
      <template slot="body">
        The following contact requires a mobile phone number associated with
        them for a transaction plan text action to be started:<br /><br />
        <ul>
          <li v-for="c in contactErrorList" :key="c.id">
            {{ c.first_name }} {{ c.last_name }}
          </li>
        </ul>
      </template>
    </LdConfirmation>
    <LdConfirmation
      v-model="modal"
      width="580"
      @click:cancel="cancelPlanSelect"
      @click:ok="confirmToComplete"
      confirmText="Start Anyways"
    >
      <template slot="header">
        Incomplete Key Contract Dates
      </template>
      <template slot="body">
        This transaction plan depends upon the following key contract dates.<br /><br />
        <ul>
          <li v-for="kcd in toComplete" :key="kcd.id">{{ kcd.label }}</li>
        </ul>
      </template>
    </LdConfirmation>
    <StagePlanChangeModal
      :showStageChangeModal="stagePlanMixin_showStageChangeModal"
      :currentStageName="currentStageName"
      :nextStageName="nextStageName"
      :nextPlanName="nextPlanName"
      :currentPlanName="planName"
      :closeStageChangeModal="closeStageChangeModal"
      :confirmStageChange="confirmStageChange"
      :isLoading="stagePlanMixin_isLoading"
      >
    </StagePlanChangeModal>
  </div>
</template>

<script>
import { LdConfirmation } from 'lion-vue';
import { createNamespacedHelpers } from 'vuex';
import { PLAN_BY_STAGE_FLAG } from '@/Core/config/constants';
import StagePlanChangeModalMixin from '@/Core/mixins/stage-plan-change-modal';
import Tag from './Tag';
import StagePlanChangeModal from '../../components/StagePlanChangeModal';

const {
  mapActions: mapViewActions,
} = createNamespacedHelpers('viewTransaction');

const {
  mapState: mapAppState,
} = createNamespacedHelpers('ldAppLayout');

export default {
  components: {
    Tag,
    LdConfirmation,
    StagePlanChangeModal,
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    type: {
      required: true,
      type: String,
    },
    stage: {
      required: true,
      type: Number,
    },
    stages: {
      required: true,
      type: Array,
    },
    moveStage: {
      required: true,
      type: Function,
    },
    created: {
      required: true,
      type: String,
    },
    kcdsIncomplete: {
      type: Array,
    },
    plans: {
      type: Array,
    },
    txId: {
      type: Number,
    },
    startPlan: {
      type: Function,
    },
    hasPlan: {
      type: Boolean,
    },
    planName: {
      type: String,
    },
    stakeholders: {
      type: Array,
    },
  },
  data() {
    return {
      date: new Date(this.created),
      planDisabled: true,
      selectedPlan: null,
      selectedText: null,
      toComplete: null,
      modal: false,
      disabledPlan: false,
      contactError: false,
      loading: false,
    };
  },
  mixins: [StagePlanChangeModalMixin],
  computed: {
    formattedDate() {
      // Return the date as DD/MM/YYYY, standardized to UTC
      return new Date(
        this.date.getTime() - this.date.getTimezoneOffset() * 60000
      )
        .toLocaleString()
        .split(',')[0]
        .split('/')
        .join(' / ');
    },
    formattedPlans() {
      return this.plans.map(plan => {
        return {
          text: plan.title,
          value: plan.id,
        };
      });
    },
    contactErrorList() {
      return this.stakeholders.filter(s => !s.mobile_phone);
    },
    plansAvailable() {
      return this.formattedPlans && this.formattedPlans.length > 0;
    },
    noPlanMessage() {
      return `No plans available for ${this.type} type`;
    },
  },
  methods: {
    ...mapViewActions(['getTransaction']),
    closeStageChangeModal() {
      this.stagePlanMixin_showStageChangeModal = false;
      this.stagePlanMixin_returnLastStage = true;
    },
    async confirmStageChange() {
      this.stagePlanMixin_isLoading = true;
      await this.moveStage({toStage: this.stagePlanMixin_nextStage});
      this.getTransaction(this.$route.params.id);
      this.stagePlanMixin_showStageChangeModal = false;
      this.stagePlanMixin_isLoading = false;
    },
    async handleStageChange(stage) {
      this.stagePlanMixin_nextStage = stage;
      if (this.stagePlanMixin_returnLastStage) this.stagePlanMixin_returnLastStage = false;
      if (this.nextStageHasPlan) {
        // small delay before showing modal
        // to let for previous close animation finish
        setTimeout(() => {
          this.stagePlanMixin_showStageChangeModal = true;
        }, 300);
      } else {
        await this.moveStage({toStage: this.stagePlanMixin_nextStage});
        this.getTransaction(this.$route.params.id);
      }
    },
    async handlePlanSelection(value) {
      const plan = this.plans.find(p => p.id === value);
      if (!plan) return;
      this.selectedText = plan.title;
      this.selectedPlan = value;

      const tasks = new Set(plan.tasks.map(task => task.due_at));
      const refIds = this.kcdsIncomplete.map(kcd => kcd.meta.referenceId);
      const incomplete = new Set(refIds);
      const setIntersection = new Set(
        [...tasks].filter(t => incomplete.has(t))
      );
      this.toComplete = this.kcdsIncomplete.reduce((acc, kcd) => {
        if (!setIntersection.has(kcd.meta.referenceId)) return acc;
        return [...acc, kcd];
      }, []);
      if (this.toComplete.length) {
        this.modal = true;
        return;
      }

      // Warn the user if a text action recipient does not have a mobile phone #
      if (
        this.contactErrorList.length &&
        plan.tasks.filter(t => t.action_type === 'Text')
      ) {
        this.contactError = true;
        return;
      }
      this.loading = true;
      await this.startPlan({ planId: value, txId: this.txId });
      this.loading = false;
    },
    cancelPlanSelect() {
      this.modal = false;
      this.contactError = false;
      this.toComplete = null;
      this.selectedText = null;
      this.selectedPlan = null;
    },
    async confirmToComplete() {
      this.loading = true;
      await this.startPlan({ planId: this.selectedPlan, txId: this.txId });
      this.loading = true;
      this.cancelPlanSelect();
    },
  },
  beforeMount() {
    if (this.kcdsIncomplete.length) return;
    this.planDisabled = false;
    this.stagePlanMixin_lastStage = this.stage;
  },
};
</script>

<style lang="scss">
.tx-header-flex {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  border-bottom: 1px solid #dddddd;
  a {
    text-decoration: none;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .header-tag {
      margin-left: 10px;
    }
  }
  .fill {
    width: 100%;
  }
  .status-row {
    justify-content: unset;
    &:not(:first-of-type) {
      padding-left: 25px;
    }
    span {
      margin: auto 0;
    }
  }
}
</style>
