<template>
  <v-layout row justify-center>
    <v-dialog :value="value" @input="$emit('input')" persistent max-width="560">
      <v-card class="ld-upgrade-product">
        <v-card-title class="usage-warning">
          Verify email address to send emails
        </v-card-title>
        <v-card-text>
          A message with instructions to verify your email address has been sent to you.
          Verify your email address to send emails with Liondesk.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!resendComplete"
            flat
            @click="$emit('input', false)">
            Cancel
          </v-btn>
          <v-btn
            v-if="!resendComplete"
            class="primary"
            @click="resendVerificationEmail"
            :loading="resendTriggered"
            :disabled="resendTriggered">
            Resend Verification Email
          </v-btn>
          <v-btn
            v-if="resendComplete"
            class="primary"
            @click="$emit('input', false)">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      resendTriggered: false,
      resendComplete: false,
    }
  },
  watch: {
    value: {
      handler(val) {
        if (val === false) {
          this.resendTriggered = false;
          this.resendComplete = false;
        }
      }
    }
  },
  methods: {
    resendVerificationEmail() {
      this.resendTriggered = true;
      this.$emit('click:resend');
      setTimeout(() => {
        this.resendComplete = true;
      }, 1500);
    },
  },
};

</script>
<style lang="scss">
</style>