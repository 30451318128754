<!--
@Author: Kleber Pinel <kleberdasilva>
@Date:   2019-03-19T11:20:17-07:00
@Email:  kleber@hackcapital.com
@Last modified by:   kleberdasilva
@Last modified time: 2019-03-25T09:24:24-07:00
-->
<template lang="pug">
v-dialog(v-model="visible" persistent width='80%')
  v-card
    title-bar(dark) Edit Source
    v-card-text
      v-textarea.edit-html(outline ref="editHTML" v-model="sourceEditing")
    v-card-actions
      v-spacer
      v-btn(flat @click="$emit('close')") Cancel
      v-btn.primary(@click="insert") Save
</template>

<script>
import TitleBar from '@/Core/components/TitleBar';

export default {
  components: {
    TitleBar,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    originalSource: {
      type: String,
    },
  },
  data() {
    return {
      sourceEditing: this.originalSource,
    };
  },
  watch: {
    originalSource(newValue) {
      this.sourceEditing = newValue;
    },
  },
  methods: {
    insert() {
      this.$emit('insert', this.sourceEditing);
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-html {
  white-space: pre-wrap;
  font-family: monospace;
  font-size: 13px;
}

.edit-html /deep/ textarea {
  margin: 0px 0px;
  height: calc(100vh - 500px);
}
</style>
