<template>
  <v-container fluid mls-search-container>
    <v-layout row wrap>
      <mls-search
        :analytics="analytics"
        :searchPlaceholder="searchPlaceholder"
        :advancedSearch="advancedSearch"
      >
        <template slot="advancedSearch">
          <ld-search-filter
            v-model="activeConditions"
            :availableFilterFields="availableFilterFields"
            :onSaveFilter="saveFilter"
            :onApplyFilter="applyFilter"
            :initialSelectedFilter="selectedFilter"
            :savedFilters="savedFilters"
            @filterChange="updateSiblingProps"
          >
            <template slot="staticFilters">
              <v-list>
                <v-layout row wrap>
                  <v-flex xs6>
                    <v-select
                      :value="searchField('minListPrice')"
                      :items="minListPriceOptions"
                      item-text="name"
                      return-object
                      v-on:change="onChange('minListPrice',
                        {...$event, ...minListPriceConditions})"
                      label="Min Price"
                    />
                  </v-flex>
                  <v-flex xs6>
                    <v-select
                      :value="searchField('maxListPrice')"
                      :items="maxListPriceOptions"
                      item-text="name"
                      return-object
                      v-on:change="onChange('maxListPrice',
                        {...$event, ...maxListPriceConditions})"
                      label="Min Price"
                    />
                  </v-flex>
                  <v-flex xs6>
                    <v-select
                      :value="searchField('bedroomsTotal')"
                      :items="bedroomsTotalOptions"
                      item-text="name"
                      return-object
                      v-on:change="onChange('bedroomsTotal',
                        {...$event, ...bedroomsTotalConditions})"
                      label="Beds"
                    />
                  </v-flex>
                  <v-flex xs6>
                    <v-select
                      :value="searchField('bathroomsTotal')"
                      :items="bathroomsTotalOptions"
                      item-text="name"
                      return-object
                      v-on:change="onChange('bathroomsTotal',
                        {...$event, ...bathroomsTotalConditions})"
                      label="Baths"
                    />
                  </v-flex>
                  <v-flex xs6>
                    <v-select
                      :value="searchField('buildingAreaTotal')"
                      :items="buildingAreaTotalOptions"
                      item-text="name"
                      return-object
                      v-on:change="onChange('buildingAreaTotal',
                        {...$event, ...buildingAreaTotalConditions})"
                      label="Square Feet"
                    />
                  </v-flex>
                  <v-flex xs6>
                    <v-select
                      :value="searchField('daysOnMarket')"
                      :items="daysOnMarketOptions"
                      item-text="name"
                      return-object
                      v-on:change="onChange('daysOnMarket',
                        {...$event, ...daysOnMarketConditions})"
                      label="Days Listed"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <ld-select-input
                      :options="standardStatusOptions"
                      title="Status"
                      :selected="searchField('standardStatus')"
                      outline
                      multiple
                      @onInput="onChange('standardStatus', $event)"
                    />
                  </v-flex>
                </v-layout>
              </v-list>
            </template>
          </ld-search-filter>
        </template>
      </mls-search>
      <v-progress-linear v-if="isLoading" :indeterminate="true"></v-progress-linear>
      <v-flex :class="wrapperClass()">
        <mls-listings
          :columnSize="columnSize()"
          :itemsPerRow="itemsPerRow"
          :onItemClicked="onItemClicked"
          :itemsPerPage="itemsPerPage"
          :page="page"
          :mlsNumberSelected="mlsNumberSelected"
          :paginationTotalVisible="paginationTotalVisible"
        ></mls-listings>
      </v-flex>
      <v-flex xs5 v-if="map">
        <mls-map :polygon="polygon" :onItemClicked="onItemClicked"/>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

import MlsListings from './MlsListingsContainer';
import MlsSearch from './MlsSearchContainer';
import MlsMap from './MlsMapContainer';
import LdSearchFilter from 'lion-vue-src/_globals/lib/LdSearchFilter';
import MlsStaticSearchFilterMixins from '../mixins/MlsStaticSearchFilterMixins';
import LdSelectInput from 'lion-vue-src/_globals/lib/LdSelectInput';

export default {
  components: {
    MlsListings,
    MlsSearch,
    MlsMap,
    LdSearchFilter,
    LdSelectInput,
  },
  data() {
    return {
      standardStatus: [],
      activeConditions: this.initialActiveConditions,
    };
  },
  props: {
    polygon: {
      type: Boolean,
      default: false,
    },
    map: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 24,
    },
    page: {
      type: Number,
      default: 1,
    },
    mlsNumberSelected: {
      type: String,
      default: null,
    },
    paginationTotalVisible: {
      type: Number,
      default: 15,
    },
    searchPlaceholder: {
      type: String,
      default: 'Search Listings',
    },
    advancedSearch: {
      type: Boolean,
      default: true,
    },
    selectedFilter: {
      type: Object,
      default: () => {},
    },
    savedFilters: {
      type: Array,
      default: () => [],
    },
    availableFilterFields: {
      type: Array,
      default: () => [],
    },
    onSaveFilter: {
      type: Function,
      default: () => {},
    },
    onApplyFilter: {
      type: Function,
      default: () => {},
    },
    itemsPerRow: {
      type: Number,
      default: 1,
      validator(itemsPerRow) {
        return [1, 2, 3, 4].indexOf(itemsPerRow) > -1;
      },
    },
    onItemClicked: {
      type: Function,
      default: item => {
        // eslint-disable-next-line no-alert
        alert('Calback not implemented for Item Clicked. [MlsSearchContainer.vue]');
      },
    },
    analytics: {
      type: Function,
      default: () => {},
    },
  },
  mixins: [MlsStaticSearchFilterMixins],
  methods: {
    applyFilter(name, conditions) {
      this.onApplyFilter(name, this.sanitizedConditons(conditions));
    },
    saveFilter(name, conditions) {
      this.onSaveFilter(name, this.sanitizedConditons(conditions));
    },
    columnSize() {
      // eslint-disable-next-line default-case
      switch (this.itemsPerRow) {
        case 1:
          return 'xs12';
        case 2:
          return 'xs6';
        case 3:
          return 'xs4';
        case 4:
          return 'xs2';
      }
    },
    wrapperClass() {
      return this.map ? 'xs7' : '';
    },
  },
  computed: {
    ...mapState({
      isLoading: state => state.listing.isLoading,
    }),
  },
};
</script>
<style lang="scss">
// .mls-card-title {
//   text-align: center;
// }
// .mls-card-footer {
//   text-align: center;
// }
// .listing-container {
//   padding: 0px;
// }
// .search-container {
//   padding: 0px;
// }
//
// .list-card-container {
//   padding: 0px;
// }
//
// .mls-search-container {
//   margin-top: 0px;
//   padding: 0px;
// }
//
// .mls-wizard {
//   padding-top: 0px;
// }
//
// .mls-item-selected {
//   background-color: #e6e2e2;
// }
</style>
