/*
 * @Author: Kleber Pinel Bernardo da Silva
 * @Date:   2019-01-11 09:17:23
 * @Last modified by:   hugodorea
 * @Last modified time: 2019-03-28T13:03:04-07:00
 */

// Initialize wizard steps
export const initialTransactionSetupSummary = {
  key: 'transaction-setup',
  form: false,
  header: 'Setup Transaction',
  data: [],
  done: false,
};

/** DO NOT REMOVE THIS COMMENTED OUT CODE
 * This section is being omited for now and will come Back
 * later when the MLS scope is done
 * **/

// export const initialTransactionPropertySummary = {
//   key: 'transaction-property',
//   form: false,
//   header: 'Property Details',
//   data: [],
//   done: false,
// };

// export const initialTransactionCampaignSummary = {
//   key: 'transaction-campaign',
//   form: false,
//   header: 'Campaigns',
//   data: [],
//   done: false,
// };

export const initialTransactionContactsSummary = {
  key: 'transaction-contacts',
  form: false,
  header: 'Contacts',
  data: [],
  done: false,
};

export default {
  dialog: true,
  currentStage: 'transaction-setup',
  summaryValues: [],
  // Initialize wizard steps
  transactionSetupSummary: { ...initialTransactionSetupSummary },
  /** DO NOT REMOVE THIS COMMENTED OUT CODE
   * This section is being omited for now and will come Back
   * later when the MLS scope is done
   * **/
  // transactionPropertySummary: { ...initialTransactionPropertySummary },
  // transactionCampaignSummary: { ...initialTransactionCampaignSummary },
  transactionContactsSummary: { ...initialTransactionContactsSummary },
  // Field validation rules
  marketRules: [v => !!v || 'Please select a market'],
  titleRules: [v => !!v || 'Please enter a title'],
  typeRules: [v => !!v || 'Please select a transaction type'],
  stageRules: [v => !!v || 'Please select a stage'],
  // Field validation errors
  actionError: false,
  actionErrorMessage: '',
  actionSuccess: false,
  actionSuccessMessage: '',
  // Select menu values
  campaignOptions: [],
  marketOptions: [],
  roleOptions: [],
  selectedContactsOptions: [],
  selectedContactRoles: [],
  stageOptions: [],
  typeOptions: [],
  // Selected values
  selectedCampaignId: null,
  selectedContactId: null,
  // Logic values
  disableCampaigns: true,
  disableRoles: true,
  disableEdits: false,
  prevDisabled: false,
  mlsCurrentPage: 1,
  mlsNumberSelected: null,
  nextDisabled: true,
  startCampaignDisabled: true,
  submitDisabled: false,
  submitLoading: false,
  activeStage: null,
};
