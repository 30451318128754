/*
 * @Author: Ethan Dinnen
 * @Date:   2018-11-30 11:03:26
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-02-05 17:33:29
 */

import router from 'router';

const reset = ({ commit }) => commit('reset');

const goBack = ({ commit }) => {
  commit('reset');
  window.history.length > 1 ? router.go(-1) : router.push('/');
};

const createForms = ({ commit }, forms) => {
  commit('createForms', forms);
};

const updateField = ({ commit, dispatch, rootState }, field) => {
  commit('updateField', { field, rootState });
  dispatch('updatePercentage', { index: field.index, name: field.name });
};

const updatePercentage = (
  { commit, dispatch, rootState, state },
  { index, name }
) => {
  commit('calculatePercentage', { rootState, index });
  dispatch(
    'transactions/updateSectionPercentage',
    { name, value: state.percentage },
    { root: true }
  );
};

const cancel = ({ dispatch }) => {
  dispatch('goBack');
};

// API related actions
const setTemplates = async ({ commit, rootState }, values) => {
  const { property, forms } = values;
  commit('setTemplates', {
    templates: forms,
    property,
    rootState,
  });
};

export default {
  reset,
  goBack,
  createForms,
  updateField,
  updatePercentage,
  cancel,
  setTemplates,
};
