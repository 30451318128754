<!--
@Author: Kleber Pinel <kleberdasilva>
@Date:   2019-03-19T11:20:17-07:00
@Email:  kleber@hackcapital.com
@Last modified by:   kleberdasilva
@Last modified time: 2019-03-22T16:58:05-07:00
-->
<template lang="pug">
  v-dialog(v-model="visible" persistent width='480px')
    v-card
      title-bar(dark) Insert LionDesk Content
      v-card-text(data-cy="liondesk-content-modal")
        v-select(
          data-cy="contact-agent-content"
          label="Select contact or agent info"
          v-model="selectedValue"
          :items="contactUserInfo"
        )
        v-select(
          data-cy="custom-field-content"
          label="Select a custom field"
          v-model="selectedValue"
          disabled
        )
      v-card-actions
        v-spacer
        v-btn(
          flat
          data-cy="liondesk-content-cancel"
          @click="$emit('close')"
        ) Cancel
        v-btn.primary(
          data-cy="liondesk-content-submit"
          @click="insertValue"
          :disabled="!selectedValue"
        ) Insert
</template>

<script>
import { LIONDESK_CUSTOM_CONTENTS } from '../../config/custom-content';
import TitleBar from '@/Core/components/TitleBar';

export default {
  components: {
    TitleBar,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    visible(newValue) {
      this.selectedValue = null;
    },
  },
  data() {
    return {
      contactUserInfo: LIONDESK_CUSTOM_CONTENTS,
      selectedValue: null,
    };
  },
  methods: {
    insertValue() {
      this.$emit('insert', this.selectedValue);
    },
  },
};
</script>
