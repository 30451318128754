<!--
                @Author: Ethan Dinnen
                @Date:   2018-12-18 15:00:57
  @Last modified by:   hugodorea
  @Last modified time: 2019-03-28T13:14:16-07:00
-->
<template>
  <FlowLayout v-if="renderReady && this.formSummaries.length">
    <template slot="header">
      <v-btn flat="flat" @click="goBack()" class="back-button">
        <v-icon>keyboard_arrow_left</v-icon> Back
      </v-btn>
    </template>
    <template>
      <v-layout class="pt-5" justify-center="justify-center">
        <TransactionSummary
          v-model="currentStage"
          :summaryValues="summaryValues"
          :editDisabled="disableEdits"
          summaryTitle="Edit Transaction"
        />
        <TransactionSetup
          v-if="currentStage === 'transaction-setup'"
          :market="market"
          :title="title"
          :type="type"
          :stage="stage"
          :markets="marketOptions"
          :currentMarket="currentMarket"
          :handleMarketChange="getTransactionTypes"
          :updateTitle="updateTitle"
          :transactionTypes="typeOptions"
          :updateType="updateType"
          :updateStage="updateStage"
          :stages="stageOptions"
          :marketRules="marketRules"
          :titleRules="titleRules"
          :typeRules="typeRules"
          :stageRules="stageRules"
          :validateSetup="validateSetup"
          :renderReady="renderReady"
          :typeLocked="true"
          @stage-edited="handleStageChange"
        />
        <!--
          DO NOT REMOVE THIS COMMENTED OUT CODE
          This section is being omited for now and will come Back
          later when the MLS scope is done
        -->
        <!--
          <TransactionProperty
            v-if="currentStage === 'transaction-property'"
            :handlePropertyChoosen="updateMlsProperty"
            :mlsCurrentPage="mlsCurrentPage"
            :mlsNumberSelected="mlsNumberSelected"
          />
        -->
        <!-- eslint-disable -->
        <TransactionForm
          v-for="(form, index) in transaction.transaction_forms"
          v-if="currentStage === `transaction-${index}`"
          :key="`transaction-${index}`"
          :form="form"
          :market="market"
          :type="type"
          :property="property"
          :updateSummary="handleFormSummary"
          :reAssignSummaryValues="reAssignSummaryValues"
          editing
        />
        <!-- eslint-enable -->
        <TransactionContacts
          v-if="currentStage === 'transaction-contacts'"
          :searchContacts="searchTeammateSelfContacts"
          :addContact="addContact"
          :addNewContact="addNewContact"
          :contacts="contacts"
          :selectedContacts="allContacts"
          :removeContact="handleRemoveContact"
          :getRoles="getRoles"
          :roles="roleOptions"
          :role="role"
          :setRole="setRole"
        />
        <!--
          <TransactionCampaign
            v-if="currentStage === 'transaction-campaign'"
            :key="`campaign-${this.transactionCampaignSummary.data.length}`"
            :campaigns="campaignOptions"
            :handleCampaignSelect="handleCampaignSelect"
            :selectedContacts="selectedContactsOptions"
            :handleCampaignContact="handleCampaignContact"
            :roleOptions="selectedContactRoles"
            :disableRoles="disableRoles"
            :disableCampaigns="disableCampaigns"
            :startCampaignDisabled="startCampaignDisabled"
            :handleStartCampaign="handleStartCampaign"
            :initializeCampaignsSummary="initializeCampaignsSummary"
            :setDone="setCampaignsDone"
          />
        -->
      </v-layout>
    </template>
    <template slot="footer">
      <PrevNextStepper
        :items="summaryValues"
        :submitDisabled="nextDisabled || submitDisabled"
        :prevDisabled="prevDisabled"
        :submitLoading="submitLoading"
        :nextDisabled="nextDisabled"
        v-on:submit-clicked="confirmFinish"
        v-model="currentStage"
        objectKey="key"
        hasSubmit
      />
    </template>
    <StagePlanChangeModal
      :showStageChangeModal="stagePlanMixin_showStageChangeModal"
      :currentStageName="activeStageName"
      :nextStageName="nextStageName"
      :nextPlanName="nextPlanName"
      :currentPlanName="currentPlanName"
      :closeStageChangeModal="closeStageChangeModal"
      :confirmStageChange="handleFinish"
      :isLoading="stagePlanMixin_isLoading"
      >
    </StagePlanChangeModal>
  </FlowLayout>
  <v-layout v-else> <BreedingRhombusSpinner /> </v-layout>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import FlowLayout from '@/Core/layouts/FlowLayout';
import TransactionSummary from './components/CreateTransactionSummary';
import TransactionSetup from './components/CreateTransactionSetup';
import StagePlanChangeModal from './components/StagePlanChangeModal';
/** DO NOT REMOVE THIS COMMENTED OUT CODE
 * This section is being omited for now and will come Back
 * later when the MLS scope is done
 * **/
// import TransactionProperty from './components/CreateTransactionProperty';
// import TransactionCampaign from './components/CreateTransactionCampaign';
import TransactionForm from './components/CreateTransactionForm';
import TransactionContacts from './components/CreateTransactionContacts';
import PrevNextStepper from '@/Core/components/PrevNextStepper';
import formatCurrency from '@/Core/mixins/formatCurrency';
import BreedingRhombusSpinner from '@/Core/components/BreedingRhombusSpinner';
import initialValues from './components/wizard/InitialValues';
import computed from './components/wizard/Computed';
import methods from './components/wizard/Methods';
import watch from './components/wizard/Watch';
import StagePlanChangeModalMixin from '../Core/mixins/stage-plan-change-modal';

const {
  mapState: mapTransactionsState,
  mapActions: mapTransactionsActions,
} = createNamespacedHelpers('transactions');

const {
  mapState: mapViewState,
  mapActions: mapViewActions,
} = createNamespacedHelpers('viewTransaction');

const {
  mapState: mapAppState,
} = createNamespacedHelpers('ldAppLayout');

export default {
  components: {
    FlowLayout,
    TransactionSummary,
    TransactionSetup,
    /** DO NOT REMOVE THIS COMMENTED OUT CODE
     * This section is being omited for now and will come Back
     * later when the MLS scope is done
     * **/
    // TransactionProperty,
    // TransactionCampaign,
    TransactionForm,
    TransactionContacts,
    PrevNextStepper,
    BreedingRhombusSpinner,
    StagePlanChangeModal,
  },
  mixins: [formatCurrency],
  data() {
    return {
      ...initialValues,
      nextDisabled: false,
    };
  },
  mixins: [StagePlanChangeModalMixin],
  computed: {
    ...computed,
    ...mapTransactionsState([
      'title',
      'type',
      'stage',
      'property',
      'transaction',
      'market',
      'markets',
      'transactionTypes',
      'stages',
      'contacts',
      'selectedContacts',
      'setupValid',
      'roles',
      'role',
      'newContacts',
      'campaigns',
      'validContactInfo',
      'renderReady',
      'templates',
      'formSummaries',
    ]),
    ...mapViewState(['plans']),
    ...mapAppState(['userInfo']),
  },
  methods: {
    ...methods,
    ...mapTransactionsActions([
      'reset',
      'resetFinancials',
      'goBack',
      'getTransactionTypes',
      'getTransaction',
      'getMarkets',
      'getStages',
      'createTransactions',
      'updateTitle',
      'updateType',
      'updateStage',
      'updateProperty',
      'updateMarket',
      'searchContacts',
      'searchTeammateSelfContacts',
      'addContact',
      'removeContact',
      'validateSetup',
      'getRoles',
      'setRole',
      'addNewContact',
      'getCampaigns',
      'assignCampaign',
      'saveTransaction',
      'updateTransaction',
      'getTemplates',
      'updateFinancialsData',
      'resetFinancialsData',
      'setFormDone',
      'setForms',
      'moveStages',
    ]),
    ...mapViewActions(['getPlans']),
  },
  watch: {
    ...watch,
  },
  // Reset everything and retrieve select menu options
  async beforeMount() {
    const transactionId = this.$route.params.id;

    this.submitLoading = false;
    this.currentStage = 'transaction-setup';
    this.reset();
    this.resetSummaries();
    this.resetFinancials();

    await this.getTransaction(transactionId);
    await this.getPlans(this.type);

    this.typeOptions = this.selectFormat(this.transactionTypes);
    this.roleOptions = this.selectFormat(this.roles);
    this.marketOptions = this.selectFormat(this.markets);
    this.campaignOptions = this.selectFormat(this.campaigns);
  },
  // Initialize steps
  mounted() {
    this.setSummaryDone();
    this.reAssignSummaryValues();
  },
  beforeDestroy() {
    this.reset();
  },
};
</script>

<style lang="scss">
.back-button {
  color: rgba(0, 0, 0, 0.6) !important;
  .text {
    font-size: 12px;
  }
  i {
    font-size: 19px;
  }
}
</style>
