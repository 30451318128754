/*
 * @Author: Ethan Dinnen
 * @Date:   2019-02-20 13:00:03
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-02-20 13:00:33
 */

import cuid from 'cuid';

export const genId = () => cuid();

export default {
  methods: {
    genId,
  },
};
