<!--
@Author: Ethan Dinnen
@Date:   2018-12-20 10:05:29
@Last Modified by:   Ethan Dinnen
@Last Modified time: 2019-02-20 13:01:15
-->

<template>
  <v-flex
    v-if="addExisting"
    class="xs12 md4 contacts-container wizard-container"
    id="wizard-watch-height"
  >
    <span class="contacts-container__title">Contacts</span>
    <AutoCompleteInput
      :key="autoCompleteKey"
      class="contact-search"
      type="contacts"
      placeholder="Find a contact"
      :onInput="searchContacts"
      :items="contacts"
      :updateItem="addContactHandler"
      :appendIcon="''"
      :prependIcon="'search'"
      solo
    />
    <v-btn
      class="add-contact"
      flat
      color="primary"
      @click="addExisting = false"
      key="new-btn"
    >
      Add New Contact
    </v-btn>
    <v-divider />
    <ContactList
      class="contact-list"
      :contacts="selectedContacts"
      :removeContact="removeContact"
      :roles="roles"
      :setRole="setRole"
    />
  </v-flex>
  <v-flex
    v-else
    class="xs12 md4 create-new-container wizard-container"
    id="wizard-watch-height"
  >
    <v-btn
      class="back-button"
      flat
      color="primary"
      @click="addExisting = true"
      key="back-btn"
    >
      <v-icon>chevron_left</v-icon> Back to Contacts
    </v-btn>
    <v-divider class="pb-3" />
    <h2 class="add-new-header">Add New Contact</h2>
    <SelectInput
      :options="roles"
      title="Role"
      placeholder="Select a role"
      @onInput="selectedRole = $event"
      icon="expand_more"
    />
    <section>
      <h3>Personal Information</h3>
      <TextInput
        title="First Name"
        placeholder="Gopi"
        @onInput="handleUpdateNew"
        fieldId="first_name"
      />
      <TextInput
        title="Family Name"
        placeholder="Patel Gaud"
        @onInput="handleUpdateNew"
        fieldId="last_name"
      />
      <v-menu
        :close-on-content-click="false"
        v-model="birthdayMenu"
        :nudge-right="0"
        lazy
        transaction="scale-transation"
        offset-y
        full-width
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          v-model="birthday"
          label="Date of Birth"
          append-icon="event"
          readonly
        />
        <v-date-picker
          v-model="birthday"
          @input="
            menu = false;
            handleUpdateNew({ value: birthday, fieldId: 'birthday' });
          "
        />
      </v-menu>
      <TextInput
        title="Company"
        placeholder="Liondesk"
        @onInput="handleUpdateNew"
        fieldId="company"
      />
    </section>
    <section>
      <h3>Address</h3>
      <SelectInput
        :options="typeOptions"
        title="Type"
        @onInput="handleUpdateAddress"
        fieldId="type"
        selected="Home"
        icon="expand_more"
      />
      <TextInput
        title="Street Address Line 1"
        placeholder="1234 Main Street"
        @onInput="handleUpdateAddress"
        fieldId="street_address_1"
      />
      <TextInput
        title="Street Address Line 2"
        @onInput="handleUpdateAddress"
        fieldId="street_address_2"
      />
      <TextInput
        title="Zip Code / Postal Code"
        placeholder="75482"
        @onInput="handleUpdateAddress"
        fieldId="zip"
      />
      <TextInput
        title="City"
        placeholder="Sulphur Springs"
        @onInput="handleUpdateAddress"
        fieldId="city"
      />
      <TextInput
        title="State / Province"
        placeholder="State / Province"
        @onInput="handleUpdateAddress"
        fieldId="state"
      />
    </section>
    <section>
      <h3>Contact Information</h3>
      <TextInput
        title="Email"
        placeholder="gopi.patelg@liondesk.com"
        @onInput="handleUpdateNew"
        fieldId="email"
      />
      <TextInput
        title="Mobile Phone"
        placeholder="1 (604) 555-1124"
        @onInput="handleUpdateNew"
        fieldId="mobile_phone"
      />
      <TextInput
        title="Home Phone"
        placeholder="1 (604) 555-1124"
        @onInput="handleUpdateNew"
        fieldId="home_phone"
      />
      <TextInput
        title="Office Phone"
        placeholder="1 (604) 555-1124"
        @onInput="handleUpdateNew"
        fieldId="office_phone"
      />
      <TextInput
        title="Fax"
        placeholder="1 (604) 555-1124"
        @onInput="handleUpdateNew"
        fieldId="fax"
      />
    </section>
    <section>
      <h3>Spouse Information</h3>
      <TextInput
        title="Spouse Name"
        @onInput="handleUpdateNew"
        fieldId="spouse_name"
      />
      <TextInput
        title="Spouse Email"
        @onInput="handleUpdateNew"
        fieldId="spouse_email"
      />
      <TextInput
        title="Spouse Phone"
        placeholder="1 (234) 567-8910"
        @onInput="handleUpdateNew"
        fieldId="spouse_phone"
      />
      <v-menu
        :close-on-content-click="false"
        v-model="anniversaryMenu"
        :nudge-right="0"
        lazy
        transaction="scale-transation"
        offset-y
        full-width
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          v-model="anniversaryDate"
          label="Anniversary Date"
          append-icon="event"
          readonly
        />
        <v-date-picker
          v-model="anniversaryDate"
          @input="
            menu = false;
            handleUpdateNew({
              value: anniversaryDate,
              fieldId: 'anniversary',
            });
          "
        />
      </v-menu>
      <v-menu
        :close-on-content-click="false"
        v-model="spouseBirthdayMenu"
        :nudge-right="0"
        lazy
        transaction="scale-transation"
        offset-y
        full-width
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          v-model="spouseBirthday"
          label="Spouse's Birthday"
          append-icon="event"
          readonly
        />
        <v-date-picker
          v-model="spouseBirthday"
          @input="
            menu = false;
            handleUpdateNew({
              value: spouseBirthday,
              fieldId: 'spouse_birthday',
            });
          "
        />
      </v-menu>
    </section>
    <section>
      <h3>Source</h3>
      <TextInput
        title="Source"
        placeholder="Lead"
        @onInput="handleUpdateNew"
        fieldId="source"
      />
    </section>
    <v-btn class="primary add-button" @click="handleAddNew">Add Contact</v-btn>
  </v-flex>
</template>

<script>
import AutoCompleteInput from '@/Core/components/AutoCompleteInput';
import ContactList from './ContactList';
import SelectInput from '@/Core/components/SelectInput';
import TextInput from '@/Core/components/TextInput';
import genId from '@/Core/mixins/genId';

const newContactFixture = {
  first_name: '',
  last_name: '',
  email: null,
  roles: [],
  campaigns: [],
  mobile_phone: '',
  home_phone: '',
  office_phone: '',
  fax: '',
  company: '',
  birthday: '',
  anniversary: '',
  spouse_name: '',
  spouse_email: '',
  spouse_phone: '',
  spouse_birthday: '',
  addresses: [
    {
      type: 'Home',
      created_at: new Date().toISOString(),
      modified_at: new Date().toISOString(),
    },
  ],
  source: {},
  custom_fields: [],
};

export default {
  components: {
    AutoCompleteInput,
    ContactList,
    SelectInput,
    TextInput,
  },
  props: {
    contacts: Array,
    selectedContacts: Array,
    searchContacts: Function,
    addContact: Function,
    addNewContact: Function,
    removeContact: Function,
    getRoles: Function,
    roles: Array,
    role: Array,
    setRole: Function,
  },
  mixins: [genId],
  data() {
    return {
      key: 0,
      model: '',
      addExisting: true,
      selectedRole: null,
      selected: null,
      birthdayMenu: false,
      birthday: null,
      anniversaryMenu: false,
      anniversaryDate: null,
      spouseBirthdayMenu: false,
      spouseBirthday: null,
      scrollHint: false,
      typeOptions: [
        { text: 'Home', value: 'Home' },
        { text: 'Main Office', value: 'Main Office' },
        { text: 'Branch Office', value: 'Branch Office' },
        { text: 'Second Home', value: 'Second Home' },
        { text: 'Investment', value: 'Investment' },
        { text: 'Mailing', value: 'Mailing' },
      ],
      newContact: Object.assign({}, newContactFixture),
      disableRole: true,
      contactToAdd: null,
      lastContactIndex: null,
      autoCompleteKey: this.genId(),
    };
  },
  methods: {
    // Update the specified field in the newContact object
    handleUpdateNew(update) {
      if (update.fieldId === 'source') {
        this.newContact.source.name = update.value;
        return;
      }
      this.newContact[update.fieldId] = update.value;
    },
    // Update the address for the newContact object
    handleUpdateAddress(update) {
      this.newContact.addresses[0] = Object.assign(
        this.newContact.addresses[0],
        { [update.fieldId]: update.value }
      );
    },
    // Add the new contact, re-initialize the data, and switch to first tab
    handleAddNew() {
      this.newContact.role = this.selectedRole;
      this.addNewContact(this.newContact);
      this.setRole({ contact: this.newContact, role: this.selectedRole });
      this.newContact = Object.assign({}, newContactFixture);
      this.key = this.key + 1;
      this.birthday = null;
      this.anniversaryDate = null;
      this.spouseBirthday = null;
      this.disableRole = true;
      this.addExisting = true;
    },
    // Associate an existing contact, and scroll the view to top if necessary
    addContactHandler(value) {
      this.addContact(value);
      this.contactToAdd = value;
      this.lastContactIndex = this.selectedContacts.length - 1;
      this.autoCompleteKey = this.genId();
    },
    handleSetRoles(value) {
      this.setRole(value);
    },
  },
  watch: {
    // Set the newContact object's roles
    role: {
      handler(value) {
        if (this.selected === 'existing') return; // Ensure existing contact role selections aren't applied to a new contact
        this.newContact.roles = value.map(item => {
          const title = this.roles.find(role => role.value === item).text;
          return {
            role_id: item,
            title,
          };
        });
      },
    },
    /**
     * Scroll the new contact container to hint
     * to the user that the container can scroll
     */
    selected: {
      handler(value) {
        if (value !== 'new') return;
        // Only show the animation if the user hasn't seen it yet
        if (!this.scrollHint) {
          this.scrollHint = true;
          const container = document.querySelector('.items-container');
          setTimeout(() => {
            container.scrollBy({ top: 150, behavior: 'smooth' });
          }, 200);
          setTimeout(() => {
            container.scroll({ top: 0, behavior: 'smooth' });
          }, 600);
        }
      },
    },
  },
};
</script>

<style lang="scss">
.wizard-container {
  padding-top: 3px;
}
.contacts-container {
  position: relative;
  padding: 0 40px;
  &__title {
    color: #a4a4a4;
    font-family: 'Open Sans';
    font-size: 16px;
    line-height: 22px;
  }
  .contact-search {
    padding: 0 1px;
    margin-top: 16px;
    max-height: 48px;
  }
  .add-contact {
    position: absolute;
    padding: 0 6px;
    top: 90px;
    left: 27px;
    font-family: 'Open Sans';
    font-size: 17px;
    font-weight: 600;
    line-height: 23px;
    text-transform: unset;
  }
  .v-divider {
    position: relative;
    top: 50px;
  }
  .contact-list {
    margin-top: 52px;
  }

  .items-container {
    height: 325px;
    overflow-x: scroll;
    .v-menu__content {
      min-width: 315px !important;
    }
  }
  .contact-tabs {
    max-width: 338px;
    margin: 0 auto;
    .v-tabs__bar {
      background-color: transparent;
      .v-tabs__slider {
        height: 4px;
      }
      .v-tabs__item {
        &:not(.v-tabs__item--active) {
          border-bottom: 3.5px solid grey;
          padding-bottom: 3px; // Offset the padding by the bottom border
        }
      }
    }
  }
  .v-window {
    max-width: 320px;
    margin: 15px auto 0;
  }
}
.back-button {
  padding: 0 5px 0 0 !important;
  margin-left: 0;
}
.create-new-container {
  max-height: calc(100vh - 79px);
  overflow-x: scroll;
  section {
    margin-top: 44px;
    h3 {
      font-family: 'Open Sans';
      font-size: 18px;
      line-height: 24px;
    }
  }
  .add-new-header {
    color: #000000;
    font-family: 'Nunito Sans';
    font-size: 20px;
    line-height: 27px;
    margin-top: 18px;
    margin-bottom: 10px;
  }
  .v-input {
    label {
      font-family: 'Open Sans';
      font-size: 18px;
      top: 3px;
    }
  }
  .add-button {
    float: right;
    margin-right: 0;
  }
}
</style>
