import axios from 'axios';
import { HC_PROPS } from 'lion-vue-src/_globals/routes';

export const getEmailAccounts = async data => {
  const ret = await axios({
    method: 'GET',
    url: `${HC_PROPS.NYLAS_API_BASE_URL}/email-accounts/`,
  });
  return ret.data;
};
