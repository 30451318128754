<template>
  <svg 
    aria-hidden="true"
    focusable="false"
    data-icon="newspaper-variant"
    role="img"
    viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M2 4V18C2 18 2 20 4 20H20C20 20 22 20 22 18V4H2M9 13H5V7H9V13M19 13H11V11H19V13M19 9H11V7H19V9Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '$vuetify.icons.newspaper-variant',
    },
  },
};
</script>