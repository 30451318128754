import LdPlans, { mlsPlans } from 'lion-vue-src/config/plans';

const MLS_PLAN = 'mls';

const setFeatures = (state, value) => {
  state.features = { ...value.features };
};

const setPlan = (state, value) => {
  // default plan to Trial
  state.plan = LdPlans.Trial;
  if (value.subscriptionOverview && value.subscriptionOverview.txt_plan) {
    const providedPlan = value.subscriptionOverview.txt_plan;
    if (mlsPlans.includes(providedPlan)) {
      state.plan = MLS_PLAN;
    } else if (Object.values(LdPlans).includes(providedPlan)) {
      state.plan = providedPlan;
    } else {
      console.debug(`"${providedPlan}" is not a known plan. Using "${state.plan}" as default.`);
    }
  }
};

const setPaymentConfigured = (state, value) => {
  state.paymentConfigured = false;
  if (value.subscriptionOverview) {
    // TODO ak: confirm this logic for determining
    // whether the user has a valid credit card on file
    //
    state.paymentConfigured =
      value.subscriptionOverview.txt_status === 'subscribed' &&
      !mlsPlans.includes(value.subscriptionOverview.txt_plan);
  }
};

const setEmailVerified = (state, value) => {
  state.emailVerified = value.email_is_verified;
};

const setHasCustomNumber = (state, value) => {
  state.hasCustomNumber = value.custom_number.length > 0;
};

const setFeatureGate = (state, value) => {
  state.featureGate = value;
};

const setMeteredUsageKey = (state, value) => {
  state.featureMeteredUsageKey = value;
};

const setMeteredUsageAcceptedKey = (state, value) => {
  state.featureMeteredUsageAcceptedKey = value;
};

const setAddonPurchaseResult = (state, value) => {
  state.addonPurchaseResult = value;
};

const setAddonPurchaseRequested = (state, value) => {
  state.addonPurchaseRequested = value;
};

const setFeatureSubType = (state, value) => {
  state.featureSubType = value;
};

export default {
  setAddonPurchaseResult,
  setEmailVerified,
  setFeatureGate,
  setFeatures,
  setHasCustomNumber,
  setMeteredUsageAcceptedKey,
  setMeteredUsageKey,
  setPaymentConfigured,
  setPlan,
  setAddonPurchaseRequested,
  setFeatureSubType,
};
