<template>
    <div class="mls-gallery" :style="galleryStyle">
        <figure itemprop="associatedMedia" itemscope itemtype="http://schema.org/ImageObject" :style="figureStyle">
            <div class="spinner">
                <fingerprint-spinner
                  :animation-duration="1500"
                  :size="60"
                  color="#3878c1"
                  v-if="isLoading"
                />
            </div>
            <img
              ref="mainImage"
              :src="currentImage"
              @click="handleLargeImageClick"
              :class="{ loading: isLoading }"
              v-touch:swipe.left="showPreviousImage"
              v-touch:swipe.right="showNextImage"
            />
            <div class="gallery_caption" v-if="currentCaption" :style="captionStyle">
                {{ currentCaption }}
            </div>
            <a
              class="control left"
              @click="showPreviousImage"
              v-if="!leftControlClass"
            >
              <span style="position:relative;top:calc(50% - 12px)">&#9664;</span>
            </a>
            <a
              class="control right"
              @click="showNextImage"
              v-if="!rightControlClass"
            >
              <span style="position:relative;top:calc(50% - 12px)">&#9654;</span>
            </a>
            <a
              :class="'control left ' + leftControlClass"
              @click="showPreviousImage"
              v-if="leftControlClass"
            ></a>
            <a
              :class="'control right ' + rightControlClass"
              @click="showNextImage"
              v-if="rightControlClass"
            ></a>
        </figure>
        <div id="gallery_thumbnails" class="gallery_thumbnails" v-if="showThumbnails">
          <v-btn
            id="gallery_expand_btn"
            icon
            @click="handleThumbnailToggle"
          >
            <v-icon>arrow_drop_down_circle</v-icon>
          </v-btn>
          <div class="gallery_thumbnails_content">
            <div
              v-for="(item, index) in items"
              class="gallery_thumbnails_content_elem"
              :key="index"
            >
                <img
                  :id="`img-${index}`"
                  :src="item.thumbnail"
                  v-on="currentIndex !== index ? { click: () => handleImageClick(index) } : {}"
                  height="64"
                  :style="thumbnailStyle(index)"
                />
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import { FingerprintSpinner } from 'epic-spinners';

export default {
  name: 'MLSGallery',
  data() {
    return {
      currentImage: null,
      currentIndex: 0,
      currentCaption: '',
      windowWidth: 0,
      isLoading: true,
      expanded: false,
    };
  },
  props: {
    items: {
      default: [
        {
          src: 'https://picsum.photos/600/400/?image=0',
          thumbnail: 'https://picsum.photos/64/64/?image=0',
          caption: '',
        },
      ],
    },
    startImage: {
      type: Number,
      default: 0,
    },
    width: {
      type: Number,
      default: 600,
    },
    height: {
      type: Number,
      default: 400,
    },
    baseColor: {
      type: String,
      default: '#fff',
    },
    accentColor: {
      type: String,
      default: '#3878c1',
    },
    textColor: {
      type: String,
      default: '#000',
    },
    responsive: {
      type: Boolean,
      default: false,
    },
    showThumbnails: {
      type: Boolean,
      default: true,
    },
    mobileHeight: {
      type: Number,
      default: 0,
    },
    mobileHeightBreakpoint: {
      type: Number,
      default: 0,
    },
    leftControlClass: {
      type: String,
      default: '',
    },
    rightControlClass: {
      type: String,
      default: '',
    },
    disableImageClick: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FingerprintSpinner,
  },
  computed: {
    galleryStyle() {
      return this.windowWidth > this.width && !this.responsive
        ? `width:${this.width}px`
        : 'width:100%';
    },
    captionStyle() {
      return `color:${this.textColor}`;
    },
    figureStyle() {
      if (this.currentImage) {
        return this.getImageSize(this.currentImage)
          .then(result => {
            let heightValue = 'height:auto';

            // Hide Loader
            this.handleLoader(false);

            if (
              result.widthValue < result.heightValue
              && this.mobileHeight === 0
            ) {
              heightValue = `height:${this.height}px;`;
            }
            return this.windowWidth > this.width && !this.responsive
              ? `height: ${this.height};`
              : `width:100%;${
                heightValue
              }`;
          })
          .catch();
      } return this.windowWidth > this.width && !this.responsive
        ? `width:${
          this.width
        }px;height:${
          this.height
        }px;`
        : 'width:100%;height:auto;';
    },
  },
  methods: {
    handleImageClick(index) {
      this.currentIndex = index;
      this.pickImage(index);
    },
    handleLargeImageClick() {
      if (!this.disableImageClick) {
        this.showNextImage();
      }
    },
    async getImageSize(src) {
      const image = await this.getImage(src);
      return {
        widthValue: image.naturalWidth,
        heightValue: image.naturalHeight,
      };
    },
    async getImage(src) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = src;
      }).catch();
    },
    handleLoader(isLoading) {
      this.isLoading = isLoading;
    },
    pickImage(index) {
      // Show Loader
      this.handleLoader(true);

      this.currentImage = this.items[index].src;
      this.currentCaption = this.items[index].caption;
    },
    thumbnailStyle(index) {
      const color = this.currentIndex === index ? this.accentColor : this.baseColor;
      return `border-color:${color}`;
    },
    handleThumbnailToggle() {
      const container = document.getElementById('gallery_thumbnails');
      const btn = document.getElementById('gallery_expand_btn');
      if (this.expanded) {
        container.style.height = '85px';
        btn.style.transform = 'rotate(0deg)';
      } else {
        container.style.height = 'unset';
        btn.style.transform = 'rotate(180deg)';
      }
      this.expanded = !this.expanded;
    },
    showNextImage() {
      // Show Loader
      this.handleLoader(true);

      if (this.items.length > this.currentIndex + 1) {
        this.currentIndex = this.currentIndex + 1;
      } else {
        this.currentIndex = 0;
      }

      this.pickImage(this.currentIndex);
    },
    showPreviousImage() {
      // Show Loader
      this.handleLoader(true);

      if (this.currentIndex !== 0) {
        this.currentIndex = this.currentIndex - 1;
      } else {
        this.currentIndex = this.items.length - 1;
      }

      this.pickImage(this.currentIndex);
    },
  },
  mounted() {
    this.currentImage = this.items[this.startImage].src;
    this.currentCaption = this.items[this.startImage].caption;
    this.currentIndex = this.startImage;
    this.windowWidth = window.innerWidth;
  },
  watch: {
    items() {
      this.currentImage = this.items[this.startImage].src;
    },
  },
};
</script>

<style lang="scss">
.mls-gallery {
  max-width: 100%;
  figure {
    position: relative;
    margin: 0;
    padding: 0;
    max-width: 100%;
    text-align: center;
    cursor: pointer;
    background-color: white;
    img {
      max-width: 100%;
      transition: opacity 0.25s ease;
      vertical-align: middle;
      &.loading {
        opacity: 0.25;
        min-height: 200px;
        transition: opcacity 0.25s ease;
      }
    }
    a.control {
      position: absolute;
      top: 0;
      padding-left: 15px;
      padding-right: 15px;
      height: 98.5%;
      display: none;
      font-size: 20px;
      color: #fff;
      cursor: pointer;
      background-color: rgba(0,0,0,0.3);
      text-shadow: 0 0 20px rgba(0, 0, 0, 0.75);
      // Disable selection of control icons
      -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
         -khtml-user-select: none; /* Konqueror HTML */
           -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                      supported by Chrome and Opera */
      &:before {
        position: relative;
        top: calc(50% - 12px);
      }
      &.left {
        left: 0;
      }
      &.right {
        right: 0;
      }
    }
    &:hover {
      a.control {
        display: block;
      }
    }

  }
  .gallery_caption {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 4px 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    font-size: 1em;
  }
  .gallery_thumbnails {
    height: 85px;
    overflow-y: hidden;
    display: flex;
    flex-direction: row-reverse;
    #gallery_expand_btn {
      transition: all 0.3s cubic-bezier(.68,.62,.83,.67);
      z-index: 1;
      i {
        z-index: -1;
      }
      margin-top: 23px;
      margin-right: 28px;
    }
  }
  .gallery_thumbnails_content {
    display: flex;
    flex-wrap: wrap;
  }
  .gallery_thumbnails .gallery_thumbnails_content_elem {
    display: inline-block;
    margin-top: 10px;
    margin-right: 8px;
  }
  .gallery_thumbnails .gallery_thumbnails_content_elem img {
    border: 2px solid #fff;
    cursor: pointer;
  }
  .spinner {
    position: absolute;
    left: 50%;
    top: calc(50% - 32px);
  }
  .spinner > div {
    z-index: 9999;
    position: relative;
    left: -50%;
  }
}
</style>
