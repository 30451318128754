<!--
  @Author: Ethan Dinnen
  @Date:   2019-02-21 16:16:17
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-02-25 15:17:07
-->
<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    :return-value.sync="date"
    persistent
    lazy
    full-width
    width="460px"
  >
    <v-text-field
      slot="activator"
      v-model="date"
      :label="field.label"
      :prepend-icon="'calendar_today'"
    />
    <v-date-picker v-model="date" scrollable landscape>
      <v-spacer />
      <v-btn flat color="primary" @click="modal = false;">Cancel</v-btn>
      <v-btn flat color="primary" @click="handleSave">OK</v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      modal: false,
      date: this.default,
    };
  },
  methods: {
    handleSave() {
      this.$refs.dialog.save(this.date);
      this.emitInput(this.date);
    },
    getIcon(type) {
      return (
        {
          absolute: 'calendar_today',
          relative: 'date_range',
        }[type] || null
      );
    },
    emitInput() {
      this.$emit('onInput', { ...this.field, value: this.date });
    },
  },
};
</script>
