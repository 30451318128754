/*
 * @Author: Ethan Dinnen <ethan>
 * @Date:   2018-12-21 10:07:54
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-01-04 15:16:45
 */

import axios from 'axios';

/**
 * Searches a transaction for contacts
 * @param {number|string} id The transaction id
 * @param {string} query The query to search in first name fields
 * @return {array} The list of matches
 */
export const searchContacts = async (query = '') => {
  return await axios
    .get(`transactions/contacts?q=${query}`)
    .then(res => {
      return res.data.data;
    })
    .catch(err => {
      throw new Error(err.stack);
    });
};

export const searchTeammateSelfContacts = async (query = '') => {
  return await axios
    .get(`transactions/contacts/team?q=${query}`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.stack);
    });
};

/**
 * Associates a contact with a transaction
 * @param {number} transactionId The id of the transaction to associate with
 * @param {object} contact The contact object to associate
 * @return {obect} The stakeholder object now associated with the Tx
 */
export const associateContact = async (transactionId, contact) => {
  return await axios
    .post(`transactions/${transactionId}/contacts`, { contact })
    .then(({ body }) => body)
    .catch(err => {
      throw new Error(err.stack);
    });
};

/**
 * Retrieves all stakeholders on a transaction
 * @param {number} transactionId The id of the transaction
 * @return {array} The list of stakeholder objects associated with the Tx
 */
export const getStakeholders = async transactionId => {
  return await axios
    .get(`transactions/${transactionId}/contacts/stakeholders`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.stack);
    });
};

/**
 * Retrieves all stakeholder roles
 * @return {array} The list of possible stakeholder roles
 */
export const getRoles = async () => {
  return await axios
    .get('stakeholder_roles')
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.stack);
    });
};
