<!--
  @Author: Ethan Dinnen
  @Date:   2018-12-11 15:52:47
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-01-15 12:50:48
-->
<template lang="pug">
  tr.tx-data-table-data
    template(v-for='(text, key) in item')
      template(v-if='key !== "id"')
        td.text-xs-left(
          @click='expand'
          :key='key'
          )
          Item(:data='text')
    td.text-xs-left(
      v-if="taskActions"
      class="action-container"
    )
      v-btn(
        v-if="data.item.type.text === 'Task'"
        :class="['check-btn', completed ? 'completed' : '']"
        icon
        @click="handleToggleComplete"
      )
        v-icon check_circle
      v-btn(
        v-if="data.item.status.text !== 'Sent'"
        class="delete-btn"
        icon
        @click="dialog = true"
      )
        v-icon delete

    ld-confirmation(
      :value="dialog"
      width="580"
      risk="high"
      @click:cancel="dialog = false"
      @click:ok="handleDelete"
      confirmText="Delete Action"
    )
      template(slot="header")
        span Delete Action
      template(slot="body")
        span Are you sure you want to permanently delete this action?
</template>

<script>
import { LdConfirmation } from 'lion-vue';
import DataTableMenu from '@/Core/components/DataTableMenu';
import ListDataLogic from '@/Core/mixins/ListDataLogic';
import Item from './TableItem';

export default {
  components: {
    Item,
    DataTableMenu,
    LdConfirmation,
  },
  props: {
    data: {
      type: Object,
    },
    selected: {
      type: Array,
    },
    taskActions: {
      type: Boolean,
      default: false,
    },
    toggleComplete: {
      type: Function,
      default: () => {},
    },
    deleteTask: {
      type: Function,
      default: () => {},
    },
    deleteCommunication: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  mixins: [ListDataLogic],
  methods: {
    handleToggleComplete() {
      const { id, status } = this.item;
      this.toggleComplete({ id, status: status.text });
    },
    handleDelete() {
      if (this.item.type.text === 'Email') {
        this.deleteCommunication(this.item.id);
        this.dialog = false;
        return;
      }
      this.deleteTask(this.item.id);
      this.dialog = false;
    },
  },
  computed: {
    item() {
      if (this.data.item) {
        return this.data.item;
      }
      return this.data;
    },
    completed() {
      const { status } = this.item;
      if (status && status.text && status.text.toLowerCase() === 'incomplete')
        return false;
      return true;
    },
  },
};
</script>

<style lang="scss">
.tx-data-table-data {
  .action-container {
    width: 140px;
    .check-btn {
      margin: 0;
      display: none;
      &.completed {
        color: var(--v-success-base);
        display: inline-flex;
      }
    }
    .delete-btn {
      margin: 0;
      display: none;
    }
  }
  &:hover {
    .action-container {
      .check-btn {
        display: inline-flex;
      }
      .delete-btn {
        display: inline-flex;
      }
    }
  }
}
</style>
