<template  lang="pug">
.change-password.ld-dismissable-banner(v-if='isVisible')
  i.v-icon.material-icons.change-password--attention
    | error_outline
  p.change-password--text
    | {{ bannerText() }}
  a.change-password--ctaContainer.change-password--cta.text-uppercase(:href='redirectUrl()')
    | Update password
  .change-password--divider
  a.change-password-ctaContainer.change-password--cta.text-uppercase(
    :href='learnWhyUrl',
    target='_blank'
  )
    | Learn Why
  button.mr-3(@click='onDismiss()')
    i.v-icon.material-icons.change-password--close
      | close
</template>

<script>
import LdDismissableBanner from './LdDismissableBanner';
import { HC_PROPS } from 'lion-vue';
import { startOfDay, addDays, format } from 'date-fns';

const DATE_FORMAT = 'MM/DD/YYYY'

export default {
  components: { LdDismissableBanner },
  data() {
    return {
      isVisible: false,
      learnWhyUrl: 'https://lonewolf.my.site.com/s/article/LD-Why-do-I-need-to-reset-my-password-LionDesk',
    };
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    alreadyConnected: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    user: {
      immediate: true,
      deep: true,
      handler(value) {
        const isPWUpdated = this.passwordUpdated(value);
        
        if (!isPWUpdated) this.showBanner();
      },
    },
  },
  methods: {
    redirectUrl() {
      const currentURL = window.location.href;
      return `${HC_PROPS.ACCOUNTS_APP_URL}/change-password?redirect_url=${currentURL}`;
    },
    bannerText() {
      const cutOffDate = format(new Date(HC_PROPS.RESET_PASSWORD_CUTOFF_DATE), DATE_FORMAT);
      const bannerText = `To protect your account, please reset your password to meet our minimum policies. If you do not make this update by ${cutOffDate}, you will be asked to reset your password prior to login.`;
      return bannerText;
    },
    onDismiss() {
      this.isVisible = false;
      window.$cookies.set('dismissPWBannerState', new Date(), null, null, HC_PROPS.MAIN_DOMAIN);
    },
    showBanner() {
      const bannerState = window.$cookies.get('dismissPWBannerState');
      if (!bannerState) {
        this.isVisible = true;
        return;
      }

      const displayDate = startOfDay(addDays(new Date(bannerState), 1));
      let shouldDisplayBanner = false;

      if (new Date() >= displayDate) {
        window.$cookies.remove('dismissPWBannerState', null, HC_PROPS.MAIN_DOMAIN);
        shouldDisplayBanner = true;
      }
      this.isVisible = shouldDisplayBanner;
    },
    passwordUpdated(user) {
      const cutOffDate = Date.parse(HC_PROPS.RESET_PASSWORD_CUTOFF_DATE);
      const firstReleaseDate = Date.parse(HC_PROPS.RESET_PASSWORD_FIRST_RELEASE_DATE);
      const passChangedDate = Date.parse(user.password_changed_at);
      const createdAtDate = Date.parse(user.created_at);

      if (new Date() > cutOffDate) {
        return true;
      }

      if (
        createdAtDate >= firstReleaseDate ||
        (passChangedDate && passChangedDate >= firstReleaseDate)
      ) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.change-password {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  background-color: #f1d4d7;
  border-bottom: 1px solid rgba(90, 90, 90, 0.1);
  height: 61px;
  &--text {
    display: inline-block;
    margin-right: 20px;
    color: var(--v-text-base);
    letter-spacing: 0;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
  }
  &--ctaContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 20px;
  }
  &--cta {
    white-space: nowrap;
    flex-wrap: nowrap;
    justify-self: flex-start;
    color: #a64951;
    font-size: 16px;
    font-weight: 700;
  }
  &--divider {
    border-left-color: #a64951;
    border-left-style: solid;
    border-left-width: 1px;
    color: var(--v-text-base);
    height: 30px;
    width: 1px;
    margin: 0 20px;
  }
  &--close {
    color: #a64951;
    margin-left: 24px;
  }
  &--attention {
    font-size: 28px;
    margin-right: 12px;
    color: #a64951;
  }
}
</style>
