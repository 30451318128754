import LdFeatures from 'lion-vue-src/_globals/lib/LdFeatures';
import { createNamespacedHelpers, mapGetters } from 'vuex';

const {
  mapState: mapSubscriptionState,
  mapActions: mapSubscriptionActions,
} = createNamespacedHelpers('subscription');

const { mapActions: mapAppLayoutActions } = createNamespacedHelpers('ldAppLayout');

const AddOnMessages = Object.freeze({
  LimitsSuccess:
    'Your limits have been increased successfully. Your purchase will appear in the invoice of your current billing cycle.',
  StormDialerSuccess:
    'Storm Dialer subscription successful. Your purchase will appear in a monthly billing invoice.',
  Error:
    'Your purchase has failed to process. Please contact <a href="mailto:billing@liondesk.com">LionDesk billing</a>.',
});

const customNumberFeatures = [LdFeatures.LeadAssist, LdFeatures.CustomNumber];

export default {
  props: {
    feature: {
      type: String,
      default: null,
    },
    usage: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      meteredUsageKey: null,
      originalEvent: 'click',
      MLS_RESTRICTED_TEXT: 'mlsRetrictedTexts',
    };
  },
  watch: {
    async featureMeteredUsageAcceptedKey(newValue) {
      if (newValue && newValue === this.meteredUsageKey) {
        // if "metered usage accepted" key matches
        // that of this component, emit the original event
        // (e.g. click) and reset local and store values
        // of metered usage request
        try {
          await this.createSubscriptionAddon({ 
            feature: this.feature || this.bannerFeature,
            subType: this.featureSubType,
          });
        } catch (e) {
          // notify UI of failed add-on action
          this.completeAddonPurchase({ error: true, message: AddOnMessages.Error });
          return;
        }
        // refresh user info and limits if add-on action was successful
        await this.getUserInfo();

        // notify UI of successful add-on action
        if (this.feature === LdFeatures.StormDialer) {
          this.completeAddonPurchase({ error: false, message: AddOnMessages.StormDialerSuccess });
        } else {
          this.completeAddonPurchase({ error: false, message: AddOnMessages.LimitsSuccess });
        }

        this.meteredUsageKey = null;
      }
    },
  },
  computed: {
    ...mapSubscriptionState([
      'emailVerified',
      'featureGate',
      'featureMeteredUsageAcceptedKey',
      'features',
      'hasCustomNumber',
      'paymentConfigured',
      'plan',
      'featureSubType',
    ]),
    ...mapGetters({
      hasMlsUserAccessToTextFeature: 'userInformation/hasMlsUserAccessToTextFeature',
    }),
    isUnverified() {
      // TODO: implement BE mapping for which features
      // are dependent on email verification
      if (
        [LdFeatures.Emails, LdFeatures.Campaigns, LdFeatures.BulkCampaigns].includes(this.feature)
      ) {
        return !this.emailVerified;
      }
      return false;
    },
    isGated() {
      if (!this.feature) {
        // null values assumed "allowed to use"
        return false;
      }
      if (this.mlsUserRetrictedTexts()) return true;

      if (this.isFeatureValid) {
        if (this.tracking) {
          if (this.tracking.remaining < this.usage && !this.paymentConfigured) {
            // feature is gated if tracking remaining credit balance
            // is less than intended credit usage and no payment method
            // is configured for this user
            return true;
          }
        }
        if (customNumberFeatures.includes(this.feature) && !this.hasCustomNumber) {
          return true;
        }
        // otherwise gate based on value of `has_access` field
        return !this.isFeatureAccessible;
      }
      // if feature is not found in features list - do not gate by default per business request
      return false;
    },
    isMetered() {
      if (!this.feature) {
        // null values assumed "allowed to use"
        return false;
      }
      if (!this.isGated) {
        if (this.tracking) {
          if (this.tracking.remaining < this.usage && this.paymentConfigured) {
            // feature is metered if it has usage tracking and
            // intended usage will exceed remaining plan allowance
            // feature usage will be metered
            return true;
          }
        }
      }
      return false;
    },
    isFeatureValid() {
      // feature exists in known feature list
      return this.features && Object.prototype.hasOwnProperty.call(this.features, this.feature);
    },
    isFeatureAccessible() {
      // feature is accessible to user
      return this.features[this.feature].has_access;
    },
    tracking() {
      // feature usage tracking
      return this.features[this.feature].tracking;
    },
    gatedClass() {
      return { 'ld-gated-feature': this.isGated || this.isUnverified };
    },
    gatedTo() {
      return this.isGated ? '' : this.to;
    },
    gatedHref() {
      return this.isGated ? '' : this.href;
    },
  },
  methods: {
    ...mapSubscriptionActions([
      'toggleFeatureGate',
      'requestMeteredUsage',
      'createSubscriptionAddon',
      'completeAddonPurchase',
    ]),
    ...mapAppLayoutActions(['toggleVerifyEmailCta', 'getUserInfo']),
    // handle component "action" event
    // i.e. click, change, etc.
    async onAction(event, originalEvent) {
      await this.getUserInfo();
      this.originalEvent = originalEvent;
      if (this.isUnverified) {
        if (event && typeof event === 'object') event.preventDefault();
        this.toggleVerifyEmailCta(true);
      } else if (this.isGated) {
        if (event && typeof event === 'object') event.preventDefault();
        this.meteredUsageKey = null;
        this.mlsUserRetrictedTexts() 
          ? this.toggleFeatureGate({ feature: this.MLS_RESTRICTED_TEXT }) 
          : this.toggleFeatureGate({ feature: this.feature });
      } else if (this.isMetered) {
        if (event && typeof event === 'object') event.preventDefault();
        // create single-use metered usage key for this component
        // to monitor when user accepts metered usage prompt
        this.meteredUsageKey = `gated-feature-activator-${new Date().getTime()}`;
        this.toggleFeatureGate({ feature: this.feature, meteredUsageKey: this.meteredUsageKey });
      } else {
        this.$emit(originalEvent, event);
      }
    },
    mlsUserRetrictedTexts() {
      return (this.feature == LdFeatures.TextMessaging || this.feature == LdFeatures.BulkTexts) 
        && this.hasMlsUserAccessToTextFeature;
    },
  },
};
