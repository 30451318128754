<template lang="pug">
v-dialog(v-model="showChangeProfilePictureModal" persistent max-width='750')
    v-card.main
        v-container.pa-0(fluid)
            v-layout.pa-0(column, wrap)
                v-flex.pa-0(shrink)
                    v-toolbar(dark, color="primary")
                        v-toolbar-title EDIT YOUR PROFILE PICTURE
                v-flex.pa-0.modal-body(grow)
                    ld-image-loader(
                        v-if="isUploading"
                        @onAttachmentsRef="handleAttachmentsRef"
                        @onFileRead="handleFileRead"
                    )
                    ld-image-cropper(
                        v-if='isEditing || isEdited'
                        :aria-hidden="!isEditing"
                        ref="cropper"
                        @onCropped="handleCroppedImage"
                        :url='current.url'
                        :cropperData='editor.data'
                        :canvasData='editor.canvas'
                        :cropBoxData='editor.cropBox'
                    )
                    ld-image-cropper-toolbar(
                        v-if="isEditing"
                        @onMoveMode='$refs.cropper.moveMode()'
                        @onCropMode='$refs.cropper.cropMode()'
                        @onZoomIn='$refs.cropper.zoomIn()'
                        @onZoomOut='$refs.cropper.zoomOut()'
                        @onRotateLeft='$refs.cropper.rotateLeft()'
                        @onRotateRight='$refs.cropper.rotateRight()'
                        @onFlipHorizontal='$refs.cropper.swapHorizontal()'
                        @onFlipVertical='$refs.cropper.swapVertical()'
                    )
                    .edited-container(
                        v-if="isEdited"
                    )
                        img(:src='current.url')

                .actions(v-if="isUploading")
                    v-btn.modal-btn.outlined(@click="handleUpload" color="primary") Upload
                .actions(v-if="isEditing")
                    v-btn.modal-btn.outlined(@click="handleRemove" color="primary") Remove
                    v-btn.modal-btn.outlined(@click="handleEditorUndo" color="primary") Undo
                    v-btn.modal-btn.outlined(@click="handleConfirm" color="primary") Confirm
                .actions(v-if="isEdited")
                    v-btn.modal-btn.outlined(@click="handleRemove" color="primary") Remove
                    v-btn.modal-btn.outlined(@click="handleUndoConfirm" color="primary") Undo
        v-card-actions.actions
            v-layout(row justify-end)
                v-btn.modal-btn(@click='handleCancel' flat color="primary") Cancel
                v-btn.modal-btn.primary(@click='handleSave' :disabled="isSaveDisabled") Save
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import LdImageLoader from './LdImageLoader.vue';
import LdImageCropper from './LdImageCropper.vue';
import LdImageCropperToolbar from './LdImageCropperToolbar.vue';
const { mapState: mapStateLdAppLayout, mapActions: mapActionsLdAppLayout } = createNamespacedHelpers('ldAppLayout');

const Mode = {
    Uploading: 'uploading',
    Editing: 'editing',
    Edited: 'edited',
}

export default {
    name: 'LdChangeProfilePictureModal',
    components: {
        LdImageCropper,
        LdImageLoader,
        LdImageCropperToolbar,
    },
    data() {
        return {
            mode: Mode.Uploading,
            current: {
                url: '',
                name: '',
                type: '',
            },
            previous: {
                url: '',
                name: '',
                type: '',
            },
            editor: {
                data: null,
                canvas: null,
                cropBox: null,
            },
            attachmentsRef: null,
        };
    },
    methods: {
        handleSave() {
            this.uploadProfilePicture(this.current);
            this.closeModal();
            this.clearImageEditor();
        },
        handleCancel() {
            this.closeModal();
        },
        handleFileRead(data) {
            this.current = data;
            Object.assign(this.previous, this.current);
            this.mode = Mode.Editing;
        },
        handleCroppedImage({ url }, detail) {
            const current = Object.assign({}, this.previous);
            current.url = url;
            this.current = current;
            this.editor = Object.assign(this.editor, detail);
            this.mode = Mode.Edited;
        },
        handleEditorUndo() {
            this.editor = {
                data: null,
                canvas: null,
                cropBox: null,
            };
            this.$refs.cropper.reset();
            this.$refs.cropper.clear();
        },
        handleConfirm() {
            this.$refs.cropper.crop();
        },
        handleUndoConfirm() {
            Object.assign(this.current, this.previous);
            this.mode = Mode.Editing;
        },
        handleRemove() {
            this.clearImageEditor();
        },
        handleAttachmentsRef(attachments) {
            this.attachmentsRef = attachments;
        },
        handleUpload() {
            this.attachmentsRef.click();
        },
        closeModal() {
            this.setShowChangeProfilePictureModal(false);
        },
        clearImageEditor() {
            this.current = {
                url: '',
                name: '',
                type: '',
            };
            this.previous = {
                url: '',
                name: '',
                type: '',
            };
            this.editor = {
                data: null,
                canvas: null,
                cropBox: null,
            };
            this.mode = Mode.Uploading;
        },
        ...mapActionsLdAppLayout([
            'setShowChangeProfilePictureModal',
            'uploadProfilePicture',
        ]),
    },
    updated() {
        const userMenu = document.getElementById('lwt-unified-navbar__user-menu')
        const isShowingUserMenu = userMenu.classList.contains('show-menu');
        if (isShowingUserMenu && this.showChangeProfilePictureModal) {
            userMenu.classList.remove('show-menu');
        }
    },
    computed: {
        ...mapStateLdAppLayout([
            'showChangeProfilePictureModal',
        ]),
        isUploading() {
            return this.mode == Mode.Uploading;
        },
        isEditing() {
            return this.mode == Mode.Editing;
        },
        isEdited() {
            return this.mode == Mode.Edited;
        },
        isSaveDisabled() {
            return !this.current.url || !this.isEdited;
        }
    }
};
</script>
<style lang="scss" scoped>
.modal-body {
    max-height: 90vh;
    height: 20em;

    .edited-container {
        height: 100%;
        width: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.actions {
    padding: 0.4em 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .outlined {
        width: 11rem;
        border: 1px solid var(--v-primary-base);
        background-color: transparent !important;
        color: var(--v-primary-base);
    }

}

.main {
    .modal-btn {
        box-shadow: none;
        font-weight: bold;
        font-size: 16px;
    }

    &>.actions {
        border-top: solid 1px var(--v-background-lighten4);
        padding: 24px;
    }

    .modal-body>[aria-hidden='true'] {
        display: none;
    }
}
</style>
