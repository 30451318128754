<template>
  <svg
    data-icon="processing-status"
    viewBox="0 0 64 64"
    >
    <g id="Twilio-Registration---OTP-Modals" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Text-Copy-18" transform="translate(-834.000000, -192.000000)" fill-rule="nonzero">
            <g id="Group-4" transform="translate(834.000000, 192.000000)">
                <path d="M32.1112748,0 C31.2487584,-0.0119996133 30.4464888,0.44274008 30.0116708,1.19033139 C29.5768528,1.93792271 29.5768528,2.86254129 30.0116708,3.6101326 C30.4464888,4.35772391 31.2487584,4.81246361 32.1112748,4.80022296 C47.1090079,4.80022296 59.2166912,16.95011 59.2166912,32.0001205 C59.2166912,47.050131 47.1090079,59.2000181 32.1112748,59.2000181 C23.161457,59.2000181 15.2544786,54.8635912 10.3154555,48.1719348 C9.52946668,47.1062019 8.0313506,46.8816456 6.96931963,47.6703741 C5.90728865,48.4591027 5.68351231,49.9624408 6.46950117,51.0281737 C12.2769789,58.8964624 21.6185369,64 32.1112748,64 C49.6942523,64 64,49.6443869 64,32.0001205 C64,14.3558542 49.6942523,0 32.1112748,0 Z" id="Path" fill="#235EA1"></path>
                <path d="M23.5,1 C22.1192881,1 21,2.11928813 21,3.5 C21,4.88071187 22.1192881,6 23.5,6 C24.8807119,6 26,4.88071187 26,3.5 C26,2.11928813 24.8807119,1 23.5,1 Z" id="Path" fill="#E4E7EE"></path>
                <path d="M15.5,5 C14.1192881,5 13,6.11928813 13,7.5 C13,8.88071187 14.1192881,10 15.5,10 C16.8807119,10 18,8.88071187 18,7.5 C18,6.11928813 16.8807119,5 15.5,5 Z" id="Path" fill="#C7CFDD"></path>
                <path d="M8.5,12 C7.11928813,12 6,13.1192881 6,14.5 C6,15.8807119 7.11928813,17 8.5,17 C9.39316397,17 10.2184815,16.5235027 10.6650635,15.75 C11.1116455,14.9764973 11.1116455,14.0235027 10.6650635,13.25 C10.2184815,12.4764973 9.39316397,12 8.5,12 L8.5,12 Z" id="Path" fill="#AAB7CB"></path>
                <path d="M4.5,20 C3.11928813,20 2,21.1192881 2,22.5 C2,23.8807119 3.11928813,25 4.5,25 C5.88071187,25 7,23.8807119 7,22.5 C7,21.1192881 5.88071187,20 4.5,20 Z" id="Path" fill="#8F9FB9"></path>
                <path d="M2.5,29 C1.11928811,29 0,30.1192882 0,31.5 C0,32.8807118 1.11928811,34 2.5,34 C3.88071189,34 5,32.8807118 5,31.5 C5,30.1192882 3.88071189,29 2.5,29 Z" id="Path" fill="#7286A9"></path>
                <path d="M4.5,38 C3.11928813,38 2,39.1192881 2,40.5 C2,41.8807119 3.11928813,43 4.5,43 C5.88071187,43 7,41.8807119 7,40.5 C7,39.1192881 5.88071187,38 4.5,38 L4.5,38 Z" id="Path" fill="#567197"></path>
                <path d="M42,14.5 L23,14.5 C22.1715729,14.5 21.5,15.1715729 21.5,16 L21.5,20 C21.5,20.8284271 22.1715729,21.5 23,21.5 L42,21.5 C42.8284271,21.5 43.5,20.8284271 43.5,20 L43.5,16 C43.5,15.1715729 42.8284271,14.5 42,14.5 Z M40.5,17.5 L40.5,18.5 L24.5,18.5 L24.5,17.5 L40.5,17.5 Z" id="Rectangle" fill="#235EA1"></path>
                <path d="M42,41.5 L23,41.5 C22.1715729,41.5 21.5,42.1715729 21.5,43 L21.5,47 C21.5,47.8284271 22.1715729,48.5 23,48.5 L42,48.5 C42.8284271,48.5 43.5,47.8284271 43.5,47 L43.5,43 C43.5,42.1715729 42.8284271,41.5 42,41.5 Z M40.5,44.5 L40.5,45.5 L24.5,45.5 L24.5,44.5 L40.5,44.5 Z" id="Rectangle" fill="#235EA1"></path>
                <path d="M27.5,20 L27.5,24.1818182 C27.5,25.3730548 27.8334148,26.0095538 28.8197751,26.9467274 L29.4381033,27.5128913 C30.8543747,28.8084577 31.5,29.8285857 31.5,31.5 C31.5,33.1714143 30.8543747,34.1915423 29.4381033,35.4871087 L29.0124474,35.8738202 C27.876394,36.9130508 27.5,37.5524929 27.5,38.8181818 L27.5,43 L24.5,43 L24.5,38.8181818 C24.5,36.6538408 25.2230472,35.3313804 26.7713024,33.8616887 L26.9875526,33.6602707 C27.0634966,33.5907992 27.3477532,33.333436 27.4132086,33.2735592 C28.2666166,32.4928849 28.5,32.1241246 28.5,31.5 C28.5,30.9174837 28.2966971,30.5574181 27.5757378,29.8772812 L27.4132086,29.7264408 C27.3477532,29.666564 27.0634966,29.4092008 26.9875526,29.3397293 C25.2902726,27.7871038 24.5,26.4445384 24.5,24.1818182 L24.5,20 L27.5,20 Z" id="Path" fill="#235EA1"></path>
                <path d="M41.5,20 L41.5,24.1818182 C41.5,26.3461592 40.7769528,27.6686196 39.2286976,29.1383113 L39.0124474,29.3397293 C38.9365034,29.4092008 38.6522468,29.666564 38.5867914,29.7264408 C37.7333834,30.5071151 37.5,30.8758754 37.5,31.5 C37.5,32.0825163 37.7033029,32.4425819 38.4242622,33.1227188 L38.5867914,33.2735592 C38.6522468,33.333436 38.9365034,33.5907992 39.0124474,33.6602707 C40.7097274,35.2128962 41.5,36.5554616 41.5,38.8181818 L41.5,43 L38.5,43 L38.5,38.8181818 C38.5,37.6269452 38.1665852,36.9904462 37.1802249,36.0532726 L36.5618967,35.4871087 C35.1456253,34.1915423 34.5,33.1714143 34.5,31.5 C34.5,29.8285857 35.1456253,28.8084577 36.5618967,27.5128913 L36.9875526,27.1261798 C38.123606,26.0869492 38.5,25.4475071 38.5,24.1818182 L38.5,20 L41.5,20 Z" id="Path" fill="#235EA1"></path>
                <polygon id="Path" fill="#235EA1" points="38 33.5 38 35.5 27 35.5 27 33.5"></polygon>
                <polygon id="Path" fill="#235EA1" points="34 22.5 34 24.5 26 24.5 26 22.5"></polygon>
                <polygon id="Path" fill="#235EA1" points="38 22.5 38 24.5 36 24.5 36 22.5"></polygon>
                <polygon id="Path" fill="#235EA1" points="40 38.5 40 40.5 32 40.5 32 38.5"></polygon>
                <polygon id="Path" fill="#235EA1" points="30 38.5 30 40.5 28 40.5 28 38.5"></polygon>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '$vuetify.icons.processing-status',
    },
  },
};
</script>
