import Vue from 'vue';

import AsyncComputed from 'vue-async-computed';
import Vue2TouchEvents from 'vue2-touch-events';

import * as mixins from './mixins';
import routes from './routes';
import stores from './stores';

import LdAppLayout from './lib/LdAppLayout';
import LdConfirmation from './lib/LdConfirmation';
import LdFeatures from './lib/LdFeatures';
import LdGatedButton from './lib/LdGatedButton';
import LdGatedContent from './lib/LdGatedContent';
import LdGatedLink from './lib/LdGatedLink';
import LdGatedRouterLink from './lib/LdGatedRouterLink';
import LdGatedSwitch from './lib/LdGatedSwitch';
import LdGatedTab from './lib/LdGatedTab';
import LdSearchFilter from './lib/LdSearchFilter';
import LdSelectInput from './lib/LdSelectInput';
import LdSlideOut from './lib/LdSlideOut';
import LdTextInput from './lib/LdTextInput';
import MlsGallery from './lib/MlsGallery';
import MlsListings from './lib/MlsListings';
import MlsMap from './lib/MlsMap';
import MlsPropertyInfo from './lib/MlsPropertyInfo';
import MlsMobilePropertyInfo from './lib/MlsMobilePropertyInfo';
import MlsSearch from './lib/MlsSearch'; //
import MlsSearchWrapper from './lib/MlsSearchWrapper'; //
import MlsSimilarListings from './lib/MlsSimilarListings';
import CustomIcons from './lib/CustomIcons';
import LdHowItWorks from './lib/LdHowItWorks';
import LdChangeProfilePictureModal from './lib/LdChangeProfilePictureModal';
import LdProfilePicture from './lib/LdProfilePicture';

Vue.use(AsyncComputed);
Vue.use(Vue2TouchEvents);

const HC_PROPS = {
  MAIN_DOMAIN: 'liondesk.com',
  MLS_API: 'http://vmi.liondesk.com/api',
  ACCOUNTS_API: 'http://accounts.liondesk.com/api/v1',
  ACCOUNTS_APP_URL: 'http://accounts.liondesk.com',
  MLS_APP_URL: 'http://vmi.liondesk.com',
  TRANSACTIONS_APP_URL: 'http://transactions.liondesk.com',
  PLATFORM_APP_URL: 'https://crm.liondesk.com',
  NYLAS_API_BASE_URL: 'https://nylas.liondesk.com/api/v1',
  COMMUNICATIONS_API_BASE_URL: 'https://communications.liondesk.com/api/v1',
  RESET_PASSWORD_CUTOFF_DATE: '2021-12-21T08:00:00Z',
  RESET_PASSWORD_PHASE_2_DEPLOY_DATE: '2022-12-07T08:00:00Z',
  RESET_PASSWORD_FIRST_RELEASE_DATE: '2021-10-04T08:00:00Z',
  UL_PLATFORM_URL: 'https://platform.lwolf.com',
  LW_DOMAIN: 'lwolf.com',
};

const initHCProps = function ({
  MAIN_DOMAIN = HC_PROPS.MAIN_DOMAIN,
  MLS_API = HC_PROPS.MLS_API,
  ACCOUNTS_API = HC_PROPS.ACCOUNTS_API,
  ACCOUNTS_APP_URL = HC_PROPS.ACCOUNTS_APP_URL,
  MLS_APP_URL = HC_PROPS.MLS_APP_URL,
  TRANSACTIONS_APP_URL = HC_PROPS.TRANSACTIONS_APP_URL,
  PLATFORM_APP_URL = HC_PROPS.PLATFORM_APP_URL,
  NYLAS_API_BASE_URL = HC_PROPS.NYLAS_API_BASE_URL,
  COMMUNICATIONS_API_BASE_URL = HC_PROPS.COMMUNICATIONS_API_BASE_URL,
  RESET_PASSWORD_CUTOFF_DATE = HC_PROPS.RESET_PASSWORD_CUTOFF_DATE,
  RESET_PASSWORD_FIRST_RELEASE_DATE = HC_PROPS.RESET_PASSWORD_FIRST_RELEASE_DATE,
  UL_PLATFORM_URL = HC_PROPS.UL_PLATFORM_URL,
  LW_DOMAIN = HC_PROPS.LW_DOMAIN,
}) {
  HC_PROPS.MAIN_DOMAIN = MAIN_DOMAIN;
  HC_PROPS.MLS_API = MLS_API;
  HC_PROPS.ACCOUNTS_API = ACCOUNTS_API;
  HC_PROPS.MLS_APP_URL = MLS_APP_URL;
  HC_PROPS.ACCOUNTS_APP_URL = ACCOUNTS_APP_URL;
  HC_PROPS.TRANSACTIONS_APP_URL = TRANSACTIONS_APP_URL;
  HC_PROPS.PLATFORM_APP_URL = PLATFORM_APP_URL;
  HC_PROPS.NYLAS_API_BASE_URL = NYLAS_API_BASE_URL;
  HC_PROPS.COMMUNICATIONS_API_BASE_URL = COMMUNICATIONS_API_BASE_URL;
  HC_PROPS.RESET_PASSWORD_CUTOFF_DATE = RESET_PASSWORD_CUTOFF_DATE;
  HC_PROPS.RESET_PASSWORD_FIRST_RELEASE_DATE = RESET_PASSWORD_FIRST_RELEASE_DATE;
  HC_PROPS.UL_PLATFORM_URL = UL_PLATFORM_URL;
  HC_PROPS.LW_DOMAIN = LW_DOMAIN;

};

export {
  initHCProps,
  HC_PROPS,
  mixins,
  routes,
  stores,
  LdTextInput,
  LdAppLayout,
  LdSlideOut,
  LdSelectInput,
  LdSearchFilter,
  LdConfirmation,
  MlsGallery,
  MlsPropertyInfo,
  MlsMobilePropertyInfo,
  MlsSearchWrapper,
  MlsSimilarListings,
  MlsListings,
  MlsMap,
  MlsSearch,
  LdGatedButton,
  LdGatedTab,
  LdGatedLink,
  LdGatedRouterLink,
  LdGatedSwitch,
  LdGatedContent,
  LdFeatures,
  CustomIcons,
  LdHowItWorks,
  LdChangeProfilePictureModal,
  LdProfilePicture,
};
