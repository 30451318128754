<!--
@Author: Kleber Pinel <kleberdasilva>
@Date:   2019-03-19T11:38:55-07:00
@Email:  kleber@hackcapital.com
@Last modified by:   kleberdasilva
@Last modified time: 2019-03-25T09:12:10-07:00
-->

<template lang="pug">
v-dialog(v-model="visible" persistent width='480px')
  v-card
    title-bar(dark) {{ title }}
    v-card-text
      v-text-field(label="Link URL" v-model="linkURL")
    v-card-actions
      v-spacer
      v-btn(flat @click="$emit('close')") Cancel
      v-btn.primary(@click="insertValue" :disabled="!linkURL") Insert
</template>

<script>
import TitleBar from '@/Core/components/TitleBar';

export default {
  components: {
    TitleBar,
  },
  props: {
    title: {
      type: String,
      default: 'Insert Link URL',
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    visible() {
      this.linkURL = null;
    },
  },
  data() {
    return {
      linkURL: null,
    };
  },
  methods: {
    insertValue() {
      this.$emit('insert', this.linkURL);
    },
  },
};
</script>
