import {
  getUserDetailsInformation,
  resendActivationEmail,
  updateFeatureFlag,
  getOnboardingUserInformation,
  updateProfilePicture,
  getULToken,
} from 'lion-vue-src/api/accountsApi';
import { getEmailAccounts } from 'lion-vue-src/api/nylasApi';
import {
  getSmsUsage,
  getRegister10DLCInfo,
  getRegisterStarter10DLCInfo,
  getRegisterLowStandard10DLCInfo,
} from 'lion-vue-src/api/A2p10DLCApi';
import { HC_PROPS } from 'lion-vue-src/_globals/routes';
import {
  FEATURE_FLAG_CONTACTS_MS,
  FEATURE_FLAG_CONTACTS_MS_TOGGLE,
  FEATURE_FLAG_EVENTS_MS,
  FEATURE_FLAG_EVENTS_MS_TOGGLE,
  FEATURE_FLAG_QUALIFY,
  PROFILE_PICTURE_LIMIT_SIZE,
} from './constants';
import UploadService from 'lion-vue-src/api/uploaderService';

const checkForPasswordReset = async ({ commit }, user) => {
  if (!user.feature_flags.includes('reset-password')) {
    return;
  }
  const cutOffDate = Date.parse(HC_PROPS.RESET_PASSWORD_CUTOFF_DATE);
  const firstReleaseDate = Date.parse(HC_PROPS.RESET_PASSWORD_FIRST_RELEASE_DATE);
  const passChangedDate = Date.parse(user.password_changed_at);
  const createdAtDate = Date.parse(user.created_at);

  if (
    createdAtDate < firstReleaseDate &&
    (!passChangedDate || passChangedDate < firstReleaseDate) &&
    Date.now() > cutOffDate
  ) {
    const redirect = encodeURIComponent(window.location.href);
    window.location = `${HC_PROPS.ACCOUNTS_APP_URL}/change-password?redirect_url=${redirect}`;
  }
};

const getUserInfo = async ({ commit, dispatch }) => {
  try {
    const { me } = await getUserDetailsInformation();
    dispatch('checkForPasswordReset', me);
    commit('userInfo', me);
    commit('subscription/setFeatures', me, { root: true });
    commit('subscription/setPlan', me, { root: true });
    commit('subscription/setPaymentConfigured', me, { root: true });
    commit('subscription/setEmailVerified', me, { root: true });
    commit('subscription/setHasCustomNumber', me, { root: true });
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status == 403) {
      window.location = error.response.data.redirectTo;
    }
  }
};

const setHasAtLeastOneEmailAccount = async ({ commit }) => {
  const { email_accounts } = await getEmailAccounts();
  commit('setHasAtLeastOneEmailAccount', email_accounts.length > 0);
};
const setNavBarExpanded = async ({ commit }, value) => {
  commit('navBarExpanded', value);
};

const setShowChangeProfilePictureModal = async ({ commit }, value) => {
  commit('showChangeProfilePictureModal', value);
};

const togglePaymentCta = async ({ commit }, value) => {
  commit('togglePaymentCta', value);
};

const toggleVerifyEmailCta = async ({ commit }, value) => {
  commit('toggleVerifyEmailCta', value);
};

const resendVerifyEmail = async ({ state }, value) => {
  return await resendActivationEmail(state.userInfo.email);
};

const setShouldLoadV2ContactsInitialState = ({ commit, state, dispatch }) => {
  const user = state.userInfo;
  if (!user) return;

  const includesContactMS = user.feature_flags.includes(FEATURE_FLAG_CONTACTS_MS);
  const includesContactMSToggle = user.feature_flags.includes(FEATURE_FLAG_CONTACTS_MS_TOGGLE);

  if (!includesContactMS && !includesContactMSToggle) {
    dispatch('callUpdateFeatureFlag', {
      flag: FEATURE_FLAG_CONTACTS_MS,
      active: true,
    });
  }
  commit('setShouldLoadFF', {
    shouldLoadFF: includesContactMS,
    flag: FEATURE_FLAG_CONTACTS_MS,
  });
};

const setShouldLoadV2EventsInitialState = ({ commit, state, dispatch }) => {
  const user = state.userInfo;
  if (!user) return;

  const includesEventsMS = user.feature_flags.includes(FEATURE_FLAG_EVENTS_MS);
  const includesEventsMSToggle = user.feature_flags.includes(FEATURE_FLAG_EVENTS_MS_TOGGLE);

  if (!includesEventsMS && !includesEventsMSToggle) {
    dispatch('callUpdateFeatureFlag', {
      flag: FEATURE_FLAG_EVENTS_MS,
      active: true,
    });
  }
  commit('setShouldLoadFF', {
    shouldLoadFF: includesEventsMS,
    flag: FEATURE_FLAG_EVENTS_MS,
  });
};

const setShouldLoadV2FFInitialState = ({ commit, state, dispatch }, flag) => {
  const user = state.userInfo;
  if (!user) return;

  const includesFlag = user.feature_flags.includes(flag);
  let includesFFToggle;
  switch (flag) {
    case FEATURE_FLAG_CONTACTS_MS:
      includesFFToggle = user.feature_flags.includes(FEATURE_FLAG_CONTACTS_MS_TOGGLE);
      break;
    case FEATURE_FLAG_EVENTS_MS:
      includesFFToggle = user.feature_flags.includes(FEATURE_FLAG_EVENTS_MS_TOGGLE);
      break;
    case FEATURE_FLAG_QUALIFY:
      includesFFToggle = true;
      break;
    default:
      return;
  }

  if (!includesFlag && !includesFFToggle) {
    dispatch('callUpdateFeatureFlag', {
      flag,
      active: true,
    });
  }
  commit('setShouldLoadFF', {
    shouldLoadFF: includesFlag,
    flag,
  });
};

async function callUpdateFeatureFlag({ commit, dispatch }, { flag, active }) {
  try {
    await updateFeatureFlag(flag, active);
    commit('setIsUpdatingFeatureFlag', { isUpdating: false, flag });
    dispatch('getUserInfo', { root: true })
    location.reload();
  } catch (error) {
    console.log(error);
  }
}

const setShouldSeeFFToggle = ({ commit, state }, featureFlagToggle) => {
  const user = state.userInfo;
  if (!user) return;
  const includesFFToggle = user.feature_flags.includes(featureFlagToggle);
  commit('setShouldSeeFFToggle', { includesFFToggle, featureFlagToggle });
};

const setShouldLoadFeatureFlag = ({ state, commit, dispatch }, { shouldLoadFF, flag }) => {
  commit('setShouldLoadFF', { shouldLoadFF: !!shouldLoadFF, flag });
  commit('setIsUpdatingFeatureFlag', { isUpdating: true, flag });
  switch (flag) {
    case FEATURE_FLAG_CONTACTS_MS:
      dispatch('callUpdateFeatureFlag', {
        flag,
        active: state.shouldLoadV2Contacts,
      });
      break;
    case FEATURE_FLAG_EVENTS_MS:
      dispatch('callUpdateFeatureFlag', {
        flag,
        active: state.shouldLoadV2Events,
      });
      break;
    case FEATURE_FLAG_QUALIFY:
      dispatch('callUpdateFeatureFlag', {
        flag,
        active: state.shouldLoadQualify,
      });
      break;
    default:
      return;
  }
};

const getOnboardingInfo = ({ commit }) => {
  return getOnboardingUserInformation()
    .then(response => {
      const { onboarding_finished } = response.data;
      commit('setOnboardingFinished', onboarding_finished);
    })
    .catch(error => {
      console.log(error);
    });
};

const getSmsUsageInfo = ({ commit }) => {
  return getSmsUsage()
    .then(response => {
      commit('setSmsUsageInfo', response.data);
    })
    .catch(error => {
      console.log(error);
    });
};

const getRegistration10DlcInfo = ({ commit }) => {
  return getRegister10DLCInfo()
    .then(response => {
      const data = parserInfoRegister(response.data);
      commit('setRegistrationInfo', data);
    })
    .catch(() => {
      commit('setHasErrorGetRegistrationInfo', true);
    });
};

const getRegistrationStarter10DlcInfo = ({ commit }) => {
  return getRegisterStarter10DLCInfo()
    .then(response => {
      const data = parserInfoRegister(response.data);
      commit('setRegistrationStarterInfo', data);
    })
    .catch(() => {
      commit('setHasErrorGetRegistrationInfo', true);
    });
};

const getRegistrationLowStandard10DlcInfo = ({ commit }) => {
  return getRegisterLowStandard10DLCInfo()
    .then(response => {
      const data = parserInfoRegister(response.data);
      commit('setRegistrationLowStandardInfo', data);
    })
    .catch(() => {
      commit('setHasErrorGetRegistrationInfo', true);
    });
};

const parserInfoRegister = data => {
  const result = data;
  const { twilio_data, registration_data } = data;
  result.twilio_data = null;
  result.registration_data = null;
  if (twilio_data.Valid) {
    result.twilio_data = JSON.parse(twilio_data.String);
  }
  if (registration_data.Valid) {
    result.registration_data = JSON.parse(registration_data.String);
  }
  return result;
};
const dataURLtoBlob = async (dataURL) => {
  const base64Data = await fetch(dataURL)
  const blob = await base64Data.blob();
  return blob;
};
const getFileProfilePictureName = (fileName) => {
  const extension = fileName.split('.').pop();
  const name = fileName.split('.').shift();
  const random = Math.random().toString().slice(2, 10);
  return `${name}-${random}.${extension}`;
};
const uploadProfilePicture = async ({ commit, state }, picture) => {
  const user = state.userInfo;
  const file = await dataURLtoBlob(picture.url);
  const fileName = getFileProfilePictureName(picture.name);
  
  try {
    const { fileID } = await UploadService.uploadFile('profilePicture', {
      file: file,
      fileName: fileName,
      fileSize: file.size,
      folderName: '',
    }, { isPrivate: false });
    await updateProfilePicture(fileID);
    const { me } = await getUserDetailsInformation();
    commit('userInfo', me);
  } catch (error) {
    if (error && error.data && error.data.error == 'FILE_MAX_SIZE_REACHED') {
      commit('setError', {hasError: true, errorMessage: `The file size should not exceed ${PROFILE_PICTURE_LIMIT_SIZE}`});
    } else {
      commit('setError', {hasError: true, errorMessage: error});
    }
  }
};

const getUserULToken = async ({ commit }) => {
  await getULToken()
    .then(response => {
      const { token } = response.data;
      commit('setULToken', token);
    })
    .catch(error => {
      console.log(error);
    });
};


export default {
  checkForPasswordReset,
  getUserInfo,
  setNavBarExpanded,
  setShowChangeProfilePictureModal,
  togglePaymentCta,
  toggleVerifyEmailCta,
  resendVerifyEmail,
  callUpdateFeatureFlag,
  setHasAtLeastOneEmailAccount,
  getOnboardingInfo,
  getSmsUsageInfo,
  getRegistration10DlcInfo,
  getRegistrationStarter10DlcInfo,
  getRegistrationLowStandard10DlcInfo,
  setShouldLoadFeatureFlag,
  setShouldSeeFFToggle,
  setShouldLoadV2FFInitialState,
  uploadProfilePicture,
  getUserULToken,
};
