<template>
  <v-container class="edit-plan-container">
    <v-btn flat="flat" color="primary" @click="goBack" class="back-button">
      <v-icon>keyboard_arrow_left</v-icon> Back
    </v-btn>
    <div class="edit-title" v-if="!editing">
      <h2>{{ plan.title }}</h2>
      <v-btn icon small @click="editing = true">
        <v-icon small>edit</v-icon>
      </v-btn>
    </div>
    <div class="edit-title" v-else>
      <TextInput
        class="title-input"
        test-id="tx-plan-name"
        title="Title"
        placeholder="Update Plan Name"
        :entered="plan.title"
        @onInput="updateTitle"
        required
        :rules="titleRules"
        @onEnter="handleUpdate"
      />
      <v-btn icon small @click="handleUpdate"
        ><v-icon small>save</v-icon></v-btn
      >
      <v-btn icon small @click="handleCancel"
        ><v-icon small>cancel</v-icon></v-btn
      >
    </div>
    <h4 class="plan-type">Type: {{ getTypeName(plan.type_id) }}</h4>
    <v-btn color="primary" @click="addAction" class="add-action">
      Add an action
    </v-btn>

    <TransactionsTable
      class="task-table"
      :headers="headers"
      :items="items"
      :sortItems="sortItems"
      :pagination="pagination"
      :handlePagination="handlePagination"
      showDeleteOnly
      showEdit
      :handleDelete="handleDelete"
      :confirmDelete="confirmDelete"
      :handleEdit="handleEdit"
    />

    <LdConfirmation
      :value="confirmationDialog"
      width="580"
      @click:cancel="confirmDelete"
      @click:ok="handleDelete"
      confirmText="Delete"
    >
      <template slot="header">
        Delete task
      </template>
      <template slot="body">
        Are you sure? This cannot be undone.
      </template>
    </LdConfirmation>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import TransactionsTable from 'transactionsComponents/components/TransactionsTable';
import { LdConfirmation } from 'lion-vue';
import TextInput from '@/Core/components/TextInput';

const {
  mapMutations: mapEditMutations,
} = createNamespacedHelpers('editPlans');

export default {
  props: {
    plan: Object,
    getTypeName: Function,
    addAction: Function,
    getKCDName: Function,
    pagination: Object,
    handlePagination: Function,
    handleDelete: Function,
    handleEdit: Function,
    confirmDelete: Function,
    confirmationDialog: Boolean,
    updatePlan: Function,
    goBack: Function,
  },
  components: {
    TransactionsTable,
    LdConfirmation,
    TextInput,
  },
  data() {
    return {
      headers: [
        {
          text: 'Action Type',
          value: 'action_type',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Description',
          value: 'description',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'status',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Due At',
          value: 'due_at',
          align: 'left',
          sortable: true,
        },
      ],
      editing: false,
      updatedTitle: '',
      titleRules: [v => !!v || 'Please enter a title'],
    };
  },
  methods: {
    ...mapEditMutations(['sortTasks']),
    updateTitle(value) {
      this.updatedTitle = value;
    },
    handleCancel() {
      this.updatedTitle = '';
      this.editing = false;
    },
    async handleUpdate() {
      const plan = { ...this.plan, title: this.updatedTitle };
      await this.updatePlan(plan);
      this.handleCancel();
    },
    sortItems() {
      this.sortTasks({
        column: this.pagination.sortBy,
        descending: this.pagination.descending,
      });
    },
  },
  computed: {
    items() {
      if (!this.plan || !this.plan.tasks) return [];
      return this.plan.tasks.map(task => {
        const { id, action_type, description, status, due_at } = task;
        return {
          id,
          data: [
            {
              id,
              text: action_type,
              value: 'action_type',
            },
            {
              text: description,
              value: 'description',
            },
            {
              text: status,
              value: 'status',
            },
            {
              text: this.getKCDName(due_at),
              value: 'due_at',
            },
          ],
        };
      });
    },
  },
};
</script>

<style lang="scss">
.edit-plan-container {
  margin-top: 5px;
  .edit-title {
    display: flex;
    flex-direction: row;
    h2 {
      margin: auto 0;
    }
    .title-input {
      max-width: 300px;
      padding-top: 0;
    }
  }
  .plan-type {
    color: var(--v-info-darken1);
    margin-bottom: 20px;
  }
  .add-action {
    margin-left: 0;
  }
  .back-button {
    position: relative;
    right: 20px;
  }
  .task-table {
    margin-top: 10px;
  }
}
</style>
