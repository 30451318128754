/*
 * @Author: Ethan Dinnen
 * @Date:   2018-11-30 11:03:26
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-01-18 12:54:16
 */

import { Stages, Transactions, Contacts, Campaigns, Plan } from 'api';
import formTemplateNames from '@/Core/constants/formTemplateNames';

const reset = ({ commit }) => commit('reset');

const resetCampaigns = ({ commit }) => commit('resetCampaigns');

// Update the stage list
const updateStages = ({ commit }, value) => commit('updateStages', value);

const updateTransaction = ({ commit }, value) =>
  commit('updateTransaction', value);

// API related actions

const moveStage = async ({ state, dispatch }, data) => {
  const { toStage } = data;
  await Stages.move(
    state.transaction.transaction_type.market_id,
    state.transaction.transaction_type_id,
    state.transaction.id,
    state.transaction.stage_id,
    toStage,
  );
  dispatch('refreshTransaction');
};

const getTransaction = async ({ dispatch, commit, state }, value) => {
  const transaction = await Transactions.get(value);
  let incomplete = [];

  if (transaction.transaction_forms) {
    const kcds = transaction.transaction_forms.find(
      form => form.name === formTemplateNames.KEY_CONTRACT_DATES
    );
    if (kcds && Array.isArray(kcds.transaction_form_fields)) {
      // Count how many KCDS are incomplete
      const countIncomplete = (total, obj) => {
        if (obj.value) return total;
        return [...total, obj];
      };
      incomplete = kcds.transaction_form_fields.reduce(countIncomplete, []);
    }
  }

  dispatch('updateTransaction', transaction);
  commit('setIncompleteKCDs', incomplete);
  commit('updateStakeholders', await Contacts.getStakeholders(value));
  dispatch(
    'updateStages',
    await Stages.get(
      state.transaction.transaction_type.market_id,
      state.transaction.transaction_type_id
    )
  );
  if (!state.transaction.tasks || !state.transaction.tasks.length) return;
  dispatch('refreshTasks');
};

const refreshTasks = async ({ dispatch, commit, state }) => {
  commit('tasksLoading', true);
  const refreshed = await Plan.refreshTasks(state.transaction.id);
  const updated = {
    ...state.transaction,
    tasks: refreshed.tasks,
    communications: refreshed.communications,
  };
  dispatch('updateTransaction', updated);
  commit('tasksLoading', false);
};

const refreshTransaction = async ({ dispatch, commit, state }) => {
  const transaction = await Transactions.get(state.transaction.id);
  dispatch('updateTransaction', transaction);
};

const getCampaigns = async ({ dispatch, commit }, campaigns) => {
  dispatch('resetCampaigns');
  for (const campaign of campaigns) {
    commit('updateCampaigns', await Campaigns.get(campaign));
  }
};

const toggleComplete = async ({ dispatch, commit }, { id, status }) => {
  commit('tasksLoading', true);
  if (status === 'complete') {
    await Plan.updateTask(id, { status: 'incomplete' });
  } else if (status === 'incomplete') {
    await Plan.updateTask(id, { status: 'complete' });
  }
  dispatch('refreshTasks');
};

const deleteTask = async ({ commit, dispatch }, id) => {
  commit('tasksLoading', true);
  await Plan.deleteTask(id);
  dispatch('refreshTasks');
};

const deleteCommunication = async ({ commit, dispatch, state }, id) => {
  commit('tasksLoading', true);
  const comm = state.transaction.communications.find(c => c.id === id);
  await Plan.destroyCommunication(comm);
  dispatch('refreshTasks');
};

const getPlans = async ({ commit }, type) => {
  const plans = await Plan.allByType(type);
  commit('setPlans', plans);
};

const startPlan = async ({ dispatch }, { planId, txId }) => {
  await dispatch('transactions/startPlan', { planId, txId }, { root: true })
  dispatch('refreshTransaction');
};

export default {
  reset,
  resetCampaigns,
  updateStages,
  updateTransaction,
  refreshTransaction,
  moveStage,
  getTransaction,
  getCampaigns,
  refreshTasks,
  toggleComplete,
  deleteTask,
  deleteCommunication,
  getPlans,
  startPlan,
};
