<!--
  @Author: Ethan Dinnen
  @Date:   2018-12-18 15:20:02
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-02-25 15:07:40
-->
<template>
  <v-expansion-panel
    class="ld-summary-list"
    v-model="openPanels"
    expand="expand"
  >
    <v-expansion-panel-content
      v-for="(item, i) in items"
      :id="item.key"
      :key="i"
      :disabled="true"
    >
      <div class="panel-head" v-if="openPanels.includes(item)">
        <div
          :class="['header', 'open', isLoop ? 'open-loop' : '']"
          slot="header"
        >
          {{ item.header }}
        </div>
        <a
          v-if="isLoop"
          class="float-right edit-button loop-edit"
          flat
          @click.prevent="emitSelection(item.key);"
          >Edit</a
        >
      </div>
      <div class="panel-head" v-else>
        <div class="header" slot="header">{{ item.header }}</div>
        <a
          v-if="item.done && !editDisabled"
          class="float-right edit-button"
          flat
          @click.prevent="emitSelection(item.key);"
          >Edit</a
        >
      </div>
      <v-card-text class="pt-0 pr-0 pb-0 pl-0" v-if="openPanels.includes(item)">
        <v-layout>
          <v-flex
            class="no-overflow xs9 panel-data"
            v-if="item.key !== 'transaction-contacts'"
          >
            <!-- <v-icon class="pr-2">{{ item.icon }}</v-icon> -->
            <span v-for="(line, i) in item.data" :key="i">{{ line }}</span>
          </v-flex>
          <v-flex class="no-overflow xs9 panel-data" v-else>
            <div
              v-for="(contact, i) in item.data"
              :key="i"
              style="margin: 2px 0;"
            >
              <StakeholderAvatar
                :stakeholder="formatContact(contact)"
                :size="28"
              />
              {{ name(contact) }}
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import StakeholderAvatar from '@/Core/components/StakeholderAvatar';

export default {
  components: {
    StakeholderAvatar,
  },
  data() {
    return {
      /**
       * The truthy values of the props.items.
       * This controls which lists are expanded
       */
      openPanels: [],
    };
  },
  props: {
    /**
     * Controls whether the edit button is disabled or not
     */
    editDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * The initial items we want to populate the expander with
     * It expects an array of objects in the following format:
     * {
     *    header: 'This is a header!', // String
     *    text: 'This is the content!', // String
     *    icon: 'email', // String, The prepended icon
     * }
     */
    items: Array,
    /**
     * The v-model bound to the callback if the `edit` button
     * is clicked, indicating which stage the user is on right now.
     */
    value: String,
  },
  mounted() {
    this.checkExpansion(this.items);
  },
  watch: {
    items: {
      handler(value) {
        this.checkExpansion(value);
      },
      deep: true,
    },
    value: {
      handler() {
        this.checkExpansion(this.items);
      },
    },
  },
  computed: {
    isLoop() {
      return this.value === 'transaction-creation-loop';
    },
  },
  methods: {
    /**
     * Generates an array of booleans, which is true if it has content
     * Otherwise, input false.
     */
    checkExpansion(items) {
      if (this.isLoop) {
        this.openPanels = items;
        return;
      }
      if (!items && !items.length) return;
      this.openPanels = items.filter(({ key }) => key === this.value);
    },
    /**
     * Emits the index at which the item is located
     */
    emitSelection(index) {
      this.$emit('input', index);
    },
    formatContact(contact) {
      return {
        meta: {
          first_name: contact.first_name,
          last_name: contact.last_name,
        },
      };
    },
    name(contact) {
      if (!contact) return '';
      return `${contact.first_name} ${contact.last_name}`;
    },
  },
};
</script>
<style lang="scss">
// Aruably this could be in the 'liondesk.scss' file, but in light of encapsulation,
// it might be better to write this here so that this component be modular
.ld-summary-list {
  box-shadow: none;
  overflow-x: scroll;
  justify-content: start;
  max-height: calc(100vh - 300px);
  .no-overflow {
    overflow: hidden;
    white-space: nowrap;
  }
  .v-expansion-panel .v-expansion-panel__header {
    padding: 0;
    min-height: unset;
  } // Controls the padding for child components
  .has-content {
    color: var(--v-primary-base) !important;
    font-size: 20px;
  }
  .v-expansion-panel__body {
    font-size: 15px;
    color: var(--v-background-lighten1);
    padding-bottom: 10px;
    display: block !important;
  } // Formats the body if exists
  li {
    background: none !important;
  }
  li:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  } // Required because box-shadow none removes bottom border
  .edit-button {
    height: inherit;
    margin: auto 0 auto auto;
    color: #3878c1;
  }
  .header {
    &.open {
      margin: 15px 0 0;
      color: #3878c1;
    }
    &.open-loop {
      color: rgba(0, 0, 0, 0.5) !important;
    }
  }
  .panel-head {
    display: flex;
    flex-direction: row;
    margin: 15px auto 0;
    color: rgba(0, 0, 0, 0.38);
    justify-content: space-between;
    .loop-edit {
      margin: 22px 0 0 0 !important;
    }
  }
  .v-expansion-panel__container {
    max-width: 340px;
  }
  .loop-panel {
  }
  .panel-data {
    display: flex;
    flex-direction: column;
    color: rgba(0, 0, 0, 0.5);
    max-width: 100%;
    flex-basis: 100%;
  }
}
</style>
