/*
 * @Author: Kleber Pinel Bernardo da Silva
 * @Date:   2019-01-25 15:59:17
 * @Last Modified by:   Kleber Pinel Bernardo da Silva
 * @Last Modified time: 2019-01-25 15:59:24
 */

export default function (HC_PROPS) {
  return [
    {
      path: `${HC_PROPS.PLATFORM_APP_URL}/dashboard`,
      name: 'dashboard',
    },
    {
      path: `${HC_PROPS.PLATFORM_APP_URL}/contacts`,
      name: 'contacts',
    },
    {
      path: `${HC_PROPS.PLATFORM_APP_URL}/communications/email`,
      name: 'communications',
    },
    {
      path: `${HC_PROPS.PLATFORM_APP_URL}/tasks`,
      name: 'tasks',
    },
    {
      path: `${HC_PROPS.PLATFORM_APP_URL}/campaigns`,
      name: 'campaigns',
    },
  ];
}
