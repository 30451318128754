<!--
  @Author: Ethan Dinnen
  @Date:   2018-12-18 15:18:32
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-02-07 12:49:39
-->
<template>
  <v-flex class="xs12 md5">
    <div class="header" v-if="!isLoop">{{ summaryTitle }}</div>
    <v-flex
      :class="[!isLoop ? 'pt-5 summary-padding' : '', 'pr-0 pb-0 pl-0 xs8']"
    >
      <div class="header review" v-if="isLoop">
        Review your New Transaction details
      </div>
      <SummaryList
        :items="summaryValues"
        v-model="selectedItem"
        :editDisabled="editDisabled"
      ></SummaryList>
    </v-flex>
  </v-flex>
</template>

<script>
import SummaryList from '@/Core/components/SummaryList';

export default {
  components: { SummaryList },

  data() {
    return {
      selectedItem: this.value,
    };
  },

  props: {
    /**
     * Controls whether the edit button is disabled or not
     */
    editDisabled: Boolean,
    /**
     * The array of objects that will be displayed on the summary liset
     */
    summaryValues: Array,
    /**
     * The current selection that the user is currently in
     */
    value: String,

    summaryTitle: String,
  },
  computed: {
    isLoop() {
      return this.value === 'transaction-creation-loop';
    },
  },

  watch: {
    selectedItem(value) {
      this.$emit('input', value);
    },
    value(value) {
      this.selectedItem = value;
    },
  },
};
</script>

<style>
.summary-padding {
  padding-top: 20px !important;
}
.header {
  font-size: 24px !important;
  font-weight: 400;
  line-height: 32px !important;
  letter-spacing: normal !important;
}
.review {
  font-size: 19px !important;
}
</style>
