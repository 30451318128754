<!--
  @Author: Ethan Dinnen
  @Date:   2018-12-03 13:40:08
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-02-15 14:39:39
-->

<template>
  <TransactionTypesWidgetPresenter
    :transactionTypesWidget="transactionTypesWidget"
    :transactionTypesWidgetInProgress="transactionTypesWidgetInProgress"
    :setSelectedTransactionTypeId="setSelectedTransactionTypeId"
    :selectedTransactionTypeId="selectedTransactionTypeId"
    :updateLevel="updateLevel"
  />
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import TransactionTypesWidgetPresenter from './TransactionTypesWidgetPresenter';

const {
  mapState: mapViewState,
  mapActions: mapViewActions,
} = createNamespacedHelpers('transactionTypesWidget');

export default {
  components: {
    TransactionTypesWidgetPresenter,
  },
  data() {
    return {
      index: -1,
      transactionTypesWidgetInProgress: true,
    };
  },
  computed: {
    ...mapViewState(['transactionTypesWidget', 'selectedTransactionTypeId']),
  },
  methods: {
    ...mapViewActions([
      'getTransactionTypesWidget',
      'setSelectedTransactionTypeId',
      'updateLevel',
    ]),
  },
  mounted() {
    const container = document.querySelector('.horiz-scroll');
    setTimeout(() => {
      container.scrollBy({ left: 150, behavior: 'smooth' });
    }, 500);
    setTimeout(() => {
      container.scroll({ left: 0, behavior: 'smooth' });
    }, 1050);
  },
  async beforeMount() {
    await this.getTransactionTypesWidget();
  },
};
</script>
