<!--
  @Author: Kleber Pinel
  @Date:   2018-12-14 15:58:26
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-02-06 10:55:41
-->

<template>
  <div v-if="selectedTransactionType" class="stages-pipeline-container">
    <v-card-text class="overflow-hidden py-0">
      <div class="dot-bar">
        <div class="dot outside" />
        <div class="stage-pipeline__top-bar">
          <div class="dot" v-for="n in inProcessStages.length" :key="n" />
        </div>
        <div class="dot outside" />
      </div>
      <v-layout row align-content-center class="horiz-scroll">
        <v-flex
          v-for="(item, index) in inProcessStages"
          :key="index"
          class="stage-pipeline"
        >
          <div class="transaction-type-card" column>
            <label class="pipeline-title">{{ item.title }}</label>
            <div row>
              <router-link :to="`/view/${selectedId}/${item.title}`">
                <div class="value">{{ item.transactionCount }}</div>
              </router-link>
              <div class="summation">
                <div>S: {{ formatCurrency(item.summation.total_sales) }}</div>
                <div>
                  C: {{ formatCurrency(item.summation.total_commission) }}
                </div>
              </div>
            </div>
            <label class="pipeline-view-link">
              <router-link
                :to="`/pipelines/${item.transaction_type_id}/${item.id}`"
              >
                View items
              </router-link>
            </label>
          </div>
        </v-flex>
      </v-layout>
    </v-card-text>
    <div v-if="closedStages.length > 0">
      <v-layout row align-content-center class="horiz-scroll closed-stages">
        <div
          v-for="(item, index) in closedStages"
          :key="index"
          class="stage-pipeline"
        >
          <div class="transaction-type-card closed-stages">
            <label class="closed-title-label">
              {{ item.title }}
              <router-link
                :to="`/view/${selectedId}/${item.title}`"
                class="closed-sort-link"
              >
                <label class="closed-stage-transaction-count">
                  {{ item.transactionCount }}
                </label>
              </router-link>
            </label>
          </div>
        </div>
      </v-layout>
    </div>
  </div>
</template>

<script>
import formatCurrency from '@/Core/mixins/formatCurrency';

export default {
  components: {},
  props: {
    transactionTypesWidget: Array,
    selectedId: Number,
    id: Number,
  },
  mixins: [formatCurrency],
  data() {
    return {
      toggleMenu: false,
    };
  },
  computed: {
    selectedTransactionType() {
      return this.transactionTypesWidget.find(
        transactionTypesWidget =>
          transactionTypesWidget.transactionType.id === this.selectedId
      );
    },
    inProcessStages() {
      return this.selectedTransactionType.summationsByStage
        .filter(stage => stage.stage_type === 'in-process')
        .sort((a, b) => a.level - b.level);
    },
    closedStages() {
      return this.selectedTransactionType.summationsByStage
        .filter(stage => {
          return stage.stage_type === 'closed';
        })
        .sort((a, b) => a.level - b.level);
    },
    hasClosedStages() {
      const closedStage = this.selectedTransactionType.summationsByStage.find(
        stage => stage.stage_type === 'closed'
      );
      return closedStage !== undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
a.closed-sort-link {
  cursor: default;
  label {
    cursor: pointer;
  }
}
label {
  color: #a29696;
  font-size: 10px;
  font-weight: 500;
  margin-left: 16px;
  &.pipeline-view-link {
    font-size: 12px;
    margin-left: 0px;
    color: #3979c2;
  }
  &.pipeline-title {
    font-size: 13px;
    margin-left: 0px;
  }
  &.closed-title-label {
    color: #969696;
    font-weight: 500;
    font-size: 16px;
  }
}
.stages-pipeline-container {
  padding: 10px 60px 20px;
  @media all and (max-width: 959px) {
    padding: 10px 30px 20px;
  }
}
.transaction-type-card {
  padding: 0px;
  font-size: 12px;
  text-align: left;

  margin: 0px 20px;

  &.closed-stages {
    margin-left: 0px;
    margin-right: 15px;
    width: 100%;
    .closed-stage-transaction-count {
      color: #275b96;
      margin-left: 5px;
      font-size: 17px;
      font-weight: 500;
    }
  }
  .value {
    font-size: 48px;
    display: inline-block;
    padding-right: 6px;
    margin-right: 8px;
    color: #275b96;
  }
  .summation {
    font-size: 13px;
    font-weight: 400;
    display: inline-block;
    color: var(--v-primary-dark-base);
  }
  .flat {
    &:before {
      border: none;
    }
  }
}

.transaction-type-card {
  border-right: 1px solid #ebeaeb;
}
.stage-pipeline:last-child .transaction-type-card {
  border-right: 0px;
}

.stage-pipeline:last-child .bar {
  width: 70%;
}

.overflow-hidden {
  overflow: hidden;
  background-color: white;
}
.horiz-scroll {
  overflow-y: hidden;
  overflow-x: auto;
  margin-bottom: 0px !important;
  padding-bottom: 20px;
  margin: 0px;
  padding: 0px;

  .closed-stages {
    margin-top: 15px;
  }
}
.pipeline-wapper {
  margin: 10px 20px;
  padding-right: 0px;
  height: 100px;
}

.dot-bar {
  display: flex;
  flex-direction: row;
  .stage-pipeline__top-bar {
    margin-top: 20px;
    margin-bottom: 15px;
    width: 100%;
    height: 1px;
    background-color: #444242;
    position: relative;
    display: flex;
    justify-content: space-around;
    .dot {
      &:first-of-type {
        border: none;
        background: none;
      }
      &:last-of-type {
        border: none;
        background: none;
      }
    }
  }
  .dot {
    display: inline-block;
    vertical-align: middle;
    width: 5px;
    height: 5px;
    background-color: white;
    border: solid 1px black;
    border-radius: 50%;
    background: #444242;
    border-color: #444242;
    position: relative;
    bottom: 2px;
  }
  .outside {
    position: relative;
    top: 18px;
  }
}

.stage-pipeline {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
::-webkit-scrollbar {
  width: 0px; /* remove scrollbar space */
  background: transparent; /* optional: just make scrollbar invisible */
}
</style>
