<template lang="pug">
  v-flex.action-timing(xs12 md4)
    v-layout(row wrap)
      v-flex(xs12)
        div.header.pb-5 Set the due date
      v-flex(xs12)
        div.relative-select
          TextInput(
            v-if="radioGroup !== 'on'"
            :entered="taskCopy.due_at.days"
            :title="`Days ${radioGroup}`"
            placeholder="5"
            @onInput="setDays"
          )
          v-radio-group(
            v-model="radioGroup"
            :class="[radioGroup !== 'on' ? 'is-relative' : '']"
            @change="setTiming"
          )
            v-radio(
              label="On"
              value="on"
            )
            v-radio(
              label="Before"
              value="before"
            )
            v-radio(
              label="After"
              value="after"
            )
        SelectInput(
          :options="kcdOptions"
          title="Key Contract Dates"
          :selected="task.due_at.value"
          @onInput="setDueAt"
        )

</template>
<script>
import SelectInput from '@/Core/components/SelectInput';
import TextInput from '@/Core/components/TextInput';
import { TIMING_TYPES } from '../config/actions';

export default {
  components: {
    SelectInput,
    TextInput,
  },
  data() {
    return {
      calendarMenu: null,
      taskCopy: Object.assign({}, this.task),
      radioGroup: this.task.due_at.timing || 'on',
      days: '',
    };
  },

  props: {
    /**
     * Array of keys that adds a timing type to the list dropdown even if it's disabled
     * Defaults to none
     */
    initialTiming: Object,
    /**
     * The default prop passed in, expects the delay_by_days and dispatch_time
     * e.g. {
     *  delay_by_days: 1,
     *  dispatch_time: 19:00:00
     * }
     */
    value: Object,
    updateTiming: Function,
    updateTask: Function,
    task: Object,

    /**
     * The select input formatted array of key contract date names/ids
     * to choose when the task will be due for a particular transaction
     */
    kcdOptions: Array,
  },

  computed: {
    timingTypes: {
      get() {
        const supportedTypes = Object.values(TIMING_TYPES).filter(
          type => type.supported
        );
        if (this.initialTiming && this.initialTiming.timing_type)
          supportedTypes.push(this.initialTiming.timing_type);
        return supportedTypes;
      },
    },
  },

  watch: {
    taskCopy: {
      handler(value) {
        this.updateTask(this.taskCopy);
        return value;
      },
      deep: true,
    },
  },
  methods: {
    setDueAt(value) {
      this.taskCopy = {
        ...this.taskCopy,
        due_at: { ...this.taskCopy.due_at, value },
      };
    },
    setDays(days) {
      this.taskCopy = {
        ...this.taskCopy,
        due_at: { ...this.taskCopy.due_at, days },
      };
    },
    setTiming(timing) {
      this.taskCopy = {
        ...this.taskCopy,
        due_at: { ...this.taskCopy.due_at, timing },
      };
    },
  },
};
</script>
<style lang="scss">
.action-timing {
  .relative-select {
    display: flex;
    flex-direction: row;
    .v-text-field {
      width: 0;
    }
    .v-input--radio-group {
      &.is-relative {
        margin-left: 20px;
      }
      .v-input--radio-group__input {
        flex-direction: row;
      }
      .v-radio {
        margin-bottom: 8px;
      }
    }
  }
}
</style>
