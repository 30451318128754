/*
 * @Author: Ethan Dinnen
 * @Date:   2018-12-04 10:43:18
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-01-29 11:27:39
 */

import axios from 'axios';

/**
 * Retrieve the stages for a transaction type
 * sorted by id ascending
 * @param {number|string} type The transaction type id
 * @return {array} The stages for the transaction type
 */
export const get = async (market, type) => {
  return await axios
    .get(`markets/${market}/transaction_types/${type}/stages`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.stack);
    });
};

/**
 * Move a transaction between stages
 * @param {number} market The market id
 * @param {number} typeId The transaction type id
 * @param {number} transactionId The transaction's id
 * @param {number} fromStage The stage id to move from
 * @param {number} toStage The stage id to move to
 * @return {object} The newly modified transaction
 */
export const move = async (
  market,
  typeId,
  transactionId,
  fromStage,
  toStage,
) => {
  return await axios
    .put(
      `markets/${market}/transaction_types/${typeId}/pipeline/stage_management`,
      { transactionId, fromStage, toStage }
    )
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.stack);
    });
};

/**
 * Update a stage
 * @param {number} market The market id
 * @param {number} type The transaction type id
 * @param {number} id The id of the stage to rename
 * @param {string} stage The object containing values to update
 * @return {object} The newly renamed stage
 */
export const update = async (market, type, id, stage) => {
  return await axios
    .put(`markets/${market}/transaction_types/${type}/stages/${id}`, {
      stage,
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.stack);
    });
};

/**
 * Create a new stage
 * @param {number} market The market id
 * @param {number} type The transaction type id
 * @param {object} stage The new stage to create
 * @return {object} The newly created stage
 */
export const create = async (market, type, stage) => {
  return await axios
    .post(`markets/${market}/transaction_types/${type}/stages`, { stage })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.data);
    });
};

/**
 * Delete a stage
 * @param {number} market The market id
 * @param {number} type The transaction type id
 * @param {number} id The id of the stage to delete
 * @return {boolean} Returns true upon success
 */
export const deleteStage = async (market, type, id) => {
  return await axios
    .delete(`markets/${market}/transaction_types/${type}/stages/${id}`)
    .then(() => {
      return true;
    })
    .catch(err => {
      throw new Error(err.data);
    });
};
