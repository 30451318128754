import vmiFeathers from 'vmi-feathers-client';

export default {
  data() {
    return {
      savedFilters: [],
    };
  },
  methods: {
    async fetchAllSavedSearches() {
      const results = await vmiFeathers()
        .service('typeahead')
        .find({
          query: {
            searchText: '',
            searchTypes: ['saved_searches'],
          },
        });

      this.savedFilters = results.map(result => {
        const { conditions } = result;
        return {
          id: result.id,
          name: result.suggestion,
          conditions,
        };
      });
    },
  },
};
