import axios from 'axios';
import { HC_PROPS } from 'lion-vue-src/_globals/routes';

export const getSmsUsage = async data => {
  const response = await axios({
    method: "get",
    url: `${HC_PROPS.COMMUNICATIONS_API_BASE_URL}/sms/usage`,
  });
  return response;
};

export const getRegister10DLCInfo = async data => {
  const response = await axios({
    method: "get",
    url: `${HC_PROPS.COMMUNICATIONS_API_BASE_URL}/register-10dlc/info/standard`,
  });
  return response;
};

export const getRegisterStarter10DLCInfo = async data => {
  const response = await axios({
    method: "get",
    url: `${HC_PROPS.COMMUNICATIONS_API_BASE_URL}/register-10dlc/info/starter`,
  });
  return response;
};

export const getRegisterLowStandard10DLCInfo = async data => {
  const response = await axios({
    method: "get",
    url: `${HC_PROPS.COMMUNICATIONS_API_BASE_URL}/register-10dlc/info/low_standard`,
  });
  return response;
};
