<template>
  <div
    class="extra-info"
    v-if="shouldShow()"
  >
    <div class="title primary--text">{{info.title}}</div>
    <div class="extra">
      <div
        v-for="(v, k) in info.data"
        v-if="v.value"
        v-bind:key="v.value"
        :class="infoClass(k)"
      ><span>
        <v-icon v-if="v.icon">{{v.icon}}</v-icon>
        <b v-if="!v.icon">{{v.label}}: </b>
        <a v-if="v.label === 'Email'" :href="`mailto:${v.value}`">{{v.value}}</a>
        <span v-else>{{v.value}}</span>
      </span></div>
    </div>
  </div>
</template>

<script>
const dataTypes = {
  AGENT: 'agent',
  GENERIC: 'generic',
}

export default {
  name: 'MlsPropertyExtraInfo',
  props: {
    info: {
      type: Object,
      required: true,
    },
    dataType: {
      type: String,
      default: 'generic',
    },
  },
  methods: {
    infoClass(key) {
      const data = this.info.data
      if (this.dataType !== dataTypes.AGENT) return ''
      if (key === 'name') return 'extra-info__extra__extra-name'
      return 'extra-info__extra__extra-with-padding'
    },
    shouldShow() {
      const data = this.info.data
      if (this.dataType === dataTypes.AGENT && data.name.value) return true

      for (const key in data) {
        if (data[key].value) return true
      }
      return false
    },
  },
};
</script>

<style lang="scss" scope>
.extra-info{
  margin-top: 20px;
  .extra {
    padding: 10px 0px;
  }
}
.extra-info__extra__extra-name{
  font-size: 18px;
}
.extra-info__extra__extra-with-padding{
  padding-left: 28px;
}
</style>
