<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <g id="clone-icon" fill="currentColor">
            <path
                d="M22,16A2,2 0 0,1 20,18H8C6.89,18 6,17.1 6,16V4C6,2.89 6.89,2 8,2H20A2,2 0 0,1 22,4V16M16,20V22H4A2,2 0 0,1 2,20V7H4V20H16Z" />
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            default: '$vuetify.icons.clone',
        },
    },
};
</script>
