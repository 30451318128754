/**
 * Ported in from LWT-UNIFIEDNAV assets and samples.
 * TODO MW - Remove whatever we do not user in here, can add back in as necessary
 */
import { createPopper } from '@popperjs/core';

export default (function () {
  var _args = {};
  return {
    init: function (Args) {
      _args = Args;
      var overflowItems = [];
      var moreButton = document.getElementById('more-button');
      var moreMenu = document.getElementById('overflow-dropdown-menu');
      var moreMenuList = document.getElementById('overflow-dropdown-menu-items');
      var amountToAdd = 0;
      var dataAttribute = 'data-nav-id';
      var moreMenuOpen = false;
      var userMenuOpen = false;
      var helpMenuOpen = false;
      var betaMenuOpen = false;
      var notificationMenuOpen = false;
      var activeMoreItem = -1;
      var activeMoreSubItem = -1;
      var userMenuToggle = document.querySelector('#lwt-unified-navbar__user-menu-toggle');
      var helpMenuToggle = document.querySelector('#lwt-unified-navbar__help-menu-toggle');
      var betaMenuToggle = document.querySelector('#lwt-unified-navbar__beta-menu-toggle');
      var notificationMenuToggle = document.querySelector('#lwt-unified-navbar__notification-menu-toggle');
      var userMenu = document.querySelector('#lwt-unified-navbar__user-menu');
      var helpMenu = document.querySelector('#lwt-unified-navbar__help-menu');
      var betaMenu = document.querySelector('#lwt-unified-navbar__beta-menu');
      var notificationMenu = document.querySelector('#lwt-unified-navbar__notification-menu');
      var activeMenuItem = -1;
      var activeToggle = null;
      var mobileMenuToggleButton = document.querySelector('#lwt-unified-mobile-menu-toggle');
      var mobileMenuPanel = document.querySelector('#lwt-unified-navbar__mobile-menu-panel-navigation');
      var mobileMenuOpen = false;
      if (mobileMenuPanel) {
        var focusableElementsMobileMenu = mobileMenuPanel.querySelectorAll(
          'button, a, [tabindex]:not([tabindex="-1"])'
        );
      }
      var focusedMobileMenuItemIndex = -1;
      var mobileNotificationToggleButton = document.querySelector('#lwt-unified-mobile-notifications-toggle');
      var mobileNotificationPanel = document.querySelector('#lwt-unified-navbar__mobile-notification-panel');

      if (mobileNotificationPanel) {
        var focusableElementsNotificationPanel = mobileNotificationPanel.querySelectorAll(
          'button, a, [tabindex]:not([tabindex="-1"])'
        );
      }
      var focusedMobileNotificationItemIndex = -1;
      var notificationPanelOpen = false;
      var appSwitcherToggle = document.querySelector('#lwt-unified-navbar__application-switcher-menu-toggle');
      var appSwitcherMenu = document.querySelector('#lwt-unified-navbar__application-switcher-menu');
      var appSwitcherMobilePanel = document.querySelector('#lwt-unified-navbar__mobile-app-switcher-panel');
      if (appSwitcherMobilePanel) {
        var focusableElementsAppSwitcherPanel = appSwitcherMobilePanel.querySelectorAll(
          'button, a, [tabindex]:not([tabindex="-1"])'
        );
      }
      var focusedMobileAppSwitcherItemIndex = -1;
      var mobileAppSwitcherOpen = false;
      var mobileDropdownMenuItems = document.querySelectorAll(
        '.lwt-unified-navbar__mobile-menu-item.lwt-unified-navbar__dropdown-menu-item'
      );
      var activeAppSwitcherItem = { activeColumn: 0, activeItem: -1 };
      var mobileScreenWidth = 576;
      var appSectionItems = [];
      var appSwitcherSections = [];
      var launchPad = null;
      function removeHighlightedItemClass(menuItem) {
        if (menuItem) {
          menuItem.classList.remove('highlighted');
        }
      }

      function addHighlightedItemClass(menuItem) {
        if (menuItem) {
          menuItem.classList.add('highlighted');
        }
      }

      function removeHighlightedClass(menu) {
        const menuUL = findDomNodeByNodeName(menu.childNodes, 'UL')[0];
        const highlightedItem = [...menuUL.childNodes].filter(
          (node) => node.classList && node.classList.contains('highlighted')
        )[0];
        removeHighlightedItemClass(highlightedItem);
      }

      function setActiveDescendant(element, activeItemElement) {
        element.setAttribute('aria-activedescendant', activeItemElement.getAttribute('id'));
      }

      function setSubActiveDescendant(element, index, subIndex) {
        element.setAttribute('aria-activedescendant', `more-menu-item-${index}-${subIndex}`);
      }

      function removeActiveDescendant(element) {
        element.removeAttribute('aria-activedescendant');
      }

      function findDomNodeByNodeName(nodes, nodeName) {
        return [...nodes].filter((node) => node.nodeName === nodeName);
      }

      function focusNextElement(itemIndex, focusableElements, menuToggle, setIndex) {
        let newIndex = setIndex(itemIndex + 1);
        // If the item has sub items it the sub menu is closed choose the next main item
        if (focusableElements[itemIndex] && focusableElements[itemIndex].parentNode) {
          if (
            focusableElements[itemIndex].parentNode.classList.contains(
              'lwt-unified-navbar__dropdown-menu-item'
            )
          ) {
            const nextElementSibling = focusableElements[itemIndex].parentNode.nextElementSibling;
            if (nextElementSibling.classList.contains('menu-collapsed')) {
              const childElements = [...nextElementSibling.childNodes].filter(
                (node) =>
                  node.classList &&
                  node.classList.contains('lwt-unified-navbar__mobile-menu-item')
              );
              newIndex = setIndex(itemIndex + childElements.length + 1);
            }
          }
        }

        if (!focusableElements[newIndex]) {
          setIndex(-1);
          menuToggle.focus();
        } else {
          focusableElements[newIndex].focus();
        }
      }

      function focusPreviousElement(itemIndex, focusableElements, menuToggle, setIndex) {
        let newIndex = setIndex(itemIndex - 1);
        if (
          focusableElements[itemIndex] &&
          focusableElements[itemIndex].parentNode &&
          focusableElements[itemIndex - 1]
        ) {
          const previousElement = focusableElements[itemIndex - 1].parentNode.parentNode;
          if (previousElement.classList.contains('menu-collapsed')) {
            const childElements = [...previousElement.childNodes].filter(
              (node) =>
                node.classList && node.classList.contains('lwt-unified-navbar__mobile-menu-item')
            );
            newIndex = setIndex(itemIndex - childElements.length - 1);
          }
        }

        if (!focusableElements[newIndex]) {
          menuToggle.focus();
          if (newIndex < -1) {
            focusableElements[focusableElements.length - 1].focus();
            setIndex(focusableElements.length - 1);
          }
        } else {
          focusableElements[newIndex].focus();
        }
      }

      function focusFirstElement(menuToggle, setIndex) {
        menuToggle.focus();
        setIndex(-1);
      }

      function focusLastElement(focusableElements, setIndex) {
        focusableElements[focusableElements.length - 1].focus();
        setIndex(focusableElements.length - 1);
      }

      function handleSubMenuClick(e) {
        if (activeMoreSubItem === -1) {
          e.target.classList.toggle('menu-expanded');
          e.target.nextElementSibling.classList.toggle('sub-dropdown-menu-collapsed');
        }
      }

      function showHideOverflowMenu() {
        if (overflowItems.length > 0) {
          moreButton.classList.remove('hide-menu');
          moreButton.classList.add('show-menu');
          moreMenuList.innerHTML = '';
          overflowItems.map((element, index) => {
            const newLi = document.createElement('li');
            var clone = element.cloneNode(true);
            clone.setAttribute('tabindex', '-1');
            newLi.setAttribute(dataAttribute, clone.getAttribute(dataAttribute));
            newLi.setAttribute('id', 'more-menu-item-' + index);
            newLi.appendChild(clone);
            var childIndex = Array.from(moreMenuList.childNodes).findIndex(
              (child) => child.getAttribute(dataAttribute) === newLi.getAttribute(dataAttribute)
            );
            if (!moreMenuList.contains(newLi) && childIndex === -1) {
              // If the next element is a dropdown menu
              const nextElementSibling = element.nextElementSibling;
              if (
                nextElementSibling &&
                nextElementSibling.classList.contains('lwt-unified-navbar__dropdown-menu')
              ) {
                // If the dropdown menu is visible when it is going into the overflow menu hide it.
                const childMenu = findDomNodeByNodeName(nextElementSibling.childNodes, 'UL')[0];
                var childMenuClone = childMenu.cloneNode(true);
                childMenuClone.classList.add('sub-dropdown-menu-collapsed');
                const menuCloneLiElements = findDomNodeByNodeName(childMenuClone.childNodes, 'LI');
                menuCloneLiElements.map((childLi, childLiIndex) => {
                  childLi.setAttribute('id', 'more-menu-item-' + index + '-' + childLiIndex);
                  if (childLi.childNodes[0]) {
                    findDomNodeByNodeName(childLi.childNodes, 'A')[0].setAttribute(
                      'tabindex',
                      '-1'
                    );
                  }
                });
                newLi.appendChild(childMenuClone);
                newLi.addEventListener('click', (e) => handleSubMenuClick(e));
                newLi.classList.add('has-dropdown-items');
                if (nextElementSibling.classList.contains('show-menu')) {
                  nextElementSibling.classList.remove('show-menu');
                }
              }
              if (newLi.classList.contains('has-dropdown-items')) {
                const aElement = [...newLi.children].filter((node) => node.nodeName === 'A')[0];
                const ulElement = [...newLi.children].filter((node) => node.nodeName === 'UL')[0];
                if (aElement.classList.contains('active')) {
                  ulElement.classList.remove('sub-dropdown-menu-collapsed');
                  aElement.classList.add('menu-expanded');
                }
              }
              moreMenuList.appendChild(newLi);
              activeMoreItem = -1;
            }
          });
          // add the text node to the newly created div
        } else {
          hideMoreMenu(true);
          moreButton.classList.remove('show-menu');
          moreButton.classList.add('hide-menu');
        }
      }

      function focusMenu(menu) {
        menu.focus();
      }

      function hideMobileMenu(menuToggle, panel) {
        if (menuToggle && menuToggle.classList.contains('mobile-menu-open')) {
          menuToggle.classList.remove('mobile-menu-open');
        }
        if (panel && panel.classList.contains('panel-visible')) {
          panel.classList.remove('panel-visible');
        }
        mobileMenuOpen = false;
        notificationPanelOpen = false;
        mobileAppSwitcherOpen = false;
      }

      function handleResize(event, onLoad) {
        var overflowMenu = document.getElementById('overflow-menu');
        if (overflowMenu) {
          var childItemsArray = [...overflowMenu.children].filter((child) =>
            child.getAttribute(dataAttribute)
          );
          childItemsArray.map((child) => {
            if (onLoad) {
              amountToAdd = moreButton.offsetWidth;
            }
            var pos = child.offsetLeft - overflowMenu.offsetLeft + child.offsetWidth + amountToAdd;
            if (pos > overflowMenu.offsetWidth) {
              if (child) {
                if (overflowItems.indexOf(child) === -1) {
                  if (onLoad) {
                    overflowItems.push(child);
                  } else {
                    overflowItems.unshift(child);
                  }
                  if (overflowItems.length >= 1) {
                    amountToAdd = moreButton.offsetWidth;
                  } else {
                    amountToAdd = 0;
                  }
                }
              }
            } else {
              if (overflowItems.indexOf(child) > -1) {
                child.classList.remove('not-visible');
                overflowItems = overflowItems.filter(
                  (item) => item.getAttribute(dataAttribute) !== child.getAttribute(dataAttribute)
                );
                if (overflowItems.length === 1) {
                  amountToAdd = 0;
                } else {
                  amountToAdd = moreButton.offsetWidth;
                }
              }
            }
          });
          overflowItems.map((item) => item.classList.add('not-visible'));
          showHideOverflowMenu();
        }
        if (event) {
          if (event.target.outerWidth > mobileScreenWidth) {
            hideMobileMenu(appSwitcherToggle, appSwitcherMobilePanel);
            hideMobileMenu(mobileNotificationToggleButton, mobileNotificationPanel);
            hideMobileMenu(mobileMenuToggleButton, mobileMenuPanel);
          }
        }
      }

      function handleShowHideMenu() {
        moreMenuPopper.update();
        moreMenu.classList.toggle('show-menu');
        moreMenuOpen = !moreMenuOpen;
        setTimeout(() => {
          moreMenu.focus();
        }, 100);
      }

      function handleShowMoreMenuKeydown(e) {
        if (e.key === 'ArrowDown') {
          e.preventDefault();
          handleShowHideMenu();
          activeMoreItem += 1;
          // If on the list item select the first item
          if (moreMenuList.childNodes.length === activeMoreItem) {
            addHighlightedItemClass(moreMenuList.childNodes[0]);
            activeMoreItem = 0;
            setActiveDescendant(moreMenu, moreMenuList.childNodes[0]);
          } else {
            addHighlightedItemClass(moreMenuList.childNodes[activeMoreItem]);
            setActiveDescendant(moreMenu, moreMenuList.childNodes[activeMoreItem]);
          }
        }
      }

      function hideMoreMenu(focusButton) {
        if (focusButton) {
          moreButton.focus();
        }
        moreMenu.classList.remove('show-menu');
        removeActiveDescendant(moreMenu);
        if (moreMenuList.childNodes[activeMoreItem]) {
          removeHighlightedItemClass(moreMenuList.childNodes[activeMoreItem]);
        }
        const itemsWithDropDowns = [...moreMenuList.childNodes].filter((node) =>
          node.classList.contains('has-dropdown-items')
        );
        itemsWithDropDowns.map((dropdown) => {
          const ULMenu = findDomNodeByNodeName(dropdown.childNodes, 'UL')[0];
          if (ULMenu) {
            const LIsInULMenu = findDomNodeByNodeName(ULMenu.childNodes, 'LI');
            LIsInULMenu.map((node) => {
              removeHighlightedItemClass(node);
            });
          }
        });
        activeMoreItem = -1;
        moreMenuOpen = false;
        activeMoreSubItem = -1;
      }

      function handleMoreMenuKeydown(e) {
        const activeMenuItem = moreMenuList.childNodes[activeMoreItem];
        switch (e.key) {
          case 'ArrowDown':
            e.preventDefault();
            removeHighlightedItemClass(activeMenuItem);
            // Handle sub menu items
            if (activeMenuItem) {
              const childUL = findDomNodeByNodeName(activeMenuItem.childNodes, 'UL')[0];
              if (childUL && !childUL.classList.contains('sub-dropdown-menu-collapsed')) {
                const subMenuLiElements = findDomNodeByNodeName(childUL.childNodes, 'LI');
                if (subMenuLiElements[activeMoreSubItem]) {
                  removeHighlightedItemClass(subMenuLiElements[activeMoreSubItem]);
                }
                activeMoreSubItem = activeMoreSubItem + 1;
                if (subMenuLiElements.length === activeMoreSubItem) {
                  activeMoreSubItem = -1;
                }

                if (subMenuLiElements[activeMoreSubItem]) {
                  addHighlightedItemClass(subMenuLiElements[activeMoreSubItem]);
                  setSubActiveDescendant(moreMenu, activeMoreItem, activeMoreSubItem);
                  return false;
                }
              }
            }
            activeMoreItem += 1;
            // If on the list item select the first item
            if (moreMenuList.childNodes.length === activeMoreItem) {
              addHighlightedItemClass(moreMenuList.childNodes[0]);
              activeMoreItem = 0;
              setActiveDescendant(moreMenu, moreMenuList.childNodes[0]);
            } else {
              addHighlightedItemClass(moreMenuList.childNodes[activeMoreItem]);
              setActiveDescendant(moreMenu, moreMenuList.childNodes[activeMoreItem]);
            }
            break;
          case 'ArrowUp':
            e.preventDefault();
            if (activeMenuItem) {
              removeHighlightedItemClass(activeMenuItem);
            }
            if (activeMoreSubItem === -1) {
              activeMoreItem -= 1;
            }
            // If there is a sub menu and it is expanded highlight the item in the sub menu/else select the next main item.
            const itemWithSubMenu = moreMenuList.childNodes[activeMoreItem - 1]
              ? moreMenuList.childNodes[activeMoreItem - 1]
              : moreMenuList.childNodes[activeMoreItem];
            if (itemWithSubMenu) {
              const childUL = findDomNodeByNodeName(
                moreMenuList.childNodes[activeMoreItem].childNodes,
                'UL'
              )[0];

              if (childUL && !childUL.classList.contains('sub-dropdown-menu-collapsed')) {
                const subMenuLiElements = findDomNodeByNodeName(childUL.childNodes, 'LI');
                activeMoreSubItem =
                  activeMoreSubItem === -1 ? subMenuLiElements.length : activeMoreSubItem;
                removeHighlightedItemClass(subMenuLiElements[activeMoreSubItem]);
                activeMoreSubItem = activeMoreSubItem - 1;
                addHighlightedItemClass(subMenuLiElements[activeMoreSubItem]);
                if (subMenuLiElements[activeMoreSubItem]) {
                  setSubActiveDescendant(moreMenu, activeMoreItem, activeMoreSubItem);
                  return false;
                }
              }
            }

            // If on the fist item select the last item
            if (activeMoreItem <= -1) {
              // If the last item is a dropdown menu and it is expanded
              if (
                moreMenuList.childNodes[moreMenuList.childNodes.length - 1].classList.contains(
                  'has-dropdown-items'
                )
              ) {
                if (
                  moreMenuList.childNodes[
                    moreMenuList.childNodes.length - 1
                  ].childNodes[0].classList.contains('menu-expanded')
                ) {
                  const menuItems = findDomNodeByNodeName(
                    moreMenuList.childNodes[moreMenuList.childNodes.length - 1].childNodes[1]
                      .childNodes,
                    'LI'
                  );
                  addHighlightedItemClass(menuItems[menuItems.length - 1]);
                  activeMoreSubItem = menuItems.length - 1;
                  activeMoreItem = moreMenuList.childNodes.length - 1;
                  return;
                }
              }
              addHighlightedItemClass(moreMenuList.childNodes[moreMenuList.childNodes.length - 1]);

              activeMoreItem = moreMenuList.childNodes.length - 1;

              setActiveDescendant(
                moreMenu,
                moreMenuList.childNodes[moreMenuList.childNodes.length - 1]
              );
            } else {
              if (moreMenuList.childNodes[activeMoreItem]) {
                addHighlightedItemClass(moreMenuList.childNodes[activeMoreItem]);
                setActiveDescendant(moreMenu, moreMenuList.childNodes[activeMoreItem]);
              }
            }
            break;
          case 'End':
            e.preventDefault();
            // Select the last item
            removeHighlightedItemClass(activeMenuItem);
            // If the last item in the list has a dropdown and is expanded select the last item in the dropdown
            if (
              moreMenuList.childNodes[moreMenuList.childNodes.length - 1].classList.contains(
                'has-dropdown-items'
              )
            ) {
              if (
                moreMenuList.childNodes[
                  moreMenuList.childNodes.length - 1
                ].childNodes[0].classList.contains('menu-expanded')
              ) {
                const menuItems = findDomNodeByNodeName(
                  moreMenuList.childNodes[moreMenuList.childNodes.length - 1].childNodes[1]
                    .childNodes,
                  'LI'
                );

                addHighlightedItemClass(menuItems[menuItems.length - 1]);
                activeMoreSubItem = menuItems.length - 1;
                activeMoreItem = moreMenuList.childNodes.length - 1;
                setActiveDescendant(moreMenu, menuItems[menuItems.length - 1]);
                return;
              }
            }
            addHighlightedItemClass(moreMenuList.childNodes[moreMenuList.childNodes.length - 1]);
            activeMoreItem = moreMenuList.childNodes.length - 1;
            setActiveDescendant(moreMenu, moreMenuList.childNodes[moreMenuList.childNodes.length - 1]);
            break;
          case 'Home':
            e.preventDefault();
            // Select the first item
            removeHighlightedItemClass(activeMenuItem);
            addHighlightedItemClass(moreMenuList.childNodes[0]);
            activeMoreItem = 0;
            setActiveDescendant(moreMenu, moreMenuList.childNodes[0]);
            break;
          case 'Enter':
            e.preventDefault();
            const linkItem =
              activeMoreSubItem > -1
                ? findDomNodeByNodeName(
                  document.getElementById(
                    `more-menu-item-${activeMoreItem}-${activeMoreSubItem}`
                  ).childNodes,
                  'A'
                )[0]
                : document.getElementById(`more-menu-item-${activeMoreItem}`).childNodes[0];
            linkItem.click();
            if (!linkItem.classList.contains('lwt-unified-navbar__dropdown-menu-item')) {
              hideMoreMenu(true);
            }
            break;
          case 'Escape':
          case 'Tab':
            e.preventDefault();
            hideMoreMenu(true);
            break;
          default:
            break;
        }
      }

      function handleBodyClick(e) {
        if (moreMenuOpen) {
          if (!moreMenu.contains(e.target) && e.target !== moreButton) {
            hideMoreMenu(false);
          }
        }
        if (notificationMenuOpen) {
          if (
            !notificationMenu.contains(e.target) &&
            !notificationMenuToggle.contains(e.target) &&
            e.target !== notificationMenuToggle
          ) {
            notificationMenu.classList.remove('show-menu');
            removeHighlightedClass(notificationMenu);
            notificationMenuOpen = false;
            activeMenuItem = -1;
          }
        }

        if (userMenuOpen) {
          if (
            !userMenuToggle.contains(e.target) &&
            !userMenu.contains(e.target) &&
            e.target !== userMenuToggle
          ) {
            userMenu.classList.remove('show-menu');
            userMenuOpen = false;
            removeHighlightedClass(userMenu);
            activeMenuItem = -1;
          }
        }

        if (helpMenuOpen) {
          if (
            !helpMenuToggle.contains(e.target) &&
            !helpMenu.contains(e.target) &&
            e.target !== helpMenuToggle
          ) {
            helpMenu.classList.remove('show-menu');
            helpMenuOpen = false;
            removeHighlightedClass(helpMenu);
            activeMenuItem = -1;
          }
        }

        if (betaMenuOpen) {
          if (
            !betaMenuToggle.contains(e.target) &&
            !betaMenu.contains(e.target) &&
            e.target !== betaMenuToggle
          ) {
            betaMenu.classList.remove('show-menu');
            betaMenuOpen = false;
            removeHighlightedClass(betaMenu);
            activeMenuItem = -1;
          }
        }

        if (
          appSwitcherMenu &&
          appSwitcherMenu.classList.contains('show-menu') &&
          !appSwitcherMenu.contains(e.target) &&
          !appSwitcherToggle.contains(e.target) &&
          e.target !== appSwitcherToggle
        ) {
          appSwitcherMenu.classList.remove('show-menu');
          const parentWrapper = [...appSwitcherMenu.childNodes].filter(
            (node) =>
              node.classList &&
              node.classList.contains('lwt-unified-navbar__application-switcher-section-list')
          )[0];
          const appSwitcherSections = findDomNodeByNodeName(parentWrapper.childNodes, 'UL');
          appSectionItems = [
            ...appSwitcherSections[activeAppSwitcherItem.activeColumn].childNodes
          ].filter(
            (node) =>
              node.nodeName === 'LI' && node.classList && !node.classList.contains('section-header')
          );
          if (appSectionItems[activeAppSwitcherItem.activeItem]) {
            removeHighlightedItemClass(appSectionItems[activeAppSwitcherItem.activeItem]);
            removeActiveDescendant(appSwitcherMenu);
          }
          activeAppSwitcherItem = {
            activeColumn: 0,
            activeItem: -1
          };
        }
      }

      if (moreButton) {
        moreButton.addEventListener('click', handleShowHideMenu);
        moreButton.addEventListener('keydown', handleShowMoreMenuKeydown);
      }

      if (moreMenu) {
        moreMenu.addEventListener('keydown', handleMoreMenuKeydown);
      }

      function handlePopperItemClickOutside(e, toggleButton, menu) {
        if (!menu.contains(e.target) && e.target !== toggleButton) {
          menu.classList.remove('show-menu');
          activeMenuItem = -1;
        }
      }

      // Create dropdown menus in navbar
      if (_args && _args.dropdownMenuItems) {
        _args.dropdownMenuItems.map((menuItem) => {
          const customPopperMenu = createPopper(
            document.getElementById(menuItem.itemId),
            document.getElementById(menuItem.menuId),
            {
              placement: 'bottom-end',
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 8]
                  }
                }
              ]
            }
          );
          if (document.getElementById(menuItem.menuId)) {
            document.getElementById(menuItem.menuId).addEventListener('keydown', handleMenuKeydown);
            document
              .getElementById(menuItem.itemId)
              .addEventListener('click', (e) =>
                handleMenuClick(e, document.getElementById(menuItem.menuId), customPopperMenu)
              );
            document
              .getElementById(menuItem.itemId)
              .addEventListener('keydown', (e) =>
                handleMenuItemKeyDown(e, document.getElementById(menuItem.menuId))
              );
            document.getElementById(menuItem.itemId).addEventListener('click', (e) => {
              e.preventDefault();
              e.stopPropagation();
            });
            document.body.addEventListener('click', (e) =>
              handlePopperItemClickOutside(
                e,
                document.getElementById(menuItem.itemId),
                document.getElementById(menuItem.menuId)
              )
            );
          }
        });
      }

      // Create dropdown menu for them user menu
      const userMenuPopper = createPopper(userMenuToggle, userMenu, {
        placement: 'bottom-end',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8]
            }
          }
        ]
      });

      // Create dropdown menu for them user menu
      const helpMenuPopper = createPopper(helpMenuToggle, helpMenu, {
        placement: 'bottom-end',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8]
            }
          }
        ]
      });

      // Create dropdown menu for them user menu
      const betaMenuPopper = createPopper(betaMenuToggle, betaMenu, {
        placement: 'bottom-end',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ],
      });

      // Create the dropdown menu for the app switcher
      const appSwitcherPopper = createPopper(appSwitcherToggle, appSwitcherMenu, {
        placement: 'bottom-start',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8]
            }
          }
        ]
      });

      function handleMenuClick(e, menu, popperMenu) {
        if (userMenu !== menu) {
          userMenu.classList.remove('show-menu');
        }
        if (notificationMenu !== menu) {
          notificationMenu.classList.remove('show-menu');
        }

        if (notificationMenu === menu) {
          notificationMenuPopper.update();
          notificationMenuOpen = true;
        }

        if (userMenu === menu) {
          userMenuPopper.update();
          userMenuOpen = true;
        }

        if (helpMenu === menu) {
          helpMenuPopper.update();
          helpMenuOpen = true;
        }

        if (betaMenu === menu) {
          betaMenuPopper.update();
          betaMenuOpen = true;
        }

        if (appSwitcherMenu === menu) {
          appSwitcherPopper.update();
          activeToggle = e.target;
          if (document.body.clientWidth > mobileScreenWidth) {
            appSwitcherMenu.classList.toggle('show-menu');
            focusMenu(appSwitcherMenu);
          } else {
            toggleAppSwitcherPanel(e);
          }
          return;
        }
        if (menu) {
          activeToggle = e.target;
          menu.classList.toggle('show-menu');
          focusMenu(menu);
        }

        if (popperMenu) {
          popperMenu.update();
        }
      }

      function handleMenuItemKeyDown(e, menu) {
        switch (e.key) {
          case 'ArrowDown':
            menu.classList.add('show-menu');
            menu.focus();
            activeToggle = e.target;
            const ULMenu = findDomNodeByNodeName(menu.childNodes, 'UL')[0];
            const menuItems = findDomNodeByNodeName(ULMenu.childNodes, 'LI');
            activeMenuItem = menuItems[0].classList.contains(
              'lwt-unified-navbar__dropdown-menu-section-header'
            )
              ? 1
              : 0;
            if (menuItems[activeMenuItem]) {
              addHighlightedItemClass(menuItems[activeMenuItem]);
              setActiveDescendant(menu, menuItems[activeMenuItem]);
            } else {
              removeHighlightedItemClass(menuItems[0]);
              setActiveDescendant(menu, menuItems[0]);
              activeMenuItem = 0;
            }
            break;
          default:
        }
      }

      function handleMenuKeydown(e) {
        const menu = findDomNodeByNodeName(e.target.childNodes, 'UL')[0];
        if (!menu) return false;
        const menuItems = findDomNodeByNodeName(menu.childNodes, 'LI');
        const menuItemsLength = menuItems.length;
        switch (e.key) {
          case 'ArrowDown':
            e.stopPropagation();
            e.preventDefault();
            removeHighlightedItemClass(menuItems[activeMenuItem]);
            activeMenuItem = activeMenuItem + 1;
            // If the next menu item is a divider skip it
            if (
              menuItems[activeMenuItem] &&
              menuItems[activeMenuItem].classList.contains(
                'lwt-unified-navbar__dropdown-menu-divider'
              )
            ) {
              activeMenuItem += 1;
            }
            // If the next item is a header skip it
            // It would be nice to combine these two checks into one at some point
            if (
              menuItems[activeMenuItem] &&
              menuItems[activeMenuItem].classList.contains(
                'lwt-unified-navbar__dropdown-menu-section-header'
              )
            ) {
              activeMenuItem += 1;
            }
            if (menuItems[activeMenuItem]) {
              addHighlightedItemClass(menuItems[activeMenuItem]);
              setActiveDescendant(menu.parentNode, menuItems[activeMenuItem]);
            } else {
              if (appSwitcherMenu && appSwitcherMenu.classList.contains('show-menu')) {
                e.target.classList.remove('show-menu');
                removeActiveDescendant(appSwitcherMenu);
                removeHighlightedItemClass(appSectionItems[activeAppSwitcherItem.activeItem]);
                activeMenuItem = -1;
                activeAppSwitcherItem.activeItem = activeAppSwitcherItem.activeItem + 1;
                if (
                  activeAppSwitcherItem.activeItem >= appSectionItems.length &&
                  activeAppSwitcherItem.activeColumn === appSwitcherSections.length - 1
                ) {
                  activeAppSwitcherItem.activeColumn = 0;
                  activeAppSwitcherItem.activeItem = 0;
                  if (launchPad) {
                    activeAppSwitcherItem.activeColumn = 0;
                    activeAppSwitcherItem.activeItem = -1;
                    addHighlightedItemClass(launchPad);
                  }
                }

                if (
                  activeAppSwitcherItem.activeItem >= appSectionItems.length &&
                  activeAppSwitcherItem.activeColumn < appSwitcherSections.length - 1
                ) {
                  {
                    activeAppSwitcherItem.activeColumn = activeAppSwitcherItem.activeColumn + 1;
                    activeAppSwitcherItem.activeItem = 0;
                  }
                }

                appSwitcherMenu.focus();

                setActiveDescendant(
                  appSwitcherMenu,
                  appSectionItems[activeAppSwitcherItem.activeItem]
                );
                addHighlightedItemClass(appSectionItems[activeAppSwitcherItem.activeItem]);
                return false;
              }
              const firstItem = menuItems[0].classList.contains(
                'lwt-unified-navbar__dropdown-menu-section-header'
              )
                ? 1
                : 0;
              addHighlightedItemClass(menuItems[firstItem]);
              setActiveDescendant(menu.parentNode, menuItems[firstItem]);
              activeMenuItem = firstItem;
            }
            break;
          case 'ArrowUp':
            {
              e.preventDefault();
              e.stopPropagation();
              removeHighlightedItemClass(menuItems[activeMenuItem]);
              const firstItem = menuItems[0].classList.contains(
                'lwt-unified-navbar__dropdown-menu-section-header'
              )
                ? 1
                : 0;
              if (activeMenuItem === firstItem || activeMenuItem === -1) {
                if (appSwitcherMenu && appSwitcherMenu.classList.contains('show-menu')) {
                  e.target.classList.remove('show-menu');
                  activeMenuItem = -1;
                  appSectionItems = [
                    ...appSwitcherSections[appSwitcherSections.length - 1].childNodes
                  ].filter(
                    (node) =>
                      node.nodeName === 'LI' &&
                      node.classList &&
                      !node.classList.contains('section-header')
                  );

                  if (
                    activeAppSwitcherItem.activeItem === -1 &&
                    activeAppSwitcherItem.activeColumn > 0
                  ) {
                    activeAppSwitcherItem.activeColumn = activeAppSwitcherItem.activeColumn - 1;
                    activeAppSwitcherItem.activeItem =
                      [
                        ...appSwitcherSections[activeAppSwitcherItem.activeColumn]
                          .childNodes
                      ].filter(
                        (node) =>
                          node.nodeName === 'LI' &&
                          node.classList &&
                          !node.classList.contains('section-header')
                      ).length - 1;
                    appSectionItems = [
                      ...appSwitcherSections[activeAppSwitcherItem.activeColumn].childNodes
                    ].filter(
                      (node) =>
                        node.nodeName === 'LI' &&
                        node.classList &&
                        !node.classList.contains('section-header')
                    );
                  }
                  appSwitcherMenu.focus();
                  return false;
                }
                addHighlightedItemClass(menuItems[menuItemsLength - 1]);
                activeMenuItem = menuItemsLength - 1;
                setActiveDescendant(menu.parentNode, menuItems[menuItemsLength - 1]);
              } else {
                activeMenuItem = activeMenuItem - 1;
                // If the next item is a header skip it
                // It would be nice to combine these two checks into one at some point
                if (
                  menuItems[activeMenuItem] &&
                  menuItems[activeMenuItem].classList.contains(
                    'lwt-unified-navbar__dropdown-menu-section-header'
                  )
                ) {
                  activeMenuItem -= 1;
                }
                // If the previous item is a divider skip it
                if (
                  menuItems[activeMenuItem] &&
                  menuItems[activeMenuItem].classList.contains(
                    'lwt-unified-navbar__dropdown-menu-divider'
                  )
                ) {
                  activeMenuItem -= 1;
                }
                addHighlightedItemClass(menuItems[activeMenuItem]);
                setActiveDescendant(menu.parentNode, menuItems[activeMenuItem]);
              }
            }
            break;
          case 'Enter':
            e.preventDefault();
            e.stopPropagation();
            const itemToClick = [...menuItems[activeMenuItem].childNodes].filter(
              (node) => node.nodeName === 'A' || node.nodeName === 'BUTTON'
            )[0];
            itemToClick.click();
            break;
          case 'Escape':
          case 'Tab':
            e.preventDefault();
            e.target.classList.remove('show-menu');
            removeHighlightedItemClass(menuItems[activeMenuItem]);
            activeMenuItem = -1;
            activeToggle.focus();
            break;
          case 'End':
            e.preventDefault();
            removeHighlightedItemClass(menuItems[activeMenuItem]);
            activeMenuItem = menuItemsLength - 1;
            if (menuItems[activeMenuItem]) {
              addHighlightedItemClass(menuItems[activeMenuItem]);
              setActiveDescendant(menu.parentNode, menuItems[activeMenuItem]);
            }
            break;
          case 'ArrowRight':
          case 'ArrowLeft':
            e.preventDefault();
            e.stopPropagation();
            break;
          case 'Home':
            e.preventDefault();
            removeHighlightedItemClass(menuItems[activeMenuItem]);
            activeMenuItem = menuItems[0].classList.contains(
              'lwt-unified-navbar__dropdown-menu-section-header'
            )
              ? 1
              : 0;
            if (menuItems[activeMenuItem]) {
              addHighlightedItemClass(menuItems[activeMenuItem]);
              setActiveDescendant(menu.parentNode, menuItems[activeMenuItem]);
            }
            break;
          default:
          // do nothing
        }
      }

      function setMobileMenuIndex(index) {
        return (focusedMobileMenuItemIndex = index);
      }

      function setMobileNotificationIndex(index) {
        return (focusedMobileNotificationItemIndex = index);
      }

      function setMobileAppSwitcherIndex(index) {
        return (focusedMobileAppSwitcherItemIndex = index);
      }

      function togglePanel(e) {
        e.target.classList.toggle('mobile-menu-open');
        if (mobileMenuPanel) mobileMenuPanel.classList.toggle('panel-visible');
        if (mobileNotificationPanel) mobileNotificationPanel.classList.remove('panel-visible');
        if (appSwitcherMobilePanel) appSwitcherMobilePanel.classList.remove('panel-visible');
        if (mobileNotificationToggleButton)
          mobileNotificationToggleButton.classList.remove('mobile-menu-open');
        if (appSwitcherToggle) appSwitcherToggle.classList.remove('mobile-menu-open');
        mobileMenuOpen = !mobileMenuOpen;
        focusedMobileMenuItemIndex = -1;
        notificationPanelOpen = false;
      }

      function toggleNotificationPanel(e) {
        e.target.classList.toggle('mobile-menu-open');
        if (mobileNotificationPanel) mobileNotificationPanel.classList.toggle('panel-visible');
        if (mobileMenuPanel) mobileMenuPanel.classList.remove('panel-visible');
        if (appSwitcherMobilePanel) appSwitcherMobilePanel.classList.remove('panel-visible');
        if (appSwitcherToggle) appSwitcherToggle.classList.remove('mobile-menu-open');
        if (mobileMenuToggleButton) mobileMenuToggleButton.classList.remove('mobile-menu-open');
        notificationPanelOpen = !notificationPanelOpen;
        focusedMobileNotificationItemIndex = -1;
        mobileMenuOpen = false;
      }

      function toggleAppSwitcherPanel(e) {
        if (appSwitcherMobilePanel) appSwitcherMobilePanel.classList.toggle('panel-visible');
        if (appSwitcherToggle) appSwitcherToggle.classList.toggle('mobile-menu-open');
        if (mobileMenuPanel) mobileMenuPanel.classList.remove('panel-visible');
        if (mobileMenuToggleButton) mobileMenuToggleButton.classList.remove('mobile-menu-open');
        if (mobileNotificationToggleButton)
          mobileNotificationToggleButton.classList.remove('mobile-menu-open');
        if (mobileNotificationPanel) mobileNotificationPanel.classList.remove('panel-visible');
        mobileAppSwitcherOpen = !mobileAppSwitcherOpen;
        focusedMobileAppSwitcherItemIndex = -1;
        mobileMenuOpen = false;
        notificationPanelOpen = false;
      }

      function hidePanel(e, menu) {
        menu.classList.remove('panel-visible');
        e.classList.remove('mobile-menu-open');
        if (menu === mobileMenuPanel) {
          mobileMenuOpen = false;
        }
        if (menu === mobileNotificationPanel) {
          notificationPanelOpen = false;
        }
      }

      function closePanel(menuToggle, menu) {
        menuToggle.focus();
        hidePanel(menuToggle, menu);
      }

      function tabPanel(e, itemIndex, focusableElements, menuToggle, setIndex) {
        if (e.shiftKey) {
          var newIndex = setIndex(itemIndex - 1);
          if (!focusableElements[newIndex]) {
            e.preventDefault();
            menuToggle.focus();
            if (newIndex < -1) {
              focusableElements[focusableElements.length - 1].focus();
              setIndex(focusableElements.length - 1);
            }
          }
          return false;
        }
        var newItemIndex = setIndex(itemIndex + 1);
        if (!focusableElements[newItemIndex]) {
          e.preventDefault();
          setIndex(-1);
          menuToggle.focus();
        }
      }

      function handleMobileMenuButtonKeydown(e) {
        if (mobileMenuOpen) {
          switch (e.key) {
            case 'ArrowDown':
              e.preventDefault();
              focusNextElement(
                focusedMobileMenuItemIndex,
                focusableElementsMobileMenu,
                mobileMenuToggleButton,
                setMobileMenuIndex
              );
              break;
            case 'ArrowUp':
              e.preventDefault();
              focusPreviousElement(
                focusedMobileMenuItemIndex,
                focusableElementsMobileMenu,
                mobileMenuToggleButton,
                setMobileMenuIndex
              );
              break;
            case 'Home':
              e.preventDefault();
              focusFirstElement(mobileMenuToggleButton, setMobileMenuIndex);
              break;
            case 'End':
              e.preventDefault();
              const focusableElements = [...focusableElementsMobileMenu];
              const lastFocusableElement = focusableElements[focusableElements.length - 1];
              // If the last item is a collapsable menu and it is collapsed focus the parent node
              if (
                lastFocusableElement.parentNode.parentNode &&
                lastFocusableElement.parentNode.parentNode.classList.contains('menu-collapsed')
              ) {
                const childElements = [
                  ...lastFocusableElement.parentNode.parentNode.childNodes
                ].filter(
                  (element) =>
                    element.classList &&
                    element.classList.contains('lwt-unified-navbar__mobile-menu-item')
                );
                focusableElements[focusableElements.length - childElements.length - 1].focus();
                setMobileMenuIndex(focusableElements.length - childElements.length - 1);
                return;
              }
              focusLastElement(focusableElementsMobileMenu, setMobileMenuIndex);
              break;
            case 'Escape':
              e.preventDefault();
              closePanel(mobileMenuToggleButton, mobileMenuPanel);
              break;
            case 'Tab':
              tabPanel(
                e,
                focusedMobileMenuItemIndex,
                focusableElementsMobileMenu,
                mobileMenuToggleButton,
                setMobileMenuIndex
              );
              break;
            default:
            // do nothing
          }
        }
      }

      function handleMobileNotificationButtonKeydown(e) {
        if (notificationPanelOpen) {
          switch (e.key) {
            case 'ArrowDown':
              e.preventDefault();
              focusNextElement(
                focusedMobileNotificationItemIndex,
                focusableElementsNotificationPanel,
                mobileNotificationToggleButton,
                setMobileNotificationIndex
              );
              break;
            case 'ArrowUp':
              e.preventDefault();
              focusPreviousElement(
                focusedMobileNotificationItemIndex,
                focusableElementsNotificationPanel,
                mobileNotificationToggleButton,
                setMobileNotificationIndex
              );
              break;
            case 'Home':
              e.preventDefault();
              focusFirstElement(mobileNotificationToggleButton, setMobileNotificationIndex);
              break;
            case 'End':
              e.preventDefault();
              focusLastElement(focusableElementsNotificationPanel, setMobileNotificationIndex);
              break;
            case 'Escape':
              e.preventDefault();
              closePanel(mobileNotificationToggleButton, mobileNotificationPanel);
              break;
            case 'Tab':
              tabPanel(
                e,
                focusedMobileNotificationItemIndex,
                focusableElementsNotificationPanel,
                mobileNotificationToggleButton,
                setMobileNotificationIndex
              );
              break;
            default:
            // do nothing
          }
        }
      }

      function clickInstanceButton(e, menu) {
        e.click();
      }

      function handleAppSwitcherKeyDown(e) {
        e.preventDefault();
        const parentWrapper = [...e.target.childNodes].filter(
          (node) =>
            node.classList &&
            node.classList.contains('lwt-unified-navbar__application-switcher-section-list')
        )[0];
        launchPad = e.target.querySelector('.lwt-unified-navbar__application-switcher-section-launchpad');
        if (parentWrapper) {
          appSwitcherSections = findDomNodeByNodeName(parentWrapper.childNodes, 'UL');
        }

        const activeColumn = activeAppSwitcherItem.activeColumn;
        const activeItem = activeAppSwitcherItem.activeItem;
        if (appSwitcherSections) {
          appSectionItems = [
            ...appSwitcherSections[activeAppSwitcherItem.activeColumn].childNodes
          ].filter(
            (node) =>
              node.nodeName === 'LI' && node.classList && !node.classList.contains('section-header')
          );
        }
        switch (e.key) {
          case 'ArrowDown':
            e.preventDefault();

            if (appSectionItems[activeAppSwitcherItem.activeItem]) {
              // find closest element with the class lwt-unified-navbar__instance-badge-button
              const instanceBadgeButton = appSectionItems[
                activeAppSwitcherItem.activeItem
              ].querySelector('.lwt-unified-navbar__instance-badge-button');
              // find the dropdown menu
              const dropdownMenu = appSectionItems[activeAppSwitcherItem.activeItem].querySelector(
                '.lwt-unified-navbar__dropdown-menu'
              );
              if (instanceBadgeButton) {
                clickInstanceButton(instanceBadgeButton, dropdownMenu);

                // fire arrow down event on the dropdown menu
                dropdownMenu.dispatchEvent(new KeyboardEvent('keydown', { key: 'ArrowDown' }));
                dropdownMenu.focus();
                return false;
              }
              removeActiveDescendant(appSwitcherMenu);
              removeHighlightedItemClass(appSectionItems[activeAppSwitcherItem.activeItem]);
            }
            activeAppSwitcherItem = {
              activeColumn,
              activeItem: activeItem + 1
            };
            if (appSectionItems[activeAppSwitcherItem.activeItem]) {
              setActiveDescendant(appSwitcherMenu, appSectionItems[activeAppSwitcherItem.activeItem]);
              addHighlightedItemClass(appSectionItems[activeAppSwitcherItem.activeItem]);
              removeHighlightedItemClass(launchPad);
            } else {
              // Select the first item in the next column

              activeAppSwitcherItem = {
                activeColumn: activeColumn + 1,
                activeItem: 0
              };
              if (appSwitcherSections[activeAppSwitcherItem.activeColumn]) {
                appSectionItems = [
                  ...appSwitcherSections[activeAppSwitcherItem.activeColumn].childNodes
                ].filter(
                  (node) =>
                    node.nodeName === 'LI' &&
                    node.classList &&
                    !node.classList.contains('section-header')
                );
                addHighlightedItemClass(appSectionItems[activeAppSwitcherItem.activeItem]);
                setActiveDescendant(
                  appSwitcherMenu,
                  appSectionItems[activeAppSwitcherItem.activeItem]
                );
              } else {
                // If on the last item in the last column select the first item in the first column
                if (launchPad) {
                  addHighlightedItemClass(launchPad);
                  activeAppSwitcherItem = { activeColumn: 0, activeItem: -1 };
                  return false;
                }
                activeAppSwitcherItem = { activeColumn: 0, activeItem: 0 };
                appSectionItems = [
                  ...appSwitcherSections[activeAppSwitcherItem.activeColumn].childNodes
                ].filter(
                  (node) =>
                    node.nodeName === 'LI' &&
                    node.classList &&
                    !node.classList.contains('section-header')
                );
                addHighlightedItemClass(appSectionItems[activeAppSwitcherItem.activeItem]);
                setActiveDescendant(
                  appSwitcherMenu,
                  appSectionItems[activeAppSwitcherItem.activeItem]
                );
              }
            }
            break;
          case 'ArrowUp':
            e.preventDefault();
            if (launchPad && launchPad.classList.contains('highlighted')) {
              removeHighlightedItemClass(launchPad);
              appSectionItems = [
                ...appSwitcherSections[appSwitcherSections.length - 1].childNodes
              ].filter(
                (node) =>
                  node.nodeName === 'LI' &&
                  node.classList &&
                  !node.classList.contains('section-header')
              );
              activeAppSwitcherItem = {
                activeColumn: appSwitcherSections.length - 1,
                activeItem: appSectionItems.length - 1
              };
              addHighlightedItemClass(appSectionItems[appSectionItems.length - 1]);
              return false;
            }
            if (appSectionItems[activeAppSwitcherItem.activeItem]) {
              removeHighlightedItemClass(appSectionItems[activeAppSwitcherItem.activeItem]);
              removeActiveDescendant(appSwitcherMenu);
            }
            activeAppSwitcherItem = {
              activeColumn,
              activeItem: activeItem - 1
            };
            if (appSectionItems[activeAppSwitcherItem.activeItem]) {
              addHighlightedItemClass(appSectionItems[activeAppSwitcherItem.activeItem]);
              setActiveDescendant(appSwitcherMenu, appSectionItems[activeAppSwitcherItem.activeItem]);
            } else {
              activeAppSwitcherItem = {
                activeColumn: activeColumn - 1,
                activeItem: 0
              };
              if (appSwitcherSections[activeAppSwitcherItem.activeColumn]) {
                appSectionItems = [
                  ...appSwitcherSections[activeAppSwitcherItem.activeColumn].childNodes
                ].filter(
                  (node) =>
                    node.nodeName === 'LI' &&
                    node.classList &&
                    !node.classList.contains('section-header')
                );

                addHighlightedItemClass(appSectionItems[appSectionItems.length - 1]);
                setActiveDescendant(appSwitcherMenu, appSectionItems[appSectionItems.length - 1]);
                activeAppSwitcherItem = {
                  activeColumn: activeColumn - 1,
                  activeItem: appSectionItems.length - 1
                };
              } else {
                // Select the last column in the last row
                if (launchPad) {
                  addHighlightedItemClass(launchPad);
                  activeAppSwitcherItem = { activeColumn: 0, activeItem: -1 };
                  return false;
                }
                appSectionItems = [
                  ...appSwitcherSections[appSwitcherSections.length - 1].childNodes
                ].filter(
                  (node) =>
                    node.nodeName === 'LI' &&
                    node.classList &&
                    !node.classList.contains('section-header')
                );
                addHighlightedItemClass(appSectionItems[appSectionItems.length - 1]);
                setActiveDescendant(appSwitcherMenu, appSectionItems[appSectionItems.length - 1]);
                activeAppSwitcherItem = {
                  activeColumn: appSwitcherSections.length - 1,
                  activeItem: appSectionItems.length - 1
                };
              }
            }
            break;
          case 'ArrowLeft':
            e.preventDefault();
            // Remove the highlighted class from the highlighted item
            if (appSectionItems[activeAppSwitcherItem.activeItem]) {
              removeHighlightedItemClass(appSectionItems[activeAppSwitcherItem.activeItem]);
              removeActiveDescendant(appSwitcherMenu);
            }
            activeAppSwitcherItem = {
              activeColumn: activeAppSwitcherItem.activeColumn - 1,
              activeItem: activeAppSwitcherItem.activeItem
            };
            if (appSwitcherSections[activeAppSwitcherItem.activeColumn]) {
              appSectionItems = [
                ...appSwitcherSections[activeAppSwitcherItem.activeColumn].childNodes
              ].filter(
                (node) =>
                  node.nodeName === 'LI' &&
                  node.classList &&
                  !node.classList.contains('section-header')
              );
              if (appSectionItems[activeAppSwitcherItem.activeItem]) {
                addHighlightedItemClass(appSectionItems[activeAppSwitcherItem.activeItem]);
                setActiveDescendant(
                  appSwitcherMenu,
                  appSectionItems[activeAppSwitcherItem.activeItem]
                );
              } else {
                appSectionItems = [
                  ...appSwitcherSections[activeAppSwitcherItem.activeColumn].childNodes
                ].filter(
                  (node) =>
                    node.nodeName === 'LI' &&
                    node.classList &&
                    !node.classList.contains('section-header')
                );
                activeAppSwitcherItem = {
                  activeColumn: activeAppSwitcherItem.activeColumn,
                  activeItem: appSectionItems.length - 1
                };
                addHighlightedItemClass(appSectionItems[activeAppSwitcherItem.activeItem]);
                setActiveDescendant(
                  appSwitcherMenu,
                  appSectionItems[activeAppSwitcherItem.activeItem]
                );
              }
            } else {
              // Select the selected item in the last column
              activeAppSwitcherItem = {
                activeColumn: appSwitcherSections.length - 1,
                activeItem: activeAppSwitcherItem.activeItem
              };
              appSectionItems = [
                ...appSwitcherSections[appSwitcherSections.length - 1].childNodes
              ].filter(
                (node) =>
                  node.nodeName === 'LI' &&
                  node.classList &&
                  !node.classList.contains('section-header')
              );
              if (appSectionItems[activeAppSwitcherItem.activeItem]) {
                addHighlightedItemClass(appSectionItems[activeAppSwitcherItem.activeItem]);
                setActiveDescendant(
                  appSwitcherMenu,
                  appSectionItems[activeAppSwitcherItem.activeItem]
                );
              } else {
                // if there is no item in the last column at the selected index select the last item in the list
                addHighlightedItemClass(appSectionItems[appSectionItems.length - 1]);
                setActiveDescendant(appSwitcherMenu, appSectionItems[appSectionItems.length - 1]);
              }
            }
            break;
          case 'ArrowRight':
            e.preventDefault();
            // Remove the highlighted class from the highlighted item
            if (appSectionItems[activeAppSwitcherItem.activeItem]) {
              removeHighlightedItemClass(appSectionItems[activeAppSwitcherItem.activeItem]);
              removeActiveDescendant(appSwitcherMenu);
            }
            activeAppSwitcherItem = {
              activeColumn: activeAppSwitcherItem.activeColumn + 1,
              activeItem: activeAppSwitcherItem.activeItem
            };
            if (appSwitcherSections[activeAppSwitcherItem.activeColumn]) {
              appSectionItems = [
                ...appSwitcherSections[activeAppSwitcherItem.activeColumn].childNodes
              ].filter(
                (node) =>
                  node.nodeName === 'LI' &&
                  node.classList &&
                  !node.classList.contains('section-header')
              );
              if (appSectionItems[activeAppSwitcherItem.activeItem]) {
                addHighlightedItemClass(appSectionItems[activeAppSwitcherItem.activeItem]);
                setActiveDescendant(
                  appSwitcherMenu,
                  appSectionItems[activeAppSwitcherItem.activeItem]
                );
              } else {
                appSectionItems = [
                  ...appSwitcherSections[activeAppSwitcherItem.activeColumn].childNodes
                ].filter(
                  (node) =>
                    node.nodeName === 'LI' &&
                    node.classList &&
                    !node.classList.contains('section-header')
                );
                activeAppSwitcherItem = {
                  activeColumn: activeAppSwitcherItem.activeColumn,
                  activeItem: appSectionItems.length - 1
                };
                addHighlightedItemClass(appSectionItems[activeAppSwitcherItem.activeItem]);

                setActiveDescendant(
                  appSwitcherMenu,
                  appSectionItems[activeAppSwitcherItem.activeItem]
                );
              }
            } else {
              // Select the item with the selected index in the first column
              activeAppSwitcherItem = {
                activeColumn: 0,
                activeItem: activeAppSwitcherItem.activeItem
              };
              appSectionItems = [...appSwitcherSections[0].childNodes].filter(
                (node) =>
                  node.nodeName === 'LI' &&
                  node.classList &&
                  !node.classList.contains('section-header')
              );
              if (appSectionItems[activeAppSwitcherItem.activeItem]) {
                addHighlightedItemClass(appSectionItems[activeAppSwitcherItem.activeItem]);
                setActiveDescendant(
                  appSwitcherMenu,
                  appSectionItems[activeAppSwitcherItem.activeItem]
                );
              } else {
                // Select the last item in the first column
                addHighlightedItemClass(appSectionItems[appSectionItems.length - 1]);
                setActiveDescendant(appSwitcherMenu, appSectionItems[appSectionItems.length - 1]);
              }
            }
            break;
          case 'Home':
            e.preventDefault();
            // Select the first item in the first column
            if (appSectionItems[activeAppSwitcherItem.activeItem]) {
              removeHighlightedItemClass(appSectionItems[activeAppSwitcherItem.activeItem]);
              removeActiveDescendant(appSwitcherMenu);
            }
            activeAppSwitcherItem = { activeColumn: 0, activeItem: 0 };
            appSectionItems = [
              ...appSwitcherSections[activeAppSwitcherItem.activeColumn].childNodes
            ].filter(
              (node) =>
                node.nodeName === 'LI' &&
                node.classList &&
                !node.classList.contains('section-header')
            );

            addHighlightedItemClass(appSectionItems[activeAppSwitcherItem.activeItem]);
            setActiveDescendant(appSwitcherMenu, appSectionItems[activeAppSwitcherItem.activeItem]);
            break;
          case 'End':
            e.preventDefault();
            if (launchPad) {
              addHighlightedItemClass(launchPad);
              setActiveDescendant(appSwitcherMenu, launchPad);
              return false;
            }
            // Select the last item in the last column
            if (appSectionItems[activeAppSwitcherItem.activeItem]) {
              removeHighlightedItemClass(appSectionItems[activeAppSwitcherItem.activeItem]);
              removeActiveDescendant(appSwitcherMenu);
            }
            appSectionItems = [
              ...appSwitcherSections[appSwitcherSections.length - 1].childNodes
            ].filter(
              (node) =>
                node.nodeName === 'LI' &&
                node.classList &&
                !node.classList.contains('section-header')
            );
            addHighlightedItemClass(appSectionItems[appSectionItems.length - 1]);
            activeAppSwitcherItem = {
              activeColumn: appSwitcherSections.length - 1,
              activeItem: appSectionItems.length - 1
            };
            setActiveDescendant(appSwitcherMenu, appSectionItems[activeAppSwitcherItem.activeItem]);

            break;
          case 'Enter':
            e.preventDefault();
            if (launchPad && launchPad.classList.contains('highlighted')) {
              // find the first link in the launchpad and click it
              const linkItem = findDomNodeByNodeName(launchPad.childNodes, 'A')[0];
              linkItem.click();
              removeActiveDescendant(appSwitcherMenu);
              return false;
            }
            const linkItem = findDomNodeByNodeName(
              appSectionItems[activeAppSwitcherItem.activeItem].childNodes,
              'A'
            )[0];
            // close the app switcher

            e.target.classList.remove('show-menu');
            removeHighlightedItemClass(appSectionItems[activeAppSwitcherItem.activeItem]);
            activeAppSwitcherItem = {
              activeColumn: 0,
              activeItem: -1
            };
            // remove the highlighted class from the item
            linkItem.click();
            appSwitcherToggle.focus();

            removeActiveDescendant(appSwitcherMenu);
            break;
          case 'Escape':
          case 'Tab':
            e.preventDefault();
            appSwitcherToggle.focus();
            e.target.classList.remove('show-menu');
            if (appSectionItems[activeAppSwitcherItem.activeItem]) {
              removeHighlightedItemClass(appSectionItems[activeAppSwitcherItem.activeItem]);
              removeActiveDescendant(appSwitcherMenu);
            }
            activeAppSwitcherItem = {
              activeColumn: 0,
              activeItem: -1
            };
            removeActiveDescendant(appSwitcherMenu);
            break;
          default:
          // Do nothing
        }
      }

      function handleAppSwitcherToggleKeyDown(e) {
        switch (e.key) {
          case 'ArrowDown':
            e.preventDefault();
            if (document.body.clientWidth <= mobileScreenWidth) {
              focusNextElement(
                focusedMobileAppSwitcherItemIndex,
                focusableElementsAppSwitcherPanel,
                appSwitcherToggle,
                setMobileAppSwitcherIndex
              );
            } else {
              appSwitcherMenu.classList.add('show-menu');
              appSwitcherMenu.focus();
              const activeColumn = activeAppSwitcherItem.activeColumn;
              const activeItem = activeAppSwitcherItem.activeItem;
              const parentWrapper = [...appSwitcherMenu.childNodes].filter(
                (node) =>
                  node.classList &&
                  node.classList.contains('lwt-unified-navbar__application-switcher-section-list')
              )[0];
              const appSwitcherSections = findDomNodeByNodeName(parentWrapper.childNodes, 'UL');

              let appSectionItems = [
                ...appSwitcherSections[activeAppSwitcherItem.activeColumn].childNodes
              ].filter(
                (node) =>
                  node.nodeName === 'LI' &&
                  node.classList &&
                  !node.classList.contains('section-header')
              );
              activeAppSwitcherItem = {
                activeColumn,
                activeItem: activeItem + 1
              };
              if (appSectionItems[activeAppSwitcherItem.activeItem]) {
                setActiveDescendant(
                  appSwitcherMenu,
                  appSectionItems[activeAppSwitcherItem.activeItem]
                );
                addHighlightedItemClass(appSectionItems[activeAppSwitcherItem.activeItem]);
              }
            }
            break;
          case 'ArrowUp':
            e.preventDefault();
            focusPreviousElement(
              focusedMobileAppSwitcherItemIndex,
              focusableElementsAppSwitcherPanel,
              appSwitcherToggle,
              setMobileAppSwitcherIndex
            );
            break;
          case 'Home':
            e.preventDefault();
            focusFirstElement(appSwitcherToggle, setMobileAppSwitcherIndex);
            break;
          case 'End':
            e.preventDefault();
            focusLastElement(focusableElementsAppSwitcherPanel, setMobileAppSwitcherIndex);
            break;
          case 'Escape':
            e.preventDefault();
            closePanel(appSwitcherToggle, appSwitcherMobilePanel);
            break;
          case 'Tab':
            tabPanel(
              e,
              focusedMobileAppSwitcherItemIndex,
              focusableElementsAppSwitcherPanel,
              appSwitcherToggle,
              setMobileAppSwitcherIndex
            );
            break;
          default:
          // do nothing
        }
      }

      function handleMobileDropdownItemClick(e, element) {
        e.target.classList.toggle('menu-expanded');
        const siblingElement = element.nextElementSibling;
        if (
          siblingElement &&
          siblingElement.classList.contains('lwt-unified-navbar__mobile-menu-sub-menu')
        ) {
          siblingElement.classList.toggle('menu-collapsed');
        }
      }

      // Event listeners
      document.body.addEventListener('click', handleBodyClick);

      window.addEventListener('resize', (e) => handleResize(e, false));

      if (userMenuToggle) {
        userMenuToggle.addEventListener('click', (e) => handleMenuClick(e, userMenu));
        userMenuToggle.addEventListener('keydown', (e) => handleMenuItemKeyDown(e, userMenu));
      }

      if (helpMenuToggle) {
        helpMenuToggle.addEventListener('click', (e) => handleMenuClick(e, helpMenu));
        helpMenuToggle.addEventListener('keydown', (e) => handleMenuItemKeyDown(e, helpMenu));
      }

      if (betaMenuToggle) {
        betaMenuToggle.addEventListener('click', e => handleMenuClick(e, betaMenu));
        betaMenuToggle.addEventListener('keydown', e => handleMenuItemKeyDown(e, betaMenu));
      }

      if (notificationMenuToggle) {
        notificationMenuToggle.addEventListener('click', (e) => handleMenuClick(e, notificationMenu));
        notificationMenuToggle.addEventListener('keydown', (e) =>
          handleMenuItemKeyDown(e, notificationMenu)
        );
      }
      if (userMenu) {
        userMenu.addEventListener('keydown', handleMenuKeydown);
      }

      if (helpMenu) {
        helpMenu.addEventListener('keydown', handleMenuKeydown);
      }

      if (betaMenu) {
        betaMenu.addEventListener('keydown', handleMenuKeydown);
      }

      if (notificationMenu) {
        notificationMenu.addEventListener('keydown', handleMenuKeydown);
      }
      if (mobileMenuToggleButton) {
        mobileMenuToggleButton.addEventListener('click', togglePanel);
        mobileMenuToggleButton.addEventListener('keydown', handleMobileMenuButtonKeydown);
      }
      if (focusableElementsMobileMenu) {
        [...focusableElementsMobileMenu].map((element) =>
          element.addEventListener('keydown', handleMobileMenuButtonKeydown)
        );
      }
      if (mobileNotificationToggleButton) {
        mobileNotificationToggleButton.addEventListener('click', toggleNotificationPanel);

        mobileNotificationToggleButton.addEventListener('keydown', handleMobileNotificationButtonKeydown);
      }
      if (focusableElementsNotificationPanel) {
        [...focusableElementsNotificationPanel].map((element) =>
          element.addEventListener('keydown', handleMobileNotificationButtonKeydown)
        );
      }
      if (appSwitcherToggle) {
        appSwitcherToggle.addEventListener('click', (e) => handleMenuClick(e, appSwitcherMenu));
        appSwitcherToggle.addEventListener('keydown', handleAppSwitcherToggleKeyDown);
      }
      if (appSwitcherMenu) {
        appSwitcherMenu.addEventListener('keydown', handleAppSwitcherKeyDown);
      }
      if (focusableElementsAppSwitcherPanel)
        [...focusableElementsAppSwitcherPanel].map((element) =>
          element.addEventListener('keydown', handleAppSwitcherToggleKeyDown)
        );
      if (mobileDropdownMenuItems) {
        [...mobileDropdownMenuItems].map((element) =>
          element.addEventListener('click', (e) => handleMobileDropdownItemClick(e, element))
        );
      }

      // Fire the resize function to hide more menu items on load
      handleResize(null, true);
    }
  };
})();
