import ldAppLayout from './modules/ldAppLayout/store';
import subscription from './modules/subscription/store';
import howItWorks from './modules/howItWorks/how-it-works';
import customNumber from './modules/customNumber/custom-number';

export default {
  ldAppLayout,
  subscription,
  howItWorks,
  customNumber,
};
