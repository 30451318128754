/*
 * @Author: Ethan Dinnen
 * @Date:   2018-11-30 11:03:26
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2018-11-30 11:17:10
 */

import initialState from './initialState';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = initialState();

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
