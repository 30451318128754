<template>
  <v-layout class="pt-5 create-plan-action" justify-center>
    <ActionSummary
      :value="currentStage"
      :items="summaryValues"
      :planName="planName"
      :editDisabled="isActionComplete"
      :isEditingAction="!!actionId"
      @input="updateStage"
    />

    <ActionType
      v-if="currentStage === 'action-type'"
      :value="actionType"
      :actionTypes="actionTypes"
      @input="setActionType"
    />

    <TemplatePicker
      v-if="currentStage === 'action-template'"
      :value="actionTemplate"
      @input="updateTemplateContainer"
      :communication-type="actionType.db"
      column-width="xs12 md4 pa-0"
      :templateFolders="templateFolders"
      :setTemplatePreview="setTemplatePreview"
      :fetchTemplates="fetchTemplates"
      :getTemplatesAndSelectedFolder="getTemplatesAndSelectedFolder"
    />

    <ActionInstructions
      v-if="currentStage === 'action-instructions'"
      :value="task.description"
      :updateInstructions="updateInstructions"
    />

    <ActionTiming
      v-if="currentStage === 'action-timing'"
      :task="task"
      :updateTask="updateTask"
      :kcdOptions="kcdOptions"
    />

    <ActionRecipient
      v-if="currentStage === 'action-recipient'"
      :roles="roles"
      :handleSelectRole="handleSelectRole"
      :selectedRole="selectedRole"
    />

    <ActionAssignee
      v-if="currentStage === 'action-assignee'"
      v-on:update:action-reminder="updateReminder"
      :reminderType="actionReminder"
      :isReminderVisible="isDesiredTypeTask()"
      :assignee="actionAssignee"
      :updateAssignee="updateAssignee"
      :updateReminder="updateReminder"
    />

    <v-navigation-drawer
      v-model="showTemplate"
      @toggle="showTemplate = $event"
      absolute
      temporary
      right
      width="580"
    >
      <div class="drawer-close">
        <v-btn icon @click="showTemplate = false">
          <v-icon medium>chevron_right</v-icon>
        </v-btn>
      </div>
      <v-flex xs12>
        <v-flex xs12>
          <TemplateEditor
            v-model="templatePreview"
            :form-action="formAction"
            :template-folders="templateFoldersList"
            :is-creating-template="isCreatingTemplate"
            @update-template="handleUpdateTemplate"
            @create-template="handleCreateTemplate"
            @cancel="handleCancelTemplate"
            :files="files"
            :attachmentHandlerId="attachmentHandlerId"
            :handleUpload="handleUpload"
            :handleFileError="handleFileError"
            :getAccessToken="getAccessToken"
          />
        </v-flex>
      </v-flex>
    </v-navigation-drawer>
    <LdConfirmation
      :value="showFileError"
      width="490"
      @click:cancel="closeErrors"
      @click:ok="tryUploadAgain"
      cancelText="Close"
      confirmText="Try Another File"
    >
      <template slot="header">
        Failed to attach file
      </template>
      <template slot="body">
        <span>
          The following file{{ fileErrors.length > 1 ? 's' : '' }} could not be
          attached:
        </span>
        <ul class="files">
          <li v-for="(file, index) in fileErrors" :key="index">
            {{ file.name }}
          </li>
        </ul>
        <span>Please ensure the file size does not exceed 15 MB.</span>
      </template>
    </LdConfirmation>
  </v-layout>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { LdConfirmation } from 'lion-vue';

import ActionSummary from '@/Core/components/SummaryList';
import ActionType from './components/CreateActionType';
import ActionInstructions from './components/CreateActionInstructions';
import ActionTiming from './components/CreateActionTiming';
import ActionRecipient from './components/CreateActionRecipient';
import ActionAssignee from './components/CreateActionAssignee';
import TemplatePicker from './components/TemplatePicker';
import TemplateEditor from './components/TemplateEditor';

export default {
  components: {
    ActionSummary,
    ActionType,
    ActionInstructions,
    ActionTiming,
    ActionRecipient,
    ActionAssignee,
    TemplatePicker,
    TemplateEditor,
    LdConfirmation,
  },
  props: {
    currentStage: String,
    summaryValues: Array,
    planName: String,
    isActionComplete: Boolean,
    actionId: Number,
    actionType: Object,
    actionTypes: Array,
    actionInstructions: String,
    actionTimingInitialType: Object,
    isDesiredTypeText: Function,
    setValidationStatus: Function,
    actionTiming: Object,
    actionReminder: String,
    isDesiredTypeTask: Function,
    actionAssignee: Object,
    clearActions: Function,
    setActionType: Function,
    updateInstructions: Function,
    updateTiming: Function,
    updateAssignee: Function,
    updateReminder: Function,
    task: Object,
    updateTask: Function,
    kcdOptions: Array,
    actionTemplate: Object,
    updateTemplateContainer: Function,
    roles: Array,
    handleSelectRole: Function,
    selectedRole: Number,
    updateStage: Function,
  },
  data() {
    return {
      showTemplate: false,
      templatePreview: {},
      isCreatingTemplate: false,
      activeTab: 'templates',
      files: [],
      fileErrors: [],
      showFileError: false,
      attachmentHandlerId: 'attachmentUpload',
    };
  },
  watch: {
    files(value) {
      if (value && Array.isArray(value)) {
        this.setFiles(value);
      }
    },
  },
  computed: {
    ...mapState({
      templateFolders: state => state.planTasks.templateFolders,
    }),
    formAction() {
      return this.isCreatingTemplate ? 'create' : 'update';
    },
    // for the template folder dropdown in the TemplateEditor component
    templateFoldersList() {
      return this.templateFolders.map(folder => {
        return { text: folder.name, value: folder.id };
      });
    },
    blankTemplate() {
      return {
        template_type: this.actionType.db,
        body: '',
      };
    },
  },
  created() {
    this.findAllTeamMembers();
  },
  methods: {
    ...mapMutations('planTasks', [
      'assignTemplatesToFolder',
      'setTemplateFolders',
    ]),
    ...mapActions('planTasks', [
      'updateTemplate',
      'createTemplate',
      'getTemplateFolders',
      'getTemplatesInFolder',
      'setFiles',
      'getAccessToken',
      'findAllTeamMembers',
    ]),
    /**
     * Function to get the children of a particular folder and assign it to that folder.
     *
     * @param {Object} folder, The template folder that we want to fetch its children
     */
    async fetchTemplates(folder) {
      const templates = await this.getTemplatesInFolder({
        templateFolder: folder,
        type: this.actionType.db,
      });
      this.assignTemplatesToFolder({ desiredFolder: folder, templates });
    },
    async handleUpdateTemplate() {
      if (this.files.length) {
        const ids = this.files.map(file => file.id).join(',');
        this.templatePreview.file_id = this.templatePreview.file_id
          ? this.templatePreview.file_id + ',' + ids
          : ids;
      }
      await this.updateTemplate(this.templatePreview);
      await this.getTemplatesAndSelectedFolder();
      this.toggleTemplateEditor();
      this.templatePreview = {};
    },
    async handleCreateTemplate() {
      const template = this.templatePreview;
      const payload = {
        template_type: template.template_type,
        template_folder_id: template.template_folder_id,
        description: template.description,
        subject: template.subject,
        body: template.body,
      };
      if (this.files.length) {
        payload.file_id = this.files.map(file => file.id).join(',');
      }
      await this.createTemplate(payload);
      await this.getTemplatesAndSelectedFolder();
      this.searchValue = '';
      this.toggleTemplateEditor();
      this.templatePreview = {};
    },
    handleCancelTemplate() {
      this.isCreatingTemplate = false;
      this.activeTab = 'templates';
      this.showTemplate = false;
      this.templatePreview = {};
    },
    /**
     * @param {Object} template is the template we want to preview.
     * It sets the body, description, and subject as fields that might get changed
     * That is important to disable the save button, as well as confirming lost changes
     * on the back button
     */
    setTemplatePreview(template = null) {
      this.isCreatingTemplate = !template;
      if (!template) template = this.blankTemplate;

      // Create a duplicate object to remove assignment
      this.templatePreview = Object.assign({}, template);
      // Sets old fields for the disabled functionality to compare against
      this.templatePreview.oldBody = this.templatePreview.body;
      this.templatePreview.oldDescription = this.templatePreview.description;
      this.templatePreview.oldSubject = this.templatePreview.subject;
      this.toggleTemplateEditor();
    },
    toggleTemplateEditor() {
      this.showTemplate = !this.showTemplate;
      this.activeTab =
        this.activeTab === 'templates' ? 'template-preview' : 'templates';
    },
    /**
     * Function to re-initialize the current folder treeview in to the default:
     * Displaying a list of folders and expanding the current selected template's folder
     */
    async getTemplatesAndSelectedFolder() {
      const folder = this.templatePreview.template_folder_id;
      const folders = (await this.getTemplateFolders()) || [];
      this.setTemplateFolders(folders);
      this.activeTemplateFolderIds = [];
      if (folder) {
        this.activeTemplateFolderIds.push(
          this.actionTemplate.template_folder_id
        );
        this.fetchTemplates({ id: folder });
      }
    },
    handleUpload(value) {
      // Update the local files array. Triggers Vuex store update
      this.files = value;
    },
    handleFileError(file) {
      // Add the errored file to the array
      this.fileErrors = [...this.fileErrors, file];
      // Show the modal
      this.showFileError = true;
    },
    closeErrors() {
      // Hide the error modal and clear the errored item array
      this.showFileError = false;
      this.fileErrors = [];
    },
    tryUploadAgain() {
      // TODO AK: refactor this using component $refs once quill is removed to allow
      // for multiple instances of AttachmentHandler and better component separation
      document.querySelector(`#${this.attachmentHandlerId}`).click(); // Auto-click the input
      this.closeErrors();
    },
  },
};
</script>

<style lang="scss">
.create-plan-action {
  .header {
    color: black;
    font-size: 24px !important;
    font-weight: 400;
    line-height: 32px !important;
    letter-spacing: normal !important;
  }
  .ld-summary-list {
    width: 660px;
    max-height: calc(100vh - 430px);
  }
}
</style>
