import LdPlans from 'lion-vue-src/config/plans';
import LdThresholds from 'lion-vue-src/config/thresholds';
import LdFeatures from 'lion-vue-src/_globals/lib/LdFeatures';

const content = Object.freeze({
  [LdFeatures.Emails]: {
    Default: {
      Default: {
        action: 'View Plans',
        description:
          'You have used USAGE_INFO of your emails. Overage pricing of OVERAGE_PRICING will be automatically applied after all limits are used. RESET_MESSAGE Our higher subscription tiers includes more features with reduced overage charge.',
        error: false,
      },
      [LdThresholds.High]: {
        error: true,
      },
      [LdThresholds.Critical]: {
        error: true,
      },
    },
    [LdPlans.Trial]: {
      Default: {
        action: 'Upgrade Now',
        description:
          'You have used USAGE_INFO of the email in your free trial. Upgrade to get more.',
      },
    },
    [LdPlans.MLS]: {
      Default: {
        action: 'Upgrade Now',
        description:
          'You have used USAGE_INFO of the emails in your MLS plan. Upgrade to get more.',
      },
    },
    [LdPlans.PAB]: {
      Default: {
        action: '',
        description:
          'You have zero email credits remaining for this month. RESET_MESSAGE',
      },
    },
    OveragePricing: {
      [LdPlans.Starter]: `$2 for an additional 1000 credits`,
      [LdPlans.ProPlus]: `$1.50 for an additional 1000 credits`,
      [LdPlans.Elite]: `$1 for an additional 1000 credits`,
      [LdPlans.LeadsPlus]: `$1.50 for an additional 1000 credits`,
      [LdPlans.CRM]: `$1.50 for an additional 1000 credits`,
      [LdPlans.CRMPremier]: `$1.50 for an additional 1000 credits`,
    },
  },
  [LdFeatures.TextMessaging]: {
    Default: {
      Default: {
        action: 'View Plans',
        description:
          'You have used USAGE_INFO of your texts. Overage pricing of OVERAGE_PRICING will be automatically applied after all limits are used. RESET_MESSAGE Our higher subscription tiers includes more features with reduced overage charge.',
        error: false,
      },
      [LdThresholds.High]: {
        error: true,
      },
      [LdThresholds.Critical]: {
        error: true,
      },
    },
    [LdPlans.Trial]: {
      Default: {
        action: 'Upgrade Now',
        description:
          'You have used USAGE_INFO of the text in your free trial. Upgrade to get more.',
      },
    },
    [LdPlans.Starter]: {
      Default: {
        customNumberDescription:
          'You have used USAGE_INFO of your monthly free text credits. Purchase a custom number to continue to text with overage pricing. Or upgrade to get a free custom number with reduced overage pricing.',
      },
    },
    [LdPlans.MLS]: {
      Default: {
        action: 'Upgrade Now',
        description: 'You have used USAGE_INFO of the texts in your MLS plan. Upgrade to get more.',
      },
    },
    [LdPlans.BrokerLite]: {
      Default: {
        action: 'Upgrade Now',
        description:
          'You have used USAGE_INFO of the texts in your current plan. Upgrade to get more.',
      },
    },
    [LdPlans.CRM]: {
      Default: {
        customNumberDescription:
            'You have used USAGE_INFO of your monthly free text credits. Purchase a custom number to continue to text with overage pricing. Or upgrade to get a free custom number with reduced overage pricing.',
      },
    },
    [LdPlans.CRMPremier]: {
      Default: {
        customNumberDescription:
            'You have used USAGE_INFO of your monthly free text credits. Purchase a custom number to continue to text with overage pricing. Or upgrade to get a free custom number with reduced overage pricing.',
      },
    },
    [LdPlans.PAB]: {
      Default: {
        action: '',
        description:
          'You have zero text credits remaining for this month. RESET_MESSAGE',
      },
    },
    OveragePricing: {
      [LdPlans.Starter]: `$5 for an additional 100 credits`,
      [LdPlans.ProPlus]: `$3 for an additional 100 credits`,
      [LdPlans.Elite]: `$2 for an additional 100 credits`,
      [LdPlans.LeadsPlus]: `$3 for an additional 100 credits`,
      [LdPlans.CRM]: `$3 for an additional 100 credits`,
      [LdPlans.CRMPremier]: `$3 for an additional 100 credits`,
    },
  },
  [LdFeatures.VideoTexts]: {
    Default: {
      Default: {
        action: 'View Plans',
        description:
          'You have used USAGE_INFO of your video texts. Overage pricing of OVERAGE_PRICING will be automatically applied after all limits are used. RESET_MESSAGE Our higher subscription tiers includes more features with reduced overage charge.',
        error: false,
      },
      [LdThresholds.High]: {
        error: true,
      },
      [LdThresholds.Critical]: {
        error: true,
      },
    },
    [LdPlans.Trial]: {
      Default: {
        action: 'Upgrade Now',
        description:
          'You have used USAGE_INFO of the video text in your free trial. Upgrade to get more.',
      },
    },
    [LdPlans.Starter]: {
      Default: {
        customNumberDescription:
          'You have used USAGE_INFO of your monthly free video text credits. Purchase a custom number to continue to video text with overage pricing. Or upgrade to get a free custom number with reduced overage pricing.',
      },
    },
    [LdPlans.MLS]: {
      Default: {
        action: 'Upgrade Now',
        description:
          'You have used USAGE_INFO of the video texts in your MLS plan. Upgrade to get more.',
      },
    },
    [LdPlans.PAB]: {
      Default: {
        action: '',
        description:
          'You have zero video text credits remaining for this month. RESET_MESSAGE',
      },
    },
    OveragePricing: {
      [LdPlans.Starter]: `$7 for an additional 100 credits`,
      [LdPlans.ProPlus]: `$5 for an additional 100 credits`,
      [LdPlans.Elite]: `$2 for an additional 100 credits`,
      [LdPlans.LeadsPlus]: `$5 for an additional 100 credits`,
      [LdPlans.CRM]: `$5 for an additional 100 credits`,
      [LdPlans.CRMPremier]: `$5 for an additional 100 credits`,
    },
  },
  [LdFeatures.LeadAssist]: {
    Default: {
      Default: {
        action: 'View Plans',
        description:
          'You have used USAGE_INFO of your Lead Assist credits. Overage pricing of OVERAGE_PRICING will be automatically applied after all limits are used. Our higher subscription tiers includes more features with reduced overage charge.',
        customNumberDescription:
          'You have used USAGE_INFO of your Lead Assist credits. Select a custom number to use Lead Assist with overage pricing',
      },
      [LdThresholds.High]: {
        error: true,
      },
      [LdThresholds.Critical]: {
        error: true,
      },
    },
    [LdPlans.Trial]: {
      Default: {
        action: 'Upgrade Now',
        description:
          'You have used USAGE_INFO of the Lead Assist credits in your free trial. Upgrade to get more.',
      },
    },
    [LdPlans.MLS]: {
      Default: {
        action: 'Upgrade Now',
        description:
          'You have used USAGE_INFO of the Lead Assist credits in your MLS plan. Upgrade to get more.',
      },
    },
    [LdPlans.Starter]: {
      Default: {
        action: 'View Plans',
        description:
          'You have used USAGE_INFO of your Lead Assist credits. Overage pricing of OVERAGE_PRICING will be automatically applied after all limits are used. Our higher subscription tiers includes more features with reduced overage charge.',
        customNumberDescription:
          'You have used USAGE_INFO of your Lead Assist credits. Purchase a custom number to continue to use Lead Assist with overage pricing or upgrade to get a free custom number with reduced overage pricing',
      },
      [LdThresholds.High]: {
        error: true,
      },
      [LdThresholds.Critical]: {
        error: true,
      },
    },
    OveragePricing: {
      [LdPlans.Starter]: `$15 for an additional 5 credits`,
      [LdPlans.ProPlus]: `$12.50 for an additional 5 credits`,
      [LdPlans.Elite]: `$11.50 for an additional 5 credits`,
      [LdPlans.LeadsPlus]: `$12.50 for an additional 5 credits`,
      [LdPlans.CRM]: `$12.50 for an additional 5 credits`,
      [LdPlans.CRMPremier]: `$12.50 for an additional 5 credits`,
    },
  },
});

export default content;
