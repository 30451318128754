/*
 * @Author: Ethan Dinnen
 * @Date:   2018-11-30 11:03:26
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-01-15 12:12:07
 */

import initialState from './initialState';

const reset = state => {
  Object.assign(state, initialState());
};

const setPlan = (state, plan) => {
  state.plan = plan;
};

const sortTasks = (state, sortInfo) => {
  const { column } = sortInfo;
  const sortDesc = sortInfo.descending ? -1 : 1;

  const collator = new Intl.Collator('en').compare;

  state.plan.tasks.sort((a, b) => {
    if(column === 'due_at') {
      return collator(a['due_at'].value, b['due_at'].value) * sortDesc;
    }
    return collator(a[column], b[column]) * sortDesc;
  });
};

export default {
  reset,
  setPlan,
  sortTasks,
};
