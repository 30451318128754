<template>
  <svg
    aria-hidden="true"
    focusable="false"
    data-icon="lonewolf"
    role="img"
    viewBox="0 0 25 25"
    style="fill: #9a262c"
  >
    <g id="Page-1">
      <g id="Create-tx" transform="translate(-308.000000, -439.000000)">
        <g id="Group-3-Copy" transform="translate(308.000000, 439.000000)">
          <g id="Clip-2"></g>
          <defs>
            <filter
              id="Adobe_OpacityMaskFilter"
              filterUnits="userSpaceOnUse"
              x="2"
              y="3"
              width="21"
              height="20"
            >
              <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
            </filter>
          </defs>
          <mask maskUnits="userSpaceOnUse" x="2" y="3" width="21" height="20" id="mask-2_2_">
            <g class="st0">
              <polygon id="path-1_2_" class="st1" points="2,3 23,3 23,23 2,23 					" />
            </g>
          </mask>
          <path
            id="Fill-1"
            class="st2"
            d="M11.9,9.6C13.3,9.2,13.4,8,13.8,7c0.3-0.8,0.4-0.9,1.2-0.4c0.5,0.4,0.7,0.7,0.6,1.3
				c0,0.4-0.1,1-0.1,1.5c0.6-1.6,1-1.5,1-1.5c0.2,0,0.5,0.4,0.5,0.4c0.2,0.3,0.1,0.9-0.1,1.2c0,0-0.8,2-0.9,3.2c0,0-0.2,0.7-0.2,1.1
				c-0.2,1.1-0.2,1.4-0.2,2.5c0,0.5-0.1,1.1,0.2,2.2c0.1,0.4,0.7,2.3,0.2,4.4c4.1-1.4,7-5.2,7-9.7C23,7.6,18.3,3,12.5,3
				S2,7.6,2,13.3c0,3,1.3,5.7,3.3,7.5c0.1-0.2,0.2-0.3,0.2-0.5c0.3-0.6,0.7-1.2,1.1-1.7c0.5-0.7,1.1-1.4,1.6-2.1c0,0,0,0,0,0
				C7,15.9,6.9,15.8,8,14.9c1.1-0.8,1.9-1.8,2.3-3.1C10.6,10.7,11,9.9,11.9,9.6"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '$vuetify.icons.lonewolf',
    },
  },
};
</script>