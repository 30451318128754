<!--
@Author: Ethan Dinnen
@Date:   2019-01-11 11:10:23
@Last Modified by:   ethan
@Last Modified time: 2019-02-19 11:50:45
-->
<template>
  <div class="property-tx">
    <v-layout row justify-space-between class="tx-property-info-layout">
      <MlsGallery
        :width="704"
        :height="396"
        :mobileHeight="396"
        :items="images"
      />
      <MlsPropertyInfo
        :property="property"
        :price="price"
        :googleApiKey="googleApiKey"
      />
    </v-layout>
  </div>
</template>

<script>
import { MlsGallery, MlsPropertyInfo } from 'lion-vue';

export default {
  components: {
    MlsGallery,
    MlsPropertyInfo,
  },
  modules: {
    ...MlsGallery,
  },
  props: {
    property: {
      type: Object,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
  },
  methods: {
    imageMap(images) {
      return images.map(img => {
        return {
          src: img[0],
          thumbnail: img[1],
        };
      });
    },
  },
  computed: {
    googleApiKey() {
      return process.env.GOOGLE_MAPS_KEY;
    },
    images() {
      const { processed } = this.property._source.ImageUrls;
      const n = processed.length / 2;
      const images = [];
      for (let i = 1; i < n + 1; i++) {
        const sizes = processed.filter(img => img.includes(`-${i}-`));
        images.push(sizes);
      }
      return this.imageMap(images);
    },
  },
};
</script>

<style lang="scss">
.property-tx {
  margin: 50px 0;
  .tx-property-info-layout {
    @media all and (max-width: 1200px) {
      flex-wrap: wrap;
      .property-summary {
        margin-top: 25px;
      }
    }
  }
  .property-info-layout {
    margin-left: 25px;
    img {
      margin-top: 4px;
    }
  }
}
</style>
