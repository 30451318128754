<!--
  @Author: Ethan Dinnen
  @Date:   2018-12-11 15:58:26
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-01-15 12:50:58
-->

<template>
  <span>{{ formatData(data) }}</span>
</template>

<script>
import formatCurrency from '@/Core/mixins/formatCurrency';
export default {
  props: {
    data: {
      type: Object,
    },
  },
  mixins: [formatCurrency],
  methods: {
    formatData(data) {
      if (data.format === 'currency') {
        return this.formatCurrency(data.text, '$');
      }
      return data.text;
    },
  },
};
</script>
