export default {
  data() {
    return {
      isModalVisible: false,
    };
  },
  methods: {
    modal_open() {
      this.isModalVisible = true;
    },
    modal_close() {
      this.isModalVisible = false;
    },
  },
};
