<!--
@Author: Kleber Pinel <kleberdasilva>
@Date:   2019-02-13T14:49:18-08:00
@Email:  kleber@hackcapital.com
@Last modified by:   kleberdasilva
@Last modified time: 2019-03-08T15:09:32-08:00
-->
<template>
  <div class="ld-select-input">
    <v-select
      :class="[
        'ld-select',
        overlength ? 'ld-price-selected' : ''
      ]"
      outline
      color="#033"
      :items="options"
      :label="title"
      :chips="chips"
      :attach="attach"
      :multiple="multiple"
      :placeholder="placeholder"
      v-model="selectedValue"
      @change="handleEmit"
      :rules="rules"
      :required="required"
      :disabled="disabled"
      :append-icon="icon"
      :error-messages="errors"
    >
      <template slot="selection" slot-scope="data">
        <div>
          <span v-if="!isObject(data.item)">{{ data.item }}</span>
          <span v-else>{{ data.item.name }}</span>
          <div class="icon-position">
            <v-icon>{{ data.item.optionIcon }}</v-icon>
          </div>
        </div>
      </template>
      <template slot="item" slot-scope="data">
          <template v-if="!isObject(data.item)">
            <v-list-tile-content v-text="data.item"></v-list-tile-content>
          </template>
          <template>
            <v-list-tile-content>
              <v-list-tile-title>
                {{ data.item.name }}
                <div class="icon-position" >
                  <v-icon >{{ data.item.optionIcon }}</v-icon>
                </div>
              </v-list-tile-title>
            </v-list-tile-content>
          </template>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: String,
    title: String,
    options: Array,
    chips: {
      type: Boolean,
      required: false,
      default: false,
    },
    withIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    attach: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    errors: {
      type: Array,
      required: false,
      default: () => [],
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    selected: {
      required: false,
      default: '',
    },
    nameId: {
      type: String,
      required: false,
      default: null,
    },
    icon: {
      type: String,
      required: false,
      default: '$vuetify.icons.dropdown',
    },
  },
  data() {
    return {
      selectedValue: this.selected,
    };
  },
  computed: {
    overlength() {
      const { selectedValue } = this;
      return (
        selectedValue
        && selectedValue.key
        && (
          selectedValue.key.includes('price')
          || selectedValue.key.includes('dom')
        )
      );
    },
  },
  methods: {
    handleEmit(selectedValue) {
      const { nameId } = this;
      this.$emit(
        'onInput',
        nameId ? { value: selectedValue, nameId } : selectedValue,
      );
    },
    isObject(obj) {
      const type = typeof obj;
      return type === 'object' && !!obj && !Array.isArray(obj);
    },
  },
};
</script>

<style lang="scss">
  .ld-select {
    height: 45px;
    width: 215px;
    max-width: 225px !important;
    margin-left: 14px;
    font-weight: bold;
    font-size: 14px;
    font-family: 'Nunito', sans-serif;
    .v-input__slot {
      border: 1px solid var(--v-primary-base) !important;
      border-radius: 2px !important;
      min-height: 45px !important;
    }
    .v-input__append-inner {
      margin: auto 0 !important;
    }
    .v-select__selections {
      color: var(--v-primary-base) !important;
      padding-top: 0 !important;
      position: relative;
      top: 2px;
    }
    i {
      color: var(--v-primary-base) !important;
    }
  }
  .ld-price-selected {
    width: 225px;
  }

.icon-position {
  float: right;
  position: relative;
  left: 7px;
  bottom: 2px;
}
</style>
