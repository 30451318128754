/**
 * @Author: Kleber Pinel <kleberdasilva>
 * @Date:   2019-01-24T14:38:15-08:00
 * @Email:  kleber@hackcapital.com
 * @lastModifiedBy:   Carmen Chow <carmen@hackcapital.com>
 * @lastModifiedTime: 2019-04-17 13:09:00
 */

export default {
  data() {
    return {
      caretData: {},
    };
  },
  methods: {
    saveCaretData(element) {
      const caretPosition = this.getCaretPosition(element);
      this.caretData = this.getCaretData(element, caretPosition);
    },

    restoreCaretPosition() {
      const d = { ...this.caretData };
      // Set this to occur at the end of the execution queue
      window.setTimeout(() => {
        this.$refs.editor.focus();
        const sel = window.getSelection();
        const range = document.createRange();
        range.setStart(d.node, d.position);
        this.editor.focus();
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
        sel.addRange(range);
      }, 0);
    },

    getAllTextnodes(el) {
      const a = [];
      const walk = document.createTreeWalker(
        el,
        NodeFilter.SHOW_TEXT,
        null,
        false
      );
      let n = walk.nextNode();
      while (n) {
        a.push(n);
        n = walk.nextNode();
      }
      return a;
    },

    getCaretData(el, position) {
      let node = el;
      const nodes = this.getAllTextnodes(el);
      for (let n = 0; n < nodes.length; n++) {
        const { nodeValue } = nodes[n];
        if (nodeValue && position > nodeValue.length && nodes[n + 1]) {
          // remove amount from the position, go to next node
          position -= nodeValue.length;
        } else {
          node = nodes[n];
          break;
        }
      }
      // you'll need the node and the position (offset) to set the caret
      return { node, position };
    },

    getCaretPosition(element) {
      // get caret position for input fields/textarea
      if (!element.attributes.contenteditable) {
        return element.selectionEnd || 0;
      }

      // get caret position for contenteditable divs
      let caretOffset = 0;
      /**
       * Do not get caret position unless contenteditable div has content in
       * it. This prevents IndexSizeError on setting the selection range when switching
       * between the Email & Text tab on the communications page
       */
      if (
        !!this.editor.innerHTML &&
        typeof window.getSelection !== 'undefined'
      ) {
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
          const range = window.getSelection().getRangeAt(0);
          const selected = range.toString().length;
          const preCaretRange = range.cloneRange();
          preCaretRange.selectNodeContents(element);
          preCaretRange.setEnd(range.endContainer, range.endOffset);
          caretOffset = preCaretRange.toString().length - selected;
        }
      }
      return caretOffset;
    },
  },
};
