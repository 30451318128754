/*
 * @Author: Ethan Dinnen
 * @Date:   2019-01-23 14:33:59
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-01-23 14:34:59
 */

import initialState from './initialState';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = initialState();

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
