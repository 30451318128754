<template>
  <svg viewBox="0 0 175 61" fill="none">
    <g clip-path="url(#clip0)">
      <path
        d="M19.3075 43.6831L8.78263 40.6109L8.66955 40.1944C8.56484 39.8162 6.16501 30.8374 9.38991 19.4577C12.5017 8.45615 20.4593 2.47734 23.7386 0.416416C24.3962 0.00423069 25.1961 -0.106252 25.9374 0.106215C26.6787 0.322931 27.2986 0.849848 27.6378 1.55099C29.334 5.06094 32.9485 14.418 29.8324 25.4152C27.0934 35.0782 19.9316 42.9947 19.63 43.3262L19.3075 43.6831Z"
        fill="white"
      />
      <path
        d="M8.69043 35.6604C8.96266 38.3715 9.40242 39.982 9.40242 39.982L19.0646 42.7993C19.0646 42.7993 20.1912 41.5627 21.7241 39.4848C21.1712 39.3786 18.34 38.8262 15.2156 37.9168C11.7436 36.9055 8.70299 35.6646 8.69043 35.6604Z"
        fill="#00A567"
      />
      <path
        d="M29.8576 11.1673C29.1791 7.04122 27.864 3.79048 26.951 1.89528C26.4358 0.832942 25.1333 0.454751 24.1449 1.07515C22.3775 2.18423 19.563 4.23241 16.8281 7.36417C16.8365 7.37692 18.7044 9.91802 22.8172 11.1206C26.93 12.3189 29.845 11.1716 29.8576 11.1673Z"
        fill="#E5E3DC"
      />
      <path
        d="M19.3075 43.6831L8.78263 40.6109L8.66955 40.1944C8.56484 39.8162 6.16501 30.8374 9.38991 19.4577C12.5017 8.45615 20.4593 2.47734 23.7386 0.416416C24.3962 0.00423069 25.1961 -0.106252 25.9374 0.106215C26.6787 0.322931 27.2986 0.849848 27.6378 1.55099C29.334 5.06094 32.9485 14.418 29.8324 25.4152C27.0934 35.0782 19.9316 42.9947 19.63 43.3262L19.3075 43.6831ZM10.0391 39.3616L18.8133 41.9197C20.2206 40.2794 26.0379 33.1745 28.3582 24.9903C31.3276 14.5115 27.8807 5.58785 26.2599 2.23513C26.1091 1.92068 25.8411 1.69547 25.5102 1.59773C25.1794 1.5 24.8359 1.55099 24.5428 1.73371C21.4184 3.70115 13.8252 9.40376 10.8558 19.8826C8.12927 29.5031 9.62026 37.4918 10.0391 39.3616Z"
        fill="#211E1F"
      />
      <path
        d="M9.40248 39.9819C9.40248 39.9819 5.95142 43.0755 5.21849 45.6675C4.2929 48.9268 7.48429 50.8135 8.39732 54.2427C9.31034 57.6719 7.58062 59.3759 7.58062 59.3759C7.58062 59.3759 14.8932 58.0204 14.2817 53.9367C13.5572 49.0925 15.3455 47.3715 17.1213 50.8347C20.9242 47.0528 19.0646 42.8035 19.0646 42.8035L9.40248 39.9819Z"
        fill="#F15F4A"
      />
      <path
        d="M5.23938 60.604L7.04867 58.8236C7.09893 58.7726 8.43078 57.3533 7.66015 54.451C7.32928 53.2145 6.65499 52.1649 6.00163 51.1493C4.93364 49.4878 3.82796 47.7711 4.48551 45.4594C5.27288 42.6804 8.75327 39.5358 8.89985 39.4041L9.21397 39.1237L19.6258 42.1619L19.7724 42.4934C19.8561 42.6888 21.7995 47.2824 17.6657 51.3957L16.9286 52.1309L16.4511 51.2003C15.781 49.8915 15.3371 49.8022 15.3245 49.798C15.1277 49.9127 14.6419 51.0983 15.0481 53.8264C15.7601 58.6026 8.05384 60.0856 7.72716 60.1451L5.23938 60.604ZM9.60347 40.8531C8.77421 41.652 6.49583 43.9721 5.95137 45.8886C5.49067 47.5203 6.27805 48.7484 7.27902 50.2994C7.96589 51.366 8.7407 52.5728 9.13439 54.0473C9.60347 55.815 9.44013 57.1876 9.12183 58.1607C11.0316 57.536 13.8503 56.2315 13.5278 54.0558C13.0545 50.8943 13.5571 48.7484 14.868 48.315C15.358 48.1535 16.2627 48.145 17.2469 49.5473C19.3033 46.9594 18.763 44.3461 18.4824 43.441L9.60347 40.8531Z"
        fill="#211E1F"
      />
      <path
        d="M28.3204 27.7098C31.9013 30.013 31.5956 37.5216 28.5424 43.8488C28.1655 44.6307 26.997 44.4012 26.9593 43.5301C26.8294 40.5131 25.6316 37.6915 24.0066 36.183C24.0066 36.183 25.2882 34.1943 26.2138 32.3884C27.4995 29.8643 28.3204 27.7098 28.3204 27.7098Z"
        fill="#E5E3DC"
      />
      <path
        d="M27.3362 45.0344C26.6787 44.8432 26.2263 44.2653 26.1928 43.5599C26.0756 40.8403 25.0118 38.1675 23.4873 36.7524L23.0056 36.3063L23.3616 35.7538C23.3742 35.7326 24.6348 33.7779 25.5269 32.0272C26.7792 29.5753 27.5917 27.4506 27.6001 27.4294L27.9351 26.5455L28.7225 27.0512C32.9149 29.7495 32.2657 37.8828 29.2209 44.1888C28.8942 44.8687 28.1822 45.2214 27.4493 45.0641C27.4158 45.0556 27.3739 45.0471 27.3362 45.0344ZM24.9824 36.0725C26.5614 37.8318 27.6001 40.6066 27.7257 43.4919C27.8304 43.5556 27.843 43.5301 27.8555 43.5046C30.6239 37.7808 30.8878 31.5088 28.6345 28.9846C28.266 29.86 27.6671 31.2283 26.8922 32.7411C26.2389 34.0116 25.4306 35.3544 24.9824 36.0725Z"
        fill="#211E1F"
      />
      <path
        d="M9.40247 22.1901C5.17241 22.2113 1.50775 28.7043 0.770625 35.6902C0.678485 36.5613 1.79673 36.9905 2.28675 36.2639C3.9327 33.8077 6.33672 32.0825 8.48526 31.6831C8.48526 31.6831 8.5774 28.8105 8.74074 26.7836C8.9669 23.9535 9.40247 22.1901 9.40247 22.1901Z"
        fill="#E5E3DC"
      />
      <path
        d="M1.16855 37.3432C1.13085 37.3305 1.09316 37.3177 1.05128 37.305C0.347663 37.0415 -0.0711556 36.3616 0.00841986 35.6052C0.741352 28.6491 4.45208 21.4379 9.39833 21.4125L10.3826 21.4082L10.1438 22.3771C10.1396 22.394 9.72082 24.1235 9.50303 26.8431C9.34388 28.8275 9.25174 31.6746 9.25174 31.7043L9.2308 32.329L8.62352 32.4437C6.64669 32.8134 4.4102 34.4834 2.91921 36.6973C2.52552 37.288 1.83447 37.5387 1.16855 37.3432ZM8.43086 23.1164C5.2269 24.0683 2.19046 29.4862 1.53292 35.7709C1.52873 35.8007 1.52454 35.8262 1.57899 35.8474C3.23332 33.4636 5.52426 31.6831 7.73981 31.0584C7.7775 30.1023 7.85708 28.1859 7.97435 26.7199C8.09999 25.1689 8.28427 23.9323 8.43086 23.1164Z"
        fill="#211E1F"
      />
      <path
        d="M15.1109 38.295C11.6515 37.2836 8.57314 36.0343 8.54382 36.0216L8.82862 35.2992C8.85794 35.3119 11.8986 36.5485 15.3203 37.5471C18.7421 38.5457 21.8622 39.1193 21.8916 39.1278L21.7575 39.8927C21.7282 39.8842 18.5703 39.3021 15.1109 38.295Z"
        fill="#211E1F"
      />
      <path
        d="M10.4956 34.6279C10.7663 34.6279 10.9857 34.4053 10.9857 34.1307C10.9857 33.8561 10.7663 33.6335 10.4956 33.6335C10.225 33.6335 10.0056 33.8561 10.0056 34.1307C10.0056 34.4053 10.225 34.6279 10.4956 34.6279Z"
        fill="#211E1F"
      />
      <path
        d="M12.5729 35.533C12.876 35.533 13.1216 35.2838 13.1216 34.9763C13.1216 34.6689 12.876 34.4197 12.5729 34.4197C12.2699 34.4197 12.0243 34.6689 12.0243 34.9763C12.0243 35.2838 12.2699 35.533 12.5729 35.533Z"
        fill="#211E1F"
      />
      <path
        d="M14.6628 36.4211C15.0028 36.4211 15.2784 36.1414 15.2784 35.7964C15.2784 35.4514 15.0028 35.1718 14.6628 35.1718C14.3228 35.1718 14.0471 35.4514 14.0471 35.7964C14.0471 36.1414 14.3228 36.4211 14.6628 36.4211Z"
        fill="#211E1F"
      />
      <path
        d="M16.803 37.0457C17.1431 37.0457 17.4187 36.766 17.4187 36.421C17.4187 36.0761 17.1431 35.7964 16.803 35.7964C16.463 35.7964 16.1874 36.0761 16.1874 36.421C16.1874 36.766 16.463 37.0457 16.803 37.0457Z"
        fill="#211E1F"
      />
      <path
        d="M18.9976 37.4069C19.2937 37.4069 19.5337 37.1634 19.5337 36.863C19.5337 36.5626 19.2937 36.3191 18.9976 36.3191C18.7016 36.3191 18.4615 36.5626 18.4615 36.863C18.4615 37.1634 18.7016 37.4069 18.9976 37.4069Z"
        fill="#211E1F"
      />
      <path
        d="M21.2049 37.7511C21.4755 37.7511 21.6949 37.5285 21.6949 37.2539C21.6949 36.9793 21.4755 36.7567 21.2049 36.7567C20.9342 36.7567 20.7148 36.9793 20.7148 37.2539C20.7148 37.5285 20.9342 37.7511 21.2049 37.7511Z"
        fill="#211E1F"
      />
      <path
        d="M17.2678 10.7381C17.5385 10.7381 17.7579 10.5155 17.7579 10.2409C17.7579 9.96637 17.5385 9.74377 17.2678 9.74377C16.9972 9.74377 16.7778 9.96637 16.7778 10.2409C16.7778 10.5155 16.9972 10.7381 17.2678 10.7381Z"
        fill="#211E1F"
      />
      <path
        d="M19.1861 12.2083C19.4891 12.2083 19.7348 11.9591 19.7348 11.6516C19.7348 11.3442 19.4891 11.095 19.1861 11.095C18.8831 11.095 18.6375 11.3442 18.6375 11.6516C18.6375 11.9591 18.8831 12.2083 19.1861 12.2083Z"
        fill="#211E1F"
      />
      <path
        d="M21.2089 13.3217C21.5489 13.3217 21.8246 13.042 21.8246 12.697C21.8246 12.3521 21.5489 12.0724 21.2089 12.0724C20.8689 12.0724 20.5933 12.3521 20.5933 12.697C20.5933 13.042 20.8689 13.3217 21.2089 13.3217Z"
        fill="#211E1F"
      />
      <path
        d="M23.3491 13.9463C23.6891 13.9463 23.9647 13.6667 23.9647 13.3217C23.9647 12.9767 23.6891 12.697 23.3491 12.697C23.009 12.697 22.7334 12.9767 22.7334 13.3217C22.7334 13.6667 23.009 13.9463 23.3491 13.9463Z"
        fill="#211E1F"
      />
      <path
        d="M25.6108 14.0695C25.9069 14.0695 26.1469 13.826 26.1469 13.5256C26.1469 13.2252 25.9069 12.9817 25.6108 12.9817C25.3147 12.9817 25.0747 13.2252 25.0747 13.5256C25.0747 13.826 25.3147 14.0695 25.6108 14.0695Z"
        fill="#211E1F"
      />
      <path
        d="M27.9728 13.8613C28.2434 13.8613 28.4628 13.6387 28.4628 13.3641C28.4628 13.0895 28.2434 12.8669 27.9728 12.8669C27.7022 12.8669 27.4828 13.0895 27.4828 13.3641C27.4828 13.6387 27.7022 13.8613 27.9728 13.8613Z"
        fill="#211E1F"
      />
      <path
        d="M18.7421 25.4791C15.9653 24.6675 14.3571 21.7184 15.157 18.9054C15.957 16.0881 18.8636 14.4563 21.6362 15.2679C24.4129 16.0796 26.0212 19.0286 25.2212 21.8417C24.4213 24.6547 21.5189 26.2907 18.7421 25.4791ZM21.4267 16.0158C19.0562 15.3232 16.5768 16.717 15.8942 19.1221C15.2115 21.5272 16.5852 24.0428 18.9557 24.7355C21.3262 25.4281 23.8056 24.0343 24.4883 21.6292C25.171 19.2241 23.7973 16.7042 21.4267 16.0158Z"
        fill="#211E1F"
      />
      <path
        d="M16.9286 20.2565C16.7527 20.2055 16.6312 20.0313 16.6564 19.8401C16.7527 18.9605 17.1841 18.1743 17.8668 17.6219C18.717 16.9335 19.831 16.6998 20.8822 17.0525C21.0833 17.1163 21.1964 17.333 21.1335 17.5412C21.0707 17.7452 20.8739 17.8514 20.6519 17.7962C19.8478 17.5369 18.9934 17.7069 18.3442 18.2296C17.8207 18.6503 17.494 19.2537 17.4186 19.9293C17.3935 20.1418 17.205 20.2948 16.9956 20.2735C16.9705 20.265 16.9495 20.2608 16.9286 20.2565Z"
        fill="#211E1F"
      />
      <path
        d="M18.8845 24.9862C16.5056 24.2935 14.3906 21.6249 15.157 18.9054C15.9569 16.0881 18.8636 14.4563 21.6361 15.2679C24.4129 16.0796 26.0212 19.0286 25.2212 21.8417C24.4171 24.693 21.3053 25.6916 18.8845 24.9862ZM21.4267 16.0158C19.0562 15.3232 16.5768 16.717 15.8941 19.1221C15.1905 21.6122 17.3391 23.7284 19.0939 24.2425C21.1587 24.8459 23.8098 24.0173 24.4883 21.6292C25.171 19.2241 23.7972 16.7042 21.4267 16.0158Z"
        fill="#211E1F"
      />
      <path
        d="M22.7083 11.4944C18.5076 10.2706 16.5978 7.70403 16.5182 7.59355L17.1339 7.13037C17.1506 7.15587 18.985 9.59923 22.9219 10.7466C26.8588 11.8939 29.6942 10.8145 29.7193 10.8018L29.9958 11.5242C29.8701 11.5752 26.9091 12.7182 22.7083 11.4944Z"
        fill="#211E1F"
      />
      <path
        d="M12.0997 40.7725C12.0997 40.7725 8.89576 42.7272 9.10517 45.6167C9.31458 48.5062 10.9773 49.7428 10.9773 49.7428C10.9773 49.7428 11.4464 44.5119 14.2734 45.3362C16.9664 46.1224 17.4438 42.3277 17.4438 42.3277L12.0997 40.7725Z"
        fill="#E4E522"
      />
      <path
        d="M11.2997 50.4651L10.7552 50.0572C10.6798 50.0019 8.94592 48.6804 8.72814 45.6464C8.50198 42.5528 11.7688 40.5217 11.907 40.4367L12.0494 40.3517L17.8709 42.0514L17.8291 42.3829C17.8165 42.4721 17.5401 44.5883 16.225 45.4636C15.6345 45.8588 14.9434 45.9438 14.1728 45.7143C13.7037 45.5784 13.3016 45.6336 12.9456 45.8801C11.8735 46.6237 11.4421 48.9481 11.3667 49.781L11.2997 50.4651ZM12.1624 41.1931C11.5803 41.5925 9.32286 43.3007 9.4862 45.5869C9.60766 47.2654 10.2443 48.3447 10.7091 48.9183C10.8976 47.856 11.3834 46.016 12.5059 45.2384C13.0587 44.856 13.6869 44.7625 14.378 44.9665C14.935 45.1279 15.3999 45.0769 15.7978 44.8135C16.5056 44.3418 16.8658 43.254 17.004 42.6081L12.1624 41.1931Z"
        fill="#211E1F"
      />
    </g>
    <g clip-path="url(#clip1)">
      <path
        d="M53.376 13.1303C53.376 11.8595 54.0375 11.1559 55.303 11.1559H65.2832C71.3087 11.1559 75.6373 14.2575 75.6373 19.7571C75.6373 22.8587 74.1346 25.5367 71.5892 26.9008V26.9942C75.2131 28.0783 76.9532 31.4599 76.9532 34.8415C76.9532 41.5186 71.6323 44.5269 65.4198 44.5269H55.303C54.0375 44.5269 53.376 43.8663 53.376 42.5525V13.1303ZM65.3336 24.79C67.9724 24.79 69.4321 22.909 69.4321 20.511C69.4321 18.113 68.0227 16.3755 65.1969 16.3755H59.4015V24.79H65.3336ZM65.8944 39.3073C69.0006 39.3073 70.7407 37.2826 70.7407 34.4682C70.7407 31.6968 68.9503 29.6722 65.8944 29.6722H59.4015V39.3073H65.8944Z"
        fill="#00A567"
      />
      <path
        d="M93.527 19.994C100.825 19.994 106.757 25.2136 106.757 32.544C106.757 39.9247 100.825 45.094 93.527 45.094C86.2288 45.094 80.347 39.9247 80.347 32.544C80.347 25.2064 86.2288 19.994 93.527 19.994ZM93.527 40.018C97.4314 40.018 100.681 37.0098 100.681 32.544C100.681 28.1285 97.4314 25.07 93.527 25.07C89.6226 25.07 86.4157 28.1285 86.4157 32.544C86.4157 37.0098 89.6226 40.018 93.527 40.018Z"
        fill="#00A567"
      />
      <path
        d="M122.806 19.994C130.104 19.994 136.036 25.2136 136.036 32.544C136.036 39.9247 130.104 45.094 122.806 45.094C115.508 45.094 109.626 39.9247 109.626 32.544C109.626 25.2064 115.508 19.994 122.806 19.994ZM122.806 40.018C126.711 40.018 129.961 37.0098 129.961 32.544C129.961 28.1285 126.711 25.07 122.806 25.07C118.902 25.07 115.695 28.1285 115.695 32.544C115.695 37.0098 118.895 40.018 122.806 40.018Z"
        fill="#00A567"
      />
      <path
        d="M139.373 39.9678L139.941 39.1206C140.645 38.137 141.444 38.0365 142.623 38.6539C143.752 39.2642 145.542 40.298 147.987 40.298C150.058 40.298 151.33 39.3575 151.33 37.857C151.33 33.9584 139.423 35.4159 139.423 27.188C139.423 22.4854 143.565 19.994 148.605 19.994C151.805 19.994 154.02 21.0279 155.292 21.7315C156.378 22.2987 156.515 23.2823 155.997 24.3664L155.529 25.2136C154.961 26.3408 154.07 26.4844 152.891 25.9675C151.855 25.5008 150.302 24.79 148.325 24.79C146.254 24.79 145.075 25.6372 145.075 27.1378C145.075 31.0866 156.982 29.5358 156.982 37.7134C156.982 41.9422 153.408 45.094 147.85 45.094C143.989 45.094 141.307 43.6366 139.847 42.653C138.762 42.0355 138.668 41.0016 139.373 39.9678Z"
        fill="#00A567"
      />
      <path
        d="M162.77 25.63H161.497C160.225 25.63 159.57 24.9695 159.57 23.6556V22.8586C159.57 21.5879 160.232 20.8842 161.548 20.8842H162.914V15.9949C162.914 14.7241 163.619 14.0205 164.891 14.0205H166.775C168.048 14.0205 168.753 14.7241 168.753 15.9949V20.8842H172.233C173.505 20.8842 174.21 21.5879 174.21 22.8586V23.6556C174.21 24.9695 173.505 25.63 172.283 25.63H168.753V34.9348C168.753 38.5031 170.967 39.2139 172.614 39.4005C174.073 39.5441 174.591 40.0108 174.591 41.3247V42.7822C174.591 44.1894 173.793 44.7566 172.146 44.7566C167.861 44.7566 162.777 42.4519 162.777 35.9687V25.63H162.77Z"
        fill="#00A567"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="31.692" height="60.604" fill="white" />
      </clipPath>
      <clipPath id="clip1">
        <rect width="121.895" height="34.0589" fill="white" transform="translate(53.105 11.1559)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '$vuetify.icons.boost',
    },
  },
};
</script>