/*
 * @Author: Ethan Dinnen
 * @Date:   2018-11-30 11:03:26
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-01-15 12:12:07
 */

import initialState from './initialState';

const reset = state => {
  Object.assign(state, initialState());
};

const updateTitle = (state, value) => {
  state.title = value;
};

const updateType = (state, value) => {
  state.type = value;
};

const updateStagePlan = (state, value) => {
  state.stage = value;
};

export default {
  reset,
  updateTitle,
  updateType,
  updateStagePlan,
};
