/*
 * @Author: Ethan Dinnen
 * @Date:   2019-01-23 14:33:53
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-02-06 16:22:53
 */

const unnamedStages = state => {
  return state.stages.filter(({ title }) => !title).length;
};

const unnamedForms = state => {
  return state.templates.filter(({ name }) => !name).length;
};

const emptyForms = state => {
  return state.templates.filter(
    ({ template_fields }) => !template_fields.length
  ).length;
};

const unnamedFields = state => {
  return state.templates
    .map((t, i) => {
      return state.templates[i].template_fields.filter(({ label }) => !label)
        .length;
    })
    .reduce((acc, val) => acc + val, 0);
};

const duplicateForms = state => {
  return (
    new Set(state.templates.map(x => x['name'])).size !== state.templates.length
  );
};

const duplicateFields = state => {
  const allFields = state.templates.map(t => {
    return t.template_fields.map(f => f.label.toLowerCase());
  });

  const duplicateFieldsArr = allFields.map(arr => {
    return new Set(arr).size !== arr.length;
  });

  return duplicateFieldsArr.includes(true);
};

export default {
  unnamedStages,
  unnamedForms,
  emptyForms,
  unnamedFields,
  duplicateForms,
  duplicateFields,
};
