<!--
  @Author: Ethan Dinnen
  @Date:   2019-01-10 17:16:29
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-01-11 11:47:49
-->
<template>
  <v-layout id="forms-wrapper" column>
    <TitleBar style="padding-left: 28px;">Forms</TitleBar>
    <v-expansion-panel v-for="form in forms" :key="form.id">
      <v-expansion-panel-content>
        <div slot="header">
          <h3>{{ form.name }}</h3>
        </div>
        <v-card>
          <v-card-text>
            <div class="item-wrapper">
              <div
                v-for="item in completeFields(form.transaction_form_fields)"
                :key="item.id"
                class="item"
              >
                <span>{{ item.label }}:</span>
                {{ form.name === 'Financials' ? formatType(item) : item.value }}
              </div>
            </div>
            <router-link
              class="incomplete-prompt"
              :to="`/edit/${txId}`"
              v-if="numIncomplete(form.transaction_form_fields)"
            >
              There are
              {{ numIncomplete(form.transaction_form_fields) }} incomplete
              fields in this section. Click here to edit the transaction.
            </router-link>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-layout>
</template>

<script>
import TitleBar from '@/Core/components/TitleBar';
import formatCurrency from '@/Core/mixins/formatCurrency';
import transactionInputTypes from '@/Core/constants/transactionInputTypes';

export default {
  components: {
    TitleBar,
  },
  props: {
    txId: {
      type: Number,
      required: true,
    },
    forms: {
      type: Array,
      required: true,
    },
  },
  mixins: [formatCurrency],
  computed: {
    completedForms() {
      return this.forms.filter(form => this.hasCompletedFields(form));
    },
  },
  methods: {
    formatType(item) {
      if (item.type === transactionInputTypes.CURRENCY) return this.formatCurrency(item.value);
      if (item.type === transactionInputTypes.PERCENTAGE) return `${item.value}%`;
      return item.value;
    },
    numIncomplete(fields) {
      return fields.length - this.completeFieldsWithRelatives(fields);
    },
    hasCompletedFields(form) {
      const values = form.transaction_form_fields
        .map(field => field.value)
        .filter(value => !!value);
      return values.length;
    },
    completeFields(fields) {
      const filtered = fields.filter(field => !!field.value);
      const sorted = filtered.sort((a, b) =>
        a.position > b.position ? 1 : -1
      );
      return sorted;
    },
    completeFieldsWithRelatives(fields) {
      const completed = this.completeFields(fields).filter(
        field => field.type !== transactionInputTypes.RELATIVE
      ).length;
      const relative = fields.filter(
        field => field.type === transactionInputTypes.RELATIVE
      ).length;
      return completed + relative;
    },
  },
};
</script>

<style lang="scss">
@mixin n-columns($min-width, $gutter, $last-equal: false, $max-cols: 3) {
  .item-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$gutter;
    margin-top: -$gutter;

    .item {
      flex: 1 0 $min-width;
      margin-left: $gutter;
      margin-top: $gutter;

      @if $last-equal {
        @for $i from 2 through $max-cols {
          $screen-width: ($min-width * $i)+ ($gutter * $i);
          $column-width: (100% / $i);
          @media (min-width: $screen-width) {
            max-width: calc(#{$column-width} - #{$gutter});
          }
        }

        $column-width: (100% / $max-cols);
        @media (min-width: $min-width*$max-cols) {
          min-width: calc(#{$column-width} - #{$gutter});
        }
      }
    }
  }
}

#forms-wrapper {
  @include n-columns(200px, 10px, true, 3);

  margin-top: 30px;

  .item {
    span {
      color: var(--v-lion-grey-base);
    }
  }

  h3 {
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: 300;
  }

  .v-card__text {
    padding: 0 30px 20px;
  }

  .incomplete-prompt {
    position: relative;
    top: 10px;
  }
}
</style>
