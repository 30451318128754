import LdPlans from 'lion-vue-src/config/plans';
import LdFeatures from 'lion-vue-src/_globals/lib/LdFeatures';

const content = Object.freeze({
  Default: {
    title: 'Upgrade to access this feature',
    description: `
      <p>Upgrade your LionDesk subscription to access this feature</p>
      <p>Upgrading will give you access to this and many other features to help you connect, communicate and close with your clients.</p>
      <p><a href="UPGRADE_LINK">Learn more about our subscription options.</a></p>
    `,
    warning: false,
  },
  [LdFeatures.Emails]: {
    [LdPlans.MLS]: {
      title: 'DON’T STOP NOW – KEEP EMAILING YOUR CLIENTS',
      description: `<p>You are attemping to send a number of emails that will exceed your remaining limits of REMAINING_LIMIT.</p>
        <p>Overage pricing <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGE
        <p>Our higher subscription tiers include more free monthly emails and a reduced email overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.Starter]: {
      title: 'DON’T STOP NOW – KEEP EMAILING YOUR CLIENTS',
      description: `<p>You are attemping to send a number of emails that will exceed your remaining limits of REMAINING_LIMIT.</p>
        <p>Overage pricing <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGE
        <p>Our higher subscription tiers include more free monthly emails and a reduced email overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.ProPlus]: {
      title: 'DON’T STOP NOW – KEEP EMAILING YOUR CLIENTS',
      description: `<p>You are attemping to send a number of emails that will exceed your remaining limits of REMAINING_LIMIT.</p>
        <p>Overage pricing of <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGE.
        <p>Our higher subscription tiers include more free monthly emails and a reduced email overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.Pro]: {
      title: 'DON’T STOP NOW – KEEP EMAILING YOUR CLIENTS',
      description: `<p>You are attemping to send a number of emails that will exceed your remaining limits of REMAINING_LIMIT.</p>
        <p>Overage pricing <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGE
        <p>Our higher subscription tiers include more free monthly emails and a reduced email overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.Elite]: {
      title: 'DON’T STOP NOW – KEEP EMAILING YOUR CLIENTS',
      description: `<p>You are attemping to send a number of emails that will exceed your remaining limits of REMAINING_LIMIT.</p>
        <p>Overage pricing <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGE
        <p>Our enterprise solutions offer volume pricing for all communications and unlimited emailing.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.LeadsPlus]: {
      title: 'DON’T STOP NOW – KEEP EMAILING YOUR CLIENTS',
      description: `<p>You are attemping to send a number of emails that will exceed your remaining limits of REMAINING_LIMIT.</p>
        <p>Overage pricing of <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGE.
        <p>Our higher subscription tiers include more free monthly emails and a reduced email overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.CRM]: {
      title: 'DON’T STOP NOW – KEEP EMAILING YOUR CLIENTS',
      description: `<p>You are attemping to send a number of emails that will exceed your remaining limits of REMAINING_LIMIT.</p>
        <p>Overage pricing of <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGE.
        <p>Our higher subscription tiers include more free monthly emails and a reduced email overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.CRMPremier]: {
      title: 'DON’T STOP NOW – KEEP EMAILING YOUR CLIENTS',
      description: `<p>You are attemping to send a number of emails that will exceed your remaining limits of REMAINING_LIMIT.</p>
        <p>Overage pricing of <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGE.
        <p>Our higher subscription tiers include more free monthly emails and a reduced email overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.PAB]: {
      title: 'EMAIL CREDITS',
      description: `<p>You have zero email credits remaining for this month. RESET_MESSAGE </p>`,
      warning: true,
    },
  },
  [LdFeatures.TextMessaging]: {
    [LdPlans.Starter]: {
      title: 'DON’T STOP NOW – KEEP TEXTING YOUR CLIENTS',
      description: `<p>You are attemping to send a number of texts that will exceed your remaining limits of REMAINING_LIMIT</p>
        <p>Overage pricing <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGE
        <p>Our higher subscription tiers include a reduced text overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      noCustomNumber: `<p>You are attemping to send a number of texts that will exceed your remaining limits of REMAINING_LIMIT</p>
        <p><a href="CUSTOM_NUMBER_LINK">Purchase a custom number</a> to continue to text with overage pricing for an <strong>additional 100 credits for $5.</strong></p>
        <p>Our higher subscription tiers include a free custom number and reduced text overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.ProPlus]: {
      title: 'DON’T STOP NOW – KEEP TEXTING YOUR CLIENTS',
      description: `<p>You are attemping to send a number of texts that will exceed your remaining limits of REMAINING_LIMIT</p>
        <p>Overage pricing <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGE
        <p>Our higher subscription tiers include a reduced text overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.Elite]: {
      title: 'DON’T STOP NOW – KEEP TEXTING YOUR CLIENTS',
      description: `<p>You are attemping to send a number of texts that will exceed your remaining limits of REMAINING_LIMIT</p>
        <p>Overage pricing <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGE
        <p>Our enterprise solutions offer volume pricing for all communications.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.LeadsPlus]: {
      title: 'DON’T STOP NOW – KEEP TEXTING YOUR CLIENTS',
      description: `<p>You are attemping to send a number of texts that will exceed your remaining limits of REMAINING_LIMIT</p>
        <p>Overage pricing <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGE
        <p>Our higher subscription tiers include a reduced text overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.CRM]: {
      title: 'DON’T STOP NOW – KEEP TEXTING YOUR CLIENTS',
      description: `<p>You are attemping to send a number of texts that will exceed your remaining limits of REMAINING_LIMIT</p>
        <p>Overage pricing <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGE
        <p>Our higher subscription tiers include a reduced text overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      noCustomNumber: `<p>You are attemping to send a number of texts that will exceed your remaining limits of REMAINING_LIMIT</p>
        <p><a href="CUSTOM_NUMBER_LINK">Purchase a custom number</a> to continue to text with overage pricing for an <strong>additional 100 credits for $5.</strong></p>
        <p>Our higher subscription tiers include a free custom number and reduced text overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.CRMPremier]: {
      title: 'DON’T STOP NOW – KEEP TEXTING YOUR CLIENTS',
      description: `<p>You are attemping to send a number of texts that will exceed your remaining limits of REMAINING_LIMIT</p>
        <p>Overage pricing <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGE
        <p>Our higher subscription tiers include a reduced text overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      noCustomNumber: `<p>You are attemping to send a number of texts that will exceed your remaining limits of REMAINING_LIMIT</p>
        <p><a href="CUSTOM_NUMBER_LINK">Purchase a custom number</a> to continue to text with overage pricing for an <strong>additional 100 credits for $5.</strong></p>
        <p>Our higher subscription tiers include a free custom number and reduced text overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.PAB]: {
      title: 'TEXT CREDITS',
      description: `<p>You have zero text credits remaining for this month. RESET_MESSAGE </p>`,
      warning: true,
    },
  },
  [LdFeatures.BulkTexts]: {
    [LdPlans.Starter]: {
      title: 'DON’T STOP NOW – KEEP TEXTING YOUR CLIENTS',
      description: `<p>You are attemping to send a number of texts that will exceed your remaining limits of REMAINING_LIMIT</p>
        <p>Overage pricing <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGE
        <p>Our higher subscription tiers include a reduced text overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      noCustomNumber: `<p>You are attemping to send a number of texts that will exceed your remaining limits of REMAINING_LIMIT</p>
        <p><a href="CUSTOM_NUMBER_LINK">Purchase a custom number</a> to continue to text with overage pricing for an <strong>additional 100 credits for $5.</strong></p>
        <p>Our higher subscription tiers include a free custom number and reduced text overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.ProPlus]: {
      title: 'DON’T STOP NOW – KEEP TEXTING YOUR CLIENTS',
      description: `<p>You are attemping to send a number of texts that will exceed your remaining limits of REMAINING_LIMIT</p>
        <p>Overage pricing <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGE
        <p>Our higher subscription tiers include a reduced text overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.Elite]: {
      title: 'DON’T STOP NOW – KEEP TEXTING YOUR CLIENTS',
      description: `<p>You are attemping to send a number of texts that will exceed your remaining limits of REMAINING_LIMIT</p>
        <p>Overage pricing <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGE
        <p>Our enterprise solutions offer volume pricing for all communications.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.LeadsPlus]: {
      title: 'DON’T STOP NOW – KEEP TEXTING YOUR CLIENTS',
      description: `<p>You are attemping to send a number of texts that will exceed your remaining limits of REMAINING_LIMIT</p>
        <p>Overage pricing <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGE
        <p>Our higher subscription tiers include a reduced text overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.CRM]: {
      title: 'DON’T STOP NOW – KEEP TEXTING YOUR CLIENTS',
      description: `<p>You are attemping to send a number of texts that will exceed your remaining limits of REMAINING_LIMIT</p>
        <p>Overage pricing <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGE
        <p>Our higher subscription tiers include a reduced text overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.CRMPremier]: {
      title: 'DON’T STOP NOW – KEEP TEXTING YOUR CLIENTS',
      description: `<p>You are attemping to send a number of texts that will exceed your remaining limits of REMAINING_LIMIT</p>
        <p>Overage pricing <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGE
        <p>Our higher subscription tiers include a reduced text overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.PAB]: {
      title: 'TEXT CREDITS',
      description: `<p>You have zero text credits remaining for this month. RESET_MESSAGE </p>`,
      warning: true,
    },
  },
  [LdFeatures.VideoTexts]: {
    [LdPlans.Starter]: {
      title: 'DON’T STOP NOW - KEEP VIDEO TEXTING YOUR CLIENTS',
      description: `<p>You are attemping to send a number of video texts that will exceed your remaining limits of REMAINING_LIMIT</p>
        <p>Overage pricing <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGE
        <p>Our higher subscription tiers include a reduced video text overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      noCustomNumber: `<p>You are attemping to send a number of video texts that will exceed your remaining limits of REMAINING_LIMIT</p>
        <p><a href="CUSTOM_NUMBER_LINK">Purchase a custom number</a> to continue to text with overage pricing for an <strong>additional 100 credits for $7.</strong></p>
        <p>Our higher subscription tiers include a free custom number and reduced text overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.ProPlus]: {
      title: 'DON’T STOP NOW - KEEP VIDEO TEXTING YOUR CLIENTS',
      description: `<p>You are attemping to send a number of video texts that will exceed your remaining limits of REMAINING_LIMIT</p>
        <p>Overage pricing <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGE
        <p>Our higher subscription tiers include a reduced video text overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.Elite]: {
      title: 'DON’T STOP NOW - KEEP VIDEO TEXTING YOUR CLIENTS',
      description: `<p>You are attemping to send a number of video texts that will exceed your remaining limits of REMAINING_LIMIT</p>
        <p>Overage pricing <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGE
        <p>Our enterprise solutions offer volume pricing for all communications.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.LeadsPlus]: {
      title: 'DON’T STOP NOW - KEEP VIDEO TEXTING YOUR CLIENTS',
      description: `<p>You are attemping to send a number of video texts that will exceed your remaining limits of REMAINING_LIMIT</p>
        <p>Overage pricing <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGE
        <p>Our higher subscription tiers include a reduced video text overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.CRM]: {
      title: 'DON’T STOP NOW - KEEP VIDEO TEXTING YOUR CLIENTS',
      description: `<p>You are attemping to send a number of video texts that will exceed your remaining limits of REMAINING_LIMIT</p>
        <p>Overage pricing <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGE
        <p>Our higher subscription tiers include a reduced video text overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.CRMPremier]: {
      title: 'DON’T STOP NOW - KEEP VIDEO TEXTING YOUR CLIENTS',
      description: `<p>You are attemping to send a number of video texts that will exceed your remaining limits of REMAINING_LIMIT</p>
        <p>Overage pricing <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGE
        <p>Our higher subscription tiers include a reduced video text overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.PAB]: {
      title: 'VIDEO TEXT CREDITS',
      description: `<p>You have zero video text credits remaining for this month. RESET_MESSAGE </p>`,
      warning: true,
    },
  },
  [LdFeatures.LeadAssist]: {
    [LdPlans.Starter]: {
      title: 'DON’T STOP NOW - KEEP USING LEAD ASSIST',
      description: `<p>You are attempting to start a Lead Assist that will exceed your remaining limits of REMAINING_LIMIT.</p>
        <p>Overage pricing <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGE
        <p>Our higher subscription tiers include a <strong>free custom number</strong> and a reduced Lead Assist
        overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.ProPlus]: {
      title: 'DON’T STOP NOW - KEEP USING LEAD ASSIST',
      description: `<p>You are attempting to start a Lead Assist that will exceed your remaining limits of REMAINING_LIMIT.</p>
        <p>Overage pricing <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGEp>
        <p>Our higher subscription tiers include a <strong>free custom number</strong> and a reduced Lead Assist
        overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.Elite]: {
      title: 'DON’T STOP NOW - KEEP USING LEAD ASSIST',
      description: `<p>You are attempting to start a Lead Assist that will exceed your remaining limits of REMAINING_LIMIT.</p>
        <p>Overage pricing <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGEp>
        `,
      warning: true,
    },
    [LdPlans.LeadsPlus]: {
      title: 'DON’T STOP NOW - KEEP USING LEAD ASSIST',
      description: `<p>You are attempting to start a Lead Assist that will exceed your remaining limits of REMAINING_LIMIT.</p>
        <p>Overage pricing <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGEp>
        <p>Our higher subscription tiers include a <strong>free custom number</strong> and a reduced Lead Assist
        overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.CRM]: {
      title: 'DON’T STOP NOW - KEEP USING LEAD ASSIST',
      description: `<p>You are attempting to start a Lead Assist that will exceed your remaining limits of REMAINING_LIMIT.</p>
        <p>Overage pricing <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGEp>
        <p>Our higher subscription tiers include a <strong>free custom number</strong> and a reduced Lead Assist
        overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
    [LdPlans.CRMPremier]: {
      title: 'DON’T STOP NOW - KEEP USING LEAD ASSIST',
      description: `<p>You are attempting to start a Lead Assist that will exceed your remaining limits of REMAINING_LIMIT.</p>
        <p>Overage pricing <strong>OVERAGE_PRICING</strong> will be automatically applied after all limits are used. RESET_MESSAGEp>
        <p>Our higher subscription tiers include a <strong>free custom number</strong> and a reduced Lead Assist
        overage charge.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
    },
  },
  [LdFeatures.StormDialer]: {
    title: 'Subscribe to Storm Dialer',
    description: `<p>Subscribe to Storm Dialer</p>
      `,
  },
});

export default content;
