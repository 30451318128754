<template>
  <a @click="onAction($event, 'click')" :href="gatedHref" :class="gatedClass" :target="target">
    <slot></slot>
  </a>
</template>

<script>
import featureGate from 'lion-vue-src/Shared/mixins/featureGate';

export default {
  props: [ 'disabled', 'href', 'target', 'tabindex' ],
  mixins: [featureGate],
};
</script>
