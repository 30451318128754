/*
 * @Author: Kleber Pinel
 * @Date:   2018-12-14 10:53:51
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-02-11 11:23:16
 */

import axios from 'axios';

/**
 * Gets all transaction types in a market
 * @param {number|string} market The market id
 * @return {array} The array of transaction types for the specified market
 */
export const getTypes = async market => {
  return await axios
    .get(`markets/${market}/transaction_types`)
    .then(data => {
      return data.data;
    })
    .catch(err => {
      throw new Error(err.stack);
    });
};

export const getType = async (market, id) => {
  return await axios
    .get(`markets/${market}/transaction_types/${id}`)
    .then(data => {
      return data.data;
    })
    .catch(err => {
      throw new Error(err.stack);
    });
};

/**
 * Get infos for Transaction Type Widget Pipeline
 * @return {array} Returns an array will all info
 */
export const getWidget = async () => {
  const marketId = 1;
  return await axios
    .get(`markets/${marketId}/transaction_types/widgets`)
    .then(data => {
      return data.data;
    })
    .catch(err => {
      throw new Error(err.stack);
    });
};

export const getTemplates = async (market, id) => {
  return await axios
    .get(`markets/${market}/transaction_types/${id}/templates`)
    .then(data => {
      return data.data;
    })
    .catch(err => {
      throw new Error(err.stack);
    });
};

export const update = async (market, id, transactionType) => {
  return await axios
    .put(`markets/${market}/transaction_types/${id}`, { transactionType })
    .then(data => {
      return data.data;
    })
    .catch(err => {
      throw new Error(err.stack);
    });
};

export const create = async (transactionType, stages, templates, market) => {
  return await axios
    .post(`markets/${market}/transaction_types`, {
      transactionType,
      stages,
      templates,
    })
    .then(data => {
      return data.data;
    })
    .catch(err => {
      throw new Error(err.stack);
    });
};

export const deleteOne = async (
  market,
  transactionType,
  stages,
  transactions
) => {
  return await axios
    .delete(`markets/${market}/transaction_types/${transactionType}`, {
      data: {
        stages,
        transactions,
      },
    })
    .then(data => {
      return data.data;
    })
    .catch(err => {
      throw new Error(err.stack);
    });
};
