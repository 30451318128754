<!--
  @Author: Ethan Dinnen
  @Date:   2019-01-23 13:55:33
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-01-30 13:55:46
-->

<template>
  <v-flex class="xs12 md5 tx-type-summary">
    <div class="header">{{ summaryTitle }}</div>
    <v-flex class="pr-0 pb-0 pl-0 xs8">
      <SummaryList
        :items="summaryValues"
        v-model="selectedItem"
        :editDisabled="editDisabled"
      />
    </v-flex>
  </v-flex>
</template>

<script>
import SummaryList from '@/Core/components/SummaryList';

export default {
  components: { SummaryList },

  data() {
    return {
      selectedItem: this.value,
    };
  },

  props: {
    editDisabled: Boolean,
    summaryValues: Array,
    value: String,
    summaryTitle: String,
  },
  watch: {
    selectedItem(value) {
      this.$emit('input', value);
    },
    value(value) {
      this.selectedItem = value;
    },
  },
};
</script>

<style lang="scss">
.header {
  font-size: 24px !important;
  font-weight: 400;
  line-height: 32px !important;
  letter-spacing: normal !important;
  &.open {
    color: var(--v-primary-dark-base) !important;
  }
}
.tx-type-summary {
  overflow-y: scroll;
  .v-expansion-panel {
    justify-content: start;
  }
}
</style>
