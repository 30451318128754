<template>
  <svg 
    data-icon="credit-card-inverse" 
    role="img" 
    viewBox="0 0 24 24"
    >

    <path
      fill="currentColor"
      d="M20 4H4A2 2 0 0 0 2 6V18A2 2 0 0 0 4 20H20A2 2 0 0 0 22 18V6A2 2 0 0 0 20 4M20 11H4V8H20Z" />
  </svg>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      default: '$vuetify.icons.credit-card-inverse',
    },
  },
};
</script>
