
const hasAccountManagementFF = state => {
  if (!state.userInfo) return false;
  const { feature_flags: featureFlags } = state.userInfo;
  return featureFlags.includes('acct_management');
};

const hasBrokersSalesDemoFF = state => {
  if (!state.userInfo) return false;
  const { feature_flags: featureFlags } = state.userInfo;
  return featureFlags.includes('brokers_sales_demo');
};

const profilePicture = state => {
  const result = {
    profilePictureURL: 0,
    size: 52,
    fontSize: 22,
  }
  if (!state.userInfo) return result;
  const user = state.userInfo;
  result.profilePictureURL = user && user.profile_picture ? user.profile_picture : null;
  return result;
}

const has10DlcCampaignStatusFF = state => {
  if (!state.userInfo) return false;
  const { feature_flags: featureFlags } = state.userInfo;
  return featureFlags.includes('new_10dlc_campaign_status');
};

export default {
  hasAccountManagementFF,
  profilePicture,
  has10DlcCampaignStatusFF,
  hasBrokersSalesDemoFF,
};
