/*
 * @Author: Ethan Dinnen
 * @Date:   2019-01-29 15:01:42
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-01-29 15:05:06
 */
export default {
  IN_PROCESS: 'in-process',
  CLOSED: 'closed',
};
