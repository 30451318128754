/*
 * @Author: Ethan Dinnen
 * @Date:   2018-12-04 12:05:03
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2018-12-14 15:46:17
 */
import axios from 'axios';

/**
 * Get list of all avaliable markets
 */
export const get = async () => {
  return await axios.get('markets').then(res => res.data);
};

/**
 * Get financials data for a market and transaction type
 */
export const getFinancials = async (market, typeId) => {
  return await axios
    .get(`markets/${market}/transaction_types/${typeId}/pipeline`)
    .then(res => res.data);
};
