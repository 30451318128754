/*
 * @Author: Ethan Dinnen
 * @Date:   2019-02-07 9:54:00
 * @Last Modified by:   ethan
 * @Last Modified time: 2019-02-21 16:03:47
 */
export default {
  CURRENCY: 'currency',
  PERCENTAGE: 'percentage',
  ABSOLUTE: 'absolute',
  RELATIVE: 'relative',
  DATE: 'date',
};
