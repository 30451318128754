import Bullhorn from 'lion-vue-shared/components/CustomIcons/Bullhorn';
import Facebook from 'lion-vue-shared/components/CustomIcons/Facebook';
import GoogleAds from 'lion-vue-shared/components/CustomIcons/GoogleAds';
import BreakthroughBroker from 'lion-vue-shared/components/CustomIcons/BreakthroughBroker';
import FileImageOutline from 'lion-vue-shared/components/CustomIcons/FileImageOutline';
import FileCompare from 'lion-vue-shared/components/CustomIcons/FileCompare';
import FileTableOutline from 'lion-vue-shared/components/CustomIcons/FileTableOutline';
import CardBulletedOutline from 'lion-vue-shared/components/CustomIcons/CardBulletedOutline';
import Mailbox from 'lion-vue-shared/components/CustomIcons/Mailbox';
import Liondesk from 'lion-vue-shared/components/CustomIcons/Liondesk';
import Pinned from 'lion-vue-shared/components/CustomIcons/Pinned';
import Unpinned from 'lion-vue-shared/components/CustomIcons/Unpinned';
import FileChart from 'lion-vue-shared/components/CustomIcons/FileChart';
import FileDocument from 'lion-vue-shared/components/CustomIcons/FileDocument';
import NewspaperVariant from 'lion-vue-shared/components/CustomIcons/NewspaperVariant';
import Newspaper from 'lion-vue-shared/components/CustomIcons/Newspaper';
import TagMultiple from 'lion-vue-shared/components/CustomIcons/TagMultiple';
import CreateOwn from 'lion-vue-shared/components/CustomIcons/CreateOwn';
import AdvertiseRealState from 'lion-vue-shared/components/CustomIcons/AdvertiseRealState';
import Gmail from 'lion-vue-shared/components/CustomIcons/Gmail';
import Outlook from 'lion-vue-shared/components/CustomIcons/Outlook';
import OutlookOutline from 'lion-vue-shared/components/CustomIcons/OutlookOutline';
import OtherMail from 'lion-vue-shared/components/CustomIcons/OtherMail';
import AdStats from 'lion-vue-shared/components/CustomIcons/AdStats';
import Microsoft from 'lion-vue-shared/components/CustomIcons/Microsoft';
import AccountQuestion from 'lion-vue-shared/components/CustomIcons/AccountQuestion';
import HelpCenter from 'lion-vue-shared/components/CustomIcons/HelpCenter';
import ViewGridOutline from 'lion-vue-shared/components/CustomIcons/ViewGridOutline';
import Marketplace from 'lion-vue-shared/components/CustomIcons/Marketplace';
import SubmitIdeas from 'lion-vue-shared/components/CustomIcons/SubmitIdeas';
import FileExcel from 'lion-vue-shared/components/CustomIcons/FileExcel';
import LoneWolf from 'lion-vue-shared/components/CustomIcons/LoneWolf';
import Boost from 'lion-vue-shared/components/CustomIcons/Boost';
import CloudCma from 'lion-vue-shared/components/CustomIcons/CloudCma';
import Qualify from 'lion-vue-shared/components/CustomIcons/Qualify';
import TagRightArrow from 'lion-vue-shared/components/CustomIcons/TagRightArrow';
import TagMinus from 'lion-vue-shared/components/CustomIcons/TagMinus';
import TagRemove from 'lion-vue-shared/components/CustomIcons/TagRemove';
import LeadsPlus from 'lion-vue-shared/components/CustomIcons/LeadsPlus';
import Sitemap from 'lion-vue-shared/components/CustomIcons/Sitemap';
import Addon from 'lion-vue-shared/components/CustomIcons/Addon';
import MicrosoftOutlook from 'lion-vue-shared/components/CustomIcons/MicrosoftOutlook';
import MicrosoftFlat from 'lion-vue-shared/components/CustomIcons/MicrosoftFlat';
import PhoneDial from 'lion-vue-shared/components/CustomIcons/PhoneDial';
import FaceAgent from 'lion-vue-shared/components/CustomIcons/FaceAgent';
import CreditCardInverse from 'lion-vue-shared/components/CustomIcons/CreditCardInverse';
import Export from 'lion-vue-shared/components/CustomIcons/Export';
import CellphonePlay from 'lion-vue-shared/components/CustomIcons/CellphonePlay';
import EditImage from 'lion-vue-shared/components/CustomIcons/EditImage';
import Clone from 'lion-vue-shared/components/CustomIcons/Clone';
import VerifyingInfo from 'lion-vue-shared/components/CustomIcons/VerifyingInfo';
import ProcessingStatus from 'lion-vue-shared/components/CustomIcons/ProcessingStatus';
import FailedStatus from 'lion-vue-shared/components/CustomIcons/FailedStatus';
import VerifiedStatus from 'lion-vue-shared/components/CustomIcons/VerifiedStatus';
import AcceptedStatus from 'lion-vue-shared/components/CustomIcons/AcceptedStatus';
import AcceptedErrorStatus from 'lion-vue-shared/components/CustomIcons/AcceptedErrorStatus';
import SuccessfulStatus from 'lion-vue-shared/components/CustomIcons/SuccessfulStatus';
import CancelOutline from 'lion-vue-shared/components/CustomIcons/CancelOutline';

// More CustomIcons can be added components here
const icons = {
  bullhorn: {
    component: Bullhorn,
  },
  facebook: {
    component: Facebook,
  },
  'google-ads': {
    component: GoogleAds,
  },
  'br-broker': {
    component: BreakthroughBroker,
  },
  'file-image': {
    component: FileImageOutline,
  },
  'file-table': {
    component: FileTableOutline,
  },
  'file-compare': {
    component: FileCompare,
  },
  'card-bulleted': {
    component: CardBulletedOutline,
  },
  liondesk: {
    component: Liondesk,
  },
  pinned: {
    component: Pinned,
  },
  unpinned: {
    component: Unpinned,
  },
  mailbox: {
    component: Mailbox,
  },
  'edit-image': {
    component: EditImage,
  },
  'file-chart': {
    component: FileChart,
  },
  'file-document': {
    component: FileDocument,
  },
  'newspaper-variant': {
    component: NewspaperVariant,
  },
  newspaper: {
    component: Newspaper,
  },
  'tag-multiple': {
    component: TagMultiple,
  },
  'create-own': {
    component: CreateOwn,
  },
  'advertise-real-state': {
    component: AdvertiseRealState,
  },
  gmail: {
    component: Gmail,
  },
  outlook: {
    component: Outlook,
  },
  outlook_outline: {
    component: OutlookOutline,
  },
  other_mail: {
    component: OtherMail,
  },
  'ad-stats': {
    component: AdStats,
  },
  microsoft: {
    component: Microsoft,
  },
  'account-question': {
    component: AccountQuestion,
  },
  'help-center': {
    component: HelpCenter,
  },
  'view-grid-outline': {
    component: ViewGridOutline,
  },
  'marketplace': {
    component: Marketplace,
  },
  'submit-ideas': {
    component: SubmitIdeas,
  },
  'file-excel': {
    component: FileExcel,
  },
  lonewolf: {
    component: LoneWolf,
  },
  boost: {
    component: Boost,
  },
  'cloud-cma': {
    component: CloudCma,
  },
  'qualify': {
    component: Qualify,
  },
  'tag-right-arrow': {
    component: TagRightArrow,
  },
  'tag-minus': {
    component: TagMinus,
  },
  'tag-remove': {
    component: TagRemove,
  },
  'leads-plus': {
    component: LeadsPlus,
  },
  sitemap: {
    component: Sitemap
  },
  addon: {
    component: Addon
  },
  'microsoft-outlook': {
    component: MicrosoftOutlook
  },
  'microsoft-flat': {
    component: MicrosoftFlat
  },
  'phone-dial': {
    component: PhoneDial
  },
  'face-agent': {
    component: FaceAgent
  },
  'credit-card-inverse': {
    component: CreditCardInverse
  },
  export: {
    component: Export
  },
  'cellphone-play': {
    component: CellphonePlay
  },
  clone: {
    component: Clone,
  },
  'verifying-info': {
    component: VerifyingInfo,
  },
  'processing-status': {
    component: ProcessingStatus
  },
  'failed-status': {
    component: FailedStatus
  },
  'verified-status': {
    component: VerifiedStatus
  },
  'accepted-status': {
    component: AcceptedStatus
  },
  'accepted-error-status': {
    component: AcceptedErrorStatus
  },
  'successful-status': {
    component: SuccessfulStatus
  },
  'cancel-outline': {
    component: CancelOutline,
  },
};

export default icons;
