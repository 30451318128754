<template>
  <svg
    data-icon="verified-status"
    viewBox="0 0 83 73"
    >
    <g id="Twilio-Registration---OTP-Modals" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Text-Copy-22" transform="translate(-874.000000, -191.000000)" fill-rule="nonzero">
            <g id="Group-3" transform="translate(874.500000, 191.500000)">
                <path d="M39,21 C39,22.0188912 39.0742902,23.0208345 39.2178683,24.0009441 L5.71428571,24 C4.21201209,24 3,25.2004011 3,26.673913 L3,55.8913043 C3,57.3648163 4.21201209,58.5652174 5.71428571,58.5652174 L26.7857143,58.5652174 C27.1810379,58.5652174 27.5603791,58.7212772 27.8412572,58.9994646 L35.821,66.903 L35.8214286,60.0652174 C35.8214286,59.2855213 36.4163167,58.6447687 37.1769686,58.572084 L37.3214286,58.5652174 L56.2857143,58.5652174 C57.7879879,58.5652174 59,57.3648163 59,55.8913043 L59.0002989,41.949707 C59.4957701,41.9830518 59.9958587,42 60.5,42 C61.0044806,42 61.504903,41.983029 62.0007014,41.9496397 L62,55.8913043 C62,59.0281694 59.4384121,61.5652174 56.2857143,61.5652174 L38.821,61.565 L38.8214286,70.5 C38.8214286,71.7869178 37.3219192,72.4566433 36.3661192,71.6570813 L36.2658856,71.5657528 L26.169,61.565 L5.71428571,61.5652174 C2.63490643,61.5652174 0.119463314,59.1447995 0.00413138916,56.1091578 L-2.84217094e-14,55.8913043 L-2.84217094e-14,26.673913 C-2.84217094e-14,23.537048 2.56158787,21 5.71428571,21 L39,21 Z" id="Combined-Shape" fill="#000000"></path>
                <path d="M14.5,36 C11.4624339,36 9,38.4624339 9,41.5 C9,44.5375661 11.4624339,47 14.5,47 C17.5375661,47 20,44.5375661 20,41.5 C20,38.4624339 17.5375661,36 14.5,36 Z M14.5,39 C15.8807119,39 17,40.1192881 17,41.5 C17,42.8807119 15.8807119,44 14.5,44 C13.1192881,44 12,42.8807119 12,41.5 C12,40.1192881 13.1192881,39 14.5,39 Z" id="Oval-Copy" fill="#000000"></path>
                <path d="M30.5,36 C27.4624339,36 25,38.4624339 25,41.5 C25,44.5375661 27.4624339,47 30.5,47 C33.5375661,47 36,44.5375661 36,41.5 C36,38.4624339 33.5375661,36 30.5,36 Z M30.5,39 C31.8807119,39 33,40.1192881 33,41.5 C33,42.8807119 31.8807119,44 30.5,44 C29.1192881,44 28,42.8807119 28,41.5 C28,40.1192881 29.1192881,39 30.5,39 Z" id="Oval-Copy-2" fill="#000000"></path>
                <path d="M47.5,36 C44.4624339,36 42,38.4624339 42,41.5 C42,44.5375661 44.4624339,47 47.5,47 C50.5375661,47 53,44.5375661 53,41.5 C53,38.4624339 50.5375661,36 47.5,36 Z M47.5,39 C48.8807119,39 50,40.1192881 50,41.5 C50,42.8807119 48.8807119,44 47.5,44 C46.1192881,44 45,42.8807119 45,41.5 C45,40.1192881 46.1192881,39 47.5,39 Z" id="Oval-Copy-3" fill="#000000"></path>
                <path d="M60.5,0 C48.6258779,0 39,9.40202025 39,21 C39,32.5979797 48.6258779,42 60.5,42 C72.3741221,42 82,32.5979797 82,21 C82,9.40202025 72.3741221,0 60.5,0 Z M60.5,3 C70.677819,3 78.9285714,11.0588745 78.9285714,21 C78.9285714,30.9411255 70.677819,39 60.5,39 C50.322181,39 42.0714286,30.9411255 42.0714286,21 C42.0714286,11.0588745 50.322181,3 60.5,3 Z" id="Oval-Copy-4" fill="#235EA1"></path>
                <path d="M67.5,8 L53.5,8 C53.2238576,8 53,8.22385763 53,8.5 L53,11.5 C53,11.7761424 53.2238576,12 53.5,12 L67.5,12 C67.7761424,12 68,11.7761424 68,11.5 L68,8.5 C68,8.22385763 67.7761424,8 67.5,8 Z M67,9 L67,11 L54,11 L54,9 L67,9 Z" id="Rectangle-Copy" fill="#235EA1"></path>
                <path d="M67.5,29 L53.5,29 C53.2238576,29 53,29.2238576 53,29.5 L53,32.5 C53,32.7761424 53.2238576,33 53.5,33 L67.5,33 C67.7761424,33 68,32.7761424 68,32.5 L68,29.5 C68,29.2238576 67.7761424,29 67.5,29 Z M67,30 L67,32 L54,32 L54,30 L67,30 Z" id="Rectangle-Copy-2" fill="#235EA1"></path>
                <path d="M56,11.5 L56,14.7727273 C56,15.9166355 56.3271182,16.5680211 57.1697765,17.4103004 L57.6644799,17.8841478 C58.6050743,18.7819879 59,19.4331258 59,20.5 C59,21.4957492 58.6559758,22.129346 57.8453678,22.9393772 L57.3452379,23.418496 C56.3721878,24.3473165 56,25.0071039 56,26.2272727 L56,29.5 L55,29.5 L55,26.2272727 C55,24.7767364 55.4453977,23.9018421 56.4581855,22.8871984 L56.9740041,22.3924966 C57.7356692,21.6654527 58,21.2296345 58,20.5 C58,19.8224822 57.7720821,19.3983061 57.1296907,18.7590542 L56.6547621,18.3048596 C55.5028122,17.2052711 55,16.3139221 55,14.7727273 L55,11.5 L56,11.5 Z" id="Path-Copy-2" fill="#235EA1"></path>
                <path d="M67,11.5 L67,14.7727273 C67,16.2232636 66.5546023,17.0981579 65.5418145,18.1128016 L65.0259959,18.6075034 C64.2643308,19.3345473 64,19.7703655 64,20.5 C64,21.1775178 64.2279179,21.6016939 64.8703093,22.2409458 L65.3452379,22.6951404 C66.4971878,23.7947289 67,24.6860779 67,26.2272727 L67,29.5 L66,29.5 L66,26.2272727 C66,25.0833645 65.6728818,24.4319789 64.8302235,23.5896996 L64.3355201,23.1158522 C63.3949257,22.2180121 63,21.5668742 63,20.5 C63,19.5042508 63.3440242,18.870654 64.1546322,18.0606228 L64.6547621,17.581504 C65.6278122,16.6526835 66,15.9928961 66,14.7727273 L66,11.5 L67,11.5 Z" id="Path-Copy-3" fill="#235EA1"></path>
                <polygon id="Path-Copy-4" fill="#235EA1" points="63 22.5 63 23.5 57 23.5 57 22.5"></polygon>
                <polygon id="Path-Copy-5" fill="#235EA1" points="61.5 13.5 61.5 14.5 55.5 14.5 55.5 13.5"></polygon>
                <polygon id="Path-Copy-6" fill="#235EA1" points="64.5 13.5 64.5 14.5 62.5 14.5 62.5 13.5"></polygon>
                <polygon id="Path-Copy-7" fill="#235EA1" points="66.5 26.5 66.5 27.5 60.5 27.5 60.5 26.5"></polygon>
                <polygon id="Path-Copy-8" fill="#235EA1" points="58.5 26.5 58.5 27.5 56.5 27.5 56.5 26.5"></polygon>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '$vuetify.icons.verified-status',
    },
  },
};
</script>
