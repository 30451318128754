<template>
  <TransactionsLayout>
    <CreatePlanPresenter
      :title="title"
      :titleRules="titleRules"
      :updateTitle="updateTitle"
      :updateType="onTypeChange"
      :updateStagePlan="updateStagePlan"
      :types="transactionTypes"
      :type="type"
      :stages="stages"
      :stage="stage"
    />
  </TransactionsLayout>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import TransactionsLayout from '@/Core/layouts/TransactionsLayout';
import CreatePlanPresenter from './CreatePlanPresenter';

const {
  mapState: mapPlanState,
  mapActions: mapPlanActions,
} = createNamespacedHelpers('plans');

const {
  mapState: mapTransactionsState,
  mapActions: mapTransactionsActions,
} = createNamespacedHelpers('transactions');

export default {
  components: {
    TransactionsLayout,
    CreatePlanPresenter,
  },
  data() {
    return {
      titleRules: [v => !!v || 'Please enter a title'],
    };
  },
  computed: {
    ...mapPlanState(['title', 'type', 'stage']),
    ...mapTransactionsState(['transactionTypes', 'stages']),
  },
  methods: {
    ...mapPlanActions(['updateTitle', 'updateType', 'updateStagePlan', 'reset']),
    ...mapTransactionsActions(['getTransactionTypes', 'getStages']),
    onTypeChange(e) {
      this.updateType(e);
      this.getStages(e);
    }
  },
  async beforeMount() {
    await this.reset();
    if (!this.transactionTypes) {
      await this.getTransactionTypes(1);
    }
  },
};
</script>
