<!--
@Author: Ethan Dinnen
@Date:   2018-12-20 11:21:00
@Last Modified by:   Ethan Dinnen
@Last Modified time: 2019-02-19 15:09:50
-->
<template>
  <v-container class="contact-list">
    <div v-for="(contact, i) in contacts" :key="`contact-${i}`" class="contact">
      <v-card
        class="contact-card"
        @mouseover="hover = i"
        @mouseleave="hover = null"
      >
        <v-btn flat icon @click="removeContact(contact)" v-if="hover === i">
          <v-icon small>clear</v-icon>
        </v-btn>
        <v-card-text class="contact-card__text">
          <div class="contact-card__info">
            <span class="name">{{ name(contact) }}</span>
            <span class="email">{{ contact.email }}</span>
            <span v-if="contact.owner_name" class="owner">
              Owner: {{ contact.owner_name }}
            </span>
          </div>
          <SelectInput
            :options="roles"
            label="Role"
            :selected="contact.roles[0] || 5"
            icon="expand_more"
            @onInput="handleSetRole(contact, $event)"
          />
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import SelectInput from '@/Core/components/SelectInput';

export default {
  components: {
    SelectInput,
  },
  props: {
    contacts: Array,
    removeContact: Function,
    roles: Array,
    setRole: Function,
  },
  data() {
    return {
      hover: null,
    };
  },
  methods: {
    name(contact) {
      return `${contact.first_name} ${contact.last_name}`;
    },
    stakeholder(contact) {
      return {
        meta: {
          first_name: contact.first_name,
          last_name: contact.last_name,
        },
      };
    },
    handleSetRole(contact, role) {
      this.setRole({ contact, role });
    },
  },
};
</script>

<style lang="scss">
.contact-list {
  display: flex;
  flex-direction: column;
  padding: 0;
  .contact {
    display: flex;
    flex-direction: row;
    position: relative;
    .contact-card {
      flex: 1 1 auto;
      margin: 2px;
      padding: 0 20px;
      &__text {
        display: flex;
        justify-content: space-between;
        padding: 0;
        .v-input {
          flex: unset;
          &__slot {
            &::before {
              display: none;
            }
            &::after {
              display: none;
            }
          }
          .v-select__selections {
            flex-direction: row-reverse;
            .v-select__selection {
              color: rgba(104, 104, 104, 0.3);
              font-family: 'Open Sans';
              font-size: 15px;
              line-height: 20px;
            }
          }
        }
      }
      &__info {
        display: flex;
        flex-direction: column;
        margin: auto 0;
        .name {
          color: var(--v-lion-grey-base);
          font-family: 'Open Sans';
          font-size: 15px;
          font-weight: 600;
          line-height: 20px;
        }
        .email {
          color: var(--v-lion-grey-base);
          font-family: 'Open Sans';
          font-size: 11px;
          line-height: 15px;
        }
        .owner {
          color: var(--v-lion-grey-lighten2);
          font-family: 'Open Sans';
          font-size: 10px;
          line-height: 15px;
        }
      }
    }
    .name-container,
    .role-container {
      margin: auto 0;
      background-color: #efefef;
      padding: 5px;
      height: 34px;
    }
    .name-container {
      margin-right: 1.5px;
      min-width: 50px;
      flex: 1 1 auto;
      .avatar {
        margin-right: 10px;
      }
    }
    .role-container {
      margin-left: 1px;
      text-align: center;
      flex: 1 1 5%;
    }
  }
  .v-btn {
    margin-right: 0;
    position: absolute;
    top: -12px;
    left: -14px;
  }
}
</style>
