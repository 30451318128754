/*
 * @Author: Ethan Dinnen
 * @Date:   2018-11-26 13:21:45
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-02-21 13:54:23
 */

import Vue from 'vue';
import VueResource from 'vue-resource';
import VueCookies from 'vue-cookies';
import VueAnalytics from 'vue-analytics';
import VueIntercom from 'vue-intercom';
import 'core/plugins/vuetify';
import { initHCProps } from 'lion-vue';
import App from './App.vue';
import axios from 'axios';
import store from './store';
import router from './router';
import { Auth } from 'api';
import smoothscroll from 'smoothscroll-polyfill';
import '@/Core/assets/scss/main.scss';

initHCProps({
  MLS_API: `${process.env.MLS_API}`,
  ACCOUNTS_API: `${process.env.ACCOUNTS_API}`,
  MAIN_DOMAIN: `${process.env.MAIN_DOMAIN}`,
  TRANSACTIONS_APP_URL: `${process.env.TRANSACTIONS_APP_URL}`,
  PLATFORM_APP_URL: `${process.env.PLATFORM_APP_URL}`,
  MLS_APP_URL: `${process.env.MLS_APP_URL}`,
  ACCOUNTS_APP_URL: `${process.env.ACCOUNTS_APP_URL}`,
  NYLAS_API_BASE_URL: `${process.env.NYLAS_API_BASE_URL}`,
  UL_PLATFORM_URL: `${process.env.UL_PLATFORM_URL}`,
  LW_DOMAIN: `${process.env.LW_DOMAIN}`,
});

// Polyfill for window.scroll smoothness
smoothscroll.polyfill();

Vue.config.productionTip = false;
Vue.use(VueResource);
Vue.use(VueCookies);
axios.defaults.headers.Authorization = `Bearer ${Vue.cookies.get('LD_AUTH')}`; // Setting auth header in order to make users/me from vue-components to work
Vue.use(VueAnalytics, {
  id: [process.env.GOOGLE_ANALYTICS_ID_TX, process.env.GOOGLE_ANALYTICS_ID_ALL],
  router, // Auto-track all routes
  autoTracking: {
    pageviewTemplate (route) {
      return {
        page: route.path === '/' ? route.name : router.path,
      }
    }
  },
  checkDuplicatedScript: true, // Ensure Google Analytics only loaded once
  debug: {
    sendHitTask: process.env.NODE_ENV === 'production', // Don't track dev/staging
  },
});

/**
 * Add 'v-click-outside' to fire an action if a click event occurs outside of
 * an applied element
 */
Vue.directive('click-outside', {
  bind: (el, binding, vnode) => {
    el.clickOutsideEvent = event => {
      if (!(el == event.target || el.contains(event.target))) {
        if (!vnode.context[binding.expression]) return;
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: el => {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

axios.defaults.baseURL = `${process.env.API_URL}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['x-device'] = 'browser';

axios.interceptors.request.use(config => {
  const authHeader = `Bearer ${Vue.cookies.get('LD_AUTH')}`;
  config.headers.Authorization = authHeader;
  return config;
}, error => {
  return Promise.reject(error);
});

const tokenGenerator = {
  getTokenPromise: null,
  token: null,
  async getToken() {
    if (!this.getTokenPromise) {
      this.getTokenPromise = new Promise(async (resolve, reject) => {
        if (!this.token) {
          try {
            const res = await axios.get(
              `${process.env.AUTHENTICATION_API_BASE_URL}/refresh-token`,
              {
                withCredentials: true,
              }
            );
            this.token = res.data.access_token;
            resolve(this.token);
          } catch (err) {
            reject(err);
          }
        } else {
          resolve(this.token);
        }
      });
    }
    return this.getTokenPromise;
  },
};

axios.interceptors.response.use(null, async ({ response }) => {
  if (
    response &&
    response.config &&
    response.config.url ===
      `${process.env.AUTHENTICATION_API_BASE_URL}/refresh-token`
  ) {
    Vue.cookies.remove('RL6Token');
    const redirect = encodeURIComponent(window.location.href);
    const newLocation = `${process.env.LOGIN_URL}/signin?ref=${redirect}`;
    window.location.assign(newLocation);
  }
  if (response && response.status === 401 && !response.config._retry) {
    try {
      const token = await tokenGenerator.getToken();
      if (!token) return Promise.reject(response);
      Vue.cookies.set(
        'LD_AUTH',
        token,
        null,
        null,
        process.env.MAIN_DOMAIN
      );
      response.config._retry = true;
      tokenGenerator.token = null;
      tokenGenerator.getTokenPromise = null;
      return axios.request(response.config);
    } catch (err) {
      tokenGenerator.token = null;
      tokenGenerator.getTokenPromise = null;
      return err;
    }
  }
  return Promise.reject(response);
});

Vue.use(VueIntercom, { appId: process.env.INTERCOM_APP_ID });

new Vue({
  router,
  store,
  render: h => h(App),
  async created() {
    await Auth.checkUser();
  },
}).$mount('#app');
