import startCase from 'lodash/startCase';

export default (dataSource) => {
  const {
    UnparsedAddress, StreetNumber, StreetNumberNumeric,
    StreetName, StreetSuffix, StreetDirPrefix,
  } = dataSource;

  if (UnparsedAddress) {
    return startCase(UnparsedAddress.toLowerCase());
  }

  const streetNumber = StreetNumberNumeric || StreetNumber
  return startCase(`${streetNumber} ${StreetDirPrefix} ${StreetName} ${StreetSuffix}`)
};
