// Default dropdown option to unset the current value
export const DEFAULT_UNSET_OPTION_NAME = 'None';

export const DEFAULT_UNSET_OPTION = {
  text: 'No one',
  value: 0,
  description: 'Unassigns the user from the selected contact(s)',
};

/**
 * Default timeout length for setting an error on API request
 * prevents error snackbar from showing up on page renav
 */
export const HIDE_ERROR_AFTER_MS = 5000;

export const CANCEL_DIRTY_TIMEOUT_MS = 1000;

/**
 * In cases where the internet connection is extremely fast,
 *   there's no visual feedback that the loading has begun or ended.
 *
 * The spinner appears and disappears instantly. To solve,
 * we may set a minimum time after which to hide the spinner.
 */
export const MIN_LOADING_ANIMATION_TIME_MS = 250;

/**
 * Common default query parameters for initial requests
 */
export const queryDefaults = Object.freeze({
  skip: 0,
  limit: 50,
});

let delayTimer;

/**
 * Util function to set delay while typing
 * @param {function} callback is the callback function after the type delay
 * @param {array} args is the arguments we want to pass to the callback
 */
export const debounce = (callback, args) => {
  clearTimeout(delayTimer);
  delayTimer = setTimeout(() => {
    callback(...args);
  }, 500);
};

/**
 * The function is commonly used with the debounce function above. It is used as the callback
 * function in a watcher for search. We only make the API request if the search is longer than
 * one character.
 *
 * @param  {String}   search   the search string
 * @param  {Function} callback the function that makes the API request
 * @return {}
 */
export function prepareRequest(search, callback) {
  if (typeof callback !== 'function') {
    console.error('Callback is not a function');
  } else {
    callback(search);
  }
}

/**
 * Give possible time slots in 12-hour format
 * @returns {Array}
 */
export const timeSlots = () => {
  const slots = [];
  const minutesPerDay = 1440;
  let hours;
  let minutes;
  let ap;
  for (let i = 0; i < minutesPerDay; i += 15) {
    hours = Math.floor(i / 60);
    minutes = i % 60;
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    ap = hours % 24 < 12 ? 'am' : 'pm';
    hours %= 12;
    if (hours === 0) {
      hours = 12;
    }
    slots.push(`${hours}:${minutes} ${ap}`);
  }
  return slots;
};

/**
 * Give possible time slots in 24-hour format
 * @returns {Array}
 */
export const timeSlots24Hour = () => {
  const slots = [];
  const minutesPerDay = 1440;
  let hours;
  let minutes;
  for (let i = 0; i < minutesPerDay; i += 15) {
    hours = Math.floor(i / 60);
    if (hours < 10) {
      hours = `0${hours}`;
    }
    minutes = i % 60;
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    slots.push(`${hours}:${minutes}`);
  }
  return slots;
};

/**
 * Regex to indicate the validity of a time string
 * @type {(function(*=): (boolean | string))[]}
 */
export const timeRules = [
  v =>
    /^(([1-9]|$)|1(?:[0-2]|$))((:)(([0-5])(([0-9])((| )(am|pm)))))$/.test(v) ||
    'Valid time is required',
];

/**
 * Regex to indicate the validity of a time string in 24-hour format
 * @type {(function(*=): (boolean | string))[]}
 */
export const timeRules24Hour = [
  v =>
    /^(((0|1)(?:[0-9]|$))|(2(?:[0-3]|$)))((:)(([0-5])(([0-9]))))$/.test(v) ||
    'Valid time is required',
];
