/*
 * @Author: Ethan Dinnen
 * @Date:   2018-11-30 11:03:26
 * @Last Modified by:   Kleber Pinel
 * @Last Modified time: 2018-12-18 15:29:19
 */

export default () => ({
  transactionTypesWidget: [],
  selectedTransactionTypeId: null,
});
