import axios from 'axios';
import { HC_PROPS } from 'lion-vue-src/_globals/routes';

export const getUserDetailsInformation = async data => {
  const ret = await axios({
    method: 'GET',
    url: `${HC_PROPS.ACCOUNTS_API}/users/me`,
    withCredentials: true,
  });
  return ret.data;
};

export const resendActivationEmail = async data => {
  const ret = await axios({
    method: 'PUT',
    url: `${HC_PROPS.ACCOUNTS_API}/authenticate/resend-activation-email`,
    data: { email: data },
  })
  return ret.data;
}

export const updateFeatureFlag = async (flag, active) => {
  return axios({
    method: "put",
    url: `${process.env.VUE_APP_ACCOUNTS_API}/users/update-feature-flags`,
    json: true,
    data: {
      mode: active ? 'add' : 'remove',
      flag,
    },
  })
};

export const getOnboardingUserInformation = () => {
  return axios({
    method: "get",
    url: `${HC_PROPS.ACCOUNTS_API}/users/onboarding/info`,
    json: true,
  });
};

export const updateProfilePicture = async fileId => {
  return axios({
    method: "put",
    url: `${HC_PROPS.ACCOUNTS_API}/users/profile-picture/${fileId}`,
    json: true,
  });
};

export const getULToken = () => {
  return axios({
    method: 'GET',
    url: `${HC_PROPS.ACCOUNTS_API}/authenticate/get-ul-token`,
  });
};
