<template lang="pug">
div(v-if="!isLoading && howItWorksVideo")
  v-btn(
    v-if="howItWorksVideo.show" 
    outline color='orange'
    class="font-weight-medium text-capitalize" 
    @click="modal_open") 
    v-icon.pr-1 play_circle_outline
    | {{ buttonText }}
  liondesk-dialog(
    v-if="isModalVisible"
    v-model="isModalVisible"
    :width="'initial'"
    :dialog-title="dialogTitle || 'How it works'" 
    ok-button-text="Close" 
    hide-cancel-button
    @click:ok="modal_close"
  )
    div(
      slot="body"
    )
      iframe(
        v-if="howItWorksVideo.url" 
        width="720px"
        height="407px"
        :src="howItWorksVideo.url" 
        allowfullscreen
        style="border:none; justify-content:center"
      )
      a(
        v-if="howItWorksVideo.zendesk"
        target="_blank"
        :href="howItWorksVideo.zendesk"
      )
        p(
          class="zendesk-link-text"
        ) Learn More about how {{ howItWorksVideo.feature }} work

</template>

<script>
import { mapActions, mapState } from 'vuex';
import Modal from '../mixins/modal';

export default {
  name: 'LdHowItWorks',
  components: {
    LiondeskDialog: () => import(/* webpackChunkName: "LiondeskDialog" */ './LdDialog'),
  },
  mixins: [Modal],
  props: {
    feature: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapState('howItWorks', ['videos', 'isLoading']),
    howItWorksVideo() {
      return this.videos.find(
        ({ feature }) => feature.toLowerCase() === this.feature.toLowerCase(),
      );
    },
    buttonText() {
      return this.howItWorksVideo.btn_text || 'How it works';
    },
    dialogTitle() {
      return `How ${this.howItWorksVideo.dialog_title} works`;
    },
  },
  created() {
    this.fetchHowItWorksVideos();
  },
  methods: {
    ...mapActions('howItWorks', ['fetchHowItWorksVideos']),
  },
};
</script>

<style>
  
  .zendesk-link-text {
    margin: 10px 0 0 0;
  }
</style>
