<template>
  <svg
    aria-hidden="true"
    focusable="false"
    data-icon="file-excel"
    role="img"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '$vuetify.icons.file-excel',
    },
  },
};
</script>
