<!--
    @Author: Ethan Dinnen
    @Date:   2018-11-30 11:36:30
@Last modified by:   kleberdasilva
@Last modified time: 2019-02-28T23:58:04-08:00
-->

<template>
  <v-text-field
    :label="title"
    :placeholder="placeholder"
    v-model="value"
    @input="emitInput"
    @keyup.enter="submit"
    :rules="rules"
    :required="required"
    :type="type"
  />
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    title: String,
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    entered: {
      type: String,
      required: false,
      default: '',
    },
    fieldId: {
      type: String,
      required: false,
      default: null,
    },
    submit: {
      type: Function,
    },
  },
  data() {
    return {
      value: this.entered,
    };
  },
  methods: {
    emitInput() {
      const { value, fieldId } = this;
      this.$emit('onInput', fieldId ? { value, fieldId } : value);
    },
  },
};
</script>
