export default {
  task: {
    action_type: 'None',
    category_id: 0,
    contact_id: 0,
    description: '',
    due_at: {
      value: '',
      days: '',
      timing: 'on',
    },
    folder_id: 0,
    is_all_day: true,
    notes: '',
    status: 'incomplete',
  },
  kcds: [],
  isEditing: false,
  /**
   * Template viewer-related state
   */
  templateFolders: [],
  displayTemplates: [],
  templatesInProgress: false,
  files: [],
  roles: [],
  action: {},
  teamMembers: [],
};
