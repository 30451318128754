import mls from '../Mls/routes';
import transactions from '../Transactions/routes';
import platform from '../Shared/routes';

import { HC_PROPS, initHCProps } from './index';

export default function (LOCAL_HC_PROPS) {
  return [
    ...platform(LOCAL_HC_PROPS),
    mls(LOCAL_HC_PROPS),
    transactions(LOCAL_HC_PROPS),
  ];
}
export { initHCProps, HC_PROPS };
