<template>
  <div class="ld-confirmation">
    <CriticalRiskPresenter
      v-if="critical"
      :value="value"
      :width="width"
      @click:ok="emitOk"
      @click:cancel="emitCancel"
      :cancelText="cancelText"
      :confirmText="confirmText"
      :parrotText="parrotText"
    >
      <template v-slot:header>
        <slot name="header" />
      </template>
      <template v-slot:criticalBody>
        <slot name="criticalBody" />
      </template>
      <template v-slot:body>
        <slot name="body" />
      </template>
    </CriticalRiskPresenter>

    <LowHighRiskPresenter
      v-else
      :risk="risk"
      :width="width"
      :value="value"
      @click:ok="emitOk"
      @click:cancel="emitCancel"
      :cancelText="cancelText"
      :confirmText="confirmText"
      :disabled="disabled"
    >
      <template v-slot:header>
        <slot name="header" />
      </template>
      <template v-slot:body>
        <slot name="body" />
      </template>
    </LowHighRiskPresenter>
  </div>
</template>

<script>
import LowHighRiskPresenter from './LowHighRiskPresenter';
import CriticalRiskPresenter from './CriticalRiskPresenter';
import riskLevels from './riskLevels';

export default {
  components: {
    LowHighRiskPresenter,
    CriticalRiskPresenter,
  },
  props: {
    risk: {
      type: String,
      default: riskLevels.LOW,
    },
    confirmText: {
      type: String,
      default: 'Ok',
    },
    cancelText: {
      type: String,
      default: 'Cancel',
    },
    parrotText: {
      type: String,
      default: 'DELETE',
    },
    width: {
      type: String,
      default: 'auto',
    },
    disabled: {
      type: Boolean,
    },
    value: {
      type: Boolean,
    },
  },
  computed: {
    critical() {
      return this.risk.toLowerCase() === riskLevels.CRITICAL;
    },
  },
  methods: {
    emitOk() {
      this.$emit('click:ok');
    },
    emitCancel() {
      this.$emit('click:cancel');
    },
  },
};
</script>


<style lang="scss">
.ld-confirmation {
  font-family: 'Nunito Sans';
  border-radius: 4px;
  .v-card__title {
    font-size: 22px;
    font-weight: 600;
    border-bottom: 1px solid var(--v-background-lighten3);
    margin: 0 15px 5px;
    padding-left: 0;
  }
  .v-card__text {
    font-size: 13px;
    font-family: 'Open Sans';
  }
  .v-card__actions {
    margin: 0 8px;
    padding-bottom: 15px;
    .v-btn {
      font-weight: bold;
      font-size: 16px;
    }
  }
}
</style>
