<template>
  <div v-bind="$props" :class="gatedClass">
    <div v-if="!isGated">
      <slot></slot>
    </div>
    <div v-else class="upgrade-banner">
      <i aria-hidden="true" class="v-icon material-icons theme--light">error_outline</i>
      <span v-html="message"></span>
    </div>
  </div>
</template>

<script>
import { HC_PROPS } from 'lion-vue-src/_globals';
import featureGate from 'lion-vue-src/Shared/mixins/featureGate';
import GatedFeatureContent from 'lion-vue-src/config/gatedContent';

export default {
  mixins: [featureGate],
  data() {
    return {
      GatedFeatureContent
    }
  },
  computed: {
    upgradeUrl() {
      return `${HC_PROPS.ACCOUNTS_APP_URL}/settings?subModal=true`;
    },
    message() {
      if (GatedFeatureContent[this.$props.feature] && GatedFeatureContent[this.$props.feature][this.plan]) {
        return GatedFeatureContent[this.$props.feature][this.plan].shortMessage;
      }

      const { shortMessage } = GatedFeatureContent.Default;
      return shortMessage.replace(/UPGRADE_URL/g, this.upgradeUrl);
    }
  },
};
</script>
