<template>
  <div @click="onAction($event, 'click')">
    <v-switch v-bind="$props" v-model="value" @change="onChange" :class="gatedClass" :disabled="disabled || isGated">
      <template slot="label">
        <slot name="label"></slot>
      </template>
    </v-switch>
  </div>
</template>

<script>
import featureGate from 'lion-vue-src/Shared/mixins/featureGate';

export default {
  props: [ 'label', 'value', 'color', 'disabled', 'tabindex' ],
  mixins: [featureGate],
  methods: {
    onChange(value) {
      if (!this.isGated) {
        this.$emit('input', value);
      }
    },
  },
};
</script>
