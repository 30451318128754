/*
 * @Author: Ethan Dinnen
 * @Date:   2018-11-30 11:03:26
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-02-15 14:05:55
 */

import {
  Transactions,
  TransactionTypes,
  Markets,
  Stages,
  Contacts,
  Campaigns,
  Plan,
  Teams,
} from 'api';
import router from 'router';
import stageTypes from '@/Core/constants/stageTypes';
import transactionInputTypes from '@/Core/constants/transactionInputTypes';
import kcdRelativeTypes from '@/Core/constants/kcdRelativeTypes';
import * as moment from 'moment';

const reset = ({ commit }) => commit('reset');

const createReset = ({ commit }) => commit('createReset');

const resetFinancials = ({ dispatch }) =>
  dispatch('forms/reset', {}, { root: true });

const updateTitle = ({ commit }, value) => commit('updateTitle', value);

const updateType = async ({ commit, dispatch }, value) => {
  commit('updateType', value);
  await dispatch('getTemplates');
};

const updateStage = ({ commit }, value) => commit('updateStage', value);

const updateProperty = ({ commit }, value) => commit('updateProperty', value);

const updateMarket = ({ commit }, value) => commit('updateMarket', value);

const updatePipeline = async ({ dispatch, state }, value) => {
  await dispatch('updateType', value);

  const { market, type } = state;
  await dispatch('getPipeline', { market, type });
};

const goBack = () => {
  window.history.length > 1 ? router.go(-1) : router.push('/');
};

const validateSetup = ({ commit }, value) => {
  commit('validateSetup', value);
};

const setRole = ({ commit }, target) => {
  commit('setRole', target);
};

const addContact = async ({ commit }, contact) => {
  commit('addContact', contact);
};

const addNewContact = async ({ commit }, newContact) => {
  commit('addNewContact', newContact);
};

const assignCampaign = async ({ commit }, data) => {
  commit('assignCampaign', data);
};

const addTempToPipeline = ({ commit }, obj) => {
  commit('addTempToPipeline', obj);
};

const removeTempFromPipeline = ({ commit }) => {
  commit('removeTempFromPipeline');
};

const updateFinancialsData = ({ commit }, target) => {
  commit('updateFinancialsData', target);
};

const resetFinancialsData = ({ commit }) => {
  commit('resetFinancialsData');
};

const setFormsDone = ({ commit }) => {
  commit('setFormsDone');
};

const setFormDone = ({ commit }, key) => {
  commit('setFormDone', key);
};

const updateSectionPercentage = ({ commit }, target) => {
  commit('updateSectionPercentage', target);
};

const setForms = ({ commit }, target) => {
  commit('setForms', target);
};

// API Calls

const getTransactions = async ({ commit }, value) => {
  const {
    rowsPerPage,
    page,
    sortBy,
    direction,
    query,
    filter = '',
    type,
  } = value;
  const transactions = await Transactions.getAll(
    rowsPerPage,
    page,
    sortBy,
    direction,
    query,
    filter,
    type
  );
  commit('updateTransactions', transactions);
};

const getTransaction = async ({ commit, dispatch }, transactionId ) => {
  const transaction = await Transactions.get(transactionId);
  const markets = await Markets.get();
  const market = markets[0].id;
  const type = transaction.transaction_type_id;

  commit('updateMarketList', markets);
  commit('updateTransactionTypes', await TransactionTypes.getTypes(market));
  commit('updateType', type);
  commit('setRoles', await Contacts.getRoles());
  commit('setCampaigns', await Campaigns.getAll());
  commit(
    'updateStages',
    await Stages.get(market, transaction.transaction_type.id)
  );
  await dispatch('getTemplates');
  // Form Summaries must be based on the actual transaction forms/fields
  // and NOT the type/template associated with this transaction
  // in order for it to still be editable if there are custom forms/fields
  // that were added to the transaction in addition to those that came with
  // the type/template
  await commit('setFormSummaries', transaction);
  // Sort the transaction forms in order of position
  const sort = (a, b) => a.position - b.position;
  const fieldsSorted = transaction.transaction_forms.map(form => {
    const sortedFields = form.transaction_form_fields.sort(sort);
    return {
      ...form,
      transaction_form_fields: sortedFields,
    };
  });
  const sorted = fieldsSorted.sort(sort);
  // Set the forms to sorted
  transaction.transaction_forms = sorted;
  dispatch('setFormsDone');
  commit('updateTransaction', transaction);
};

const setFormSummaries = ({ commit }, transaction) => {
  commit('setFormSummaries', transaction);
};

const getTransactionTypes = async ({ commit }, marketId) => {
  commit('updateTransactionTypes', await TransactionTypes.getTypes(marketId));
};

const getTemplates = async ({ state, commit }) => {
  const { market, type } = state;
  commit('getTemplates', await TransactionTypes.getTemplates(market, type));
};

const getStages = async ({ commit, state }, typeId) => {
  const { market } = state;
  const stages = await Stages.get(market, typeId);
  commit('updateStages', stages);
};

const getMarkets = async ({ commit }) => {
  commit('updateMarketList', await Markets.get());
};

const getPipeline = async ({ commit }, values) => {
  const { market, type } = values;
  const pipeline = await Markets.getFinancials(market, type);
  commit('updatePipeline', pipeline);
};

const moveStages = async ({ state }, data) => {
  const { from, to, id } = data;
  await Stages.move(state.market, state.type, id, from, to);
};

const saveTransaction = async ({ commit, state }) => {
  const {
    title,
    stage,
    type,
    property,
    selectedContacts,
    newContacts,
    templates,
  } = state;
  const data = {
    transaction: {
      title,
      stage_id: stage,
      transaction_type_id: type,
      meta: {
        property,
      },
    },
    contacts: {
      existingContacts: selectedContacts,
      newContacts,
    },
    forms: [...templates],
  };

  commit('saveTransaction', data);
};

const updateTransaction = async ({ state, dispatch }) => {

  let forms = [...state.transaction.transaction_forms];
  if (state.type !== state.transaction.transaction_type_id) {
    // if transaction type has been changed during edit
    // replace existing forms/fields with sanitized forms/fields from
    // new type template
    forms = [...state.transactionsToCreate[0].forms];
  }
  // If the "Key Contract Dates" form has any relative fields we need to fill them
  checkForRelativeFields(forms, dispatch);
  const {
    title,
    stage,
    type,
    property,
    selectedContacts,
    newContacts,
    transaction,
  } = state;

  const data = {
    id: transaction.id,
    title,
    stage_id: stage,
    transaction_type_id: type,
    meta: {
      property,
    },
    transaction_forms: forms,
    contacts: {
      existingContacts: selectedContacts,
      newContacts,
    },
  };
  return await Transactions.update(data);
};

const createTransactions = async ({ state, dispatch, commit }) => {
  let transactionsCreated = [];
  for (const transaction of state.transactionsToCreate) {
    // If the "Key Contract Dates" form has any relative fields we need to fill them
    checkForRelativeFields(transaction.forms, dispatch, true);
    const created = await Transactions.create(transaction);
    transactionsCreated.push(created);
  }
  commit('setTransactionsCreated',transactionsCreated);
};

// Search for any relative fields in KCD form to fill them
const checkForRelativeFields = (forms, dispatch, isCreation) => {
  if (!forms) return;
  const KEY_CONTRACT_DATES = 'Key Contract Dates';
  const keyContractDates = forms.find(form => form.name === KEY_CONTRACT_DATES);
  // If the transaction doesn't have keyContractDates or relative fields we stop the process
  if (!keyContractDates) return;
  const relativeFields = keyContractDates.transaction_form_fields.filter(
    field => field.type === transactionInputTypes.RELATIVE
  );
  if(!relativeFields.length) return;
  const keyContractDatesIndex = forms.findIndex(form => form.name === KEY_CONTRACT_DATES);
  // For each relative field we calculate its value and we update the field
  relativeFields.forEach((field) => {
    const referenceDate = getReferenceDateValue(field, keyContractDates);
    // If is creation we update the reference object directly since is looped in the outer scope
    if (isCreation) return setValueForCreate(referenceDate, field, keyContractDates);
    // Otherwise we update the field through the object in the store
    const fieldToUpdate = {
      index: keyContractDatesIndex,
      update: { ...field, value: referenceDate },
      name: KEY_CONTRACT_DATES
    };
    dispatch('forms/updateField', fieldToUpdate, { root: true });
  });
  if (isCreation) forms[keyContractDatesIndex] = keyContractDates;
}

// Update the field value in the referenced object
const setValueForCreate = (value, field, form) => {
  const index = form.transaction_form_fields.findIndex(
    oldField => oldField.meta.referenceId === field.meta.referenceId
  );
  form.transaction_form_fields[index] = { ...field, value };
}

// We reach the last referenced node inside the fields through the field meta
const getReferenceDateValue = ({ meta }, form) => {
  let relativeDate;
  const refField = form.transaction_form_fields.find(
    refField => refField.meta.referenceId === meta.reference
  );
  const { 
    value: refDate, 
    type: refType, 
    meta: refMeta 
  } = refField;
  // If the reference field is a relative field we keep looking
  if (refType === transactionInputTypes.RELATIVE) {
    relativeDate = getReferenceDateValue({ meta: refMeta }, form);
    if (!relativeDate) return '';
  }
  // Otherwise verify if the referenced field has a value or if we are carrying a value
  if (!refDate && !relativeDate) return '';
  relativeDate = relativeDate || refDate;
  // We calculate the new value through the meta and relative value
  return calculateRelativeDate(meta, relativeDate);
}

const calculateRelativeDate = ({ relativity, days }, date) => {
  const UNIT = 'days';
  const FORMAT = 'YYYY-MM-DD';
  switch(relativity){
    case kcdRelativeTypes.BEFORE:
      return moment(date).subtract(+days, UNIT).format(FORMAT);
    case kcdRelativeTypes.AFTER:
      return moment(date).add(+days, UNIT).format(FORMAT);
    default:
      return date;
  }
}

/* eslint-disable */
const deleteTransaction = async ({}, id) => {
  /* eslint-enable */
  await Transactions.deleteOne(id);
  return;
};

const getRoles = async ({ commit }) => {
  const roles = await Contacts.getRoles();
  commit('setRoles', roles);
};

const searchContacts = async ({ commit }, q) => {
  const contacts = await Contacts.searchContacts(q);
  commit('setContacts', contacts);
};

const searchTeammateSelfContacts = async ({ commit }, q) => {
  const contactsMs = await Contacts.searchTeammateSelfContacts(q);
  let contacts = contactsMs.self.contacts ? [...contactsMs.self.contacts] : [];
  if (!contactsMs.team_members) {
    commit('setContacts', contacts);
    return;
  }
  contactsMs.team_members.map(teamMember => {
    if (!teamMember.contacts) return;
    const tContacts = teamMember.contacts.map(c => {
      return {
        ...c,
        owner_name: `${teamMember.first_name} ${teamMember.last_name}`,
      };
    });
    contacts = [...contacts, ...tContacts];
  });
  commit('setContacts', contacts);
};

const removeContact = async ({ commit }, contact) => {
  commit('removeContact', contact);
};

const getCampaigns = async ({ commit }) => {
  const campaigns = await Campaigns.getAll();
  commit('setCampaigns', campaigns);
};

const renameStage = async ({ dispatch, state }, stage) => {
  const { market, type } = state;
  await Stages.update(market, type, stage.id, stage);
  dispatch('getPipeline', { market, type });
};

const deleteStage = async ({ dispatch, commit, state }, id) => {
  const { market, type } = state;
  try {
    await Stages.deleteStage(market, type, id);
    commit('setPipelineError', '');
    dispatch('getPipeline', { market, type });
  } catch (err) {
    commit('setPipelineError', err.message);
  }
};

const createStage = async ({ dispatch, state }, stage) => {
  const { market, type, pipeline } = state;
  if (stage.stage_type === stageTypes.IN_PROCESS) {
    const [, ...oldStages] = pipeline.pipeline.summationsByStage;
    for (const s of oldStages) {
      const shifted = {
        level: s.level + 1,
      };
      await Stages.update(market, type, s.id, shifted);
    }
  }

  await Stages.create(market, type, stage);
  dispatch('getPipeline', { market, type });
};

const getPlans = async ({ commit }) => {
  const plans = await Plan.getAll();
  commit('setPlans', plans);
};

const findPlans = async ({ commit }, query) => {
  const { title, typeId } = query;
  const plans = await Plan.find({ title, typeId });
  commit('setPlansSearchResults', plans);
};

const deletePlan = async ({ dispatch }, id) => {
  await Plan.destroy(id);
  await dispatch('getPlans');
};

const startPlan = async ({ dispatch }, { planId, txId }) => {
  try {
    await Plan.startPlan(planId, txId);
    dispatch('queueSnack', { message: 'Plan Started Successfully', color: 'success' });
  } catch (e) {
    dispatch('queueSnack', { message: e.message, color: 'error' });
  }
};

const queueSnack = async ({ commit }, snack) => {
  commit('queueSnack', snack);
};

const clearLastSnack = async ({ commit }) => {
  commit('clearLastSnack');
};

export default {
  reset,
  createReset,
  resetFinancials,
  updateTitle,
  updateType,
  updateStage,
  updateProperty,
  updateMarket,
  updatePipeline,
  validateSetup,
  getTransaction,
  getTransactions,
  setFormSummaries,
  getTransactionTypes,
  getStages,
  getMarkets,
  getPipeline,
  goBack,
  moveStages,
  createTransactions,
  deleteTransaction,
  getRoles,
  setRole,
  searchContacts,
  searchTeammateSelfContacts,
  addContact,
  addNewContact,
  removeContact,
  getCampaigns,
  assignCampaign,
  saveTransaction,
  renameStage,
  deleteStage,
  updateTransaction,
  addTempToPipeline,
  removeTempFromPipeline,
  createStage,
  getTemplates,
  updateFinancialsData,
  resetFinancialsData,
  setFormsDone,
  setFormDone,
  updateSectionPercentage,
  setForms,
  getPlans,
  findPlans,
  deletePlan,
  startPlan,
  queueSnack,
  clearLastSnack,
};
