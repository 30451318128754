<template>
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 10C4 14 7 17 11 17C13 17 16 15.5 16 15.5L15 12.5C15 12.5 13.1 13 11.5 13C9.5 13 8 12 8 10C8 8 9.5 7 11.5 7C12.7 7 15 7.5 15 7.5L16 4.5C16 4.5 13 3 11 3C7 3 4 6 4 10Z" 
                stroke="#4B5563" stroke-width="1.5" stroke-linejoin="round"></path>
        <path d="M12 7L13 3.5" stroke="#4B5563" stroke-width="1.5"></path>
        <path d="M12 13L13 16.5" stroke="#4B5563" stroke-width="1.5"></path>
    </svg>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      default: '$vuetify.icons.leads-plus',
    },
  },
};
</script>
