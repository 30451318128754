<template>
  <v-snackbar :color="item.color" v-model="visible" bottom :timeout="item.timeout" @input="onClose">
    {{ item.message }}
    <v-btn v-if="item.showClose" dark flat @click="onClose">Close</v-btn>
  </v-snackbar>
</template>
<script>

const snackbarDefaults = { color: 'darkgrey', timeout: 4000, showClose: true };

export default {
  props: {
    items: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  watch: {
    items: {
      handler(value) {
        if (value && value.length > 0) {
          // small delay before showing next snackbar
          // to let for previous close animation finish
          setTimeout(() => {
            this.visible = true;
          }, 250);
        };
      },
      immediate: true,
    },
  },
  computed: {
    item() {
      let value;
      if (this.items && this.items.length > 0) {
        // if snack item is a plain string
        // convert it to an object with message
        if (typeof this.items[0] === 'string') {
          value = { message: this.items[0] };
        } else {
          value = this.items[0];
        }
      }
      // merge with defaults to set
      // properties not specified
      return Object.assign({}, snackbarDefaults, value);
    },
  },
  methods: {
    onClose() {
      this.visible = false;
      this.$emit('input');
    },
  },
};
</script>