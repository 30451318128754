<!--
    @Author: Ethan Dinnen
    @Date:   2018-12-05 14:40:54
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-03-25 15:25:30
-->
<template>
  <div
    class="flex financials-wizard wizard-container"
    :key="`${propertyId ? propertyId : 'financials-form'}`"
  >
    <!-- eslint-disable -->
    <div v-for="(field, i) in fieldSlice" :key="field.label">
      <TransactionFormInput
        v-if="!isCommission(field) && !isDate(field)"
        :type="field.type"
        :position="form.position"
        :title="field.label"
        :placeholder="placeholder(field)"
        :onInput="updateField"
        :default="getFormatted(field)"
        :prependIcon="isRelative(field) ? 'date_range' : null"
        selectOnClick
      />
      <TransactionFormDate
        v-else-if="isDate(field) && !isRelative(field)"
        :field="field"
        :default="getFormatted(field)"
        :position="form.position"
        @onInput="updateField"
      />
    </div>
    <!-- eslint-enable -->
    <a @click.prevent="handleMore" class="view-toggle" v-if="shouldShowMore">
      {{ showMore ? 'View Less Options' : 'View More Options' }}
      <v-icon>{{ showMore ? 'expand_less' : 'expand_more' }}</v-icon>
    </a>
    <div class="remaining" v-if="showMore">
      <div v-for="field in remaining" :key="field.label">
        <TransactionFormInput
          v-if="!isDate(field)"
          :type="field.type"
          :position="form.position"
          :title="field.label"
          :placeholder="placeholder(field)"
          :onInput="updateField"
          :default="getFormatted(field)"
        />
        <TransactionFormDate
          v-else-if="isDate(field) && !isRelative(field)"
          :field="field"
          :position="form.position"
          @onInput="updateField"
          :default="getFormatted(field)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TransactionFormInput from 'coreComponents/TransactionFormInput';
import TransactionFormDate from 'coreComponents/TransactionFormDate';
import formatCurrency from '@/Core/mixins/formatCurrency';
import transactionInputTypes from '@/Core/constants/transactionInputTypes';
import formTemplateNames from '@/Core/constants/formTemplateNames';
import kcdRelativeTypes from '@/Core/constants/kcdRelativeTypes';

export default {
  components: {
    TransactionFormInput,
    TransactionFormDate,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    updateField: {
      type: Function,
      required: true,
    },
    propertyId: {
      type: String,
      required: false,
    },
  },
  mixins: [formatCurrency],
  data() {
    return {
      showMore: false,
    };
  },
  computed: {
    // Fields to show initially
    fieldSlice() {
      // If editing a transaction form will have 'transaction_form_fields' property and not have template_fields
      if (this.form.transaction_form_fields) {
        return this.form.transaction_form_fields.slice(0, 4);
      }
      return this.form.template_fields.slice(0, 4);
    },
    // Fields to show when 'Show More' is clicked
    remaining() {
      // If editing a transaction...
      if (this.form.transaction_form_fields) {
        return this.form.transaction_form_fields.slice(4);
      }
      return this.form.template_fields.slice(4);
    },
    shouldShowMore() {
      return (
        this.remaining.length &&
        this.remaining.length !==
          this.remaining.filter(
            field => field.type === transactionInputTypes.RELATIVE
          ).length
      );
    },
  },
  methods: {
    isRelative(field) {
      return field.type === transactionInputTypes.RELATIVE;
    },
    isEqual(field) {
      if (field.meta && field.meta.relativity) {
        return field.meta.relativity === kcdRelativeTypes.EQUAL;
      }
      return false;
    },
    getFormatted(field) {
      if (field.type === transactionInputTypes.CURRENCY && field.value) {
        return this.formatCurrency(field.value);
      }
      if (field.value === '' || field.value === 'NaN') return '';
      return field.value;
    },
    isCommission(field) {
      return field.label === formTemplateNames.SALE_COMMISSION_TOTAL;
    },
    isDate({ type }) {
      const { DATE, ABSOLUTE, RELATIVE } = transactionInputTypes;
      return [DATE, ABSOLUTE, RELATIVE].includes(type);
    },
    placeholder(field) {
      if (field.label === formTemplateNames.SALE_COMMISSION_TOTAL) {
        return '$0';
      }
      return {
        currency: null,
        text: null,
        date: null,
      }[field.type];
    },
    // Toggle the remaining fields and scroll the container
    handleMore() {
      this.showMore = !this.showMore;
      if (!this.showMore) return;
      setTimeout(() => {
        document
          .querySelector('.financials-wizard')
          .scrollBy({ top: 425, behavior: 'smooth' });
      });
    },
  },
};
</script>

<style lang="scss">
h3 {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 15px;
}
.view-toggle {
  color: #3878c1;
  font-weight: 600;
  i {
    max-height: 17px;
    color: #3878c1 !important;
  }
}
.remaining {
  margin-top: 15px;
}
.financials-wizard {
  /* padding-top: 53px; */
  max-height: 425px;
  overflow-x: scroll;
}
</style>
