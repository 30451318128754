<!--
@Author: Ethan
@Date:   2019-03-20T11:17:30-07:00
@Email:  ethan@hackcapital.com
@Last modified by:   kleberdasilva
@Last modified time: 2019-03-25T14:40:17-07:00
-->

<template lang="pug">
  v-menu(
    left
    offset-y
    v-model="visible"
    :close-on-content-click="false"
    max-width="381"
    max-height="250"
  )
    span(slot="activator")
      slot
    v-card.emoji-picker
      div
        v-text-field.emoji-search(label="Search" v-model="search" ref="emojiSearch")
      div
        div(v-for="(emojiGroup, category) in emojis" :key="category")
          h4.emoji-category {{ category }}
          div.emojis
            span.emoji-choice(
              v-for="(emoji, emojiName) in emojiGroup"
              :tabindex="0"
              :key="emojiName"
              @click="insert(emoji)"
              @keyup.enter="insert(emoji)"
              :title="emojiName"
            ) {{ emoji }}
</template>

<script>
import emojis from '../../config/emojis';

export default {
  props: {
    emojiTable: {
      type: Object,
      required: false,
      default() {
        return emojis;
      },
    },
  },
  data() {
    return {
      visible: false,
      search: '',
    };
  },
  computed: {
    // Filter the emoji list if a search value is given
    emojis() {
      // No search value given, so return the full list
      if (!this.search) return this.emojiTable;

      // Loop over the emoji category names
      return Object.keys(this.emojiTable).reduce((obj, category) => {
        // Check for matching emojis within the current category
        const emojiCat = Object.keys(this.emojiTable[category]).reduce((emojiObj, emoji) => {
          // This emoji does not match the search so continue
          if (!new RegExp(`.*${this.search}.*`).test(emoji)) return emojiObj;
          // Matching emoji found; add it to the accumulator
          return { ...emojiObj, [emoji]: this.emojiTable[category][emoji] };
        }, {});

        // No matching emojis found in this category so continue
        if (!Object.keys(emojiCat).length) return obj;
        // Matching emojis found; add the category to the results
        return { ...obj, [category]: emojiCat };
      }, {});
    },
  },
  watch: {
    visible(open) {
      // Reset search value when window closed
      if (!open) {
        this.search = '';
        return;
      }
      // Focus on the search box
      setTimeout(() => {
        this.$refs.emojiSearch.focus();
      }, 250);
    },
  },
  methods: {
    insert(emoji) {
      this.$emit('emoji', emoji);
      this.hide();
    },
    hide() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss">
.emoji-picker {
  padding: 15px;
  width: 381px;
  .emoji-search {
    margin-top: -5px;
    width: 98%;
  }

  .emoji-category {
    height: 19px;
    color: var(--v-secondary-lighten3);
    font-family: 'Open Sans';
    margin-bottom: 5px;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.15px;
    line-height: 19px;
  }

  .emojis {
    display: flex;
    flex-wrap: wrap;
    font-size: 20px;

    .emoji-choice {
      margin: 2px;
    }
  }
}
</style>
