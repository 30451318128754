<template>
  <TransactionsLayout>
    <v-container dark fluit grid-list-xl class="plan-container">
      <div class="actions-container">
        <div class="buttons-container">
          <router-link to="/plans/create">
            <v-btn color="primary" class="new-btn"> Create Plan </v-btn>
          </router-link>
          <SelectInput
            :options="typeOptions"
            outline
            :selected="'all'"
            class="type-select"
            @onInput="setFilter"
          />
          <span class="total-plans"> {{ tableItems.length }} Plans </span>
        </div>
        <div class="search-container">
          <v-text-field
            solo
            hide-details
            prepend-inner-icon="search"
            v-model="search"
            label="Search"
            @input="handleSearch"
            class="plan-search"
          />
        </div>
      </div>

      <TransactionsTable
        :headers="headers"
        :items="tableItems"
        :isLoading="isLoading"
        :pagination="pagination"
        :handlePagination="handlePagination"
        :sortItems="sortItems"
        :rowsPerPageItems="rowsPerPageItems"
        showDeleteOnly
        plans
        :confirmDelete="handleConfirm"
        :handleDelete="deletePlan"
      />
    </v-container>

    <LdConfirmation
      :value="confirmationDialog"
      width="580"
      @click:cancel="confirmationDialog = false"
      @click:ok="handleDelete"
      confirmText="Delete"
    >
      <template slot="header">
        Delete {{ getPlanName(planToDelete) }}
      </template>
      <template slot="body">
        Are you sure? This cannot be undone.
      </template>
    </LdConfirmation>
    <v-snackbar v-model="showErrorSnack" color="error" :timeout="4000">
      {{ errorMessage }}
    </v-snackbar>
  </TransactionsLayout>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import TransactionsLayout from '@/Core/layouts/TransactionsLayout';
import { LdConfirmation } from 'lion-vue';
import SelectInput from '@/Core/components/SelectInput';
import TransactionsTable from './components/TransactionsTable';

const {
  mapState: mapTransactionsState,
  mapActions: mapTransactionsActions,
  mapMutations: mapTransactionsMutations,
} = createNamespacedHelpers('transactions');

export default {
  components: {
    TransactionsLayout,
    TransactionsTable,
    SelectInput,
    LdConfirmation,
  },
  data() {
    return {
      confirmationDialog: false,
      planToDelete: null,
      headers: [
        {
          text: 'Name',
          value: 'name',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Type',
          value: 'type',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'left',
          sortable: true,
        },
      ],
      search: '',
      isLoading: false,
      rowsPerPageItems: [5, 10, 15, 25, 50, 100],
      pagination: {
        page: 1,
        rowsPerPage: 10,
        totalItems: 0,
        sortBy: 'name',
        descending: true,
      },
      filterByType: 'all',
    };
  },
  computed: {
    ...mapTransactionsState([
      'transactionTypes',
      'plans',
      'errorMessage',
      'showErrorMessage',
    ]),
    items() {
      return this.plans.map(plan => {
        const { id, title, type_id, tasks } = plan;
        let numTasks = 0;
        if (tasks) numTasks = tasks.length;

        return {
          id,
          data: [
            {
              id,
              text: title,
              value: 'Title',
            },
            {
              text: this.getTypeName(type_id),
              value: type_id,
            },
            {
              text: numTasks.toString(),
              value: numTasks,
            },
          ],
        };
      });
    },
    tableItems() {
      let items = [...this.items];
      if (this.filterByType !== 'all')
        items = items.filter(i => i.data[1].value === this.filterByType);
      if (this.search) {
        items = items.filter(i => {
          const matches = i.data.filter(col =>
            col.text.toLowerCase().includes(this.search.toLowerCase())
          );
          if (matches.length) return true;
        });
      }
      return items;
    },
    typeOptions() {
      if (!this.transactionTypes) return [];
      const all = { text: 'All Types', value: 'all' };

      const types = this.transactionTypes.map(type => {
        return {
          text: type.name,
          value: type.id,
        };
      });
      return [all, ...types];
    },
    showErrorSnack: {
      set(value) {
        this.setShowErrorMessage(value);
      },
      get() {
        return this.showErrorMessage;
      }
    }
  },
  methods: {
    ...mapTransactionsActions([
      'getTransactionTypes',
      'getPlans',
      'deletePlan',
    ]),
    ...mapTransactionsMutations(['sortPlans', 'setShowErrorMessage']),
    getPlanName(id) {
      const plan = this.plans.find(plan => plan.id === id);
      if (!plan) return '';
      return plan.title;
    },
    getTypeName(id) {
      const type = this.transactionTypes.find(t => t.id === id);
      if (!type) return 'N/A';
      return type.name;
    },
    setFilter(value) {
      this.filterByType = value;
    },
    handleSearch(value) {
      this.search = value;
    },
    handleConfirm(id) {
      this.confirmationDialog = true;
      this.planToDelete = id;
    },
    async handleDelete() {
      const id = this.planToDelete;
      await this.deletePlan(id);
      this.confirmationDialog = false;
    },
    async handlePagination(data) {
      this.isLoading = true;
      this.isLoading = false;
      this.pagination = data;
      this.pagination.totalItems = 10;
    },
    sortItems() {
      this.sortPlans({
        column: this.pagination.sortBy,
        descending: this.pagination.descending
      });
    },
  },
  async beforeMount() {
    if (!this.transactionTypes) {
      await this.getTransactionTypes(1);
    }

    await this.getPlans();
  },
};
</script>

<style lang="scss">
.plan-container {
  margin-top: 5px;
  max-width: unset;
}
.new-btn {
  margin-left: 0;
  color: white !important;
}
.type-select {
  width: 140px;
  flex: unset;
  margin-top: 5px !important;
  font-weight: bold;
  font-size: 14px;
  font-family: 'Nunito', sans-serif;
  .v-input__slot {
    border: 2px solid var(--v-primary-base) !important;
    min-height: 38px !important;
  }
  .v-input__append-inner {
    margin: auto 0 !important;
  }
  .v-select__selections {
    color: var(--v-primary-base) !important;
    padding-top: 0 !important;
  }
  .v-icon {
    color: var(--v-primary-base);
  }
  input::placeholder {
    color: var(--v-primary-base) !important;
  }
}
.actions-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: -15px;
  .buttons-container {
    display: flex;
    flex-direction: row;
  }
}
.total-plans {
  margin-top: 13px;
  margin-left: 8px;
  font-size: 15px;
  color: var(--v-lion-grey-base);
}
</style>
