<template>
  <v-container id="listings" fluid grid-list-sm listing-container>
    <div class="listing-controls">
      <div style="display: flex;">
        <div class="listing-controls__sort-by">
          <ld-select-input
            :options="sortBy"
            :selected="sortSelected"
            @onInput="handleSelectSort"
          />
        </div>
        <div class="listing-controls__count" v-if="!selected.length">
          {{ totalListing }} {{ pluralize('Listing', allListings.length) }}
        </div>
        <!-- TODO: Uncomment this when CTA actions have been decided upon -->
        <!-- <div class="listing-controls__count" v-else>
          {{ selected.length }} {{ pluralize('Listing', selected.length) }} Selected
        </div> -->
        <!-- <v-btn class="select-listings" flat color="#3878C0" @click="handleSelectAll">
          {{ selected.length === allListings.length ? 'Deselect All' : 'Select All'}}
        </v-btn> -->
      </div>
      <div class="listing-controls__type">
        <MlsCtaContainer v-if="selected.length" />
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="grid = true;" v-on="on">
              <v-icon :color="grid ? ldPrimary : null">view_module</v-icon>
            </v-btn>
          </template>
          <span>Grid view</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="grid = false;" v-on="on">
              <v-icon :color="grid ? null : ldPrimary">list</v-icon>
            </v-btn>
          </template>
          <span>List view</span>
        </v-tooltip>
      </div>
    </div>
    <v-layout class="listings-display" row wrap :key="listingsKey">
      <v-flex
        :class="['listings-flex-item', grid ? 'grid' : '']"
        v-for="listing in listings"
        :key="listing.id"
        :style="flexStyle"
      >
        <GridCard
          v-if="grid"
          :isSelected="isSelected(listing)"
          :select="select"
          :listing="listing"
          :onItemClicked="itemClicked"
          :mlsNumberSelected="mlsNumberSelected"
          :noHover="noHover"
        />
        <TableCard
          v-else
          :isSelected="isSelected(listing)"
          :select="select"
          :listing="listing"
          :onItemClicked="itemClicked"
          :mlsNumberSelected="mlsNumberSelected"
          :noHover="noHover"
        />
      </v-flex>
    </v-layout>
    <div class="text-xs-center">
      <v-pagination
        v-model="currentPage"
        v-if="allListings.length > perPage"
        :value="currentPage"
        :length="paginationLimit"
        v-on:input="onChange($event)"
        :total-visible="paginationTotalVisible"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import GridCard from 'lion-vue-mls/components/MlsListingCardGridContainer';
import TableCard from 'lion-vue-mls/components/MlsListingCardTableContainer';
import MlsCtaContainer from 'lion-vue-mls/components/MlsCtaContainer';
// eslint-disable-next-line
import LdSelectInput from 'lion-vue-src/_globals/lib/LdSelectInput';
import Pluralize from 'lion-vue-mls/mixins/Pluralize';

export default {
  components: {
    GridCard, TableCard, MlsCtaContainer, LdSelectInput,
  },
  props: {
    columnSize: {
      type: String,
      default: 'xs6',
    },
    itemsPerPage: {
      type: Number,
      default: 20,
    },
    itemsPerRow: {
      type: Number,
      default: 2,
    },
    page: {
      type: Number,
      default: 1,
    },
    paginationTotalVisible: {
      type: Number,
      default: 10,
    },
    mlsNumberSelected: {
      type: String,
      default: null,
    },
    onItemClicked: {
      type: Function,
      default: () => {
        // eslint-disable-next-line no-alert
        alert('Calback not implemented for Item Clicked. [MlsListings.vue]');
      },
    },
    noHover: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [Pluralize],
  watch: {
    searchText(val) {
      this.currentPage = 1;
    },
  },
  computed: {
    ...mapState({
      allListings: state => state.listing.listings,
      selected: state => state.listing.selected,
      sortSelected: state => state.listing.sortSelected,
      orderSelected: state => state.listing.orderSelected,
      searchText: state => state.search.searchText,
    }),
    paginationLimit() {
      if (this.allListings.length < this.perPage) return 1;
      return Math.ceil(this.allListings.length / this.perPage);
    },
    listings() {
      if (this.allListings.length <= this.perPage) return this.allListings;
      return this.allListings.slice(this.listingSliceMin, this.listingSliceMax);
    },
    totalListing() {
      return this.allListings.length >= 500 ? '500+' : this.allListings.length;
    },
    flexStyle() {
      return this.grid ? 'display: flex; max-width: 50%;' : 'width: 100%';
    },
    ldPrimary() {
      return '#3878C2';
    },
  },
  methods: {
    ...mapActions({
      find: 'listing/find',
      select: 'listing/select',
      selectAll: 'listing/selectAll',
      deselectAll: 'listing/deselectAll',
      selectSort: 'listing/selectSort',
      selectOrder: 'listing/selectOrder',
    }),
    onChange(event) {
      this.currentPage = event;
      let start = (this.currentPage - 1) * this.perPage;
      if (start === 0) start = -1;
      this.listingSliceMin = start + 1;
      this.listingSliceMax = start + this.perPage + 1;
      const elem = document.getElementById('listings');
      if (elem) {
        elem.scrollTo(0, 0);
      }
    },
    itemClicked(item) {
      this.onItemClicked(item, this.currentPage);
    },
    isSelected(listing) {
      return this.selected.includes(listing);
    },
    handleSelectAll() {
      if (this.selected.length === this.allListings.length) {
        this.deselectAll();
      } else {
        this.selectAll();
      }
      this.listingsKey = this.genId();
    },
    genId() {
      return (
        `_${
          Math.random()
            .toString(36)
            .substr(2, 9)}`
      );
    },
    handleSelectSort(value) {
      this.currentPage = 1;
      this.selectSort(value);
    },
  },
  created() {
    if (this.allListings.length === 0 || this.mlsNumberSelected) {
      const { mlsNumberSelected } = this;
      this.find({ mlsNumberSelected });
    }
    if (this.page !== 0) {
      this.onChange(this.page);
    }
  },
  data() {
    return {
      currentPage: this.page,
      perPage: this.itemsPerPage,
      listingSliceMin: 0,
      listingSliceMax: this.itemsPerPage,
      grid: true,
      listingsKey: this.genId(),
      sortBy: [
        {
          name: 'Recently Updated', sort: 'asc', optionIcon: 'arrow_upward', key: 'mod-asc',
        },
        {
          name: 'Recently Updated', sort: 'desc', optionIcon: 'arrow_downward', key: 'mod-desc',
        },
        {
          name: 'Price: Low to High', sort: 'asc', optionIcon: 'arrow_upward', key: 'price-asc',
        },
        {
          name: 'Price: High to Low', sort: 'desc', optionIcon: 'arrow_downward', key: 'price-desc',
        },
        {
          name: 'DOM: Low to High', sort: 'asc', optionIcon: 'arrow_upward', key: 'dom-asc',
        },
        {
          name: 'DOM: High to Low', sort: 'desc', optionIcon: 'arrow_downward', key: 'dom-desc',
        },
      ],
    };
  },
  mounted() {
    this.deselectAll();
  },
};
</script>

<style lang="scss">
#listings {
  width: 100%;
  height: 781px;
  padding-top: 0px;
  overflow: scroll !important;
  .listing-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: -10px;
    &__type {
      display: flex;
      .v-btn {
        z-index: 1;
        margin: 15px 0px;
        i {
          z-index: -1;
        }
      }
    }
    &__count {
      margin: auto 0;
      color: var(--v-secondary-lighten1);
      font-family: "Nunito Sans";
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
    }
    .v-text-field {
      padding-top: 0px;
      margin-top: 0px;
      max-width: 121px;
    }
    .v-input__slot {
      margin-bottom: 0px;
    }
    &__sort-by {
      margin: auto 0;
      color: #000000;
      font-family: "Nunito Sans";
      font-size: 20px;
      font-weight: 600;
      line-height: 27px;
      padding: 10px;
      padding-left: 0;
      .v-text-field {
        max-width: 195px;
      }
    }
    &__order-by {
      margin: auto 0;
      color: #000000;
      font-family: "Nunito Sans";
      font-size: 20px;
      font-weight: 600;
      line-height: 27px;
      padding: 10px;
      .v-text-field {
        max-width: 121px;
      }
    }
  }
  .select-listings {
    margin: 15px 8px;
  }
  .listings-display {
    margin: auto !important;
    margin-right: 4px;
  }
}

@media all and (max-width: 1410px) {
  .listings-display {
    .listings-flex-item.grid {
      padding-right: 6px;
      .listing-card-grid {
        width: 100vw;
      }
    }
  }
}
</style>
