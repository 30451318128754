<template>
  <v-card class="search-filters">
    <filter-name-modal
      v-model="filterName"
      :show-dialog="toggleFilterNameModal"
      @filterNameCancel="handleFilterNameCancel"
      @filterNameSave="saveFilter"
    />
    <v-dialog v-model="deleteFilterDialog" width="500">
      <v-card>
        <v-card-title class="title dark primary white--text">Delete Filter</v-card-title>
        <v-card-text>Are you sure you want to delete this filter?</v-card-text>
        <v-card-actions>
          <v-layout pa-0 row justify-end>
            <div>
              <v-btn color="primary" flat @click="deleteFilterDialog = false">
                Cancel
              </v-btn>
              <v-btn color="error" flat @click="deleteFilter">
                Delete
              </v-btn>
            </div>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showComingSoonModal" width="500">
      <v-card>
        <v-card-title class="card_title_icon">Coming soon</v-card-title>
        <v-card-text>Stay tuned for Visual Market Insights to:</v-card-text>
        <div class="card_title_description">
          <v-icon>check_circle</v-icon>
          <span> Save your own listings for faster searching</span>
        </div>
        <v-card-actions>
          <v-layout pa-0 row justify-end>
            <div>
              <v-btn flat @click="handleComingSoonModal">
                Cancel
              </v-btn>
              <v-btn color="primary" @click="handleComingSoonModal">
                Ok
              </v-btn>
            </div>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-text>
      <v-list>
        <v-list-tile>
          <v-select
            :items="savedFilters"
            item-text="name"
            label="Saved Filters"
            :value="selectedFilter"
            @change="changeSelect"
            return-object
            prepend-icon="filter_list"
          >
            <v-icon
              slot="append"
              @click.stop="toggleFilterNameModal = !toggleFilterNameModal"
            >edit</v-icon>
          </v-select>
        </v-list-tile>
        <slot name="staticFilters"/>
        <v-list-tile
          v-for="(condition, index) in activeConditions"
          :key="generateKey(index, condition)"
        >
          <condition
            :index="index"
            :available-conditions="availableFilterFields"
            :condition="condition"
            @input="updateCondition"
            @delete="removeItem"
          ></condition>
        </v-list-tile>
        <!-- TODO: currently broken. disable (nov-2019) -->
        <!-- <v-list-tile>
          <v-btn class="add-condition" color="primary" flat="flat" @click="addCondition">
            <v-icon>add</v-icon>Add criteria
          </v-btn>
        </v-list-tile> -->
        <v-list-tile>
          <div>
            <span class="filter-note">The first 5 saved filters will be displayed on dashboard by default</span>
          </div>
        </v-list-tile>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-flex xs10>
        <v-btn flat="flat" :disabled="shouldDisableButtons" color="primary" @click="saveFilter">
          <v-icon>save</v-icon>Save filter
        </v-btn>
        <v-btn flat="flat" :disabled="shouldDisableButtons" color="primary" @click="applyFilter">
          <v-icon>save_alt</v-icon>Apply filter
        </v-btn>
        <v-btn flat="flat" :disabled="shouldDisableButtons" color="primary" @click="resetFilter">
          <v-icon>restore</v-icon>Reset filter
        </v-btn>
      </v-flex>
      <v-flex xs2>
        <v-btn color="red" flat="flat" :disabled="shouldDisableButtons" @click="deleteFilterDialog = true">
          <v-icon color="red">delete</v-icon>Delete filter
        </v-btn>
      </v-flex>
      <v-progress-circular
        v-if="areFiltersSaving"
        :size="20"
        :width="2"
        color="primary"
        indeterminate="indeterminate"
      ></v-progress-circular>
    </v-card-actions>
  </v-card>
</template>

<script>
import groupBy from 'lodash/groupBy';
import Condition from './Condition';
import FilterNameModal from './FilterNameModal';

const defaultFilterName = '';

export default {
  components: { Condition, FilterNameModal },

  props: {
    disableButtons: {
      type: Boolean,
      default: false,
    },
    /**
     * ** this.activeConditions **
     *
     * [this.value] == [v-model=selectedConditions] from parent
     *
     * Not sure why we don't make this a named prop.
     * At mount time, we rename this.value to this.activeConditions
     *
     * activeConditions are conditions currently listed in the interface.
     *
     * @type {Array}
     */
    value: {
      type: Array,
      default: () => [],
    },

    /**
     * All the conditions available for selection.
     * Consisting of objects of this form:
     * [{
     *    name: 'Hotness',
     *    field: 'hotness_id',
     *    type: 'foreignKey',
     *    icon: 'list',
     *  }, ...
     * ]
     *
     * @type {Array}
     */
    availableFilterFields: {
      type: Array,
      default: () => [],
    },

    /**
     * handler for save filter event
     *
     * @type {Function}
     */
    onSaveFilter: {
      type: Function,
      required: true,
    },
    /**
     * handler for save filter event
     *
     * @type {Function}
     */
    onApplyFilter: {
      type: Function,
      required: true,
    },
    initialSelectedFilter: {
      type: Object,
      default: () => {
        return {};
      },
    },
    /**
     * handler for save filter event
     *
     * @type {Function}
     */
    savedFilters: {
      type: Array,
      default: () => [],
    },
    onResetFilter: {
      type: Function,
      required: true,
    },
    onDeleteFilter: {
      type: Function,
      required: true,
    },
    showComingSoonModal: Boolean,
    handleComingSoonModal: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      /**
       * On load, don't show filter conditions.
       * To show:
       * - saved filter is selected
       * - New Filter button is clicked
       *
       * @type {Boolean}
       */
      filterName: defaultFilterName,
      activeConditions: [],
      areFiltersSaving: false,
      selectedFilter: this.initialSelectedFilter,
      shouldDisableButtons: this.disableButtons,
      toggleFilterNameModal: false,
      deleteFilterDialog: false,
    };
  },

  watch: {
    disableButtons(value) {
      this.shouldDisableButtons = value;
    },
    savedFilters(value) {
      this.changeSelect(this.selectedFilter);
    },
  },

  mounted() {
    if (Object.keys(this.selectedFilter).length) {
      this.populateFilterConditions();
    }

    // If the selected filter is empty, clear it
    this.$watch(
      'initialSelectedFilter',
      initialSelectedFilter => {
        if (!Object.keys(initialSelectedFilter).length) {
          this.clearFilters();
        }
        if (Object.keys(this.initialSelectedFilter).length && initialSelectedFilter.conditions) {
          this.changeSelect(initialSelectedFilter);
        }
      },
      { deep: true },
    );
  },

  methods: {
    /**
     * Add the first of the available conditions
     */
    addCondition() {
      this.activeConditions.push(Object.assign({}, this.availableFilterFields[0]));
    },
    updateCondition(index, value) {
      // For reactivity, assign this object back to itself.
      this.activeConditions[index] = Object.assign({}, this.activeConditions[index], value);
    },
    /**
     * Generates a semi-unique key to use in the v-for.
     *
     * Triggers updates when any of the variables therein are updated.
     * Importantly, does not regenerate a key when the `value` is changed.
     *
     * If it had, that would re-render the value textfield/datepicker, causing
     * it to lose focus.
     *
     * TODO CS - the key should change when a new saved filter is chosen
     *  from the list, even if the index, key, and operator are the same
     *  (but the value is different).
     *
     *  To fix: get the saved filter index &/or filter name to hash with
     *   as well.
     *
     * @param  {number} index     index of array in list.
     * @param  {object} condition condition with full details
     * @return {string}           like `0-created_at-tomorrow`
     */
    generateKey(index, condition) {
      return `${index}-${condition.field}-${condition.operator}`;
    },

    groupFilterConditions() {
      const { staticConditions, activeConditions } = groupBy(
        this.selectedFilter.conditions,
        condition => {
          return condition.static ? 'staticConditions' : 'activeConditions';
        },
      );

      if (staticConditions) {
        this.$emit('filter-change', staticConditions);
      }
      // Active conditions is always null
      return activeConditions || [];
    },

    populateFilterConditions() {
      // populate the name field
      this.filterName = this.selectedFilter.name;
      this.activeConditions = this.groupFilterConditions();
    },

    /**
     * Clear the filters completely
     */
    clearFilters() {
      this.filterName = '';
      this.activeConditions = [];
    },

    /**
     * Save the current filter.
     *
     * Conditions are stripped of the query string & object for saving;
     * Not necessary for the database. Let the front-end handle
     * construction of the query. The back-end should be agnostic.
     *
     * Different service methods are called for create/update
     */
    async saveFilter() {
      if (!this.filterName) {
        this.toggleFilterNameModal = true;
      } else {
        this.toggleFilterNameModal = false;
        this.areFiltersSaving = true;
        this.selectedFilter.name = this.filterName;
        await this.onSaveFilter(this.filterName, this.activeConditions, this.selectedFilter.id);
        this.areFiltersSaving = false;
      }
    },

    async applyFilter() {
      this.areFiltersSaving = true;
      await this.onApplyFilter(this.filterName, this.activeConditions);
      this.areFiltersSaving = false;
    },

    resetFilter() {
      this.selectedFilter = { name: defaultFilterName };
      this.onResetFilter();
      this.clearFilters();
    },

    async deleteFilter() {
      this.deleteFilterDialog = false
      await this.onDeleteFilter(this.selectedFilter);
      this.selectedFilter = { name: defaultFilterName };
      this.clearFilters();
    },

    changeSelect(selectedFilter) {
      if (!selectedFilter) return;
      this.selectedFilter = selectedFilter;
      this.populateFilterConditions();
    },

    removeItem(index) {
      this.activeConditions.splice(index, 1);
    },

    handleFilterNameCancel() {
      this.toggleFilterNameModal = false;
    },
  },
};
</script>

<style lang="scss" type="text/scss">
.search-filters {
  box-shadow: none !important;

  .v-list__tile {
    padding: 0 !important;
  }

  .add-condition {
    margin-left: 0px;
    padding-left: 0px;
  }

  .filters-loading {
    margin-bottom: 0;
    height: 7px;
  }

  .save-errors {
    color: red;
    padding-left: 10px;
  }

  .filter-note {
    padding-left: 4px;
    color: gray;
    font-size: 14px;
  }

  .filter-item > .v-list__tile__content {
    width: 660px;
  }

  .v-card__actions {
    .v-progress-circular {
      margin-left: 20px;
    }
  }

  .filter-item {
    flex: 1 1;
    width: 100%;
    height: 100%;
    display: flex;

    & > .v-list__tile__action {
      display: flex;
      flex: 0 0 32px !important;
      justify-content: center;
      align-items: center;
      height: auto !important;
    }

    & > .v-list__tile__content {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-start;

      & > div {
        flex: 1 0;
        white-space: nowrap;

        p {
          margin: 0;
          padding: 0;
        }

        &.filter__condition {
          flex: 0 0 75px;
          width: 75px;
          max-width: 75px;
          text-align: left;
          padding-right: 2em;
        }
        &.filter__type {
          flex: 0 0 200px;
          flex-wrap: nowrap;
          min-width: 200px;
          max-width: 200px;
        }
        &.filter__value {
          flex: 1 0 30%;
          padding: 0 1em;
        }

        .v-input,
        .v-input__slot {
          margin: 0;
          padding: 0;
          flex: 1 0;
        }

        .v-text-field__details {
          display: none;
        }

        .v-chip__content {
          height: auto;
        }
      }
    }
  }
  .v-select__selections {
    align-items: center;
    display: flex;
    flex: 0 0 80%;
    flex-wrap: nowrap;
    line-height: 18px;
    overflow: hidden;

    & > span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      & > i {
        margin-right: 0.5em;
      }
    }
  }
  .v-select-list {
    .v-list__tile {
      padding: 0 1em !important;

      & > i {
        margin-right: 0.5em;
      }
    }
  }
  .title {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    min-height: 60px;
    width: 100%;
    text-transform: uppercase;
    padding-right: 12px;
  }
}

.card_title_icon {
  background: var(--v-primary-base);
  color: white;
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
  padding: 16px 24px;
  &:before {
    font-family: 'Material Icons';
    font-weight: normal;
    font-size: 22px;
    text-transform: none;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    content: "star";
    opacity: 0.5;
    margin: 0 8px 0 0;
  }
}

.card_title_description {
  display: inline-block;
  padding: 0px 40px 40px 40px;

  & > i {
    font-size: 18px;
  }
}

</style>
