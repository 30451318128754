<!--
@Author: Kleber Pinel <kleberdasilva>
@Date:   2019-03-19T14:06:36-07:00
@Email:  kleber@hackcapital.com
@Last modified by:   kleberdasilva
@Last modified time: 2019-03-25T14:51:07-07:00
-->

<template lang="pug">
  section(v-if="!disabled")
    content-picker(
      :visible="contentPickerVisible"
      @close="resetToolbarElements"
      @insert="$emit('insert-ld-content', $event)"
    )

    insert-url(
      :visible="insertImageVisible"
      @close="resetToolbarElements"
      @insert="$emit('insert-url-image', $event)"
      title="Insert Public Image URL"
    )

    insert-url(
      :visible="insertLinkVisible"
      @close="resetToolbarElements"
      @insert="insertLinkUrl"
      title="Insert Link URL"
    )

    edit-html(
      :visible="editHTMLVisible"
      @close="resetToolbarElements"
      @insert="$emit('edit-html', $event)"
      :originalSource="textValue"
    )

    v-toolbar.editor-tools(dense flat)
      div(v-for="tool in tools" :key="tool.name")
        emoji-picker(
          @emoji="$emit('insert-emoji', $event)"
          v-if="tool.name === 'Emoji'"
        )
          v-tooltip(bottom)
            span {{ tool.hint }}
            v-btn(flat slot="activator")
              v-icon {{ tool.icon }}
        v-tooltip(bottom v-else)
          span {{ tool.hint }}
          v-btn(
            flat
            slot="activator"
            :class="{ active: tool.isActive }"
            @click='onCommand(tool.command, tool.commandArgument, tool.customCommand)'
          )
            v-icon(v-if="tool.icon")  {{ tool.icon }}
            span(v-else)  {{ tool.label }}
        .divider(v-if="tool.isSectionEnd")
      slot(name="custom")
</template>

<script>
import ContentPicker from './ContentPicker';
import InsertUrl from './InsertUrl';
import EditHtml from './EditHtml';
import EmojiPicker from './EmojiPicker';

export default {
  components: {
    ContentPicker,
    InsertUrl,
    EditHtml,
    EmojiPicker,
  },
  props: {
    textValue: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    toggleToolbarItemVisible: {
      type: Boolean,
      default: true,
    },
    tools: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      linkText: '',
      imageURL: '',
      sourceEditing: this.textValue,
      showSource: false,
      contentPickerVisible: false,
      insertImageVisible: false,
      insertLinkVisible: false,
      editHTMLVisible: false,
      emojiPickerVisible: false,
    };
  },
  watch: {
    // If this values change, we need to close the opened component
    toggleToolbarItemVisible(newValue) {
      this.resetToolbarElements();
    },
  },
  methods: {
    onCommand(command, textValue, customCommand) {
      switch (command) {
        case 'source':
          this.editHTMLVisible = !this.editHTMLVisible;
          return;
        case 'ImageUrl':
          this.insertImageVisible = !this.insertImageVisible;
          return;
        case 'ldContent':
          this.contentPickerVisible = !this.contentPickerVisible;
          return;
        case 'emoji':
          this.emojiPickerVisible = !this.emojiPickerVisible;
          return;
        case 'createLink': {
          const selRange = window.getSelection();
          const selText = selRange.toString();
          this.linkText = selText;
          this.insertLinkVisible = !this.insertLinkVisible;
          return;
        }
        case 'attachment':
          // TODO AK: refactor this using component $refs once to allow
          // for multiple instances of AttachmentHandler and better component separation
          document.querySelector('#attachmentUpload').click();
          return;
        default:
          this.$emit('exec-command', command, textValue);
      }
    },

    insertLinkUrl(linkValue) {
      this.$emit('insert-link-url', this.linkText, linkValue);
    },

    resetToolbarElements() {
      this.linkText = '';
      this.imageURL = '';
      this.contentPickerVisible = false;
      this.insertImageVisible = false;
      this.insertLinkVisible = false;
      this.editHTMLVisible = false;
      this.emojiPickerVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.editor-tools /deep/ .v-toolbar__content {
  padding: 4px;
  flex-wrap: wrap;
  height: initial !important;
}
.editor-tools {
  .v-btn {
    padding: 0px 4px;
    height: 30px;
    min-width: 0px;
    margin: 2px;
    opacity: 0.5;
    border-radius: 2px;
    text-transform: initial;
    &.active {
      opacity: 1;
      &:before {
        background-color: currentColor;
      }
    }
  }
  .divider {
    height: 20px;
    width: 1px;
    border-left: solid 1px #ccc;
    margin: 0 2px;
    display: inline;
  }
}
</style>
