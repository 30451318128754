<!--
  @Author: Ethan Dinnen
  @Date:   2018-11-30 11:36:30
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-02-26 14:45:41
-->

<template>
  <v-text-field
    :label="title"
    :placeholder="placeholder"
    v-model="value"
    :rules="rules"
    @blur="handleInput();"
    @input="handleInput();"
    @focus="selectOnClick ? selectText($event) : null;"
    :required="required"
    :disabled="disabled"
    :prepend-icon="prependIcon"
    :error-messages="errors"
    :maxlength="type === inputTypes.PERCENTAGE ? 6 : null"
  />
</template>

<script>
import formatCurrency, { sanitize } from '@/Core/mixins/formatCurrency';
import transactionInputTypes from '@/Core/constants/transactionInputTypes';

export default {
  props: {
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    title: String,
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    position: {
      type: Number,
      required: true,
    },
    default: {
      type: [String, Number],
      required: false,
      default: null,
    },
    onInput: {
      type: Function,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    prependIcon: {
      type: String,
      required: false,
    },
    errors: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectOnClick: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [formatCurrency],
  data() {
    return {
      value: this.default,
    };
  },
  computed: {
    inputTypes() {
      return transactionInputTypes;
    },
  },
  methods: {
    selectText(evt) {
      evt.srcElement.select();
    },
    handleInput() {
      const { position, title, type } = this;

      // Remove cents from value
      const raw = sanitize(this.value + '');
      switch (type) {
        case transactionInputTypes.CURRENCY:
          // Format as a currency, if the field is not blank
          this.value = this.value ? this.formatCurrency(raw) : '';
          this.onInput({ value: raw, formatted: this.value, position, title });
          return;
        case transactionInputTypes.PERCENTAGE:
          if (!this.value) return;
          this.value = raw ? Math.min(parseFloat(raw), 100) + '' : '';
          this.onInput({
            value: this.value,
            formatted: this.value + '%',
            position,
            title,
          });
          this.value = this.value + '%';
          return;
        default:
          this.onInput({
            value: this.value,
            formatted: this.value,
            position,
            title,
          });
      }
    },
  },
};
</script>
