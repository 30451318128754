const LdFeatures = Object.freeze({
  SharedCampaigns: 'shared_campaigns',
  VideoTexts: 'video_texts',
  Emails: 'emails',
  TextMessaging: 'individual_texts',
  BulkTexts: 'bulk_texts',
  BulkCampaigns: 'bulk_campaigns',
  Campaigns: 'campaigns',
  Text2Sell: 'text_2_sell',
  CustomNumber: 'custom_number',
  TransactionPipelines: 'transaction_pipelines',
  LeadAssist: 'lead_assist',
  ApplicationVmi: 'vmi_app',
  ApplicationTransactions: 'transactions_app',
});

export default LdFeatures;
