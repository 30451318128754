/*
 * @Author: Ethan Dinnen
 * @Date:   2019-02-01 10:56:25
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-02-20 13:06:22
 */
import { genId } from '@/Core/mixins/genId';

export default {
  position: 3,
  name: 'Financials',
  tmp_id: genId(),
  mandatory: false,
  template_fields: [
    {
      label: 'Purchase/Sale Price',
      type: 'currency',
      position: 1,
      field_source: '_source.ListPrice',
      tmp_id: genId(),
    },
    {
      label: 'Commission Percentage',
      type: 'percentage',
      position: 2,
      field_source: null,
      tmp_id: genId(),
    },
    {
      label: 'Add Fixed Commission',
      type: 'currency',
      position: 3,
      field_source: null,
      tmp_id: genId(),
    },
    {
      label: 'Sale Commission Total',
      type: 'currency',
      position: 4,
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Market Price',
      type: 'currency',
      position: 5,
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Offer Price',
      type: 'currency',
      position: 6,
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Title fee',
      type: 'currency',
      position: 7,
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Escrow fee',
      type: 'currency',
      position: 8,
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Sale Commission Split $ - Buy Side',
      type: 'currency',
      position: 9,
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Recording fee',
      type: 'currency',
      position: 10,
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Sale Commission Split $ - Sell Side',
      type: 'currency',
      position: 11,
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Fixed closing costs',
      type: 'currency',
      position: 12,
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Notary fee',
      type: 'currency',
      position: 13,
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Sale Commission Rate',
      type: 'currency',
      position: 14,
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Discretionary costs',
      type: 'currency',
      position: 15,
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Earnest Money Amount',
      type: 'currency',
      position: 16,
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Sale Commission Split % - Buy Side',
      type: 'currency',
      position: 17,
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Total cash proceeds',
      type: 'currency',
      position: 18,
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Earnest Money Held By',
      type: 'currency',
      position: 19,
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Sale Commission Split % - Sell Side',
      type: 'currency',
      position: 20,
      field_source: '',
      tmp_id: genId(),
    },
  ],
};
