/**
 * @author: Brett Campbell (brett@hackcapital.com)
 * @date: Thursday, 21st February 2019 11:13:34 am
 * @lastModifiedBy: Brett Campbell (brett@hackcapital.com)
 * @lastModifiedTime: Thursday, 21st February 2019 6:45:56 pm
 *
 * DESCRIPTION
 *
 * @copyright (c) 2019 HackCapital
 */

export const Operators = {
  WITHIN_LAST_X_DAYS: 'within the last x days',
  YESTERDAY: 'yesterday',
  TODAY: 'today',
  TOMORROW: 'tomorrow',
  WITHIN_NEXT_X_DAYS: 'within the next x days',
  OCCURRED_BEFORE: 'occurred before',
  OCCURRED_ON: 'occurred on',
  OCCURRED_AFTER: 'occurred after',
  NEVER_OCCURRED: 'never occurred',
};
