<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 85 78" style="enable-background:new 0 0 85 78;" xml:space="preserve">
    <title>Group 8</title>
    <desc>Created with Sketch.</desc>
    <path id="Fill-4" class="st0" d="M80.6,4.5v68.7l-75.7,0V4.5H80.6z M81.2,0.7h-77c-1.8,0-3.3,1.5-3.3,3.2v70c0,1.8,1.5,3.2,3.3,3.2
    h77c1.8,0,3.3-1.5,3.3-3.2v-70C84.5,2.1,83,0.7,81.2,0.7L81.2,0.7z"/>
    <path class="st0" d="M44.5,61.2H40c-0.5,0-0.9-0.4-0.9-0.9V18.1c0-0.5,0.4-0.9,0.9-0.9h4.5c0.5,0,0.9,0.4,0.9,0.9v42.2
    C45.4,60.8,45,61.2,44.5,61.2z"/>
    <path class="st0" d="M27,61.2h-4.5c-0.5,0-0.9-0.4-0.9-0.9V31.7c0-0.5,0.4-0.9,0.9-0.9H27c0.5,0,0.9,0.4,0.9,0.9v28.6
    C27.9,60.8,27.5,61.2,27,61.2z"/>
    <path class="st0" d="M62,61.2h-4.5c-0.5,0-0.9-0.4-0.9-0.9V44.9c0-0.5,0.4-0.9,0.9-0.9H62c0.5,0,0.9,0.4,0.9,0.9v15.5
    C62.9,60.8,62.5,61.2,62,61.2z"/>
  </svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '$vuetify.icons.ad-stats',
    },
  },
};
</script>

<style type="text/css">
  .st0{fill:#3C6697;}
</style>