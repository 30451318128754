<!--
  @Author: Ethan Dinnen
  @Date:   2018-12-11 15:15:05
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-02-15 14:43:35
-->
<template>
  <ld-app-layout class="transactions-container" title="Transactions" activeApp="transactions">
    <ld-change-profile-picture-modal />
    <v-flex class="transactions-layout">
      <v-container class="py-0 elevation-1 white fixed-nav" fluid>
        <v-tabs slider-color="primary">
          <v-tab v-for="item in items" :key="item.route" :to="item.route">
            {{ item.text }}
          </v-tab>
          <v-spacer class="grow" />
          <ld-how-it-works feature="transactions" />
        </v-tabs>
      </v-container>
      <ld-gated-content :feature="LdFeatures.ApplicationTransactions">
        <slot></slot>
      </ld-gated-content>
    </v-flex>
    <snack-queue :items="snacks" @input="clearLastSnack"/>
  </ld-app-layout>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import SnackQueue from '@/Core/components/SnackQueue';
import {
  LdAppLayout,
  LdFeatures,
  LdGatedContent,
  LdHowItWorks,
  LdChangeProfilePictureModal,
} from 'lion-vue';

const {
  mapState,
  mapActions,
} = createNamespacedHelpers('transactions');

export default {
  name: 'TransactionsLayout',
  components: {
    LdAppLayout,
    LdGatedContent,
    LdHowItWorks,
    LdChangeProfilePictureModal,
    SnackQueue,
  },
  data() {
    return {
      LdFeatures,
      items: [
        { route: '/', text: 'Dashboard' },
        { route: '/pipelines', text: 'Pipelines' },
        { route: '/plans', text: 'Plans' },
      ],
    };
  },
  methods: {
    ...mapActions([
      'clearLastSnack',
      'queueSnack',
    ]),
  },
  computed: {
    ...mapState([
      'snacks',
    ]),
  },
};
</script>

<style>
.transactions-layout {
  width: 100%;
}
.fixed-nav {
  position: relative;
}
.transactions-layout .upgrade-banner {
  margin: 64px 12px;
}
</style>
