import axios from 'axios';

export const findTeamMembers = async id => {
  return await axios.get(`${process.env.CRM_API}/team-members?request_status=Accepted&$limit=50`)
    .then(res => {
      const teammMembers = res.data.data.map(member => {
        return {
          value: member.user.id,
          text: `${member.user.first_name} ${member.user.last_name}`
        }
      })
      return teammMembers;
  });
};
