<!--
  @Author: Ethan Dinnen
  @Date:   2018-12-11 15:29:27
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-02-26 14:45:01
-->

<template>
  <TransactionsLayout>
    <v-container dark fluid grid-list-xl class="ld-tx-container">
      <TransactionTypesWidget />
      <title-bar class="tx-type-title"> Transactions Table </title-bar>
      <v-layout row>
        <v-flex class="xs4">
          <v-layout row class="tx-info">
            <router-link :to="'/create'">
              <v-btn color="primary" class="new-button">
                New Transaction
              </v-btn>
            </router-link>
            <!--
              <v-overflow-btn
                color="primary"
                class="new-button"
                :items="newOptions"
                label="NEW"
                v-model="selectedNewOption"
                @change="handleRouteNew"
              />
            -->
            <!--
              <v-select
                :items="rowsPerPageItems"
                v-model="pagination.rowsPerPage"
                label="Items per page"
                @input="handleLimitChange"
                class="item-limit"
              ></v-select>
            -->
            <span class="transaction-total" v-if="items.length > 0">
              All {{ pagination.totalItems }} Transactions
            </span>
          </v-layout>
        </v-flex>
        <v-flex class="xs2 offset-xs6">
          <v-text-field
            solo
            hide-details
            prepend-inner-icon="search"
            v-model="search"
            label="Search"
            @input="handleSearch"
            class="transaction-search"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <TransactionsTable
        :headers="headers"
        :items="items"
        :isLoading="isLoading"
        :pagination="pagination"
        :handlePagination="handlePagination"
        :rowsPerPageItems="rowsPerPageItems"
        :confirmDelete="handleConfirmDelete"
        :handleDelete="deleteTransaction"
        :transactions="transactions"
        :handleRefresh="handleRefresh"
      />
    </v-container>
    <LdConfirmation
      :value="dialog"
      width="500"
      risk="high"
      @click:cancel="dialog = false;"
      @click:ok="this.handleDeleteTransaction"
      confirmText="Delete transaction"
    >
      <template slot="header">
        Delete Transaction
      </template>
      <template slot="body">
        Are you sure you want to permanently delete this transaction?
      </template>
    </LdConfirmation>
  </TransactionsLayout>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { LdConfirmation } from 'lion-vue';
import TransactionsLayout from '@/Core/layouts/TransactionsLayout';
import TransactionsTable from './components/TransactionsTable';
import TransactionTypesWidget from './TransactionTypesWidget';
import TitleBar from '@/Core/components/TitleBar';

const {
  mapActions: mapTransactionsActions,
  mapState: mapTransactionsState,
} = createNamespacedHelpers('transactions');

export default {
  components: {
    TransactionsLayout,
    TransactionsTable,
    TransactionTypesWidget,
    LdConfirmation,
    TitleBar,
  },
  data() {
    return {
      dialog: false,
      expanded: [],
      search: '',
      headers: [
        {
          text: 'Title',
          value: 'transactions.title',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Address',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Type',
          value: 'transaction_types.name',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Stage',
          value: 'stages.title',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Sale Value',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Commission Value',
          align: 'left',
          sortable: false,
        },
      ],
      isLoading: false,
      rowsPerPageItems: [5, 10, 15, 25, 50, 100],
      pagination: {
        page: 1,
        rowsPerPage: 10,
        totalItems: 0,
        sortBy: 'transactions.created_at',
        descending: true,
      },
      items: [],
      selectedNewOption: null,
      newOptions: [
        { text: 'Transaction', value: 'transaction' },
        { text: 'Transaction Type', value: 'type' },
      ],
    };
  },
  computed: {
    ...mapTransactionsState(['transactions']),
  },
  methods: {
    ...mapTransactionsActions([
      'reset',
      'getTransactions',
      'deleteTransaction',
    ]),
    handleConfirmDelete(id) {
      this.dialog = true;
      this.transactionToDelete = id;
    },
    async handleRefresh() {
      await this.handlePagination(this.pagination);
    },
    async handleDeleteTransaction() {
      if (!this.transactionToDelete) return;
      await this.deleteTransaction(this.transactionToDelete);
      this.transactionToDelete = null;
      await this.handleRefresh();
      this.dialog = false;
    },
    async handlePagination(data) {
      const { page, rowsPerPage, sortBy, descending } = data;
      const query = this.search;
      this.isLoading = true;
      const direction = descending ? 'desc' : 'asc';
      await this.getTransactions({
        rowsPerPage,
        page,
        sortBy,
        direction,
        query,
        filter: this.$route.params.filter,
        type: this.$route.params.type_id,
      });
      this.isLoading = false;
      this.pagination = data;
      this.pagination.totalItems = this.transactions.total;
      this.items = this.transactions.data;
    },
    expand(data) {
      data.expanded = !data.expanded;
    },
    async handleSearch(query) {
      this.isLoading = true;
      this.pagination.page = 1;
      const { page, rowsPerPage, sortBy, descending } = this.pagination;
      const direction = descending ? 'desc' : 'asc';
      await this.getTransactions({
        rowsPerPage,
        page,
        sortBy,
        direction,
        query,
        filter: this.$route.params.filter,
        type: this.$route.params.type_id,
      });
      this.isLoading = false;
      this.pagination.totalItems = this.transactions.total;
      this.items = this.transactions.data;
    },
    handleRouteNew(selected) {
      this.selectedNewOption = null;
      const selectedRoute = { transaction: '/create', type: '/create/type' }[
        selected
      ];
      if (!selectedRoute) return;
      this.$router.push(selectedRoute);
    },
  },
  beforeMount() {
    this.reset();
  },
  watch: {
    async $route(to, from) {
      if (
        (to.path === '/' || to.path.includes('/view/')) &&
        (from.path === '/' || from.path.includes('/view/'))
      ) {
        this.handleRefresh();
      }
    },
  },
};
</script>

<style lang="scss">
.v-pagination__navigation,
.v-pagination__item {
  outline: none;
  border: 0;
  box-shadow: none;
}
.item-limit {
  padding-left: 5px;
  height: 52px;
  flex: 0 1 auto;
}
.item-limit .v-input__slot {
  width: 110px;
}
.transaction-total {
  margin: auto 10px;
  size: 14px;
  color: var(--v-lion-grey-base);
}
.transactions__new-button {
  text-decoration: none;
}
.tx-info {
  margin: auto 0 !important;
  .new-button {
    flex: unset;
    height: 36px;
    margin: auto 0;
    padding-top: 0;
    label {
      color: white !important;
      margin-left: 10px;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }
    .v-input__slot {
      border: 2px solid var(--v-primary-base) !important;
      border-radius: 2px;
      min-height: 36px;
      min-width: 90px;
      box-shadow: 0px 1.5px 1px rgba(0, 0, 0, 0.3);
    }
    .v-select__selection {
      color: white;
      font-size: 14px;
      text-transform: uppercase;
    }
    .v-icon {
      color: white !important;
    }
    .v-input__control {
      height: 36px;
      &::before {
        display: none;
      }
    }
    .v-input__append-inner {
      margin: unset !important;
      width: 25px;
    }
    .v-select__slot {
      height: 36px;
      background-color: var(--v-primary-base);
      input {
        margin-left: unset;
      }
    }
  }
}
</style>
