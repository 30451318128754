import LdPlans from '../../../../../config/plans';

export default () => ({
  // setting this to one of LdFeature values will trigger modal
  featureGate: null,
  // unique key of component requesting metered usage confirmation
  featureMeteredUsageKey: null,
  // unique key of component the user granted metered usage to
  featureMeteredUsageAcceptedKey: null,
  // all activated features for current user
  features: {},
  // subscription plan for current user
  plan: LdPlans.Trial,
  // true if current user has payment method on file
  // and can therefore use metered features
  paymentConfigured: false,
  // true if current user verified their email address
  emailVerified: false,
  // last add-on purchase result - { error: true|false, message: text }
  addonPurchaseResult: null,
  // use to determine access for leadAssist feature.
  hasCustomNumber: false,
  addonPurchaseRequested: false,
  banners: [],
  featureSubType: null,
});
