/*
 * @Author: Ethan Dinnen
 * @Date:   2018-11-30 11:03:26
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-01-04 15:24:26
 */

const stageName = state => id => {
  return state.stages.find(stage => stage.id === id).title;
};

const typeName = state => id => {
  return state.transactionTypes.find(type => type.id === id).name;
};

const allContacts = state => {
  return [...state.selectedContacts, ...state.newContacts];
};

const searchPlanItems = state => {
  const { plansSearchResults } = state;
  let plansSearchItems = [];

  if (plansSearchResults.length) {
    plansSearchItems = [
      ...plansSearchResults,
    ];
  } else {
    plansSearchItems = [
      { header: 'No plans were found' },
    ];
  }
  return plansSearchItems;
};

export default {
  stageName,
  typeName,
  allContacts,
  searchPlanItems,
};
