/*
 * @Author: Kleber Pinel Bernardo da Silva
 * @Date:   2019-01-25 16:06:34
 * @Last Modified by:   Kleber Pinel Bernardo da Silva
 * @Last Modified time: 2019-01-25 16:07:43
 */

export default {
  data() {
    return {
      showSlideOut: false,
    };
  },
  methods: {
    toggleSlideOut(newValue) {
      this.showSlideOut = !!newValue;
    },
  },
};
