<template>
  <v-layout column>
    <TitleBar class="title-bar">Plans Executed</TitleBar>
    <TxDataTable
      :headers="headers"
      :isLoading="false"
      :items="items"
      :pagination="pagination"
      :handlePagination="handlePagination"
    />
  </v-layout>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import TitleBar from '@/Core/components/TitleBar';
import TxDataTable from './TxDataTable';
import { ROWS_PER_PAGE } from '@/Core/config/constants';

const { mapState: mapViewState } = createNamespacedHelpers('viewTransaction');

export default {
  components: {
    TitleBar,
    TxDataTable,
  },
  props: {
    plans: {
      type: Array,
    },
    executedPlans: {
      type: Array,
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Plan',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Status',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Terminated by Stage',
          align: 'left',
          sortable: false,
        },
      ],
      pagination: {
        page: 1,
        rowsPerPage: 5,
        totalItems: this.plans.length,
        descending: true,
      },
    };
  },
  computed: {
    ...mapViewState(['transaction', 'stages']),
    items() {
      if (!this.executedPlans) return [];
      const stage = this.stages.find(
        stage => stage.id === this.transaction.stage_id
      );
      
      const stageTitle = stage ? stage.title : '';
      const stageId = stage ? stage.id : null;

      return this.executedPlans
        .map(plan => {
          const lastStage = this.stages.find(stage => stage.id === plan.terminated_by_stage);
          return {
            id: plan.plan_id,
            plan: {
              key: 'Plan',
              text: plan.plan_title,
            },
            status: {
              key: 'Status',
              text: plan.plan_id == this.transaction.meta.plan ? 'Active' : 'Inactive',
            },
            stage: {
              key: 'Stage',
              text: lastStage ? lastStage.title : '',
            },
          };
        })
        .slice(
          (this.pagination.page - 1) * this.pagination.rowsPerPage,
          this.pagination.page * this.pagination.rowsPerPage
        );
    },
  },
  methods: {
    async handlePagination(data) {
      this.pagination = data;
      this.pagination.totalItems = this.plans.length;
    },
  },
};
</script>
<style lang="scss" scoped>
.title-bar {
  padding-left: 28px;
}
</style>
