/*
 * @Author: Ethan Dinnen
 * @Date:   2019-02-20 12:59:19
 * @Last Modified by:   ethan
 * @Last Modified time: 2019-03-25 14:56:43
 */
import { genId } from '@/Core/mixins/genId';

export default {
  position: 2,
  name: 'Key Contract Dates',
  tmp_id: genId(),
  mandatory: false,
  template_fields: [
    {
      label: 'Start Date',
      position: 1,
      tmp_id: 'start-date',
      type: 'absolute',
      field_source: '',
      meta: { referenceId: 'start-date' },
    },
    {
      label: 'Closing Date',
      position: 2,
      tmp_id: 'closing-date',
      type: 'absolute',
      field_source: '',
      meta: { referenceId: 'closing-date' },
    },
    {
      label: 'Inspection Date',
      position: 3,
      tmp_id: 'inspection-date',
      type: 'absolute',
      field_source: '',
      meta: { referenceId: 'inspection-date' },
    },
    {
      label: 'Offer Date',
      position: 4,
      tmp_id: 'offer-date',
      type: 'absolute',
      field_source: '',
      meta: { referenceId: 'offer-date' },
    },
    {
      label: 'Offer Expiration Date',
      position: 5,
      tmp_id: 'offer-expiration-date',
      type: 'absolute',
      field_source: '',
      meta: { referenceId: 'offer-expiration-date' },
    },
    {
      label: 'Occupancy Date',
      position: 6,
      tmp_id: 'occupancy-date',
      type: 'absolute',
      field_source: '',
      meta: { referenceId: 'occupancy-date' },
    },
    {
      label: 'Contract Agreement Date',
      position: 7,
      tmp_id: 'contract-agreement-date',
      type: 'absolute',
      field_source: '',
      meta: { referenceId: 'contract-agreement-date' },
    },
  ],
};
