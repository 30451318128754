import LdFeatures from 'lion-vue-src/_globals/lib/LdFeatures';
import LdPlans from 'lion-vue-src/config/plans';

const content = Object.freeze({
  Default: {
    title: 'Upgrade to access this feature',
    description: `
      <p>Upgrading will give you access to this and many other features to help you connect, communicate and close with your clients.</p>
      <p><a href="UPGRADE_LINK">Learn more about our subscription options.</a></p>
    `,
    warning: false,
    shortMessage: 'This feature is not available in your current plan. Please <a href="UPGRADE_URL">upgrade</a> your plan.',
    modalBtnText: 'Upgrade',
    modalCTALink: '/settings?modal=subscription',
  },
  [LdFeatures.Emails]: {
    [LdPlans.Trial]: {
      title: 'Over email limit',
      description:
        `<p>You have exceeded your plan’s free email limit. Upgrade to a <strong>paid plan</strong>
        to access additional emails and other paid features, including:
          <ol>
            <li>Emails</li>
            <li>Texts</li>
            <li>Video Text</li>
          </ol>
        </p>
        `,
      warning: true,
    },
    [LdPlans.MLS]: {
      title: 'Over email limit',
      description:
        `<p>You have exceeded your plan’s free email limit. Upgrade to a <strong>paid plan</strong>
        to access additional emails and other paid features, including:
          <ol>
            <li>Emails</li>
            <li>Texts</li>
            <li>Video Text</li>
          </ol>
        </p>
        `,
      warning: true,
    },
  },
  [LdFeatures.TextMessaging]: {
    [LdPlans.Trial]: {
      title: 'Upgrade Your Subscription',
      description:
        `<div><strong>Unfortunately, your current LionDesk subscription does not support texting.</strong></div><br>
          <div>
          Unlock the power of texting by upgrading your subscription!<br>Visit the Payments & Subscriptions section to select your texting plan.
        </div>
        `,
      warning: true,
    },
    [LdPlans.MLS]: {
      title: 'Over text limit',
      description:
        `<p>You have exceeded your plan’s free text limit. Upgrade to a <strong>paid plan</strong>
        to access additional texts and other paid features, including:
          <ol>
            <li>Emails</li>
            <li>Texts</li>
            <li>Video Text</li>
          </ol>
        </p>
        `,
      warning: true,
    },
  },
  [LdFeatures.VideoTexts]: {
    [LdPlans.Trial]: {
      title: 'Over video text limit',
      description:
        `<p>You have exceeded your plan’s free video text limit. Upgrade to a <strong>paid plan</strong>
        to access additional video texts and other paid features, including:
          <ol>
            <li>Emails</li>
            <li>Texts</li>
            <li>Video Text</li>
          </ol>
        </p>
        `,
      warning: true,
    },
    [LdPlans.MLS]: {
      title: 'Over video text limit',
      description:
        `<p>You have exceeded your plan’s free video text limit. Add a <strong>payment method</strong>
          to access additional video texts and other paid features, including:
          <ol>
            <li>Emails</li>
            <li>Texts</li>
            <li>Video Text</li>
          </ol>
        </p>
        `,
      warning: true,
    },
  },
  [LdFeatures.BulkTexts]: {
    [LdPlans.Trial]: {
      title: 'Upgrade To Send Bulk Texts',
      description:
        `<p>Sending bulk texts is not available with your <strong>Free Trial Plan</strong>.
        Upgrade to a <strong>paid plan</strong> to get access to sending bulk texts and a range of additional features, including:
          <ol>
            <li>Bulk Emails</li>
            <li>Bulk Campaigns</li>
            <li>Bulk Texts</li>
          </ol>
        </p>
        `,
    },
    [LdPlans.MLS]: {
      title: 'Upgrade To Send Bulk Texts',
      description:
        `<p>Sending bulk texts is not available with your <strong>MLS Plan</strong>.
        Upgrade to a <strong>paid plan</strong> to get access to sending bulk texts and a range of additional features, including:
          <ol>
            <li>Bulk Emails</li>
            <li>Bulk Campaigns</li>
            <li>Bulk Texts</li>
          </ol>
        </p>
        `,
    },
  },
  [LdFeatures.BulkCampaigns]: {
    [LdPlans.Trial]: {
      title: 'Upgrade To Send Bulk Campaigns',
      description:
        `<p>Sending bulk campaigns is not available with your <strong>Free Trial Plan</strong>.
        Upgrade to a <strong>paid plan</strong> to get access to sending bulk campaigns and a range of additional features, including:
          <ol>
            <li>Bulk Emails</li>
            <li>Bulk Campaigns</li>
            <li>Bulk Texts</li>
          </ol>
        </p>
        `,
    },
    [LdPlans.MLS]: {
      title: 'Upgrade To Send Bulk Campaigns',
      description:
        `<p>Sending bulk campaigns is not available with your <strong>MLS Plan</strong>.
        Upgrade to a <strong>paid plan</strong> to get access to sending bulk campaigns and a range of additional features, including:
          <ol>
            <li>Bulk Emails</li>
            <li>Bulk Campaigns</li>
            <li>Bulk Texts</li>
          </ol>
        </p>
        `,
    },
    [LdPlans.Starter]: {
      title: 'Upgrade To Send Bulk Campaigns',
      description:
        `<p>Sending bulk campaigns is not available with your <strong>Starter Plan</strong>.
        Upgrade to a <strong>Pro+ Plan</strong> to get access to sending bulk campaigns and a range of additional features, including:
          <ol>
            <li>Bulk Emails</li>
            <li>Bulk Campaigns</li>
            <li>Bulk Texts</li>
          </ol>
        </p>
        `,
    },
  },
  [LdFeatures.TransactionPipelines]: {
    [LdPlans.Trial]: {
      title: 'Upgrade for Multiple Pipelines',
      description:
        `<p>Multiple transaction pipelines are not available with your <strong>Trial Plan</strong>.
        Upgrade to a <strong>Pro+ Plan</strong> to create additional custom pipelines.</p>
        `,
      shortMessage: 'This feature is not available in your current plan. Please <a href="UPGRADE_URL">upgrade</a> your plan.',
    },
    [LdPlans.Starter]: {
      title: 'Upgrade for Multiple Pipelines',
      description:
        `<p>Multiple transaction pipelines are not available with your <strong>Starter Plan</strong>.
        Upgrade to a <strong>Pro+ Plan</strong> to create additional custom pipelines.</p>
        `,
      shortMessage: 'This feature is not available in your current plan. Please <a href="UPGRADE_URL">upgrade</a> your plan.',
    },
  },
  [LdFeatures.ApplicationTransactions]: {
    [LdPlans.Trial]: {
      title: 'Keep track of all your transactions',
      description:
        `<p>Liondesk Transactions is not available with your <strong>Trial Plan</strong>.
        Upgrade to a <strong>paid plan</strong> to track your deals and your pipeline. Know how close you are to closing.
          <ol>
            <li>All your deals in one place</li>
            <li>Easily track your pipelines</li>
            <li>Custom transactions & pipelines</li>
            <li>Transaction campaigns</li>
          </ol>
        </p>
        `,
      shortMessage: 'This feature is not available in your current plan. Please <a href="UPGRADE_URL">upgrade</a> your plan.',
    },
    [LdPlans.MLS]: {
      title: 'Keep track of all your transactions',
      description:
        `<p>Liondesk Transactions is not available with your <strong>MLS Plan</strong>.
        Upgrade to a <strong>paid plan</strong> to track your deals and your pipeline. Know how close you are to closing.
          <ol>
            <li>All your deals in one place</li>
            <li>Easily track your pipelines</li>
            <li>Custom transactions & pipelines</li>
            <li>Transaction campaigns</li>
          </ol>
        </p>
        `,
      shortMessage: 'This feature is not available in your current plan. Please <a href="UPGRADE_URL">upgrade</a> your plan.',
    },
  },
  [LdFeatures.ApplicationVmi]: {
    [LdPlans.Trial]: {
      title: 'Coming soon!',
      description: ` <p>Stay tuned for Visual Market Insights to:
          <ol>
            <li>Saved filters for faster searching</li>
            <li>Share listings with your contacts</li>
            <li>Schedule showings directly from LionDesk</li>
          </ol>
        </p>
      `,
      shortMessage: 'Coming Soon... Stay tuned for Visual Market Insights',
      modalBtnText: 'Ok',
      modalCTALink: null,
    },
    [LdPlans.MLS]: {
      title: 'Coming soon!',
      description: ` <p>Stay tuned for Visual Market Insights to:
          <ol>
            <li>Saved filters for faster searching</li>
            <li>Share listings with your contacts</li>
            <li>Schedule showings directly from LionDesk</li>
          </ol>
        </p>
      `,
      shortMessage: 'Coming Soon... Stay tuned for Visual Market Insights',
      modalBtnText: 'Ok',
      modalCTALink: '',
    },
    [LdPlans.Starter]: {
      title: 'Coming soon!',
      description: ` <p>Stay tuned for Visual Market Insights to:
          <ol>
            <li>Saved filters for faster searching</li>
            <li>Share listings with your contacts</li>
            <li>Schedule showings directly from LionDesk</li>
          </ol>
        </p>
      `,
      shortMessage: 'Coming Soon... Stay tuned for Visual Market Insights',
      modalBtnText: 'Ok',
      modalCTALink: '',
    },
    [LdPlans.ProPlus]: {
      title: 'Coming soon!',
      description: ` <p>Stay tuned for Visual Market Insights to:
          <ol>
            <li>Saved filters for faster searching</li>
            <li>Share listings with your contacts</li>
            <li>Schedule showings directly from LionDesk</li>
          </ol>
        </p>
      `,
      shortMessage: 'Coming Soon... Stay tuned for Visual Market Insights',
      modalBtnText: 'OK',
      modalCTALink: '',
    },
    [LdPlans.Elite]: {
      title: 'Coming soon!',
      description: ` <p>Stay tuned for Visual Market Insights to:
          <ol>
            <li>Saved filters for faster searching</li>
            <li>Share listings with your contacts</li>
            <li>Schedule showings directly from LionDesk</li>
          </ol>
        </p>
      `,
      shortMessage: 'Coming Soon... Stay tuned for Visual Market Insights',
      modalBtnText: 'Ok',
      modalCTALink: '',
    },
    [LdPlans.LeadsPlus]: {
      title: 'Coming soon!',
      description: ` <p>Stay tuned for Visual Market Insights to:
          <ol>
            <li>Saved filters for faster searching</li>
            <li>Share listings with your contacts</li>
            <li>Schedule showings directly from LionDesk</li>
          </ol>
        </p>
      `,
      shortMessage: 'Coming Soon... Stay tuned for Visual Market Insights',
      modalBtnText: 'OK',
      modalCTALink: '',
    },
    // TODO: Restore the following code after Vmi is back on.
    // [LdPlans.Trial]: {
    //   title: 'UPGRADE TO UNLOCK VISUAL MARKET INSIGHTS',
    //   description:
    //     `<p>Visual Market Insights is not available with your <strong>Trial Plan</strong>.
    //     Upgrade to a <strong>Pro+ Plan</strong>. To unlock this feature simply click the UPGRADE button below.
    //       <ol>
    //         <li>Saved filters for faster searching</li>
    //         <li>Share listings with your contacts</li>
    //         <li>Schedule showings directly from LionDesk</li>
    //       </ol>
    //     </p>
    //     `,
    // },
    // [LdPlans.Starter]: {
    //   title: 'UPGRADE TO UNLOCK VISUAL MARKET INSIGHTS',
    //   description:
    //     `<p>Visual Market Insights is not available with your <strong>Starter Plan</strong>.
    //     Upgrade to a <strong>Pro+ Plan</strong>. To unlock this feature simply click the UPGRADE button below.
    //       <ol>
    //         <li>Saved filters for faster searching</li>
    //         <li>Share listings with your contacts</li>
    //         <li>Schedule showings directly from LionDesk</li>
    //       </ol>
    //     </p>
    //     `,
    // },
  },
  [LdFeatures.CustomNumber]: {
    [LdPlans.Trial]: {
      title: 'SET CUSTOM NUMBER',
      description:
        `<p>To connect to LionDesk Lead Assist, you must be on a plan with a custom LionDesk texting number.
        </p>
        `,
      modalBtnText: 'Upgrade',
      modalCTALink: '/settings?modal=subscription',
    },
    [LdPlans.MLS]: {
      title: 'SET CUSTOM NUMBER',
      description:
        `<p>To connect to LionDesk Lead Assist, you must be on a plan with a custom LionDesk texting number.
        </p>
        `,
      modalBtnText: 'Upgrade',
      modalCTALink: '/settings?modal=subscription',
    },
    [LdPlans.Starter]: {
      title: 'SET CUSTOM NUMBER',
      description:
        `<p>To connect to Liondesk Lead Assist you must have a custom Liondesk texting number.
        </p>
        <p>
          Add a custom number on your profile page.
        </p>
        `,
      modalBtnText: 'Go to Profile',
      modalCTALink: '/settings',
    },
    [LdPlans.ProPlus]: {
      title: 'SET CUSTOM NUMBER',
      description:
        `<p>To connect to Liondesk Lead Assist you must have a custom Liondesk texting number.
        </p>
        <p>
          Add a custom number on your profile page.
        </p>
        `,
      modalBtnText: 'Go to Profile',
      modalCTALink: '/settings',
    },
    [LdPlans.Elite]: {
      title: 'SET CUSTOM NUMBER',
      description:
        `<p>To connect to Liondesk Lead Assist you must have a custom Liondesk texting number.
        </p>
        <p>
          Add a custom number on your profile page.
        </p>
        `,
      modalBtnText: 'Go to Profile',
      modalCTALink: '/settings',
    },
    [LdPlans.LeadsPlus]: {
      title: 'SET CUSTOM NUMBER',
      description:
        `<p>To connect to Liondesk Lead Assist you must have a custom Liondesk texting number.
        </p>
        <p>
          Add a custom number on your profile page.
        </p>
        `,
      modalBtnText: 'Go to Profile',
      modalCTALink: '/settings',
    },
  },
  [LdFeatures.LeadAssist]: {
    [LdPlans.Trial]: {
      title: 'Over lead assist limit',
      description:
        `<p>You have exceeded your plan’s free Lead Assist limit. 
        Upgrade to a paid plan to use Lead Assist with overage pricing and more features.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
      modalBtnText: 'Upgrade',
      modalCTALink: '/settings?modal=subscription',
    },
    [LdPlans.MLS]: {
      title: 'Over lead assist limit',
      description:
        `<p>You have exceeded your plan’s free Lead Assist limit. 
        Upgrade to a paid plan to use Lead Assist with overage pricing and more features.</p>
        <p><a href="UPGRADE_LINK">View subscription options.</a></p>
        `,
      warning: true,
      modalBtnText: 'Upgrade',
      modalCTALink: '/settings?modal=subscription',
    },
    [LdPlans.Starter]: {
      title: 'Purchase Custom Number',
      description:
        `
      <p>This campaign has a Lead Assist action that requires a custom Liondesk texting number.</p>
      <p>Purchase a custom number in the Payments & Subscriptions settings.</p>
      `,
      modalBtnText: 'Purchase Custom Number',
      modalCTALink: '/settings?modal=customNumber',
    },
    [LdPlans.ProPlus]: {
      title: 'Select Custom Number',
      description: `
        <p>This campaign has a Lead Assist action that requires a custom Liondesk texting number.</p>
        <p>Select a custom number in the Payments & Subscriptions settings.</p>
        `,
      modalBtnText: 'Select Custom Number',
      modalCTALink: '/settings?modal=customNumber',
    },
    [LdPlans.Elite]: {
      title: 'Select Custom Number',
      description: `
        <p>This campaign has a Lead Assist action that requires a custom Liondesk texting number.</p>
        <p>Select a custom number in the Payments & Subscriptions settings.</p>
        `,
      modalBtnText: 'Select Custom Number',
      modalCTALink: '/settings?modal=customNumber',
    },
    [LdPlans.LeadsPlus]: {
      title: 'Select Custom Number',
      description: `
        <p>This campaign has a Lead Assist action that requires a custom Liondesk texting number.</p>
        <p>Select a custom number in the Payments & Subscriptions settings.</p>
        `,
      modalBtnText: 'Select Custom Number',
      modalCTALink: '/settings?modal=customNumber',
    },
  },
  mlsRetrictedTexts: {
    title: 'Upgrade to send text',
    description: `
      <p>Sending texts is not available with your <b>MLS Plan</b>. Upgrade to a <b>paid plan</b> to access additional texts and other paid features, including:
      <ol>
            <li>Emails</li>
            <li>Texts</li>
            <li>Video Text</li>
          </ol>
      </p>
    `,
    warning: false,
    shortMessage: 'This feature is not available in your current plan. Please <a href="UPGRADE_URL">upgrade</a> your plan.',
    modalBtnText: 'Upgrade',
    modalCTALink: '/settings?modal=subscription',
  }
});

export default content;
