<template lang="pug">
  v-flex(:class="columnWidth").template-picker
    //- The dialog prompting the user to confirm overriding changes
    v-dialog(v-model="userConfirm" persistent max-width="480")
      v-card
        title-bar(dark) Unsaved Changes
        v-card-text Are you sure you want to override the unsaved changes to you template body?
        v-card-actions
          v-spacer
          v-btn(flat @click="setDesiredTemplateByUser") Yes
          v-btn.primary(@click="userConfirm = false") No
    //- The modal prompting the user to enter the new folder name
    v-dialog(v-model="isNewFolderModalOpen" persistent max-width="480")
      v-card
        title-bar(dark) Add New Folder
        v-card-text
          v-text-field(
            v-model="newFolderName"
            label="Folder Name"
            :error-messages="newFolderErrorMessage"
          )
          v-textarea(
            v-model="newFolderDescription"
            auto-grow
            rows="1"
            label="Description"
          )
        v-card-actions
          v-spacer
          v-btn(flat @click="handleClickCancel") Cancel
          v-btn.primary(
            :disabled="!newFolderName.trim().length"
            @click="handleClickCreateTemplateFolder"
          ) OK

    v-flex.xs12.pt-0
      v-tabs(v-model="activeTab" v-show="false")
        v-tab(href="#templates")
        v-tab(@click="setTemplatePreview()" href="#template-preview")

      v-tabs-items(v-model="activeTab")
        v-tab-item(value="templates")
          v-container.pt-0
            slot(name="header")
              .drawer-title
                span Choose a Template
              p Select the template you want to use for your {{ communicationType.toLowerCase() }}

            v-text-field(
              solo
              prepend-inner-icon="search"
              v-model="searchValue"
              placeholder="Search for templates"
            )

            v-btn.ml-0(color="primary" @click="setTemplatePreview();")
              span New Template
            v-btn(flat color="primary" outline @click="isNewFolderModalOpen = true")
              span New Folder
            shallow-tree-view.display-template-folders(
              v-if="displayTemplates.length"
              v-model="displayTemplates"
              :active="activeTemplateFolderIds"
              :desiredTemplate="value"
              :hasPreview="true"
              :lookingForTemplateType="communicationType"
              :foldersDisabled="searchValue.length > 2"
              @child-clicked="setDesiredTemplate"
              @item-clicked="fetchTemplates($event)"
              @child-preview-clicked="setTemplatePreview"
            )
            div.display-template-folders.no-result.text-lg-center.title.pt-5(
              v-else
            ) No results..

</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { debounce } from '@/Core/utilities';
import TitleBar from '@/Core/components/TitleBar';
import ShallowTreeView from './ShallowTreeView';
import TemplateEditor from './TemplateEditor';

export default {
  components: {
    ShallowTreeView,
    TitleBar,
    TemplateEditor,
  },
  props: {
    communicationType: {
      validator(value) {
        // The value must match one of these strings
        return ['Text', 'Email'].indexOf(value) !== -1;
      },
    },
    slideInEditor: {
      type: Boolean,
      default: false,
    },
    /**
     * The desired template
     */
    value: Object,
    columnWidth: {
      type: String,
      default: 'xs12',
    },
    templateFolders: {
      type: Array,
      required: true,
    },
    setTemplatePreview: {
      type: Function,
      required: true,
    },
    fetchTemplates: {
      type: Function,
      required: true,
    },
    getTemplatesAndSelectedFolder: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      showTemplate: false,
      activeTab: 'templates',
      isCreatingTemplate: false,
      templatePreview: {},
      activeTemplateFolderIds: [],
      userConfirm: false,
      clickedTemplate: {},
      searchValue: '',
      // Variables to add new folder
      isNewFolderModalOpen: false,
      newFolderName: '',
      newFolderDescription: '',
      newFolderErrorMessage: '',
    };
  },

  computed: {
    ...mapState({
      // templateFolders: state => state.planTasks.templateFolders,
      displayTemplates: state => state.planTasks.displayTemplates,
      isEditing: state => state.planTasks.isEditing,
    }),
    // ...mapTemplatesState(['templateFolders', 'displayTemplates', 'isEditing']),
    formAction() {
      return this.isCreatingTemplate ? 'create' : 'update';
    },
    /**
     * Computes which vuex store we want to use to fecth data and functions.
     */
    desiredTemplate() {
      return Object.assign({}, this.value);
    },
    // for the template folder dropdown in the TemplateEditor component
    templateFoldersList() {
      return this.templateFolders.map(folder => {
        return { text: folder.name, value: folder.id };
      });
    },
    blankTemplate() {
      return {
        template_type: this.communicationType,
        body: '',
      };
    },
  },

  async mounted() {
    this.templatePreview = this.actionTemplate;
    await this.getTemplatesAndSelectedFolder();
  },

  watch: {
    /**
     * Opens the particular folder that the current selected template is in
     * Only works if we haven't input anything in the search bar (template-folder-level)
     */
    value: {
      handler(value) {
        if (this.searchValue.length < 3) {
          if (
            !this.activeTemplateFolderIds.includes(value.template_folder_id)
          ) {
            this.activeTemplateFolderIds.push(value.template_folder_id);
          }
          this.fetchTemplates({ id: value.template_folder_id });
        }
      },
      deep: true,
    },
    // Update the search string on debounce
    searchValue(value) {
      debounce(this.updateSearchString, [value]);
    },
  },

  methods: {
    ...mapMutations('planTasks', [
      'assignTemplatesToFolder',
      'setTemplateFolders',
    ]),
    ...mapActions('planTasks', [
      'setEditing',
      'getTemplateFolders',
      'getTemplatesInFolder',
      'searchTemplates',
      'createTemplateFolder',
    ]),
    // Function to emit the desired template back to its parent
    setDesiredTemplate(desiredTemplate) {
      this.$emit('input', desiredTemplate);
    },
    /*
     * Pops up the user confirmation to whether to replace the current template
     */
    setUserPrompt(template) {
      this.clickedTemplate = template;
      if (this.isEditing) {
        this.userConfirm = true;
      } else {
        this.setDesiredTemplateByUser();
      }
    },
    /*
     * This closes the modal, sets editing to be false, and sets the desired template
     */
    setDesiredTemplateByUser() {
      this.userConfirm = false;
      this.setEditing(false);
      this.setDesiredTemplate(this.clickedTemplate);
    },
    /**
     * Gets the templates according to the search string, and maps it to their
     * corresponding folder
     *
     * @param {String} searchString, The desired search string
     */
    async updateSearchString(searchString) {
      if (!searchString.length) {
        await this.getTemplatesAndSelectedFolder();
      } else if (searchString.length > 2) {
        this.activeTemplateFolderIds = [];
        await this.searchTemplates({
          searchValue: searchString,
          type: this.communicationType,
        });
        this.openAllPanels(this.displayTemplates);
      }
    },
    /**
     * Opens all panels, used in conjunction with searching on a template
     */
    openAllPanels(items) {
      const tempArr = [];
      for (let i = 0; i < items.length; i++) {
        tempArr.push(items[i].id);
      }
      this.activeTemplateFolderIds = tempArr;
    },
    /**
     * Helper function to create the new folder
     */
    async handleClickCreateTemplateFolder() {
      const error = await this.createTemplateFolder({
        name: this.newFolderName,
        description: this.newFolderDescription,
      });
      if (error) {
        this.newFolderErrorMessage = error.message;
      } else {
        this.newFolderErrorMessage = '';
        this.newFolderName = '';
        this.newFolderDescription = '';
        this.searchValue = '';
        await this.getTemplatesAndSelectedFolder();
        this.isNewFolderModalOpen = false;
      }
    },
    /**
     * Helper function to cancel the creation of the new folder
     */
    handleClickCancel() {
      this.isNewFolderModalOpen = false;
      this.newFolderName = '';
      this.newFolderDescription = '';
      this.newFolderErrorMessage = '';
    },
  },
};
</script>
<style lang="scss" scoped>
.template-picker {
  overflow-x: scroll;
  height: calc(100vh - 206px);
}
.display-template-folders {
  overflow-y: auto;
  margin: 12px -5px -5px -5px !important;
  padding: 5px !important;
  margin-top: 12px !important;

  &.no-result {
    color: var(--v-secondary-lighten3);
  }
}
</style>
