<!--
  @Author: Ethan Dinnen
  @Date:   2018-12-14 15:06:23
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-02-20 10:14:25
-->
<template>
  <TransactionsLayout>
    <v-container class="pipeline-container" v-if="pipeline.pipeline">
      <v-select
        class="pipeline-select"
        v-if="typeLoaded"
        :items="typeSelections"
        outline
        :placeholder="getPlaceholder"
        v-model="selected"
        @input="handleUpdatePipeline"
        color="#033"
      />
      <v-layout row class="button-container">
        <!--
          <v-btn @click="newStage" color="primary-dark" class="stage-button">
            New Stage
          </v-btn>
        -->
        <v-overflow-btn
          :key="stagesAdded"
          color="primary-dark"
          class="stage-button"
          :items="stageTypes"
          label="NEW STAGE"
          target=".pipeline-container"
          v-model="selectedStageType"
          @change="newStage"
        />
        <router-link class="pipeline__transactions__new-button" to="/create">
          <v-btn color="primary-dark" class="create-button"
            >New Transaction</v-btn
          >
        </router-link>
        <span class="transaction-total">
          {{ pipeline.pipeline.transactionCount }} Transaction{{
            pipeline.pipeline.transactionCount !== 1 ? 's' : ''
          }}
        </span>
      </v-layout>
      <v-layout row class="pipeline-layout">
        <v-flex
          class="pipeline-flex"
          v-for="(stage, index) in stages"
          :key="stage.id"
        >
          <PipelineColumn
            :stage="stage"
            :delete-stage="deleteStage"
            :rename-stage="renameStage"
            :create-stage="createStage"
            :error="pipelineError"
            :remove-temp="removeTempFromPipeline"
            :is-editing="stage.id === -1"
            :type="type"
          >
            <DraggableStages
              ref="stage"
              :stage="stage"
              :index="index"
              :pipeline="pipeline"
              :hasPlan="nextStageHasPlan"
              @stage-change="handleStageChange($event)"
              :plans="plans"
            />
          </PipelineColumn>
        </v-flex>
      </v-layout>
    </v-container>
    <StagePlanChangeModal
      :showStageChangeModal="stagePlanMixin_showStageChangeModal"
      :currentStageName="currentStage.title"
      :nextStageName="nextStageName"
      :nextPlanName="nextPlanName"
      :currentPlanName="currentPlanName"
      :closeStageChangeModal="closeStageChangeModal"
      :confirmStageChange="confirmStageChange"
      :isLoading="stagePlanMixin_isLoading"
      >
    </StagePlanChangeModal>
  </TransactionsLayout>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import TransactionsLayout from '@/Core/layouts/TransactionsLayout';

import PipelineColumn from './components/PipelineColumn';
import DraggableStages from './components/DraggableStages';
import StagePlanChangeModal from './components/StagePlanChangeModal';
import StagePlanChangeModalMixin from '../Core/mixins/stage-plan-change-modal';

const {
  mapState: mapTransactionsState,
  mapActions: mapTransactionsActions,
} = createNamespacedHelpers('transactions');

const {
  mapState: mapViewState,
  mapActions: mapViewActions,
} = createNamespacedHelpers('viewTransaction');

export default {
  components: {
    TransactionsLayout,
    PipelineColumn,
    DraggableStages,
    StagePlanChangeModal,
  },
  data() {
    return {
      selected: null,
      selectedStageType: null,
      stagesAdded: 0,
      stageTypes: [
        { text: 'In the pipeline', value: 'in-process' },
        { text: 'Out of the pipeline', value: 'closed' },
      ],
      changedType: false,
      selectedTransaction: null,
      currentStage: '',
    };
  },
  mixins: [StagePlanChangeModalMixin],
  computed: {
    ...mapTransactionsState([
      'pipeline',
      'transactionTypes',
      'type',
      'market',
      'pipelineError',
    ]),
    ...mapViewState(['plans']),
    stages() {
      return this.pipeline.pipeline ? this.pipeline.pipeline.summationsByStage : null;
    },
    // Format transactionTypes for select menu
    typeSelections() {
      return this.transactionTypes
        .map(type => {
          return { text: type.name, value: type.id };
        })
        .sort((a, b) => a.value - b.value);
    },
    // Don't render select if types not loaded
    typeLoaded() {
      return !!this.transactionTypes;
    },
    getPlaceholder() {
      if (this.changedType) {
        return null;
      }
      if (this.$route.params.type_id) {
        return this.typeSelections.find(type => {
          return type.value === parseInt(this.selected);
        }).text;
      }
      return '';
    },
  },
  methods: {
    ...mapTransactionsActions([
      'addTempToPipeline',
      'removeTempFromPipeline',
      'getPipeline',
      'updateType',
      'getTransactionTypes',
      'updateMarket',
      'updatePipeline',
      'renameStage',
      'deleteStage',
      'createStage',
    ]),
    ...mapViewActions(['getPlans']),
    handleStageChange(stages) {
      const { from, to, id } = stages;
      this.stagePlanMixin_nextStage = to;
      this.stagePlanMixin_lastStage = from;
      this.currentStage = this.stages.find(stage => stage.id === from);
      this.selectedTransaction = id;
      this.stagePlanMixin_showStageChangeModal = true;
    },
    closeStageChangeModal() {
      this.stagePlanMixin_showStageChangeModal = false;
      this.updateTypePipeline();
    },
    async confirmStageChange() {
      this.stagePlanMixin_isLoading = true;
      await this.$refs.stage[0].moveStage(
        this.stagePlanMixin_lastStage,
        this.stagePlanMixin_nextStage,
        this.selectedTransaction
      );
      this.stagePlanMixin_isLoading = false;
      this.stagePlanMixin_showStageChangeModal = false;
    },
    async handleUpdatePipeline() {
      if (!this.changedType) this.changedType = true;
      await this.updatePipeline(this.selected);
      await this.getPlans(this.type);
    },
    // Set the selected type and update the pipeline
    async updateTypePipeline() {
      const type = this.$route.params.type_id
        ? this.$route.params.type_id
        : this.transactionTypes[0].id;
      this.updateType(type);
      const { market } = this;
      this.selected = type;

      await this.getPipeline({ market, type });
    },
    newStage() {
      const { selected, selectedStageType } = this;
      this.addTempToPipeline({ selected, selectedStageType });
      this.selectedStageType = null;
      this.stagesAdded += 1;
    },
  },
  watch: {
    /**
     * We want to watch the route and update the
     * selected type and the pipeline data if the
     * route changes from /pipeline/:type_id/:id
     * to /pipeline
     */
    async $route(to, from) {
      if (to.params === from.params) return;
      this.changedType = false;
      await this.updateTypePipeline();
    },
  },
  async beforeUpdate() {
    if (!this.typeLoaded) {
      await this.getTransactionTypes(this.market);
    }
  },
  async beforeMount() {
    this.updateMarket(1); // Remove this and retrieve instead when implemented
    if (!this.transactionTypes) {
      await this.getTransactionTypes(this.market);
    }

    // Get the inital pipeline data
    await this.updateTypePipeline();
    await this.getPlans(this.type);

    /* On desktop, scroll the board to hint to the
     * user that the board is scrollable provided
     */
    if (document.body.clientWidth <= 900) return;
    const container = document.querySelector('.pipeline-layout');
    setTimeout(() => {
      container.scrollBy({ left: 350, behavior: 'smooth' });
    }, 100);
    setTimeout(() => {
      container.scroll({ left: 0, behavior: 'smooth' });
    }, 350);
  },
};
</script>

<style lang="scss">
.pipeline-container {
  max-width: unset;
  margin: unset;
  overflow-x: auto;
  font-family: 'Nunito', sans-serif;
  margin-top: 5px;

  .pipeline-select {
    width: 180px;
    margin-left: 14px;
    font-weight: bold;
    font-size: 14px;
    font-family: 'Nunito', sans-serif;
    .v-input__slot {
      border: 2px solid #3878c1 !important;
      min-height: 40px;
    }
    .v-input__append-inner {
      margin: auto 0 !important;
    }
    .v-select__selections {
      color: #3878c1 !important;
      padding-top: 0 !important;
    }
    .v-icon {
      color: #3878c1;
    }
  }

  .button-container {
    margin-left: 6px;
    margin-bottom: 20px;
    .stage-button {
      flex: unset;
      height: 36px;
      margin: auto 0;
      padding-top: 0;
      margin-left: 9px;
      label {
        color: white;
        margin-left: 20px;
        margin-top: -2px;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
      .v-input__slot {
        border: 2px solid var(--v-primary-dark-base) !important;
        border-radius: 2px;
        min-height: 36px;
        min-width: 136px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
      }
      .v-select__selection {
        color: white;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
      }
      .v-icon {
        color: white !important;
      }
      .v-input__control {
        height: 36px;
        &::before {
          background-color: unset !important;
        }
      }
      .v-input__append-inner {
        margin: unset !important;
        width: 25px;
      }
      .v-select__slot {
        height: 36px;
        background-color: var(--v-primary-dark-base);
        input {
          margin-left: unset;
        }
      }
      .v-input__slot {
        margin-bottom: 0px;
      }
    }
    .button-container .v-text-field > .v-input__control > .v-input__slot:before,
    .v-text-field > .v-input__control > .v-input__slot:after {
      display: none;
    }
    .button-container .v-text-field > .v-input__control > .v-input__slot:before,
    .v-text-field > .v-input__control > .v-input__slot:before {
      display: none;
    }
    .create-button {
      color: white;
      font-weight: 500;
      width: 180px;
    }
  }
}
.pipeline-select {
  &.v-input {
    input::placeholder {
      color: #3878c1 !important;
    }
  }
  .v-input__slot {
    width: 101%;
  }
}
.pipeline-layout {
  display: flex;
  overflow: scroll;
  @media all and (max-width: 900px) {
    flex-wrap: wrap;
  }
}
.pipeline-flex {
  max-width: unset;
  margin: 0 15px;
  flex: 1 0 25%;
  width: 250px;
  @media all and (max-width: 900px) {
    flex: 1 1 auto;
    width: auto;
  }
  &:last-of-type {
    padding-right: 1px;
  }
}
.pipeline__transactions__new-button {
  text-decoration: none;
}
</style>
