/*
 * @Author: Ethan Dinnen
 * @Date:   2018-11-30 11:03:26
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-01-18 12:54:16
 */

const reset = async ({ commit }) => await commit('reset');

const updateTitle = ({ commit }, value) => {
  commit('updateTitle', value);
};

const updateType = ({ commit }, value) => {
  commit('updateType', value);
};

const updateStagePlan = ({ commit }, value) => {
  commit('updateStagePlan', value);
};

export default {
  reset,
  updateTitle,
  updateType,
  updateStagePlan,
};
