<template>
  <div class="mls-actions">
    <v-btn fab small color="#3878C0">
    <v-icon>playlist_add</v-icon>
    </v-btn>
    <v-btn fab small color="#3878C0">
    <v-icon>visibility</v-icon>
    </v-btn>
    <v-btn fab small color="#3878C0">
    <v-icon>share</v-icon>
    </v-btn>
    <v-divider v-if="divider" vertical />
    <v-btn fab small color="#3878C0">
    <v-icon>location_on</v-icon>
    </v-btn>
    <v-btn fab small color="#3878C0">
    <v-icon>gesture</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    divider: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
