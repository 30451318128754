<template>
  <div>
  <ld-dismissable-banner
      v-if="isVisible && has10DlcCampaignStatusFF"
      text="Your business could not be registered, please contact support so we can help you to fix this issue."
      upgradeText="Contact support"
      :error="true"
      :upgradeLink="redirectUrl"
      @input="onDismiss()"
    ></ld-dismissable-banner>
    <ld-dismissable-banner
      v-if="isVisible && !has10DlcCampaignStatusFF"
      text="Your brand could not be registered, please contact support so we can help you to fix this issue."
      upgradeText="Contact support"
      :error="true"
      :upgradeLink="redirectUrl"
      @input="onDismiss()"
    ></ld-dismissable-banner>
  </div>
</template>

<script>
import LdDismissableBanner from './LdDismissableBanner';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: mapAppLayoutGetters } = createNamespacedHelpers(
  'ldAppLayout',
);

const REGISTRATION_FAILED = 'registration_failed_a2p_dismissed';
const TWILIO_REJECT = 'twilio-rejected';
const CAMPAIGN_STATUS_FAILED = 'failed';

export default {
  components: { LdDismissableBanner },
  data() {
    return {
      isVisible: false,
    }
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    registrationStandardInfo: {
      type: Object,
      default: null,
    },
    registrationStarterInfo: {
      type: Object,
      default: null,
    },
    registrationLowStandardInfo: {
      type: Object,
      default: null,
    },
  },
  watch: {
    registrationStandardInfo: {
      handler(value) {
        this.handlerIfVisible(value);
      },
    },  
    registrationStarterInfo: {
      handler(value) {
        this.handlerIfVisible(value);
      },
    },
    registrationLowStandardInfo: {
      handler(value) {
        this.handlerIfVisible(value);
      },
    },  
  },
  computed: {
    redirectUrl() {
      return 'mailto:support@liondesk.com?subject=Brand%20Registration%20Status';
    },
    ...mapAppLayoutGetters([
      'has10DlcCampaignStatusFF',
    ]),
  },
  methods: {
    isDismissed() {
      const value = localStorage.getItem(REGISTRATION_FAILED);
      return (value && value === 'true') || false; 
    },
    onDismiss() {
      localStorage.setItem(REGISTRATION_FAILED, true);
      this.isVisible = false;
      this.$emit('show', this.isVisible);
    },
    hasFailedRegistration(data){
      return data.customer_profile_status === TWILIO_REJECT || 
              data.a2p_trust_bundle_status === TWILIO_REJECT
    },
    hasFailedCampaignStatus(data){
      return data === CAMPAIGN_STATUS_FAILED
    },
    handlerVisibleTwilioData(twilio_data){
      this.isVisible = this.hasFailedRegistration(twilio_data) && !this.isDismissed();
      if (this.isVisible) {
        this.$emit('show', this.isVisible);
      }
    },
    handlerCampaignStatus(campaign_status){
      this.isVisible = this.hasFailedCampaignStatus(campaign_status) && !this.isDismissed();
        if (this.isVisible) {
          this.$emit('show', this.isVisible);
        }
    },
    handlerIfVisible(value){
      if (value.campaign_status){
        this.handlerCampaignStatus(value.campaign_status);
      }else if (value.twilio_data) {
        this.handlerVisibleTwilioData(value.twilio_data);
      }
    }
  },
};
</script>
