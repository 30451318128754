<!--
  @Author: Ethan Dinnen
  @Date:   2018-12-17 9:50:50
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-02-07 12:50:27
-->
<template>
  <div :id="`pipeline-col-${type}-${stage.id}`" class="pipeline-col">
    <div class="pipeline-col-header">
      <v-text-field
        v-if="titleEdit || creatingStage"
        ref="titleEdit"
        label="Stage Title"
        color="white"
        v-model="stageName"
        :loading="creatingStage"
        @blur="handleRenameStage"
        @keyup.enter.native="handleRenameStage"
      />
      <h3 v-else>{{ stageName }}</h3>
      <DataTableMenu :actions="actions" :color="'white'" />
    </div>
    <div class="pipeline-col-tx">
      <div class="pipeline-col-tx-header">
        <span class="total">{{ stage.transactionCount }}</span>
        <div class="summation">
          <span>
            Sales: {{ formatCurrency(stage.summation.total_sales, '$') }}
          </span>
          <span>
            Commission:
            {{ formatCurrency(stage.summation.total_commission, '$') }}
          </span>
        </div>
      </div>
      <slot></slot>
    </div>
    <v-snackbar v-model="snackbar" color="error" multi-line :timeout="5000">
      {{ error }}
    </v-snackbar>

    <LdConfirmation
      :value="dialog"
      width="490"
      risk="high"
      @click:cancel="dialog = false;"
      @click:ok="handleDeleteStage"
      cancelText="No"
      confirmText="Yes"
    >
      <template slot="header">
        Delete {{ stage.title }}
      </template>
      <template slot="body">
        Are you sure you want to delete the stage "{{ stage.title }}"? This
        cannot be undone.
      </template>
    </LdConfirmation>
  </div>
</template>

<script>
import { LdConfirmation } from 'lion-vue';
import DataTableMenu from '@/Core/components/DataTableMenu';
import formatCurrency from '@/Core/mixins/formatCurrency';

export default {
  components: {
    DataTableMenu,
    LdConfirmation,
  },
  props: {
    stage: {
      type: Object,
      required: true,
    },
    deleteStage: {
      type: Function,
      required: true,
    },
    renameStage: {
      type: Function,
      required: true,
    },
    createStage: {
      type: Function,
      requried: true,
    },
    error: {
      type: String,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    removeTemp: {
      type: Function,
      required: true,
    },
    type: {
      type: [Number, String],
    },
  },
  mixins: [formatCurrency],
  data() {
    return {
      titleEdit: false,
      stageName: this.stage.title,
      snackbar: false,
      dialog: false,
      actions: [
        {
          title: 'Rename Stage',
          icon: 'edit',
          func: this.handleShowEdit,
        },
        {
          title: 'Delete Stage',
          icon: 'delete',
          func: () => (this.dialog = true),
        },
      ],
      creatingStage: false,
    };
  },
  methods: {
    handleShowEdit() {
      // Show the edit field
      this.titleEdit = true;
      /**
       * Focus on the field for seamless editing,
       * setTimeout required as ref is set after render
       */
      setTimeout(() => {
        this.$refs.titleEdit.focus();
      }, 200);
    },
    async handleNewStage() {
      const { level, stage_type, transaction_type_id } = this.stage;

      if (!this.stageName) {
        this.stageName = null;
        this.titleEdit = false;
        this.removeTemp();
        return;
      }

      const stageToAdd = {
        level,
        stage_type,
        transaction_type_id,
        title: this.stageName,
      };

      this.creatingStage = true;
      await this.createStage(stageToAdd);
      this.creatingStage = false;
      this.titleEdit = false;

    },
    async handleRenameStage() {
      if (this.stage.id === -1 && this.titleEdit) {
        await this.handleNewStage();
        return;
      }

      const {
        stage: { id },
        stageName,
      } = this;

      if (!stageName) {
        this.stageName = this.stage.title;
        this.titleEdit = false;
        return;
      }

      // Create update object to send to API
      const update = { id, title: stageName };

      // Rename the stage
      this.renameStage(update);
      this.titleEdit = false;
    },
    async handleDeleteStage() {
      // Try to delete the stage and show error if failure occurs
      await this.deleteStage(this.stage.id);
      this.dialog = false;
      if (this.error !== '') this.snackbar = true;
    },
  },
  mounted() {
    if (this.isEditing) {
      this.handleShowEdit();
    }

    // Set the active state based on the passed id, if it is defined
    if (this.$route.params.id === undefined) return;
    const activeStage = document.getElementById(
      `pipeline-col-${this.$route.params.type_id}-${this.$route.params.id}`
    );
    if (!activeStage) return;
    activeStage.classList.add('active');
  },
};
</script>

<style lang="scss">
.pipeline-col {
  display: flex;
  flex-direction: column;
  @media (max-width: 900px) {
    margin-bottom: 25px;
  }
  &.active {
    background-color: #e4f0f6;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    background-color: var(--v-primary-dark-base);
    padding: 10px 5px 0 10px;
    border-radius: 5px 5px 0 0;
    .v-input__slot {
      &::before {
        border-color: white !important;
      }
      .v-label {
        color: white !important;
      }
      input {
        color: white !important;
        &::placeholder {
          color: white;
        }
      }
    }
    h3 {
      color: #fff;
      font-weight: 500;
      font-size: 17px;
      margin-bottom: 10px;
    }
  }
  &-tx {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
    padding: 12px;
    &-header {
      display: flex;
      flex-wrap: wrap;
      .total {
        color: var(--v-primary-dark-base);
        margin: auto 0;
        font-size: 45px;
        font-weight: 600;
      }
      .summation {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        margin-left: 20px;
        span {
          color: var(--v-info-base);
          font-family: 'Nunito Sans', sans-serif;
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
}
</style>
