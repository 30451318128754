<!--
  @Author: Ethan Dinnen
  @Date:   2019-01-23 13:18:21
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-02-25 13:23:44
-->
<template>
  <ld-gated-content
    :feature="LdFeatures.TransactionPipelines"
  >
    <div v-if="!submitLoading">
      <FlowLayout :currentStage="currentStage" class="tx-type-wizard-container">
        <template slot="header">
          <v-btn flat="flat" class="back-button" @click="goBack">
            <v-icon>keyboard_arrow_left</v-icon> Back
          </v-btn>
        </template>
        <template>
          <v-layout class="pt-5" justify-center>
            <TypeSummary
            v-model="currentStage"
            :summaryValues="summaryValues"
            :editDisabled="false"
            summaryTitle="Add a New Transaction Type"
            />
            <TypeSetup
              v-if="currentStage === 'tx-type-setup'"
              :editing="editing"
              :isNew="isNew"
              :types="typeOptions"
              :selectedType="type"
              :handleTypeSelect="handleTypeSelect"
              :typeName="typeName"
              :updateName="handleUpdateName"
              :nameRules="nameRules"
              :validateSetup="validateSetup"
              :handleUpdateRadio="handleUpdateRadio"
              :stages="stages"
              :addNewStage="handleAddNewStage"
              :updateStageType="updateStageType"
              :moveStageUp="moveStageUp"
              :moveStageDown="moveStageDown"
              :removeStage="removeStage"
              :splitByType="splitByType"
              :upDisabled="upDisabled"
              :downDisabled="downDisabled"
              :clearSelection="clearSelection"
              :templates="templates"
              :renameStage="renameStage"
              :templateStages="templateStages"
              :transactionsByStage="transactionsByStage"
              :moveTransactions="moveTransactions"
            />
            <TypeForms
              v-if="currentStage === 'tx-type-form'"
              :templates="templates"
              :deleteField="deleteField"
              :deleteFields="deleteFields"
              :deleteTemplate="deleteTemplate"
              :addField="addField"
              :updateField="updateField"
              :addRelativeField="addRelativeField"
              :addSection="addSection"
              :updateTemplateName="updateTemplateName"
              :updateFieldName="updateFieldName"
              :moveField="moveField"
              :moveTemplate="moveTemplate"
            />
          </v-layout>
        </template>
        <template slot="footer">
          <div>
            <v-btn
              flat
              class="save-btn"
              color="primary"
              @click="handleSave"
              :disabled="saveDisabled"
            >
              Save Progress
            </v-btn>
            <v-btn
              v-if="editing"
              flat
              class="delete-btn"
              color="red"
              @click="dialog = true;"
            >
              Delete Transaction Type
            </v-btn>
          </div>
          <PrevNextStepper
            :items="summaryValues"
            :submitDisabled="saveDisabled"
            :submitLoading="false"
            :nextDisabled="!isNextEnabled"
            v-on:submit-clicked="handleSubmit"
            v-model="currentStage"
            objectKey="key"
            :hasSubmit="currentStage === 'tx-type-form'"
          />
        </template>
        <v-snackbar v-model="snackbarError" color="error" :timeout="3000">
          {{ stageError }}
        </v-snackbar>
        <v-snackbar v-model="snackbarSaved" color="success" :timeout="3000">
          {{ typeSavedMsg }}
        </v-snackbar>
      </FlowLayout>
      <LdConfirmation
        :value="dialog"
        width="580"
        risk="critical"
        :parrotText="typeName.toUpperCase()"
        @click:cancel="dialog = false;"
        @click:ok="handleDeleteType"
      >
        <template slot="header">
          Delete Transaction Type
        </template>
        <template slot="criticalBody">
          {{ totalTransactions }} transaction{{ pluralTransactions }} been
          assigned to this transaction type. If you proceed with deletion you will
          also delete all the associated stages and their respective transactions.
        </template>
        <template slot="body">
          Are you sure you wish to proceed?
        </template>
      </LdConfirmation>
    </div>
    <v-layout v-else> <BreedingRhombusSpinner /> </v-layout>
  </ld-gated-content>
</template>


<script>
import { LdConfirmation } from 'lion-vue';
import FlowLayout from '@/Core/layouts/FlowLayout';
import PrevNextStepper from '@/Core/components/PrevNextStepper';
import BreedingRhombusSpinner from '@/Core/components/BreedingRhombusSpinner';
import { LdFeatures, LdGatedContent } from 'lion-vue';
import TypeSummary from './components/TypeSummary';
import TypeSetup from './components/TypeSetup';
import TypeForms from './components/TypeForms';

export default {
  components: {
    FlowLayout,
    PrevNextStepper,
    BreedingRhombusSpinner,
    TypeSummary,
    TypeSetup,
    TypeForms,
    LdConfirmation,
    LdGatedContent,
  },
  props: {
    submitLoading: Boolean,
    summaryValues: Array,
    isNew: Boolean,
    typeOptions: Array,
    type: Number,
    handleTypeSelect: Function,
    typeName: String,
    handleUpdateName: Function,
    nameRules: Array,
    validateSetup: Function,
    handleUpdateRadio: Function,
    stages: Array,
    handleAddNewStage: Function,
    updateStageType: Function,
    moveStageUp: Function,
    moveStageDown: Function,
    removeStage: Function,
    isNextEnabled: Boolean,
    handleSubmit: Function,
    handleSave: Function,
    saveDisabled: Boolean,
    stageError: String,
    resetStageError: Function,
    splitByType: Object,
    upDisabled: Function,
    downDisabled: Function,
    editing: Boolean,
    resetSavedMsg: Function,
    typeSavedMsg: String,
    clearSelection: Function,
    templates: Array,
    renameStage: Function,
    templateStages: Array,
    deleteField: Function,
    deleteFields: Function,
    deleteTemplate: Function,
    addField: Function,
    updateField: Function,
    addRelativeField: Function,
    addSection: Function,
    updateTemplateName: Function,
    updateFieldName: Function,
    transactionsByStage: Array,
    moveTransactions: Function,
    setStepDone: Function,
    handleDeleteType: Function,
    totalTransactions: Number,
    moveField: Function,
    moveTemplate: Function,
  },
  data() {
    return {
      LdFeatures,
      currentStage: 'tx-type-setup',
      snackbarError: false,
      snackbarSaved: false,
      snackbarAdded: false,
      dialog: false,
    };
  },
  computed: {
    pluralTransactions() {
      return this.totalTransactions === 1 ? ' has' : 's have';
    },
  },
  methods: {
    goBack() {
      this.$router.push('/');
    },
  },
  watch: {
    currentStage: {
      handler(value) {
        if (value === 'tx-type-setup') {
          this.setStepDone(1);
        } else if (value === 'tx-type-form') {
          this.setStepDone(0);
        }
      },
    },
    stageError: {
      handler(value) {
        if (value) this.snackbarError = true;
      },
    },
    typeSavedMsg: {
      handler(value) {
        if (value) this.snackbarSaved = true;
      },
    },
    snackbarError: {
      handler(value) {
        if (!value) this.resetStageError();
      },
    },
    snackbarSaved: {
      handler(value) {
        if (!value) this.resetSavedMsg();
      },
    },
  },
};
</script>

<style lang="scss">
.tx-type-wizard-container {
  .v-snack__content {
    justify-content: center;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    .save-btn {
      color: #3878c2;
      font-family: 'Open Sans';
      font-size: 17px;
      font-weight: 600;
      line-height: 23px;
    }
    .layout {
      position: unset;
      right: unset;
      flex: unset;
    }
  }
  .v-text-field__slot {
    label {
      color: var(--v-primary-dark-base) !important;
    }
  }
  .v-select__slot {
    label {
      color: var(--v-primary-dark-base) !important;
    }
  }
  .v-input__slot::before {
    border-color: var(--v-primary-dark-base) !important;
  }
  .v-input--checkbox {
    .accent--text {
      color: var(--v-primary-dark-base) !important;
      caret-color: var(--v-primary-dark-base) !important;
    }
  }
}
</style>
