<template lang="pug">
.loader(
    :style='imageContainerHeight'
    @change='changeFile',
    @dragover='dragoverFile',
    @drop='dropFile'
)
    p Drop image here or
        label.browse browse...
            input(type='file', accept='image/*', style='display: none', ref='attachments')
</template>
<script>
const REGEXP_MIME_TYPE_IMAGES = /^image\/\w+$/;
export default {
    name: 'LdImageLoader',
    mounted() {
        this.sendAttachmentsRef();
    },
    computed: {
        imageContainerHeight() {
            const imgHeight = this.imgHeight - 64;
            return {
                height: `${imgHeight}px`
            };
        },
    },
    methods: {
        changeFile({ target }) {
            const { files } = target;
            this.processFiles(files);
        },
        dragoverFile(event) {
            event.preventDefault();
        },
        dropFile(event) {
            const { files } = event.dataTransfer;
            event.preventDefault();
            this.processFiles(files, event);
        },
        async processFiles(files, event) {
            if (!files || files.length < 1) {
                return;
            }
            try {
                const [firstFile] = files;
                const data = await this.readFile(firstFile, event);
                if (data) {
                    this.$emit("onFileRead", data);
                }
            } catch (error) {
                this.$emit("onError", error && error.message ? error.message : error);
            }
        },
        async readFile(file, event) {
            if (!file) {
                return;
            }
            if (!REGEXP_MIME_TYPE_IMAGES.test(file.type)) {
                throw new Error(`Please ${event ? event.type : 'choose'} an image file.`);
            }
            if (!URL) {
                throw new Error('Your browser is not supported.');
            }
            return {
                name: file.name,
                type: file.type,
                url: URL.createObjectURL(file),
            }
        },
        sendAttachmentsRef() {
            this.$emit('onAttachmentsRef', this.$refs.attachments);
        },
    },
}
</script>
<style lang="scss" scoped>
.loader {
    display: table;
    height: 100%;
    overflow: hidden;
    width: 100%;
    background-color: #fff;

    &>p {
        color: #999;
        display: table-cell;
        text-align: center;
        vertical-align: middle;
    }
}

.browse {
    cursor: pointer;
    margin-left: 0.25rem;
    color: var(--v-primary-base);

    &:hover {
        color: var(--v-primary-base);
        text-decoration: underline;
    }
}
</style>
