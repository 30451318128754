<!--
  @Author: Ethan Dinnen
  @Date:   2018-12-19 15:31:44
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-02-25 14:21:49
-->
<template>
  <v-layout>
    <v-btn
      flat="flat"
      :disabled="!currentIndex || prevDisabled"
      @click="handleClickPrev"
      >Prev</v-btn
    >
    <v-btn
      class="stepper-button"
      v-if="!hasSubmit"
      :disabled="nextDisabled"
      @click="nextAsSubmit ? $emit('submit-clicked') : handleClickNext();"
      >Next</v-btn
    >
    <v-btn
      class="stepper-button"
      v-else
      :disabled="submitDisabled"
      @click="$emit('submit-clicked');"
      >{{ submitValue }}</v-btn
    >
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 0,
    };
  },
  props: {
    /**
     * @prop [Required] [Array] items
     * The array of items given
     * Item can be one of 'Object' | 'Number' | 'String'
     * e.g. [{ key: 0, name: 'lion' }, { key: 1, name: 'desk' }]
     */
    items: Array,
    /**
     * @prop [Optional] [String] objectKey
     * If given, this means that items is and array of objects
     * This determines the value being returned. Otherwise, return the whole item
     * e.g. 'type'
     */
    objectKey: String,
    /**
     * @prop [Optional]
     * Controls whether the last button should be Submit instead of Next.
     */
    hasSubmit: {
      type: Boolean,
      default: false,
    },
    submitLoading: {
      type: Boolean,
      default: false,
    },
    submitText: {
      type: String,
      default: '',
    },
    /**
     * @prop [Optional]
     * If hasSubmit is true, sets the disabled condition for that button
     */
    submitDisabled: {
      type: Boolean,
      default: false,
    },
    nextDisabled: {
      type: Boolean,
      default: true,
    },
    prevDisabled: {
      type: Boolean,
      default: false,
    },
    // Override function of next click to instead emit 'submit-clicked'
    nextAsSubmit: {
      type: Boolean,
      default: false,
    },
    /**
     * @prop [Required] [String | Number | Object] value
     * The current selection that the user is currently in.
     * e.g. 'template-type'
     */
    value: String || Number || Object,
  },

  mounted() {
    this.findItemIndex(this.items, this.objectKey, this.value);
  },

  watch: {
    items: {
      handler(value) {
        this.findItemIndex(value, this.objectKey, this.value);
      },
      deep: true,
    },
    value(value) {
      this.findItemIndex(this.items, this.objectKey, value);
    },
  },

  computed: {
    submitValue() {
      const { submitText } = this;
      if (submitText === 'create') {
        return this.submitLoading ? '...Creating' : 'Create';
      }
      return this.submitLoading ? '...Submitting' : 'Submit';
    },
  },

  methods: {
    findItemIndex(items, objectKey, value) {
      this.currentIndex = objectKey
        ? items.findIndex(item => item[objectKey] === value)
        : items.findIndex(item => item === value);
    },
    handleClickPrev() {
      const { items, objectKey } = this;

      if (!this.currentIndex) return;

      const item = items[--this.currentIndex];
      const prevItem = objectKey ? item[objectKey] : item;
      this.$emit('input', prevItem);
    },
    handleClickNext() {
      const { items, objectKey } = this;

      if (this.currentIndex > this.items.length - 1) return;

      const item = items[++this.currentIndex];
      const nextItem = objectKey ? item[objectKey] : item;
      this.$emit('input', nextItem);
    },
  },
};
</script>

<style>
.stepper-button {
  color: white !important;
  background-color: #3878c1 !important;
}
</style>
