<!--
  @Author: Ethan Dinnen
  @Date:   2018-12-19 10:02:50
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-01-11 16:42:10
-->
<template>
  <v-avatar
    :color="personalColour(stakeholder, 80, 60)"
    :size="size"
    class="stakeholder-avatars"
  >
    <span class="white--text initials"> {{ initials }} </span>
  </v-avatar>
</template>

<script>
import personalColour from '@/Core/mixins/personalColour';
export default {
  props: {
    stakeholder: Object,
    size: {
      type: Number,
      required: false,
      default: 32,
    },
  },
  mixins: [personalColour],
  computed: {
    initials() {
      if (
        this.stakeholder.meta.first_name === '' &&
        this.stakeholder.meta.last_name === ''
      )
        return 'BC';
      return `${
        this.stakeholder.meta.first_name !== ''
          ? this.stakeholder.meta.first_name[0].toUpperCase()
          : ''
      }${
        this.stakeholder.meta.last_name !== ''
          ? this.stakeholder.meta.last_name[0].toUpperCase()
          : ''
      }`;
    },
  },
};
</script>

<style>
.initials {
  position: relative;
  top: 0.5px;
}
</style>
