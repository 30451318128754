import axios from 'axios';

export const create = async (title, type_id, stage_id = null) => {
  const plan = { title, type_id, stage_id };
  if (!stage_id) delete plan.stage_id;
  return await axios
    .post('plans', { plan })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.data.errors);
    });
};

export const get = async id => {
  return await axios
    .get(`plans/${id}`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.data);
    });
};

export const update = async plan => {
  return await axios
    .put('plans', { plan })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.data);
    });
};

export const find = async (params) => {
  return await axios
    .get('plans/find', { params })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.data);
    });
};

export const getAll = async () => {
  return await axios
    .get('plans')
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.data);
    });
};

export const allByType = async type => {
  return await axios
    .get(`plans/type/${type}`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.data);
    });
};

export const destroy = async id => {
  return await axios
    .delete(`plans/${id}`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.data);
    });
};

export const startPlan = async (planId, txId) => {
  return await axios
    .get(`plans/start/${txId}/${planId}`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      // TODO: fix all the incorrect "new Error(err.data)"
      // new Error(...) expects a string, not object 
      // so throwing new Error(err.data)
      // makes the message [Object Object] i.e. completely useless
      throw new Error(err.data.message || err.data.errors[0]);
    });
};

export const createTask = async task => {
  return await axios
    .post('plans/tasks', { ...task })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.data);
    });
};

export const getTasks = async planId => {
  return await axios
    .get(`plans/tasks/${planId}`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.data);
    });
};

export const refreshTasks = async transactionId => {
  return await axios
    .get(`plans/tasks/refresh/${transactionId}`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.data);
    });
};

export const updateTask = async (id, body) => {
  return await axios
    .put(`plans/tasks/${id}`, body)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.data);
    });
};

export const deleteTask = async id => {
  return await axios
    .delete(`plans/tasks/crm/${id}`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.data);
    });
};

export const destroyTask = async id => {
  return await axios
    .delete(`plans/tasks/${id}`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.data);
    });
};

export const getTemplateFolders = async () => {
  return await axios
    .get('plans/crmTemplates/template-folders')
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.data);
    });
};

export const getTemplates = async (type, folder, search, description) => {
  let url = 'plans/crmTemplates/templates';
  let appended = false;
  if (type !== '') {
    url = url + `?type=${type}&`;
    appended = true;
  }
  if (folder !== '') {
    url = url + `${appended ? '&' : '?'}folder=${folder}&`;
    appended = true;
  }
  if (search.length) {
    url = url + `${appended ? '&' : '?'}search=${search}&`;
    appended = true;
  }
  if (description) {
    url = url + `${appended ? '&' : '?'}description=${description}`;
    appended = true;
  }
  return await axios
    .get(url)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.data);
    });
};

export const createTemplateFolder = async data => {
  return await axios
    .post('plans/crmTemplates/template-folders', data)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.data);
    });
};

export const createTemplate = async data => {
  return await axios
    .post('plans/crmTemplates/templates', data)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.data);
    });
};

export const patchTemplate = async (id, data) => {
  return await axios
    .patch(`plans/crmTemplates/templates/${id}`, data)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.data);
    });
};

export const getAccessToken = async () => {
  return await axios
    .get('plans/crmTemplates/accessToken')
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.data);
    });
};

export const destroyCommunication = async comm => {
  return await axios
    .delete(`plans/communications/destroy`, { body: comm })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      throw new Error(err.data);
    });
};
