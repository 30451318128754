<template>
  <v-card
    class="listing-card-table"
  >
    <v-checkbox
      v-if="selected || showHover"
      class="select-box"
      v-model="selected"
      color="#285B96"
      @change="handleSelect"
    />
    <div
      class="listing-card-table__image"
      :style="withImage(image)"
      v-on:click="itemClicked()"
    />
    <ActionsContainer divider v-if="showHover" />
    <v-card-text
      :class="['listing-details-container', showHover ? 'hovered' : null]"
      v-on:click="itemClicked()"
    >
      <div class="listing-details">
        <span class="text-primary">
          {{ this.listing._source.UnparsedAddress }}
        </span>
        <span class="text-secondary">
          {{ this.listing._source.City }},
          {{ this.listing._source.StateOrProvince }}
        </span>
        <div v-if="this.listing._source.ListingOffice" class="text-secondary">
          Courtesy of {{ this.listing._source.ListingOffice }}
        </div>
        <div class="details">
          <span class="text-secondary">
            {{ this.details.BedroomsTotal }} BED
          </span>
          <span class="text-secondary">
            {{ this.details.BathroomsTotal }} BATH
          </span>
          <span class="text-secondary">
            {{ this.details.BuildingAreaTotal }} SQFT
          </span>
          <span class="text-secondary">{{ this.details.YearBuilt }}</span>
        </div>
      </div>
    </v-card-text>
    <v-divider light vertical v-on:click="itemClicked()" />
    <v-card-text
      card-footer
      :class="['footer-info-container', showHover ? 'hovered' : null]"
      v-on:click="itemClicked()"
    >
      <div class="footer-info">
        <div class="footer-info__status">
        <LdTag
          :text="listing._source.StandardStatus"
          :color="statusColor(listing._source.StandardStatus)"
        />
        </div>
        <span class="days-on">{{ this.listing._source.DaysOnMarket }} DOM</span>
        <span class="footer-info__price">{{ listingPrice }}</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import statusColor from 'lion-vue-shared/mixins/statusColor';
import LdTag from 'lion-vue-shared/components/LdTagContainer';
import ActionsContainer from 'lion-vue-mls/components/MlsActionsContainer';
import formatCurrency from 'lion-vue-shared/mixins/formatCurrency';
import MlsListingCardMixins from 'lion-vue-mls/mixins/MlsListingCardMixins';

export default {
  components: {
    LdTag,
    ActionsContainer,
  },
  name: 'listing-card',
  props: {
    listing: {
      type: Object,
      require: true,
    },
    mlsNumberSelected: {
      type: String,
      default: null,
    },
    onItemClicked: {
      type: Function,
      default: () => {
        // eslint-disable-next-line no-alert
        alert('Calback not implemented for Item Clicked. [MlsListingCard.vue]');
      },
    },
    select: {
      type: Function,
      default: () => {},
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    noHover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hover: false,
      selected: this.isSelected,
    };
  },
  mixins: [statusColor, formatCurrency, MlsListingCardMixins],
};
</script>

<style lang="scss">
.listing-card-table {
  position: relative;
  margin: 6px auto;
  display: flex !important;
  &:hover {
    cursor: pointer;
  }

  &__image {
    min-width: 182px;
    background-size: cover;
    background-position: center;
  }
  .mls-actions {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    i {
      color: white !important;
    }

  }
  .select-box {
    position: absolute;
    top: -8px;
    left: 10px;
    z-index: 2;
    &:not(.v-input--is-dirty) {
      .v-input--selection-controls__input {
        background-color: white;
        width: 18px;
        height: 17px;
        border-radius: 2px;
        box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.2);
        margin-top: 3px;
        margin-left: 2.5px;
      }
      i {
        display: none;
      }
    }
    &.v-input--is-dirty {
      i {
        display: inline-flex;
      }
    }
  }
  .v-carousel {
    box-shadow: unset;
    border-top-left-radius: 5px;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 5px;
  }
  .hovered {
    opacity: 0.4;
  }
  .listing-details-container {
    padding-bottom: 8px;
    transition: opacity 0.2s ease-in-out;
  }
  .footer-info-container {
    padding-top: 12px;
    padding-bottom: 8px;
    transition: opacity 0.2s ease-in-out;
  }
  .listing-details {
    display: flex;
    flex-direction: column;
    .text-primary {
      color: #3878C2;
      font-family: "Open Sans";
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
    }
    .text-secondary {
      color: #686868;
      font-family: "Open Sans";
      font-size: 13px;
      line-height: 16px;
    }
    .details {
      margin-top: 20px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      max-width: 85%;
    }
  }
  .footer-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    &__status {
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .days-on {
      color: #686868;
      font-family: "Open Sans";
      font-size: 13px;
      line-height: 16px;
    }
    &__price {
      margin-top: 5px;
      color: #4A4A4A;
      font-family: "Open Sans";
      font-size: 16px;
      font-weight: 600;
      letter-spacing: -0.05px;
      line-height: 22px;
    }
  }
}
</style>
