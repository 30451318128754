<!--
  @Author: Ethan Dinnen
  @Date:   2018-12-19 16:58:44
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-02-25 15:07:28
-->
<template>
  <v-flex class="xs12 md4" id="wizard-watch-height">
    <Forms
      :market="market"
      :type="type"
      :property="property"
      :updateSummary="updateSummary"
      :editing="editing"
      :template="template"
      :reAssignSummaryValues="reAssignSummaryValues"
      :form="form"
    />
  </v-flex>
</template>

<script>
import Forms from 'transactionsComponents/Forms';
export default {
  components: {
    Forms,
  },
  props: {
    market: Number,
    type: Number,
    property: Object,
    updateSummary: Function,
    editing: {
      type: Boolean,
      default: false,
    },
    template: {
      type: Object,
      required: false,
      default: () => {},
    },
    reAssignSummaryValues: Function,
    form: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
};
</script>
