<template>
  <ld-dismissable-banner
      v-if="isVisible"
      text="You have not connected your email, get the most out of LionDesk and connect now."
      upgradeText="Connect Now"
      :upgradeLink="redirectUrl"
      @input="onDismiss()"
    ></ld-dismissable-banner>
</template>

<script>
import LdDismissableBanner from './LdDismissableBanner';
import { HC_PROPS } from 'lion-vue-src/_globals';

const EMAIL_INTEGRATION = "email_integration_notification_dismissed";

export default {
  components: { LdDismissableBanner },
  data() {
    return {
      isVisible: false,
    }
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    alreadyConnected: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    user: {
      handler(value) {
        if (!value) {
          return;
        }

        this.isVisible = !this.isDismissed();
        if (this.isVisible) {
          this.$emit('show', this.isVisible);
        }
      },
      deep: true,
      immediate: true,
    },
    alreadyConnected: {
      handler(value) {
        if(value) {
          this.isVisible = false;
          this.$emit('show', this.isVisible);
        }
      }
    },
  },
  computed: {
    redirectUrl() {
      return `${HC_PROPS.PLATFORM_APP_URL}/email-integrations`;
    },
  },
  methods: {
    isDismissed() {
      const value = localStorage.getItem(EMAIL_INTEGRATION);
      return (value && value === "true") || false; 
    },
    onDismiss() {
      localStorage.setItem(EMAIL_INTEGRATION, true);
      this.isVisible = false;
      this.$emit('show', this.isVisible);
    },
  },
};
</script>
