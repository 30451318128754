<!--
      @Author: Ethan Dinnen
      @Date:   2018-11-30 15:45:40
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-02-08 10:43:00
-->
<template>
  <v-autocomplete
    v-model="value"
    :items="itemList"
    :label="Title"
    hide-no-data
    :disabled="disabled"
    :loading="loading"
    @change="onChange"
    :search-input.sync="search"
    :rules="rules"
    :required="required"
    :placeholder="placeholder"
    :debounce-search="0"
    :append-icon="appendIcon"
    :prepend-inner-icon="prependIcon"
    :solo="solo"
  />
</template>

<script>
export default {
  props: {
    title: String,
    items: Array,
    type: {
      type: String,
      required: false,
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    solo: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    onInput: {
      type: Function,
      required: true,
    },
    updateItem: {
      type: Function,
      required: false,
      default() {
        return () => {};
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    appendIcon: {
      type: String,
      required: false,
      default: '$vuetify.icons.dropdown',
    },
    prependIcon: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      value: '',
      search: '',
      loading: false,
      Title: this.title,
    };
  },
  computed: {
    // Compute an array of items to display
    itemList() {
      if (this.type === 'properties') {
        return this.items.map(item => item.address);
      } else if (this.type === 'contacts') {
        return this.items.map(item => {
          if (item.owner_name) {
            return `${item.first_name} ${item.last_name} - Owner: ${
              item.owner_name
            }`;
          }
          return `${item.first_name} ${item.last_name}`;
        });
      }
      return this.items;
    },
  },
  methods: {
    // Emit the object matching user's selection to vuex
    onChange() {
      switch (this.type) {
        case 'properties':
          this.onInput(this.items.find(item => item.address === this.value));
          break;
        case 'contacts':
          this.updateItem(
            this.items.find(item => {
              const names = this.value.split(' - Owner: ');
              return `${item.first_name} ${item.last_name}` === names[0];
            })
          );
          break;
        default:
          this.onInput(this.value);
          break;
      }
    },
  },
  watch: {
    async search(query) {
      if (this.type === 'contacts') {
        this.loading = true;
        if (query) await this.onInput(query);
        this.loading = false;
      }
    },
  },
};
</script>
