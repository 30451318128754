<template>
  <v-dialog :value="showDialog" persistent width='480px'>
      <v-card>
        <v-card-title class="title dark primary white--text">Name your filter</v-card-title>
        <v-card-text>
          <v-text-field
            label="Filter Name"
            :value="value"
            placeholder="Enter a filter name"
            @input="handleFilterNameChange"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            flat
            @click="$emit('filterNameCancel')"
          > Cancel </v-btn>
          <v-btn
            class="primary"
            @click="$emit('filterNameSave', value)"
            :disabled="!value || !value.trim().length"
          > Save </v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    showDialog: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    handleFilterNameChange(name) {
      this.$emit('input', name);
    },
  }
}
</script>