<!--
  @Author: Ethan Dinnen
  @Date:   2018-11-30 11:36:30
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-02-26 14:45:47
-->
<template>
  <v-text-field
    :id="fieldId"
    :label="title"
    :placeholder="placeholder"
    v-model="value"
    @focus="selectOnClick ? selectText($event) : null;"
    @input="emitInput"
    @click:append="emitAppendClick"
    :rules="rules"
    :required="required"
    :prepend-icon="prependIcon"
    :append-icon="appendIcon"
    :disabled="disabled"
    :error-messages="errors"
    @keyup.enter.native="$emit('onEnter');"
  />
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    title: String,
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    entered: {
      type: String,
      required: false,
      default: '',
    },
    fieldId: {
      type: [String, Number],
      required: false,
      default: null,
    },
    prependIcon: {
      type: String,
      required: false,
      default: null,
    },
    appendIcon: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    errors: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectOnClick: {
      type: Boolean,
      required: false,
      default: false,
    },
    returnId: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      value: this.entered,
    };
  },
  methods: {
    selectText(evt) {
      evt.srcElement.select();
    },
    emitInput() {
      const { value, fieldId, returnId } = this;
      this.$emit('onInput', fieldId && returnId ? { value, fieldId } : value);
    },
    emitAppendClick() {
      this.$emit('onAppendClick', this.fieldId);
    },
  },
};
</script>
