<!--
@Author: Ethan Dinnen
@Date:   2018-12-17 11:33:11
@Last Modified by:   Ethan Dinnen
@Last Modified time: 2019-02-19 11:14:59
-->
<template>
  <draggable
    v-model="stagesGroup"
    :options="dragOptions"
    :move="onMove"
    @start="isDragging = true;"
    @end="handleDrop"
    :id="index"
    style="width: auto;"
  >
    <transition-group
      type="transition"
      :name="'flip-list'"
      class="draggable-group"
      :id="stage.id"
    >
      <v-card
        v-for="item in stagesGroup"
        :id="`pipeline-card-${item.id}`"
        :key="item.id"
        class="mt-3 transaction-card"
        @click="handleCardClick(item.id);"
      >
        <v-card-title primary-title class="pipeline-card-title">
          <div class="col">
            <span
              class="mls-id"
              v-if="item.meta.property && item.meta.property._source.ListingId"
            >
              MLS: {{ item.meta.property._source.ListingId }}
            </span>
            <router-link :to="`/${item.id}`"><h4>{{ item.title }}</h4></router-link>
            <span v-if="item.meta.property" class="pipeline-card-address">
              {{ address(item.meta.property) }}
            </span>
          </div>
          <div class="menu">
            <DataTableMenu :actions="makeActions(item.id)" />
          </div>
        </v-card-title>
        <v-card-text
          :class="[
            'pipeline-text',
            'pt-1',
            stakeholders(item) ? '' : 'pipeline-text-no-border',
          ]"
        >
          <div class="drag-pipeline-financials">
            <span>S: {{ financials(item.id).sales }}</span>
            <span>C: {{ financials(item.id).commission }}</span>
          </div>
          <div class="drag-pipeline-date">{{ date(item) }}</div>
        </v-card-text>
        <v-layout row class="pipeline-stakeholders" v-if="stakeholders(item)">
          <div
            v-for="stakeholder in stakeholders(item)"
            class="pipeline-avatar-container"
            :key="stakeholder.id"
          >
            <StakeholderAvatar :stakeholder="stakeholder" :size="25" />
          </div>
        </v-layout>
      </v-card>
    </transition-group>
  </draggable>
</template>

<script>
import DataTableMenu from '@/Core/components/DataTableMenu';
import StakeholderAvatar from '@/Core/components/StakeholderAvatar';
import formatCurrency from '@/Core/mixins/formatCurrency';
import personalColour from '@/Core/mixins/personalColour';
import StagePlanChangeModal from './StagePlanChangeModal';
import StagePlanChangeModalMixin from '../../Core/mixins/stage-plan-change-modal';
import { PLAN_BY_STAGE_FLAG } from '@/Core/config/constants';
import { createNamespacedHelpers } from 'vuex';
import capitalize from 'lodash/capitalize';
import moment from 'moment';
import draggable from 'vuedraggable';

export default {
  components: {
    draggable,
    DataTableMenu,
    StakeholderAvatar,
  },
  props: {
    stage: Object,
    index: Number,
    pipeline: Object,
    hasPlan: Boolean,
    plans: {
      type: Array,
      default: [],
    },
  },
  mixins: [formatCurrency, personalColour, StagePlanChangeModalMixin],
  data() {
    return {
      isDragging: false,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 50,
        group: 'stages',
        ghostClass: 'ghost',
      };
    },
    stagesGroup: {
      get() {
        // Retrieve the transactions list from the state via the passed id
        return this.$store.state.transactions.pipeline.pipeline
          .summationsByStage[this.index].transactions;
      },
      set(value) {
        // Update the local state transactions array when item is dragged
        return this.$store.commit('transactions/updatePipelineStages', {
          transactions: value,
          index: this.index,
        });
      },
    },
  },
  methods: {
    makeActions(id) {
      return [
        {
          title: 'Edit Transaction',
          icon: 'edit',
          path: `/edit/${id}`,
        },
      ];
    },
    address(property) {
      const {
        StreetNumberNumeric,
        StreetSuffix,
        StreetName,
        StateOrProvince,
        City,
      } = property._source;
      return `${StreetNumberNumeric} ${capitalize(StreetName)} ${capitalize(
        StreetSuffix
      )}, ${capitalize(City)}, ${StateOrProvince}`;
    },
    date(item) {
      // Return tx creation date as YYYY/MM/DD
      return moment(item.created_at).format('YYYY/MM/DD');
    },
    stakeholders(item) {
      if (item.user_tx_stakeholders.length < 1) return false;
      return item.user_tx_stakeholders;
    },
    financials(id) {
      const { sales, commission } = this.pipeline.values.find(transaction => {
        return transaction.id === id;
      });
      return {
        sales: this.formatCurrency(sales) || '0',
        commission: this.formatCurrency(commission) || '0',
      };
    },
    async handleDrop(evt) {
      const from = +this.stage.id;
      // Retrieve the to id from the state via the event's 'to' id
      const to = +evt.to.id;
      const id = +evt.item.id.split('-')[2];
      this.stagePlanMixin_nextStage = to;
      /**
       * Dispatch the moveStages transactions action
       * to commit the stage change to the database
       */
      if (from === to) {
        this.handleCardClick(id);
        this.isDragging = false;
        return false;
      }
      if (this.nextStageHasPlan) {
        this.$emit('stage-change', { from, to, id });
      } else {
        this.moveStage(from, to, id);
      }
    },
    async moveStage(from, to, id) {
      await this.$store.dispatch('transactions/moveStages', { from, to, id });
      this.isDragging = false;
      const { market, type } = this.$store.state.transactions;
      await this.$store.dispatch('transactions/getPipeline', { market, type });
      this.handleCardClick(id);
      return true;
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    handleCardClick(item) {
      const card = document.getElementById(`pipeline-card-${item}`);
      const active = document.getElementsByClassName('active-pipeline-card');
      if (card.classList.contains('active-pipeline-card')) return;
      for (const activeCard of active) {
        activeCard.classList.remove('active-pipeline-card');
      }
      card.classList.add('active-pipeline-card');
    },
  },
};
</script>

<style lang="scss" scoped>
.draggable-group {
  height: calc(100vh - 245px);
  display: block;
  padding: 1px;
  overflow-x: scroll;
  @media all and (max-width: 900px) {
    min-height: 150px;
    width: auto;
    margin-bottom: 25px;
  }
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
.pipeline-card-title {
  padding-bottom: 0;
  padding-top: 15px;
  position: relative;
  display: flex;
  .col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h4 {
      color: #285b96;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: -4px;
    }
  }
  .menu {
    position: absolute;
    top: 15px;
    right: 5px;
  }
  .mls-id {
    color: #8e8e8e;
  }
  .pipeline-card-address {
    color: #050505;
  }
}
.pipeline-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px;
  padding: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--v-lion-light-grey-base);
  &.pipeline-text-no-border {
    border-bottom: 0px;
  }
  .drag-pipeline-financials {
    color: #285b96;
    font-size: 16px;
    span:not(:first-of-type) {
      margin-left: 10px;
    }
  }
  .drag-pipeline-date {
    color: #8e8e8e;
    margin: auto 0;
  }
}
.pipeline-stakeholders {
  margin-top: -6px;
  margin-bottom: 3px;
  margin-left: 12px;
}
.pipeline-avatar-container {
  margin-bottom: -10px;
  max-width: 65%;
  .stakeholder-avatars {
    margin: 15px 3px 0;
  }
}
.transaction-card {
  position: relative;
  padding-bottom: 15px;
  min-height: 80px;
  border-radius: 5px;
  transition: unset;
  .pipeline-date {
    position: absolute;
    right: 15px;
    bottom: 10px;
    color: #6b6b6b;
  }
  .date-stakeholders {
    bottom: 22px;
  }
  &.active-pipeline-card {
    border: 1.6px solid #325e9c;
  }
}
</style>
