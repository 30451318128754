<template>
  <SelectInput
    class="action-recipient-select"
    title="Select a recipient role"
    :options="options"
    @onInput="handleSelectRole"
    :selected="selectedRole"
  />
</template>

<script>
import SelectInput from '@/Core/components/SelectInput';

export default {
  components: {
    SelectInput,
  },
  props: {
    roles: {
      type: Array,
      required: true,
    },
    handleSelectRole: {
      type: Function,
      required: true,
    },
    selectedRole: {
      type: Number,
      required: true,
    },
  },
  computed: {
    options() {
      return this.roles.map(role => {
        return { text: role.name, value: role.id };
      });
    },
  },
};
</script>

<style lang="scss">
.action-recipient-select {
  max-width: 480px;
}
</style>
