import vmiFeathers from 'vmi-feathers-client';

const defaultConditions = [
  {
    value: ['Active'],
    field: 'StandardStatus',
    operator: '$or_in',
    type: 'array',
    static: true,
  },
  {
    value: ['Residential'],
    field: 'PropertyType',
    operator: '$or_in',
    type: 'array',
    static: true,
  },
];

export default {
  namespaced: true,
  state: {
    searchText: '',
    searchType: '',
    coordinates: [],
    savedSearch: {
      name: '',
      conditions: [...defaultConditions],
    },
    searchSuggestions: [],
    mapBounds: null,

    // By default, load on Liondesk office (easter-egg)
    mapCenter: { lat: 33.1378458, lng: -117.2975841 },
  },
  mutations: {
    updateSearchText(state, payload) {
      state.searchText = payload;
    },
    updateSearchType(state, payload) {
      state.searchType = payload;
    },
    updateCoordinates(state, payload) {
      state.coordinates = payload;
    },
    updateSavedSearch(state, payload) {
      state.savedSearch = payload;
    },
    updateSearchSuggestions(state, results) {
      const newSuggs = results.map(result => {
        const {
          id,
          searchType,
          coordinates,
          conditions,
        } = result;
        return {
          id,
          searchText: result.suggestion,
          searchType,
          coordinates,
          conditions,
        };
      });
      state.searchSuggestions = newSuggs;
    },
    setMapBounds(state, payload) {
      state.mapBounds = payload;
    },
    updateMapCenter(state, payload) {
      state.mapCenter = payload;
    },
    deleteFilter(state, payload) {
      state.searchSuggestions = payload;
    },
  },
  actions: {
    async updateSearchSuggestions({ commit }, searchText) {
      const results = await vmiFeathers()
        .service('typeahead')
        .find({
          query: {
            searchText,
            searchTypes: [
              'polygon',
              'city',
              'address',
              'mls_number',
              'postal_code',
            ],
          },
        })
        .catch(err => {
          console.error('typeahead find() failed:', err);
        });
      commit('updateSearchSuggestions', results);
    },
    async saveSearch({ state }) {
      const { name, conditions, id } = state.savedSearch;
      if (id) {
        await vmiFeathers()
          .service('saved-searches')
          .patch(id, {
            conditions,
            name,
          });
      } else {
        await vmiFeathers()
          .service('saved-searches')
          .create({
            conditions,
            name,
          });
      }
    },
    async deleteSearchFilter({ state, commit }, filter) {
      await vmiFeathers().service('saved-searches').remove(filter.id);
      const newSearchSuggestions = state.searchSuggestions.filter(searchSuggestion => {
        return searchSuggestion.id !== filter.id;
      });
      commit('deleteFilter', newSearchSuggestions);
    },
    updateSavedSearch({ commit }, payload) {
      commit('updateSavedSearch', payload);
    },
    updateSelectedSearch({ commit, state }, payload) {
      const { searchText, searchType, coordinates } = payload;
      let { conditions } = state.savedSearch;
      if (!conditions) {
        conditions = [...defaultConditions];
      }

      if (searchType === 'savedFilter') {
        commit('updateSavedSearch', { name: searchText, conditions });
      }

      commit('updateSearchText', searchText);
      commit('updateSearchType', searchType);
      commit('updateCoordinates', coordinates);
    },
    setMapBounds({ commit }, payload) {
      commit('setMapBounds', payload);
    },
    updateMapCenter({ commit }, payload) {
      commit('updateMapCenter', payload);
    },
  },
};
