/*
 * @Author: Ethan Dinnen
 * @Date:   2018-11-30 11:03:26
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-02-25 17:50:16
 */

import initialState from './initialState';
import { sanitize } from '@/Core/mixins/formatCurrency';
import transactionInputTypes from '@/Core/constants/transactionInputTypes';

const reset = state => {
  Object.assign(state, initialState());
};

const createForms = (state, value) => {
  state.newForms = value;
};

const updateField = (state, { field, rootState }) => {
  const { index, update } = field;
  const transaction = rootState.transactions.transaction;
  // If the transaction is defined, user is editing a transaction
  if (transaction) {
    // Find the correct form
    const form = transaction.transaction_forms[index];
    // Find the correct field
    const toUpdate = form.transaction_form_fields.find(
      formField =>
        formField.label === update.label || formField.label === update.title
    );
    // Find it's index
    const updateIndex = form.transaction_form_fields.indexOf(toUpdate);
    // Ensure value is empty string if undefined
    const value = update.value || '';
    // Set the fields value, sanitize it if it is a currency
    toUpdate.value =
      update.value && toUpdate.type === transactionInputTypes.CURRENCY
        ? sanitize(update.value + '')
            .replace(/\$/, '')
            .replace(/,/, '')
        : value;
    // Update the transaction_forms to have the updated value
    rootState.transactions.transaction.transaction_forms[
      index
    ].transaction_form_fields[updateIndex] = toUpdate;
    return;
  }
  // Transaction was not defined, so user is creating a transaction and we need to use the templates
  const form = rootState.transactions.templates[index];
  const toUpdate = form.template_fields.find(
    formField =>
      formField.label === update.label || formField.label === update.title
  );
  const updateIndex = form.template_fields.indexOf(toUpdate);
  const { value } = update;
  toUpdate.value =
    toUpdate.type === transactionInputTypes.CURRENCY
      ? sanitize(value)
          .replace(/\$/, '')
          .replace(/,/, '')
      : value;
  // Update the specified template's template_fields
  rootState.transactions.templates[index].template_fields[
    updateIndex
  ] = toUpdate; // Overwrite the old field in the form
};

const countEqual = arr => {
  const completeReducer = (total, { value, type }) => {
    if (!value || value === '$ 0' || type === transactionInputTypes.RELATIVE) return total;
    return total + 1;
  };
  const equalFilter = field => field.type === transactionInputTypes.RELATIVE;

  const total = arr.length;
  const completed = arr.reduce(completeReducer, 0);
  const numRelEq = arr.filter(equalFilter).length;

  return {
    total,
    completed,
    numRelEq,
  };
};

const calculatePercentage = (state, { rootState, index }) => {
  // Count the total number, completed number, and number of 'relative' fields with 'equal' relativity for transaction
  const transaction = rootState.transactions.transaction;
  const { total, completed, numRelEq } = transaction
    ? countEqual(transaction.transaction_forms[index].transaction_form_fields)
    : countEqual(rootState.transactions.templates[index].template_fields);

  // Calculate the percent completed
  state.percentage = Math.ceil((completed / (total - numRelEq)) * 100);
  return;
};

export default {
  reset,
  createForms,
  updateField,
  calculatePercentage,
};
