/*
 * @Author: Ethan Dinnen
 * @Date:   2018-12-18 12:15:22
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2018-12-18 12:28:51
 */

/**
 * Create a personal colour based on full name
 * @param stakeholder {object} The stakeholder to retrieve a colour for
 * @param s {number} The saturation value integer (out of 100)
 * @param l {number} The luminance value integer (out of 100)
 */
export default {
  methods: {
    personalColour(stakeholder, s, l) {
      let hash = 0;
      const { first_name, last_name } = stakeholder.meta;
      const name = `${first_name} ${last_name}`;
      // Compute a unique hash based on name's unicode char values
      for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
      }
      // Get a hue value out of 360 from hash
      let h = hash % 360;
      return `hsl(${h}, ${s}%, ${l}%)`;
    },
  },
};
