<!--
  @Author: Ethan Dinnen
  @Date:   2018-12-11 15:52:47
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-01-14 14:45:51
-->
<template>
  <tr>
    <template v-for="(header, index) in data.headers">
      <th
        class="text-xs-left"
        :key="index"
        @click="setSortBy(header);"
        :class="[
          'column',
          header.sortable ? 'sortable' : '',
          sortOrder ? 'desc' : 'asc',
          header.value === sortField ? 'active' : '',
        ]"
      >
        {{ header.text }}
        <v-icon small v-show="header.sortable === true">arrow_upward</v-icon>
      </th>
    </template>
  </tr>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
    selected: {
      type: Array,
    },
    pageLimit: {
      type: Number,
    },
    setSortBy: {
      type: Function,
    },
    sortField: {
      type: String,
    },
    sortOrder: {
      type: Boolean,
    },
  },
  data() {
    return {
      actions: [
        {
          title: 'Change Stage',
          icon: 'edit',
          path: '/',
        },
        {
          title: 'Start Campaign',
          icon: 'edit',
          path: '/',
        },
        {
          title: 'Delete Selected',
          icon: 'delete',
          func: this.deleteSelected,
        },
      ],
    };
  },
  methods: {
    deleteSelected() {
      this.$emit('click:delete-select');
    },
    selectAll() {
      this.$emit('click:select-all');
    },
  },
};
</script>
