<!--
  @Author: Ethan Dinnen
  @Date:   2018-12-11 15:58:26
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2018-12-11 17:28:12
-->

<template>
  <router-link :to="!plans ? `/${data.id}` : `/plans/${data.id}`">
    {{ data.text }}
  </router-link>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
    plans: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
