import {
  initialTransactionSetupSummary,
  /** DO NOT REMOVE THIS COMMENTED OUT CODE
   * This section is being omited for now and will come Back
   * later when the MLS scope is done
   * **/
  // initialTransactionPropertySummary,
  // initialTransactionCampaignSummary,
  initialTransactionContactsSummary,
} from './InitialValues';
import { Plan } from 'api';
import { PLAN_BY_STAGE_FLAG } from '@/Core/config/constants';
import { formatCurrency } from '@/Core/mixins/formatCurrency';

// Update the summary on the left
const reAssignSummaryValues = function() {
  /**
   * TODO MW - Add these fields back in when MLS scope is done
   * this.transactionPropertySummary,
   * this.transactionCampaignSummary,
   **/
  this.summaryValues = [this.transactionSetupSummary];
  if (this.formSummaries.length) {
    this.summaryValues.push(
      ...this.formSummaries,
    );
  }
  this.summaryValues.push(this.transactionContactsSummary)
};

const setSummaryDone = function() {
  this.transactionSetupSummary.done = true;
  /** DO NOT REMOVE THIS COMMENTED OUT CODE
   * This section is being omited for now and will come Back
   * later when the MLS scope is done
   * **/
  // this.transactionPropertySummary.done = true;
  this.transactionContactsSummary.done = true;
  // this.transactionCampaignSummary.done = true;
};

// Format arrays for select field
const selectFormat = function(obj, isContact) {
  if (isContact)
    return obj.map(item => {
      return {
        text: `${item.first_name} ${item.last_name}`,
        value: item.id,
      };
    });
  return obj.map(item => {
    return {
      text: item.name || item.title || item.role_title,
      value: item.id || item.role_id,
    };
  });
};

/**
 * As this is the last item in the wizard we want to enable
 * the edit button on the left as soon as this section has
 * been loaded
 */
const setCampaignsDone = function() {
  this.transactionCampaignSummary.done = true;
};

const setContactDone = () => {
  this.transactionContactsSummary.done = true;
};

// Set the selected campaign and enable the start button
const handleCampaignSelect = function(campaign) {
  this.selectedCampaignId = campaign;
  this.startCampaignDisabled = false;
};

// Select the contact and enable the role selector
const handleCampaignContact = function(contact) {
  this.disableRoles = false;
  // New contacts don't have an id and instead return the full name as a string
  if (contact.constructor == String) {
    // Find the new contact by name, and format the roles
    this.selectedContactRoles = this.selectFormat(
      this.allContacts.find(c => `${c.first_name} ${c.last_name}` === contact)
        .roles
    );
  } else {
    // Find the existing contact by id, and format the roles
    this.selectedContactRoles = this.selectFormat(
      this.allContacts.find(c => c.id === contact).roles
    );
  }
  // Set the selected contact
  this.selectedContactId = contact;
  this.disableCampaigns = false;
};

const handleStartCampaign = function() {
  const { selectedCampaignId, selectedContactId } = this;

  for (const id of selectedCampaignId) {
    // Assign this campaign to the user
    this.assignCampaign({
      id,
      contact: selectedContactId,
    });

    const line = getCampaignSummaryLine(this, id);
    if (this.transactionCampaignSummary.data.includes(line)) return;
    this.transactionCampaignSummary.data.push(line);
  }

  // Reset values
  this.selectedContactId = null;
  this.disableRoles = true;
  this.disableCampaigns = true;
  this.startCampaignDisabled = true;
};

const initializeCampaignsSummary = function() {
  if (this.transaction.user_tx_stakeholders) {
    this.transaction.user_tx_stakeholders.map(stakeholder => {
      if (stakeholder.campaigns) {
        stakeholder.campaigns.map(campaignId => {
          const line = getCampaignSummaryLine(
            {
              selectedContactId: stakeholder.stakeholder_id,
              allContacts: this.selectedContacts,
              campaigns: this.campaigns,
            },
            campaignId
          );
          if (this.transactionCampaignSummary.data.includes(line)) return;
          this.transactionCampaignSummary.data.push(line);
        });
      }
    });
  }
};

const getCampaignSummaryLine = function(state, selectedCampaignId) {
  const { selectedContactId, allContacts, campaigns } = state;

  // Add the line to the summary
  const campaign = campaigns.find(
    campaign => campaign.id === selectedCampaignId
  ).name;

  if (selectedContactId.constructor !== String) {
    const contact = allContacts.find(
      contact => contact.id === selectedContactId
    );
    const name = `${contact.first_name} ${contact.last_name}`;
    return `${name} - ${campaign}`;
  }
  return `${selectedCampaignId} - ${campaign}`;
};

const handleFormSummary = async function(index, value) {
  await this.updateFinancialsData({ index, value });
  this.reAssignSummaryValues();
};

const handleRemoveContact = function(value) {
  // Remove this contact's rows from the campaign summary if they exist
  if (!this.transactionCampaignSummary) {
    this.removeContact(value);
    return;
  }
  this.transactionCampaignSummary.data = this.transactionCampaignSummary.data.reduce(
    (acc, el) => {
      if (el.includes(`${value.first_name} ${value.last_name}`)) return acc;
      acc.push(el);
      return acc;
    },
    []
  );
  this.removeContact(value);
};

const updateMlsProperty = function(item, page) {
  this.updateProperty(item);
  this.mlsCurrentPage = page;
  this.mlsNumberSelected = item._source.ListingId;

  const valueFormated = formatCurrency(item._source.ListPrice);
  this.setForms({ item, route: this.$route });
  this.updateFinancialsData({
    index: 0,
    value: `Sales Value - ${valueFormated}`,
  });
};

const resetSummaries = function() {
  this.transactionSetupSummary = { ...initialTransactionSetupSummary };
  /** DO NOT REMOVE THIS COMMENTED OUT CODE
   * This section is being omited for now and will come Back
   * later when the MLS scope is done
   * **/
  // this.transactionPropertySummary = {
  //   ...initialTransactionPropertySummary,
  // };
  // this.transactionCampaignSummary = {
  //   ...initialTransactionCampaignSummary,
  // };
  this.transactionContactsSummary = {
    ...initialTransactionContactsSummary,
  };
  this.transactionSetupSummary.data = [];
  // this.transactionPropertySummary.data = [];
  this.resetFinancialsData();
  this.transactionContactsSummary.data = [];
  // this.transactionCampaignSummary.data = [];
};

const handleFinishSteps = function() {
  this.currentStage = 'transaction-creation-loop';
};

const handleStageChange = function(stage) {
  this.activeStage = stage;
};

const handleAnother = async function() {
  // Save the transaction with the passed financials form for creation
  await this.saveTransaction();
  this.createReset();
  this.resetSummaries();
  this.resetFinancials();
  this.currentStage = 'transaction-setup';
  this.mlsNumberSelected = null;
};

const closeStageChangeModal = function() {
  this.stagePlanMixin_showStageChangeModal = false;
  this.updateStage(this.activeStage);
};

const confirmFinish = async function() {
  this.stagePlanMixin_nextStage = this.stage;
  this.stagePlanMixin_lastStage = this.activeStage;
  if (this.nextStageHasPlan && this.activeStage) {
    this.stagePlanMixin_showStageChangeModal = true;
  } else {
    this.submitLoading = true;
    await this.updateTransaction();
    this.goBack();
  }
};

const handleFinish = async function() {
  this.stagePlanMixin_showStageChangeModal = false;
  this.submitLoading = true;
  // saveTransaction generates a set of forms/fields
  // mapped from selected template that need to be
  // passed to the API for BOTH create and update
  // in order to update transaction forms/fields
  // when the template is changed
  await this.saveTransaction();
  if (this.transaction) {
    await this.updateTransaction();
    if (this.stage !== this.activeStage) {
      await this.moveStages({
        from: this.activeStage,
        to: this.stage,
        id: this.transaction.id,
      })
    }
  } else {
    await this.createTransactions();
    if (this.transactionsCreated.length) {
      this.transactionsCreated.forEach(async transaction => {
        const txTypePlans = await Plan.allByType(transaction.transaction_type_id);
        const stagePlan = txTypePlans.find(plan => plan.stage_id === transaction.stage_id);
        if (stagePlan) await Plan.startPlan(stagePlan.id, transaction.id);
      })
    }
  }

  this.goBack();
};

export default {
  handleCampaignContact,
  handleCampaignSelect,
  setCampaignsDone,
  setContactDone,
  selectFormat,
  setSummaryDone,
  reAssignSummaryValues,
  handleStartCampaign,
  handleFormSummary,
  initializeCampaignsSummary,
  handleRemoveContact,
  updateMlsProperty,
  resetSummaries,
  handleFinishSteps,
  handleAnother,
  handleFinish,
  handleStageChange,
  confirmFinish,
  closeStageChangeModal,
};
