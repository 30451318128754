/*
 * @Author: Ethan Dinnen
 * @Date:   2019-01-23 14:33:36
 * @Last modified by:   hugodorea
 * @Last modified time: 2019-03-19T11:52:54-07:00
 */
import { TransactionTypes, Markets, Stages } from 'api';
import router from 'router';
import flatMap from 'lodash/flatMap';

const reset = ({ commit }) => commit('reset');

const resetNotName = ({ commit }) => commit('resetNotName');

const resetStageError = ({ commit }) => commit('resetStageError');

const resetSavedMsg = ({ commit }) => {
  commit('resetSavedMsg');
};

const updateName = ({ commit }, name) => {
  commit('updateName', name);
};

const validateSetup = ({ commit }, valid) => {
  commit('validateSetup', valid);
};

const updateMarket = ({ commit }, market) => {
  commit('updateMarket', market);
};

const setStages = ({ commit }, stages) => {
  commit('setStages', stages);
};

const addNewStage = ({ commit }, stage) => {
  commit('addNewStage', stage);
};

const updateStageType = ({ commit }, values) => {
  commit('updateStageType', values);
};

const moveStageUp = ({ commit }, stage) => {
  commit('moveStageUp', stage);
};

const moveStageDown = ({ commit }, stage) => {
  commit('moveStageDown', stage);
};

const removeStage = async ({ state, commit }, stage) => {
  // If this stage is in the db already
  if (stage.id) {
    // Get it from the database
    const stages = await Markets.getFinancials(state.market, state.type);
    const oldStage = stages.pipeline.summationsByStage.find(
      s => s.title === stage.title
    );
    // Check if it has transactions
    if (oldStage.transactions.length) {
      commit(
        'setStageError',
        'In order to be removed, stages must have no transactions'
      );
      return;
    }
  }
  commit('removeStage', stage);
};

const setTemplates = ({ commit }, templates) => {
  commit('setTemplates', templates);
};

const clearSelection = ({ commit }) => {
  commit('clearSelection');
};

const renameStage = ({ commit }, data) => {
  commit('renameStage', data);
};

const deleteField = ({ commit }, target) => {
  commit('deleteField', target);
};

const deleteFields = ({ commit }, target) => {
  commit('deleteFields', target);
};

const deleteTemplate = ({ commit }, target) => {
  commit('deleteTemplate', target);
};

const addField = ({ commit }, target) => {
  commit('addField', target);
};

const updateField = ({ commit }, target) => {
  commit('updateField', target);
};

const addRelativeField = ({ commit }, target) => {
  commit('addRelativeField', target);
};

const addSection = ({ commit }, position) => {
  commit('addSection', position);
};

const updateTemplateName = ({ commit }, target) => {
  commit('updateTemplateName', target);
};

const updateFieldName = ({ commit }, target) => {
  commit('updateFieldName', target);
};

const moveTemplate = ({ commit }, target) => {
  commit('moveTemplate', target);
};

const moveField = ({ commit }, target) => {
  commit('moveField', target);
};

// API Calls
const getMarkets = async ({ commit }) => {
  const markets = await Markets.get();
  commit('updateMarketList', markets);
};

const getTypes = async ({ commit, state }) => {
  const types = await TransactionTypes.getTypes(state.market);
  commit('updateTypeList', types);
};

const typeSelect = async ({ commit, dispatch, state }, type) => {
  commit('setType', type);
  await dispatch('setSelectedType', type);

  const stages = await Stages.get(state.market, type);
  await commit('setSanitizedStages', stages);

  const templates = await TransactionTypes.getTemplates(state.market, type);
  await dispatch('setTemplates', templates);
};

const getType = async ({ commit, state }, id) => {
  const type = await TransactionTypes.getType(state.market, id);
  const stages = await Stages.get(state.market, id);
  commit('setStages', stages);
  const {
    pipeline: { summationsByStage },
  } = await Markets.getFinancials(state.market, id);
  commit('setTransactionsByStage', summationsByStage);
  const templates = await TransactionTypes.getTemplates(state.market, id);
  commit('setEdit', { type, stages, templates });
};

const setSelectedType = async ({ commit, state }, id) => {
  const type = await TransactionTypes.getType(state.market, id);
  commit('setSelectedType', type);
};

const saveType = async ({ commit, dispatch, state }) => {
  const { stages, market, typeName, templates, editingName, type } = state;
  const completed = templates.length > 0 && stages.length > 0;

  const transactionType = {
    name: typeName,
    market_id: market,
    completed,
  };

  const tmpIdRemoved = templates.map(t => {
    // eslint-disable-next-line
    const { tmp_id, mandatory, ...rest } = t;
    const template_fields = t.template_fields.map(f => {
      // eslint-disable-next-line
      const { tmp_id, ...restFields } = f;
      return restFields;
    });
    return { ...rest, template_fields };
  });

  if (editingName) {
    await TransactionTypes.update(market, type, {
      ...transactionType,
      stages,
      templates: tmpIdRemoved,
    });
    await dispatch('getType', type);
    commit('setSavedMsg', `Successfully saved "${typeName}"`);
    return;
  }
  await TransactionTypes.create(transactionType, stages, tmpIdRemoved, market);
  commit('setSavedMsg', `Successfully saved "${typeName}"`);
};

const moveTransactions = async (
  { state, dispatch },
  { transactions, from, to }
) => {
  for (const t of transactions) {
    await Stages.move(state.market, state.type, t.id, from, to);
  }
  await dispatch('getType', state.type);
};

const deleteTxType = async ({ state }) => {
  // Get the transactions to delete
  const transactions = flatMap(state.transactionsByStage, ({ transactions }) =>
    transactions.map(({ id }) => id)
  );

  // Get the stages to delete
  const stages = state.transactionsByStage.map(s => s.id);

  // Delete the transaction type
  await TransactionTypes.deleteOne(
    state.market,
    state.type,
    stages,
    transactions
  );

  router.push('/');
};

export default {
  reset,
  resetNotName,
  resetStageError,
  resetSavedMsg,
  typeSelect,
  updateName,
  validateSetup,
  getMarkets,
  getTypes,
  getType,
  updateMarket,
  setSelectedType,
  setStages,
  addNewStage,
  updateStageType,
  moveStageUp,
  moveStageDown,
  removeStage,
  saveType,
  clearSelection,
  setTemplates,
  renameStage,
  deleteField,
  deleteFields,
  deleteTemplate,
  addField,
  updateField,
  addRelativeField,
  addSection,
  updateTemplateName,
  updateFieldName,
  moveTemplate,
  moveTransactions,
  moveField,
  deleteTxType,
};
