<!--
@Author: Ethan Dinnen
@Date:   2019-01-08 13:33:36
@Last Modified by:   Ethan Dinnen
@Last Modified time: 2019-02-19 16:45:10
-->
<template>
  <div class="tag-container">
    <div v-if="text" class="standard" :style="`background-color: ${color};`">
      {{ text }}
    </div>
    <v-select
      v-else
      :items="options"
      v-model="value"
      height="25"
      :background-color="color"
      @change="onStageChange"
      id="ld-tag-select"
    />
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: false,
    },
    color: {
      type: String,
      required: false,
      default: '#005F9D',
    },
    options: {
      type: Array,
      required: false,
    },
    selected: {
      type: Number,
      required: false,
      default: null,
    },
    returnLastStage: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      value: this.selected,
      lastSelected: this.selected,
    };
  },
  methods: {
    onStageChange() {
      this.$emit('stage-change',this.value);
    },
  },
  watch: {
    value: {
      handler(newStage, oldStage) {
        this.lastStage = oldStage;
      },
      immediate: true,
    },
    returnLastStage() {
      if (this.returnLastStage) this.value = this.selected;
    }
  },
};
</script>

<style lang="scss">
.tag-container {
  display: inline-block;
  div.standard {
    padding: 4px 12px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
  }
  .v-input {
    height: 25px;
    max-width: unset;
  }
  .v-input__icon {
    i {
      color: white !important;
    }
  }
  .v-text-field {
    padding-top: 0;
    margin-top: 0;
  }
  .v-input__slot {
    margin-bottom: unset;
    border-radius: 3px;
    &::after {
      display: none;
    }
    &::before {
      display: none;
    }
  }
  .v-input input {
    max-width: 10px;
  }
  .v-select__selection {
    max-width: unset;
  }
  .v-select__selection--comma {
    margin: 7px -6px 7px 11px;
    color: white;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }
}
</style>
