<template>
  <svg
    aria-hidden="true"
    focusable="false"
    data-icon="outlook_outline"
    role="img"
    viewBox="0 0 45 40"
  >
    <g id="Group">
        <path d="M31.0344828,10.6666667 L31.0344828,20.2128032 L34.5377048,22.3133935 C34.6300976,22.3390551 34.8302814,22.340889 34.9226741,22.3133935 L50,12.6334495 C50,11.4878396 48.8778136,10.6666667 48.2445416,10.6666667 L31.0344828,10.6666667 Z" id="path3"></path>
        <path d="M31.0364073,22.8569662 L34.2329294,24.9219377 C34.6832522,25.2332218 35.2259491,24.9219377 35.2259491,24.9219377 C34.6851768,25.2332218 50,15.6666667 50,15.6666667 L50,32.989986 C50,34.8757883 48.7163876,35.6666667 47.2730465,35.6666667 L31.0344828,35.6666667 L31.0344828,22.8569662 L31.0364073,22.8569662 Z" id="path5"></path>
        <g id="g23">
            <path d="M15.2432112,18.5678571 C14.2063578,18.5678571 13.3801724,19.0428571 12.7701509,19.9910714 C12.1601293,20.9392857 11.8542026,22.1946429 11.8542026,23.7571429 C11.8542026,25.3428571 12.1601293,26.5964286 12.7701509,27.5178571 C13.3801724,28.4410714 14.1807112,28.9 15.1699353,28.9 C16.1903017,28.9 17,28.4517857 17.5971983,27.5553571 C18.1943966,26.6589286 18.4948276,25.4142857 18.4948276,23.8232143 C18.4948276,22.1642857 18.2053879,20.8732143 17.6246767,19.95 C17.0439655,19.0285714 16.2507543,18.5678571 15.2432112,18.5678571 Z" id="path25"></path>
            <path d="M0.807866379,5.3625 L0.807866379,41.7464286 L29.2022629,47.55 L29.2022629,0.00535714286 L0.807866379,5.3625 Z M19.8082974,29.7053571 C18.6084052,31.2446429 17.0439655,32.0160714 15.1131466,32.0160714 C13.2317888,32.0160714 11.7003233,31.2696429 10.5150862,29.7785714 C9.33168103,28.2857143 8.73814655,26.3428571 8.73814655,23.9464286 C8.73814655,21.4160714 9.33900862,19.3696429 10.5425647,17.8071429 C11.7461207,16.2446429 13.3398707,15.4625 15.3238147,15.4625 C17.1978448,15.4625 18.7146552,16.2089286 19.8705819,17.7053571 C21.0283405,19.2017857 21.607224,21.1732143 21.607224,23.6214286 C21.6090517,26.1375 21.0081897,28.1660714 19.8082974,29.7053571 Z" id="path27"></path>
        </g>
    </g>
</svg>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      default: '$vuetify.icons.outlook_outline',
    },
  },
};
</script>
