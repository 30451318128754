<!--
  @Author: Ethan Dinnen
  @Date:   2019-01-09 13:59:11
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-01-30 13:15:52
-->
<template>
  <v-flex
    class="xs12 md4 wizard-container wizard-loop"
    id="wizard-watch-height"
  >
    <h3 style="font-size: 18px;">Do you want to add another transaction?</h3>
    <v-btn class="loop-btn-fill" @click="handleAnother"
      >ADD ANOTHER TRANSACTION</v-btn
    >
    <v-btn flat class="loop-btn-hollow" @click="handleFinish"
      >FINISH AND EXIT</v-btn
    >
  </v-flex>
</template>

<script>
export default {
  props: {
    handleAnother: {
      type: Function,
      required: true,
    },
    handleFinish: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    setTimeout(() => {
      const summary = document.querySelector('.ld-summary-list');
      if (!summary) return;
      summary.scroll({ top: 100, behavior: 'smooth' });
      setTimeout(() => {
        summary.scroll({ top: 0, behavior: 'smooth' });
      }, 500);
    }, 1000);
  },
};
</script>

<style lang="scss">
.wizard-loop {
  margin: auto 0;
  display: flex;
  flex-direction: column;
  h3 {
    font-size: 18px;
  }
  .loop-btn-fill {
    color: white !important;
    background-color: #3878c1 !important;
    font-weight: 600;
    margin-left: 0;
    width: 245px;
  }
  .loop-btn-hollow {
    border: 1px solid #3878c1;
    color: #3878c1 !important;
    background-color: transparent !important;
    font-weight: 600;
    margin-left: 0;
    width: 150px;
  }
}
</style>
