<template lang="pug">
  v-flex(xs12 md4)
    v-layout(row wrap)
      v-flex(xs12)
        div.header.pb-5 Select Assignee
      v-flex(xs12)
        v-autocomplete(
          v-model="selectedUser"
          label="Assignee"
          :items="completeTeammates"
          :placeholder="selectedUser.text"
          return-object
        )

        div(v-if="isReminderVisible")
          v-checkbox(
            v-model="isReminderChecked"
            label="Set up a reminder"
            color="primary"
          )

          div.subheading.pt-5(v-if="isReminderChecked") Send reminder via

          v-radio-group(
            v-if="isReminderChecked"
            v-model="selectedReminderType"
            row
          )
            v-radio(
              v-for="reminderType in reminderTypes"
              :key="reminderType.value"
              :value="reminderType.value"
              :onIcon="reminderType.icon"
              :offIcon="reminderType.icon"
              color="primary"
            )
</template>
<script>
import { DEFAULT_TEAMMATES, TASK_REMINDER_TYPES } from './../config/actions';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      selectedUser: this.assignee || DEFAULT_TEAMMATES[0],
      searchValue: '',
      isReminderChecked: !!this.reminderType, // Displays the reminder selection
      reminderTypes: TASK_REMINDER_TYPES,
      selectedReminderType: this.reminderType || 'email', // The desired method of reminder, defaults to email
    };
  },

  props: {
    /**
     * Whether the reminder is supposed to be visible
     */
    isReminderVisible: Boolean,
    /**
     * The desired reminder type
     */
    reminderType: String,
    /**
     * The desired assignee
     */
    assignee: Object,
    updateAssignee: Function,
    updateReminder: Function,
  },

  computed: {
    ...mapState('planTasks', [
      'teamMembers'
    ]),
    completeTeammates: {
      get() {
        return [
          { header: 'Select Assignee' },
          ...DEFAULT_TEAMMATES,
          ...this.teamMembers,
        ]
      },
    },
  },

  created() {
    if (!this.selectedUser) {
      this.selectedUser = DEFAULT_TEAMMATES[0];
    }
  },

  watch: {
    // Watchers for the selected user and the prop
    selectedUser: {
      handler(value) {
        this.$emit('input', value);
        this.updateAssignee(value);
      },
      deep: true,
    },
    value: {
      handler(value) {
        this.selectedUser = value;
      },
      deep: true,
    },
    // Watchers for the desired reminder type
    selectedReminderType(value) {
      this.$emit('update:action-reminder', value);
      this.updateReminder(value);
    },
    isReminderChecked(value) {
      const toSend = value ? this.selectedReminderType : '';
      this.$emit('update:action-reminder', toSend);
      this.updateReminder(toSend);
    },
  },

  methods: {
    displayName(item) {
      let displayString = '';
      displayString += item.first_name || '';
      displayString += item.first_name && item.last_name ? ' ' : '';
      displayString += item.last_name || '';
      return displayString;
    },
    /**
     * @param {Array} teammates, The array of Users
     * @returns {Object} The first valid teammate (valid being having a first name)
     *
     * The first valid teammate from the list of teammates
     */
    getFirstValidTeammate(teammates) {
      let counter = 0;
      let currentTeammate = null;
      while (teammates[counter] && !teammates[counter].first_name) {
        currentTeammate = teammates[++counter];
      }
      return currentTeammate;
    },
  },
};
</script>
