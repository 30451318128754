<!--
@Author: Ethan Dinnen
@Date:   2019-03-25 9:54:00
@Last Modified by:   Ethan Dinnen
@Last Modified time: 2019-03-25 17:37:03
-->

<template lang="pug">
  div
    form
      input(
        type="file"
        multiple
        ref="attachments"
        :id="inputId"
        @change="handleFiles"
        style="display: none;"
        :accept="allowedTypes.join(', ')"
      )
    attachment-chips.mt-2(
      :files="uploaded"
      :is-disabled="false"
      :showProgress="true"
      @removeAttachment="handleRemoveAttachment"
    )
</template>

<script>
import axios from 'axios';
import AttachmentChips from './AttachmentChips';

const MAX_FILE_SIZE_BYTES = 15728640;

export default {
  components: {
    AttachmentChips,
  },
  props: {
    inputId: {
      type: String,
      required: true,
    },
    files: {
      type: Array,
      required: true,
      default: () => [],
    },
    folderId: {
      type: Number,
      default: 0,
    },
    getAccessToken: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      uploaded: [],
      allowedTypes: [
        // Documents
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/msword',
        // Powerpoint
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        // Videos
        'video/quicktime',
        'video/mp4',
        // Images
        'image/jpeg',
        'image/png',
        'image/gif',
      ],
    };
  },
  watch: {
    files(value) {
      if (value.length) return;
      /**
       * Clear uploaded array of completed files if passed file array is empty
       * This keeps the component up to date when emails are sent as the files
       * store array is cleared on send
       */
      this.uploaded = this.uploaded.reduce((acc, file) => {
        if (file.progress !== 100) return [...acc, file];
        return acc;
      }, []);
    },
  },
  methods: {
    // Remove an item from a passed array
    remove(arr, id) {
      return arr.filter(val => val !== id);
    },
    // Initiate file uploads
    handleFiles() {
      // Get the list of files from the input
      const { files } = this.$refs.attachments;

      // Loop over the files
      Object.values(files).map(file => {
        // If file size is over 15MB in binary byte representation
        if (file.size > MAX_FILE_SIZE_BYTES) {
          this.$refs.attachments.value = ''; // Reset the input
          this.$emit('error', file);
          return null;
        }

        // If the user has already uploaded a file with this name during this session
        if (this.uploaded.find(f => f.name === file.name)) {
          this.$refs.attachments.value = ''; // Reset the input
          return null;
        }

        this.uploadFile(file);

        return null;
      });
      this.$refs.attachments.value = ''; // Reset the input
    },
    async uploadFile(file) {
      // Add this file to the uploaded array
      this.uploaded = [
        ...this.uploaded,
        {
          name: file.name,
          type: file.type,
          size_bytes: file.size,
          progress: 0,
        },
      ];
      // Create new form to upload
      const formData = new FormData();
      formData.set('attachments', file);
      formData.set('folder_id', this.folderId);
      const that = this;

      const { access_token } = await this.getAccessToken();

      // Send the file to API
      axios
        .post(`${process.env.PLATFORM_APP_URL}/files`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${access_token}`,
          },
          onUploadProgress: e => {
            const item = { ...that.uploaded.find(f => f.name === file.name) };
            const i = that.uploaded.findIndex(f => f.name === file.name);
            item.progress = (e.loaded / e.total) * 100;
            that.uploaded = [
              ...that.uploaded.slice(0, i),
              item,
              ...that.uploaded.slice(i + 1),
            ];
          },
        })
        .then(res => {
          const { id, name } = res.data;
          const i = this.uploaded.findIndex(f => f.name === name);
          this.uploaded[i].id = id;
          this.$emit('change', this.filterNoIds(this.uploaded));
        })
        .catch(() => {
          this.$refs.attachments.value = '';
          this.$emit('error', file);
        });

      // TODO: Uncomment this once CRM accepts our JWT
      // axios
      //   .post('https://api-v2.liondesk.com/files', formData, {
      //     headers: {
      //       'Content-Type': 'multipart/form-data',
      //     },
      //     progress(e) {
      //       const item = { ...that.uploaded.find(f => f.name === file.name) };
      //       const i = that.uploaded.findIndex(f => f.name === file.name);
      //       item.progress = (e.loaded / e.total) * 100;
      //       that.uploaded = [
      //         ...that.uploaded.slice(0, i),
      //         item,
      //         ...that.uploaded.slice(i + 1),
      //       ];
      //     },
      //   })
      //   .then(res => {
      //     const { id, name } = res.data;
      //     const i = this.uploaded.findIndex(f => f.name === name);
      //     this.uploaded[i].id = id;
      //     this.$emit('change', this.filterNoIds(this.uploaded));
      //   })
      //   .catch(err => {
      //     this.$refs.attachments.value = '';
      //     this.$emit('error', file);
      //   });
    },
    handleRemoveAttachment(name) {
      const i = this.uploaded.findIndex(f => f.name === name);
      this.uploaded = [
        ...this.uploaded.slice(0, i),
        ...this.uploaded.slice(i + 1),
      ];
      this.$emit('change', this.filterNoIds(this.uploaded));
    },
    filterNoIds(uploaded) {
      return uploaded.filter(file => file.id);
    },
  },
};
</script>
