<template lang="pug">
  v-flex.px-0(xs12)
    v-expansion-panel.ld-shallow-tree-view(
        v-model="openPanels"
        expand
      )
      v-expansion-panel-content(
        v-for="(item, i) in items"
        :disabled="!item.counts[lookingForTemplateType]"
        :key="i"
        expand-icon=""
        @click.native="handleClickItem(item)"
      )
        template(slot="header")
          div(:class="{ 'is-selected': item.id === desiredTemplate.template_folder_id }")
            v-layout.expansion-item.folder.ma-0.pa-1(
              v-if="item.children"
              row
              :class="[lookingForClass, elevationClass(item.counts[lookingForTemplateType])]"
            )
              v-flex.folder__icon(shrink)
                  v-icon folder
              v-flex.folder__name(grow) {{ item.name }}
              v-flex.folder__template-counts(
                shrink
                v-for="templateType in templateTypes"
                :key="templateType"
              )
                span(
                  :class="[{ 'has-templates': !!item.counts[templateType] }, wrapperClass(templateType)]"
                )
                  span.template-count.mr-1(
                    :class="'template-count--'+templateType.toLowerCase()"
                  ) {{ item.counts[templateType] }}
                  v-icon {{ icon(templateType) }}

        v-card-text(v-if="item.children")
          v-list
            v-list-tile.leaf(
              v-for="child in item.children"
              :key="child.id"
              avatar
              @click="handleClickChild(child)"
              :class="{ 'is-selected': child.id === desiredTemplate.id }"
            )
              v-list-tile-action.template-type
                v-icon {{ templateTypeIcon[child.template_type] }}
              template(v-if="child.files.length")
                v-list-tile-action
                  v-icon attach_file
                v-list-tile-content.number-of-attachments.shrink
                  | {{ child.files.length }}
              template(v-else)
                v-list-tile-action
                v-list-tile-content.number-of-attachments.shrink
              v-list-tile-content
                span.text-truncate.expand-width {{ child.name }}
              v-list-tile-avatar(
                v-if="hasPreview"
                @click.stop="handleClickPreview(child)"
              )
                v-icon remove_red_eye
</template>
<script>
import { templateTypeIcon } from '../config/actions';

export default {
  data() {
    return {
      /**
       * Array of Booleans indicating which panels are open
       */
      openPanels: [],
      templateTypeIcon,
      items: [],
      templateTypes: ['Email', 'Text'],
    };
  },
  props: {
    /**
     * Boolean that toggles whether the preview icon should be shown
     */
    hasPreview: {
      type: Boolean,
      default: false,
    },
    /**
     * The ID stating which item is currently active
     */
    desiredTemplate: {
      type: Object,
      default: () => {},
    },
    lookingForTemplateType: {
      type: String,
    },
    /**
     * The ID stating which item is currently active
     */
    active: {
      type: Array,
      default: () => [],
    },
    /**
     * Controls whether the folders are clickable
     */
    foldersDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * The array of top-level objects
     */
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    lookingForClass() {
      return `looking-for-${this.lookingForTemplateType.toLowerCase()}`;
    },
    lookingForTemplateTypeLower() {
      return this.lookingForTemplateType.toLowerCase();
    },
  },
  mounted() {
    this.items = this.value;
    this.checkExpansion(this.value);
  },
  watch: {
    value: {
      handler(value) {
        this.items = value;
      },
      deep: true,
    },
    active: {
      handler() {
        this.checkExpansion(this.items);
      },
      deep: true,
    },
  },
  methods: {
    elevationClass(count) {
      return count ? 'elevation-2' : 'elevation-0';
    },

    wrapperClass(templateType) {
      return `${templateType.toLowerCase()}-wrapper`;
    },

    /**
     * Converts Text or Email to the icon name
     *
     * @param  {String} templateType Text, Email, etc
     * @return {String} - the icon
     */
    icon(templateType) {
      return templateTypeIcon[templateType];
    },

    /**
     * Generates an array of booleans, which is true if it has content
     * Otherwise, input false.
     */
    checkExpansion(items) {
      const openPanels = [];
      if (items && items.length) {
        items.forEach(item => {
          // Push true if ID matches he given active prop
          openPanels.push(this.active.includes(item.id));
        });
        this.openPanels = openPanels;
      }
    },
    handleClickChild(child) {
      this.$emit('child-clicked', child);
    },
    handleClickItem(item) {
      // This is required because we're using click.native on the expansion panel
      // This means that even though we 'disabled' the expansion panel,
      // it still registers the click
      if (!this.foldersDisabled) {
        this.$emit('item-clicked', item);
      }
    },
    handleClickPreview(child) {
      this.$emit('child-preview-clicked', child);
    },
  },
};
</script>
<style lang="scss">
.ld-shallow-tree-view {
  box-shadow: none;
  .v-expansion-panel__container {
    border: none !important;
    margin-bottom: 8px;
    &--active {
      .folder,
      .folder > .v-icon {
        color: var(--v-primary-base);
      }
    }
    .v-expansion-panel__header {
      padding: 0;
      .expansion-item {
        padding: 12px !important;
        .folder__name {
          flex: 1;
          white-space: normal;
          font-size: 16px; // to match children
        }

        // For text
        &.looking-for-email .email-wrapper.has-templates,
        &.looking-for-text .text-wrapper.has-templates {
          color: var(--v-info-darken1);
          &.has-templates i {
            color: var(--v-info-darken1);
          }
        }

        .folder__template-counts {
          color: var(--v-secondary-lighten3);
          i {
            color: var(--v-secondary-lighten4);
          }
          .template-count {
            width: 23px;
            margin-right: 4px;
            display: inline-block;
            text-align: right;
          }
        }
      }
    } // Controls the padding for child components
    .v-expansion-panel__body {
      font-size: 15px;
      color: var(--v-background-lighten1);

      &.expand-transition-enter-active,
      &.expand-transition-enter-to,
      &.expand-transition-leave-active,
      &.expand-transition-leave-to {
        .leaf {
          &:before,
          &:not(:last-child):after {
            border: solid 0 white; // allows for fade in from white
          }
        }
      }
      .leaf {
        padding-left: 24px;
        &:before,
        &:not(:last-child):after {
          content: '';
          position: absolute;
          height: 20px;
          width: 12px;
          margin-left: -12px;
          transition: 200ms; // fade in
        }
        &:before {
          border-left: solid 2px var(--v-background-lighten3);
          border-bottom: solid 2px var(--v-background-lighten3);
        }
        &:not(:last-child):after {
          margin-top: -20px;
          border-left: solid 2px var(--v-background-lighten3);
        }

        .v-list__tile__action {
          min-width: 25px;
          margin-left: 5px;
          &.template-type {
            margin-left: 0;
          }
        }
        .v-list__tile__content.number-of-attachments {
          width: 25px;
          min-width: 25px;
        }
      }
    }
  }
  .v-expansion-panel__header {
    padding: 0;
  } // Controls the padding for child components
  li {
    background: none !important;
  }
  .v-list {
    padding-top: 0;
    background: none;
  }
  .v-list__tile--avatar {
    height: 40px;
  }
  .v-card__text {
    padding: 12px;
  }
  .is-selected,
  .is-selected .theme--light.v-icon,
  .is-selected .v-list__tile__action > i,
  .is-selected .v-avatar > .theme--light.v-icon {
    color: var(--v-primary-base) !important;
  }
  .expand-width {
    width: 100%;
  }
}
</style>
