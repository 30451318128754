<template>
  <v-container>
    <v-layout class="similarListings">
      <v-flex v-for="similarListing in similarListings" :key="similarListing.id" xs4>
        <mls-listing-card :listing="similarListing" :onItemClicked="onItemClicked" noHover />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import MlsListingCard from './MlsListingCardGridContainer';

export default {
  components: {
    MlsListingCard,
  },
  name: 'SimilarListings',
  props: {
    listing: {
      type: Object,
      require: true,
    },
    resultsSize: {
      type: Number,
      default: 5,
    },
    onItemClicked: {
      type: Function,
      default: () => {
        // eslint-disable-next-line no-alert
        alert('Calback not implemented for Item Clicked. [MlsSimilarListings.vue]');
      },
    },
  },
  computed: {
    ...mapState({
      similarListings: state => state.listing.similarListings,
    }),
  },
  created() {
    this.findSimilar({listing: this.listing, resultsSize: this.resultsSize});
  },
  methods: {
    ...mapActions({
      findSimilar: 'listing/findSimilar',
    }),
  },
};
</script>
<style lang="scss">
.similarListings {
  overflow-x: auto;
  .flex {
    margin: 20px;
  }
  .listing-card {
    width: 400px;
  }
}
</style>
