<template>
  <v-container class="create-plan-container">
    <v-btn flat="flat" @click="goBack" class="back-button">
      <v-icon>keyboard_arrow_left</v-icon> Back
    </v-btn>
    <v-form v-model="valid">
      <TextInput
        test-id="tx-plan-name"
        title="Title"
        placeholder="Enter Plan Name"
        :entered="title"
        @onInput="updateTitle"
        required
        :rules="titleRules"
      />
      <SelectInput
        :disabled="selectDisabled"
        :options="typeOptions"
        :selected="type"
        placeholder="Select Type"
        @onInput="updateType"
      />
      <SelectInput
        :disabled="selectStageDisable"
        :options="stageOptions"
        :selected="stage"
        placeholder="Select Stage"
        @onInput="updateStagePlan"
      />
      <v-btn color="primary" @click="addAction" :disabled="disableSubmitButton">
        Add an action
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
import TextInput from '@/Core/components/TextInput';
import SelectInput from '@/Core/components/SelectInput';
import { PLAN_BY_STAGE_FLAG } from '@/Core/config/constants';
import { createNamespacedHelpers } from 'vuex';
import router from 'router';


export default {
  components: {
    TextInput,
    SelectInput,
  },
  props: {
    title: String,
    titleRules: Array,
    updateTitle: Function,
    updateType: Function,
    updateStagePlan: Function,
    types: Array,
    type: [Number, String],
    stages: Array,
    stage: [Number, String],
  },
  data() {
    return {
      valid: false,
    };
  },
  computed: {
    selectDisabled() {
      if (this.title) return false;
      return true;
    },
    typeOptions() {
      if (!this.types) return [];
      return this.types.map(type => {
        return { text: type.name, value: type.id };
      });
    },
    selectStageDisable() {
      if (this.title && this.type) return false;
      return true;
    },
    stageOptions() {
      if (!this.stages) return [];
      return this.stages.map(stage => {
        return { text: stage.title, value: stage.id };
      });
    },
    disableSubmitButton() {
      return !this.valid || !this.type || !this.stage;
    },
  },
  methods: {
    goBack() {
      window.history.length > 1 ? router.go(-1) : router.push('/plans');
    },
    addAction() {
      router.push('/plans/create/action');
    },
  },
};
</script>

<style lang="scss">
.create-plan-container {
  margin-top: 5px;
  .back-button {
    color: var(--v-primary-base) !important;
    position: relative;
    right: 20px;
    .text {
      font-size: 12px;
      font-weight: 400;
    }
    i {
      font-size: 19px;
    }
  }
}
</style>
