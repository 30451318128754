<template>
    <LdConfirmation
      v-model="showStageChangeModal"
      width="580"
      @click:cancel="closeStageChangeModal"
      @click:ok="confirmStageChange"
      confirmText="Continue"
    >
      <template slot="header">
        Change Transaction Stage?
      </template>
      <template slot="body">
        <template v-if="!isLoading">
          If you continue the transaction stage will pass from {{currentStageName}} to {{nextStageName}}. 
          Activating {{nextPlanName}} to the transaction, and consequently all pending tasks 
          from the previous plan {{currentPlanName}} will be marked as complete.
        </template>
        <v-layout v-else justify-center>
          <v-progress-circular
            :size="100"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-layout>
      </template>
    </LdConfirmation>
</template>

<script>
import { LdConfirmation } from 'lion-vue';
import { createNamespacedHelpers } from 'vuex';

export default {
    components: {
    LdConfirmation,
  },
  props: {
    showStageChangeModal: {
      type: Boolean,
      required: true,
    },
    currentStageName: {
      type: String,
    },
    nextStageName: {
      type: String,
    },
    nextPlanName: {
      type: String,
    },
    currentPlanName: {
      type: String,
    },
    closeStageChangeModal: {
      type: Function,
      required: true,
    },
    confirmStageChange: {
      type: Function,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
