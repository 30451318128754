/*
 * @Author: Ethan Dinnen
 * @Date:   2018-12-04 12:05:03
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-01-07 12:13:44
 */
import axios from 'axios';

/**
 * Get a single campaign by id
 * @param id The campaign ID
 */
export const get = async id => {
  return await axios.get(`campaigns/${id}`).then(res => res.data);
};

/**
 * Get all campaigns
 */
export const getAll = async () => {
  return await axios.get('campaigns').then(res => res.data);
};

/**
 * Search the campaigns by campaign name
 * @param q The search query string
 */
export const search = async q => {
  return await axios.get(`campaigns?name=${q}`).then(res => res.data);
};

/**
 * Start a campaign for a contact
 * @param id The campaign id
 * @param contact The id for the contact this campaign should be started for
 */
export const start = async (id, contact) => {
  return await axios
    .post(`campaigns/${id}`, { contact })
    .then(res => res.data);
};
