<!--
  @Author: Ethan Dinnen
  @Date:   2018-12-14 9:59:30
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-01-15 12:50:54
-->
<template>
  <v-layout align-space-between justify-space-between fill-height row>
    <v-flex>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="items"
          header-text="text"
          header-key="value"
          :loading="isLoading"
          :total-items="mutablePagination.totalItems"
          @update:pagination="handlePagination"
          :pagination.sync="mutablePagination"
          :rows-per-page-items="rowsPerPageItems"
          select-all
          item-key="id"
          v-model="selected"
          hide-actions
        >
          <template slot="headers" slot-scope="items">
            <ListHeader
              :data="items"
              :selected="selected"
              @click:select-all="selectAll"
              :pageLimit="mutablePagination.rowsPerPage"
              :setSortBy="handleSort"
              :sortField="mutablePagination.sortBy"
              :sortOrder="mutablePagination.descending"
            />
          </template>
          <template slot="items" slot-scope="data">
            <ListData
              :data="data"
              :selected="selected"
              @click:expand="data.expanded = !data.expanded"
              @click:select-one="selectOne"
              :taskActions="taskActions"
              :toggleComplete="toggleComplete"
              :deleteTask="deleteTask"
              :deleteCommunication="deleteCommunication"
            />
          </template>
        </v-data-table>
        <v-layout justify-center>
          <v-pagination
            :length="totalPages"
            v-model="mutablePagination.page"
            @input="handlePageChange"
            :total-visible="7"
            v-show="totalPages > 1"
            class="pa-1"
          ></v-pagination>
        </v-layout>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import ListData from './ListData';
import ListHeader from './ListHeader';
import TableMethods from '@/Core/mixins/TableMethods';

export default {
  components: {
    ListData,
    ListHeader,
  },
  props: {
    headers: Array,
    items: Array,
    rowsPerPageItems: Array,
    handlePagination: Function,
    isLoading: Boolean,
    pagination: Object,
    taskActions: Boolean,
    toggleComplete: {
      type: Function,
      default: () => {},
    },
    deleteTask: {
      type: Function,
      default: () => {},
    },
    deleteCommunication: {
      type: Function,
      default: () => {},
    },
  },
  mixins: [TableMethods],
  data() {
    return {
      selected: [],
      mutablePagination: this.pagination,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(
        this.mutablePagination.totalItems / this.mutablePagination.rowsPerPage
      );
    },
  },
};
</script>
