<template lang="pug">
div
  div(@click="openModal")
    slot(name="activator")
  v-dialog.d-inline(v-model="isModalVisible" lazy persistent max-width="480")
    v-card.card(v-if="!isPlanStarted" v-bind:class="{ loading: loading }")
      title-bar(dark) Start Transaction Plan
      v-progress-linear.my-0(v-if="loading" indeterminate)
      v-card-text.pa-4
        v-autocomplete.autocomplete(
          solo
          flat
          clearable
          no-filter
          hide-selected
          prepend-inner-icon="search"
          :search-input.sync="search"
          :items="searchPlanItems"
          placeholder="Search plans"
          :loading="loading"
          item-text="title"
          item-value="id"
          v-model="selectedPlan"
        )
      v-card-actions.card-actions
        v-spacer
        v-btn(flat @click="closeModal") Cancel
        v-btn.primary(@click="submitStartPlan" :disabled='!selectedPlan || loading') Start Plan
    v-card.card(v-else)
      title-bar(dark) Plan already started
      v-card-text.px-4
        p.mb-4.mt-2 The following plan has been started for this transaction:
        ul.mb-4
          li {{ startedPlanTitle }}
      v-card-actions.card-actions
        v-spacer
        v-btn.primary(@click="closeModal") Ok
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { Transactions } from 'api';
import TitleBar from '@/Core/components/TitleBar';
import Modal from '@/Core/mixins/modal';
import { debounce } from '@/Core/utilities';

const {
  mapState: mapTransactionsState,
  mapActions: mapTransactionsActions,
  mapGetters: mapTransactionsGetters,
  mapMutations: mapTransactionsMutations,
} = createNamespacedHelpers('transactions');

export default {
  components: {
    TitleBar,
  },
  mixins: [Modal],
  data() {
    return {
      search: null,
      selectedPlan: null,
      showError: false,
      loading: false,
      startedPlan: null,
      transactionTypeId: null,
    };
  },
  props: {
    selectedTransactionId: {
      type: Number,
    },
    query: {
      type: Object,
    },
  },
  computed: {
    ...mapTransactionsGetters([
      'searchPlanItems',
    ]),
    startedPlanTitle() {
      const plan = this.searchPlanItems.find(plan =>{
        return plan.id === this.startedPlan;
      });
      if (plan) return plan.title;
    },
    isPlanStarted() {
      return !!this.startedPlan;
    }
  },
  watch: {
    /**
     * Watcher on the search value.
     * @param  {String} value the search string
     */
    search(search) {
      debounce(this.prepareRequest, [{title: search, typeId: this.transactionTypeId}, this.findPlans]);
    },
    async isModalVisible() {
      if (this.isModalVisible) {
        this.loading = true;
        const { transaction_type_id, meta } = await Transactions.get(this.selectedTransactionId);
        this.startedPlan = meta.plan;
        this.transactionTypeId = transaction_type_id;
        await this.findPlans({ title: null, typeId: transaction_type_id});
        this.loading = false;
      } else {
        this.startedPlan = null;
        this.selectedPlan = null;
        this.clearPlansSearchResults();
      }
    },
  },
  methods: {
    ...mapTransactionsActions([
      'getPlans',
      'findPlans',
      'getTransaction',
      'startPlan',
    ]),
    ...mapTransactionsMutations([
      'clearPlansSearchResults',
    ]),
    async submitStartPlan() {
      this.loading = true;
      await this.startPlan({ txId: this.selectedTransactionId, planId: this.selectedPlan });
      this.loading = false;
      this.$emit('on-plan-started');
      this.closeModal();
    },
    prepareRequest(search, callback) {
      if (typeof callback !== 'function') {
        console.error('Callback is not a function');
      } else {
        callback(search);
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.card{
  &.loading{
    .autocomplete, .card-actions {
      opacity: 0.25;
      pointer-events: none;
    }
  }
  .card-actions {
    padding: 24px;
    border-top: 1px solid var(--v-background-lighten4);
  }
}
</style>