/*
 * @Author: Hugo Dorea
 * @Date:   2018-11-05 10:54:39
 * @Last modified by:   kleberpinel
 * @Last modified time: 2019-01-31T13:05:14-08:00
 */

import Vue from 'vue';
import Router from 'vue-router';

import Transaction from 'transactionsComponents';
import Pipeline from 'transactionsComponents/Pipeline';
import CreateTransaction from 'transactionsComponents/Create';
import EditTransaction from 'transactionsComponents/Edit';
import ViewTransaction from 'transactionsComponents/ViewTransaction';
import CreateTxType from 'transactionsComponents/TransactionTypeWizard';
import Plans from 'transactionsComponents/Plans';
import CreatePlan from 'transactionsComponents/CreatePlan';
import CreatePlanAction from 'transactionsComponents/CreatePlanAction';
import EditPlan from 'transactionsComponents/EditPlan';
import { Auth } from 'api';
import {
  routes,
  HC_PROPS,
  initHCProps,
} from 'lion-vue';

let coreRoutes = [];
if (process.env.NODE_ENV !== 'testing') {
  initHCProps({
    ACCOUNTS_APP_URL: `${process.env.LOGIN_URL}`,
    TRANSACTIONS_APP_URL: `${process.env.TRANSACTIONS_APP_URL}`,
    MLS_APP_URL: `${process.env.MLS_APP_URL}`,
  });
  coreRoutes = routes(HC_PROPS);
}

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    ...coreRoutes,
    { 
      path: '/', 
      component: Transaction, 
      name: 'transactions-home',
      meta: { getUserInfo: true },
    },
    { 
      path: '/view/:type_id/:filter', 
      component: Transaction,
      meta: { getUserInfo: true },
    },
    { 
      path: '/pipelines', 
      component: Pipeline,
      meta: { getUserInfo: true },
    },
    { 
      path: '/pipelines/:type_id/:id', 
      component: Pipeline,
      meta: { getUserInfo: true },
    },
    { 
      path: '/plans', 
      component: Plans,
      meta: { getUserInfo: true },
    },
    { 
      path: '/plans/create', 
      component: CreatePlan,
      meta: { getUserInfo: true },
    },
    { 
      path: '/plans/create/action', 
      component: CreatePlanAction,
      meta: { getUserInfo: true },
    },
    { 
      path: '/plans/create/action/:id', 
      component: CreatePlanAction,
      meta: { getUserInfo: true },
    },
    { 
      path: '/plans/edit/action/:planId/:taskId', 
      component: CreatePlanAction,
      meta: { getUserInfo: true },
    },
    { 
      path: '/plans/:id', 
      component: EditPlan,
      meta: { getUserInfo: true },
    },
    { 
      path: '/create', 
      component: CreateTransaction,
      meta: { getUserInfo: true },
    },
    { 
      path: '/create/type', 
      component: CreateTxType,
      meta: { getUserInfo: true },
    },
    { 
      path: '/create/type/:id', 
      component: CreateTxType,
      meta: { getUserInfo: true },
    },
    { 
      path: '/edit/:id', 
      component: EditTransaction,
      meta: { getUserInfo: true },
    },
    { 
      path: '/:id', 
      component: ViewTransaction,
      meta: { getUserInfo: true },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  await Auth.validate();
  next();
});

export default router;
