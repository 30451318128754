export const searchTypes = {
  SAVED_SEARCHES: 'saved_searches',
};

export const searchTypesAliases = {
  polygon: 'Saved Area',
  saved_searches: 'Saved Search',
  mls_number: 'MLS Number',
  city: 'City',
  address: 'Address',
  postal_code: 'Postal Code',
};

export const minListPriceConditions = {
  field: 'ListPrice',
  operator: '$gte',
  type: 'integer',
  static: true,
};
export const minListPriceOptions = [
  { value: 0, name: '0+' },
  { value: 200000, name: '$200,000+' },
  { value: 300000, name: '$300,000+' },
  { value: 400000, name: '$400,000+' },
  { value: 600000, name: '$600,000+' },
  { value: 800000, name: '$800,000+' },
  { value: 1000000, name: '$1,000,000+' },
  { value: 1500000, name: '$1,500,000+' },
];

export const maxListPriceConditions = {
  field: 'ListPrice',
  operator: '$lte',
  type: 'integer',
  static: true,
};

export const maxListPriceOptions = [
  { value: null, name: 'Any Price' },
  { value: 250000, name: '$250,000' },
  { value: 300000, name: '$300,000' },
  { value: 350000, name: '$350,000' },
  { value: 400000, name: '$400,000' },
  { value: 450000, name: '$450,000' },
  { value: 500000, name: '$500,000' },
  { value: 550000, name: '$550,000' },
];

export const bedroomsTotalConditions = {
  field: 'BedroomsTotal',
  operator: '$gte',
  type: 'integer',
  static: true,
};

export const bedroomsTotalOptions = [
  { value: null, name: 'Any' },
  { value: 0, name: '0+' },
  { value: 1, name: '1+' },
  { value: 2, name: '2+' },
  { value: 3, name: '3+' },
  { value: 4, name: '4+' },
  { value: 5, name: '5+' },
];

export const bathroomsTotalConditions = {
  field: 'BathroomsTotal',
  operator: '$gte',
  type: 'integer',
  static: true,
};

export const bathroomsTotalOptions = [
  { value: null, name: 'Any' },
  { value: 0, name: '0+' },
  { value: 1, name: '1+' },
  { value: 2, name: '2+' },
  { value: 3, name: '3+' },
  { value: 4, name: '4+' },
];

export const homeTypeOptions = ['Residential', 'Condominium'];

export const buildingAreaTotalConditions = {
  field: 'BuildingAreaTotal',
  operator: '$gte',
  type: 'integer',
  static: true,
};

export const buildingAreaTotalOptions = [
  { value: null, name: 'Any' },
  { value: 500, name: '500+ sq ft' },
  { value: 750, name: '750+ sq ft' },
  { value: 1000, name: '1,000+ sq ft' },
  { value: 1250, name: '1,250+ sq ft' },
  { value: 1500, name: '1,500+ sq ft' },
  { value: 1750, name: '1,750+ sq ft' },
  { value: 2000, name: '2,000+ sq ft' },
  { value: 2250, name: '2,250+ sq ft' },
  { value: 2500, name: '2,500+ sq ft' },
  { value: 2750, name: '2,750+ sq ft' },
  { value: 3000, name: '3,000+ sq ft' },
  { value: 3250, name: '3,250+ sq ft' },
  { value: 3500, name: '3,500+ sq ft' },
];

export const daysOnMarketConditions = {
  field: 'DaysOnMarket',
  operator: '$lte',
  type: 'integer',
  static: true,
};
export const daysOnMarketOptions = [
  { value: null, name: 'Any' },
  { value: 1, name: '1 day' },
  { value: 7, name: '7 days' },
  { value: 14, name: '14 days' },
  { value: 30, name: '30 days' },
  { value: 90, name: '90 days' },
];

export const standardStatusOptions = [
  { value: 'Active', name: 'Active' },
  { value: 'Contingent', name: 'Contingent' },
  { value: 'Conditionally Withdrawn', name: 'Conditionally Withdrawn' },
  { value: 'Expired', name: 'Expired' },
  { value: 'Partial Save', name: 'Partial Save' },
  { value: 'Pending', name: 'Pending' },
  { value: 'Sold', name: 'Sold' },
  { value: 'Unconditionally Withdrawn', name: 'Unconditionally Withdrawn' },
];

export const standardStatusConditions = {
  field: 'StandardStatus',
  operator: '$or_in',
  type: 'array',
  static: true,
};

export const propertyTypeOptions = [
  { value: 'Commercial', name: 'Commercial' },
  { value: 'Vacant Land', name: 'Vacant Land' },
  { value: 'Residential', name: 'Residential' },
  { value: 'Multi-family', name: 'Multi-family' },
];

export const propertyTypeConditions = {
  field: 'PropertyType',
  operator: '$or_in',
  type: 'array',
  static: true,
};

export const findCallers = {
  INDEX_BEFORE_MOUNT: 'index_before_mount',
  MAP_DRAG_SEARCH: 'map_drag_search',
};

// We need to to revisit this one later because we need to map
// ListAgentKey accordingly for RETS boards
// export const listAgentKeyConditions = {
//   field: 'ListAgentKey',
//   operator: 'iLike',
//   type: 'text',
//   static: true,
// };

export const initialStaticConditions = () => {
  return {
    minListPrice: { ...minListPriceOptions[0], ...minListPriceConditions },
    maxListPrice: { ...maxListPriceOptions[0], ...maxListPriceConditions },
    bedroomsTotal: { ...bedroomsTotalOptions[0], ...bedroomsTotalConditions },
    bathroomsTotal: { ...bathroomsTotalOptions[0], ...bathroomsTotalConditions },
    buildingAreaTotal: { ...buildingAreaTotalOptions[0], ...buildingAreaTotalConditions },
    daysOnMarket: { ...daysOnMarketOptions[0], ...daysOnMarketConditions },
    standardStatus: { value: [standardStatusOptions[0].value], ...standardStatusConditions },
    propertyType: { value: [propertyTypeOptions[2].value], ...propertyTypeConditions },
    // We need to to revisit this one later because we need to map
    // ListAgentKey accordingly for RETS boards
    // listAgentKey: { value: '', ...listAgentKeyConditions },
  };
};
