<template>
  <div class="ld-dismissable-banner">
    <v-layout justify-center align-center :style="{ 'background-color': color.background }">
      <v-icon light class="pr-2">{{ error ? 'error_outline' : 'warning' }}</v-icon>
      <span v-html="text"></span>
      <template v-if="customNumberText">
        <a
          :href="customNumberLink"
          class="v-btn v-btn--flat"
          :style="{ color: color.foreground }"
        >{{ customNumberText }}</a>
        <div class="divider"></div>
      </template>
      <template v-if="upgradeText && upgradeText !== 'Continue'">
        <a
          :href="upgradeLink"
          class="v-btn v-btn--flat"
          :style="{ color: color.foreground }"
        >{{ upgradeText }}</a>
        <div class="divider"></div>
      </template>
      <template v-if="upgradeText && upgradeText === 'Continue'">
        <v-btn
          class="v-btn v-btn--flat"
          :style="{ color: color.foreground }"
          @click="$emit('accept', feature)"
        >{{ upgradeText }}</v-btn>
        <div class="divider"></div>
      </template>
      <v-btn flat dark :color="color.foreground" @click="$emit('input', false)">
        Dismiss
        <v-icon>close</v-icon>
      </v-btn>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: null,
    },
    upgradeText: {
      type: String,
      default: '',
    },
    customNumberText: {
      type: String,
      default: '',
    },
    upgradeLink: {
      type: String,
      default: '',
    },
    customNumberLink: {
      type: String,
      default: '',
    },
    feature: {
      type: String,
      default: '',
    }
  },
  computed: {
    color() {
      return {
        foreground: this.error ? '#A64951' : '#3878C0',
        background: this.error ? '#F1D4D7' : '#DAE2ED',
      };
    },
  },
};
</script>
<style lang="scss">
$banner-font-size: 14px;
.ld-dismissable-banner {
  line-height: 1;
  height: 56px;
  color: var(--v-text-base);
  font-size: $banner-font-size;
  width: 100%;
  padding: 6px 24px 2px;
  .layout {
    padding: 0 12px;
    height: 100%;
  }
  .divider {
    height: 30px;
    width: 1px;
    border-left: 1px solid rgba(0, 0, 0, 0.27);
    margin: auto 0;
  }
}
</style>
