<!--
  @Author: Ethan Dinnen
  @Date:   2019-01-28 13:14:08
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-02-26 14:49:11
-->
<template>
  <div v-if="!preview">
    <draggable
      v-model="sectionsGroup"
      :options="dragOptions"
      :move="onMove"
      @start="isDragging = true;"
      @end="handleDrop"
      :id="index"
      style="width: auto;"
    >
      <transition-group
        type="transaction"
        :name="'flip-list'"
        class="draggable-group"
        :id="position - 1"
      >
        <div :key="`card${tmp_id}`">
          <div class="form-section-card" :id="`card-${position}`">
            <v-card class="form-section-card">
              <v-card-text>
                <div class="drag-handle" v-if="position > 3" />
                <div class="required-label" v-if="mandatory" />
                <div class="form-section-card__header">
                  <TextInput
                    title="Section Name"
                    placeholder="Please enter a name"
                    :entered="name"
                    @onInput="handleUpdateTemplateName"
                    required
                    :rules="[v => !!v || 'Please enter a name']"
                    :errors="getTitleErrors(name, tmp_id)"
                  />
                  <div
                    class="form-section-card__header__actions"
                    v-if="name !== formNames.PROPERTY_ADDRESS"
                  >
                    <span class="add-fields">Add Fields</span>
                    <div
                      class="buttons"
                      v-if="name !== formNames.KEY_CONTRACT_DATES"
                    >
                      <v-tooltip bottom>
                        <v-btn
                          slot="activator"
                          flat
                          @click="addField({ type: 'currency', position });"
                        >
                          <v-icon>attach_money</v-icon>
                        </v-btn>
                        <span>Currency</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <v-btn
                          slot="activator"
                          flat
                          @click="addField({ type: 'text', position });"
                        >
                          <v-icon>text_fields</v-icon>
                        </v-btn>
                        <span>Text</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <v-btn
                          slot="activator"
                          flat
                          @click="addField({ type: 'date', position });"
                        >
                          <v-icon>access_time</v-icon>
                        </v-btn>
                        <span>Date</span>
                      </v-tooltip>
                    </div>
                    <div class="buttons" v-else>
                      <v-tooltip bottom>
                        <v-btn
                          slot="activator"
                          flat
                          @click="addField({ type: 'absolute', position });"
                        >
                          <v-icon>calendar_today</v-icon>
                        </v-btn>
                        <span>Absolute date</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <v-btn slot="activator" flat @click="toggleDrawer">
                          <v-icon>date_range</v-icon>
                        </v-btn>
                        <span>Relative date</span>
                      </v-tooltip>
                    </div>
                  </div>
                </div>
                <div class="form-section-card__fields" v-if="fields.length">
                  <div
                    class="field-container"
                    v-for="field in filteredFields"
                    :key="`template-${field.tmp_id ? field.tmp_id : field.id}`"
                    :id="`${position}${field.tmp_id ? field.tmp_id : field.id}`"
                  >
                    <draggable
                      v-model="fieldsGroup"
                      :options="fieldDragOptions"
                      :move="fieldOnMove"
                      @start="fieldIsDragging = true;"
                      @end="handleFieldDrop"
                      :id="field.tmp_id || field.id"
                      style="flex: auto;"
                    >
                      <transition-group
                        type="transaction"
                        :name="'flip-list'"
                        class="draggable-group"
                        :id="field.position - 1"
                      >
                        <div
                          class="field-container__drag"
                          :key="field.tmp_id || field.id"
                        >
                          <TextInput
                            :key="
                              `${field.tmp_id || field.id}-${fieldUniqueId}`
                            "
                            selectOnClick
                            :fieldId="field.tmp_id || field.id"
                            :returnId="false"
                            :entered="field.label"
                            :prependIcon="getIcon(field.type)"
                            :disabled="inputDisabled(field)"
                            :rules="[
                              v => !!v || v !== '' || 'Please name this field',
                            ]"
                            :errors="
                              getFieldErrors(
                                field.label,
                                field.tmp_id || field.id
                              )
                            "
                            @onInput="
                              value =>
                                handleUpdateFieldName(
                                  value,
                                  position,
                                  field.position
                                )
                            "
                            :append-icon="
                              field.type === inputTypes.RELATIVE &&
                              field.position > 2
                                ? appendIcon
                                : null
                            "
                            @onAppendClick="toggleMenu(field);"
                          />
                          <v-btn
                            tabindex="-1"
                            flat
                            :class="[
                              'field-container__delete',
                              isMandatory(field) ? 'hidden' : '',
                            ]"
                            :disabled="isMandatory(field) || filteredFields.length < 2"
                            @click="
                              shouldDeleteField({
                                position,
                                fieldPosition: field.position,
                              });
                            "
                          >
                            <v-icon>clear</v-icon>
                          </v-btn>
                          <v-list
                            v-if="menuOpen === (field.tmp_id || field.id)"
                            v-click-outside="toggleMenu"
                          >
                            <v-list-tile>
                              <v-list-tile-title @click="toggleDrawer(field);">
                                <v-icon>edit</v-icon>
                                Edit
                              </v-list-tile-title>
                            </v-list-tile>
                          </v-list>
                        </div>
                      </transition-group>
                    </draggable>
                  </div>
                </div>
                <div v-else class="empty-section">
                  <h3>Please add a field</h3>
                </div>
              </v-card-text>
            </v-card>
            <v-btn
              icon
              flat
              :class="['form-section-card__delete', mandatory ? 'hidden' : '']"
              @click="dialog = true;"
              :disabled="mandatory"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </div>
          <v-btn
            @click="addSection(position);"
            class="add-section"
            color="primary-dark"
          >
            <v-icon small>add</v-icon> Add Section
          </v-btn>
        </div>
      </transition-group>
    </draggable>
    <LdConfirmation
      :value="dialog"
      width="490"
      @click:cancel="dialog = false;"
      @click:ok="handleDeleteTemplate(position);"
      confirmText="Remove"
    >
      <template slot="header">
        Delete Section
      </template>
      <template slot="body">
        Are you sure you want to delete this section? This cannot be undone.
      </template>
    </LdConfirmation>
    <LdConfirmation
      :value="kcdDialog"
      width="490"
      @click:cancel="kcdDialog = false;"
      @click:ok="handleDeleteField(fieldOpts);"
      cancelText="No"
      confirmText="Yes"
    >
      <template slot="header">
        Delete key contract date field
      </template>
      <template slot="body">
        This field has {{ descendants.size }} fields linked to it. These fields
        will also be deleted. Are you sure you wish to continue?
      </template>
    </LdConfirmation>
    <v-navigation-drawer
      v-if="name === formNames.KEY_CONTRACT_DATES"
      :key="relDrawerId"
      class="form-section-relativeDrawer"
      v-model="relativeDrawer"
      absolute
      temporary
      right
      :width="drawerWidth"
    >
      <div class="drawer-close">
        <v-btn icon @click="closeDrawer">
          <v-icon medium>chevron_right</v-icon>
        </v-btn>
      </div>
      <v-form v-model="newValid" class="drawer-relative">
        <h2>Add Relative Date</h2>
        <TextInput
          title="Name"
          @onInput="updateNewRelative('label', $event);"
          :entered="newRelative.label"
          :rules="[v => !!v || 'Label required']"
          required
          :errors="
            getFieldErrors(
              newRelative.label,
              newRelative.tmp_id || newRelative.id
            )
          "
        />
        <TextInput
          title="Number of Days"
          v-if="relativity !== 'equal'"
          placeholder="5"
          @onInput="updateNewRelative('meta.days', $event);"
          :rules="numberValidator"
          :entered="newRelative.meta.days"
        />
        <v-radio-group
          v-model="relativity"
          @change="updateNewRelative('meta.relativity', relativity);"
        >
          <v-radio label="Before" value="before" />
          <v-radio label="After" value="after" />
          <v-radio label="Equal to" value="equal" />
        </v-radio-group>
        <SelectInput
          :options="kcdFieldOptions"
          label="Reference Date"
          :selected="newRelative.meta.reference"
          @onInput="updateNewRelative('meta.reference', $event);"
          :errors="referenceErrors"
        />
      </v-form>
      <div class="actions">
        <v-btn flat @click="resetNewRelative">Cancel</v-btn>
        <v-btn color="primary" @click="addNewRelative" :disabled="!newValid">
          Apply
        </v-btn>
      </div>
    </v-navigation-drawer>
  </div>
  <div class="form-section-preview" v-else>
    <div class="form-section-preview__row">
      <span class="form-section-preview__row__name">{{ name }}</span>
    </div>
    <div
      class="form-section-preview__row"
      v-for="field in fields"
      :key="`row-${field.label}`"
    >
      <v-icon>{{ getIcon(field.type) }}</v-icon>
      <span class="form-section-preview__row__title">{{ field.label }}</span>
    </div>
  </div>
</template>

<script>
import { LdConfirmation } from 'lion-vue';
import TextInput from '@/Core/components/TextInput';
import SelectInput from '@/Core/components/SelectInput';
import draggable from 'vuedraggable';

import FormSectionLogic from '../mixins/FormSectionLogic';
import '../styles/TxTypeWizard.scss';

export default {
  props: {
    name: {
      type: String,
      required: false,
      default: null,
    },
    index: {
      type: Number,
      required: false,
      defualt: 0,
    },
    tmp_id: {
      type: [String, Number],
    },
    mandatory: {
      type: Boolean,
      required: false,
      default: false,
    },
    position: {
      type: Number,
      required: false,
    },
    fields: Array,
    preview: {
      type: Boolean,
      default: false,
    },
    deleteField: {
      type: Function,
    },
    deleteFields: {
      type: Function,
    },
    deleteTemplate: {
      type: Function,
    },
    addField: {
      type: Function,
    },
    updateField: {
      type: Function,
    },
    addRelativeField: {
      type: Function,
    },
    addSection: {
      type: Function,
    },
    updateTemplateName: {
      type: Function,
    },
    updateFieldName: {
      type: Function,
    },
    getTitleErrors: {
      type: Function,
    },
    templates: {
      type: Array,
    },
    moveSection: {
      type: Function,
    },
    moveField: {
      type: Function,
    },
    moveTemplate: {
      type: Function,
    },
  },
  components: {
    TextInput,
    SelectInput,
    draggable,
    LdConfirmation,
  },
  mixins: [FormSectionLogic],
};
</script>

<style lang="scss">
.drawer-relative {
  h2 {
    color: var(--v-primary-darken1);
    font-weight: 400;
  }
}
</style>
