<template>
  <v-layout row justify-space-between class="property-info-layout">
    <div column class="property-summary body">
      <a
        class="virtual-tour"
        v-if="virtualTourURL"
        :href="virtualTourURL"
        target="_blank"
      >Virtual Tour <v-icon>open_in_new</v-icon></a>
      <LdTag
        :text="property._source.StandardStatus"
        :color="statusColor(property._source.StandardStatus)"
        v-if="!isPublic"
      />
      <span class="dom" v-if="!isPublic">{{ dom }}</span>
      <v-layout>
        <v-flex xs6>
          <v-flex py-4>
            <div class="title primary--text">{{ address }}</div>
            <div>{{ city }}</div>
          </v-flex>
          <v-flex>
            {{ mlsNumber }}
          </v-flex>
          <v-flex>
            {{ listingOffice }}
          </v-flex>
          <v-flex py-2>
            <div>
              Listing Price:
              <span class="font-weight-bold black--text">{{ listingPrice }}</span>
            </div>
          </v-flex>
          <v-flex class="property-info" pb-2>
            <span>{{ propertyInfo.bedrooms }} BED</span>
            <span>{{ propertyInfo.bathrooms }} BATH</span>
            <span>{{ propertyInfo.sqft }} SQFT</span>
            <span>{{ propertyInfo.year }}</span>
          </v-flex>
        </v-flex>
        <v-flex xs6>
          <mls-property-extra-info
            :info="mainAgentInfo"
            :dataType="'agent'"
          />
        </v-flex>
      </v-layout>  

      <mls-static-map
        :googleApiKey="googleApiKey"
        :listing="property"
        :zoom="14"
        :center="address"
        :size="[400, 200]"
        type="roadmap"
        class="center-image"
      ></mls-static-map>
      <mls-property-extra-info
        :info="coAgentInfo"
        :dataType="'agent'"
      />
      <mls-property-extra-info
        :info="showingInfo"
      />
    </div>
  </v-layout>
</template>

<script>
import formatCurrency from 'lion-vue-shared/mixins/formatCurrency';
import statusColor from 'lion-vue-shared/mixins/statusColor';
import LdTag from 'lion-vue-shared/components/LdTagContainer';
import MlsStaticMap from './MlsStaticMapContainer';
import MlsPropertyExtraInfo from './MlsPropertyExtraInfoContainer';
import mountAddress from '../helpers/mountAddress';

export default {
  name: 'MlsMobilePropertyInfo',
  components: {
    LdTag,
    MlsStaticMap,
    MlsPropertyExtraInfo,
  },
  props: {
    property: {
      type: Object,
      required: true,
    },
    price: {
      type: String,
      required: false,
    },
    googleApiKey: {
      type: String,
      required: true,
    },
    isPublic: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  mixins: [formatCurrency, statusColor],
  methods: {
    imageMap(images) {
      return images.map(img => {
        return {
          src: img[0],
          thumbnail: img[1],
        };
      });
    },
  },
  computed: {
    virtualTourURL() {
      const { VirtualTourUrl } = this.property._source;
      return VirtualTourUrl;
    },
    address() {
      return mountAddress(this.property._source)
    },
    dom() {
      const { DaysOnMarket } = this.property._source;
      return `${DaysOnMarket} DOM`;
    },
    mlsNumber() {
      const { ListingId } = this.property._source;
      return `MLS NUMBER: ${ListingId}`;
    },
    listingOffice() {
      const { ListingOffice } = this.property._source;
      return `Courtesy of ${ListingOffice}`;
    },
    listingPrice() {
      const ListPrice = this.price
        ? this.formatCurrency(this.price)
        : this.formatCurrency(this.property._source.ListPrice);
      return `${ListPrice}`;
    },
    city() {
      const { City, StateOrProvince } = this.property._source;
      return `${City}, ${StateOrProvince}`;
    },
    propertyInfo() {
      const {
        BathroomsTotal, BedroomsTotal, SquareFeet, YearBuilt,
      } = this.property._source;
      return {
        bathrooms: BathroomsTotal,
        bedrooms: BedroomsTotal,
        sqft: SquareFeet,
        year: YearBuilt,
      };
    },
    images() {
      const { processedImages } = this.property._source.ImageUrls;
      if (!processedImages) {
        return this.imageMap([]);
      }
      const n = processedImages.length / 2;
      const images = [];
      for (let i = 1; i < n + 1; i++) {
        const sizes = processedImages.filter(img => img.includes(`-${i}-`));
        images.push(sizes);
      }
      return this.imageMap(images);
    },
    mainAgentInfo() {
      const {
        ListAgentFullName, ListAgentFirstName, ListAgentLastName,
        ListAgentEmail, ListAgentDirectWorkPhone, ListingAgentId,
        ListingOffice, ListingOfficePhone, ListingOfficeId,
      } = this.property._source;

      return {
        title: 'Listing Agent',
        data: {
          name: {
            label: 'Name',
            icon: 'account_circle',
            value: ListAgentFullName || `${ListAgentFirstName} ${ListAgentLastName}`.trim(),
          },
          email: {
            label: 'Email',
            icon: '',
            value: ListAgentEmail,
          },
          phone: {
            label: 'Work Phone',
            icon: '',
            value: ListAgentDirectWorkPhone,
          },
          mlsID: {
            label: 'MLSID',
            icon: '',
            value: ListingAgentId,
          },
          officeName: {
            label: 'Office Name',
            icon: '',
            value: ListingOffice,
          },
          officePhone: {
            label: 'Office Phone',
            icon: '',
            value: ListingOfficePhone,
          },
          officeMLSID: {
            label: 'Office MLSID',
            icon: '',
            value: ListingOfficeId,
          },
        },
      };
    },
    coAgentInfo() {
      const {
        CoListAgentFullName, CoListAgentFirstName, CoListAgentLastName,
        CoListAgentEmail, CoListAgentDirectWorkPhone, CoListAgentMlsId,
        CoListOfficeName, CoListOfficePhone, ColListOfficeMlsId,
      } = this.property._source;

      return {
        title: 'CoListing Agent',
        data: {
          name: {
            label: 'Name',
            icon: 'account_circle',
            value:CoListAgentFullName || `${CoListAgentFirstName} ${CoListAgentFirstName}`.trim(),
          },
          email: {
            label: 'Email',
            icon: '',
            value: CoListAgentEmail,
          },
          phone: {
            label: 'Work Phone',
            icon: '',
            value: CoListAgentDirectWorkPhone,
          },
          mlsID: {
            label: 'MLSID',
            icon: '',
            value: CoListAgentMlsId,
          },
          officeName: {
            label: 'Office Name',
            icon: '',
            value: CoListOfficeName,
          },
          officePhone: {
            label: 'Office Phone',
            icon: '',
            value: CoListOfficePhone,
          },
          officeMLSID: {
            label: 'Office MLSID',
            icon: '',
            value: ColListOfficeMlsId,
          },
        },
      };
    },
    showingInfo() {
      const {
        ShowingInstructions, ShowingContactName, ShowingContactType,
        ShowingContactPhone, ShowingContactPhoneExt,
      } = this.property._source;

      return {
        title: 'Showing',
        data: {
          instructions: {
            label: 'Instructions',
            icon: '',
            value: ShowingInstructions,
          },
          contact_name: {
            label: 'Contact Name',
            icon: '',
            value: ShowingContactName,
          },
          contact_type: {
            label: 'Contact Type',
            icon: '',
            value: ShowingContactType,
          },
          ContactPhone: {
            label: 'ContactPhone',
            icon: '',
            value: `${ShowingContactPhone} ${ShowingContactPhoneExt}`.trim(),
          },
        },
      };
    }
  },
};
</script>

<style lang="scss" scope>
.property-summary {
  color: var(--v-text-lighten2);
  .property-info {
    span {
      &:not(:first-of-type) {
        margin-left: 15px;
      }
    }
  }
}
.dom {
  height: 19px;
  width: 52px;
  color: #4a4a4a;
  font-size: 14px;
  line-height: 17px;
  padding: 10px;
}
.virtual-tour{
  margin-bottom: 20px;
  display: block;
  font-size: 15px;
  border: 1px solid #3878c2;
  padding: 8px 10px;
  width: -webkit-max-content;
  width: -moz-max-content;
  i {
    color: #3878c2;
  }
  &:hover{
    background-color: #3878c2;
    color: #fff;
    i {
      color: #fff;
    }
  }
}
.grey-top-border{
  border-top: 1px dashed #ccc;
  margin: 30px 0px;
}
.center-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
