export const LIONDESK_CUSTOM_CONTENTS = [
  { text: 'Contact full name', value: '{{firstname}} {{lastname}}' },
  { text: 'Contact first name', value: '{{firstname}}' },
  { text: 'Contact last name', value: '{{lastname}}' },
  { text: 'Contact email', value: '{{email}}' },
  { text: 'Contact home phone', value: '{{homephone}}' },
  { text: 'Content mobile phone', value: '{{mobilephone}}' },
  { text: 'Contact office phone', value: '{{officephone}}' },
  { text: 'Contact home street address 1', value: '{{address1}}' },
  { text: 'Contact home street address 2', value: '{{address2}}' },
  { text: 'Contact home city', value: '{{city}}' },
  { text: 'Contact home zip code', value: '{{zip}}' },
  { text: 'Contact source', value: '{{contactsource}}' },
  { text: '-----', disabled: true },
  { text: 'Your full name', value: '{{agentfirstname}} {{agentlastname}}' },
  { text: 'Your first name', value: '{{agentfirstname}}' },
  { text: 'Your last name', value: '{{agentlastname}}' },
  { text: 'Your email', value: '{{agentemail}}' },
  { text: 'Your phone', value: '{{agentphone}}' },
  { text: 'Your email signature', value: '{{emailsignature}}' },
  { text: 'Your website URL', value: '{{website}}' },
  { text: 'Your affiliate URL', value: '{{affiliateurl}}' },
  { text: 'Assigned To full name', value: '{{assignedto_firstname}} {{assignedto_lastname}}' },
  { text: 'Assigned To first name', value: '{{assignedto_firstname}}' },
  { text: 'Assigned To last name', value: '{{assignedto_lastname}}' },
  { text: 'Assigned To email', value: '{{assignedto_email}}' },
  { text: 'Assigned To phone', value: '{{assignedto_phone}}' },
  { text: 'Assigned To email signature', value: '{{assignedto_emailsignature}}' },
  { text: 'Assigned To website URL', value: '{{assignedto_website}}' },
  { text: 'Assigned To affiliate URL', value: '{{assignedto_affiliateurl}}' },
  { text: '------', disabled: true },
  { text: 'Your mailing address: Sender', value: '{{directmail_sender}}' },
  { text: 'Your mailing address: address 1', value: '{{directmail_address1}}' },
  { text: 'Your mailing address: address 1', value: '{{directmail_address2}}' },
  { text: 'Your mailing address: city', value: '{{directmail_city}}' },
  { text: 'Your mailing address: state/province', value: '{{directmail_state}}' },
  { text: 'Your mailing address: zip/postal code', value: '{{directmail_zip}}' },
  { text: 'Your mailing address: country code', value: '{{directmail_country}}' },
];
