<template>
  <v-layout row justify-center>
    <v-dialog :value="!!feature" @input="$emit('input')" persistent max-width="640">
      <v-card class="ld-upgrade-product">
        <v-card-title :class="{ 'usage-warning': warning }">{{ title }}</v-card-title>
        <v-card-text v-html="description"></v-card-text>
        <div
          class="purchase-result d-flex"
          v-if="addonPurchaseResult"
          :class="{ failed: addonPurchaseResultError }"
        >
          <v-icon color="error" v-if="addonPurchaseResultError">error_outline</v-icon>
          <v-icon color="success" v-else>check_circle</v-icon>
          <span class="px-3" v-html="addonPurchaseResultMessage"></span>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div v-if="addonPurchaseRequested" class="pr-2">
            We are processing the add-on you have purchased...
          </div>
          <v-btn
            v-if="!addonPurchaseResult && !addonPurchaseRequested"
            flat
            @click="upgradeAction(false)"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="shouldShowPurchaseCustomNumber"
            class="primary"
            :loading="addonPurchaseRequested"
            :href="customNumberLink"
          >
            Purchase Custom Number
          </v-btn>
          <v-btn
            v-else-if="isMetered && !addonPurchaseResult"
            class="primary"
            :loading="addonPurchaseRequested"
            @click="acceptMeteredUsage"
          >
            Continue
          </v-btn>
          <v-btn
            v-if="!isMetered && !addonPurchaseResult"
            class="primary"
            @click="upgradeAction(true)"
          >
            {{ CTAText }}
          </v-btn>
          <v-btn
            v-if="(isMetered && addonPurchaseResult)"
            class="primary"
            @click="upgradeAction(false)"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import dateFns from 'date-fns';
import { HC_PROPS } from 'lion-vue-src/_globals';
import LdFeatures from 'lion-vue-src/_globals/lib/LdFeatures';
import LdPlans from 'lion-vue-src/config/plans';
import LdOveragePricing from 'lion-vue-src/config/overagePricing';
import GatedFeatureContent from 'lion-vue-src/config/gatedContent';
import MeteredFeatureContent from 'lion-vue-src/config/meteredContent';

const { mapState: mapSubscriptionState, mapActions: mapSubscriptionActions } =
  createNamespacedHelpers('subscription');

export default {
  props: {
    feature: {
      type: String,
      default: '',
    },
    plan: {
      type: String,
      default: LdPlans.Trial,
    },
    upgradeLink: {
      type: String,
      default: null,
    },
    customNumberLink: {
      type: String,
      default: null,
    },
    featureMeteredUsageKey: {
      type: String,
      default: null,
    },
    addonPurchaseResult: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      GatedFeatureContent,
      MeteredFeatureContent,
      LdPlans,
    };
  },
  watch: {
    addonPurchaseResult: {
      handler(value) {
        this.toggleAddonPurchaseRequested(false);
      },
    },
  },
  computed: {
    ...mapSubscriptionState(['addonPurchaseRequested']),
    title() {
      const content = this.isMetered ? MeteredFeatureContent : GatedFeatureContent;
      if (content[this.feature] && content[this.feature][this.plan]) {
        return content[this.feature][this.plan].title;
      } else if (content[this.feature] && !content[this.feature][this.plan]) {
        return content[this.feature].title;
      }
      return content.Default.title;
    },
    description() {
      const content = this.isMetered ? MeteredFeatureContent : GatedFeatureContent;
      let { description } = content.Default;
      const plansCheckCustomNumber = [LdPlans.Starter, LdPlans.CRM, LdPlans.CRMPremier];
      if (content[this.feature] && content[this.feature][this.plan]) {
        ({ description } = content[this.feature][this.plan]);
        if (
          plansCheckCustomNumber.includes(this.plan) &&
          this.isTextingOrVideoTextingFeature &&
          !this.user.custom_number
        ) {
          description = content[this.feature][this.plan].noCustomNumber;
        }
      } else if (content[this.feature] && !content[this.feature][this.plan]) {
        ({ description } = content[this.feature]);
      }
      description = description.replace(/UPGRADE_LINK/g, this.upgradeLink);
      description = description.replace(/CUSTOM_NUMBER_LINK/g, this.customNumberLink);
      if (
        this.user &&
        this.user.features &&
        this.user.features[this.feature] &&
        this.user.features[this.feature].tracking
      ) {
        const { tracking } = this.user.features[this.feature];
        const usage = tracking.limit ? `${tracking.remaining}/${tracking.limit}` : 'all';
        const overagePricing = LdOveragePricing[this.feature][this.plan];
        const nextReset = tracking.next_reset_at.Time;
        const resetMessage = `Your limit will be reset on ${dateFns.format(
          nextReset,
          'MMM D, YYYY',
        )}.`;
        description = description.replace(/REMAINING_LIMIT/, usage);
        description = description.replace(/OVERAGE_PRICING/, overagePricing);
        description = description.replace(/RESET_MESSAGE/, resetMessage);
      }
      return description;
    },
    closeButtonText() {
      return this.addonPurchaseResultError ? 'Close' : 'Cancel';
    },
    warning() {
      const content = this.isMetered ? MeteredFeatureContent : GatedFeatureContent;
      if (content[this.feature] && content[this.feature][this.plan]) {
        return !!content[this.feature][this.plan].warning;
      }
      return false;
    },
    isMetered() {
      return !!this.featureMeteredUsageKey;
    },
    addonPurchaseResultMessage() {
      return this.addonPurchaseResult ? this.addonPurchaseResult.message : null;
    },
    addonPurchaseResultError() {
      return this.addonPurchaseResult ? this.addonPurchaseResult.error : null;
    },
    CTAText() {
      const cta = this.generateCTAContents();

      return cta.text;
    },
    isTextingOrVideoTextingFeature() {
      return [
        LdFeatures.TextMessaging,
        LdFeatures.VideoTexts,
        LdFeatures.BulkTexts
      ].includes(this.feature);
    },
    shouldShowPurchaseCustomNumber() {
      return (
        this.isMetered &&
        !this.addonPurchaseResult &&
        !this.user.custom_number &&
        this.isTextingOrVideoTextingFeature
      );
    },
  },
  methods: {
    ...mapSubscriptionActions(['toggleAddonPurchaseRequested']),
    upgradeAction(upgrade) {
      this.$emit('input', null);
      if (upgrade) {
        const cta = this.generateCTAContents();

        if (cta.link) {
          const route = `${HC_PROPS.ACCOUNTS_APP_URL}${cta.link}`;
          window.location.href = route;
        }
      }
    },
    acceptMeteredUsage() {
      // show loading state
      this.toggleAddonPurchaseRequested(true);

      // emit accept:addon event to signal originating activator
      // to replay the intended action (e.g. click event)
      //
      // the value of metered:accepted event is the unique key
      // of the originating activator this modal (e.g. ld-gated-button)
      // all active gated components watch for this value
      // and when it changes to match their unique key,
      // trigger the original action the user attempted to
      // perform when being prompted with the metered use modal
      setTimeout(() => {
        this.$emit('accept:addon', this.featureMeteredUsageKey);
      }, 1000);
    },
    generateCTAContents() {
      let text;
      let link;
      const content = GatedFeatureContent;

      if (content[this.feature] && content[this.feature][this.plan]) {
        text = content[this.feature][this.plan].modalBtnText || content.Default.modalBtnText;
        link = content[this.feature][this.plan].modalCTALink || content.Default.modalCTALink;
        return { text, link };
      }

      text = content.Default.modalBtnText;
      link = content.Default.modalCTALink;
      return { text, link };
    },
  },
};
</script>
<style lang="scss">
.ld-upgrade-product {
  .v-card__title {
    background: var(--v-primary-base);
    color: white;
    font-weight: 500;
    font-size: 20px;
    text-transform: uppercase;
    padding: 16px 24px;
    &:before {
      font-family: 'Material Icons';
      font-weight: normal;
      font-size: 22px;
      text-transform: none;
      font-feature-settings: 'liga';
      -webkit-font-feature-settings: 'liga';
      -webkit-font-smoothing: antialiased;
      content: 'star';
      opacity: 0.5;
      margin: 0 8px 0 0;
    }
  }
  .usage-warning {
    &:before {
      content: 'error_outline';
    }
  }
  .purchase-result {
    margin: 0px 24px 24px 24px;
    padding: 12px;
    border: solid 1px;
    color: var(--v-success-base);
    background-color: var(--v-success-lighten5);
    border-radius: 4px;
    vertical-align: middle;
    &.failed {
      color: var(--v-error-base);
      background-color: var(--v-error-lighten5);
      a {
        color: var(--v-error-darken2);
      }
    }
  }
  .v-card__text {
    padding: 24px;
    ol {
      padding: 0 16px;
      li {
        list-style-type: none;
        display: inline-block;
        min-width: 250px;
        min-height: 35px;
        &:before {
          font-family: 'Material Icons';
          font-weight: normal;
          content: 'check_circle';
          color: var(--v-background-base);
          padding: 2px 4px;
          vertical-align: middle;
        }
      }
    }
  }
  .v-card__actions {
    padding: 24px;
    border-top: solid 1px var(--v-background-lighten4);
  }
}
</style>
