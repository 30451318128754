// All the colors that vuetify currently supports
const allColors = [
  'red',
  'pink',
  'purple',
  'deep-purple',
  'indigo',
  'blue',
  'light-blue',
  'cyan',
  'teal',
  'green',
  'light-green',
  'lime',
  'yellow',
  'amber',
  'orange',
  'deep-orange',
  'brown',
  'blue-grey',
  'grey',
];
// The two possible hues. There is also 'accemt', but it is only restricted to some colors.
const hue = ['lighten', 'darken'];

/*
 * @param {Number} max The exclusive upper bound we want to generate the random integer from.
 * @returns {Number} The randomized number generated.
 *
 * Generates a random integer until the specified maximum
 */
function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

/*
 * @param {String} [keyword] The string we want to pass in the hashing function for
 * @returns {number} A 9-digit integer representing the hash
 *
 * Hash required to ensure a keyword always generates the desired same color
 * Obtained from https://jsperf.com/hashcodelordvlad
 */
function hashingFunction(keyword) {
  return keyword.split('').reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0); // eslint-disable-line no-bitwise
    return Math.abs(a & a); // eslint-disable-line no-bitwise
  }, 0);
}

/*
 * @param {String} [keyword] The keyword that we would hash to ensure the same random color
 * @param {Enum} ['all' | 'light' | 'dark'] The desired hue, either light or dark or both
 * @returns {String} The randomly-generated vuetify-compliant color output
 *
 * Generates a random color based on the specifications.
 */
const generateRandomColor = (keyword = '', restriction = 'all') => {
  if (keyword === null || keyword === 0) {
    return 'primary';
  }
  const randomHash = hashingFunction(keyword);
  // Generates the random number based on the hash if given, otherwise from a random number
  const randomColorValue = keyword
    ? randomHash % allColors.length
    : getRandomInt(allColors.length);
  const randomHueValue = keyword
    ? randomHash % hue.length
    : getRandomInt(hue.length);
  const shade = keyword ? (randomHash % 4) + 1 : getRandomInt(4) + 1;
  // Obtain the color from either the random hashes or the random int
  const randomColor = allColors[randomColorValue];
  const randomHue = hue[randomHueValue];
  switch (restriction) {
    case 'light':
      return `${randomColor} lighten-${shade}`;
    case 'dark':
      return `${randomColor} darken-${shade}`;
    default:
      return `${randomColor} ${randomHue}-${shade}`;
  }
};

export default generateRandomColor;
