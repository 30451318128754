<!--
  @Author: Ethan Dinnen
  @Date:   2019-01-23 13:18:10
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-02-25 13:23:39
-->
<template>
  <TransactionTypeWizardPresenter
    v-if="renderEdit"
    :editing="editing"
    :submitLoading="submitLoading"
    :summaryValues="summaryValues"
    :isNew="isNew"
    :typeOptions="typeOptions"
    :type="type"
    :handleTypeSelect="handleTypeSelect"
    :typeName="typeName"
    :handleUpdateName="handleUpdateName"
    :nameRules="nameRules"
    :validateSetup="validateSetup"
    :handleUpdateRadio="handleUpdateRadio"
    :stages="stages"
    :handleAddNewStage="handleAddNewStage"
    :updateStageType="updateStageType"
    :moveStageUp="moveStageUp"
    :moveStageDown="moveStageDown"
    :removeStage="removeStage"
    :isNextEnabled="isNextEnabled"
    :handleSubmit="handleSubmit"
    :handleSave="saveType"
    :saveDisabled="saveDisabled"
    :stageError="stageError"
    :resetStageError="resetStageError"
    :splitByType="splitByType"
    :upDisabled="upDisabled"
    :downDisabled="downDisabled"
    :resetSavedMsg="resetSavedMsg"
    :typeSavedMsg="typeSavedMsg"
    :clearSelection="handleClearSelection"
    :templates="templates"
    :renameStage="renameStage"
    :templateStages="templateStages"
    :deleteField="deleteField"
    :deleteFields="deleteFields"
    :deleteTemplate="deleteTemplate"
    :addField="addField"
    :updateField="updateField"
    :addRelativeField="addRelativeField"
    :addSection="addSection"
    :updateTemplateName="updateTemplateName"
    :updateFieldName="updateFieldName"
    :transactionsByStage="transactionsByStage"
    :moveTransactions="moveTransactions"
    :setStepDone="setStepDone"
    :handleDeleteType="handleDeleteType"
    :totalTransactions="totalTransactions"
    :moveField="moveField"
    :moveTemplate="moveTemplate"
  />
  <div class="spinner" v-else><BreedingRhombusSpinner /></div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import TransactionTypeWizardPresenter from './TransactionTypeWizardPresenter';
import BreedingRhombusSpinner from '@/Core/components/BreedingRhombusSpinner';
import stageTypes from '@/Core/constants/stageTypes';

const {
  mapState: mapTypeCreateState,
  mapActions: mapTypeCreateActions,
  mapGetters: mapTypeCreateGetters,
} = createNamespacedHelpers('createType');

const initialSetupSummary = {
  key: 'tx-type-setup',
  header: 'Setup Transaction Type',
  data: ['', '', ''],
  done: false,
};

const initialFormSummary = {
  key: 'tx-type-form',
  header: 'Setup Forms',
  data: [''],
  done: false,
};

export default {
  components: {
    TransactionTypeWizardPresenter,
    BreedingRhombusSpinner,
  },
  data() {
    return {
      submitLoading: false,
      summaryValues: [],
      nextDisabled: false,
      setupSummary: { ...initialSetupSummary },
      formSummary: { ...initialFormSummary },
      nameRules: [
        v =>
          !this.nameComparator.includes(v.toLowerCase()) ||
          this.editNameSame(v.toLowerCase()) ||
          'Name cannot be the same as an existing transaction type',
        v => !!v || 'Please enter a name',
      ],
      typeOptions: [],
      isNew: true,
      snackbar: false,
    };
  },
  computed: {
    ...mapTypeCreateState([
      'types',
      'type',
      'markets',
      'market',
      'typeName',
      'setupValid',
      'stages',
      'stageError',
      'editReady',
      'editingName',
      'typeSavedMsg',
      'templates',
      'templateStages',
      'transactionsByStage',
    ]),
    ...mapTypeCreateGetters([
      'unnamedStages',
      'unnamedForms',
      'emptyForms',
      'unnamedFields',
      'duplicateForms',
      'duplicateFields',
    ]),
    totalTransactions() {
      return this.transactionsByStage.reduce((acc, s) => {
        return acc + s.transactionCount;
      }, 0);
    },
    renderEdit() {
      if (!this.editing) return true;
      return this.editReady;
    },
    editing() {
      return !!this.id;
    },
    id() {
      return this.$route.params.id;
    },
    nameComparator() {
      return this.types.map(type => {
        return type.name.toLowerCase();
      });
    },
    isNextEnabled() {
      if (this.editing) return true;
      return this.stages.length > 0 && this.setupValid;
    },
    saveDisabled() {
      return !(
        this.stages.length > 0 &&
        !!this.typeName &&
        (this.setupValid || this.editing) &&
        this.unnamedStages === 0 &&
        this.unnamedForms === 0 &&
        this.emptyForms === 0 &&
        this.unnamedFields === 0 &&
        !this.duplicateForms &&
        !this.duplicateFields
      );
    },
    splitByType() {
      const inPipeline = this.stages.filter(
        stage => stage.stage_type === stageTypes.IN_PROCESS
      );
      const outPipeline = this.stages.filter(
        stage => stage.stage_type === stageTypes.CLOSED
      );
      return { inPipeline, outPipeline };
    },
  },
  methods: {
    ...mapTypeCreateActions([
      'reset',
      'resetNotName',
      'getMarkets',
      'getTypes',
      'getType',
      'typeSelect',
      'updateName',
      'validateSetup',
      'updateMarket',
      'addNewStage',
      'updateStageType',
      'moveStageUp',
      'moveStageDown',
      'removeStage',
      'resetStageError',
      'saveType',
      'setStages',
      'resetSavedMsg',
      'clearSelection',
      'renameStage',
      'deleteField',
      'deleteFields',
      'deleteTemplate',
      'addField',
      'updateField',
      'addRelativeField',
      'addSection',
      'updateTemplateName',
      'updateFieldName',
      'moveTransactions',
      'deleteTxType',
      'moveField',
      'moveTemplate',
    ]),
    handleDeleteType() {
      this.deleteTxType();
    },
    setStepDone(index) {
      if (index === 0) {
        this.setupSummary.done = true;
      } else {
        this.formSummary.done = true;
      }
      this.reAssignSummaryValues();
    },
    editNameSame(v) {
      return this.editingName.toLowerCase() === v;
    },
    reAssignSummaryValues() {
      this.summaryValues = [this.setupSummary, this.formSummary];
    },
    async handleSubmit() {
      await this.saveType();
      this.$router.push('/');
    },
    // Format arrays for select field
    selectFormat(obj) {
      return obj.map(item => {
        return {
          text: item.name,
          value: item.id,
        };
      });
    },
    // Set the type and reassign the summary text
    async handleTypeSelect(type) {
      this.setupSummary.data[0] = `Duplicating - ${
        this.typeOptions.find(t => t.value === type).text
      }`;
      await this.typeSelect(type);
      this.reAssignSummaryValues();
    },
    async handleClearSelection() {
      this.setupSummary.data[0] = '';
      await this.clearSelection();
      this.reAssignSummaryValues();
    },
    // Set the name and add it to the summary text
    handleUpdateName(name) {
      const formatted = name ? `Name - ${name}` : null;
      this.setupSummary.data[1] = formatted;
      this.updateName(name);
      this.reAssignSummaryValues();
    },
    // Update the summary to remove 'Duplicating' line if is type is new
    handleUpdateRadio(value) {
      this.isNew = value;
      if (value) {
        this.setupSummary.data[2] = '';
        this.resetNotName();
        if (this.typeName) {
          this.validateSetup(true);
        }
      }
      this.setupSummary.data[0] = '';
      this.setupSummary.data[1] = this.typeName
        ? `Name - ${this.typeName}`
        : null;
    },
    handleAddNewStage(stageName, inPipeline) {
      const stage = {
        level: 0,
        stage_type: inPipeline ? stageTypes.IN_PROCESS : stageTypes.CLOSED,
        title: stageName,
      };
      this.addNewStage(stage);
    },
    /**
     * Disable the up arrow if stage is first stage,
     * or first out of pipeline stage
     */
    upDisabled({ level, stage_type }) {
      const { inPipeline } = this.splitByType;
      if (stage_type === stageTypes.IN_PROCESS) {
        return level === 1;
      }
      return level === inPipeline.length + 1;
    },
    // Disable down button if stage is last stage or last in pipeline stage
    downDisabled({ level, stage_type }) {
      const { inPipeline, outPipeline } = this.splitByType;

      if (stage_type === stageTypes.IN_PROCESS) {
        return level === inPipeline.length;
      }
      return level === inPipeline.length + outPipeline.length;
    },
  },
  watch: {
    // Update summary values if stages length updated
    stages: {
      handler(value) {
        if (!value.length) {
          this.setupSummary.data[2] = '';
        } else {
          this.setupSummary.data[2] = `${value.length} Stage${
            value.length > 1 ? 's' : ''
          }`;
        }
        this.reAssignSummaryValues();
      },
    },
    typeName: {
      handler(value) {
        this.handleUpdateName(value);
      },
    },
    templates: {
      handler(value) {
        if (!value.length) {
          this.formSummary.data = [];
        } else {
          this.formSummary.data[0] = `${value.length} Section${
            value.length > 1 ? 's' : ''
          }`;
        }
        this.reAssignSummaryValues();
      },
    },
  },
  async beforeMount() {
    this.reset();
    await this.getMarkets();
    await this.updateMarket(this.markets[0].id);
    await this.getTypes();
    this.typeOptions = this.selectFormat(this.types);
    if (this.id) {
      await this.getType(this.id);
    }
  },
  mounted() {
    this.reAssignSummaryValues();
  },
};
</script>

<style scoped>
.spinner {
  margin: auto;
}
</style>
