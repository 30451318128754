/*
 * @Author: Ethan Dinnen
 * @Date:   2018-12-18 12:13:32
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-02-07 11:23:00
 */
import pipe from 'lodash/fp/pipe';

export const formatCurrency = (value, prefix = '$ ') => {
  if (!value || value === '0') return `${prefix}0`;
  return (
    prefix +
    pipe(sanitize)(value.toString()).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // Format as a currency
  );
};

export const removeCents = value => {
  if (!value) return;
  return value.replace(/\..*/g, '');
};

// Removes anything before $ sign, remove % symbol, limit to max 99 cents, everything but numbers (except cents), and leading 0s
export const sanitize = value => {
  if (!value) return;
  return value
    .replace(/^.*\$/g, '')
    .replace(/%/g, '')
    .replace(/^(.*\.\d{2})\d+/g, '$1')
    .replace(/(?!\.\d{1,2}$)[^0-9]/g, '')
    .replace(/^0*/g, '');
};

export default {
  methods: {
    formatCurrency,
  },
};
