<template>
  <FlowLayout class="create-action">
    <template slot="header">
      <v-btn flat="flat" @click="goBack" class="back-button">
        <v-icon>keyboard_arrow_left</v-icon> Back
      </v-btn>
    </template>

    <template>
      <CreateActionPresenter
        :currentStage="currentStage"
        :summaryValues="summaryValues"
        :planName="planName"
        :isActionComplete="isActionComplete"
        :actionType="actionType"
        :actionTypes="actionTypes"
        :actionInstructions="actionInstructions"
        :actionTimingInitialType="actionTimingInitialType"
        :isDesiredTypeText="isDesiredTypeText"
        :isDesiredTypeTask="isDesiredTypeTask"
        :setValidationStatus="setValidationStatus"
        :actionTiming="actionTiming"
        :actionReminder="actionReminder"
        :actionAssignee="actionAssignee"
        :clearActions="clearActions"
        :setActionType="setActionType"
        :updateInstructions="updateInstructions"
        :updateTiming="updateTiming"
        :updateAssignee="updateAssignee"
        :updateReminder="updateReminder"
        :task="task"
        :updateTask="updateTask"
        :kcdOptions="kcdOptions"
        :actionTemplate="this.action.communication_template || actionTemplate"
        :updateTemplateContainer="updateTemplate"
        :roles="roles"
        :handleSelectRole="handleSelectRole"
        :selectedRole="actionRecipient"
        :actionId="actionId"
        :updateStage="updateStage"
      />
    </template>

    <template slot="footer">
      <div v-if="actionId">
        <v-btn
          flat
          class="save-btn"
          color="primary"
          @click="handleSave"
          :disabled="submitDisabled"
        >
          Save Progress
        </v-btn>
      </div>
      <PrevNextStepper
        v-if="!isActionComplete && currentStage !== ''"
        :items="summaryValues"
        :submitDisabled="submitDisabled"
        :nextDisabled="!this.actionId && !hasSelectedAnOption"
        :hasSubmit="hasSubmit"
        v-on:submit-clicked="onHandleSubmit"
        v-on:update-clicked="userConfirmUpdateDialog = true"
        v-model="currentStage"
        objectKey="key"
      />
    </template>
  </FlowLayout>
</template>

<script>
import CreateActionPresenter from './CreateActionPresenter';
import { mapActions, mapState } from 'vuex';
import router from 'router';
import FlowLayout from '@/Core/layouts/FlowLayout';
import PrevNextStepper from '@/Core/components/PrevNextStepper';
import { ACTION_TYPES, getActionType, TASK_REMINDER_TYPES } from './config/actions';

const EMAIL = 'Email';
const TEXT = 'Text';
const TASK = 'Task';

const initialActionTypeSummary = {
  key: 'action-type',
  header: 'Select the type of action',
  data: [],
  done: false,
}; // default action type object body
const initialActionTemplateSummary = {
  key: 'action-template',
  header: 'Find the right template',
  data: [],
  done: false,
}; // default action template object body
const initialActionInstructionsSummary = {
  key: 'action-instructions',
  header: 'Write the Instructions',
  data: [],
  done: false,
}; // default action instruction object body
const initialActionTimingSummary = {
  key: 'action-timing',
  header: 'Set the due date',
  data: [],
  done: false,
}; // default action timing object body
const initialActionRecipientSummary = {
  key: 'action-recipient',
  header: 'Set the recipient',
  data: [],
  done: false,
}; // default action timing object body
const initialActionAssigneeSummary = {
  key: 'action-assignee',
  header: 'Set Assignee',
  data: [],
  done: false,
}; // default action assignee object body

/**
 * TO DO: return assignee to the steps when functionality is created.
 */
export default {
  components: {
    CreateActionPresenter,
    FlowLayout,
    PrevNextStepper,
  },
  data() {
    return {
      actionTypes: ACTION_TYPES,
      actionTypeSummary: Object.assign({}, initialActionTypeSummary),
      actionTemplateSummary: Object.assign({}, initialActionTemplateSummary),
      actionInstructionsSummary: Object.assign(
        {},
        initialActionInstructionsSummary
      ),
      actionTimingSummary: Object.assign({}, initialActionTimingSummary),
      actionRecipientSummary: Object.assign({}, initialActionRecipientSummary),
      actionAssigneeSummary: Object.assign({}, initialActionAssigneeSummary),
      summaryValues: [],
      actionType: {},
      actionTemplate: {},
      actionInstructions: '',
      actionTiming: {},
      actionTimingInitialType: {},
      actionRecipient: 0,
      actionAssignee: {},
      actionReminder: '',
      actionId: null,
      currentStage: 'action-type',
      planName: '',
      isActionComplete: false,
      isCreatingTemplate: false,
      isValid: true,
      isSubmitting: false,
      userConfirmUpdateDialog: false,
    };
  },

  async created() {
    this.reAssignSummaryValues();
  },

  computed: {
    ...mapState({
      task: state => state.planTasks.task,
      txType: state => state.plans.type,
      kcds: state => state.planTasks.kcds,
      roles: state => state.planTasks.roles,
      action: state => state.planTasks.action,
    }),
    kcdOptions() {
      return this.kcds.map(kcd => {
        return {
          text: kcd.label,
          value: kcd.meta.referenceId,
        };
      });
    },
    formAction() {
      return this.isCreatingTemplate ? 'create' : 'update';
    },
    actionTypeTask() {
      return getActionType('task');
    },
    actionTypeText() {
      return getActionType('text');
    },
    hasSelectedAnOption() {
      if (this.currentStage === 'action-type') {
        return !!Object.keys(this.actionType).length;
      }
      if (this.currentStage === 'action-template') {
        return !!Object.keys(this.actionTemplate).length;
      }
      if (this.currentStage === 'action-instructions') {
        return !!Object.keys(this.actionInstructions).length;
      }
      if (this.currentStage === 'action-timing') {
        if (
          this.task.due_at.timing !== 'on' &&
          (!this.task.due_at.days || +this.task.due_at.days < 0)
        )
          return false;

        return !!this.task.due_at.value;
      }
      if (this.currentStage === 'action-recipient') {
        return !!this.actionRecipient;
      }
      if (this.currentStage === 'action-assignee') {
        return !!Object.keys(this.actionAssignee).length;
      }
      return null;
    },
    /**
     * The condition at which the submit button is disabled
     */
    submitDisabled() {
      if (this.isDesiredTypeTask()) {
        const { due_at, description } = this.task;
        return (
          !Object.keys(this.actionType).length ||
          !description.length ||
          !due_at ||
          !Object.keys(this.actionAssignee).length
        );
      }

      const val =
        !Object.keys(this.actionType).length ||
        !Object.keys(this.actionTemplate).length ||
        !Object.keys(this.actionTiming).length ||
        !this.actionRecipient;
      return val;
    },
    hasSubmit() {
      return (
        this.currentStage === 'action-recipient' ||
        this.isDesiredTypeTask() && this.currentStage === 'action-assignee'
      );
    },
  },

  watch: {
    currentStage: {
      handler(value) {
        if (value === 'action-instructions') {
          this.actionTypeSummary.done = true;
          this.reAssignSummaryValues();
        }
        if (value === 'action-timing') {
          this.actionInstructionsSummary.done = true;
        }
        if (value === 'action-assignee') {
          this.actionTimingSummary.done = true;
        }
      },
    },
    // Watches the selected action type and update the summary accordingly
    actionType: {
      handler(value) {
        if (value) {
          this.updateTask({
            ...this.task,
            action_type: value.text,
          });
          this.actionTypeSummary.data = [value.text] || [''];
          this.actionTypeSummary.done = true;
          this.actionTypeSummary.icon = value.icon || '';
          this.actionTypeSummary.db = value.db || '';
          this.actionTypeSummary.createAction = value.createAction || '';
          const { assignee } = this.actionAssigneeSummary;
          if (value.text !== this.actionTypeTask) {
            this.actionAssigneeSummary.data = [assignee];
            this.actionAssigneeSummary.header = 'Set Assignee';
          }
          this.reAssignSummaryValues();
        }
      },
      deep: true,
    },
    // Watches the selected action template and update the summary accordingly
    actionTemplate: {
      handler(value) {
        if (value && value.description) {
          this.actionTemplateSummary.data[0] = value.description || '';
          this.actionTemplateSummary.done = true;
          this.updateTask({
            ...this.task,
            communication_template: value,
            description: value.description,
          });
          this.reAssignSummaryValues();
        }
      },
      deep: true,
    },
    // Watches the selected action instructions and update the summary accordingly
    actionInstructions(value) {
      this.actionInstructionsSummary.data = [value];
      this.actionInstructionsSummary.done = true;
      this.reAssignSummaryValues();
    },
    'task.due_at': {
      handler(value) {
        if (!this.kcdOptions.length) return;
        const kcd = this.kcdOptions.find(kcd => kcd.value === value.value);
        if (!kcd) return;
        const { text } = kcd;
        const update = `
          ${
            value.timing !== 'on' && value.days !== '0' && value.days
              ? `${value.days} days ${value.timing} `
              : ''
          }${text}
        `;
        this.actionTiming = { text: update };
        this.actionTimingSummary.data = [update];
        this.actionTimingSummary.done = true;
        this.reAssignSummaryValues();
      },
    },
    actionRecipient: {
      handler(value) {
        if (value) {
          const role = this.roles.find(r => r.id === value).name;
          this.actionRecipientSummary.data = [role];
          this.actionRecipientSummary.done = true;
          this.updateTask({ ...this.task, contact_id: value });
          this.reAssignSummaryValues();
        }
      },
    },
    // Watches the selected action assignee and update the summary accordingly
    actionAssignee: {
      handler(assignee) {
        if (assignee) {
          let assigneeName = assignee.text;
          this.actionAssigneeSummary.assignee = assigneeName;
          const { reminder } = this.actionAssigneeSummary;
          // Appends the reminder type if exists
          if (reminder && this.isDesiredTypeTask()) {
            this.actionAssigneeSummary.data = [`${assigneeName} via ${reminder}`];
          } else {
            this.actionAssigneeSummary.data = [assigneeName];
          }
          if (assigneeName != 'Me') {
            this.updateTask({
              ...this.task,
              assigned_user_id: assignee.value,
            });
          }
          this.actionAssigneeSummary.done = true;
          this.reAssignSummaryValues();
        }
      },
      deep: true,
    },
    actionReminder: {
      handler(value) {
        if (value) {
          const { assignee } = this.actionAssigneeSummary;
          this.actionAssigneeSummary.data = [`${assignee} via ${value}`];
          let reminderSlected = TASK_REMINDER_TYPES.find(reminderType => reminderType.value == value);
          let reminderId = reminderSlected.id;
          this.updateTask({
            ...this.task,
            reminder_type_id: reminderId,
          });
        }
      },
    },
    action(value) {
      if (value.reminder_type_id) {
        const reminderType = TASK_REMINDER_TYPES.find(type => type.id == value.reminder_type_id);
        this.actionReminder = reminderType.value;
      } else {
        this.actionReminder = '';
      }
    },
  },
  methods: {
    ...mapActions('planTasks', [
      'updateTask',
      'reset',
      'saveTask',
      'getTransactionType',
      'getRoles',
      'getAction',
      'patchTask',
    ]),
    updateTemplate(template) {
      this.actionTemplate = template;
    },
    updateReminder(value) {
      this.actionReminder = value;
    },
    updateAssignee(value) {
      this.actionAssignee = value;
    },
    updateTiming(value) {
      this.actionTiming = value;
    },
    updateInstructions(value) {
      this.actionInstructions = value;
      this.updateTask({ ...this.task, description: value });
    },
    updateStage(stage) {
      this.currentStage = stage;
    },
    setActionType(type) {
      this.actionTemplateSummary.data = [];
      this.actionTemplateSummary.done = false;
      this.actionTemplate = {};
      this.actionType = type;
    },
    handleSelectRole(role) {
      this.actionRecipient = role;
    },
    goBack() {
      window.history.length > 1 ? router.go(-1) : router.push('/plans');
    },
    /**
     *
     * If any validation step during add/edit action is violated, this
     * function will be called, setting isValid to false, and ensuring the
     * nextPrevStepper is disabled until the validation has been satisfied.
     *
     * @param {Boolean} isValid
     */
    setValidationStatus(isValid) {
      this.isValid = isValid;
    },
    /**
     * Go back to the actions list
     */
    handleClickBack() {
      this.redirectToCampaignActions();
    },
    /**
     * Updates the current list of summaries with the desired
     * values and the desired order.
     */
    reAssignSummaryValues() {
      if (this.isDesiredTypeTask()) {
        this.summaryValues = [
          this.actionTypeSummary,
          this.actionInstructionsSummary,
          this.actionTimingSummary,
          this.actionAssigneeSummary,
        ];
      } else {
        this.summaryValues = [
          this.actionTypeSummary,
          this.actionTemplateSummary,
          this.actionTimingSummary,
          this.actionRecipientSummary,
        ];
      }
    },
    // Returns true if the desired action type is Task
    isDesiredTypeTask() {
      return this.actionType.text === this.actionTypeTask;
    },
    // Returns true if the desired action type is Text
    isDesiredTypeText() {
      return this.actionType.text === this.actionTypeText;
    },
    /**
     * Calls the submit backend, and displays the finish page afterwards
     */
    onHandleSubmit() {
      this.isSubmitting = true;
      const params = this.$route.params;
      const planId = params.planId || params.id;
      const taskId = params.taskId;

      if (!planId) {
        return this.saveTask();
      } else if (!taskId) {
        return this.saveTask(planId);
      }
      this.updateTask({ ...this.task, id: taskId, plan_id: planId });
      return this.patchTask(this.actionId);
    },
    handleSave() {
      const { id, plan_id } = this.action;
      this.updateTask({ ...this.task, id, plan_id });
      this.patchTask(this.actionId);
    },
    // Resets the variables for the page.
    clearActions() {
      this.currentStage = 'action-type';
      this.actionType = {};
      this.actionTemplate = {};
      this.actionInstructions = '';
      this.actionTiming = {};
      this.actionRecipient = {};
      this.actionAssignee = {};
      this.actionReminder = '';
      this.actionTypeSummary = Object.assign({}, initialActionTypeSummary);
      this.actionTemplateSummary = Object.assign(
        {},
        initialActionTemplateSummary
      );
      this.actionInstructionsSummary = Object.assign(
        {},
        initialActionInstructionsSummary
      );
      this.actionTimingSummary = Object.assign({}, initialActionTimingSummary);
      this.actionRecipientSummary = Object.assign(
        {},
        initialActionRecipientSummary
      );
      this.actionAssigneeSummary = Object.assign(
        {},
        initialActionAssigneeSummary
      );
      this.summaryValues = [];
      this.isActionComplete = false;
      this.reAssignSummaryValues();
    },
    clearTemplate() {
      this.updateTemplate(this.action.communication_template);
      this.actionTemplateSummary.data[0] =
        this.actionTemplate.description || '';
      this.actionTemplateSummary.done = true;
    },
  },
  async beforeMount() {
    await this.reset();
    await this.getTransactionType(this.txType);
    if (this.$route.path.includes('edit')) {
      const { planId, taskId } = this.$route.params;
      await this.getAction({ planId, taskId });
      this.actionId = this.action.id;
      switch (this.action.action_type) {
        case EMAIL:
          this.actionType = {
            createAction: 'Email Contact',
            db: EMAIL,
            disabled: false,
            icon: 'email',
            key: 'email',
            text: EMAIL,
          };
          this.clearTemplate();
          break;
        case TEXT:
          this.actionType = {
            createAction: 'Text Contact',
            db: TEXT,
            disabled: false,
            icon: 'tablet_android',
            key: 'text',
            text: TEXT,
          };
          this.clearTemplate();
          break;
        case TASK:
          this.actionType = {
            disabled: false,
            icon: 'playlist_add_check',
            key: 'task',
            text: TASK,
          };
          this.actionInstructions = this.action.description;
          this.actionInstructionsSummary.data = [this.action.description];
          this.actionInstructionsSummary.done = true;
          break;
        default:
          break;
      }

      const { due_at } = this.action;
      this.actionTiming = due_at;
      this.actionRecipient = this.action.contact_id;
      this.actionAssignee = { value: this.action.assigned_user_id, text: 'Me' };

      this.updateTask({
        ...this.task,
        action_type: this.actionType.text,
        due_at,
        communication_template: this.actionTemplate,
        description: this.action.description || this.actionTemplate.description,
        contact_id: this.actionRecipient,
        status: this.action.status,
        notes: this.action.notes,
      });

      this.reAssignSummaryValues();
    }
  },
  async mounted() {
    await this.getRoles();
  },
};
</script>

<style lang="scss">
.create-action {
  .footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    .save-btn {
      color: #3878c2;
      font-family: 'Open Sans';
      font-size: 17px;
      font-weight: 600;
      line-height: 23px;
    }
    .layout {
      position: unset;
      right: unset;
      flex: unset;
    }
    .layout {
      justify-content: flex-end;
    }
  }
}
</style>
