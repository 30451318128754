/**
 * @author: Corey Sanford <corey@hackcapital.com>
 * @date:   2019-02-14
 *
 * Converts a file size in bytes to a file size in KB or MB.
 *
 * Uses MB when at 1MB or more.
 * Uses KB when at 1KB or more.
 * Uses bytes otherwise.
 *
 * For example:
 * 123        => 123 Bytes
 * 1234       => 1 KB
 * 123456     => 123 KB
 * 1234567    => 1 MB
 */

const bytesPerKilobyte = 1000;
const bytesPerMegabyte = 1000 * 1000;

export default function fileSize(sizeInBytes) {
  if (sizeInBytes < bytesPerKilobyte) {
    return `${sizeInBytes} Bytes`;
  }

  if (sizeInBytes < bytesPerMegabyte) {
    return `${Math.floor(sizeInBytes / bytesPerKilobyte)} KB`;
  }

  return `${Math.floor(sizeInBytes / bytesPerMegabyte)} MB`;
}
