/*
 * @Author: Kleber Pinel Bernardo da Silva
 * @Date:   2019-01-25 15:33:46
 * @Last Modified by:   Kleber Pinel Bernardo da Silva
 * @Last Modified time: 2019-01-25 15:33:54
 */

import initialState from './initialState';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = initialState();

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
