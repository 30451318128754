<!--
@Author: Kleber Pinel <kleberdasilva>
@Date:   2019-03-01T10:21:35-08:00
@Email:  kleber@hackcapital.com
@Last modified by:  Carmen Chow <carmen@hackcapital.com>
@Last modified time: 2019-04-17 13:09:00
-->

<template lang="pug">
  v-layout(row wrap)

    content-picker(
      :visible="showLDContentModal"
      @close="hideLDContent"
      @insert="insertLDContent"
    )

    v-layout.justify-end(v-if="actionsBar" row)
      div.action-item(@click.stop="showLDContent")
        // slot if want to put activator btn above input field
        slot(name="activator")
      div.action-item
        slot(name="action-one")
      div.action-item
        slot(name="action-two")

    v-flex(:class="inputSize")
      v-textarea(
        data-cy="message-body"
        v-if="fieldType === 'textarea'"
        :value="value"
        @input="onInput"
        @blur="saveCaretData(input)"
        :label="label"
        :outline="outlineField"
        :ref="refName"
        :rows="rows"
        :no-resize="noResize"
        :disabled="disabled"
        prepend-icon="subject"
        hide-details
      )

      v-text-field(
        data-cy="message-body"
        v-if="fieldType === 'input'"
        :value="value"
        @input="onInput"
        @blur="saveCaretData(input)"
        :label="label"
        :outline="outlineField"
        :ref="refName"
        :disabled="disabled"
        :box="box"
        :prepend-icon="icon"
      )

      rich-textarea-editor(
        v-if="fieldType === 'quill'"
        :value="value"
        :disabled="disabled"
        @input="onInput"
      )

    v-layout.mt-4
      //- here in case the activator should go below the input field
      v-flex.pt-0.pr-0(
        v-if="!hideActivator"
        :class="activatorSize"
        @click.stop="showLDContent"
      )
        slot(name="activator-bottom")
</template>

<script>
import TitleBar from '@/Core/components/TitleBar';
import RichTextareaEditor from './richTextareaEditor/RichTextEditor';
import ContentPicker from './richTextareaEditor/ContentPicker';
import caretPosition from '@/Core/mixins/caret-positioning';

export default {
  components: {
    TitleBar,
    ContentPicker,
    RichTextareaEditor,
  },
  mixins: [caretPosition],
  props: {
    hideActivator: {
      type: Boolean,
      default: false,
    },
    label: String,
    value: String,
    fieldType: String,
    refName: String,
    disabled: {
      type: Boolean, // disable field
      default: false,
    },
    // vuetify input styles
    outlineField: Boolean, // add border to input
    icon: String,
    box: Boolean,
    rows: String,
    noResize: Boolean,
    inputSize: {
      type: String,
      default: 'xs12',
    },
    activatorSize: {
      type: String,
      default: 'xs12',
    },
    actionsBar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showLDContentModal: false,
    };
  },
  watch: {
    value() {
      if (this.input) this.saveCaretData(this.input);
    },
  },
  computed: {
    input() {
      if (this.$refs[this.refName]) {
        return this.$refs[this.refName].$refs.input;
      } else {
        return;
      }
    },
    inputText: {
      get() {
        return this.value;
      },
      set(newValue) {
        return newValue;
      },
    },
  },
  methods: {
    onInput(newValue) {
      this.inputText = newValue;
      this.$emit('input', newValue);
    },
    insertLDContent($event) {
      this.hideLDContent();
      // Ensure that the input is focused properly. Set execution to end of queue
      window.setTimeout(() => {
        this.input.focus();
        document.execCommand('insertText', false, $event);
      }, 0);
    },
    showLDContent() {
      this.showLDContentModal = true;
    },
    hideLDContent() {
      this.showLDContentModal = false;
    },
  },
};
</script>

<style scoped>
.action-item {
  width: auto;
}

.edit-html {
  white-space: pre-wrap;
  font-family: monospace;
  font-size: 13px;
}

.edit-html >>> textarea {
  margin: 0px 0px;
  height: calc(100vh - 500px);
}
</style>
