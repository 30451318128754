<template>
  <ld-persistent-banner
    v-if="visible"
    :error="isAccessBlocked"
    :text="text"
    :link="link"
    :buttonText="cta"
  />
</template>

<script>
import LdPersistentBanner from './LdPersistentBanner';

const AccountStates = Object.freeze({
  // within 14 days of failed charge
  Expiring: 'ccexpired_pending',
  // 14 - 45 days after failed charge
  Expired: 'ccexpired',
  // after 45 days of failed charge or after account has been cancelled
  // and current billing cycle ended
  Cancelled: 'cancelled',
  // user cancelled account but time still remains until end of billing cycle
  CancelPending: 'cancelled_pending',
  // subscription on hold
  Paused: 'paused',
  PausedPending: 'paused_pending',
});

export default {
  components: { LdPersistentBanner },
  props: {
    user: {
      type: Object,
      default: null,
    },
    upgradeLink: {
      type: String,
    },
    updateLink: {
      type: String,
    },
  },
  computed: {
    text() {
      if (this.subscription) {
        switch (this.subscription.txt_status) {
          case AccountStates.Expiring:
            return 'You have an outstanding invoice. Update payment method';
          case AccountStates.Expired:
            return 'You have an expired credit card'
          case AccountStates.CancelPending:
            const { expires_in_days } = this.user.subscriptionOverview;
            const units = expires_in_days === 1 ? 'day' : 'days';
            if (expires_in_days > 0 && expires_in_days <= 30) {
              return `You have <strong>${expires_in_days} ${units}</strong> remaining to access LionDesk`;
            }
            return 'Your account is no longer active';
          case AccountStates.Cancelled:
            return 'Your account is no longer active';
          case AccountStates.Paused:
            return 'Your account subscription is paused';
          case AccountStates.PausedPending:
            const endDate = this.subscription.dt_subscription_end.slice(0, 10);
            return `Your account will be paused on ${endDate}`;
          default:
            return '';
        }
      }
    },
    cta() {
      const cancelledStates = [AccountStates.CancelPending, AccountStates.Cancelled];
      if (this.subscription && cancelledStates.includes(this.subscription.txt_status)) {
        return 'Subscribe Now';
      }
      if (
        this.subscription &&
        [AccountStates.Paused, AccountStates.PausedPending].includes(this.subscription.txt_status)
      ) {
        return 'Contact Liondesk';
      }
      return 'Update payment method';
    },
    isAccessBlocked() {
      return (
        this.subscription &&
        (this.subscription.txt_status === AccountStates.Expired ||
          this.subscription.txt_status === AccountStates.Cancelled ||
          this.subscription.txt_status === AccountStates.Paused)
      );
    },
    isInvoiceOutstanding() {
      return (
        this.user &&
        this.user.subscriptionOverview &&
        Object.values(AccountStates).includes(this.user.subscriptionOverview.txt_status)
      );
    },
    subscription() {
      if (this.isInvoiceOutstanding) {
        return this.user.subscriptionOverview;
      }
      return null;
    },
    link() {
      if (this.subscription && this.subscription.txt_status == AccountStates.Paused) {
        return 'mailto:billing@liondesk.com';
      }
      if (this.subscription && [AccountStates.Expired, AccountStates.Expiring].includes(this.subscription.txt_status)) {
        return this.updateLink;
      }
      return this.upgradeLink;
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  watch: {
    user: {
      handler(value) {
        // show banner only if outstanding invoice detected
        this.visible = this.isInvoiceOutstanding;
        if (this.visible) {
          // notify parent whether or not this banner is visible
          this.$emit('show', this.visible);
          // notify parent whether or not user access should be disabled
          this.$emit('block', this.isAccessBlocked);
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>