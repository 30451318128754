/**
 * Util function to set delay while typing
 * @param {function} callback is the callback function after the type delay
 * @param {array} args is the arguments we want to pass to the callback
 */

export const debounce = (callback, args) => {
  return setTimeout(() => {
    callback(...args);
  }, 500);
};