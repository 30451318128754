/*
 * @Author: Ethan Dinnen
 * @Date:   2018-11-30 11:03:26
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-01-15 12:12:07
 */

import initialState from './initialState';

const reset = state => {
  Object.assign(state, initialState());
};

const resetCampaigns = state => {
  state.campaigns = [];
};

const updateTransaction = (state, value) => {
  state.transaction = value;
};

const updateStages = (state, value) => {
  state.stages = value;
};

const updateStakeholders = (state, value) => {
  state.stakeholders = value;
};

const updateCampaigns = (state, value) => {
  state.campaigns = [...state.campaigns, value];
};

const tasksLoading = (state, value) => {
  state.tasksLoading = value;
};

const setIncompleteKCDs = (state, value) => {
  state.kcdsIncomplete = [...value];
};

const setPlans = (state, value) => {
  state.plans = [...value];
};

export default {
  reset,
  resetCampaigns,
  updateTransaction,
  updateStages,
  updateStakeholders,
  updateCampaigns,
  tasksLoading,
  setIncompleteKCDs,
  setPlans,
};
