<template>
  <svg
    data-icon="failed-status"
    viewBox="0 0 64 64"
    >
    <g id="Twilio-Registration---OTP-Modals" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Text-Copy-19" transform="translate(-799.000000, -193.000000)" fill="#C92F2F" fill-rule="nonzero">
            <g id="Group-4" transform="translate(799.000000, 193.000000)">
                <path d="M42.2755094,18.2857143 L45.7142857,21.6840963 L35.277676,32 L45.7142857,42.3159037 L42.2755094,45.7142857 L31.9997143,35.5587143 L21.7244906,45.7142857 L18.2857143,42.3159037 L28.722324,32 L18.2857143,21.6840963 L21.7244906,18.2857143 L31.9987143,28.4407143 L42.2755094,18.2857143 Z" id="Combined-Shape"></path>
                <path d="M32,0 C14.326888,0 0,14.326888 0,32 C0,49.673112 14.326888,64 32,64 C49.673112,64 64,49.673112 64,32 C64,14.326888 49.673112,0 32,0 Z M32,4.75428571 C47.0473925,4.75428571 59.2457143,16.9526075 59.2457143,32 C59.2457143,47.0473925 47.0473925,59.2457143 32,59.2457143 C16.9526075,59.2457143 4.75428571,47.0473925 4.75428571,32 C4.75428571,16.9526075 16.9526075,4.75428571 32,4.75428571 Z" id="Oval"></path>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '$vuetify.icons.failed-status',
    },
  },
};
</script>
