export default {
  methods: {
    setSelectedOperator() {
      // find the selected operator in the list.
      // set selectedOperator to the one found if exact match on operator && value
      this.selectedOperator = this.operators.filter(operator => {
        return operator.operator === this.value.operator && operator.value === this.value.value;
      });

      // set selectedOperator to the operator match, in need of value
      if (this.selectedOperator && !this.selectedOperator.length) {
        this.selectedOperator = this.operators.filter(operator => {
          return operator.operator === this.value.operator && operator.needValue;
        });
      }

      if (this.selectedOperator.length) this.selectedOperator = this.selectedOperator[0];
    },
  },
};
