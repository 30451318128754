<template>
  <gmap-map
    :center="mapCenter"
    :zoom="8"
    ref="gmap"
    :options="{
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false,
    }"
    @click="mapClick($event)"
    @bounds_changed="dragSearch($event)"
  >
    <div slot="visible">
      <div class="gm-count" v-if="listings.length >= 500">
        <span>
          Only displaying {{ listings.length }} results. Zoom in or apply a filter to see more listings
        </span>
      </div>
      <v-layout>
        <v-flex pr-4 shrink>
          <v-btn
            class="gm-custom-control gm-polygon-control"
            @click="polygonClick"
            :color="drawPolygonsState ? 'primary' : 'white'"
            medium
          >
            <v-icon small class="ml-0">create</v-icon>
            {{ drawButtonLabel }}
          </v-btn>
        </v-flex>
        <v-flex grow>
          <div class="gm-drag-search-container">
            <v-checkbox v-model="searchDrag" />
            <span class="gm-drag-search-info">Search when the map moves</span>
          </div>
        </v-flex>
      </v-layout>

      <div v-if="drawPolygonsState">
        <div class="gm-custom-control gm-save-control">
          <v-btn
            block
            color="white"
            :disabled="polygonPath.length < 3"
            @click="showSave = !showSave"
          >
            SAVE
          </v-btn>
        </div>
        <div class="gm-custom-control gm-apply-control" @click="applyPolygon">
          <v-btn color="white" block>APPLY</v-btn>
        </div>
        <div class="gm-custom-control gm-clear-control" @click="clearPolygon">
          <v-btn color="white" block>CLEAR</v-btn>
        </div>
      </div>

      <v-card v-if="showSave" class="show-save-card">
        <v-container>
          <v-flex xs12>Save This Area:</v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="polygonName"
              hint="Search for your saved area using search bar​"
              persistent-hint
            />
          </v-flex>
          <v-flex xs12>
            <v-btn
              small
              color="primary"
              :disabled="disablePolygon"
              @click="savePolygon"
            >
              SAVE
            </v-btn>
            <v-btn
              small
              color="primary"
              @click="showSave = !showSave"
            >
              CANCEL
            </v-btn>
          </v-flex>
        </v-container>
      </v-card>

    </div>

    <gmap-info-window
      :options="infoOptions"
      :position="infoWindowPos"
      :opened="infoWinOpen"
      @closeclick="infoWinOpen = false"
    >
      <listing-card
        :listing="listing"
        v-if="listing._source"
        noHover
        :onItemClicked="itemClicked"
      />
    </gmap-info-window>

    <gmap-cluster>
      <gmap-marker
        :key="index"
        v-for="(l, index) in listings"
        :position="l.position"
        :clickable="!drawPolygonsState"
        @click="showInfoWindow(l)"
      ></gmap-marker>
    </gmap-cluster>

    <gmap-polygon
      :paths="polygonPath"
      :editable="true"
      :options="{
        geodesic: true,
        strokeColor: '#3C3DEF',
        fillColor: '#3C3DEF',
      }"
      @paths_changed="setPolygonPaths($event.g[0].g)"
    ></gmap-polygon>

  </gmap-map>
</template>

<script>
import {
  mapGetters, mapState, mapActions, mapMutations,
} from 'vuex';
import get from 'lodash/get';
import GmapCluster from 'vue2-google-maps/dist/components/cluster'; // replace src with dist if you have Babel issues
import ListingCard from 'lion-vue-mls/components/MlsListingCardGridContainer';
import { findCallers } from '../helpers/constants';

// Initialize the bounds changed timer
let timer;

export default {
  name: 'Map',
  components: { ListingCard, GmapCluster },
  props: {
    polygon: {
      type: Boolean,
      default: false,
    },
    onItemClicked: {
      type: Function,
      default: () => {
        // eslint-disable-next-line no-alert
        alert('Calback not implemented for Item Clicked. [MlsListingCard.vue]');
      },
    },
  },
  watch: {
    searchDrag: {
      handler(val) {
        localStorage.setItem('searchDrag', val);
      },
    },
    mapCenter: {
      handler(val) {
        this.map.setCenter(val)
      }
    }
  },
  data() {
    return {
      infoWindowPos: null,
      infoWinOpen: false,
      infoOptions: {},
      listing: {},
      polygonName: '',
      loaded: false,
      searchDrag: true,
      map: null,
      mapInitialized: false,
      showSave: false,
    };
  },
  computed: {
    ...mapGetters({
      listings: 'listing/geojson',
      findCaller: 'listing/findCaller',
    }),
    ...mapState({
      allListings: state => state.listing.listings,
      polygonPath: state => state.polygon.polygonPath,
      drawPolygonsState: state => state.polygon.drawPolygonsState,
      mapBounds: state => state.search.mapBounds,
      mapCenter: state => state.search.mapCenter,
    }),
    disablePolygon() {
      return !(this.polygonPath.length > 2 && this.polygonName);
    },
    header() {
      return `${this.listing._id}: ${get(this.listing, '_source.UnparsedAddress')}`;
    },
    subHeader() {
      return `$ ${get(this.listing, '_source.ListPrice', 0).toFixed(2)}`;
    },
    drawButtonLabel() {
      return this.drawPolygonsState ? 'Drawing on map' : 'Draw on map';
    }
  },
  methods: {
    ...mapActions({
      setPolygonPaths: 'polygon/setPolygonPaths',
      addPolygonPath: 'polygon/addPolygonPath',
      savePolygonPaths: 'polygon/savePolygonPaths',
      setDrawPolygonState: 'polygon/setDrawPolygonState',
      setMapBounds: 'search/setMapBounds',
      updateSelectedSearch: 'search/updateSelectedSearch',
      updateSavedSearch: 'search/updateSavedSearch',
      find: 'listing/find',
      setFindCaller: 'listing/setFindCaller',
    }),
    ...mapMutations({
      clearPolygonPaths: 'polygon/clearPolygonPaths',
    }),
    polygonClick() {
      this.setDrawPolygonState(!this.drawPolygonsState);
      const mapElementClasses = this.$el.querySelector('.gm-style').firstElementChild.classList;
      if (this.drawPolygonsState) {
        mapElementClasses.add('active-pen');
        return;
      }
      mapElementClasses.remove('active-pen');
      this.showSave = false;
    },
    clearPolygonForm() {
      this.clearPolygonPaths();
      this.polygonName = '';
    },
    async savePolygon() {
      this.showSave = false;
      await this.savePolygonPaths(this.polygonName);

    },
    async applyPolygon() {
      const coordinates = this.polygonPath.map(latLng => {
        return [latLng.lng, latLng.lat];
      });
      await this.updateSelectedSearch({
        searchText: 'temp',
        searchType: 'polygon',
        coordinates,
      });
      await this.find({caller: "map_apply_polygon"});
    },
    async clearPolygon() {
      this.showSave = false;
      this.clearPolygonForm();
      await this.updateSelectedSearch({
        searchText: null,
        searchType: null,
        coordinates: [],
      })
      const bounds = this.getBounds(this.map);
      this.setMapBounds(bounds);
      this.find({caller: "map_clear_polygon"});
      this.setMapBounds(null);
    },
    mapClick(event) {
      if (this.drawPolygonsState) {
        this.addPolygonPath(event);
      }
    },
    itemClicked(item) {
      this.onItemClicked(item, 'MlsMap');
    },
    showInfoWindow(data) {
      this.listing = this.allListings.find(listing => listing._id === data.position.id);
      this.infoWindowPos = data.position;
      this.infoWinOpen = true;
    },
    /**
     * Returns coordinates for the corners and center of the map view.
     * Google only gives us NE and SW, so extrapolate NW and SE.
     */
    getBounds(map) {
      return {
        NE: {
          lat: map.getBounds().getNorthEast().lat(),
          lng: map.getBounds().getNorthEast().lng(),
        },
        NW: {
          lat: map.getBounds().getSouthWest().lat(),
          lng: map.getBounds().getNorthEast().lng(),
        },
        SE: {
          lat: map.getBounds().getNorthEast().lat(),
          lng: map.getBounds().getSouthWest().lng(),
        },
        SW: {
          lat: map.getBounds().getSouthWest().lat(),
          lng: map.getBounds().getSouthWest().lng(),
        },
        center: {
          lat: map.center.lat(),
          lng: map.center.lng(),
        },
      };
    },
    /**
     * Calls find() on the polygon defined by the edges of the map window
     * when map dragging or zooming stops
     */
    async dragSearch(event) {
      if (!event) return;
      if (this.findCaller && this.findCaller !== findCallers.MAP_DRAG_SEARCH) {
        // When a search performed didn't come from scrolling throught the
        // map, we set the next caller as MAP_DRAG_SEARCH so it can search
        // when the map is dragged. We need this because, in every typeahead
        // search, we need to change the location of the map by editing its
        // center location, and when this value is set, it triggers the
        // bounds_changed which will call dragSearch and we don't want to
        // perform the search inside the timer.
        this.setFindCaller(findCallers.MAP_DRAG_SEARCH);
        return;
      }
      // The user has unselected the search on drag option so do nothing
      // or user is using polygon search so do nothing
      if (!this.searchDrag || this.polygonPath.length) return;
      // Reset the timer to wait until user has stopped dragging
      clearTimeout(timer);
      // Wait 1s after pan/zoom stops before search
      timer = setTimeout(() => {
        if (!this.mapInitialized) {
          this.mapInitialized = true;
          return;
        }

        const bounds = this.getBounds(this.map);
        this.setMapBounds(bounds);
        this.find({caller: "map_drag_search"});
      }, 1000);
    },
  },
  beforeMount() {
    // Has the user set their desired drag on search option?
    const searchDrag = localStorage.getItem('searchDrag');
    if (searchDrag !== null) {
      // Assign it
      this.searchDrag = (searchDrag === 'true');
    }
  },
  mounted() {
    // Wait for the map to create
    this.$refs.gmap.$mapPromise.then(map => {
      // Set the map object in the data for later
      this.map = map;
    });
  },
};
</script>

<style lang="scss">
.vue-map-container {
  height: 100% !important;
}
div:has(.gm-style-iw) {
  width: 350px !important;
  top: 0 !important;
  left: 0 !important;
  border-radius: 2px 2px 0 0;
}

.gm-style {
  div.active-pen {
    cursor: url('../../Shared/assets/cursor_pen.png'), auto !important;
    .cluster {
      z-index: -1;
      pointer-events: none;
      cursor: url('../../Shared/assets/cursor_pen.png'), auto !important;
    }
  }
}

.gm-options-container {
  display: flex;
  flex-direction: row;
}

.gm-count {
  display: flex;
  flex-direction: row;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  text-shadow: black 1px 1px 6px;
  justify-content: center;
  padding: 5px 0;
  z-index: 7;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  .v-input--checkbox {
    position: relative;
    top: 1px;
    left: 4px;
    margin-top: unset;
    padding-top: unset;
  }
  .gm-drag-search-info {
    margin: auto 0;
    padding-right: 12px;
    color: rgba(0, 0, 0, 0.71);
  }
}

.gm-drag-search-container {
  background-color: white;
  border-radius: 3px;
  width: 230px;
  height: 30px;
  margin-top: 10px;
  position: relative;
  .v-input--checkbox {
    position: relative;
    top: 1px;
    left: 4px;
    margin-top: unset;
    padding-top: unset;
    display: inline-block;
    width: 35px;
  }
  .gm-drag-search-info {
    margin: auto 0;
    padding-right: 12px;
    color: rgba(0, 0, 0, 0.71);
    position: absolute;
    top: 5px;
  }
}


.gm-custom-control {
  margin-left: 2em;
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 2px;
}

.gm-polygon-control {
  left: 20px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.gm-save-control .gm-apply-control .gm-clear-control {
  width: 60px;
  text-align: center;
}

.gm-save-control .gm-clear-control .gm-apply-control span {
  display: inline-block;
  vertical-align: middle;
  font-weight: 500;
  color: var(--v-primary-base);
}

.gm-save-control [disabled="disabled"] {
  background-color: #f5f5f5 !important;
}

.gm-custom-control i {
  margin: 5px;
}

.gm-style .gm-style-iw-c {
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  background-color: unset;
  border-radius: 4px;
  padding: 0;
  .gm-style-iw-d {
    overflow: hidden !important;
  }
  button.gm-ui-hover-effect {
    top: 0px !important;
    right: 0px !important;
    width: 30px !important;
    height: 30px !important;
    img {
      width: 22px !important;
      height: 22px !important;
      margin: 5px !important;
    }
  }
  .listing-card-grid {
    margin: 0 !important;
  }
}

.show-save-card {
  width: 300px;
  top: 60px;
  left: 20px;
}
</style>
