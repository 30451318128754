/*
 * @Author: Ethan Dinnen
 * @Date:   2019-01-15 12:25:24
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-01-16 10:13:12
 */
export default {
  methods: {
    handleUpdatePagination() {
      this.handlePagination(this.mutablePagination);
    },
    async handleDeleteSelected() {
      await this.deleteSelected(this.selected);
      this.selected = [];
    },
    selectOne(data) {
      data.selected = !data.selected;
    },
    selectAll() {
      const isTransactionsTable = this.transactions;
      const total = isTransactionsTable
        ? this.transactions.total
        : this.items.length;
      if (
        this.selected.length !== this.mutablePagination.rowsPerPage &&
        this.selected.length !== total
      ) {
        this.selected = isTransactionsTable
          ? this.transactions.data
          : this.items;
        return;
      }
      this.selected = [];
    },
    async handlePageChange(page) {
      this.mutablePagination.page = page;
      await this.handlePagination(this.mutablePagination);
    },
    handleSort(column) {
      if (!column.sortable) {
        return;
      }

      const { value } = column;
      const { descending, sortBy } = this.mutablePagination;

      if (sortBy !== value) {
        this.mutablePagination.sortBy = value;
      } else {
        this.mutablePagination.descending = !descending;
      }

      this.handlePagination(this.mutablePagination);
      this.sortItems();
    },
    async deleteSelected([head, ...tail]) {
      if (!head) return this.handlePageChange(1);

      const { id } = head.data[0];
      await this.handleDelete(id);
      return this.deleteSelected(tail);
    },
  },
};
