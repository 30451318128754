/**
 * @author: Nico Alimin <nico@hackcapital.com>
 * @date:   2018-11-30 14:03:41
 * @lastModifiedBy:   Nico Alimin <nico@hackcapital.com>
 * @lastModifiedTime: 2018-12-04 15:13:33
 *
 * Configs file for the new action selector
 *
 * @copyright (c) 2018 Hack Capital
 */

const ACTION_TYPES = [
  {
    key: 'email',
    text: 'Email',
    icon: 'email',
    db: 'Email',
    createAction: 'Email Contact',
    disabled: false,
  },
  {
    key: 'text',
    text: 'Text',
    icon: 'tablet_android',
    db: 'Text',
    createAction: 'Text Contact',
    disabled: false,
  },
  // {
  //   key: 'phone',
  //   text: 'Phone Transcript',
  //   icon: 'phone',
  //   db: 'Phone',
  //   createAction: 'Call Contact',
  //   disabled: true,
  // },
  // {
  //   key: 'direct_mail',
  //   text: 'Direct Mail',
  //   icon: 'send',
  //   db: 'Direct Mail Letter',
  //   disabled: true,
  // },
  {
    key: 'task',
    text: 'Task',
    icon: 'playlist_add_check',
    disabled: false,
  },
  // {
  //   key: 'schedule',
  //   text: 'Schedule',
  //   icon: 'calendar_today',
  //   disabled: true,
  // },
];

function getActionType(type) {
  const actionType = ACTION_TYPES.find(action => action.key === type);
  return actionType ? actionType.text : '';
}

const AGENT_ACTION_TYPES = {
  none: 'Agent Task - No Reminder',
  email: 'Agent Task - Email Reminder',
  text: 'Agent Task - Text Reminder',
  phone: 'Agent Task - Call Reminder',
  'Direct Mail': 'Agent Task - Direct Mail: Letter (Manual)',
};

/**
 * Given the name of an agent action type, return the normalized string
 *
 * @param {String} name The name of the agent action type we want to find the key
 * @returns {String} The normalized representation of the agent action type
 */
function getAgentActionTypeByName(name) {
  if (!name) return '';
  const keys = Object.keys(AGENT_ACTION_TYPES);
  return keys.find(key => AGENT_ACTION_TYPES[key] === name);
}

const templateTypeIcon = {
  Email: 'email',
  Text: 'tablet_android',
  Phone: 'phone',
  'Direct Mail Letter': 'send',
  'Direct Mail Postcard': 'send',
};

const TIMING_TYPES = {
  IMMEDIATELY: {
    key: 0,
    name: 'Immediately',
    db: 'immediately',
    supported: true,
  },
  DELAY_BY_DAYS: {
    key: 1,
    name: 'Delay by Day and Time',
    db: 'delay_by_days',
    supported: true,
  },
  EXACT_DATE_TIME: {
    key: 2,
    name: 'Exact Date and Time',
    db: 'exact_date_time',
    supported: false,
  },
  DELAY_BY_MINUTES: {
    key: 3,
    name: 'Delay by Minutes',
    db: 'delay_by_minutes',
    supported: true,
  },
  RECURRING: { key: 4, name: 'Recurring', db: 'recurring', supported: false },
};

/**
 * Returns the complete timing object configuration, given the timing name
 * @param {String} name The desired name of a timing type
 * @returns {Object} The timing type configurations as specified in TIMING_TYPES
 *                   if exists. Otherwise return an empty object
 */
function getTimingTypeByField(name, field = 'name') {
  const keys = Object.keys(TIMING_TYPES);
  for (let i = 0; i < keys.length; i++) {
    if (TIMING_TYPES[keys[i]][field] === name) {
      return TIMING_TYPES[keys[i]];
    }
  }
  return {};
}

const DEFAULT_TEAMMATES = [
  { text: 'Me', value: null },
];

const TASK_REMINDER_TYPES = [
  { id: 1, value: 'email', icon: 'email' },
  { id: 2, value: 'phone', icon: 'phone' },
  { id: 3, value: 'text', icon: 'tablet_android' },
];

export {
  ACTION_TYPES,
  AGENT_ACTION_TYPES,
  getAgentActionTypeByName,
  templateTypeIcon,
  getActionType,
  TIMING_TYPES,
  getTimingTypeByField,
  DEFAULT_TEAMMATES,
  TASK_REMINDER_TYPES,
};
