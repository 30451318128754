import vmiFeathers from 'vmi-feathers-client';

const setPolygonPaths = ({ commit }, data) => {
  const polygon = data.map(point => ({
    lat: point.lat(),
    lng: point.lng(),
  }));
  commit('setPolygonPaths', polygon);
};

const addPolygonPath = ({ commit }, data) => {
  const polygon = { lat: data.latLng.lat(), lng: data.latLng.lng() };
  commit('addPolygonPath', polygon);
};

const savePolygonPaths = ({ commit, state }, polygonName) => {
  vmiFeathers()
    .service('polygon')
    .create({
      query: {
        name: polygonName,
        coordinates: state.polygonPath,
      },
    })
    .catch(err => {
      console.error('polygon create() failed:', err);
    });
};

const setDrawPolygonState = ({ commit }, state) => {
  commit('setDrawPolygonState', state);
};

export default {
  setPolygonPaths,
  addPolygonPath,
  savePolygonPaths,
  setDrawPolygonState,
};
