<!--
  @Author: Ethan Dinnen
  @Date:   2019-01-30 15:42:07
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-02-25 13:23:33
-->
<template>
  <v-flex class="xs12 md5 new-type-forms" id="wizard-watch-height">
    <FormSection
      v-for="(template, index) in templates"
      :index="index"
      :mandatory="template.mandatory || templates.length < 2"
      :position="template.position"
      :key="`form-${template.tmp_id ? template.tmp_id : template.id}`"
      :name="template.name"
      :tmp_id="template.tmp_id ? template.tmp_id : template.id"
      :fields="template.template_fields"
      :deleteField="deleteField"
      :deleteFields="deleteFields"
      :deleteTemplate="deleteTemplate"
      :addField="addField"
      :updateField="updateField"
      :addRelativeField="addRelativeField"
      :addSection="addSection"
      :updateTemplateName="updateTemplateName"
      :updateFieldName="updateFieldName"
      :getTitleErrors="getTitleErrors"
      :templates="templates"
      :moveField="moveField"
      :moveTemplate="moveTemplate"
    />
  </v-flex>
</template>

<script>
import FormSection from './FormSection';

export default {
  components: {
    FormSection,
  },
  props: {
    templates: Array,
    deleteField: Function,
    deleteFields: Function,
    deleteTemplate: Function,
    addField: Function,
    updateField: Function,
    addRelativeField: Function,
    addSection: Function,
    updateTemplateName: Function,
    updateFieldName: Function,
    sectionsGroup: [Object, Array],
    moveField: Function,
    moveTemplate: Function,
  },
  methods: {
    getTitleErrors(name, id) {
      if (!name) return;
      if (
        this.templates.filter(t => {
          if (t.id) {
            return t.name === name && t.id !== id;
          }
          return t.name === name && t.tmp_id !== id;
        }).length > 0
      ) {
        return ['Sections must have unique names'];
      }
    },
  },
};
</script>

<style lang="scss">
.new-type-forms {
  overflow-y: scroll;
  padding: 1px;
}
</style>
