<!--
    @Author: Ethan Dinnen
    @Date:   2018-12-14 9:59:30
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-02-15 15:38:26
-->
<template>
  <v-layout align-space-between justify-space-between fill-height row>

    <start-plan
      :selected-transaction-id="selectedTransactionId"
      @on-plan-started="handleRefresh"
      ref="startPlan"
    />
    <v-flex>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="items"
          header-text="text"
          header-key="value"
          :loading="isLoading"
          :total-items="mutablePagination.totalItems"
          @update:pagination="handlePagination"
          :pagination.sync="mutablePagination"
          :rows-per-page-items="rowsPerPageItems"
          select-all
          item-key="id"
          v-model="selected"
          hide-actions
        >
          <template slot="headers" slot-scope="items">
            <ListHeader
              :data="items"
              :total="totalItems"
              :selected="selected"
              @click:select-all="selectAll"
              @click:delete-select="
                handleDeleteSelected(plans ? 'plan' : 'transaction')
              "
              :pageLimit="mutablePagination.rowsPerPage"
              :setSortBy="handleSort"
              :sortField="mutablePagination.sortBy"
              :sortOrder="mutablePagination.descending"
              :plans="plans"
            />
          </template>
          <template slot="items" slot-scope="data">
            <ListData
              :data="data"
              :selected="selected"
              :handleDelete="confirmDelete"
              :handleEdit="handleEdit"
              @click:expand="data.expanded = !data.expanded"
              @click:select-one="selectOne"
              @click:delete="confirmDelete"
              :showDeleteOnly="showDeleteOnly"
              :showEdit="showEdit"
              :showPlanModal="handleShowPlanModal"
              :plans="plans"
            />
          </template>
        </v-data-table>
        <v-layout justify-center>
          <v-pagination
            :length="totalPages"
            v-model="mutablePagination.page"
            @input="handlePageChange"
            :total-visible="7"
            v-show="totalPages > 1"
            class="pa-1"
          ></v-pagination>
        </v-layout>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import ListData from './TransactionsListData';
import ListHeader from './TransactionsListHeader';
import TableMethods from '@/Core/mixins/TableMethods';
import StartPlan from './StartPlanModal';

export default {
  components: {
    ListData,
    ListHeader,
    StartPlan,
  },
  props: {
    headers: Array,
    items: Array,
    rowsPerPageItems: Array,
    handlePagination: Function,
    sortItems: Function,
    handleRefresh: {
      type: Function,
      required: false,
      default: new Function,
    },
    confirmDelete: Function,
    handleDelete: Function,
    handleEdit: Function,
    isLoading: Boolean,
    pagination: Object,
    transactions: Object,
    showDeleteOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    showEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    plans: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [TableMethods],
  data() {
    return {
      selected: [],
      mutablePagination: this.pagination,
      selectedTransactionId: null,
    };
  },
  computed: {
    totalItems() {
      return this.items.length;
    },
    totalPages() {
      return Math.ceil(
        this.mutablePagination.totalItems / this.mutablePagination.rowsPerPage
      );
    },
  },
  methods: {
    handleShowPlanModal(data) {
      this.selectedTransactionId = data;
      this.$refs.startPlan.openModal();
    },
  },
};
</script>
