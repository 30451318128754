/*
 * @Author: Kleber Pinel Bernardo da Silva
 * @Date:   2019-01-11 09:17:23
 * @Last Modified by:   Kleber Pinel Bernardo da Silva
 * @Last Modified time: 2019-01-15 11:08:02
 */

const stageName = function() {
  if (this.stages.length === 0) return '';
  return this.stages.find(stage => stage.id === this.stage).title;
};

const typeName = function() {
  return this.transactionTypes.find(type => type.id === this.type).name;
};

const allContacts = function() {
  return [...this.selectedContacts, ...this.newContacts];
};

const currentMarket = function() {
  if (this.marketOptions.length) {
    return this.marketOptions[0].text;
  }
  return null;
};

const isLastStep = function() {
  return this.currentStage === 'transaction-contacts';
};

const activeStageName = function() {
  if (!this.stages) return "";
  const currentStage = this.stages.find(stage => stage.id === this.activeStage);
  return currentStage ? currentStage.text || currentStage.title : "";
};

export default {
  stageName,
  typeName,
  allContacts,
  currentMarket,
  isLastStep,
  activeStageName,
};
