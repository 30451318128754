import formTemplateNames from '@/Core/constants/formTemplateNames';
import transactionInputTypes from '@/Core/constants/transactionInputTypes';
import templateIds from '@/Core/constants/templateIds';
import { genId } from '@/Core/mixins/genId';

// The default values for a new relative field
const newRelativeTemplate = {
  label: '',
  type: 'relative',
  position: null,
  field_source: null,
  meta: {
    reference: templateIds.START_DATE,
    relativity: 'before',
  },
};

const templateLabels = {
  START_DATE: 'Start Date',
  CLOSING_DATE: 'Closing Date',
};

export default {
  data() {
    return {
      isDragging: false,
      fieldIsDragging: false,
      dialog: false,
      newRelative: Object.assign({}, newRelativeTemplate),
      relativeDrawer: false,
      relativity: 'before',
      relDrawerId: genId(),
      referenceErrors: [],
      menuOpen: null,
      fieldUniqueId: genId(),
      newValid: false,
      descendants: new Set(),
      fieldOpts: {},
      kcdDialog: false,
      numberValidator: [
        v => (!!v && v.match(/^\d+$/g) !== null) || 'Please enter a number',
      ],
    };
  },
  computed: {
    filteredFields() {
      if (this.name === formTemplateNames.FINANCIALS) {
        return this.fields.filter(field => field.position !== 4);
      }
      return this.fields;
    },
    isFinancials() {
      return this.name === formTemplateNames.FINANCIALS;
    },
    // Only append the icon to fields in KEY_CONTRACT_DATES
    appendIcon() {
      return this.name === formTemplateNames.KEY_CONTRACT_DATES
        ? 'more_vert'
        : null;
    },
    dragOptions() {
      return {
        animation: 50,
        group: 'sections',
        ghostClass: 'ghost',
      };
    },
    fieldDragOptions() {
      return {
        animation: 50,
        group: `fields-${this.tmp_id}`,
        ghostClass: 'ghost',
      };
    },
    sectionsGroup: {
      get() {
        return this.templates;
      },
      set() {
        return;
      },
    },
    fieldsGroup: {
      get() {
        return this.fields;
      },
      set() {
        return;
      },
    },
    formNames() {
      return formTemplateNames;
    },
    drawerWidth() {
      return window.innerWidth / 3;
    },
    // Create the menu items for selection of relative key contract dates
    kcdFieldOptions() {
      return this.fields.map(({ label, meta: { referenceId } }) => {
        return { text: label, value: referenceId };
      });
    },
    // Make a copy of the fields with an updated relative field for ancestor checking
    fieldsWithUpdate() {
      const withUpdate = [...this.fields];
      if (this.newRelative.position) {
        withUpdate[this.newRelative.position - 1] = this.newRelative;
      }
      return withUpdate;
    },
    inputTypes() {
      return transactionInputTypes;
    },
  },
  watch: {
    // Watch the new relative field's reference to check for loops
    'newRelative.meta.reference': {
      handler(reference, oldReference) {
        if (reference === oldReference) return;
        // Disallow referencing self
        if (reference === this.newRelative.meta.referenceId) {
          this.referenceErrors = ['Cannot reference self'];
          return;
        }
        // Check for an absolute ancestor
        if (!this.hasAbsoluteAncestor(reference, this.fields.length - 1)) {
          // No absolute ancestor found. Show error and disable submit
          this.referenceErrors = [
            'Current selection does not relate back to an absolute date',
          ];
        } else if (this.referenceErrors.length) {
          // Absolute ancestor found & previous errors shown, so reset them
          this.referenceErrors = [];
        }
      },
    },
  },
  methods: {
    shouldDeleteField(options) {
      const { fieldPosition } = options;
      const field = this.fields[fieldPosition - 1];

      // Check for linked descendants
      if (
        field.type === transactionInputTypes.ABSOLUTE ||
        field.type === transactionInputTypes.RELATIVE
      ) {
        const descendants = this.findDescendants(field.meta.referenceId);
        // Field has descendants, so prompt the user for confirmation
        if (descendants.size) {
          this.descendants = descendants;
          this.fieldOpts = options;
          this.kcdDialog = true;
          return;
        }
      }
      // No descendants, so just delete the field
      this.deleteField(options);
    },
    async handleDeleteField(options) {
      const { descendants, position } = this;
      // Create an array of all the field positions to delete
      const fieldPositions = [
        options.fieldPosition,
        ...[...descendants].map(d => d.position),
      ];
      // Delete all the fields
      await this.deleteFields({
        position,
        fieldPositions,
      });
      // Close the confirmation dialog and reset related variables
      this.kcdDialog = false;
      this.descendants = new Set();
      this.fieldOpts = {};
    },
    // Set the menu open for the selected field
    toggleMenu(field = null) {
      // No field passed, so ensure no menus are open
      if (!field) this.menuOpen = null;
      const { tmp_id, id } = field;
      // Set the open menu
      if (tmp_id) {
        this.menuOpen = tmp_id;
        return;
      }
      this.menuOpen = id;
    },
    // Close and reset the relative date field drawer
    closeDrawer() {
      this.resetNewRelative();
      this.relativeDrawer = false;
    },
    // Open the relative field drawer
    toggleDrawer(field) {
      this.resetNewRelative();
      this.menuOpen = null;
      // We were passed a field, so we want to edit it by setting newRelative to equal it
      if (field.meta) {
        this.relativity = field.meta.relativity;
        this.newRelative = field;
        this.relDrawerId = genId(); // Regen the drawer key to render changes properly
      }
      this.relativeDrawer = !this.relativeDrawer;
    },
    /**
     * Checks if a relative field has an absolute ancestor
     * @param {string} reference The reference id for the parent field
     * @param {number} i The index to count down from. Ideally the number of fields
     * @return {boolean} Returns true if absolute ancestor found
     */
    hasAbsoluteAncestor(reference, i) {
      if (i === 0) return false;
      const { fieldsWithUpdate } = this;
      // Check all fields with any edited updates
      const ancestor = fieldsWithUpdate.find(
        field => field.meta.referenceId === reference
      );
      if (ancestor.type === transactionInputTypes.ABSOLUTE) return true;
      // Not found yet, go one deeper
      return this.hasAbsoluteAncestor(ancestor.meta.reference, i - 1);
    },
    /**
     * Finds all the descendant fields
     * @param {string} refId The reference id for the ancestor field
     * @param {array} bucket The bucket to put children in
     * @return {set} Returns a set of the given field's descendants
     */
    findDescendants(refId, bucket = []) {
      // Find all children
      const children = this.fields.filter(
        field => field.meta.reference === refId
      );
      // This element has no children so return the passed descendant bucket
      if (!children.length) return bucket;

      // Construct the descendants collection
      let descendants = [];
      for (const child of children) {
        // Add the child to the descendant bucket
        bucket = [...bucket, child];
        // Recursively find descendants
        const loop = this.findDescendants(child.meta.referenceId, bucket);
        // Recreate the descendants collection with the new found descendants
        descendants = [...descendants, ...loop];
      }
      // Return a set of descendants to ensure no duplicate fields included
      return new Set(descendants);
    },
    // Update a relevant value in newRelative field
    updateNewRelative(field, evt) {
      const update = Object.assign({}, this.newRelative);
      if (field.match(/^meta\..+$/g)) {
        const meta = Object.assign({}, this.newRelative.meta);
        const key = field.split('.')[1];
        meta[key] = evt;
        update.meta = meta;
      } else {
        update[field] = evt;
      }

      this.newRelative = update;
    },
    resetNewRelative() {
      this.newRelative = { ...newRelativeTemplate };
      this.relativity = 'before';
      this.relativeDrawer = false;
      this.relDrawerId = genId();
    },
    // Add or update the new field in the store
    addNewRelative() {
      const { newRelative, position, fields } = this;
      // The field has a position defined, so update the field to edit and reset
      if (newRelative.position) {
        this.updateField({ field: newRelative, position });
        this.resetNewRelative();
        this.fieldUniqueId = genId();
        return;
      }
      // The field is new, so we need to create it
      newRelative.position = fields.length + 1;
      const id = genId();
      newRelative.tmp_id = id;
      newRelative.meta.referenceId = id;
      this.addRelativeField({
        field: newRelative,
        position,
      });
      this.resetNewRelative();
    },
    inputDisabled(field) {
      // TODO ak: We're making all template forms and fields optional
      // Should this logic remain or be removed?
      if (this.name === formTemplateNames.PROPERTY_ADDRESS) return true;
      if (this.isFinancials) {
        return field.position < 5;
      }
      if (this.name === formTemplateNames.KEY_CONTRACT_DATES) {
        return (
          field.position < 3 &&
          (field.label === templateLabels.START_DATE ||
            field.label === templateLabels.CLOSING_DATE)
        );
      }
      return false;
    },
    getIcon(type) {
      return (
        {
          text: 'text_fields',
          currency: 'attach_money',
          date: 'access_time',
          percentage: '%',
          absolute: 'calendar_today',
          relative: 'date_range',
        }[type] || null
      );
    },
    isMandatory(field) {
      if (this.name === formTemplateNames.PROPERTY_ADDRESS) return true;
      if (this.isFinancials) {
        return field.position < 5;
      }
      if (this.name === formTemplateNames.KEY_CONTRACT_DATES) {
        return field.position < 3;
      }
      return false;
    },
    handleUpdateTemplateName(value) {
      this.updateTemplateName({ name: value, position: this.position });
    },
    handleUpdateFieldName(label, position, fieldPosition) {
      this.updateFieldName({ label, position, fieldPosition });
    },
    handleDeleteTemplate(position) {
      this.dialog = false;
      this.deleteTemplate(position);
    },
    getFieldErrors(label, tmp_id) {
      if (
        this.fields.filter(f => {
          if (f.id) {
            return (
              f.id !== tmp_id && f.label.toLowerCase() === label.toLowerCase()
            );
          }
          return (
            f.tmp_id !== tmp_id && f.label.toLowerCase() === label.toLowerCase()
          );
        }).length > 0
      ) {
        return ['Fields must have unique names'];
      }
    },
    // Draggable related
    handleDrop(evt) {
      const from = +this.position - 1;
      // Retrieve the to id from the state via the event's 'to' id
      const to = +evt.to.id;
      /**
       * Dispatch the moveStages transactions action
       * to commit the stage change to the database
       */
      if (from === to) {
        this.isDragging = false;
        return false;
      }
      if (to < 4) {
        this.isDragging = false;
        return false;
      }
      this.moveTemplate({ from, to });
      this.isDragging = false;
      return true;
    },
    onMove({ relatedContext, draggedContext }) {
      if (
        this.name === formTemplateNames.PROPERTY_ADDRESS ||
        this.isFinancials ||
        this.name === formTemplateNames.KEY_CONTRACT_DATES
      )
        return false;
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    handleFieldDrop(evt) {
      // const from = +this.position - 1;
      const from = +evt.from.id;
      // Retrieve the to id from the state via the event's 'to' id
      const to = +evt.to.id;
      /**
       * Dispatch the moveStages transactions action
       * to commit the stage change to the database
       */
      if (from === to) {
        this.fieldIsDragging = false;
        return false;
      }
      if (this.isFinancials && (from < 4 || to < 4)) {
        this.fieldIsDragging = false;
        return false;
      } else if (
        this.name === formTemplateNames.KEY_CONTRACT_DATES &&
        (from < 2 || to < 2)
      ) {
        this.fieldIsDragging = false;
        return false;
      }
      this.moveField({ section: this.index, from, to });
      this.fieldIsDragging = false;
      return true;
    },
    fieldOnMove({ relatedContext, draggedContext }) {
      if (this.name === formTemplateNames.PROPERTY_ADDRESS) return false;
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
  },
};
