/*
 * @Author: Ethan Dinnen
 * @Date:   2018-11-30 11:03:26
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-01-09 10:31:23
 */

export default () => ({
  stage: null,
  transaction: null,
  stages: null,
  stakeholders: null,
  campaigns: [],
  tasksLoading: false,
  kcdsIncomplete: [],
  plans: [],
});
