/**
 * @Author: Kleber Pinel <kleberdasilva>
 * @Date:   2019-03-15T15:51:22-07:00
 * @Email:  kleber@hackcapital.com
 * @Last modified by:   kleberdasilva
 * @Last modified time: 2019-03-25T15:13:50-07:00
 */

import { KeyboardShortcuts } from './keyboard-shortcuts';

export default [
  {
    name: 'Source',
    label: 'Source',
    hint: KeyboardShortcuts.Source,
    command: 'source',
    typeFor: ['email'],
    isSectionEnd: true,
    getActive: () => false,
  },
  {
    name: 'Bold',
    icon: 'format_bold',
    hint: KeyboardShortcuts.Bold,
    command: 'bold',
    typeFor: ['email'],
    getActive: style => style.fontWeight > 400 || style.fontWeight === 'bold',
  },
  {
    name: 'Italic',
    icon: 'format_italic',
    hint: KeyboardShortcuts.Italic,
    command: 'italic',
    typeFor: ['email'],
    getActive: style => style.fontStyle === 'italic',
  },
  {
    name: 'Underline',
    icon: 'format_underline',
    hint: KeyboardShortcuts.Underline,
    command: 'underline',
    isSectionEnd: true,
    typeFor: ['email'],
    getActive: style =>
      style.textDecorationLine === 'underline' ||
      style.webkitTextDecorationsInEffect === 'underline',
  },
  {
    name: 'Outdent',
    icon: 'format_indent_decrease',
    hint: KeyboardShortcuts.Outdent,
    command: 'outdent',
    typeFor: ['email'],
    getActive: () => false,
  },
  {
    name: 'Indent',
    icon: 'format_indent_increase',
    hint: KeyboardShortcuts.Indent,
    command: 'indent',
    typeFor: ['email'],
    getActive: () => false,
  },
  {
    name: 'NumberedList',
    icon: 'format_list_numbered',
    hint: KeyboardShortcuts.NumberedList,
    command: 'insertOrderedList',
    typeFor: ['email'],
    getActive: (style, parent) => {
      const allLists = document.querySelectorAll('.richtexteditor ol');
      for (let i = 0; i < allLists.length; i++) {
        if (allLists[i].contains(parent)) {
          return true;
        }
      }
    },
  },
  {
    name: 'BulletedList',
    icon: 'format_list_bulleted',
    hint: KeyboardShortcuts.BulletedList,
    command: 'insertUnorderedList',
    isSectionEnd: true,
    typeFor: ['email'],
    getActive: (style, parent) => {
      const allLists = document.querySelectorAll('.richtexteditor ul');
      for (let i = 0; i < allLists.length; i++) {
        if (allLists[i].contains(parent)) {
          return true;
        }
      }
    },
  },
  {
    name: 'ClearFormat',
    icon: 'format_clear',
    hint: KeyboardShortcuts.ClearFormat,
    command: 'removeFormat',
    typeFor: ['email'],
    getActive: () => false,
  },
  {
    name: 'Attachment',
    icon: 'attach_file',
    hint: KeyboardShortcuts.Attachment,
    command: 'attachment',
    typeFor: ['email'],
    getActive: () => false,
  },
  {
    name: 'Hyperlink',
    icon: 'insert_link',
    hint: KeyboardShortcuts.Hyperlink,
    command: 'createLink',
    typeFor: ['email'],
    commandArgument: 'selection',
    getActive: (style, parent) => {
      const allLists = document.querySelectorAll('.richtexteditor a');
      for (let i = 0; i < allLists.length; i++) {
        if (allLists[i].contains(parent)) {
          return true;
        }
      }
    },
  },
  // {
  //   name: 'LDVideo',
  //   label: 'Insert LionDesk Video',
  //   icon: 'video_library',
  //   hint: KeyboardShortcuts.LDVideo,
  //   command: 'ldVideo',
  //   getActive: style => style.fontStyle === 'italic',
  // },
  {
    name: 'Emoji',
    icon: 'tag_faces',
    hint: KeyboardShortcuts.Emoji,
    command: 'emoji',
    typeFor: ['email', 'sms'],
    getActive: () => false,
  },
  {
    name: 'ImageUrl',
    label: 'Insert Image Url',
    icon: 'add_photo_alternate',
    hint: KeyboardShortcuts.ImageUrl,
    command: 'ImageUrl',
    typeFor: ['email'],
    isSectionEnd: true,
    getActive: () => false,
  },

  {
    name: 'LDContent',
    label: 'Insert LionDesk Content',
    hint: KeyboardShortcuts.LDContent,
    command: 'ldContent',
    typeFor: ['email', 'sms'],
    getActive: () => false,
  },
];
