/*
 * @Author: Ethan Dinnen
 * @Date:   2019-01-23 14:33:43
 * @Last modified by:   hugodorea
 * @Last modified time: 2019-03-19T10:34:49-07:00
 */

import initialState from './initialState';
import stageTypes from '@/Core/constants/stageTypes';
import formTemplateNames from '@/Core/constants/formTemplateNames';
import transactionInputTypes from '@/Core/constants/transactionInputTypes';
import { genId } from '@/Core/mixins/genId';
import financials from '../fixtures/financialsTemplate';
import property from '../fixtures/propertyAddressTemplate';
import kcd from '../fixtures/kcdTemplate';
import contract from '../fixtures/contractInfoTemplate';

const reset = state => {
  Object.assign(state, initialState());
};

const resetNotName = state => {
  const name = state.typeName;
  const types = state.types;
  const market = state.market;
  const summary = state.setupSummary;
  Object.assign(state, initialState());
  state.typeName = name;
  state.market = market;
  state.types = types;
  state.setupSummary = summary;
};

const resetStageError = state => {
  state.stageError = null;
};

const resetSavedMsg = state => {
  state.typeSavedMsg = null;
};

const setStageError = (state, msg) => {
  state.stageError = msg;
};

const setSavedMsg = (state, msg) => {
  state.typeSavedMsg = msg;
};

const setType = (state, type) => {
  state.type = type;
};

const setSelectedType = (state, type) => {
  state.selectedType = type;
};

const updateName = (state, name) => {
  state.typeName = name;
};

const validateSetup = (state, valid) => {
  state.setupValid = valid;
};

const updateMarketList = (state, markets) => {
  state.markets = markets;
};

const updateMarket = (state, market) => {
  state.market = market;
};

const updateTypeList = (state, types) => {
  state.types = types.reverse();
};

const setTemplates = (state, templates) => {
  const sanitized = templates
    .map(template => {
      /* eslint-disable */
      const {
        id,
        transaction_type_id,
        created_at,
        updated_at,
        template_fields,
        ...rest
      } = template;

      const tmp_id = genId();

      const sanitizedFields = template_fields
        .map(field => {
          const tmp_field_id = genId();
          const {
            id,
            created_at,
            template_id,
            updated_at,
            ...restFields
          } = field;
          return { ...restFields, tmp_id: tmp_field_id };
        })
        .sort((a, b) => a.position - b.position);

      if (
        rest.name === formTemplateNames.FINANCIALS ||
        rest.name === formTemplateNames.PROPERTY_ADDRESS
      ) {
        return {
          ...rest,
          tmp_id,
          mandatory: true,
          template_fields: sanitizedFields,
        };
      }
      /* eslint-enable */
      return { ...rest, tmp_id, template_fields: sanitizedFields };
    })
    .sort((a, b) => a.position - b.position);
  state.templates = sanitized;
};

const clearSelection = state => {
  state.type = null;
  state.selectedType = null;
  state.stages = [];
  state.templates = [
    { ...property },
    { ...kcd },
    { ...financials },
    { ...contract },
  ];
};

const renameStage = (state, { newName, title }) => {
  const stages = state.stages;
  const i = stages.indexOf(state.stages.find(s => s.title === title));
  stages[i].title = newName;
  state.stages = stages;
};

const setStages = (state, stages) => {
  const sort = (a, b) => {
    return a.level - b.level;
  };

  const inPipeline = stages
    .filter(stage => stage.stage_type === stageTypes.IN_PROCESS)
    .sort(sort);
  const outPipeline = stages
    .filter(stage => stage.stage_type === stageTypes.CLOSED)
    .sort(sort);

  state.stages = [...inPipeline, ...outPipeline];
};

const setSanitizedStages = (state, stages) => {
  const sanitized = stages.map(stage => {
    const { stage_type, title, level } = stage;
    return {
      stage_type,
      title,
      level,
    };
  });
  setStages(state, sanitized);
  state.templateStages = sanitized;
};

const addNewStage = (state, stage) => {
  // Handle duplicate name by notifying the user
  if (state.stages.find(s => s.title === stage.title)) {
    state.stageError = 'Stages must have unique names';
    return;
  }

  // Stage is out of pipeline, so place it immediately after in pipeline stages
  if (stage.stage_type !== stageTypes.IN_PROCESS) {
    const inPipe = state.stages.filter(
      s => s.stage_type === stageTypes.IN_PROCESS
    );
    const outPipe = state.stages.filter(
      s => s.stage_type === stageTypes.CLOSED
    );
    // Assign levels accordingly based on index
    state.stages = [...inPipe, ...outPipe, stage].map((s, i) => {
      return {
        ...s,
        level: i + 1,
      };
    });
  } else {
    // Stage is in pipeline, so place it first and assign levels accordingly
    state.stages = [...state.stages, stage].map((s, i) => {
      return {
        ...s,
        level: i + 1,
      };
    });
  }
};

const updateStageType = (state, values) => {
  const { stage, isInPipeline } = values;

  // Find the stage's index for mutation
  const i = state.stages.indexOf(stage);

  if (isInPipeline) {
    state.stages[i].stage_type = stageTypes.IN_PROCESS;
    // Ensure stage appears at the end of in-process stages when sorted later
    state.stages[i].level = state.stages.length + 1;
  } else {
    // Ensure stage appears at the top of closed stages when sorted later
    state.stages[i].stage_type = stageTypes.CLOSED;
    state.stages[i].level = 0;
  }

  const sort = (a, b) => {
    return a.level > b.level;
  };

  // Retrieve in-process and closed stages seperately and sort them by level
  const inPipeline = state.stages
    .filter(s => s.stage_type === stageTypes.IN_PROCESS)
    .sort(sort);
  const outPipeline = state.stages
    .filter(s => s.stage_type === stageTypes.CLOSED)
    .sort(sort);

  /**
   * Create a new array out of the in-process and closed stages and assign
   * their levels according to their position in the array
   */
  const newStages = [...inPipeline, ...outPipeline].map((s, i) => {
    return {
      ...s,
      level: i + 1,
    };
  });
  state.stages = newStages;
};

// Immutably move an item from one index to another within the same array
const move = (list, fromIndex, targetIndex) => {
  const movingItem = list[fromIndex];
  const filteredList = [
    ...list.slice(0, fromIndex),
    ...list.slice(fromIndex + 1),
  ];
  const left = filteredList.slice(0, targetIndex);
  const right = filteredList.slice(targetIndex);

  return [...left, movingItem, ...right];
};

// Shift stage up in the list
const moveStageUp = (state, stage) => {
  const { stage_type } = stage;

  // Stage is in pipeline
  if (stage_type === stageTypes.IN_PROCESS) {
    // Find all stages in the pipeline
    let inPipeline = state.stages.filter(
      s => s.stage_type === stageTypes.IN_PROCESS
    );
    // Get index of stage to move within context of all stages in the pipeline
    const i = inPipeline.indexOf(stage);
    // Shift the stage one position left
    inPipeline = move(inPipeline, i, i - 1);
    // Find all stages out of the pipeline
    const outPipeline = state.stages.filter(
      s => s.stage_type === stageTypes.CLOSED
    );

    // Recreate the array and assign the levels accordingly
    state.stages = [...inPipeline, ...outPipeline].map((s, i) => {
      return {
        ...s,
        level: i + 1,
      };
    });
    return;
  }

  const inPipeline = state.stages.filter(
    s => s.stage_type === stageTypes.IN_PROCESS
  );
  let outPipeline = state.stages.filter(
    s => s.stage_type === stageTypes.CLOSED
  );
  const i = outPipeline.indexOf(stage);
  outPipeline = move(outPipeline, i, i - 1);

  state.stages = [...inPipeline, ...outPipeline].map((s, i) => {
    return {
      ...s,
      level: i + 1,
    };
  });
  return;
};

const moveStageDown = (state, stage) => {
  const { stage_type } = stage;

  if (stage_type === stageTypes.IN_PROCESS) {
    let inPipeline = state.stages.filter(
      s => s.stage_type === stageTypes.IN_PROCESS
    );
    const i = inPipeline.indexOf(stage);
    // Shift the stage one position right
    inPipeline = move(inPipeline, i, i + 1);
    const outPipeline = state.stages.filter(
      s => s.stage_type === stageTypes.CLOSED
    );

    state.stages = [...inPipeline, ...outPipeline].map((s, i) => {
      return {
        ...s,
        level: i + 1,
      };
    });
    return;
  }

  const inPipeline = state.stages.filter(
    s => s.stage_type === stageTypes.IN_PROCESS
  );
  let outPipeline = state.stages.filter(
    s => s.stage_type === stageTypes.CLOSED
  );
  const i = outPipeline.indexOf(stage);
  outPipeline = move(outPipeline, i, i + 1);

  state.stages = [...inPipeline, ...outPipeline].map((s, i) => {
    return {
      ...s,
      level: i + 1,
    };
  });
  return;
};

/**
 * Find the index of the stage to remove and create a new array by taking
 * slices around the stages index. Reassign levels accordingly.
 */
const removeStage = (state, stage) => {
  const i = state.stages.indexOf(stage);
  const start = state.stages.slice(0, i);
  const end = state.stages.slice(i + 1);
  state.stages = [...start, ...end].map((s, i) => {
    return {
      ...s,
      level: i + 1,
    };
  });
};

// Assign the sections which should be undeletable
const assignMandatory = (templates, mandatory) => {
  return templates
    .map(t => {
      if (mandatory.includes(t.name)) {
        return {
          ...t,
          mandatory: true,
        };
      }
      return t;
    })
    .sort((a, b) => a.position - b.position);
};

const setEdit = (state, typeObj) => {
  const { type, templates } = typeObj;
  state.type = type.id;
  state.typeName = type.name;
  // per client request, making
  // all template forms optional
  const mandatoryTemplates = [];
  const augmented = assignMandatory(templates, mandatoryTemplates);
  const sorted = augmented.map(template => {
    const sortedFields = template.template_fields.sort(
      (a, b) => a.position - b.position
    );
    return {
      ...template,
      template_fields: sortedFields,
    };
  });
  state.templates = sorted;
  state.editingName = type.name;
  state.editReady = true;
};

const deleteField = (state, { position, fieldPosition }) => {
  const fields = state.templates[position - 1].template_fields;
  const newFields = [
    ...fields.slice(0, fieldPosition - 1),
    ...fields.slice(fieldPosition),
  ];
  const isLast = fieldPosition === fields.length;

  state.templates[position - 1].template_fields = isLast
    ? newFields
    : newFields.map(f => {
        if (f.position < fieldPosition) return f;
        return {
          ...f,
          position: f.position - 1,
        };
      });
};

const deleteFields = (state, { position, fieldPositions }) => {
  const fields = state.templates[position - 1].template_fields;
  const newFields = fields.filter(
    field => !fieldPositions.includes(field.position)
  );
  state.templates[position - 1].template_fields = newFields.map((f, i) => {
    return {
      ...f,
      position: i + 1,
    };
  });
};

const deleteTemplate = (state, position) => {
  const isLast =
    position === state.templates.length || state.templates.length === 1;
  const newTemplates = [
    ...state.templates.slice(0, position - 1),
    ...state.templates.slice(position),
  ];

  state.templates = isLast
    ? newTemplates
    : newTemplates.map(t => {
        if (t.position < position) return t;
        return {
          ...t,
          position: t.position - 1,
        };
      });
};

const addField = (state, { type, position }) => {
  const toUpdate = state.templates[position - 1];
  const meta =
    type === transactionInputTypes.ABSOLUTE
      ? { meta: { referenceId: genId() } }
      : null;
  toUpdate.template_fields = [
    ...toUpdate.template_fields,
    {
      label: '',
      type,
      ...meta,
      position: toUpdate.template_fields.length + 1,
      field_source: '',
      tmp_id: genId(),
    },
  ];
  const templates = [...state.templates];
  templates[position - 1] = toUpdate;
  state.templates = templates;
};

const updateField = (state, { field, position }) => {
  const toUpdate = state.templates[position - 1];
  toUpdate.template_fields = [
    ...toUpdate.template_fields.splice(0, position - 1),
    field,
    ...toUpdate.template_fields.splice(position),
  ];
  const templates = [...state.templates];
  templates[position - 1] = toUpdate;
  state.templates = templates;
};

const addRelativeField = (state, { field, position }) => {
  const toUpdate = state.templates[position - 1];
  toUpdate.template_fields = [...toUpdate.template_fields, { ...field }];
  const templates = [...state.templates];
  templates[position - 1] = toUpdate;
  state.templates = templates;
};

const addSection = (state, position) => {
  const section = {
    position: position + 1,
    name: '',
    template_fields: [],
    tmp_id: genId(),
  };
  const templates = [...state.templates];
  const updated = templates.map(t => {
    if (t.position < position + 1) return t;
    return {
      ...t,
      position: t.position + 1,
    };
  });
  state.templates = [
    ...updated.slice(0, position),
    section,
    ...updated.slice(position),
  ];
};

const updateTemplateName = (state, { name, position }) => {
  state.templates[position - 1].name = name;
};

const updateFieldName = (state, { label, position, fieldPosition }) => {
  const toUpdate = state.templates[position - 1];
  const field = { ...toUpdate.template_fields[fieldPosition - 1] };
  field.label = label;
  toUpdate.template_fields = [
    ...toUpdate.template_fields.slice(0, fieldPosition - 1),
    field,
    ...toUpdate.template_fields.slice(fieldPosition),
  ];
  const templates = [...state.templates];
  templates[position - 1] = toUpdate;
  state.templates = templates;
};

const moveTemplate = (state, { from, to }) => {
  state.templates = move(state.templates, from, to).map((t, i) => {
    return {
      ...t,
      position: i + 1,
    };
  });
};

const setTransactionsByStage = (state, transactionsByStage) => {
  state.transactionsByStage = transactionsByStage;
};

const moveField = (state, { section, from, to }) => {
  state.templates[section].template_fields = move(
    state.templates[section].template_fields,
    from,
    to
  ).map((f, i) => {
    return {
      ...f,
      position: i + 1,
    };
  });
};

export default {
  reset,
  resetNotName,
  resetStageError,
  resetSavedMsg,
  setSavedMsg,
  setType,
  setSelectedType,
  updateName,
  validateSetup,
  updateMarketList,
  updateTypeList,
  updateMarket,
  setStages,
  setSanitizedStages,
  addNewStage,
  updateStageType,
  moveStageUp,
  moveStageDown,
  removeStage,
  setEdit,
  clearSelection,
  setTemplates,
  renameStage,
  setStageError,
  deleteField,
  deleteFields,
  deleteTemplate,
  addField,
  updateField,
  addRelativeField,
  addSection,
  updateTemplateName,
  updateFieldName,
  moveTemplate,
  setTransactionsByStage,
  moveField,
};
