import pipe from '../../utilities/pipe';

export const removeCents = value => {
  return value.replace(/\..*/g, '');
};

export const sanitize = value => {
  return value
    .replace(/^.*\$/g, '')
    .replace(/[^0-9]/g, '')
    .replace(/^0*/g, '');
};

export const formatCurrency = (value, prefix = '$ ') => {
  if (!value) return `${prefix}0`;
  return (
    prefix
    + pipe([
      removeCents,
      sanitize,
    ])(value.toString()).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  );
};

export default {
  methods: {
    formatCurrency,
  },
};
