<template>
  <div>
    <v-dialog v-model="value" persistent :width="width">
      <v-card class="ld-confirmation">
        <v-card-title>
          <slot name="header" />
        </v-card-title>
        <v-card-text>
          <slot name="body" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click="$emit('click:cancel')">{{ cancelText }}</v-btn>
          <v-btn
            :color="highRisk ? 'error' : 'primary'"
            @click="$emit('click:ok')"
            :disabled="disabled"
          >
            {{ confirmText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import riskLevels from './riskLevels';

export default {
  props: {
    value: Boolean,
    confirmText: String,
    cancelText: String,
    risk: String,
    width: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    highRisk() {
      return this.risk.toLowerCase() === riskLevels.HIGH;
    },
  },
};
</script>
