export default Object.freeze({
  Trial: 'trial',
  MLS: 'mls',
  Starter: 'starter',
  Pro: 'pro',
  ProPlus: 'pro+',
  Elite: 'elite',
  BrokerLite: 'brokerlite',
  CRM: 'crm',
  CRMPremier: 'crm-premier',
  LeadsPlus: 'leads_plus',
  PAB: 'pab',
  Free: 'free',
});

export const planIds = Object.freeze({
  LeadsPlus: 'leadsplus',
  PAB: 'promote-agent-bundle',
  LeadBoost: 'leadboost',
});

export const mlsPlans = [
  'sdmls',
  'crmls',
  'recolorado',
  'alaska',
  'bear',
  'ccar',
  'realcomp',
  'car',
];
