<template>
  <v-toolbar
    :class="['payment-cta-bar', value ? '' : 'payment-hidden']"
    fixed
    app
    dark
    flat
    :color="error ? '#F1D4D7' : '#DAE2ED'"
    prominent
  >
    <div class="info-container">
      <v-icon class="card" color="black">{{ icon }}</v-icon>
      <div class="info-container-text">
        {{ bannerText }}
      </div>
      <a :href="link">
        <v-btn flat :color="error ? '#A64951' : '#3878C0'">{{ continueBtnText }}</v-btn>
      </a>
    </div>
    <div class="dismiss-container">
      <div class="vert-divider" />
      <v-btn flat :color="error ? '#A64951' : '#3878C0'" @click="dismissBanner()">Dismiss <v-icon>clear</v-icon></v-btn>
    </div>
  </v-toolbar>
</template>

<script>
import { HC_PROPS } from 'lion-vue-src/_globals';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    dismissBanner: {
      type: Function,
      required: true,
    },
    bannerText: {
      type: String,
      required: true,
    },
    link: {
      type: String,
    },
    error: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'credit_card',
    },
    continueBtnText: {
      type: String,
      default: 'UPGRADE NOW',
    },
    upgradeLink: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      HC_PROPS,
    };
  },
};

</script>

<style lang="scss">
.payment-cta-bar {
  position: fixed !important;
  top: 64px !important;
  color: black !important;
  margin: 10px 20px 10px 95px !important;
  width: calc(100% - 110px) !important;
  padding-left: 0px !important;
  height: auto;
  .info-container {
    display: flex;
    &-text {
      max-width: 690px;
    }
  }
  &.payment-hidden {
    display: none;
  }
  .v-toolbar__content {
    justify-content: space-between;
    height: auto !important;
    padding: 10px;
    a {
      text-decoration: none;
      margin: auto 0;
    }
  }
  .dismiss-container {
    display: flex;
    div.vert-divider {
      height: 30px;
      width: 1px;
      border-left: 1px solid rgba(0,0,0,0.27);
      margin: auto 0;
    }
  }
  i.card {
    margin-right: 10px;
    padding: 0 10px 0 10px;
  }
}
</style>
