<template lang="pug">
.editor
    .canvas(@dblclick="handleCanvasDoubleClick")
        img(
            :alt='name',
            :src='url',
            @loadstart='startCropper',
            @load='startCropper',
            :style='imageStyle'
            ref='image'
        )
</template>
<script>
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';
const MINIMUM_IMAGE_SIZE_PIXEL = 1;
let URL = window.URL || window.webkitURL;

export default {
    name: 'LdImageCropper',
    props: {
        zoomValue: {
            type: Number,
            default: 0.1,
        },
        rotateValue: {
            type: Number,
            default: 90,
        },
        url: {
            type: String,
            default: '',
        },
        height: {
            type: Number,
            default: 400,
        },
        cropperData: {
            type: Object,
            default: () => ({}),
        },
        canvasData: {
            type: Object,
            default: () => ({}),
        },
        cropBoxData: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            manager: null,
        };
    },
    mounted() {
        window.addEventListener('keydown', (this.onKeydown = this.handleKeyboardKeyDown.bind(this)));
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.onKeydown);
        this.stopCropper();
    },
    computed: {
        imageStyle() {
            return {
                height: `100%`
            };
        },
    },
    methods: {
        async crop(outputType = 'image/jpeg') {
            const cropper = this.getPositionDetail();
            const { cropBox } = cropper;
            const error = this.validateCropBox(cropBox);
            if (error) {
                return this.$emit('onError', error, cropBox);
            }
            try {
                this.manager.getCroppedCanvas().toBlob((blob, type, quality) => {
                    const url = URL.createObjectURL(blob);
                    this.$emit('onCropped', {
                        blob,
                        type,
                        quality,
                        url
                    }, cropper);
                }, outputType);
            } catch (error) {
                this.$emit('onError', error, cropper);
            }
        },
        clear() {
            this.manager.clear();
        },
        reset() {
            this.manager.reset();
        },
        getPositionDetail() {
            const data = this.manager.getData();
            const canvas = this.manager.getCanvasData();
            const cropBox = this.manager.getCropBoxData();
            return {
                data,
                canvas,
                cropBox,
            };
        },
        handleCanvasDoubleClick(event) {
            if (event.target.className.indexOf('cropper-face') >= 0) {
                event.preventDefault();
                event.stopPropagation();
                this.startCropper();
            }
        },
        startCropper() {
            if (this.manager) {
                return;
            }
            this.manager = new Cropper(this.$refs.image, {
                autoCrop: true,
                dragMode: 'move',
                background: false,
                viewMode: 1,
                aspectRatio: 1,
                ready: () => {
                    this.manager
                        .setData(this.cropperData)
                        .setCanvasData(this.canvasData)
                        .setCropBoxData(this.cropBoxData);
                },
            });
        },
        stopCropper() {
            if (this.manager) {
                this.manager.destroy();
                this.manager = null;
            }
        },
        handleKeyboardKeyDown(event) {
            switch (event.key) {
                case 'z':
                    if (event.ctrlKey) {
                        event.preventDefault();
                        this.restoreFile();
                    }
                    break;
                case 'Delete':
                    this.deleteFile();
                    break;
                default:
                    if (!this.manager) {
                        return;
                    }
                    switch (event.key) {
                        case 'Enter':
                            this.cropMode();
                            break;
                        case 'Escape':
                            this.cancelChange();
                        case 'ArrowLeft':
                            this.moveImage(-1, 0);
                            break;
                        case 'ArrowUp':
                            this.moveImage(0, -1);
                            break;
                        case 'ArrowRight':
                            this.moveImage(1, 0);
                            break;
                        case 'ArrowDown':
                            this.moveImage(0, 1);
                        case 'c':
                            this.cropMode();
                            break;
                        case 'm':
                            this.moveMode();
                            break;
                        case 'i':
                            this.zoomIn();
                            break;
                        case 'o':
                            this.zoomOut();
                            break;
                        case 'l':
                            this.rotateLeft();
                            break;
                        case 'r':
                            this.rotateRight();
                            break;
                        case 'h':
                            this.swapHorizontal();
                            break;
                        case 'v':
                            this.swapVertical();
                            break;
                        default:
                            break;
                    }
            }
        },
        restoreFile() {
            if (this.file.cropped) {
                this.update({
                    cropped: false,
                    previousUrl: '',
                    url: this.file.previousUrl,
                });
            }
        },
        deleteFile() {
            this.stopCropper();
            this.update({
                cropped: false,
                cropping: false,
                loaded: false,
                name: '',
                previousUrl: '',
                type: '',
                url: '',
            });
        },
        cropMode() {
            this.setDragMode('crop');
        },
        cancelChange() {
            if (this.file.cropping) {
                this.manager.clear();
            }
        },
        moveImage(x, y) {
            e.preventDefault();
            this.manager.move(x, y);
        },
        moveMode() {
            this.setDragMode('move');
        },
        zoomIn() {
            this.manager.zoom(this.zoomValue);
        },
        zoomOut() {
            this.manager.zoom(-this.zoomValue);
        },
        rotateLeft() {
            this.manager.rotate(-this.rotateValue);
        },
        rotateRight() {
            this.manager.rotate(this.rotateValue);
        },
        swapHorizontal() {
            this.manager.scaleX(-this.manager.getData().scaleX);
        },
        swapVertical() {
            this.manager.scaleY(-this.manager.getData().scaleY);
        },
        setDragMode(action) {
            this.manager.setDragMode(action);
        },
        validateCropBox(cropBox) {
            if (cropBox.width < MINIMUM_IMAGE_SIZE_PIXEL) {
                return new Error('the image cropped must be wider than 1 pixel'), cropBox;
            }
            if (cropBox.height < MINIMUM_IMAGE_SIZE_PIXEL) {
                return new Error('the image cropped must be taller than 1 pixel'), cropBox;
            }
            if (cropBox.width < MINIMUM_IMAGE_SIZE_PIXEL && cropBox.height < MINIMUM_IMAGE_SIZE_PIXEL) {
                return new Error('the image cropped must be at least 1x1 pixel');
            }
        }
    },
};
</script>
<style lang="scss" scoped>
.editor {
    height: 100%;
}

.canvas {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    background-color: #fff;

    &>img {
        /* This rule is important to cropper.js library */
        max-width: 100%;

        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
</style>
