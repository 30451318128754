import get from 'lodash/get';
import notFound from 'lion-vue-assets/notFound.png';

const ratioAliases = {
  LARGE: "Large",
  ORIGINAL: "original",
};

export default {
  methods: {
    itemClicked() {
      this.onItemClicked(this.listing);
    },

    selectedTitle() {
      return this.mlsNumberSelected === this.listing._source.ListingId
        ? 'mls-card-title mls-item-selected'
        : 'mls-card-title';
    },

    selectedFooter() {
      return this.mlsNumberSelected === this.listing._source.ListingId
        ? 'mls-card-footer mls-item-selected'
        : 'mls-card-footer';
    },
    handleSelect(val) {
      const { listing } = this;
      this.selected = val;
      this.select({ listing, add: val });
    },
    withImage(image) {
      return `background-image: url("${image}");`;
    },
  },
  computed: {
    image() {
      const imageUrls = this.listing._source.ImageUrls
      if (!imageUrls) return notFound;

      const firstImage = imageUrls[0];
      if (!firstImage) return notFound;

      const processedURLs = firstImage.ProcessedURLs;

      if (!processedURLs) {
        return firstImage.OriginalURL
      }

      if (!processedURLs.length) {
        return firstImage.OriginalURL
      }

      if(!processedURLs[0].Ratio) return firstImage.OriginalURL;

      const img = (() => {
        const findBy = (alias) => (processedURLs.find(pi => pi.Ratio.alias === alias))
        return findBy(ratioAliases.LARGE) || findBy(ratioAliases.ORIGINAL)
      })()

      return img.URL
    },
    listingPrice() {
      return this.formatCurrency(get(this.listing._source, 'ListPrice', 0));
    },
    details() {
      const {
        BedroomsTotal, BathroomsTotal, BuildingAreaTotal, YearBuilt,
      } = this.listing._source;
      return {
        BedroomsTotal,
        BathroomsTotal,
        BuildingAreaTotal,
        YearBuilt,
      };
    },
    showHover() {
      return this.hover && !this.noHover;
    },
  },
};
