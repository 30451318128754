<!--
      @Author: Ethan Dinnen
      @Date:   2018-12-11 15:58:26
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-01-21 12:24:44
-->

<template>
  <span>{{ formatData(data) }}</span>
</template>

<script>
import formatCurrency from '@/Core/mixins/formatCurrency';
export default {
  props: {
    data: [Object, Array, String, Boolean, Number],
  },
  mixins: [formatCurrency],
  methods: {
    formatData(data) {
      if (!data) return;
      return data.text;
    },
  },
};
</script>

<style scoped>
span {
  display: inline-block;
}
span::first-letter {
  text-transform: capitalize;
}
</style>
