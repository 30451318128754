<template lang="pug">
  v-layout(wrap)
    v-chip(
      v-for='(file, index) in files'
      :key="file.name"
      @input="$emit('removeAttachment', file.name)"
      :close="file.progress === 100"
      :disabled="isDisabled"
      small
      :style="chipWidth(file.progress)"
    )
      v-icon(small) attach_file
      | {{ truncate(file.name, 12) }} ({{ file.size_bytes | fileSize }})
      div.bar-container(
        v-if="file.progress !== undefined && file.progress !== 100"
      )
        div.bar(
          :style="handleProgress(file, index)"
          :ref="`progress-${file.name}`"
        )
</template>
<script>
import fileSize from '@/Core/filters/fileSize';

export default {
  name: 'AttachmentChips',
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: true,
    },
    showProgress: {
      type: Boolean,
      default: false,
    },
  },
  filters: {
    fileSize,
  },
  methods: {
    handleProgress(file, index) {
      const { name, progress } = file;
      const item = this.$refs[`progress-${name}`];
      if (!item || !item.length) return;
      const maxWidth = 167;
      const initialWidth = 20;
      const width = maxWidth * (progress / 100) + initialWidth;
      item[0].style.width = `${width}px`;
    },
    truncate(name, len) {
      if (!name) return '';
      if (name.length > len) {
        return `${name.slice(0, len)}...`;
      }
      return name;
    },
    chipWidth(progress) {
      return progress !== 100 ? 'width: 187px;' : '';
    },
  },
};
</script>

<style scoped>
.bar-container {
  position: absolute;
  left: 0px;
}
.bar {
  height: 24px;
  width: 20px;
  border-radius: 28px;
  background-color: var(--v-primary-base);
  transition: all 0.5s linear;
  opacity: 0.3;
}
</style>
