<!--
    @Author: Ethan Dinnen
    @Date:   2018-12-05 14:40:40
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-02-25 17:52:30
-->
<template>
  <v-flex>
    <FormsPresenter
      :form="template ? template : form"
      :updateField="handleUpdateField"
      :updateSummary="updateSummary"
      :propertyId="propertyId"
    />
  </v-flex>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import FormsPresenter from './FormsPresenter';
import { formatCurrency } from '@/Core/mixins/formatCurrency';
import transactionInputTypes from '@/Core/constants/transactionInputTypes';
import formTemplateNames from '@/Core/constants/formTemplateNames';
import mandatoryKCDFields from './constants/mandatoryKCDFields';

const {
  mapState: mapSetupState,
  mapActions: mapSetupActions,
} = createNamespacedHelpers('forms');

export default {
  components: {
    FormsPresenter,
  },
  props: {
    market: Number,
    type: Number,
    property: Object,
    updateSummary: Function,
    editing: Boolean,
    template: Object,
    reAssignSummaryValues: Function,
    form: Object,
  },
  computed: {
    ...mapSetupState(['newForms', 'percentage']),
    propertyId() {
      if (!this.property) return null;
      return this.property.id;
    },
  },
  methods: {
    ...mapSetupActions([
      'reset',
      'setTemplates',
      'updateField',
      'cancel',
      'update',
      'updatePercentage',
    ]),
    calculateCommission(form, property) {
      const [
        { value: price = null }, // The 'Sale Price' value
        { value: percentage = 0 }, // The 'Commission Percentage' value
        { value: fixed = 0 }, // The 'Add To Commission' value
      ] = form[property];

      if (!(price && (percentage || fixed))) {
        // If price, or one of percentage and fixed are not set
        // Ensure commission value is set to 0
        this.updateField({
          index: form.position - 1,
          update: {
            title: 'Sale Commission Total',
            value: '0',
          },
          name: form.name,
        });
        // Reflect this change in the summary
        this.updateSummary(1, 'Commission Value - $ 0');
        // Return early as nothing else to do in this case
        return;
      }
      // Calculate the comission value, rounded to two decimal places
      const value = Math.round(price * (percentage / 100) * 100) / 100 + +fixed;
      this.updateField({
        index: form.position - 1,
        update: {
          title: 'Sale Commission Total',
          value: value.toString(),
        },
        name: form.name,
      });
      this.updateSummary(1, `Commission Value - ${formatCurrency(value)}`);
      return;
    },
    handleCalculateCommission() {
      // The user is creating a new transaction, so load from template
      if (this.template) {
        this.calculateCommission(this.template, 'template_fields');
        return;
      }
      this.calculateCommission(this.form, 'transaction_form_fields');
    },
    handleUpdateField(update) {
      // If the field to update is within the 'Financials' form
      if (
        (this.template &&
          this.template.name === formTemplateNames.FINANCIALS) ||
        (this.form && this.form.name === formTemplateNames.FINANCIALS)
      ) {
        // Update the appropriate field
        this.updateField({
          index: this.template
            ? this.template.position - 1
            : this.form.position - 1,
          update,
          name: this.template ? this.template.name : this.form.name,
        });

        // Calculate the commission percentage if necessary
        if (update.title === formTemplateNames.PURCHASE_SALES) {
          this.updateSummary(
            0,
            `Sales Value - ${update.value ? update.formatted : '$ 0.00'}`
          );
          this.handleCalculateCommission();
        } else if (
          update.title === formTemplateNames.COMMISSION_PERCENTAGE ||
          update.title === formTemplateNames.ADD_FIXED_COMMISSION
        ) {
          this.handleCalculateCommission();
        }

        // Update the summary to display % completed
        this.updateSummary(2, `${this.percentage}% of fields completed`);
        return;
      }

      // The field to update is not in the 'Financials' form, so set the value
      this.updateField({
        index: this.template
          ? this.template.position - 1
          : this.form.position - 1,
        update,
        name: this.template ? this.template.name : this.form.name,
      });

      // Reassign the summary values to ensure that updates are shown
      this.reAssignSummaryValues();
    },
    incompleteFields(fields) {
      return fields
        .filter(
          field =>
            field.type === transactionInputTypes.ABSOLUTE &&
            Object.values(mandatoryKCDFields).includes(field.meta.referenceId)
        )
        .filter(field => !field.value).length;
    },
  },
  async beforeMount() {
    this.reset();
    if (!this.editing) {
      this.updatePercentage({
        index: this.template.position - 1,
        name: this.template.name,
      });
      return;
    } else {
      const fields = this.form.transaction_form_fields;
      fields.map(item => {
        const { position, label, value, type } = item;
        const currency = type === transactionInputTypes.CURRENCY;
        this.handleUpdateField({
          value: value,
          formatted: currency ? formatCurrency(value) : value,
          position,
          title: label,
        });
      });
    }
  },
};
</script>
