<template lang="pug">
  v-container.pt-0.disabled-alternate
    //- The User Confirmation Dialog Component, when the 'Clear All' button is pressed
    v-dialog(v-model="clearConfirmationDialog" persistent max-width="480")
      v-card
        title-bar(dark) Discard unsaved changes
        v-card-text Are you sure you want to discard your unsaved changes?
        v-card-actions
          v-spacer
          v-btn(@click="clearConfirmationDialog = false" flat) No
          v-btn.primary(@click="cancel") Yes

    .drawer-title
      span {{ editorActionName }} Template
      v-btn(
        @click="isEditing = !isEditing"
        v-show="!isEditing"
        color="primary"
        flat
        icon
      )
        v-icon edit

    v-text-field(
      label="Description"
      v-model="template.description"
      @input="onInput"
      :disabled="!isEditing"
      prepend-icon="notes"
    )

    liondesk-content-editor(
      v-if="value.template_type === 'Email'"
      v-model="template.subject"
      @input="onInput"
      :disabled="!isEditing"
      label="Subject"
      field-type="input"
      ref-name="templateSubjectField"
      icon="email"
      activator-size="shrink"
      input-size="grow"
    )
      template(slot="activator-bottom")
        div.text-xs-right(v-if="isEditing")
          v-btn.title-case(flat color="primary")
            span.title-case Insert LionDesk Content

    v-select(
      :items="templateFolders"
      v-model="template.template_folder_id"
      :disabled="!isEditing"
      @input="onInput"
      label="Template Folder"
      prepend-icon="folder"
    )

    liondesk-content-editor.mt-4(
      v-if="value.template_type === 'Email'"
      v-model="template.body"
      @input="updateTemplateBody"
      ref-name="action-preview-editor"
      field-type="quill"
      :disabled="!isEditing"
    )

    rich-text-editor(
      v-if="value.template_type === 'Text'"
      toolType="sms"
      v-model="template.body"
      :disabled="!isEditing"
      @input="updateTemplateBody"
    )

    v-text-field(
      v-if="template.template_type !== 'Text' && template.template_type !== 'Email'"
      label="This template type is not supported yet"
      disabled
    )
    attachment-handler(
      :inputId="attachmentHandlerId"
      :files="files"
      @change="handleUpload"
      @error="handleFileError"
      :getAccessToken="getAccessToken"
    )

    v-layout.mt-3(justify-end)
      v-btn(color="primary" flat @click="handleCancel") Cancel
      v-btn.primary(:disabled="isDisabled" @click="save") Save Template
</template>
<script>
import fileSize from '@/Core/filters/fileSize';
import { quillEditor } from 'vue-quill-editor';
import LiondeskContentEditor from './LiondeskContentEditor';
import RichTextEditor from './richTextareaEditor/RichTextEditor';
import EmojiPicker from './richTextareaEditor/EmojiPicker';
import TitleBar from '@/Core/components/TitleBar';
import AttachmentHandler from './AttachmentHandler';

export default {
  components: {
    quillEditor,
    LiondeskContentEditor,
    RichTextEditor,
    EmojiPicker,
    TitleBar,
    AttachmentHandler,
  },
  data() {
    return {
      clearConfirmationDialog: false, // Controls whether the confirm dialog is open
      // Controls the state whether the components are writable or not
      isEditing: this.isCreatingTemplate,
      fieldsTitle: '{{fields}}',
    };
  },
  props: {
    /**
     * indicate whether this should be a new template form or a template update form
     * Should have either the value 'create' or 'update'
     */
    formAction: {
      type: String,
      required: true,
    },
    templateFolders: {
      type: Array,
    },
    /**
     * Boolean to control whether the form updates or creates a template
     */
    isCreatingTemplate: {
      type: Boolean,
      default: false,
    },
    /**
     * The template object
     */
    value: Object,
    files: Array,
    attachmentHandlerId: String,
    handleUpload: Function,
    handleFileError: Function,
    getAccessToken: Function,
  },
  computed: {
    template: {
      get() {
        return Object.assign({}, this.value);
      },
      set(newValue) {
        return newValue;
      },
    },
    editorActionName() {
      if (this.formAction === 'create') {
        return 'New';
      }
      return this.isEditing ? 'Edit' : 'Preview';
    },
    /**
     * Determine if save btn is disabled or not
     * If any of the following fields is changed, return false: 'body', 'description', 'subject'
     */
    isDisabled() {
      const { template } = this;
      return (
        !template.body ||
        (!template.subject && this.value.template_type === 'Email') ||
        !template.description ||
        !template.template_folder_id ||
        (!this.isEditing &&
          template.body === template.oldBody &&
          template.description === template.oldDescription &&
          template.subject === template.oldSubject)
      );
    },
  },
  filters: {
    fileSize,
  },
  watch: {
    isCreatingTemplate(value) {
      this.isEditing = value;
    },
  },
  methods: {
    updateTemplateBody(newValue) {
      const templateCopy = Object.assign({ body: newValue }, this.template);
      this.template = templateCopy;
      this.onInput();
    },
    onInput() {
      this.$emit('input', this.template);
    },
    save() {
      this.isEditing = false;
      this.clearConfirmationDialog = false;
      this.$emit(`${this.formAction}-template`);
    },
    handleCancel() {
      const { template, value } = this;
      if (
        template.body === value.oldBody &&
        template.description === value.oldDescription &&
        template.subject === value.oldSubject
      ) {
        this.cancel();
        return;
      }

      this.clearConfirmationDialog = true;
    },
    cancel() {
      this.isEditing = false;
      this.clearConfirmationDialog = false;
      this.$emit('cancel');
    },
  },
};
</script>
