import LdPlans from 'lion-vue-src/config/plans';
import LdFeatures from 'lion-vue-src/_globals/lib/LdFeatures';

const content = Object.freeze({
  [LdFeatures.Emails]: {
    [LdPlans.Starter]: `$2 for an additional 1000 credits`,
    [LdPlans.Pro]: `$2 for an additional 1000 credits`,
    [LdPlans.ProPlus]: `$1.50 for an additional 1000 credits`,
    [LdPlans.CRM]: `$1.50 for an additional 1000 credits`,
    [LdPlans.PAB]: `$1.50 for an additional 1000 credits`,
    [LdPlans.CRMPremier]: `$1.50 for an additional 1000 credits`,
    [LdPlans.Elite]: `$1 for an additional 1000 credits`,
    [LdPlans.LeadsPlus]: `$1.50 for an additional 1000 credits`,
  },
  [LdFeatures.TextMessaging]: {
    [LdPlans.Starter]: `$5 for an additional 100 credits`,
    [LdPlans.Pro]: `$5 for an additional 100 credits`,
    [LdPlans.ProPlus]: `$3 for an additional 100 credits`,
    [LdPlans.CRM]: `$3 for an additional 100 credits`,
    [LdPlans.PAB]: `$3 for an additional 100 credits`,
    [LdPlans.CRMPremier]: `$3 for an additional 100 credits`,
    [LdPlans.Elite]: `$2 for an additional 100 credits`,
    [LdPlans.LeadsPlus]: `$3 for an additional 100 credits`,
  },
  [LdFeatures.BulkTexts]: {
    [LdPlans.Starter]: `$5 for an additional 100 credits`,
    [LdPlans.Pro]: `$5 for an additional 100 credits`,
    [LdPlans.ProPlus]: `$3 for an additional 100 credits`,
    [LdPlans.CRM]: `$3 for an additional 100 credits`,
    [LdPlans.PAB]: `$3 for an additional 100 credits`,
    [LdPlans.CRMPremier]: `$3 for an additional 100 credits`,
    [LdPlans.Elite]: `$2 for an additional 100 credits`,
    [LdPlans.LeadsPlus]: `$3 for an additional 100 credits`,
  },
  [LdFeatures.VideoTexts]: {
    [LdPlans.Starter]: `$7 for an additional 100 credits`,
    [LdPlans.Pro]: `$7 for an additional 100 credits`,
    [LdPlans.ProPlus]: `$5 for an additional 100 credits`,
    [LdPlans.CRM]: `$5 for an additional 100 credits`,
    [LdPlans.PAB]: `$5 for an additional 100 credits`,
    [LdPlans.CRMPremier]: `$5 for an additional 100 credits`,
    [LdPlans.Elite]: `$2 for an additional 100 credits`,
    [LdPlans.LeadsPlus]: `$5 for an additional 100 credits`,
  },
  [LdFeatures.LeadAssist]: {
    [LdPlans.Starter]: `$15 for an additional 5 credits`,
    [LdPlans.Pro]: `$15 for an additional 5 credits`,
    [LdPlans.ProPlus]: `$12.50 for an additional 5 credits`,
    [LdPlans.Elite]: `$11.50 for an additional 5 credits`,
    [LdPlans.LeadsPlus]: `$12.50 for an additional 5 credits`,
  },
});

export default content;
