/*
 * @Author: Ethan Dinnen
 * @Date:   2019-01-23 14:34:05
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-02-20 13:08:24
 */

import property from '../fixtures/propertyAddressTemplate';
import keyContractDates from '../fixtures/kcdTemplate';
import financials from '../fixtures/financialsTemplate';
import contract from '../fixtures/contractInfoTemplate';

export default () => ({
  types: [],
  type: null,
  typeName: '',
  setupValid: false,
  market: null,
  markets: [],
  selectedType: {},
  stages: [],
  stageError: null,
  templates: [
    { ...property },
    { ...keyContractDates },
    { ...financials },
    { ...contract },
  ],
  editReady: false,
  editingName: '',
  typeSavedMsg: null,
  templateStages: [],
  transactionsByStage: [],
});
