<template>
  <UtilityPresenter
    :items="items"
    :username="username"
    :userInitials="userInitials"
    :profileUrl="profileUrl"
    :settingsUrl="settingsUrl"
    :paymentsUrl="paymentsUrl"
    :showPaymentsSettings="showPaymentsSettings"
    @logout="$emit('logout')"
  >
  </UtilityPresenter>
</template>

<script>
import UtilityPresenter from './LdUtilityPresenter';
import { HC_PROPS } from 'lion-vue-src/_globals/routes';

export default {
  components: {
    UtilityPresenter,
  },
  props: {
    items: Array,
    user: {
      type: Object,
      default: null,
    },
    showPaymentsSettings: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    username() {
      if (this.user) {
        return this.user.first_name || this.user.username;
      }
      return null;
    },
    userInitials() {
      if (this.user) {
        const firstLetter = this.user.first_name ? this.user.first_name.slice(0, 1) : '';
        const lastLetter = this.user.last_name ? this.user.last_name.slice(0, 1) : '';
        return firstLetter || lastLetter ? `${firstLetter}${lastLetter}` : 'LD';
      }
      return '';
    },
    profileUrl() {
      return `${HC_PROPS.PLATFORM_APP_URL}/profile`;
    },
    settingsUrl() {
      return `${HC_PROPS.PLATFORM_APP_URL}/settings`;
    },
    paymentsUrl() {
      return `${HC_PROPS.ACCOUNTS_APP_URL}/settings`;
    },
  },
};
</script>
