<!--
  @Author: Ethan Dinnen
  @Date:   2018-12-03 13:40:08
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-01-15 12:19:55
-->

<template>
  <TransactionsLayout>
    <ViewTransactionPresenter
      :transaction="transaction"
      :stages="stageOptions"
      :moveStage="moveStage"
      :stakeholders="stakeholders"
      :campaigns="campaigns"
      :getCampaigns="getCampaigns"
      :toggleComplete="toggleComplete"
      :deleteTask="deleteTask"
      :deleteCommunication="deleteCommunication"
      :tasksLoading="tasksLoading"
      :kcdsIncomplete="kcdsIncomplete"
      :plans="plans"
      :startPlan="startPlan"
      :hasPlan="hasPlan"
    />
  </TransactionsLayout>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import TransactionsLayout from '@/Core/layouts/TransactionsLayout';
import ViewTransactionPresenter from './ViewTransactionPresenter';

const {
  mapState: mapViewState,
  mapActions: mapViewActions,
} = createNamespacedHelpers('viewTransaction');

export default {
  components: {
    TransactionsLayout,
    ViewTransactionPresenter,
  },
  data() {
    return {
      stageOptions: null,
    };
  },
  methods: {
    ...mapViewActions([
      'moveStage',
      'getTransaction',
      'getCampaigns',
      'toggleComplete',
      'deleteTask',
      'deleteCommunication',
      'getPlans',
      'startPlan',
    ]),
  },
  computed: {
    ...mapViewState([
      'transaction',
      'stages',
      'stakeholders',
      'campaigns',
      'tasksLoading',
      'kcdsIncomplete',
      'plans',
    ]),
    hasPlan() {
      if (!this.transaction) return false;
      if (!this.transaction.tasks) return false;
      return true;
    },
  },
  async beforeMount() {
    await this.getTransaction(this.$route.params.id);
    await this.getPlans(this.transaction.transaction_type_id);
    // Format the stage options for the select menu
    // reverse it so it is in the right order
    this.stageOptions = this.stages
      .map(stage => {
        return { text: stage.title, value: stage.id };
      })
      .reverse();
  },
};
</script>
