import axios from 'axios';

export default {
  namespaced: true,
  state: {
    smsStatusOk: true,
    smsStatusError: null,
    twilioSubaccountIsActive: true,
    twilioSubaccountError: null,
  },
  mutations: {
    setSMSStatusOk(state, status) {
      state.smsStatusOk = status;
    },
    setSMSStatusError(state, error) {
      state.smsStatusError = error;
    },
    setTwilioSubaccountIsActive(state, status) {
      state.twilioSubaccountIsActive = status;
    },
    setTwilioSubaccountError(state, error) {
      state.twilioSubaccountError = error;
    },
  },
  actions: {
    async checkSmsStatus({ commit }) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_COMMUNICATIONS_API_BASE_URL}/phishing/sms/status`,
        );

        commit('setSMSStatusOk', response.data.smsStatusOk);
      } catch (error) {
        commit('setSMSStatusError', error.data);
      }
    },

    async checkTwilioSubaccountStatus({ commit }) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_ACCOUNTS_API}/users/twilio-subaccount/status?includeInactive=true`,
        );

        commit('setTwilioSubaccountIsActive', response.data.is_active);
      } catch (error) {
        commit('setTwilioSubaccountError', error.data);
      }
    },
  },
};
