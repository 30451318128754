/*
 * @Author: Ethan Dinnen
 * @Date:   2018-11-30 11:03:26
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-01-04 16:54:45
 */

export default () => ({
  transaction: null,
  templates: [],
  newForms: null,
  percentage: 0,
});
