<template>
  <ld-persistent-banner
    v-if="visible"
    :error="true"
    :text="text"
    :buttonText="buttonText"
    :loading="isSending"
    :complete="isComplete"
    @input="onResend">
  </ld-persistent-banner>
</template>

<script>
import LdPersistentBanner from './LdPersistentBanner';
export default {
  components: { LdPersistentBanner },
  props: {
    user: {
      type: Object,
      default: null,
    },
    resendVerifyText: {
      type: String,
      default: 'Resend Verification Email',
    },
  },
  computed: {
    text() {
      return 'Please check your inbox to verify your email address.';
    },
    isVerified() {
      return this.user && this.user.email_is_verified;
    },
    buttonText() {
      return this.isComplete ? '' : this.resendVerifyText;
    },
  },
  data() {
    return {
      visible: false,
      isSending: false,
      isComplete: false,
    };
  },
  watch: {
    user: {
      handler(value) {
        // notify parent whether or not this banner is visible
        this.visible = this.isVerified != null ? !this.isVerified : false;
        this.$emit('show', this.visible);
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    onResend() {
      this.isSending = true;
      this.$emit('click:resend');
      setTimeout(() => {
        this.isSending = false;
        this.isComplete = true;
      }, 1500);
    },
  },
};
</script>