<!--
  @Author: Ethan Dinnen
  @Date:   2019-01-08 15:59:29
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-01-18 11:50:42
-->
<template>
  <v-layout class="contacts-campaigns" column>
    <TitleBar style="padding-left: 28px;">Associated Contacts</TitleBar>
    <TxDataTable
      :headers="headers"
      :items="items"
      :isLoading="isLoading"
      :pagination="pagination"
      :handlePagination="handlePagination"
      :rowsPerPageItems="[5, 10, 15, 25, 50, 100]"
    />
  </v-layout>
</template>

<script>
import findDeep from '@/Core/mixins/findDeep';
import TitleBar from '@/Core/components/TitleBar';
import compact from 'lodash/compact';
import TxDataTable from './TxDataTable';

export default {
  components: {
    TitleBar,
    TxDataTable,
  },
  props: {
    stakeholders: {
      type: Array,
      required: true,
    },
    campaigns: {
      type: Array,
    },
    getCampaigns: {
      type: Function,
    },
  },
  mixins: [findDeep],
  data() {
    return {
      sortedStakeholders: null,
      roles: null,
      headers: [
        {
          text: 'Contact',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Owner',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Roles',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Campaigns',
          align: 'left',
          sortable: false,
        },
      ],
      pagination: {
        page: 1,
        rowsPerPage: Math.max(10, this.stakeholders.length),
        totalItems: this.stakeholders.length,
        sortBy: 'transactions.created_at',
        descending: true,
      },
      isLoading: true,
      items: this.stakeholders,
    };
  },
  methods: {
    async handlePagination(data) {
      this.isLoading = true;
      this.isLoading = false;
      this.pagination = data;
      this.pagination.totalItems = this.stakeholders.length;
      this.items = this.stakeholders;
    },
    getName(contact) {
      return `${contact.first_name} ${contact.last_name}`;
    },
    getOwner(contact) {
      if (contact.userTxStakeholder.meta.owner_name) {
        return contact.userTxStakeholder.meta.owner_name;
      }
      return 'Me';
    },
    async setCampaigns() {
      /**
       * Retrieve data about the campaign ids in the list.
       * We pass a new set to ensure that no duplicate calls
       * are made to the api by filtering out duplicate ids
       */
      const campaigns = this.findDeep(this.stakeholders, 'campaigns').flat();
      await this.getCampaigns(new Set(campaigns));
    },
    getCampaignNames(stakeholder) {
      // Make a list of campaign names for this stakeholder
      const userCampaigns = compact(
        this.findDeep(stakeholder, 'campaigns').flat()
      );
      // Loop over the campaigns
      return (
        this.campaigns
          .map(c => {
            // This user has the campaign assigned
            if (userCampaigns.includes(c.id)) {
              return c.name;
            }
            return null;
          })
          // Remove falsey values
          .filter(Boolean)
      );
    },
    async initialize() {
      await this.setCampaigns();

      this.items = this.stakeholders.map(s => {
        // Recursively find all role titles in stakeholder object
        const roles = this.findDeep(s, 'title');
        const campaignNames = this.getCampaignNames(s);

        // Return a row to render in the table
        return {
          id: s.id,
          contact: {
            key: 'Contact',
            text: this.getName(s),
          },
          owner: {
            key: 'Owner',
            text: this.getOwner(s),
          },
          roles: {
            key: 'Roles',
            text: roles.join(', '),
          },
          campaigns: {
            key: 'Campaigns',
            text: campaignNames.length > 0 ? campaignNames.join(', ') : 'None',
          },
        };
      });
      return;
    },
  },
  async beforeMount() {
    await this.initialize();
  },
};
</script>

<style lang="scss" scoped>
.contacts-campaigns {
  margin: 30px 0;
}
.contact-container {
  // display: flex;
  // flex-direction: column;
  // flex-wrap: wrap;
  min-height: 300px;
  .contact {
    margin: 10px 0;
  }
}
</style>
