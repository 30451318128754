<template>
  <svg 
    aria-hidden="true"
    focusable="false"
    data-icon="verifying-info"
    role="img"
    viewBox="0 0 118 117"
  >
    <g id="Landing-Pages" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Checking-Information" transform="translate(-715.000000, -273.000000)" fill="#235EA1" fill-rule="nonzero">
            <g id="Group-5" transform="translate(715.121951, 273.500000)">
                <path d="M109.756098,99.4268293 L7.31707317,99.4268293 C3.27596525,99.4268293 -9.09494702e-13,96.150864 -9.09494702e-13,92.1097561 L-9.09494702e-13,23.8170732 C-9.09494702e-13,19.7759652 3.27596525,16.5 7.31707317,16.5 L19.5121951,16.5 L19.5121951,21.3780488 L7.31707317,21.3780488 C5.9700372,21.3780488 4.87804878,22.4700372 4.87804878,23.8170732 L4.87804878,92.1097561 C4.87804878,93.4567921 5.9700372,94.5487805 7.31707317,94.5487805 L109.756098,94.5487805 C111.103134,94.5487805 112.195122,93.4567921 112.195122,92.1097561 L112.195122,23.8170732 C112.195122,22.4700372 111.103134,21.3780488 109.756098,21.3780488 L97.5609756,21.3780488 L97.5609756,16.5 L109.756098,16.5 C113.797205,16.5 117.073171,19.7759652 117.073171,23.8170732 L117.073171,92.1097561 C117.073171,96.150864 113.797205,99.4268293 109.756098,99.4268293 Z" id="Path"></path>
                <rect id="Rectangle" x="26.8292683" y="111.621951" width="63.4146341" height="4.87804878"></rect>
                <polygon id="Rectangle" points="34.2421054 113.391021 39.1154927 96.3167983 43.8062134 97.655641 38.932826 114.729864"></polygon>
                <polygon id="Rectangle" points="73.2644404 97.6591981 77.9548104 96.3191274 82.8326675 113.392074 78.1422976 114.732145"></polygon>
                <path d="M104.878049,84.7926829 L12.195122,84.7926829 C10.848086,84.7926829 9.75609756,83.7006945 9.75609756,82.3536585 L9.75609756,28.695122 C9.75609756,27.348086 10.848086,26.2560976 12.195122,26.2560976 L19.5121951,26.2560976 L19.5121951,31.1341463 L14.6341463,31.1341463 L14.6341463,79.9146341 L102.439024,79.9146341 L102.439024,31.1341463 L97.5609756,31.1341463 L97.5609756,26.2560976 L104.878049,26.2560976 C106.225085,26.2560976 107.317073,27.348086 107.317073,28.695122 L107.317073,82.3536585 C107.317073,83.7006945 106.225085,84.7926829 104.878049,84.7926829 Z" id="Path"></path>
                <g id="28-Information" transform="translate(29.377995, 0.000000)">
                    <path d="M36.8982441,7.07611604 C48.7609588,11.0064336 56.5492189,22.2413516 55.969799,34.6009117 C55.3904275,46.9594409 46.5858469,57.4333994 34.4078296,60.2633859 C22.234579,63.0922647 9.6367851,57.5986407 3.54511942,46.7984761 C-2.55119133,35.990076 -0.648634232,22.4956781 8.20268925,13.7469679 C9.21442749,12.746831 10.3015479,11.8241497 11.4546551,10.9868774 L13.2173093,13.414438 C12.1868793,14.1626342 11.2155639,14.9870274 10.3116739,15.8805526 C2.42157215,23.6791845 0.727318218,35.6961362 6.15813091,45.3246474 C11.5935887,54.961394 22.8488645,59.869575 33.7287696,57.3412501 C44.6039081,54.8140329 52.4568579,45.4721376 52.9730902,34.4604251 C53.4892742,23.4497436 46.545275,13.4327101 35.9547305,9.92388396 L36.8982441,7.07611604 Z" id="Path"></path>
                    <polygon id="Path" points="47.5607142 50.4393398 51.5607142 54.4393398 49.4393939 56.5606602 45.4393939 52.5606602"></polygon>
                    <path d="M60.5909773,51.594207 L55.4259389,51.594207 C54.045227,51.594207 52.9259389,52.7134952 52.9259389,54.094207 L52.9259389,72.3951492 C52.9259389,73.7758611 54.045227,74.8951492 55.4259389,74.8951492 L60.5909773,74.8951492 C61.9716892,74.8951492 63.0909773,73.7758611 63.0909773,72.3951492 L63.0909773,54.094207 C63.0909773,52.7134952 61.9716892,51.594207 60.5909773,51.594207 Z M60.090054,54.594 L60.090054,71.895 L55.925054,71.895 L55.925054,54.594 L60.090054,54.594 Z" id="Rectangle" transform="translate(58.008458, 63.244678) rotate(-45.000000) translate(-58.008458, -63.244678) "></path>
                    <polygon id="Path" points="55.4393939 55.4393398 57.5607142 57.5606602 52.5607142 62.5606602 50.4393939 60.4393398"></polygon>
                    <path d="M36.9363263,12.1163961 C46.2158569,16.0019351 51.8720162,25.4315144 50.8898692,35.3869272 C49.9078495,45.3410499 42.5181838,53.5004918 32.6576745,55.5253354 C22.7997353,57.5496512 12.7598863,52.9728792 7.88093019,44.2267082 C2.99922174,35.4756034 4.42145564,24.5887756 11.3889738,17.3641606 L13.5483685,19.4467105 C7.49747179,25.7208814 6.26322737,35.168699 10.5008582,42.76521 C14.7412414,50.3666548 23.475538,54.3482769 32.05422,52.5866549 C40.6303319,50.8255608 47.0517022,43.735281 47.9043626,35.0923933 C48.7568957,26.4507957 43.8446621,18.2614408 35.7776369,14.8836039 L36.9363263,12.1163961 Z" id="Path"></path>
                    <path d="M36.500054,21 L27.750054,21 C26.231271,21 25.000054,22.2312169 25.000054,23.75 L25.000054,32.5 C25.000054,33.8363573 26.6157669,34.5056075 27.5607142,33.5606602 L37.5607142,23.5606602 C38.5056615,22.6157129 37.8364113,21 36.500054,21 Z M32.878,24 L28,28.878 L28,24 L32.878,24 Z" id="Path"></path>
                    <path d="M35.300054,0 L13.700054,0 C12.2110369,0 11.000054,1.20322016 11.000054,2.69230769 L11.000054,31.3076923 C11.000054,32.7967798 12.2110369,34 13.700054,34 L26.900054,34 C27.2962077,34 27.6762761,33.8432859 27.9572983,33.5640652 L37.5572983,24.0256036 C37.8406917,23.7440268 38.000054,23.3610348 38.000054,22.9615385 L38.000054,2.69230769 C38.000054,1.20322016 36.7890712,0 35.300054,0 Z M35,3 L35,22.336 L26.28,31 L14,31 L14,3 L35,3 Z" id="Path"></path>
                    <polygon id="Path" points="31.500054 6.5 31.500054 9.5 17.500054 9.5 17.500054 6.5"></polygon>
                    <polygon id="Path" points="31.500054 12.5 31.500054 15.5 17.500054 15.5 17.500054 12.5"></polygon>
                    <polygon id="Path" points="23.500054 18.5 23.500054 21.5 17.500054 21.5 17.500054 18.5"></polygon>
                    <path d="M43.000054,33.5 C43.000054,42.0604136 36.0604677,49 27.500054,49 L27.500054,46 C34.4036134,46 40.000054,40.4035594 40.000054,33.5 L43.000054,33.5 Z" id="Path"></path>
                </g>
            </g>
        </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '$vuetify.icons.verifying-info',
    },
  },
}
</script>
