import {
  FEATURE_FLAG_CONTACTS_MS,
  FEATURE_FLAG_EVENTS_MS,
  FEATURE_FLAG_EVENTS_MS_TOGGLE,
  FEATURE_FLAG_CONTACTS_MS_TOGGLE,
  FEATURE_FLAG_QUALIFY,
} from './constants';

const userInfo = (state, info) => {
  state.userInfo = info;
};

const setError = (state, payload) => {
  state.hasError = payload.hasError;
  state.errorMessage = payload.errorMessage;
};

const navBarExpanded = (state, value) => {
  state.navBarExpanded = value;
};

const showChangeProfilePictureModal = (state, value) => {
  state.showChangeProfilePictureModal = value;
}

const togglePaymentCta = (state, value) => {
  state.paymentCta = value;
};

const toggleVerifyEmailCta = (state, value) => {
  state.verifyEmailCta = value;
};

const setHasAtLeastOneEmailAccount = (state, boolean) => {
  state.hasAtLeastOneEmailAccount = boolean;
};

const setShouldLoadFF = (state, { shouldLoadFF, flag }) => {
  switch (flag) {
    case FEATURE_FLAG_CONTACTS_MS:
      state.shouldLoadV2Contacts = shouldLoadFF;
      break;
    case FEATURE_FLAG_EVENTS_MS:
      state.shouldLoadV2Events = shouldLoadFF;
      break;
    case FEATURE_FLAG_QUALIFY:
      state.shouldLoadQualify = shouldLoadFF;
      break;
    default:
      return;
  }
};

const setShouldSeeFFToggle = (state, { includesFFToggle, featureFlagToggle }) => {
  switch (featureFlagToggle) {
    case FEATURE_FLAG_CONTACTS_MS_TOGGLE:
      state.shouldSeeV2ContactsToggle = includesFFToggle;
      break;
    case FEATURE_FLAG_EVENTS_MS_TOGGLE:
      state.shouldSeeV2EventsToggle = includesFFToggle;
      break;
    default:
      return;
  }
};

const setIsUpdatingFeatureFlag = (state, { isUpdatingFF, flag }) => {
  switch (flag) {
    case FEATURE_FLAG_CONTACTS_MS:
      state.isUpdatingV2ContactsFlag = isUpdatingFF;
      break;
    case FEATURE_FLAG_EVENTS_MS:
      state.isUpdatingV2EventsFlag = isUpdatingFF;
      break;
    case FEATURE_FLAG_QUALIFY:
      state.isUpdatingQualifyFlag = isUpdatingFF;
      break;
    default:
      return;
  }
};

const setOnboardingFinished = (state, isOnboardingFinished) => {
  state.onboardingFinished = isOnboardingFinished;
};

const setSmsUsageInfo = (state, data) => {
  state.smsUsageInfo = data;
};

const setRegistrationInfo = (state, data) => {
  state.registrationInfo = data;
};

const setRegistrationStarterInfo = (state, data) => {
  state.registrationStarterInfo = data;
};

const setRegistrationLowStandardInfo = (state, data) => {
  state.registrationLowStandardInfo = data;
};

const setHasErrorGetRegistrationInfo = (state, hasError) => {
  state.hasErrorGetRegistrationInfo = hasError;
};

const setULToken = (state, token) => {
  state.ulToken = token;
};

export default {
  userInfo,
  navBarExpanded,
  showChangeProfilePictureModal,
  togglePaymentCta,
  toggleVerifyEmailCta,
  setHasAtLeastOneEmailAccount,
  setOnboardingFinished,
  setSmsUsageInfo,
  setRegistrationInfo,
  setRegistrationStarterInfo,
  setRegistrationLowStandardInfo,
  setHasErrorGetRegistrationInfo,
  setShouldLoadFF,
  setIsUpdatingFeatureFlag,
  setShouldSeeFFToggle,
  setError,
  setULToken,
};
