import { genId } from '@/Core/mixins/genId';

export default {
  name: 'Contract Info',
  position: 4,
  tmp_id: genId(),
  template_fields: [
    {
      label: 'Transaction Number',
      position: 1,
      type: 'text',
      tmp_id: genId(),
      field_source: '',
    },
    {
      label: 'Class',
      position: 2,
      type: 'text',
      tmp_id: genId(),
      field_source: '',
    },
    {
      label: 'Type',
      position: 3,
      type: 'text',
      tmp_id: genId(),
      field_source: '',
    },
    {
      label: 'Referral %',
      position: 4,
      type: 'text',
      tmp_id: genId(),
      field_source: '',
    },
    {
      label: 'Referral Source',
      position: 5,
      type: 'text',
      tmp_id: genId(),
      field_source: '',
    },
  ],
};
