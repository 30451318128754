export const LOW_BRAND_TEXT_LIMIT = 6000;

export const STANDARD_BRAND = 'STANDARD';
export const LOW_STANDARD_BRAND = 'LOW_STANDARD';
export const STARTER_BRAND = 'STARTER';

export const BRAND_TEXT_VOLUME_OPTIONS = [
  { value: LOW_STANDARD_BRAND, text: `I\`ll be sending less than ${LOW_BRAND_TEXT_LIMIT} texts a day` },
  { value: STANDARD_BRAND, text: `I\`ll be sending more than ${LOW_BRAND_TEXT_LIMIT} texts a day` },
];
