import axios from 'axios';
import { HC_PROPS } from 'lion-vue-src/_globals/routes';

export const createSubscriptionAddon = async (store, data) => {
  const addOn = data.subType ? `${data.feature}_${data.subType}` : data.feature;
  const ret = await axios({
    method: 'PUT',
    url: `${HC_PROPS.ACCOUNTS_API}/subscriptions/addons`,
    data: { addon_type: addOn },
  });
  return ret.data;
};
