import axios from 'axios';
import Feathers from 'feathers/client';
import rest from 'feathers-rest/client';
import { CookieStorage } from 'cookie-storage';

const feathers = Feathers()
  .configure(rest(process.env.MLS_API).axios(axios));

export default () => {
  return feathers;
};
