/*
 * @Author: Kleber Pinel
 * @Date:   2018-11-30 11:03:26
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-01-18 13:50:55
 */

const updateTransactionTypesWidget = (state, value) => {
  // Sort by level
  const sorted = value.sort(
    (a, b) => a.transactionType.level - b.transactionType.level
  );

  state.transactionTypesWidget = sorted;
};

const setSelectedTransactionTypeId = (state, value) => {
  state.selectedTransactionTypeId = value;
};

export default {
  setSelectedTransactionTypeId,
  updateTransactionTypesWidget,
};
