/*
 * @Author: Ethan Dinnen
 * @Date:   2018-11-30 11:03:26
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-02-13 15:57:57
 */

export default () => ({
  contacts: [],
  formSummaries: [],
  market: 1,
  markets: null,
  newContacts: [],
  pipeline: {},
  property: null,
  roles: [],
  setupValid: false,
  selectedContacts: [],
  stage: null,
  stages: [],
  templates: [],
  title: null,
  transaction: null,
  transactions: {},
  transactionTypes: null,
  type: null,
  campaigns: [],
  transactionsToCreate: [],
  pipelineError: '',
  renderReady: false,
  plans: [],
  plansSearchResults: [],
  snacks: [],
  showErrorMessage: false,
  errorMessage: '',
  transactionsCreated: [],
});
