import shared from '../Shared/mixins';
import mls from '../Mls/mixins';

export const toggleSlideOut = {
  ...shared.LdSlideOutMixins,
};
export const staticSearchFilter = {
  ...mls.MlsStaticSearchFilterMixins,
};
export const fetchSavedSearches = {
  ...mls.MlsFetchSavedSearchesMixins,
};
