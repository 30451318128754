<template>
  <v-btn v-bind="$props" @click="onAction($event, 'click')" :class="gatedClass">
    <slot></slot>
  </v-btn>
</template>

<script>
import featureGate from 'lion-vue-src/Shared/mixins/featureGate';

export default {
  props: [ 'color', 'outline', 'disabled', 'type', 'icon', 'href', 'flat', 'tabindex', 'loading' ],
  mixins: [featureGate],
};
</script>
