<!--
@Author: Kleber Pinel
@Date:   2018-12-14 15:58:26
@Last Modified by:   Ethan Dinnen
@Last Modified time: 2019-02-19 15:18:54
-->

<template>
  <div>
    <v-card class="transaction-type-card-wrapper">
      <div :class="`transaction-type-card-wrapper__header ${selectedClass}`">
        <h2 :class="selectedClass">{{ item.transactionType.name }}</h2>
        <router-link
          :to="`/create/type/${item.transactionType.id}`"
          title="Edit Transaction Type"
        >
          <v-btn icon flat><v-icon small>edit</v-icon></v-btn>
        </router-link>
      </div>
      <div class="transaction-type-card" @click="selectTransactionType(item.transactionType.id);">
        <div class="value" :class="selectedClass">
          {{ item.transactionCount }}
        </div>
        <div class="summation" :class="directionClass">
          <div>S: {{ formatCurrency(item.summation.total_sales) }}</div>
          <div>
            C: {{ formatCurrency(item.summation.total_commission) }}
          </div>
        </div>
        <div class="centered">
          <v-icon color="text">{{
            this.toggleMenu ? 'expand_less' : 'expand_more'
          }}</v-icon>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import formatCurrency from '@/Core/mixins/formatCurrency';
export default {
  components: {},
  props: {
    item: Object,
    onClick: Function,
    selected: Number,
  },
  mixins: [formatCurrency],
  data() {
    return {
      toggleMenu: false,
    };
  },
  computed: {
    completed() {
      return this.item.transactionType.completed;
    },
    directionClass() {
      return this.toggleMenu ? 'up' : 'down';
    },
    selectedClass() {
      if (this.completed) {
        return this.toggleMenu ? 'selected' : '';
      }
      return this.toggleMenu ? 'selected incomplete' : 'incomplete';
    },
  },
  methods: {
    selectTransactionType(transactionTypeId) {
      if (this.toggleMenu) {
        transactionTypeId = null;
      }
      this.toggleMenu = !this.toggleMenu;
      this.onClick(transactionTypeId);
    },
  },
  watch: {
    selected: {
      handler(value) {
        if (value !== this.item.transactionType.id) {
          this.toggleMenu = false;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-menu__content {
  box-shadow: none;
}
button {
  margin: 0 0 12px 0;
  transition: 0.25s;
  border-color: transparent;
  &:active {
    outline: 0;
    color: black;
  }
  &:focus {
    outline: 0;
  }
}
h2 {
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  padding: 10px;
  background-color: var(--v-primary-base);
  color: white;
  cursor: move;
  &.selected {
    background-color: #275b96;
  }
  &.incomplete {
    background-color: #de7e07;
  }
}
.transaction-type-card {
  min-width: 199px;
  padding: 6px 16px;
  font-size: 12px;
  text-align: left;
  .value {
    font-size: 48px;
    display: inline-block;
    padding-right: 6px;
    color: var(--v-primary-base);

    &.selected {
      color: var(--v-primary-dark-base);
    }
  }
  .summation {
    font-size: 12px;
    font-weight: 500;
    display: inline-block;
    color: #a29696;
  }
  .flat {
    &:before {
      border: none;
    }
  }
}
.transaction-type-card-wrapper {
  border-radius: 5px 5px 5px 5px;
  background-color: white;
  min-height: 166px;
  cursor: move;
  &__header {
    display: flex;
    justify-content: space-between;
    background-color: var(--v-primary-base);
    h2 {
      border-top-left-radius: inherit;
    }
    &.selected {
      background-color: #275b96;
    }
    &.incomplete {
      background-color: #1976d2;
    }
    a {
      button {
        color: white;
        margin: auto 0;
      }
      margin: auto 0;
    }
  }
}
</style>
