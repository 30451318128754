/**
 * @Author: Kleber Pinel <kleberdasilva>
 * @Date:   2019-03-15T15:50:56-07:00
 * @Email:  kleber@hackcapital.com
 * @Last modified by:   kleberdasilva
 * @Last modified time: 2019-03-25T09:15:39-07:00
 */

export const KeyboardShortcuts =
  window.navigator.platform === 'MacIntel'
    ? {
      Source: 'Edit Source',
      Copy: 'Copy (⌘C‬)',
      Cut: 'Cut (⌘X‬)',
      Paste: 'Paste (⌘V‬)',
      Bold: 'Bold (⌘B‬)',
      Italic: 'Italic (⌘I‬)',
      Underline: 'Underline (⌘U‬)',
      NumberedList: 'Numbered List',
      BulletedList: 'Bulleted List',
      Indent: 'Increase Indent',
      Outdent: 'Decrease Indent',
      ClearFormat: 'Clear Format',
      Hyperlink: 'Insert Link',
      Attachment: 'Attach Files',
      LDContent: 'Insert LionDesk Content',
      LDVideo: 'Insert LionDesk Video',
      ImageUrl: 'Insert Image Url',
      Emoji: 'Insert Emoji',
    }
    : {
      Source: 'Edit Source',
      Copy: 'Copy (Ctrl-C)',
      Cut: 'Cut (Ctrl-X)',
      Paste: 'Paste (Ctrl-V)',
      Bold: 'Bold (Ctrl-B)',
      Italic: 'Italic (Ctrl-I‬)',
      Underline: 'Underline (Ctrl-U)',
      NumberedList: 'Numbered List',
      BulletedList: 'Bulleted List',
      Indent: 'Increase Indent',
      Outdent: 'Decrease Indent',
      ClearFormat: 'Clear Format',
      Hyperlink: 'Insert Link',
      Attachment: 'Attach Files',
      LDContent: 'Insert LionDesk Content',
      LDVideo: 'Insert LionDesk Video',
      ImageUrl: 'Insert Image Url',
      Emoji: 'Insert Emoji',
    };
