<template>
  <ld-dismissable-banner
      v-if="isVisible"
      error
      text="You have not selected your custom number, please head over to your payments and subscriptions to continue this process."
      upgradeText="Payments and Subscriptions"
      :upgradeLink="redirectUrl"
      @input="onDismiss()"
    ></ld-dismissable-banner>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import LdDismissableBanner from './LdDismissableBanner';
import Plans from 'lion-vue-src/config/plans';
import { HC_PROPS } from 'lion-vue-src/_globals';

const CUSTOM_NUMBER_DISMISSED = "custom_number_notification_dismissed";

export default {
  components: { LdDismissableBanner },
  data() {
    return {
      isVisible: false,
    }
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  watch: {
    user: {
      handler(value) {
        if (!value) {
          return;
        }

        this.isVisible = !this.isDismissed();
        if (this.isVisible) {
          this.$emit('show', this.isVisible);
        }
      },
      immediate: true,
      deep: true,
    },
    smsStatusOk: {
      handler(value) {
        if (!value) {
          this.onDismiss();
        }
      },
      immediate: true,
      deep: true,
    },
    twilioSubaccountIsActive: {
      handler(value) {
        if (!value) {
          this.onDismiss();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapState('customNumber', ['smsStatusOk', 'smsStatusError', 'twilioSubaccountIsActive', 'twilioSubaccountError']),
    redirectUrl() {
      return `${HC_PROPS.ACCOUNTS_APP_URL}/settings?modal=customNumber`;
    },
    isCustomNumberPresent() {
      const gatedPlans = [Plans.ProPlus, Plans.Elite, Plans.CRM, Plans.CRMPremier];
      const customNumber = this.user.custom_number;
      const { txt_plan } = this.user.subscriptionOverview;
      const validPlan = gatedPlans.includes(txt_plan);

      return !validPlan || !!customNumber
    },
  },
  created() {
    this.checkSmsStatus();
    this.checkTwilioSubaccountStatus();
  },
  methods: {
    ...mapActions('customNumber', ['checkSmsStatus', 'checkTwilioSubaccountStatus']),
    isDismissed() {
      const value = localStorage.getItem(CUSTOM_NUMBER_DISMISSED);

      return (value && value === 'true') || this.isCustomNumberPresent;
    },
    onDismiss() {
      localStorage.setItem(CUSTOM_NUMBER_DISMISSED, true);
      this.isVisible = false;
      this.$emit('show', this.isVisible);
    },
  },
};
</script>
