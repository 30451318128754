export default {
  data() {
    return {
      stagePlanMixin_showStageChangeModal: false,
      stagePlanMixin_lastStage: null,
      stagePlanMixin_nextStage: null,
      stagePlanMixin_isLoading: false,
      stagePlanMixin_returnLastStage: false,
    };
  },
  computed: {
    nextStageHasPlan() {
      if (!this.plans) return false;
      return this.plans.some(plan => plan.stage_id === this.stagePlanMixin_nextStage);
    },
    currentPlanName() {
      const currentPlan = this.plans.find(plan => plan.stage_id === this.stagePlanMixin_lastStage);
      return currentPlan ? currentPlan.title : "";
    },
    nextPlanName() {
      const nextPlan = this.plans.find(plan => plan.stage_id === this.stagePlanMixin_nextStage);
      return nextPlan ? nextPlan.title : "";
    },
    currentStageName() {
      if (!this.stages) return "";
      const currentStage = this.stages.find(stage => stage.value === this.stage || stage.id === this.stage);
      return currentStage ? currentStage.text || currentStage.title : "";
    },
    nextStageName() {
      if (!this.stages) return "";
      const nextStage = this.stages.find(stage => stage.value === this.stagePlanMixin_nextStage || stage.id === this.stagePlanMixin_nextStage);
      return nextStage ? nextStage.text || nextStage.title : "";
    },
  },
};
