<!--
@Author: Ethan Dinnen
@Date:   2019-02-11 13:19:18
@Last Modified by:   Ethan Dinnen
@Last Modified time: 2019-02-20 12:41:41
-->

<template>
  <v-navigation-drawer
    temporary
    clipped
    fixed
    hide-overlay
    :right="slideFromRight"
    :value="value"
    @input="toggleDrawer"
    :style="styles"
  >
    <slot name="closeToggle">
      <v-btn mx-4 large flat icon @click="toggleDrawer(false)">
        <v-icon class="closeToggleIcon">keyboard_arrow_right</v-icon>
      </v-btn>
      <slot name="actions"/>
      <v-divider></v-divider>
    </slot>
    <slot name="component"></slot>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'LdSlideOut',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    slideFromRight: {
      type: Boolean,
      default: false,
    },
    additionalStyles: {
      type: String,
      default: 'width: 50%; padding-top: 64px;',
    },
    toggleEvent: {
      type: String,
      default: 'toggle',
    },
  },
  methods: {
    toggleDrawer(newValue) {
      this.$emit(`${this.toggleEvent}`, newValue);
    },
  },
  computed: {
    styles() {
      if (this.value) {
        return this.additionalStyles;
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
/** prevent the drawer from covering the top navbar */
.v-navigation-drawer--temporary,
.v-navigation-drawer--is-mobile {
  z-index: 11 !important;
}
.closeToggleIcon {
  z-index: -1;
}
</style>
