<!--
  @Author: Ethan Dinnen
  @Date:   2019-01-23 17:06:28
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-02-11 11:25:30
-->
<template>
  <div v-if="!preview">
    <v-card class="stage-card">
      <v-card-text>
        <div class="stage-card-info">
          <v-form v-model="nameValid">
            <v-text-field
              v-if="controls"
              class="stage-card-info-name"
              ref="TxTypeTitleEdit"
              label="Stage:"
              color="primary"
              :placeholder="stage.title"
              v-model="newName"
              :rules="nameRules"
              @blur="handleRenameStage({ newName, title: stage.title });"
              @keyup.enter.native="
                handleRenameStage({ newName, title: stage.title });
              "
              :error-messages="newName ? null : ['Stage must have a name']"
            />
            <h3 :class="controls ? 'pointer' : null" v-else>
              Stage: <span>{{ stage.title }}</span>
            </h3>
            <v-checkbox
              label="In the Pipeline"
              v-model="isInPipeline"
              :disabled="checkDisabled"
              :value="true"
              @change="updateStageType({ stage, isInPipeline });"
            />
          </v-form>
        </div>
        <div class="stage-card-actions" v-if="controls">
          <div class="stage-card-actions-sort">
            <v-btn
              flat
              @click="moveStageUp(stage);"
              :disabled="upDisabled(stage)"
            >
              <v-icon>arrow_upward</v-icon>
            </v-btn>
            <v-btn
              flat
              @click="moveStageDown(stage);"
              :disabled="downDisabled(stage)"
            >
              <v-icon>arrow_downward</v-icon>
            </v-btn>
          </div>
          <div class="stage-card-actions-divider" />
          <div class="stage-card-actions-close">
            <v-btn flat @click="handleRemoveStage(stage);">
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <LdConfirmation
      :value="dialog"
      width="580"
      risk="high"
      @click:cancel="dialog = false;"
      @click:ok="handleMoveTransaction"
      :disabled="moveTo === null"
      confirmText="Delete and move transactions"
    >
      <template slot="header">
        Delete "{{ stage.title }}"
      </template>
      <template slot="body">
        {{ transactions.transactionCount }} transaction{{
          pluralTransactions
        }}
        in this stage. In order to delete this stage, all transactions must be
        moved to another stage. Please select the stage to move transactions to.
        <SelectInput
          class="move-select"
          @onInput="setMoveTo"
          :options="stagesOptions"
          title="Stage to move to"
          :disabled="stagesOptions.length === 0"
          :errors="stageMoveErrors"
          placeholder="Please select a stage"
        />
      </template>
    </LdConfirmation>
  </div>
  <div class="stage-preview" v-else>
    <span class="stage-preview__title">{{ stage.title }}</span>
    <v-checkbox
      label="In the Pipeline"
      v-model="isInPipeline"
      :disabled="true"
    />
  </div>
</template>

<script>
import { LdConfirmation } from 'lion-vue';
import SelectInput from '@/Core/components/SelectInput';

export default {
  components: {
    SelectInput,
    LdConfirmation,
  },
  props: {
    stage: Object,
    moveStageUp: {
      type: Function,
      required: false,
    },
    moveStageDown: {
      type: Function,
      required: false,
    },
    upDisabled: {
      type: Function,
      required: false,
    },
    downDisabled: {
      type: Function,
      required: false,
    },
    removeStage: {
      type: Function,
      required: false,
    },
    inPipeline: Boolean,
    updateStageType: {
      type: Function,
      required: false,
    },
    controls: {
      type: Boolean,
      default: true,
    },
    renameStage: Function,
    preview: {
      type: Boolean,
      default: false,
    },
    transactions: {
      type: Object,
      default: () => {},
    },
    stages: {
      type: Array,
    },
    moveTransactions: {
      type: Function,
    },
  },
  data() {
    return {
      dialog: false,
      moveTo: null,
      isInPipeline: this.inPipeline,
      newName: this.stage.title,
      nameValid: false,
      nameRules: [v => !!v || 'Stage must have a name'],
    };
  },
  computed: {
    checkDisabled() {
      return !this.controls && this.nameValid;
    },
    stagesOptions() {
      return this.stages
        .filter(stage => stage.title !== this.stage.title && stage.id)
        .map(stage => {
          return { text: stage.title, value: stage.id };
        });
    },
    stageMoveErrors() {
      if (!this.stagesOptions.length)
        return [
          'No saved stages avaliable. Please save your progress and try again.',
        ];
      return null;
    },
    pluralTransactions() {
      return this.transactions.transactionCount > 1 ? 's are' : ' is';
    },
  },
  methods: {
    handleRenameStage({ newName, title }) {
      if (newName === this.stage.title) {
        return;
      }
      this.newName = newName;
      this.renameStage({ newName, title });
    },
    handleRemoveStage(stage) {
      if (this.transactions.transactionCount && this.stages.length > 1) {
        this.dialog = true;
        return;
      }
      this.removeStage(stage);
    },
    setMoveTo(selected) {
      this.moveTo = selected;
    },
    async handleMoveTransaction() {
      await this.moveTransactions({
        transactions: this.transactions.transactions,
        from: this.stage.id,
        to: this.moveTo,
      });
      this.removeStage(this.stage);
    },
  },
};
</script>

<style lang="scss">
.headline {
  color: white;
}
.stage-card {
  margin: 15px 0;
  .v-card__text {
    padding: 15px 10px 15px 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &-info {
    .pointer {
      cursor: edit;
    }
    &-name {
      margin-bottom: -15px;
      .v-messages__message {
        margin-bottom: 10px;
      }
    }
    h3 {
      font-size: 17px;
      margin-bottom: 0;
    }
    .v-input--selection-controls {
      margin-top: 5px;
      margin-bottom: -15px;
    }
  }
  &-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 65px;
    button {
      min-width: unset;
      width: 26px;
      padding: 0;
      margin: 0;
    }
    &-sort {
      display: flex;
      flex-direction: column;
      justify-content: center;
      button {
        height: 24px;
        &:first-of-type {
          margin-bottom: 10px;
        }
        .v-btn__content {
          height: 24px;
        }
      }
      i {
        font-size: 25px;
      }
    }
    &-divider {
      width: 1px;
      height: 55px;
      margin: auto;
      margin-left: 27px;
      border-left: 1px solid var(--v-lion-light-grey-base);
    }
    &-close {
      margin: auto;
      margin-right: 15px;
      margin-left: 25px;
    }
  }
}

.stage-preview {
  border: 1px solid var(--v-lion-light-grey-base);
  &:not(:last-of-type) {
    border-bottom: none;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 29px 0 21px;
  &__title {
    margin: auto 0;
    color: var(--v-lion-grey-base);
    font-family: 'Open Sans';
    font-size: 21px;
    line-height: 28px;
  }
  .v-input--checkbox {
    flex: unset;
  }
}

.ld-confirmation {
  .move-select {
    margin-top: 20px;
  }
}
</style>
