<!--
  @Author: Ethan Dinnen
  @Date:   2018-12-11 15:54:24
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-01-16 12:32:36
-->

<template lang="pug">
  v-menu(:offset-y='offsetY || false')
    v-btn(v-if='includeButton' slot="activator" outline)
      v-icon(:color="color") {{ icon }}
    v-icon(v-else :color='color' slot='activator') {{ icon }}
    v-list
      v-list-tile(
        v-for='(item, index) in actions'
        :key='index'
        @click='handleClick(item)'
        )
        v-list-tile-action(v-if='item.icon')
          v-icon {{ item.icon }}
        v-list-tile-content
          v-list-tile-title(v-text='item.title')
</template>

<script>
export default {
  /**
   * This component is used predominately on tables currently, it was made
   * in an effort to generalize a menu to be used in any table where you have a column
   * for actions. This is normally signified by the more_vert (triple dot) icon,
   * so that is the default in this case.
   */
  props: {
    offsetY: {
      type: Boolean,
    },
    icon: {
      type: String,
      default: 'more_vert',
    },
    actions: {
      type: Array,
    },
    includeButton: {
      type: Boolean,
      default: false,
    },
    transactionId: {
      type: Number,
    },
    color: {
      type: String,
      default: 'grey',
    },
  },
  methods: {
    handleClick(item) {
      if (!item.path) {
        item.func(this.transactionId);
        return;
      }
      this.$router.push(item.path);
    },
  },
};
</script>
