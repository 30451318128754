const geojson = state => state.listings.reduce((geoArray, listing) => {
  if (listing._source.MlsLocation) {
    geoArray.push({
      position: {
        id: listing._id,
        lat: listing._source.MlsLocation.lat,
        lng: listing._source.MlsLocation.lon,
      },
    });
  } else if (listing._source.Location) {
    geoArray.push({
      position: {
        id: listing._id,
        lat: listing._source.Location.lat,
        lng: listing._source.Location.lon,
      },
    });
  }
  return geoArray;
}, []);

const getListing = state => id => {
  let matchingListing = (state.listing._id === id)
    ? state.listing
    : state.listings.find(listing => listing._id === id);

  if(!matchingListing) {
    // Find in OLD Pipeline structure
    matchingListing = (state.listing._source.ListingId === id)
      ? state.listing
      : state.listings.find(listing => listing._source.ListingId === id);
  }

  if (matchingListing && matchingListing._source.ImageUrls) {
    const { ProcessedURLs, processed } = matchingListing._source.ImageUrls;
    matchingListing._source.ImageUrls.processedImages = ProcessedURLs || processed;
  }
  return matchingListing;
};


const findCaller = state => (state.findCaller);

export default {
  geojson,
  getListing,
  findCaller,
};
