<!--
  @Author: Ethan Dinnen
  @Date:   2018-12-03 13:40:21
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-02-19 11:14:53
-->

<template>
  <!-- Don't render until transaction retrieved from API -->
  <v-container v-if="renderReady" class="view-container">
    <div>
      <router-link to="/">
        <v-btn flat class="tx-nav-btn">
          <v-icon>chevron_left</v-icon> Back to Transactions
        </v-btn>
      </router-link>
      <TransactionHeader
        :created="transaction.created_at"
        :title="transaction.title"
        :type="transaction.transaction_type.name"
        :stage="transaction.stage_id"
        :stages="stages"
        :moveStage="moveStage"
        :kcdsIncomplete="kcdsIncomplete"
        :plans="plans"
        :txId="transaction.id"
        :planName="selectedPlanName"
        :startPlan="startPlan"
        :hasPlan="hasPlan"
        :stakeholders="stakeholders"
      />
      <PropertyInfo
        v-if="transaction.meta.property"
        :property="transaction.meta.property"
        :price="financials[0].value"
      />
      <PlansTable 
        :plans="plans"
        :executedPlans="transaction.meta.plan_execution_record"
      />
      <StakeholderInfo
        :stakeholders="stakeholders"
        :campaigns="campaigns"
        :getCampaigns="getCampaigns"
      />
      <TasksTable
        :isLoading="tasksLoading"
        :tasks="tasks"
        :toggleComplete="toggleComplete"
        :deleteTask="deleteTask"
        :deleteCommunication="deleteCommunication"
        :stakeholders="stakeholders"
      />
      <FormsInfo
        :txId="transaction.id"
        :forms="transaction.transaction_forms"
      />
    </div>
  </v-container>
  <v-container v-else class="view_transaction_loader">
    <BreedingRhombusSpinner />
  </v-container>
</template>

<script>
import BreedingRhombusSpinner from '@/Core/components/BreedingRhombusSpinner';
import TransactionHeader from './components/TransactionHeader';
import PropertyInfo from './components/PropertyInfo';
import StakeholderInfo from './components/StakeholderInfo';
import FormsInfo from './components/FormsInfo';
import TasksTable from './components/TasksTable';
import PlansTable from './components/PlansTable';
import { PLAN_BY_STAGE_FLAG } from '@/Core/config/constants';
import { createNamespacedHelpers } from 'vuex';

export default {
  components: {
    BreedingRhombusSpinner,
    TransactionHeader,
    PropertyInfo,
    StakeholderInfo,
    FormsInfo,
    TasksTable,
    PlansTable,
  },
  props: {
    transaction: {
      required: true,
    },
    stages: {
      required: true,
    },
    moveStage: {
      type: Function,
      required: true,
    },
    stakeholders: {
      type: Array,
    },
    campaigns: {
      type: Array,
    },
    getCampaigns: {
      type: Function,
    },
    toggleComplete: {
      type: Function,
    },
    deleteTask: {
      type: Function,
    },
    deleteCommunication: {
      type: Function,
    },
    tasksLoading: {
      type: Boolean,
    },
    kcdsIncomplete: {
      type: Array,
    },
    plans: {
      type: Array,
    },
    startPlan: {
      type: Function,
    },
    hasPlan: {
      type: Boolean,
    },
  },
  computed: {
    financials() {
      const financials = this.transaction.transaction_forms.find(
        form => form.name === 'Financials'
      ).transaction_form_fields;
      return financials;
    },
    renderReady() {
      return (
        this.transaction !== null &&
        this.stakeholders !== null &&
        this.stages !== null
      );
    },
    tasks() {
      if (!this.transaction.tasks && !this.transaction.communications)
        return [];

      const tasks = this.transaction.tasks ? this.transaction.tasks : [];
      const communications = this.transaction.communications
        ? this.transaction.communications
        : [];
      const combined = [
        ...tasks.map(task => {
          return { ...task, type: 'task' };
        }),
        ...communications,
      ];
      return combined.filter(task => task.status !== 'deleted');
    },
    selectedPlanName() {
      if (!this.transaction) return '';
      if (!this.transaction.meta.plan) return '';
      const plan = this.plans.find(
        plan => plan.id === +this.transaction.meta.plan
      );
      if (!plan) return '';
      return plan.title;
    },
  },
};
</script>

<style lang="scss" scoped>
.view-container {
  margin-top: 40px;
}
a {
  text-decoration: none;
}
.stageMenu {
  display: flex;
  flex-direction: row;
}
.tx-nav-btn {
  color: #3878c1 !important;
  margin-left: 0;
  margin-bottom: 25px;
  position: relative;
  right: 12px;
  padding: 0 5px;
}
.view_transaction_loader {
  height: calc(100vh - 64px);
  display: flex;
  .breeding-rhombus-spinner {
    margin: auto;
  }
}
</style>
