<template>
  <v-tab v-bind="$props" @change="onAction($event, 'change')" :class="gatedClass">
    <slot></slot>
  </v-tab>
</template>

<script>
import featureGate from 'lion-vue-src/Shared/mixins/featureGate';

export default {
  props: [ 'disabled', 'href', 'tabindex', 'to' ],
  mixins: [featureGate],
};
</script>
