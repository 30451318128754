<template>
  <ld-persistent-banner
    :warningIcon="true"
    class="banner-ms-migration"
    v-if="needReconnection"
    :error="true"
    :text="text"
    :complete="false"
    :loading="true"
    link=""
    buttonText=""
  >
  </ld-persistent-banner>
</template>
<script>
import LdPersistentBanner from './LdPersistentBanner';
import { HC_PROPS } from 'lion-vue-src/_globals';
export default {
  components: { LdPersistentBanner },
  props: {
    needReconnection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
        visible: false,
    };
  },
  mounted() {
    this.visible = this.needReconnection;
  },
  computed: {
    redirectUrl() {
      return `${HC_PROPS.PLATFORM_APP_URL}/email-integrations`;
    },
    text() {
      return `<strong>Update Needed!</strong> To continue using email sync, you must reconnect your email in LionDesk. 
        Microsoft has updated its security defaults to ensure user accounts and the data they contain are far better protected going forward.
        Please reconnect your email <a href="${this.redirectUrl}">here</a>.`;
    },
  },
  watch: {
    visible: {
        handler() {
            this.$emit('show', this.visible);
        },
    },
    needReconnection: {
        handler() {
            this.$emit('show', this.needReconnection);
        },
    },
  },
};
</script>
<style lang="scss">
  .banner-ms-migration {
    height: auto !important;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .v-btn {
        display: none;
    }
  }
</style>
