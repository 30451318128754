<template lang="pug">
  v-layout.ld-tile(:class="{ 'is-active': isActive, 'disabled': disabled }" align-center justify-center)
    //- The content of the layout, which could be changed and customized
    slot(name="content")
      v-layout(align-center justify-center column)
        v-icon(v-if="icon") {{ icon }}
        div.subheader(v-if="textContent") {{ textContent }}
</template>
<script>
export default {
  props: {
    // Whether the current tile is disabled and clickable
    disabled: Boolean,
    // The content of the card to be displayed
    textContent: String,
    // The icon to be displayed if present
    icon: String,
    // Indicates that a current tile is active by giving it the 'is-active' class.
    isActive: Boolean,
  },
};
</script>
<style lang="scss">
.ld-tile {
  cursor: pointer;
  height: 150px;
  width: 150px;
  color: var(--v-primary-base);
  > div > i {
    font-size: 60px;
    color: var(--v-primary-base) !important;
    padding-bottom: 10px;
  }
}
.is-active {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.disabled {
  cursor: default;
  opacity: 0.4;
}
</style>
