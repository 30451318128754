/*
 * @Author: Hugo Dorea
 * @Date:   2018-11-05 11:40:32
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-02-07 11:29:55
 */

import Vue from 'vue';
import Vuex from 'vuex';

import transactions from 'transactionsComponents/store';
import viewTransaction from 'transactionsComponents/ViewTransaction/store';
import transactionTypesWidget from 'transactionsComponents/TransactionTypesWidget/store';
import forms from 'transactionsComponents/Forms/store';
import createType from 'transactionsComponents/TransactionTypeWizard/store';
import plans from 'transactionsComponents/CreatePlan/store';
import planTasks from 'transactionsComponents/CreatePlanAction/store';
import editPlans from 'transactionsComponents/EditPlan/store';
import { stores } from 'lion-vue';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    ...stores,
    transactions,
    viewTransaction,
    transactionTypesWidget,
    forms,
    plans,
    planTasks,
    createType,
    editPlans,
  },
});
