/*
 * @Author: Ethan Dinnen
 * @Date:   2019-02-05 15:56:03
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-02-20 13:02:51
 */
import { genId } from '@/Core/mixins/genId';

export default {
  position: 1,
  name: 'Property Info',
  tmp_id: genId(),
  mandatory: false,
  template_fields: [
    {
      label: 'Country',
      position: 1,
      type: 'text',
      field_source: '_source.Geo[0].AddressComponents.Country',
      tmp_id: genId(),
    },
    {
      label: 'Street Number',
      position: 2,
      type: 'text',
      field_source: '_source.StreetNumber',
      tmp_id: genId(),
    },
    {
      label: 'Street Name',
      position: 3,
      type: 'text',
      field_source: '_source.StreetName',
      tmp_id: genId(),
    },
    {
      label: 'Unit Number',
      position: 4,
      type: 'text',
      field_source: '_source.UnitNumber',
      tmp_id: genId(),
    },
    {
      label: 'City',
      position: 5,
      type: 'text',
      field_source: '_source.City',
      tmp_id: genId(),
    },
    {
      label: 'State/Prov',
      position: 6,
      type: 'text',
      field_source: '_source.StateOrProvince',
      tmp_id: genId(),
    },
    {
      label: 'Zip/Postal Code',
      position: 7,
      type: 'text',
      field_source: '_source.Geo[0].AddressComponents.PostalCode',
      tmp_id: genId(),
    },
    {
      label: 'County',
      position: 8,
      type: 'text',
      field_source: '_source.CountyOrParish',
      tmp_id: genId(),
    },
    {
      label: 'MLS Number',
      position: 9,
      type: 'text',
      field_source: '_source.ListingId',
      tmp_id: genId(),
    },
    {
      label: 'Parcel/Tax ID',
      position: 10,
      type: 'text',
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Year Built',
      position: 11,
      type: 'text',
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Bedrooms',
      position: 12,
      type: 'text',
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Square Footage',
      position: 13,
      type: 'text',
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'School District',
      position: 14,
      type: 'text',
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Type',
      position: 15,
      type: 'text',
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Bathrooms',
      position: 16,
      type: 'text',
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Lot Size',
      position: 17,
      type: 'text',
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Expiration Date',
      position: 18,
      type: 'text',
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Listing Date',
      position: 19,
      type: 'text',
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Original Price',
      position: 20,
      type: 'text',
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Current Price',
      position: 21,
      type: 'text',
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: '1st Mortgage Balance',
      position: 22,
      type: 'text',
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: '2nd Mortgage Balance',
      position: 23,
      type: 'text',
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Other Liens',
      position: 24,
      type: 'text',
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Description of Other Liens',
      position: 25,
      type: 'text',
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: "Homeowner's Association",
      position: 26,
      type: 'text',
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: "Homeowner's Association Dues",
      position: 27,
      type: 'text',
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Total Encumbrances',
      position: 28,
      type: 'text',
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Property Includes',
      position: 29,
      type: 'text',
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Property Excludes',
      position: 30,
      type: 'text',
      field_source: '',
      tmp_id: genId(),
    },
    {
      label: 'Remarks',
      position: 31,
      type: 'text',
      field_source: '',
      tmp_id: genId(),
    },
  ],
};
