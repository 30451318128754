/*
 * @Author: Ethan Dinnen
 * @Date:   2019-01-15 12:31:32
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-01-16 9:41:43
 */
import Title from 'transactionsComponents/components/TransactionsTitle';
import Item from 'transactionsComponents/components/TransactionsTableItem';
export default {
  computed: {
    id() {
      const { item } = this.data;
      if (item) {
        return item.id;
      }
      return this.data;
    },
    item() {
      const { item } = this.data;
      if (item) {
        return item.data;
      }
      return this.data;
    },
    isSelected: {
      get: function() {
        const { item } = this.data;
        return this.selected.includes(item);
      },
      set: function() {
        const { item } = this.data;
        const index = this.selected.indexOf(item);
        if (index > -1) {
          this.selected.splice(index, 1);
        } else {
          this.selected.push(item);
        }
      },
    },
  },
  methods: {
    getValue(index) {
      return this.item[index].value;
    },
    expand(data) {
      this.$emit('click:expand', data);
    },
    selectOne(data) {
      this.$emit('click:select-one', data);
    },
    /**
     * Checks the column name and renders a component based on that
     * @param  {String} column the column name in the data object
     * @return {}              a vue component
     */
    dataCellComponent(column) {
      return { Title }[column] || Item;
    },
  },
};
