<!--
@Author: Ethan Dinnen
@Date:   2018-12-18 15:11:42
@Last Modified by:   Ethan Dinnen
@Last Modified time: 2019-02-19 16:39:38
-->
<template>
  <ld-gated-content class="flow-upgrade" :feature="LdFeatures.ApplicationTransactions">
    <v-flex class="xs12">
      <v-container fluid="fluid" id="flow-container" class="flow-container">
        <v-layout column="column" wrap="wrap">
          <v-layout row class="top-divisor">
            <slot name="header"></slot>
          </v-layout>
          <v-layout row :id="getBodyId" class="flow-body">
            <v-flex class="xs12 d-flex">
              <slot></slot>
            </v-flex>
          </v-layout>
          <v-layout
            id="flow-footer"
            class="footer"
            row
            v-if="currentStage !== 'transaction-creation-loop'"
          >
            <slot name="footer"></slot>
          </v-layout>
        </v-layout>
      </v-container>
    </v-flex>
  </ld-gated-content>
</template>

<script>
import { LdFeatures, LdGatedContent } from 'lion-vue';
import constants from '@/Core/constants';
import { createNamespacedHelpers } from 'vuex';

const { mapActions: mapAppLayoutActions } = createNamespacedHelpers(
  'ldAppLayout'
);

export default {
  components: {
    LdGatedContent,
  },
  props: {
    currentStage: String,
  },
  data() {
    return {
      LdFeatures,
      resizeObserver: null,
      container: null,
      footer: null,
      body: null,
    };
  },
  computed: {
    isType() {
      return this.$route.path.includes('type');
    },
    getBodyId() {
      if (this.isType) {
        return 'flow-body';
      }
      return 'flow-body flow-body-scroll';
    },
  },
  methods: {
    ...mapAppLayoutActions(['getUserInfo']),
    initializeContainers() {
      this.container = document.getElementById(
        this.isType ? constants.WIZARD_WATCH_HEIGHT : 'flow-container'
      );
      this.body = document.getElementById('flow-body');
      this.footer = document.getElementById('flow-footer');
    },
  },
  mounted() {
    this.getUserInfo();
    this.initializeContainers();
    const callback = entries => {
      for (const entry of entries) {
        if (entry.target.id === constants.WIZARD_WATCH_HEIGHT) {
          const height = this.isType
            ? entry.target.scrollHeight
            : entry.contentRect.height;

          const vh = this.isType
            ? this.body.scrollHeight
            : Math.max(
                document.documentElement.clientHeight,
                window.innerHeight || 0
              );

          const boxShadow =
            height >= vh ? '0px -5px 8px rgba(0,0,0,0.2)' : 'unset';
          this.footer.style['box-shadow'] = boxShadow;
        }
      }
    };
    this.resizeObserver = new ResizeObserver(callback);
    this.resizeObserver.observe(this.container);
  },
  beforeUpdate() {
    this.resizeObserver.disconnect();
    this.initializeContainers();
    this.resizeObserver.observe(this.container);
  },
  beforeDestroy() {
    this.resizeObserver.disconnect();
  },
  watch: {
    currentStage: {
      handler(value) {
        if (value === constants.TRANSACTION_CREATION_LOOP) {
          return;
        }
        const el = document.querySelector(`#${value}`);
        el.scrollIntoView();
      },
    },
  },
};
</script>

<style lang="scss">
.footer {
  position: fixed;
  bottom: 0px;
  display: flex;
  width: 100%;
  padding: 15px 40px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  background-color: #fafafa;
  flex-direction: row-reverse;
}
.flow-container {
  padding: 0;
}
.top-divisor {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
  padding-left: 130px;
}
.flow-body {
  height: calc(100vh - 158px);
}
.flow-body-scroll {
  overflow-y: scroll;
  max-height: calc(100vh - 79px);
}
.flow-upgrade .upgrade-banner {
  margin: 12px;
}
</style>
