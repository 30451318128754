const FEATURE_FLAG_CONTACTS_MS = 'contacts-ms';
const FEATURE_FLAG_CONTACTS_MS_TOGGLE = 'contacts-ms-toggle';

const FEATURE_FLAG_EVENTS_MS = 'events-ms';
const FEATURE_FLAG_EVENTS_MS_TOGGLE = 'events-ms-toggle';

const FEATURE_FLAG_QUALIFY = 'qualify';

const PROFILE_PICTURE_LIMIT_SIZE = '4 MB';

export {
  FEATURE_FLAG_CONTACTS_MS,
  FEATURE_FLAG_CONTACTS_MS_TOGGLE,
  FEATURE_FLAG_EVENTS_MS,
  FEATURE_FLAG_EVENTS_MS_TOGGLE,
  FEATURE_FLAG_QUALIFY,
  PROFILE_PICTURE_LIMIT_SIZE,
};
