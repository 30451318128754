<!--
  @Author: Ethan Dinnen
  @Date:   2018-11-26 13:21:45
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-01-07 16:37:15
-->

<template>
  <v-app>
    <router-view></router-view>
    <v-snackbar
      v-model="showProfileError"
      color="error"
    >
      <div>
        {{ errorMessage }}
      </div>
    </v-snackbar>
  </v-app>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState: mapLdAppLayoutState, mapMutations: mapLdAppLayoutMutations } =
  createNamespacedHelpers('ldAppLayout');

export default {
  name: 'App',
  computed: {
    ...mapLdAppLayoutState(['hasError', 'errorMessage', 'userInfo']),
    showProfileError: {
      get() {
        return this.hasError;
      },
      set(value) {
        this.setError({ hasError: false, errorMessage: '' });
      },
    },
  },
  methods: {
    ...mapLdAppLayoutMutations(['setError']),
    loadEmbedSupportChat() {
      this.loadIntercomChat();
      this.loadSalesForceChat();
    },
    loadIntercomChat() {
      this.$intercom.boot({
        hide_default_launcher: true,
        alignment: 'right',
      });
      this.$intercom.hide();
    },
    loadSalesForceChat() {
      if (!window.embedded_svc) return;

      window.embedded_svc.settings.prepopulatedPrechatFields = {
        FirstName: this.userInfo.first_name,
        LastName: this.userInfo.last_name,
        Email: this.userInfo.email,
      };

      this.loadSFExtraPrechatDetails();
    },
    initSalesForceChat(gslbBaseURL) {
      if (window.is_loaded_embedded_svc) {
        return;
      }
      window.embedded_svc.addEventHandler('onSettingsCallCompleted', isAgentAvailable => {
        window.is_loaded_embedded_svc = true
      });

      window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
      window.embedded_svc.settings.entryFeature = 'LiveAgent';
      window.embedded_svc.settings.language = 'en';

      //with this the session domain behavior will work for different environments
      window.embedded_svc.settings.storageDomain = this.getSFStorageDomain();
      window.embedded_svc.settings.smallCompanyLogoImgURL =
        '/img/liondesk-logo.0988c98b.png';
      window.embedded_svc.settings.defaultMinimizedText = 'Chat with Support';
      window.embedded_svc.settings.loadingText = 'Connecting...';
      window.embedded_svc.settings.displayHelpButton = true;
      window.embedded_svc.init(
        'https://lonewolf.my.salesforce.com',
        'https://community.lwolf.com/',
        gslbBaseURL,
        '00D1a000000aEOE',
        'LionDesk_Web_Chat_Legacy',
        {
          baseLiveAgentContentURL:
            'https://c.la1-core1.sfdc-58ktaz.salesforceliveagent.com/content',
          deploymentId: '5721a000000L3N7',
          buttonId: '573N300000027v3',
          baseLiveAgentURL:
            'https://d.la1-core1.sfdc-58ktaz.salesforceliveagent.com/chat',
          eswLiveAgentDevName:
            'EmbeddedServiceLiveAgent_Parent04IN30000005RdtMAE_18dc749f77a',
          isOfflineSupportEnabled: false,
        }
      );
    },
    getSFStorageDomain() {
      const domainParts = window.location.hostname.split('.');
      if (domainParts.length <= 2) {
        return window.location.hostname;
      }

      return (
        domainParts[domainParts.length - 2] +
        '.' +
        domainParts[domainParts.length - 1]
      );
    },
    loadSFExtraPrechatDetails() {
      // All of this values must be strings
      // ref: https://developer.salesforce.com/docs/atlas.en-us.snapins_web_dev.meta/snapins_web_dev/snapins_web_prechat_details.htm
      window.embedded_svc.settings.extraPrechatFormDetails = [
        //User general info
        {
          label: 'User ID',
          value: `${this.userInfo.id}`,
          displayToAgent: true,
        },
        {
          label: 'First Name',
          value: `${this.userInfo.first_name}`,
          displayToAgent: true,
        },
        {
          label: 'Last Name',
          value: `${this.userInfo.last_name}`,
          displayToAgent: true,
        },
        {
          label: 'Email',
          value: `${this.userInfo.email}`,
          displayToAgent: true,
        },
        {
          label: 'Name',
          value: `${this.userInfo.first_name} ${this.userInfo.last_name}`,
          displayToAgent: true,
        },
        {
          label: 'Mobile',
          value: `${this.userInfo.phone_read_only.String}`,
          displayToAgent: true,
        },
        {
          label: 'Industry',
          value: `${this.userInfo.industry}`,
          displayToAgent: true,
        },
        {
          label: 'Role',
          value: `${this.userInfo.role}`,
          displayToAgent: true,
        },
        {
          label: 'Account Version',
          value: `${this.userInfo.account_version}`,
          displayToAgent: true,
        },
        {
          label: 'Custom Number',
          value: `${this.userInfo.custom_number}`,
          displayToAgent: true,
        },
        {
          label: 'LoneWolf ID',
          value: `${this.userInfo.lwa_id}`,
          displayToAgent: true,
        },
        {
          label: 'Website',
          value: `${this.userInfo.website}`,
          displayToAgent: true,
        },
        // user subscription info
        {
          label: 'Subscription: Plan',
          value: `${this.userInfo.subscriptionOverview.txt_plan}`,
          displayToAgent: true,
        },
        {
          label: 'Subscription: Expires In (Days)',
          value: `${this.userInfo.subscriptionOverview.expires_in_days}`,
          displayToAgent: true,
        },
        {
          label: 'Subscription: Is Subaccount Admin',
          value: `${this.userInfo.subscriptionOverview.is_broker}`,
          displayToAgent: true,
        },
        {
          label: 'Subscription: Is Subaccount',
          value: `${this.userInfo.subscriptionOverview.is_subaccount}`,
          displayToAgent: true,
        },
        {
          label: 'Subscription: Status',
          value: `${this.userInfo.subscriptionOverview.txt_status}`,
          displayToAgent: true,
        },
        {
          label: 'Leads+: Is Upgrade',
          value: `${
            this.userInfo.subscriptionOverview.active_items.includes(
              'plan-liondesk-leadsplus-monthly'
            ) &&
            this.userInfo.subscriptionOverview.details.id_item !==
              'plan-liondesk-leadsplus-monthly'
          }`,
          displayToAgent: true,
        },
        {
          label: 'Leads+: Is New Subscriber',
          value: `${
            this.userInfo.subscriptionOverview.details.id_item ===
            'plan-liondesk-leadsplus-monthly'
          }`,
          displayToAgent: true,
        },
        {
          label: 'Subscription: Plan ID',
          value: `${this.userInfo.subscriptionOverview.details.id_item}`,
          displayToAgent: true,
        },
        {
          label: 'Subscription: User ID',
          value: `${this.userInfo.subscriptionOverview.details.id_user}`,
          displayToAgent: true,
        },
        {
          label: 'Subscription: Purchaser ID',
          value: `${this.userInfo.subscriptionOverview.details.id_user_purchased}`,
          displayToAgent: true,
        },
        {
          label: 'Subscription: Is Subscribed',
          value: `${
            this.userInfo.subscriptionOverview.details.txt_status ===
            'subscribed'
          }`,
          displayToAgent: true,
        },
        // user sms registration info
        {
          label: 'SMS Registration: Not Started',
          value: `${this.userInfo.sms_registration.status == null}`,
          displayToAgent: true,
        },
        {
          label: 'SMS Registration: In Process',
          value: `${
            this.userInfo.sms_registration.status !== null &&
            this.userInfo.sms_registration.status !== 'campaign_completed'
          }`,
          displayToAgent: true,
        },
        {
          label: 'SMS Registration: Has Error/Failure',
          value: `${
            this.userInfo.sms_registration.status !== null &&
            (this.userInfo.sms_registration.status.indexOf('failed') > 0 ||
              this.userInfo.sms_registration.status.indexOf('error') > 0)
          }`,
          displayToAgent: true,
        },
        {
          label: 'SMS Registration: Is Complete',
          value: `${
            this.userInfo.sms_registration.status !== null &&
            this.userInfo.sms_registration.status == 'campaign_completed'
          }`,
          displayToAgent: true,
        },
        {
          label: 'SMS Registration: Attempts',
          value: `${this.userInfo.sms_registration.attempts}`,
          displayToAgent: true,
        },
        {
          label: 'SMS Registration: Error/Failure Msg',
          value: `${this.userInfo.sms_registration.error}`,
          displayToAgent: true,
        },
        ...this.userActiveItemsAsSFExtraPrechatDetails(),
        ...this.userFeaturesAsSFExtraPrechatDetails(),
        {
          label: 'Blocked From Email?',
          value: `${this.userInfo.blocked_from_sending && this.userInfo.blocked_from_sending.email}`,
          displayToAgent: true,
        },
        {
          label: 'Blocked From SMS?',
          value: `${this.userInfo.blocked_from_sending && this.userInfo.blocked_from_sending.sms}`,
          displayToAgent: true,
        },
      ];
    },
    userActiveItemsAsSFExtraPrechatDetails() {
      return this.userInfo.subscriptionOverview.active_items.map(item => {
        return {
          label: `Subscription Active Items: ${item.replaceAll('-', ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}`,
          value: `true`,
          displayToAgent: true,
        };
      });
    },
    userFeaturesAsSFExtraPrechatDetails() {
      if (!this.userInfo.features) return [];

      return Object.keys(this.userInfo.features).map(feature => {
        return {
          label: `User Feature: ${feature.replaceAll('_', ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}`,
          value: `${!!this.userInfo.features[feature].has_access}`,
          displayToAgent: true,
        };
      });
    },
  },
  created() {
    this.initSalesForceChat(window.embedded_gsl_base_url);
  },
  watch: {
    userInfo(value) {
      if (!value) {
        return;
      }

      this.loadEmbedSupportChat();
    },
  },
};
</script>
