/*
 * @Author: Kleber Pinel
 * @Date:   2018-12-14 14:11:59
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-01-21 12:07:04
 */

import { TransactionTypes } from 'api';

// Update the stage list
const updateTransactionTypesWidget = ({ commit }, value) =>
  commit('updateTransactionTypesWidget', value);

const setSelectedTransactionTypeId = ({ commit }, value) =>
  commit('setSelectedTransactionTypeId', value);

// API related actions
const getTransactionTypesWidget = async ({ dispatch }) => {
  const data = await TransactionTypes.getWidget();
  dispatch('updateTransactionTypesWidget', data);
};

const updateLevel = async ({ dispatch, rootState }, updates) => {
  const { newValue, diff } = updates;
  const {
    transactions: { market },
  } = rootState;
  for (const type of diff) {
    const { id, level } = type;
    await TransactionTypes.update(market || 1, id, { level });
  }
  dispatch('updateTransactionTypesWidget', newValue);
};

export default {
  setSelectedTransactionTypeId,
  updateTransactionTypesWidget,
  getTransactionTypesWidget,
  updateLevel,
};
