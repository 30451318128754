export const statusColor = status => {
  let color;
  switch (status) {
    case 'Unconditionally Withdrawn':
      color = '#982E6A';
      break;
    case 'Pending':
      color = '#288C96';
      break;
    case 'Expired':
      color = '#982E6A';
      break;
    case 'Active':
      color = '#FFB253';
      break;
    case 'Sold':
      color = '#288C96';
      break;
    default:
      color = '#FFB253';
  }
  return color;
};

export default {
  methods: {
    statusColor,
  },
};
