<template>
  <TransactionsLayout>
    <EditPlanPresenter
      :plan="plan"
      :getTypeName="getTypeName"
      :addAction="addAction"
      :getKCDName="getKCDName"
      :pagination="pagination"
      :handlePagination="handlePagination"
      :handleDelete="handleDelete"
      :confirmDelete="confirmDeleteTask"
      :confirmationDialog="confirmationDialog"
      :handleEdit="handleEdit"
      :updatePlan="updatePlan"
      :goBack="goBack"
    />
  </TransactionsLayout>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import TransactionsLayout from '@/Core/layouts/TransactionsLayout';
import EditPlanPresenter from './EditPlanPresenter';

const {
  mapState: mapEditState,
  mapActions: mapEditActions,
} = createNamespacedHelpers('editPlans');

const { mapActions: mapPlanActions } = createNamespacedHelpers('plans');

const {
  mapActions: mapTasksActions,
  mapState: mapTasksState,
} = createNamespacedHelpers('planTasks');

const {
  mapState: mapTransactionsState,
  mapActions: mapTransactionsActions,
} = createNamespacedHelpers('transactions');

export default {
  components: {
    TransactionsLayout,
    EditPlanPresenter,
  },
  data() {
    return {
      pagination: {
        page: 1,
        rowsPerPage: 10,
        totalItems: 0,
        sortBy: 'name',
        descending: true,
      },
      confirmationDialog: false,
      taskToDelete: null,
    };
  },
  computed: {
    ...mapEditState(['plan']),
    ...mapTransactionsState(['transactionTypes']),
    ...mapTasksState(['kcds']),
  },
  methods: {
    ...mapPlanActions(['updateType']),
    ...mapEditActions(['reset', 'getPlan', 'deleteTask', 'updatePlan']),
    ...mapTransactionsActions(['getTransactionTypes']),
    ...mapTasksActions(['getTransactionType']),
    getTypeName(id) {
      if (!this.transactionTypes) return;
      const type = this.transactionTypes.find(t => t.id === id);
      if (!type) return;
      return type.name;
    },
    addAction() {
      this.$router.push(`/plans/create/action/${this.plan.id}`);
    },
    getKCDName(obj) {
      if (!this.kcds.length) return;
      const kcd = this.kcds.find(kcd => kcd.meta.referenceId === obj.value);
      if (!kcd) return;
      if (obj.timing === 'on') return kcd.label;
      return `${obj.days} days ${obj.timing} ${kcd.label}`;
    },
    async handlePagination(data) {
      this.pagination = data;
      this.pagination.totalItems = 10;
    },
    handleDelete(id) {
      const toDelete = this.taskToDelete || id;
      this.deleteTask(toDelete);
      this.confirmationDialog = false;
    },
    confirmDeleteTask(id) {
      this.taskToDelete = id;
      this.confirmationDialog = !this.confirmationDialog;
    },
    handleEdit(id) {
      this.$router.push(`/plans/edit/action/${this.plan.id}/${id}`);
    },
    goBack() {
      this.$router.push('/plans');
    },
  },
  async beforeMount() {
    await this.reset();
    const { id } = this.$route.params;
    await this.getPlan(id);
    if (!this.transactionTypes) {
      /**
       * Takes (marketID: number) as a param.
       * TODO: Change this to be dynamic when markets implemented
       */
      await this.getTransactionTypes(1);
    }
    await this.getTransactionType(this.plan.type_id);
    this.updateType(this.plan.type_id);
  },
};
</script>
