import camelCase from 'lodash/camelCase';
import {
  initialStaticConditions,
  minListPriceOptions,
  minListPriceConditions,
  maxListPriceOptions,
  maxListPriceConditions,
  bedroomsTotalOptions,
  bedroomsTotalConditions,
  bathroomsTotalOptions,
  bathroomsTotalConditions,
  homeTypeOptions,
  buildingAreaTotalOptions,
  buildingAreaTotalConditions,
  daysOnMarketOptions,
  daysOnMarketConditions,
  standardStatusOptions,
  standardStatusConditions,
  propertyTypeOptions,
  propertyTypeConditions,
  // listAgentKeyConditions,
} from '../helpers/constants';

export default {
  data() {
    return {
      staticConditions: initialStaticConditions(),
      minListPriceOptions,
      minListPriceConditions,
      maxListPriceOptions,
      maxListPriceConditions,
      bedroomsTotalOptions,
      bedroomsTotalConditions,
      bathroomsTotalOptions,
      bathroomsTotalConditions,
      homeTypeOptions,
      buildingAreaTotalOptions,
      buildingAreaTotalConditions,
      daysOnMarketOptions,
      daysOnMarketConditions,
      standardStatusOptions,
      standardStatusConditions,
      propertyTypeOptions,
      propertyTypeConditions,
      // listAgentKeyConditions,
    };
  },
  methods: {
    searchField(field) {
      const staticField = this.staticConditions[field];
      if (staticField.type === 'array') return staticField.value;
      return this.staticConditions[field] || '';
    },
    onChange(field, value) {
      const staticField = Object.assign({}, this.staticConditions[field]);
      let newValue = value;
      if (staticField.type === 'array') {
        staticField.value = value;
        newValue = staticField;
      }
      this.staticConditions[field] = newValue;
    },
    updateSiblingProps(incomingConditions) {
      this.staticConditions = initialStaticConditions();
      // eslint-disable-next-line
      for (const condition of incomingConditions) {
        const minField = this.minListPriceConditions.field;
        const minOperator = this.minListPriceConditions.operator;
        if (condition.field === minField) {
          const field = condition.operator === minOperator ? 'minListPrice' : 'maxListPrice';
          this.staticConditions[field] = condition;
        } else {
          this.staticConditions[camelCase(condition.field)] = condition;
        }
      }
    },
    sanitizedConditons(conditions = []) {
      const mergedConditons = conditions.concat(Object.values(this.staticConditions));
      return mergedConditons.filter(condition => {
        return condition.value != null;
      });
    },
  },
};
