const addPolygonPath = (state, polygon) => {
  state.polygonPath.push(polygon);
};

const setPolygonPaths = (state, polygon) => {
  state.polygonPath = [...polygon];
};

const clearPolygonPaths = state => {
  state.polygonPath = [];
};

const setDrawPolygonState = (state, drawPolygonsState) => {
  state.drawPolygonsState = drawPolygonsState;
};

export default {
  addPolygonPath,
  setPolygonPaths,
  clearPolygonPaths,
  setDrawPolygonState,
};
