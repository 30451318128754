<!--
  @Author: Ethan Dinnen
  @Date:   2018-12-18 15:43:51
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-02-07 12:49:34
-->
<template>
  <v-flex class="xs12 md4 wizard-container" id="wizard-watch-height">
    <v-form v-model="valid">
      <!--
        <SelectInput
          title="Market"
          :placeholder="currentMarket"
          :options="markets"
          @onInput="handleMarketChange"
          disabled
        />
      -->
      <TextInput
        test-id="tx-title"
        title="Title"
        placeholder="Enter title"
        :entered="title"
        @onInput="updateTitle"
        required
        :rules="titleRules"
      />
      <SelectInput
        test-id="tx-type"
        title="Type"
        placeholder="Select transaction type"
        :selected="type"
        :options="transactionTypes"
        @onInput="updateType"
        required
        :rules="typeRules"
        :disabled="typeLocked"
      />
      <SelectInput
        test-id="tx-stage"
        title="Stage"
        placeholder="Select stage"
        :selected="stage"
        :options="stages"
        @onInput="handleStageChange"
        required
        :rules="stageRules"
        :disabled="stages.length === 0"
      />
    </v-form>
  </v-flex>
</template>

<script>
import TextInput from '@/Core/components/TextInput';
import SelectInput from '@/Core/components/SelectInput';

export default {
  components: {
    TextInput,
    SelectInput,
  },
  props: {
    currentMarket: String,
    market: Number,
    title: String,
    type: Number,
    stage: Number,
    markets: Array,
    handleMarketChange: Function,
    updateTitle: Function,
    titleRules: Array,
    transactionTypes: Array,
    updateType: Function,
    typeRules: Array,
    stages: Array,
    updateStage: Function,
    stageRules: Array,
    validateSetup: Function,
    typeLocked: Boolean,
  },
  data() {
    return {
      valid: false,
      activeStage: this.stage,
    };
  },
  watch: {
    valid: {
      handler(value) {
        this.validateSetup(value);
      },
    },
  },
  methods: {
    handleStageChange(stage) {
      this.updateStage(stage);
      this.$emit('stage-edited',this.activeStage);
    }
  },
};
</script>

<style>
.wizard-container {
  padding-top: 80px;
}
</style>
