<!--
  @Author: Ethan Dinnen
  @Date:   2019-01-03 9:35:45
  @Last Modified by:   ethan
  @Last Modified time: 2019-01-08 10:57:39
-->

<template lang="pug">
  v-card-title.c-title(:class="cssClass", :style="cssStyle")
    span.title
      slot
    .title-hyphen(v-if="$slots['menu']")
    span.menu
      slot(name="menu")
    .inline-badge(v-if="hasBadge") {{badge}}
    span.menu
      slot(name="actions")
    v-spacer
    span
      slot(name="extras")
    .progress(v-show="loading")
      v-progress-linear(:indeterminate="true")
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    dark: {
      type: Boolean,
    },
    color: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
    badge: {
      default: null,
      type: [String, Number],
    },
  },
  computed: {
    cssClass() {
      if (this.dark) {
        return `dark ${this.backgroundColor} white--text`;
      }
      return '';
    },
    backgroundColor() {
      if (this.dark) {
        return this.color || this.$vuetify.theme.primary;
      }
      return 'transparent';
    },
    cssColor() {
      if (this.dark) {
        return 'rgba(255,255,255,0.5)';
      }
      return this.color || this.$vuetify.theme.primary;
    },
    cssStyle() {
      return `--tag-color:${this.cssColor}; background-color:${
        this.backgroundColor
      }`;
    },
    hasBadge() {
      // numeric badge with value of 0 still must be shown
      return String(this.badge).length > 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.c-title {
  padding: 6px 12px 6px 52px;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  min-height: 60px;
  width: 100%;
  &:before {
    width: 16px;
    height: 16px;
    position: absolute;
    content: '';
    margin: 0 -28px;
    border-radius: 2px;
    background: var(--tag-color);
  }
  .title {
    padding-right: 12px;
  }
  .title-hyphen {
    width: 8px;
    background: var(--v-text-lighten3);
    opacity: 0.5;
    height: 2px;
    margin: 0 12px 0 0;
  }
  &.dark {
    .title-hyphen {
      background: white;
    }
  }
  .menu {
    opacity: 0.5;
    .v-menu {
      vertical-align: inherit;
    }
  }
  .inline-badge {
    margin: 0 5px;
    color: var(--v-text-lighten3);
    background: var(--v-background-lighten3);
    border-radius: 4px;
    padding: 0 6px;
    text-align: center;
    font-size: 16px;
  }
  &.dark {
    .inline-badge {
      color: white;
      background-color: var(--tag-color);
    }
  }
}
@media only screen and (max-width: 959px) {
  .c-title {
    padding: 6px 6px 6px 32px;
  }
}
</style>
