<template>
  <div class="condition">
    <v-select
      class="filter__operator"
      :items="operators"
      item-text="name"
      v-model="selectedOperator"
      return-object="return-object"
    ></v-select>
    <v-text-field
      class="filter__value text-xs-right"
      v-model="selected.value"
      v-if="selectedOperator &amp;&amp; selectedOperator.needValue"
      counter="counter"
    ></v-text-field>
  </div>
</template>

<script>
import setSelectedOperator from '../../mixins/setSelectedOperator';

export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedOperator: null,
      selected: {
        operator: null,
        value: null,
      },
      operators: [
        {
          name: 'is zero',
          operator: '$eq',
          value: 0,
          needValue: false,
        },
        {
          name: 'is one',
          operator: '$eq',
          value: 1,
          needValue: false,
        },
        { name: 'is equal to', operator: '$eq', needValue: true },
        { name: 'is not equal to', operator: '$ne', needValue: true },
        { name: 'is less than', operator: '$lt', needValue: true },
        { name: 'is greater than', operator: '$gt', needValue: true },
        { name: 'is less than or equal to', operator: '$lte', needValue: true },
        { name: 'is greater than or equal to', operator: '$gte', needValue: true },
      ],
    };
  },
  mixins: [setSelectedOperator],
  mounted() {
    this.selected = Object.assign({}, this.value);
    this.setSelectedOperator();

    this.$watch(
      'selectedOperator',
      selectedOperator => {
        this.$emit('input', {
          operator: selectedOperator.operator,
          value: selectedOperator.needValue ? this.selected.value : selectedOperator.value,
        });
      },
      { deep: true },
    );

    this.$watch(
      'selected',
      selected => {
        this.$emit('input', {
          operator: this.selectedOperator.operator,
          value: selected.value,
        });
      },
      { deep: true },
    );
  },
};
</script>
