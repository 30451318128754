<template>
  <div>
    <v-select
      class="filter__operator"
      :items="operators"
      item-text="name"
      item-value="value"
      v-model="selectedOperator"
      return-object="return-object"
    ></v-select>
    <v-select
      class="filter__value"
      :items="items"
      item-text="name"
      v-if="selectedOperator &amp;&amp; selectedOperator.needValue"
      v-model="selected.value"
      placeholder="Select..."
      :multiple="selectedOperator &amp;&amp; selectedOperator.multiple"
    >
      <template slot="selection" slot-scope="{ item, index }">
        <v-chip v-if="index === 0">
          <span>{{ item.name }}</span>
        </v-chip>
        <span
          class="grey--text caption"
          v-if="index === 1"
        >(+{{ selected.value.length - 1 }} others)</span>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedOperator: null,
      selected: {
        operator: null,
        value: null,
      },
      operators: [
        {
          name: 'is', operator: '$eq', needValue: true, multiple: false,
        },
        {
          name: 'is not', operator: '$ne', needValue: true, multiple: false,
        },
        {
          name: 'is any of', operator: '$in', needValue: true, multiple: true,
        },
        {
          name: 'is none of', operator: '$nin', needValue: true, multiple: true,
        },
        {
          name: 'is empty', operator: '$eq', value: 0, needValue: false, multiple: false,
        },
        {
          name: 'is not empty', operator: '$gt', value: 1, needValue: false, multiple: false,
        },
      ],
      items: [
        { name: 'One', value: 1 },
        { name: 'Two', value: 2 },
        { name: 'Three', value: 3 },
        { name: 'Four', value: 4 },
        { name: 'Five', value: 5 },
        { name: 'Six', value: 6 },
        { name: 'Seven', value: 7 },
        { name: 'Eight', value: 8 },
        { name: 'Nine', value: 9 },
        { name: 'Ten', value: 10 },
      ],
    };
  },
  watch: {
    selected: {
      deep: true,
      handler(value) {
        this.$emit('input', value);
      },
    },
  },
  mounted() {
    this.selected = this.value;
    this.$watch('selectedOperator', value => {
      this.selected = {
        operator: value.operator,
        value: typeof value.value === 'number' ? value.value : null,
      };
    });
  },
};
</script>

<style lang="scss" type="text/scss">
.foreign-key-filter {
  flex: 1 1;
  display: inline-flex;
  white-space: nowrap;

  .v-select__selections {
    flex-wrap: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
