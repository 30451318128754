<template>
  <div class="condition">
    <v-select
      class="filter__condition filter__operator"
      :items="operators"
      item-text="name"
      v-model="selectedOperator"
      @input="changedOperator"
      return-object="return-object"
    ></v-select>
    <v-menu
      class="filter__value"
      :close-on-content-click="false"
      v-model="isDatePickerMenuShown"
      :nudge-right="40"
      transition="scale-transition"
      lazy="lazy"
      offset-y="offset-y"
      full-width="full-width"
      v-if="selectedOperator &amp;&amp; selectedOperator.needDateValue"
    >
      <v-text-field
        slot="activator"
        v-model="selectedValue"
        prepend-icon="event"
        readonly="readonly"
      ></v-text-field>
      <v-date-picker
        no-title="no-title"
        scrollable="scrollable"
        :show-current="selectedValue || true"
        v-model="selectedValue"
        @input="isDatePickerMenuShown = false"
      ></v-date-picker>
    </v-menu>
    <v-text-field
      class="filter__value"
      type="number"
      v-model="selectedValue"
      v-if="selectedOperator &amp;&amp; selectedOperator.needIntegerValue"
      counter="counter"
    ></v-text-field>
  </div>
</template>

<script>
import dateFns from 'date-fns';
import { Operators } from '../../helpers/dateConditions';

const defaultOperator = {
  name: Operators.TODAY,
  isRelative: true,
};

const defaultSelectedValue = null;
const dateFormat = 'YYYY-MM-DD';
const defaultRelativeDays = 7;
const defaultCalendarDate = dateFns.format(new Date(), dateFormat);

export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      /**
       * The operator from the list that's been selected
       * @type {Object}
       */
      selectedOperator: null,
      /**
       * The selected condition (operand and value)
       * @type {Object}
       */
      selectedValue: null,

      isDatePickerMenuShown: false,

      /**
       * Operators may have the following keys...
       *
       * name:       the human readable name
       * isRelative: requires recalculation with respect to current date
       * needIntegerValue:  e.g. within X days where X is some integer value
       * needDateValue:   e.g. Occurred before [date]
       *                  where [date] is like '2018-01-01'
       * @type {Array}
       */
      operators: [
        {
          name: Operators.WITHIN_LAST_X_DAYS,
          isRelative: true,
          needIntegerValue: true,
        },
        {
          name: Operators.YESTERDAY,
          isRelative: true,
        },
        defaultOperator,
        {
          name: Operators.TOMORROW,
          isRelative: true,
        },
        {
          name: Operators.WITHIN_NEXT_X_DAYS,
          isRelative: true,
          needIntegerValue: true,
        },
        {
          name: Operators.OCCURRED_BEFORE,
          isRelative: false,
          needDateValue: true,
        },
        {
          name: Operators.OCCURRED_ON,
          isRelative: false,
          needDateValue: true,
        },
        {
          name: Operators.OCCURRED_AFTER,
          isRelative: false,
          needDateValue: true,
        },
        {
          name: Operators.NEVER_OCCURRED,
          isRelative: false,
        },
      ],
    };
  },

  computed: {
    /**
     * Check if the current value is a date-formatted string
     * like '2018-06-15'
     *
     * @return {Boolean}
     */
    isSelectedValueDate() {
      const dateRegex = /\d{4}-\d{2}-\d{2}/g;
      return dateRegex.test(this.selectedValue);
    },

    /**
     * If it is a number, NaN will fail.
     *
     * Flip the boolean to check if it _is_ a number
     *
     * @return {Boolean}
     */
    isSelectedValueInteger() {
      const integerRegex = /^\d+$/g;
      return integerRegex.test(this.selectedValue);
    },
  },

  methods: {
    /**
     * The operator has been changed; update/clear the value as necessary
     * @param  {} oeprator
     * @return {}
     */
    changedOperator(operator) {
      // if needs a date and value isn't a date, set to today
      if (operator.needDateValue && !this.isSelectedValueDate) {
        this.selectedValue = defaultCalendarDate;
      } else if (operator.needIntegerValue && !this.isSelectedValueInteger) {
        this.selectedValue = defaultRelativeDays;
      } else if (!operator.needDateValue && !operator.needIntegerValue) {
        this.selectedValue = defaultSelectedValue;
      }
    },

    /**
     * Check if the requested operator is relative.
     *
     * @param  {}  operatorName
     * @return {Boolean}
     */
    isRelative(operatorName) {
      const operators = this.operators.filter(operator => operator.name === operatorName);
      if (operators.length === 1) {
        return operators[0].isRelative || false;
      }
    },

    /**
     * Set the selected operator depending on what's been passed in as selected
     */
    setSelectedOperator() {
      this.selectedOperator = this.operators.filter(operator => {
        return operator.name === this.value.operator;
      });
      if (this.selectedOperator.length) this.selectedOperator = this.selectedOperator[0];
    },

    /**
     * Check if any value is set
     * @return {Boolean}
     */
    noValueGiven() {
      return !this.selectedValue;
    },

    /**
     * Wrapper to emit condition operator & value
     */
    emitSelected() {
      this.$emit('input', {
        operator: this.selectedOperator.name,
        value: this.selectedValue,
      });
    },
  },
  mounted() {
    this.selectedValue = this.value.value;
    this.setSelectedOperator();

    if (this.noValueGiven() && this.isRelative(this.selectedOperator)) {
      this.selectedValue = defaultRelativeDays;
    } else if (this.noValueGiven() && !this.isRelative(this.selectedOperator)) {
      this.selectedValue = defaultCalendarDate;
    }

    this.$watch('selectedOperator', operator => {
      this.emitSelected();
    });

    this.$watch('selectedValue', selectedValue => {
      this.emitSelected();
    });
  },
};
</script>
