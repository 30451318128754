<template>
  <v-layout justify-center align-center class="ld-persistent-banner" :style="{ 'background-color': color.background, height: '30px' }">
    <v-icon class="px-2" medium :color="color.foreground" v-if="warningIcon">error_outline</v-icon>
    <span v-html="text"></span>
    <v-btn flat :href="link" v-if="link" :style="{ 'color': color.foreground }">
      {{ buttonText }}
    </v-btn>
    <v-btn v-if="!link && !complete" small flat dark :color="color.foreground" :loading="loading" :disabled="loading" @click.prevent="$emit('input')">
      {{ buttonText }}
    </v-btn>
    <v-icon class="px-2" small :color="color.foreground" v-if="complete">check_circle</v-icon>
  </v-layout>
</template>

<script>
export default {
  props: {
    warningIcon: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      default: 'SUBSCRIBE NOW',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    complete: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    color() {
      return {
        foreground: this.error ? '#A64951' : '#3878C0',
        background: this.error ? '#F1D4D7' : '#DAE2ED',
      }
    },
  },
};
</script>

<style lang="scss">
$banner-font-size: 14px;
.ld-persistent-banner {
  line-height: 1;
  height: 30px; /* match default toolbar extension slot height */
  color: var(--v-text-base);
  font-size: $banner-font-size;
  width: 100%;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(90,90,90,0.1);
  }
  .v-btn {
    height: 24px;
    margin: 0 8px;
    font-size: $banner-font-size;
  }
}
</style>
