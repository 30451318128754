<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1" x="0px" y="0px"
        viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve">
        <g>
            <polygon class="st0" points="20.5,153.1 20.5,380 156.9,266.5  "></polygon>
            <path class="st0"
                d="M443.3,82.5H56.7C38.9,82.5,24,95.5,21,112.5l180.3,150l30.1,25c10.2,8.5,26.9,8.5,37.1,0l31.6-26.3L479,112.5  C476,95.5,461.1,82.5,443.3,82.5z">
            </path>
            <polygon class="st0" points="479.5,380 479.5,153.1 343.1,266.5  "></polygon>
            <path class="st0"
                d="M293,308.2l-24.4,20.3c-10.2,8.5-26.9,8.5-37.1,0l-31.5-26.2L181.5,287L34.1,409.6c6.2,5,14.1,8,22.6,8h386.5  c8.5,0,16.4-3,22.6-8L318.4,287L293,308.2z">
            </path>
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            default: '$vuetify.icons.other_mail',
        },
    },
};
</script>