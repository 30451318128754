/*
 * @Author: Ethan Dinnen
 * @Date:   2019-01-10 11:05:19
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-02-07 12:26:21
 */

import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import { CustomIcons } from 'lion-vue';

Vue.use(Vuetify, {
  iconfont: 'md',
  theme: {
    primary: '#235EA1',
    'primary-dark': '#285B96',
    'lion-grey': '#686868',
    'lion-light-grey': '#EBEBEB',
    secondary: '#424242',
    accent: '#3878C0',
    error: '#FF5252',
    info: '#8B8B8B',
    success: '#4CAF50',
    warning: '#FFC107',
    text: '#333333',
    background: '#7F7F7F',
  },
  options: {
    customProperties: true,
  },
  icons: {
    ...CustomIcons,
  },
});
