export default () => ({
  listings: [],
  total: 0,
  listing: {
    _id: null,
  },
  isLoading: false,
  selected: [],
  sortSelected: {
    name: 'Recently Updated',
    sort: 'desc',
    optionIcon: 'arrow_downward',
    key: 'mod-desc',
  },
  showingTime: [],
  findCaller: '',
});
