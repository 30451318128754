/*
 * @Author: Ethan Dinnen
 * @Date:   2019-02-07 9:55:09
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-02-20 13:07:24
 */

const forms = {
  FINANCIALS: 'Financials',
  PROPERTY_ADDRESS: 'Property Info',
  KEY_CONTRACT_DATES: 'Key Contract Dates',
};

const fields = {
  PURCHASE_SALES: 'Purchase/Sale Price',
  COMMISSION_PERCENTAGE: 'Commission Percentage',
  ADD_FIXED_COMMISSION: 'Add Fixed Commission',
  SALE_COMMISSION_TOTAL: 'Sale Commission Total',
};

export default {
  ...forms,
  ...fields,
};
