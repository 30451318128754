import { createSubscriptionAddon } from '../../../../../api/subscriptionApi';

const toggleFeatureGate = async ({ commit }, value) => {
  if (value) {
    commit('setFeatureGate', value.feature);
    commit('setMeteredUsageKey', value.meteredUsageKey);
  } else {
    commit('setFeatureGate', null);
    commit('setMeteredUsageKey', null);
    commit('setAddonPurchaseResult', null);
    commit('setFeatureSubType', null);
  }
};

const acceptMeteredUsage = async ({ commit }, value) => {
  commit('setMeteredUsageAcceptedKey', value);
};

const completeAddonPurchase = async ({ commit }, value) => {
  commit('setAddonPurchaseResult', value);
};

const toggleAddonPurchaseRequested = ({ commit }, value) => {
  commit('setAddonPurchaseRequested', value);
};

const toggleFeatureSubType = ({ commit }, value) => {
  commit('setFeatureSubType', value);
};

export default {
  toggleFeatureGate,
  acceptMeteredUsage,
  createSubscriptionAddon,
  completeAddonPurchase,
  toggleAddonPurchaseRequested,
  toggleFeatureSubType,
};
