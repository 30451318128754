<template>
  <v-container fluid search-container>
    <v-layout row v-if="mlsBoardNavigation">
      <slot name="mlsBoardIdentifier"></slot>
      <slot name="mlsConectBoard"></slot>
    </v-layout>
    <v-layout row wrap>
      <v-flex :class="[advancedSearch ? 'xs10' : 'xs12']" id="search-bar-flex">
        <v-autocomplete
          light
          solo
          no-filter
          v-model="searchValue"
          item-text="searchText"
          auto-select-first
          append-icon="clear"
          hide-no-data
          prepend-inner-icon="search"
          browser-autocomplete="new-password"
          :items="searchSuggestions"
          :label="searchPlaceholder"
          :search-input.sync="search"
          :item-value="searchItemType"
          @click:prepend-inner="onPrepend"
          @change="onChange($event)"
          @click:append="onAppendClick"
        >
          <template slot="item" slot-scope="{ item }" id="search-bar-items">
            <v-list-tile-content>
              <v-list-tile-title v-html="item.searchText"></v-list-tile-title>
              <v-list-tile-sub-title v-html="searchTypesAliases[item.searchType]">
              </v-list-tile-sub-title>
            </v-list-tile-content>
          </template>
        </v-autocomplete>
      </v-flex>
      <v-flex xs2 v-if="advancedSearch" id="search-bar-filter">
        <div
          class="v-input v-text-field v-text-field--single-line
            v-text-field--solo v-text-field--solo v-text-field--enclosed
            v-select v-autocomplete theme--light"
        >
          <div class="v-input__control filter-control">
            <div class="v-input__slot filter-slot">
              <div @click="toggleSlideOut" class="show-filters">
                <v-icon>filter_list</v-icon>
                <span>Filters {{ filterApplied ? '*' : '' }}</span>
              </div>
              <ld-slide-out v-model="showSlideOut" slideFromRight @toggle="toggleSlideOut">
                <template slot="component">
                  <slot name="advancedSearch"></slot>
                </template>
              </ld-slide-out>
            </div>
          </div>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { toggleSlideOut } from 'lion-vue-src/_globals/mixins';
import LdSlideOut from 'lion-vue-src/_globals/lib/LdSlideOut';
import { searchTypesAliases } from '../helpers/constants';
import { debounce } from '../../utilities/debounce';

const emptySearchValue = {
  searchText: '',
  searchType: '',
  coordinates: [],
}

export default {
  components: { LdSlideOut },
  props: {
    searchPlaceholder: {
      type: String,
      default: 'Search Listings and Saved Areas',
    },
    advancedSearch: {
      type: Boolean,
      default: false,
    },
    mlsBoardNavigation: {
      type: Boolean,
      default: false,
    },
    analytics: {
      type: Function,
      default: () => {},
    },
    handleToggleFromParent : {
      type: Boolean,
      default: null,
    },
    filterApplied: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      searchValue: emptySearchValue,
      analyticsTimeout: null,
      searchTypesAliases,
      searchTimeout: null,
    };
  },
  computed: {
    ...mapState('search', ['searchText', 'searchType', 'searchSuggestions']),
  },
  watch: {
    search(value) {
      if (this.analyticsTimeout) clearTimeout(this.analyticsTimeout);
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      this.searchTimeout = debounce(this.updateSearchSuggestions, [value]);
      this.analyticsTimeout = setTimeout(() => {
        this.analytics('MLS Search', value, 'updateSearchSuggestions');
      }, 1000);
    },
    // only triggered on first load.
    // when user is being redirected from crm to vmi to
    // create save search filter.
    handleToggleFromParent(newState, oldState) {
      if (!oldState && newState) {
        this.toggleSlideOut(true);
      }
    }
  },
  mounted() {
    this.searchValue = {
      searchText: this.searchText,
      searchType: this.searchType
    };
  },
  mixins: [toggleSlideOut],
  methods: {
    ...mapActions({
      updateSelectedSearch: 'search/updateSelectedSearch',
      updateSearchSuggestions: 'search/updateSearchSuggestions',
      updateMapCenter: 'search/updateMapCenter',
      find: 'listing/find',
      setPolygonPaths: 'polygon/setPolygonPaths',
      setDrawPolygonState: 'polygon/setDrawPolygonState',
    }),
    onChange(value) {
      if (value && value.searchType === "address" && value.id) {
        // notify parent to navigate directly to
        // listing page for given `id`
        this.$emit('click:address', value.id);
        return;
      }

      if (value && value.searchType === "polygon") {
        this.setDrawPolygonState(true);
        // Center the map on the first co-ordinate in the polygon
        // TODO MW - center on the actual center of the polygon
        this.updateMapCenter({
          lng: () => value.coordinates[0][0],
          lat: () => value.coordinates[0][1],
        });
        this.setPolygonPaths(value.coordinates.map(point => {
          return { lng: () => point[0], lat: () => point[1] };
        }));
      }

      this.updateSelectedSearch(value || defaultValue);
      this.find({caller: "search_on_change"});
    },
    searchItemType: item => ({
      searchText: item.searchText,
      searchType: item.searchType,
      coordinates: item.coordinates || [],
      conditions: item.conditions || [],
      id: item.id,
    }),
    onPrepend() {
      const selectedSearch = document.querySelector('.v-list__tile--highlighted');
      if (selectedSearch) {
        selectedSearch.click();
      }
    },
    onAppendClick(a) {
        this.search = '';
        this.searchValue = emptySearchValue;
        this.updateSelectedSearch(this.searchValue);
        this.find({caller: "search_on_append_click"});
    },
  },
};
</script>
<style lang="scss">
.search-container {
  padding-bottom: 0px !important;
  height: 85px;
}
.show-filters {
  display: flex;
  cursor: pointer;
  color: var(--v-primary-base);
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  @media all and (max-width: 1250px) {
    i {
      position: absolute;
      right: 5px;
      top: 11px;
    }
    span {
      display: none;
    }
  }
  i {
    margin-right: 8px;
    color: var(--v-primary-base) !important;
  }
  span {
    position: relative;
    top: 2px;
    text-transform: uppercase;
  }
}
#search-bar-items {
  z-index: 11;
}
#search-bar-filter {
  padding-right: 2px;
  padding-top: 2px;
  position: relative;
  left: -2px;
  .filter-control {
    min-height: 43px;
  }
  .filter-slot {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow: 0 2px 4px 0 var(--v-background-lighten4);
    border: 0.5px solid var(--v-background-lighten3);
  }
}
#search-bar-flex {
  z-index: 11;
  overflow: hidden;
  padding-top: 2px;
  .v-autocomplete {
    .v-input__control {
      min-height: 43px !important;
    }
    .v-input__slot {
      padding-left: 0;
      box-shadow: 0 2px 4px 0 var(--v-background-lighten4);
      border: 0.5px solid var(--v-background-lighten3);
      border-right: unset;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-radius: 3px;
      .v-input__prepend-inner {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        cursor: pointer;
        background-color: var(--v-primary-base);
        padding: 8.5px;
        margin-right: 13px;
        i {
          color: white !important;
        }
      }
    }
  }
}
</style>
