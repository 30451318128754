<template>
<svg 
  data-icon="create-own"
  role="img"
  viewBox="0 0 85 78"
>
<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="85" height="62">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.000259399H84.9652V61.0714H0V0.000259399Z" fill="white"/>
</mask>
<g mask="url(#mask0)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M82.3101 57.0886C82.3101 57.8214 81.714 58.4162 80.9825 58.4162H3.98249C3.25099 58.4162 2.65491 57.8214 2.65491 57.0886V3.98514C2.65491 3.25364 3.25099 2.65755 3.98249 2.65755H34.3484V2.65888H37.5571V2.65755H80.9825C81.714 2.65755 82.3101 3.25364 82.3101 3.98514V57.0886ZM80.9825 0.00238051H37.5571V-0.000274658H34.3484V0.00238051H3.98249C1.78667 0.00238051 -0.000265598 1.78931 -0.000265598 3.98514V57.0886C-0.000265598 59.2844 1.78667 61.0713 3.98249 61.0713H80.9825C83.1783 61.0713 84.9652 59.2844 84.9652 57.0886V3.98514C84.9652 1.78931 83.1783 0.00238051 80.9825 0.00238051Z" fill="#3E6491"/>
</g>
<path fill-rule="evenodd" clip-rule="evenodd" d="M58.4135 77.0024H26.5515C25.8186 77.0024 25.2239 76.409 25.2239 75.6748V70.3645C25.2239 69.7551 25.6394 69.2254 26.2289 69.0767L30.5342 68.0014V59.7438H33.1894V69.0369C33.1894 69.6462 32.7752 70.1773 32.1844 70.326L27.879 71.4013V74.3472H57.0859V71.4013L52.7806 70.326C52.1911 70.1773 51.7756 69.6462 51.7756 69.0369V59.7438H54.4308V68.0014L58.7361 69.0767C59.3256 69.2254 59.7411 69.7551 59.7411 70.3645V75.6748C59.7411 76.409 59.1464 77.0024 58.4135 77.0024Z" fill="#3E6491"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.2271 8.71401V44.7819L62.0547 44.7805V8.71401H22.2271ZM62.0547 47.4357H22.2271C20.7628 47.4357 19.5719 46.2449 19.5719 44.7805V8.71401C19.5719 7.24968 20.7628 6.05884 22.2271 6.05884H62.0547C63.519 6.05884 64.7099 7.24968 64.7099 8.71401V44.7805C64.7099 46.2449 63.519 47.4357 62.0547 47.4357Z" fill="#3E6491"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M53.273 39.5857H51.8352V24.8548L42.4863 18.177L33.1375 24.8548V39.5857H31.6997V24.4844C31.6997 24.2521 31.8112 24.0343 32.0011 23.8989L42.0682 16.7087C42.3191 16.5295 42.6536 16.5295 42.9032 16.7087L52.9716 23.8989C53.1601 24.0343 53.273 24.2521 53.273 24.4844V39.5857Z" fill="#3C6697"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M57.5876 25.2038H52.5534V23.766H55.344L42.4863 14.5818L29.6287 23.766H32.4193V25.2038H27.3851C27.0731 25.2038 26.7956 25.002 26.7 24.7046C26.6044 24.4059 26.7133 24.0807 26.9669 23.8988L42.0682 13.1122C42.3191 12.9343 42.6536 12.9343 42.9032 13.1122L58.0045 23.8988C58.2594 24.0807 58.3669 24.4059 58.2727 24.7046C58.1771 25.002 57.8996 25.2038 57.5876 25.2038Z" fill="#3C6697"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M53.992 21.6081H52.5542V15.8556H49.6773V18.013H48.2396V15.1361C48.2396 14.7391 48.5608 14.4165 48.9578 14.4165H53.2724C53.6707 14.4165 53.992 14.7391 53.992 15.1361V21.6081Z" fill="#3C6697"/>
<mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="85" height="78">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 77.0029H84.9655V0.000259399H0V77.0029Z" fill="white"/>
</mask>
<g mask="url(#mask1)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M28.1037 40.3058H56.8685V38.868H28.1037V40.3058Z" fill="#3C6697"/>
</g>
</svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '$vuetify.icons.create-own',
    },
  },
};
</script>
