<!--
                @Author: Ethan Dinnen
                @Date:   2018-12-18 15:00:57
  @Last modified by:   hugodorea
  @Last modified time: 2019-03-28T13:14:26-07:00
-->
<template>
  <FlowLayout
    :currentStage="currentStage"
    v-if="!submitLoading"
    id="create-wizard-layout"
  >
    <template slot="header">
      <v-btn flat="flat" @click="goBack()" class="back-button">
        <v-icon>keyboard_arrow_left</v-icon> Back
      </v-btn>
    </template>
    <template>
      <v-layout class="pt-5" justify-center="justify-center">
        <TransactionSummary
          v-model="currentStage"
          :summaryValues="summaryValues"
          :editDisabled="disableEdits"
          summaryTitle="Add a New Transaction"
        />
        <TransactionSetup
          class="tx-create-wizard-input-modify"
          v-if="currentStage === 'transaction-setup'"
          :market="market"
          :title="title"
          :type="type"
          :stage="stage"
          :markets="marketOptions"
          :currentMarket="currentMarket"
          :handleMarketChange="getTransactionTypes"
          :updateTitle="updateTitle"
          :transactionTypes="typeOptions"
          :updateType="updateType"
          :updateStage="updateStage"
          :stages="stageOptions"
          :marketRules="marketRules"
          :titleRules="titleRules"
          :typeRules="typeRules"
          :stageRules="stageRules"
          :validateSetup="validateSetup"
        />
        <!--
          DO NOT REMOVE THIS COMMENTED OUT CODE
          This section is being omited for now and will come Back
          later when the MLS scope is done
        -->
        <!--
          <TransactionProperty
            v-if="currentStage === 'transaction-property'"
            :handlePropertyChoosen="updateMlsProperty"
            :mlsCurrentPage="mlsCurrentPage"
            :mlsNumberSelected="mlsNumberSelected"
          />
        -->
        <!-- eslint-disable -->
        <TransactionForm
          class="tx-create-wizard-input-modify"
          v-for="(template, index) in templates"
          v-if="currentStage === `transaction-${index}`"
          :key="`transaction-${index}`"
          :template="template"
          :market="market"
          :type="type"
          :property="property"
          :updateSummary="handleFormSummary"
          :reAssignSummaryValues="reAssignSummaryValues"
        />
        <!-- eslint-enable -->
        <TransactionContacts
          class="tx-create-wizard-input-modify"
          v-if="currentStage === 'transaction-contacts'"
          :searchContacts="searchTeammateSelfContacts"
          :addContact="addContact"
          :addNewContact="addNewContact"
          :contacts="contacts"
          :selectedContacts="allContacts"
          :removeContact="handleRemoveContact"
          :getRoles="getRoles"
          :roles="roleOptions"
          :role="role"
          :setRole="setRole"
        />
        <!--
          <TransactionCampaign
            class="tx-create-wizard-input-modify"
            v-if="currentStage === 'transaction-campaign'"
            :key="`campaign-${this.transactionCampaignSummary.data.length}`"
            :campaigns="campaignOptions"
            :handleCampaignSelect="handleCampaignSelect"
            :selectedContacts="selectedContactsOptions"
            :handleCampaignContact="handleCampaignContact"
            :roleOptions="selectedContactRoles"
            :disableRoles="disableRoles"
            :disableCampaigns="disableCampaigns"
            :startCampaignDisabled="startCampaignDisabled"
            :handleStartCampaign="handleStartCampaign"
            :setDone="setCampaignsDone"
          />
        -->
        <TransactionCreationLoop
          v-if="currentStage === 'transaction-creation-loop'"
          :handleAnother="handleAnother"
          :handleFinish="handleFinish"
        />
      </v-layout>
    </template>
    <template slot="footer">
      <div>
        <PrevNextStepper
          v-if="currentStage !== 'transaction-creation-loop'"
          :items="summaryValues"
          :submitDisabled="false"
          :submitLoading="false"
          :prevDisabled="prevDisabled"
          :nextDisabled="nextDisabled"
          :nextAsSubmit="isLastStep"
          v-on:submit-clicked="handleFinishSteps"
          v-model="currentStage"
          objectKey="key"
          :hasSubmit="false"
        />
      </div>
    </template>
  </FlowLayout>
  <v-layout v-else> <BreedingRhombusSpinner /> </v-layout>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import FlowLayout from '@/Core/layouts/FlowLayout';
import TransactionSummary from './components/CreateTransactionSummary';
import TransactionSetup from './components/CreateTransactionSetup';
/** DO NOT REMOVE THIS COMMENTED OUT CODE
 * This section is being omited for now and will come Back
 * later when the MLS scope is done
 * **/
// import TransactionProperty from './components/CreateTransactionProperty';
// import TransactionCampaign from './components/CreateTransactionCampaign';
import TransactionForm from './components/CreateTransactionForm';
import TransactionContacts from './components/CreateTransactionContacts';
import TransactionCreationLoop from './components/CreateTransactionLoop';
import PrevNextStepper from '@/Core/components/PrevNextStepper';
import formatCurrency from '@/Core/mixins/formatCurrency';
import BreedingRhombusSpinner from '@/Core/components/BreedingRhombusSpinner';

import initialValues from './components/wizard/InitialValues';
import computed from './components/wizard/Computed';
import methods from './components/wizard/Methods';
import watch from './components/wizard/Watch';

const {
  mapState: mapTransactionsState,
  mapActions: mapTransactionsActions,
} = createNamespacedHelpers('transactions');

const {
  mapState: mapAppState,
} = createNamespacedHelpers('ldAppLayout');

export default {
  components: {
    FlowLayout,
    TransactionSummary,
    TransactionSetup,
    /** DO NOT REMOVE THIS COMMENTED OUT CODE
     * This section is being omited for now and will come Back
     * later when the MLS scope is done
     * **/
    // TransactionProperty,
    // TransactionCampaign,
    TransactionForm,
    TransactionContacts,
    TransactionCreationLoop,
    PrevNextStepper,
    BreedingRhombusSpinner,
  },
  mixins: [formatCurrency],
  data() {
    return { ...initialValues };
  },
  computed: {
    ...computed,
    ...mapTransactionsState([
      'title',
      'type',
      'stage',
      'property',
      'market',
      'markets',
      'transactionTypes',
      'stages',
      'contacts',
      'selectedContacts',
      'setupValid',
      'roles',
      'role',
      'newContacts',
      'campaigns',
      'validContactInfo',
      'templates',
      'formSummaries',
      'transactionsCreated',
    ]),
    ...mapAppState(['userInfo']),
  },
  methods: {
    ...methods,
    ...mapTransactionsActions([
      'reset',
      'createReset',
      'resetFinancials',
      'goBack',
      'setFormSummaries',
      'getTransactionTypes',
      'getMarkets',
      'getStages',
      'createTransactions',
      'updateTitle',
      'updateType',
      'updateStage',
      'updateProperty',
      'updateMarket',
      'searchContacts',
      'searchTeammateSelfContacts',
      'addContact',
      'removeContact',
      'validateSetup',
      'getRoles',
      'setRole',
      'addNewContact',
      'getCampaigns',
      'assignCampaign',
      'saveTransaction',
      'getTemplates',
      'updateFinancialsData',
      'resetFinancialsData',
      'setFormDone',
      'setForms',
      'resetStages',
    ]),
  },
  watch: {
    ...watch,
  },
  // Reset everything and retrieve select menu options
  async beforeMount() {
    this.submitLoading = false;
    this.currentStage = 'transaction-setup';
    this.reset();
    this.resetSummaries();
    this.resetFinancials();
    await this.getMarkets();
    this.marketOptions = this.selectFormat(this.markets);
    await this.getTransactionTypes(this.markets[0].id);
    this.typeOptions = this.selectFormat(
      [...this.transactionTypes].sort((a, b) => a.level - b.level)
    );
    await this.getRoles();
    this.roleOptions = this.selectFormat(this.roles);
    await this.getCampaigns();
    this.campaignOptions = this.selectFormat(this.campaigns);
  },
  // Initialize steps
  mounted() {
    this.reAssignSummaryValues();
  },
  beforeDestroy() {
    this.reset();
  },
};
</script>

<style lang="scss">
.back-button {
  color: rgba(0, 0, 0, 0.6) !important;
  .text {
    font-size: 12px;
    font-weight: 400;
  }
  i {
    font-size: 19px;
  }
}

.breeding-rhombus-spinner {
  margin: auto;
  height: 65px;
  width: 65px;
  position: relative;
  transform: rotate(45deg);
}
.tx-create-wizard-input-modify {
  .v-text-field__slot {
    label {
      color: var(--v-primary-dark-base) !important;
    }
  }
  .v-select__slot {
    label {
      color: var(--v-primary-dark-base) !important;
    }
  }
  .v-input__slot::before {
    border-color: var(--v-primary-dark-base) !important;
  }
  .v-input--checkbox {
    .accent--text {
      color: var(--v-primary-dark-base) !important;
      caret-color: var(--v-primary-dark-base) !important;
    }
  }
}
#create-wizard-layout {
  #flow-footer {
    flex-direction: row-reverse;
  }
}
</style>
