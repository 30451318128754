/*
 * @Author: Ethan Dinnen
 * @Date:   2019-01-08 17:15:02
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-01-08 17:17:08
 */

/**
 * Recursively find all the values for a specified key
 * @param obj {object} The object to recurse over
 * @param filter {string} The key to look for
 * @returns {array} An array of all the values for matching specified key, located anywhere in the object
 */
export default {
  methods: {
    findDeep(obj, filter) {
      const values = [];
      Object.keys(obj).some(k => {
        if (k === filter) {
          values.push(obj[k]);
          return true;
        }
        if (obj[k] && typeof obj[k] === 'object') {
          const val = this.findDeep(obj[k], filter);
          values.push(...val);
        }
      });
      return values;
    },
  },
};
