<!--
  @Author: Ethan Dinnen
  @Date:   2019-01-23 14:01:16
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-02-26 14:45:12
-->
<template>
  <v-flex class="xs12 md5 new-type-setup" id="wizard-watch-height">
    <v-radio-group
      test-id="type-duplicate-selector"
      v-if="!editing"
      v-model="isNewLocal"
      class="duplicate-select"
      @change="handleUpdateRadio"
    >
      <v-radio
        test-id="new-type"
        :label="'New Transaction Type'"
        :value="true"
        :class="isNewLocal ? 'selected' : ''"
      />
      <v-radio
        test-id="duplicate-type"
        :label="'Duplicate From Existing'"
        :value="false"
        :class="isNewLocal ? '' : 'selected'"
      />
    </v-radio-group>
    <v-form v-model="valid" v-if="isNew !== null">
      <div class="type-selector" v-if="!isNew">
        <SelectInput
          title="Transaction Type"
          :placeholder="'Select transaction type'"
          :selected="selectedType"
          :key="`selected-type-${selectedType}`"
          :options="types"
          @onInput="handleTypeSelect"
          icon="expand_more"
        />
        <v-btn
          flat
          color="primary-dark"
          v-if="selectedType"
          @click="togglePreview"
        >
          PREVIEW
        </v-btn>
      </div>
      <TextInput
        test-id="new-type-name"
        title="Name"
        placeholder="New type name"
        :entered="typeName"
        @onInput="updateName"
        required
        :rules="nameRules"
      />
    </v-form>
    <div class="stages-create">
      <h3>Stages</h3>
      <TextInput
        test-id="new-stage-name"
        :key="stagesKey"
        title="Stage Title"
        placeholder="New stage title"
        @onInput="updateStageName"
      />
      <div class="stages-create-options">
        <v-tooltip bottom nudge-top="25" max-width="500">
          <v-checkbox
            test-id="pipeline-checkbox"
            label="In the pipeline?"
            v-model="inPipeline"
            :value="true"
            slot="activator"
          />
          <span>
            <p>
              In the pipeline (checked): A stage “in the pipeline” is included
              in the pipeline. Transactions flow between stages in the pipeline.
              These stages appear as sections in the pipeline.<br />(ie.
              Contract under review)
            </p>
            <p style="margin-bottom: 0;">
              Out of the pipeline (unchecked): A stage “out of the pipeline” is
              excluded from the pipeline. These stages do not appear as sections
              in the pipeline; however, a transaction’s stage could be changed
              to a stage out of the pipeline. When reviewing a pipeline, these
              stages appear outside the pipeline.<br />(ie. Archived)
            </p>
          </span>
        </v-tooltip>
        <v-btn
          test-id="add-stage-btn"
          flat
          @click="handleAddNewStage(tmpStageName, inPipeline);"
          :disabled="!tmpStageName"
        >
          <v-icon>add</v-icon>Add stage
        </v-btn>
      </div>
    </div>
    <div class="stage-card-container">
      <DisplayStage
        v-for="stage in stages"
        :key="`stage-${stage.title}`"
        :stage="stage"
        :upDisabled="upDisabled"
        :downDisabled="downDisabled"
        :inPipeline="isInPipeline(stage.stage_type)"
        :updateStageType="updateStageType"
        :moveStageUp="moveStageUp"
        :moveStageDown="moveStageDown"
        :removeStage="removeStage"
        :renameStage="renameStage"
        :transactions="getTransactions(stage)"
        :stages="stages"
        :moveTransactions="moveTransactions"
      />
    </div>
    <v-navigation-drawer
      class="tx-type-wizard__nav-drawer"
      v-model="previewDrawer"
      absolute
      temporary
      right
      :width="drawerWidth"
    >
      <div class="drawer-close">
        <v-btn icon @click="closeDrawer"
          ><v-icon medium>chevron_right</v-icon></v-btn
        >
      </div>
      <div class="drawer-preview">
        <div id="info" class="section">
          <h3 class="type-title">{{ uppercaseTypeName }} TEMPLATE</h3>
        </div>
        <div id="stages" class="section">
          <h2>Stage Names</h2>
          <DisplayStage
            preview
            v-for="stage in templateStages"
            :key="`preview-stage-${stage.title}`"
            :stage="stage"
            :inPipeline="isInPipeline(stage.stage_type)"
            :controls="false"
          />
        </div>
        <div id="forms" class="section">
          <h2>Form Templates</h2>
          <FormSection
            preview
            v-for="template in templates"
            :key="`template-${template.name}`"
            :name="template.name"
            :fields="template.template_fields"
            disabled
          />
        </div>
      </div>
      <div class="drawer-actions">
        <div class="drawer-actions__buttons">
          <v-btn flat color="grey" @click="handleClearSelection">
            Clear Selection
          </v-btn>
          <v-btn color="primary" @click="closeDrawer">Apply</v-btn>
        </div>
      </div>
    </v-navigation-drawer>
    <v-snackbar v-model="snackbarAdded" color="success" :timeout="3000">
      Stage added successfully
    </v-snackbar>
  </v-flex>
</template>

<script>
import SelectInput from '@/Core/components/SelectInput';
import TextInput from '@/Core/components/TextInput';
import stageTypes from '@/Core/constants/stageTypes';
import genId from '@/Core/mixins/genId';
import DisplayStage from './DisplayStage';
import FormSection from './FormSection';

export default {
  components: {
    SelectInput,
    TextInput,
    DisplayStage,
    FormSection,
  },
  props: {
    types: Array,
    handleTypeSelect: Function,
    typeName: String,
    updateName: Function,
    nameRules: Array,
    validateSetup: Function,
    handleUpdateRadio: Function,
    isNew: Boolean,
    selectedType: Number,
    stages: Array,
    addNewStage: Function,
    updateStageType: Function,
    moveStageUp: Function,
    moveStageDown: Function,
    removeStage: Function,
    splitByType: Object,
    upDisabled: Function,
    downDisabled: Function,
    editing: Boolean,
    clearSelection: Function,
    templates: Array,
    renameStage: Function,
    templateStages: Array,
    transactionsByStage: Array,
    moveTransactions: Function,
  },
  mixins: [genId],
  data() {
    return {
      valid: false,
      isNewLocal: this.isNew,
      tmpStageName: null,
      inPipeline: true,
      previewDrawer: false,
      snackbarAdded: false,
      stagesKey: this.genId(),
    };
  },
  computed: {
    selectedTypeName() {
      if (!this.selectedType) return;
      return this.types.find(t => t.value === this.selectedType).text;
    },
    drawerWidth() {
      return window.innerWidth / 2;
    },
    uppercaseTypeName() {
      return this.selectedTypeName ? this.selectedTypeName.toUpperCase() : null;
    },
  },
  methods: {
    handleAddNewStage(tmpStageName, inPipeline) {
      this.addNewStage(tmpStageName, inPipeline);
      this.stagesKey = this.genId();
      this.tmpStageName = null;
      this.snackbarAdded = true;
    },
    updateStageName(value) {
      this.tmpStageName = value;
    },
    togglePreview() {
      this.previewDrawer = !this.previewDrawer;
    },
    handleClearSelection() {
      this.previewDrawer = false;
      this.clearSelection();
    },
    isInPipeline(type) {
      return type === stageTypes.IN_PROCESS;
    },
    closeDrawer() {
      this.previewDrawer = false;
    },
    getTransactions(stage) {
      if (!this.transactionsByStage.length || !stage.id) return {};
      const found = this.transactionsByStage.find(t => t.id === stage.id);
      if (!found) return {};
      return found;
    },
  },
  watch: {
    selectedType() {
      if (!this.selectedType) return;
      this.previewDrawer = true;
    },
    valid: {
      handler(value) {
        this.validateSetup(value);
      },
    },
  },
};
</script>

<style lang="scss">
.v-tooltip__content {
  font-size: 13px;
}
.new-type-setup {
  overflow-x: scroll;
  margin-top: -20px;
  padding-left: 12px;
  margin-left: -12px;
  .duplicate-select {
    .v-input--radio-group__input {
      flex-direction: row;
    }
    .v-radio:not(:last-child):not(:only-child) {
      margin-bottom: unset;
    }
    .accent--text {
      color: var(--v-primary-dark-base) !important;
      caret-color: var(--v-primary-dark-base) !important;
    }
    .v-radio {
      &.selected {
        label {
          color: var(--v-primary-dark-base);
        }
      }
    }
  }

  .type-selector {
    display: flex;
  }

  .stages-create {
    display: flex;
    flex-direction: column;
    .v-text-field {
      margin-bottom: -15px;
    }
    h3 {
      font-weight: 600;
      margin-top: 20px;
      margin-bottom: 0;
    }
    &-options {
      display: flex;
      flex-direction: row;
      .v-input--checkbox {
        margin-top: 10px;
        .accent--text {
          color: var(--v-primary-dark-base) !important;
          caret-color: var(--v-primary-dark-base) !important;
        }
      }
      .v-btn {
        margin-top: 8px;
        color: var(--v-primary-dark-base);
      }
    }
  }
  .stage-card-container {
    padding: 0 2px;
  }

  .tx-type-wizard__nav-drawer {
    max-height: 100vh !important;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    .drawer-close {
      padding: 10px 37px;
      border-bottom: 1px solid var(--v-lion-light-grey-base);
    }
    .drawer-preview {
      padding: 48px 60px 0 60px;
      flex: 1 1 auto;
      overflow-x: scroll;
      h2 {
        padding: 14px;
        color: var(--v-primary-dark-base);
        font-family: 'Open Sans';
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
      }
      h3.type-title {
        color: var(--v-primary-base);
        // font-family: 'Nunito Sans';
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
      }
      .section {
        margin-bottom: 30px;
      }
    }
    .drawer-actions {
      background-color: white;
      padding: 10px 25px 10px 25px;
      box-shadow: 0 2px 9px rgba(0, 0, 0, 0.5);
      display: block;
      width: 100%;
      &__buttons {
        float: right;
      }
    }
  }
}
</style>
