<template>
<svg 
    data-icon="liondesk"
    role="img"
    viewBox="0 -130 1330 1330"
>

<g transform="translate(0.000000,1140.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
<path fill="currentColor" d="M5585 11349 c-66 -3 -135 -7 -154 -8 -34 -1 -33 -2 55 -62 335 -228
594 -520 783 -879 195 -373 291 -733 317 -1190 6 -110 8 -118 44 -180 60 -103
99 -205 131 -340 16 -69 42 -161 56 -205 37 -112 88 -160 318 -298 218 -130
287 -181 401 -295 167 -167 318 -362 604 -782 196 -286 254 -363 326 -429 75
-69 343 -246 759 -501 574 -352 675 -425 863 -625 183 -194 282 -381 282 -535
0 -65 -40 -186 -88 -265 -20 -33 -72 -107 -115 -165 -112 -147 -114 -152 -126
-364 -20 -326 -51 -462 -184 -792 -103 -255 -122 -314 -142 -428 -19 -114 -19
-155 5 -366 11 -96 20 -205 20 -241 0 -85 -24 -222 -52 -296 l-22 -58 -13 75
c-16 94 -46 181 -85 242 -17 27 -31 48 -32 48 -2 0 3 -28 10 -62 17 -81 17
-157 0 -239 -15 -68 -55 -173 -63 -165 -3 3 -11 41 -19 86 -8 45 -27 119 -42
163 l-27 82 -6 -100 c-4 -55 -14 -131 -24 -170 -21 -84 -65 -189 -75 -178 -4
4 -21 62 -38 128 -17 66 -42 151 -55 190 l-24 69 -12 -79 c-7 -44 -27 -118
-43 -165 -31 -88 -111 -254 -126 -264 -5 -3 -12 61 -15 147 -2 83 -10 176 -17
206 l-12 54 -27 -64 c-14 -35 -38 -86 -53 -114 -28 -53 -117 -179 -123 -173
-2 1 1 23 5 48 13 63 12 213 0 246 -10 26 -11 25 -46 -46 -25 -53 -55 -93
-107 -145 -79 -79 -126 -112 -112 -79 4 11 10 55 12 99 6 85 -12 155 -39 155
-12 0 -44 -19 -90 -52 -27 -20 -118 44 -272 192 -78 75 -163 151 -189 169
-210 144 -707 224 -1027 167 -112 -20 -272 -72 -362 -117 -194 -97 -391 -273
-515 -462 -40 -60 -73 -112 -75 -117 -5 -16 -544 7 -720 29 -437 57 -806 170
-1213 371 -421 208 -731 434 -1065 776 -604 618 -980 1414 -1074 2271 -9 78
-16 170 -17 205 -1 119 -12 112 -62 -38 -387 -1167 -348 -2334 120 -3614 101
-275 190 -482 221 -508 15 -13 97 -76 183 -141 1790 -1355 4234 -1514 6194
-404 623 354 1190 838 1640 1403 1227 1539 1562 3611 885 5470 -453 1244
-1349 2305 -2509 2970 -771 442 -1706 712 -2551 735 -102 3 -201 7 -220 8 -19
1 -89 -1 -155 -4z"/>
<path  fill="currentColor" d="M3798 11020 c-523 -183 -1066 -476 -1517 -819 -169 -128 -166 -125
-101 -100 311 121 589 193 905 235 159 22 544 24 715 5 668 -74 1251 -294
1802 -682 125 -87 127 -88 210 -99 252 -34 457 -127 626 -285 l86 -80 -164
244 c-349 520 -584 798 -881 1041 -413 338 -842 510 -1432 574 l-116 12 -133
-46z"/>
<path  fill="currentColor" d="M3310 9670 c-629 -31 -1285 -226 -1932 -574 -280 -151 -327 -183
-378 -256 -161 -231 -387 -655 -515 -965 -100 -242 -289 -824 -256 -789 5 5
44 56 87 114 299 405 675 765 1068 1024 628 415 1388 676 2192 756 97 9 104
11 195 65 278 165 622 307 983 403 l69 18 -94 27 c-504 142 -965 199 -1419
177z"/>
<path  fill="currentColor" d="M2303 7736 c-899 -467 -1597 -1176 -2161 -2194 l-85 -154 12 -151
c86 -1069 471 -2095 1102 -2932 153 -203 156 -206 95 -75 -380 809 -504 1729
-350 2590 149 829 537 1615 1144 2315 75 86 98 123 171 270 47 94 117 223 157
288 39 64 68 117 64 117 -4 0 -71 -34 -149 -74z"/>
<path fill="currentColor" d="M6614 6695 c-37 -80 -31 -112 29 -152 70 -47 190 -81 341 -98 216
-25 309 -61 436 -169 73 -63 125 -83 172 -67 41 14 57 38 58 84 0 83 -94 194
-253 297 l-86 55 0 52 -1 52 -42 -15 c-124 -43 -387 -39 -606 11 -20 5 -26 -2
-48 -50z"/>
<path  fill="currentColor" d="M9872 5352 c-29 -11 -77 -37 -108 -58 -86 -62 -127 -84 -154 -84 -36
0 -97 31 -163 83 -94 74 -154 82 -192 24 -32 -50 -30 -90 9 -169 85 -171 183
-264 372 -353 178 -85 208 -121 250 -304 29 -128 33 -290 8 -384 -44 -172
-153 -307 -453 -564 -187 -160 -247 -203 -384 -272 -268 -136 -619 -209 -949
-199 -118 4 -158 10 -228 32 -331 102 -395 118 -480 120 -82 1 -85 0 -88 -22
-6 -38 17 -79 71 -127 190 -172 744 -268 1145 -199 111 19 188 44 315 104 206
96 480 180 689 211 l87 12 17 51 c9 28 52 129 96 224 181 397 216 523 227 812
7 179 8 188 38 251 20 43 63 102 127 174 124 139 148 184 154 291 8 152 -54
270 -173 331 -67 35 -160 41 -233 15z"/>
</g>
</svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '$vuetify.icons.liondesk',
    },
  },
};
</script>