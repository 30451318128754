<template>
  <div class="mls-cta-actions">
    <v-btn title="Start CMA" icon color="#3878C0">
      <v-icon>search</v-icon>
    </v-btn>
    <v-btn icon color="#3878C0">
      <v-icon>stop</v-icon>
    </v-btn>
    <v-btn icon color="#3878C0">
      <v-icon>widgets</v-icon>
    </v-btn>
    <v-btn icon color="#3878C0">
      <v-icon>star</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.mls-cta-actions {
  i {
    color: white !important;
  }
}
</style>
