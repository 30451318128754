<template lang="pug">
  v-flex(xs12 md4)
    v-layout(row wrap)
      v-flex(xs12)
        div.header.pb-5 Select Type of Action
      v-flex(v-for="actionType in actionTypes" :key="actionType.text" xs6)
        selection-tile(
          :isActive="value && value.key === actionType.key"
          :textContent="actionType.text"
          :icon="actionType.icon"
          :disabled="actionType.disabled"
          @click.native="setActionType(actionType)"
        )
</template>
<script>
import SelectionTile from '@/Core/components/SelectionTile';

export default {
  components: { SelectionTile },

  props: {
    /**
     * The possible action types. Each action type is represented as:
     * {
     *  key: 0, // integer
     *  text: 'Email', // String, the desired text to be displayed
     *  icon: 'email', // String, the desired icon to be displayed
     *  enabled: true, // Boolean, Whether the desired tile is disabled
     * }
     */
    actionTypes: Array,
    /**
     * The Selected Action Type. Expects an object in the following structure:
     * {
     *  key: 0, // number
     *  text: 'email', // string
     *  icon: 'emial', // optional icon
     *  enabled: true, // Boolean, Whether the desired tile is disabled
     * }
     */
    value: Object,
  },

  methods: {
    setActionType(value) {
      if (!value.disabled) {
        this.$emit('input', value);
        this.$emit('action-type', value.text);
      }
    },
  },
};
</script>
