<template>
    <div class="profile-picture-container">
        <v-avatar :color="color" :size="size">
            <img v-if="imageURL" alt="Avatar" :src="imageURL">
            <span :style="{ fontSize: fontSize + 'px' }" v-else>{{ initials }}</span>
        </v-avatar>
    </div>
</template>
<script>

export default {
    name: 'LdProfilePicture',
    props: {
        profilePictureURL: {
            type: String | null,
            required: true,
        },
        firstName: {
            type: String,
            required: true,
        },
        lastName: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: false,
            default: '#005F9D',
        },
        fontSize: {
            type: Number,
            required: false,
            default: 22,
        },
        size: {
            type: Number,
            required: false,
            default: 48,
        },
    },
    data() {
        return {
            imageURL: '',
        }
    },
    async mounted() {
        if (this.profilePictureURL) {
           this.imageURL = this.profilePictureURL;
        }
    },
    watch: {
        profilePictureURL: {
            handler(value) {
                this.imageURL = value;
            }
        }
    },
    computed: {
        initials() {
            const firstLetter = this.firstName ? this.firstName.charAt(0) : '';
            const secondLetter = this.lastName ? this.lastName.charAt(0) : '';
            return `${firstLetter}${secondLetter}`;
        },
    },
};
</script>
<style lang="scss">
.profile-picture-container {
    span {
        color: white;
        text-transform: uppercase;
    }
}
</style>
