<!--
@Author: Kleber Pinel <kleberdasilva>
@Date:   2019-02-05T09:54:43-08:00
@Email:  kleber@hackcapital.com
@Last modified by:   kleberdasilva
@Last modified time: 2019-02-28T17:23:51-08:00
-->

<template>
  <LdAppLayoutPresenter
    :smsUsage="smsUsageInfo"
    :registrationStandardInfo="registrationInfo"
    :registrationStarterInfo="registrationStarterInfo"
    :registrationLowStandardInfo="registrationLowStandardInfo"
    :activeApp="activeApp"
    :addonPurchaseResult="addonPurchaseResult"
    :banner="banner"
    :bannerText="bannerText"
    :dismissBanner="dismissBanner"
    :trialWarning="trialWarning"
    :featureGate="featureGate"
    :featureMeteredUsageKey="featureMeteredUsageKey"
    :items="utilNavItems"
    :isPromoVisible="isPromoVisible"
    :navBarExpanded="navBarExpanded"
    :navigation="navigation"
    :plan="plan"
    :title="title"
    :upgradeLink="`${HC_PROPS.ACCOUNTS_APP_URL}/settings?modal=subscription`"
    :updateLink="`${HC_PROPS.ACCOUNTS_APP_URL}/settings?modal=paymentMethod`"
    :customNumberLink="`${HC_PROPS.ACCOUNTS_APP_URL}/settings?modal=customNumber`"
    :user="userInfo"
    :v2Only="v2Only"
    :verifyEmail="verifyEmailCta"
    :shouldLoadV2Contacts="shouldLoadV2Contacts"
    :shouldSeeV2ContactsToggle="shouldSeeV2ContactsToggle"
    :isUpdatingV2ContactsFlag="isUpdatingV2ContactsFlag"
    :hasAtLeastOneEmailAccount="hasAtLeastOneEmailAccount"
    :hasAtLeastOneOutlookAccountToReconnect="hasAtLeastOneOutlookAccountToReconnect"
    :shouldLoadV2Events="shouldLoadV2Events"
    :shouldLoadQualify="shouldLoadQualify"
    :isUpdatingQualifyFlag="isUpdatingQualifyFlag"
    :shouldSeeV2EventsToggle="shouldSeeV2EventsToggle"
    :isUpdatingV2EventsFlag="isUpdatingV2EventsFlag"
    :setShouldLoadV2Contacts="toggleShouldLoadFeatureFlag(contactsMS)"
    :setShouldLoadV2Events="toggleShouldLoadFeatureFlag(eventsMS)"
    :setShouldLoadQualify="toggleShouldLoadFeatureFlag(qualify)"
    :hasAccountManagementFF="hasAccountManagementFF"
    :hasBrokersSalesDemoFF="hasBrokersSalesDemoFF"
    :profilePicture="profilePicture"
    @accept:addon="acceptMeteredUsage"
    @click:resendVerifyEmail="resendVerifyEmail"
    @click:a2pRegistration="redirectToA2pRegistration"
    @logout="logout"
    @toggle:drawer="toggleDrawer"
    @toggle:gated="toggleFeatureGate(null)"
    @toggle:verifyEmail="toggleVerifyEmailCta"
    @updateProfilePicture="updateProfilePicture"
    class="layout-container"
  >
    <slot></slot>
    <template slot="search">
      <slot name="search"></slot>
    </template>
  </LdAppLayoutPresenter>
</template>

<script>
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { HC_PROPS } from 'lion-vue-src/_globals';
import LdFeatures from 'lion-vue-src/_globals/lib/LdFeatures';
import LdAppLayoutPresenter from './LdAppLayoutPresenter';
import {
  FEATURE_FLAG_CONTACTS_MS,
  FEATURE_FLAG_CONTACTS_MS_TOGGLE,
  FEATURE_FLAG_EVENTS_MS,
  FEATURE_FLAG_EVENTS_MS_TOGGLE,
  FEATURE_FLAG_QUALIFY,
} from '../../store/modules/ldAppLayout/store/constants';

const EMAIL_INTEGRATION = "email_integration_notification_dismissed";

const { mapState: mapAppLayoutState, mapGetters: mapAppLayoutGetters, mapActions: mapAppLayoutActions } = createNamespacedHelpers(
  'ldAppLayout',
);
const {
  mapState: mapSubscriptionState,
  mapActions: mapSubscriptionActions,
} = createNamespacedHelpers('subscription');

const {
  mapGetters: mapEmailManagementGetters,
  mapActions: mapEmailManagementActions,
} = createNamespacedHelpers('emailManagement');

export default {
  components: {
    LdAppLayoutPresenter,
  },
  props: {
    ignoreAuth: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'LionDesk 2.0 MVP',
    },
    activeApp: {
      type: String,
      default: '',
    },
  },
  data() {
    const navigation = [
      {
        id: 'home',
        icon: 'dashboard',
        title: 'Home',
        route: {
          name: 'dashboard',
          href: `${HC_PROPS.PLATFORM_APP_URL}/dashboard`,
          target: '_self',
        },
        app: 'crm',
      },
      {
        id: 'contacts',
        icon: 'contacts',
        title: 'Contacts',
        route: { name: 'contacts', href: `${HC_PROPS.PLATFORM_APP_URL}/contacts`, target: '_self' },
        app: 'crm',
      },
      {
        id: 'communication',
        icon: 'mail_outline',
        title: 'Communications',
        route: {
          name: 'communications',
          href: `${HC_PROPS.PLATFORM_APP_URL}/communications`,
          target: '_self',
        },
        app: 'crm',
      },
      {
        id: 'tasks',
        icon: 'event_note',
        title: 'Tasks',
        route: { name: 'tasks', href: `${HC_PROPS.PLATFORM_APP_URL}/tasks`, target: '_self' },
        app: 'crm',
      },
      {
        id: 'campaigns',
        icon: 'flight_takeoff',
        title: 'Campaigns',
        route: {
          name: 'campaigns',
          href: `${HC_PROPS.PLATFORM_APP_URL}/campaigns`,
          target: '_self',
        },
        app: 'crm',
      },
      {
        id: 'videos',
        icon: 'videocam',
        title: 'Videos',
        route: {
          name: 'videos',
          href: `${HC_PROPS.PLATFORM_APP_URL}/videos?sf=created_at&so=desc`,
          target: '_self',
        },
        app: 'crm',
      },
      {
        id: 'marketing',
        icon: '$vuetify.icons.bullhorn',
        title: 'Marketing',
        route: {
          name: 'marketing',
          href: `${HC_PROPS.PLATFORM_APP_URL}/marketing`,
          target: '_self',
        },
        app: 'crm',
      },
      {
        id: 'leadsplusreporting',
        icon: '$vuetify.icons.leads-plus',
        title: 'Leads+',
        route: {
          name: 'leadsplusreporting',
          href: `${HC_PROPS.PLATFORM_APP_URL}/leadsplusreporting`,
          target: '_self',
        },
        app: 'crm',
      },
      {
        id: 'documents',
        icon: 'folder',
        title: 'Documents',
        route: {
          name: 'documents',
          href: `${HC_PROPS.PLATFORM_APP_URL}/documents`,
          target: '_self',
        },
        app: 'crm',
      },
      {
        id: 'transactions',
        icon: 'credit_card',
        title: 'Transactions',
        route: { name: '/', href: HC_PROPS.TRANSACTIONS_APP_URL, target: '_self' },
        app: 'transactions',
        feature: LdFeatures.ApplicationTransactions,
      },
      // Uncomment this when VMI is released
      // {
      //   id: 'mls',
      //   icon: 'home',
      //   title: 'Visual Market Insights',
      //   route: { name: '/', href: HC_PROPS.MLS_APP_URL, target: '_self' },
      //   app: 'mls',
      //   feature: LdFeatures.ApplicationVmi,
      // },
      {
        id: 'reporting',
        icon: 'insert_chart_outlined',
        title: 'Reporting',
        route: {
          name: 'reporting',
          href: `${HC_PROPS.PLATFORM_APP_URL}/reporting`,
          target: '_self',
        },
        app: 'crm',
      },
      {
        id: 'help',
        icon: 'help',
        title: 'Online Help',
        route: { name: 'online-help', href: 'https://lonewolf.my.site.com/s/liondesk-resources', target: '_blank' },
        app: 'help',
      },
      {
        id: 'v1',
        icon: 'launch',
        title: 'LionDesk V1',
        route: { name: 'LionDeskV1', href: 'https://www.liondesk.com/admin/', target: '_blank' },
        app: 'v1',
      },
    ];
    return {
      LdFeatures,
      drawer: true,
      navigation,
      trialWarning: false,
      banner: false,
      HC_PROPS: HC_PROPS,
      contactsMS: FEATURE_FLAG_CONTACTS_MS,
      contactsMSToggle: FEATURE_FLAG_CONTACTS_MS_TOGGLE,
      eventsMS: FEATURE_FLAG_EVENTS_MS,
      eventsMSToggle: FEATURE_FLAG_EVENTS_MS_TOGGLE,
      qualify: FEATURE_FLAG_QUALIFY,
    };
  },
  watch: {
    userInfo: {
      handler(value) {
        this.setShouldSeeFFToggle(this.contactsMSToggle);
        this.setShouldLoadV2FFInitialState(this.contactsMS);

        this.setShouldSeeFFToggle(this.eventsMSToggle);
        this.setShouldLoadV2FFInitialState(this.eventsMS);

        this.setShouldLoadV2FFInitialState(this.qualify);

        this.banner = this.showBanner;
        this.trialWarning = this.showTrialWarning;

        window.lwcrm = { me: value };
      },
      immediate: true,
    },
  },
  computed: {
    ...mapAppLayoutState([
      'navBarExpanded',
      'userInfo',
      'verifyEmailCta',
      'shouldLoadV2Contacts',
      'shouldSeeV2ContactsToggle',
      'isUpdatingV2ContactsFlag',
      'hasAtLeastOneEmailAccount',
      'shouldLoadV2Events',
      'shouldSeeV2EventsToggle',
      'isUpdatingV2EventsFlag',
      'isUpdatingQualifyFlag',
      'shouldLoadQualify',
      'onboardingFinished',
      'smsUsageInfo',
      'registrationInfo',
      'registrationStarterInfo',
      'registrationLowStandardInfo',
      'showChangeProfilePictureModal',
    ]),
    ...mapSubscriptionState([
      'addonPurchaseResult',
      'featureGate',
      'featureMeteredUsageAcceptedKey',
      'featureMeteredUsageKey',
      'features',
      'plan',
    ]),
    ...mapEmailManagementGetters([
      'hasAtLeastOneOutlookAccountToReconnect'
    ]),
    ...mapAppLayoutGetters([
      'hasAccountManagementFF',
      'hasBrokersSalesDemoFF',
      'profilePicture'
    ]),
    v2Only() {
      if (!this.userInfo) return true;
      const { account_version } = this.userInfo;
      return account_version === 2;
    },
    // reserved for future promotional banner use
    isPromoVisible() {
      return false;
    },
    utilNavItems() {
      return [];
    },
    showBanner() {
      const seen = JSON.parse(localStorage.getItem('paymentNagDismissed'));
      // If the user doesn't exist don't show banner. Prevents flash before user loads
      if (!this.userInfo) return false;

      // TODO: REMOVE THIS USER ID FILTER. The next two lines
      // if (this.MLS_DEMO_ALL.includes(this.userInfo.id)) return !seen;

      const { txt_status } = this.userInfo.subscriptionOverview;
      // If credit card (or trial user) is expired and the user has not yet dismissed, show CTA
      if (['ccexpired_pending', 'ccexpired', 'trial_expired'].includes(txt_status) && !seen)
        return true;
      // If user is not an MLS user don't show CTA
      if (txt_status !== 'mls') return false;
      // Show banner if user has not yet dismissed it
      return !seen;
    },
    bannerText() {
      return 'Add a payment method to send more emails and text messages than are included in your monthly plan. Please note that these are pay-per-use features after your monthly limit.';
    },
    showTrialWarning() {
      if (!this.userInfo) return false;
      if (this.userInfo.subscriptionOverview.txt_status == 'trial') {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapAppLayoutActions([
      'setShowChangeProfilePictureModal',
      'setNavBarExpanded',
      'getUserInfo',
      'toggleVerifyEmailCta',
      'resendVerifyEmail',
      'setHasAtLeastOneEmailAccount',
      'getOnboardingInfo',
      'getRegistration10DlcInfo',
      'getRegistrationLowStandard10DlcInfo',
      'setShouldLoadFeatureFlag',
      'setShouldSeeFFToggle',
      'setShouldLoadV2FFInitialState',
    ]),
    ...mapSubscriptionActions(['toggleFeatureGate', 'acceptMeteredUsage']),
    ...mapEmailManagementActions(['loadEmailAccounts']),
    toggleDrawer() {
      this.$emit('toggle:drawer', !this.navBarExpanded);
      this.setNavBarExpanded(!this.navBarExpanded);
    },
    logout() {
      const ref = document.location.href;
      window.$cookies.set('LD_REDIRECT_URL', ref, null, null, HC_PROPS.MAIN_DOMAIN);
      window.$cookies.remove('LD_AUTH', null, HC_PROPS.MAIN_DOMAIN);
      window.$cookies.remove('feathers-jwt', null, HC_PROPS.MAIN_DOMAIN);
      window.$cookies.remove('RL6Token', null, HC_PROPS.MAIN_DOMAIN);
      window.lwcrm = undefined;
      window.location = `${HC_PROPS.ACCOUNTS_APP_URL}/signin`;
    },
    updateProfilePicture() {
      this.setShowChangeProfilePictureModal(true);
    },
    dismissBanner() {
      localStorage.setItem('paymentNagDismissed', true);
      this.banner = false;
    },
    redirectToA2pRegistration() {
      this.$router.push('/a2p-standardbrand-registration');
    },
    toggleShouldLoadFeatureFlag(flag) {
      return (shouldLoadFF) => {
        this.setShouldLoadFeatureFlag({ shouldLoadFF, flag })
      };
    },
  },
  async created() {
    if (!localStorage.getItem(EMAIL_INTEGRATION)) {
      this.setHasAtLeastOneEmailAccount();
    }

    if (!this.ignoreAuth && this.$route.meta.getUserInfo === true) {
      this.getUserInfo();
    }
    await this.loadEmailAccounts();
    const storedPrefs = localStorage.getItem('preferences');
    if (storedPrefs) {
      const storedPrefsJSON = JSON.parse(storedPrefs);
      if (storedPrefsJSON.navBarExpanded !== undefined) {
        this.$emit('toggle:drawer', storedPrefsJSON.navBarExpanded);
        this.setNavBarExpanded(storedPrefsJSON.navBarExpanded);
      }
    }

    if (this.userInfo && !this.userInfo.email_is_verified) {
      window.location = `${HC_PROPS.PLATFORM_APP_URL}/resend-verification-email`
    }

    await this.getOnboardingInfo();
    if (!this.onboardingFinished) {
      window.location = `${HC_PROPS.PLATFORM_APP_URL}/onboarding`
    }

    await this.getRegistration10DlcInfo();
    if (!this.registrationInfo.twilio_data) {
      await this.getRegistrationLowStandard10DlcInfo();
    }

  },
  beforeMount() {
    this.banner = this.showBanner;
  },
};
</script>
