/*
 * @Author: Ethan Dinnen
 * @Date:   2018-11-30 11:03:26
 * @Last Modified by:   Ethan Dinnen
 * @Last Modified time: 2019-02-22 11:16:26
 */

import initialState from './initialState';
import stageTypes from '@/Core/constants/stageTypes';
import get from 'lodash/get';
import { sanitize } from '@/Core/mixins/formatCurrency';
import transactionInputTypes from '@/Core/constants/transactionInputTypes';
import formTemplateNames from '@/Core/constants/formTemplateNames';

const reset = state => {
  Object.assign(state, initialState());
};

// Reset values, but keep values necessary for creating more transactions
const createReset = state => {
  const {
    transactionsToCreate,
    markets,
    transactionTypes,
    roles,
    campaigns,
  } = state;
  Object.assign(state, initialState());
  state.transactionsToCreate = transactionsToCreate;
  state.markets = markets;
  state.transactionTypes = transactionTypes;
  state.roles = roles;
  state.campaigns = campaigns;
};

const updateTitle = (state, value) => {
  state.title = value;
};

const updateType = (state, value) => {
  state.type = value;
};

const updateStage = (state, value) => {
  state.stage = value;
};

const updateProperty = (state, value) => {
  state.property = value;
};

const updateMarket = (state, value) => {
  state.market = value;
};

const updatePipelineStages = (state, value) => {
  state.pipeline.pipeline.summationsByStage[value.index].transactions =
    value.transactions;
};

const validateSetup = (state, value) => {
  state.setupValid = !value;
};

const prependToPipeline = (state, stageToAdd) => {
  const { summationsByStage } = state.pipeline.pipeline;
  const rest = summationsByStage.map(stage => {
    return {
      level: stage.level + 1,
      ...stage,
    };
  });
  state.pipeline.pipeline.summationsByStage = [stageToAdd, ...rest];
};

const appendToPipeline = (state, stageToAdd) => {
  const { summationsByStage } = state.pipeline.pipeline;
  state.pipeline.pipeline.summationsByStage = [
    ...summationsByStage,
    stageToAdd,
  ];
};

const getLastLevel = state => {
  let lastLevel = 1;
  state.pipeline.pipeline.summationsByStage.map(stage => {
    if (stage.level > lastLevel) lastLevel = stage.level;
  });
  return lastLevel + 1;
};

const addTempToPipeline = (state, { selected, selectedStageType }) => {
  const lastLevel = getLastLevel(state);

  const temp = {
    id: -1,
    level: selectedStageType === stageTypes.IN_PROCESS ? 1 : lastLevel,
    stage_type: selectedStageType,
    summation: {
      total_commission: null,
      total_sales: null,
    },
    title: '',
    transactionCount: 0,
    transaction_type_id: selected,
    transaction: [],
  };

  if (selectedStageType === stageTypes.IN_PROCESS) {
    prependToPipeline(state, temp);
  } else {
    appendToPipeline(state, temp);
  }
};

const removeTempFromPipeline = state => {
  const filtered = state.pipeline.pipeline.summationsByStage.filter(
    s => s.title !== ''
  );
  state.pipeline.pipeline.summationsByStage = filtered;
};

// API related mutations

const saveTransaction = (state, transaction) => {
  // Remove unneeded properties from the template
  transaction.forms = transaction.forms.map(form => {
    /* eslint-disable */
    const {
      id,
      template_fields,
      created_at,
      updated_at,
      transaction_type_id,
      ...rest
    } = form;
    /* eslint-enable */
    // Remove unneeded properties from the fields
    const modified = form.template_fields.map(field => {
      // eslint-disable-next-line
      const { created_at, id, template_id, updated_at, ...fieldRest } = field;
      // Value was not set by the user, so add it as an empty string as it is required
      if (!field.value)
        return {
          ...fieldRest,
          value: '',
        };
      // If type is 'currency' we need to do additional processing
      if (field.type !== transactionInputTypes.CURRENCY) return fieldRest;
      // Sanitize the value to ensure we are sending a Number to the database
      const raw = sanitize(field.value.toString());
      return {
        ...fieldRest,
        value: raw,
      };
    });
    // Return the processed transaction forms
    return {
      ...rest,
      transaction_form_fields: modified,
    };
  });

  state.transactionsToCreate = [...state.transactionsToCreate, transaction];
};

const updateTransaction = (state, value) => {
  state.transaction = value;
  state.title = value.title;
  state.type = value.transaction_type.id;
  state.stage = value.stage_id;
  state.property = value.meta.property;
  value.user_tx_stakeholders.map(stakeholder => {
    if (stakeholder.roles) {
      addContact(state, {
        ...stakeholder.meta,
        id: stakeholder.stakeholder_id,
        roles: stakeholder.roles.roles,
      });
    }
  });

  value.user_tx_stakeholders.map(stakeholder => {
    if (stakeholder.campaigns) {
      stakeholder.campaigns.map(campaignId => {
        assignCampaign(state, {
          id: campaignId,
          contact: stakeholder.stakeholder_id,
        });
      });
    }
  });
  state.renderReady = true;
};

const updateTransactions = (state, value) => {
  state.transactions = value;
};

const updateStages = (state, value) => {
  state.stages = value;
};

const updatePipeline = (state, value) => {
  const sortedPipeline = { ...value };
  sortedPipeline.pipeline[
    'summationsByStage'
  ] = sortedPipeline.pipeline.summationsByStage.sort(
    (a, b) => a.level - b.level
  );
  state.pipeline = sortedPipeline;
};

const updateTransactionTypes = (state, value) => {
  state.transactionTypes = [...value].sort((a, b) => a.level - b.level);
};

const updateMarketList = (state, value) => {
  state.markets = value;
};

const setRoles = (state, value) => {
  // Sort alphabetically (case-insensitive, unicode)
  const roles = value.sort((a, b) => a.name.localeCompare(b.name));
  state.roles = roles;
};

const setRole = (state, { contact, role }) => {
  const title = state.roles.find(r => r.id === role).name;
  const newRoles = [{ value: role, title }];

  if (!contact.id) {
    const i = state.newContacts.findIndex(
      ({ first_name, last_name }) =>
        first_name === contact.first_name && last_name === contact.last_name
    );
    state.newContacts[i].roles = newRoles;
    return;
  }
  const i = state.selectedContacts.findIndex(c => c.id === contact.id);
  state.selectedContacts[i].roles = newRoles;
};

const setContacts = (state, value) => {
  state.contacts = value;
};

const addContact = (state, contact) => {
  const inList = state.selectedContacts.filter(c => {
    return (
      c.first_name === contact.first_name && c.last_name === contact.last_name
    );
  });
  if (inList.length) return;
  if (!contact.roles) {
    contact.roles = [{ value: 5, title: 'Admin' }];
  }
  state.selectedContacts = [...state.selectedContacts, contact];
};

const addNewContact = (state, contact) => {
  const inList = state.newContacts.filter(c => {
    return (
      c.first_name === contact.first_name && c.last_name === contact.last_name
    );
  });
  if (inList.length) return;
  state.newContacts = [...state.newContacts, contact];
};

const removeContact = (state, toRemove) => {
  state.selectedContacts = state.selectedContacts.filter(
    contact => contact !== toRemove
  );
  state.newContacts = state.newContacts.filter(contact => contact !== toRemove);
};

const setCampaigns = (state, campaigns) => {
  state.campaigns = campaigns;
};

const assignCampaign = (state, data) => {
  let contact;
  let index;
  // New contacts do not have ids and instead return full name as a string
  if (data.contact.constructor === String) {
    // ...so find the contact by name
    contact = state.newContacts.find(
      c => `${c.first_name} ${c.last_name}` === data.contact
    );
    index = state.newContacts.indexOf(contact);
    // Add the campaign if it does not exist yet
    if (!contact.campaigns.includes(data.id)) contact.campaigns.push(data.id);
    state.newContacts[index] = contact;
  } else {
    // Find the existing contact by id
    contact = state.selectedContacts.find(c => c.id === data.contact);

    // Ensure that the campaigns array exists on an already created contact
    if (!contact.campaigns) contact.campaigns = [];
    index = state.selectedContacts.indexOf(contact);
    if (!contact.campaigns.includes(data.id)) contact.campaigns.push(data.id);
    state.selectedContacts[index] = contact;
  }
};

const setPipelineError = (state, message) => {
  state.pipelineError = message;
};

const setFormSummaries = (state, transaction) => {
  const summaries = [];
  if (!transaction) return;
  // Set the form section summaries
  for (const t of transaction.transaction_forms) {
    const i = t.position - 1;
    // Set additional data for 'Financials' form
    const data =
      t.name === formTemplateNames.FINANCIALS
        ? [
            'Sales Value - $ 0.00',
            'Commission Value - $ 0.00',
            '0% of fields completed',
          ]
        : ['0% of fields completed'];

    summaries.push({
      key: `transaction-${i}`,
      form: true,
      header: t.name,
      data,
      done: false,
    });
  }
  state.formSummaries = summaries;

};

const getTemplates = (state, templates) => {
  // Get the form templates and sort them and their fields by position
  const sort = (a, b) => a.position - b.position;
  const fieldsSorted = templates.map(template => {
    const sortedFields = template.template_fields.sort(sort);
    return {
      ...template,
      template_fields: sortedFields,
    };
  });
  const sorted = fieldsSorted.sort(sort);
  state.templates = sorted;
};

const updateFinancialsData = (state, { index, value }) => {
  // Set the value of the specified line in the 'Financials' form summary
  const financials = state.formSummaries
    .map(s => s.header)
    .indexOf('Financials');
  state.formSummaries[financials].data[index] = value;
};

const resetFinancialsData = state => {
  // Reset the 'Financials' form summary
  if (!state.formSummaries.length) return;
  const financials = state.formSummaries.map(s => s.name).indexOf('Financials');
  state.formSummaries[financials].data = [
    'Sales Value - $ 0.00',
    'Commission Value - $ 0.00',
    '0% of fields completed',
  ];
};

// Set all the form summaries to 'done'
const setFormsDone = state => {
  state.formSummaries = state.formSummaries.map(form => {
    return {
      ...form,
      done: true,
    };
  });
};

// Set a specific form summary as 'done'
const setFormDone = (state, key) => {
  const i = state.formSummaries.findIndex(s => s.key === key);
  // Is triggered on the following element, so calculate the earlier summary
  if (i > -1) {
    state.formSummaries[i - 1].done = true;
    return;
  }
  state.formSummaries[state.formSummaries.length - 1].done = true;
};

// Update the percentage complete value for a specified form summary
const updateSectionPercentage = (state, { name, value }) => {
  const i = state.formSummaries.findIndex(s => s.header === name);
  if (i === 2) {
    state.formSummaries[2].data[2] = `${value}% of fields completed`;
    return;
  }
  state.formSummaries[i].data[0] = `${value}% of fields completed`;
};

const formatedValue = (valueFromProperty, type, value) => {
  if (!valueFromProperty) {
    if (value === '') return value;
    if (type === 'currency') return value;
    return value;
  }
  if (type === 'currency') return value || valueFromProperty;
  return value || valueFromProperty;
};

const setupFormsFor = (itemsType, items, property) => {
  const subItem =
    itemsType === 'templates' ? 'template_fields' : 'transaction_form_fields';
  const forms = items.map(item => {
    const sub = item[subItem].map(field => {
      const { label, position, type, field_source, value = '' } = field;
      const valueFromProperty = get(property, field_source);
      const finalValue = formatedValue(valueFromProperty, type, value);
      return {
        ...field,
        label,
        position,
        type,
        value: finalValue.toString(),
      };
    });
    const out = {
      ...item,
    };
    out[subItem] = sub;
    return out;
  });

  return forms.sort((a, b) => a.position - b.position);
};

const setForms = (state, { item, route }) => {
  if (route.params.id) {
    const forms = setupFormsFor(
      'transaction_form_fields',
      state.transaction.transaction_forms,
      item
    );
    state.transaction.transaction_forms = forms;
    return;
  }
  const forms = setupFormsFor('templates', state.templates, item);
  state.templates = forms;
};

const setPlans = (state, plans) => {
  state.plans = plans;
};

const sortPlans = (state, sortInfo) => {
  let { column, descending } = sortInfo;
  let comparison = 0;
  state.plans.sort((a, b) => {
  switch (column) {
    case 'name':
      comparison = a['title'].toUpperCase() > b['title'].toUpperCase() ? 1 : -1;
      break;
    case 'type':
      a = state.transactionTypes.find( transaction => transaction.id == a['type_id']);
      b = state.transactionTypes.find( transaction => transaction.id == b['type_id']);
      comparison = a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
      break;
    case 'actions':
      comparison = a.tasks.length > b.tasks.length ? 1 : -1;
      break;
  }
    comparison *= descending ? -1 : 1;
    return comparison;
  });
};

const setPlansSearchResults = (state, plans) => {
  state.plansSearchResults = plans;
};

const clearPlansSearchResults = (state) => {
  state.plansSearchResults = [];
};

const queueSnack = (state, snack) => {
  state.snacks = [...state.snacks, snack];
};

const clearLastSnack = (state) => {
  state.snacks = state.snacks.slice(1);
};

const setShowErrorMessage = (state, value) => {
  state.showErrorMessage = value;
};

const setErrorMessage = (state, value) => {
  state.errorMessage = value;
};

const setTransactionsCreated = (state, value) => {
  state.transactionsCreated = value;
};

export default {
  reset,
  createReset,
  updateTitle,
  updateType,
  updateStage,
  updateProperty,
  updatePipelineStages,
  updateMarket,
  updateTransaction,
  updateTransactions,
  validateSetup,
  updateStages,
  updatePipeline,
  updateTransactionTypes,
  updateMarketList,
  setRoles,
  setRole,
  setContacts,
  addContact,
  addNewContact,
  removeContact,
  setCampaigns,
  assignCampaign,
  saveTransaction,
  setPipelineError,
  addTempToPipeline,
  removeTempFromPipeline,
  getTemplates,
  updateFinancialsData,
  resetFinancialsData,
  setFormSummaries,
  setFormsDone,
  setFormDone,
  updateSectionPercentage,
  setForms,
  setPlans,
  sortPlans,
  setPlansSearchResults,
  clearPlansSearchResults,
  queueSnack,
  clearLastSnack,
  setShowErrorMessage,
  setErrorMessage,
  setTransactionsCreated,
};
