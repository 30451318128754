<template>
  <div class="filter-item">
    <v-list-tile-content>
      <div class="filter__type">
        <v-autocomplete
          :items="availableConditions"
          item-text="name"
          item-value="field"
          v-model="selectedCondition"
          placeholder="Select field..."
          return-object="return-object"
        >
          <template slot="item" slot-scope="{ item }">
            <v-icon size="medium">{{item.icon}}</v-icon>
            {{item.name}}
          </template>
          <template slot="selection" slot-scope="{ item }">
            <span>
              <v-icon size="medium">{{item.icon}}</v-icon>
              {{item.name}}
            </span>
          </template>
        </v-autocomplete>
      </div>
      <component
        v-if="selectedCondition"
        :is="`${selectedCondition.type}-condition`"
        :value="selectedCondition"
        @input="updateItem"
      ></component>
    </v-list-tile-content>
    <v-list-tile-action>
      <span class="filter__action">
        <v-icon @click="$emit('delete', index)" size="medium">delete</v-icon>
      </span>
    </v-list-tile-action>
  </div>
</template>

<script>
import ForeignKeyCondition from './ForeignKeyCondition';
import IntegerCondition from './IntegerCondition';
import TextCondition from './TextCondition';
import DateCondition from './DateCondition';

export default {
  components: {
    ForeignKeyCondition,
    IntegerCondition,
    DateCondition,
    TextCondition,
  },
  props: {
    condition: {
      type: Object,
      required: true,
    },
    availableConditions: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedCondition: null,
    };
  },
  created() {
    this.selectedCondition = Object.assign({}, this.condition);
  },
  methods: {
    /**
     * this.selectedCondition:
     *    icon: "calendar_today",
     *    field: "created_at",
     *    name: "Created at",
     *    operator: "today",
     *    type: date,
     *    value: null
     *
     * Purpose of this function is to propagate the changes up to the parent component
     *
     * @param  {object} value - an object depending on the child component
     * @return {}
     */
    updateItem(value) {
      this.$emit('input', this.index, Object.assign(this.selectedCondition, value));
    },
  },
};
</script>

<style lang="scss" type="text/scss">
/** Overrides */
.filter-item > .v-list__tile__content > .filter__type {
  margin-right: 10px;
  min-width: 300px !important;
  max-width: 300px !important;
}

.condition {
  flex: 1 1;
  display: inline-flex;
  white-space: nowrap;
  align-items: flex-start;

  .filter__operator {
    flex: 0 0 190px !important;
    min-width: 190px;
    max-width: 190px;
    margin-right: 1em !important;
  }

  .filter__value {
    flex: 0 0 auto !important;
  }

  .v-select__selections {
    flex-wrap: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
