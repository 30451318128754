<!--
  @Author: Ethan Dinnen
  @Date:   2018-12-11 15:52:47
  @Last Modified by:   Ethan Dinnen
  @Last Modified time: 2019-02-19 15:34:55
-->
<template>
  <tr>
    <th>
      <v-checkbox
        color="primary"
        @click.native="selectAll"
        :value="selected.length > 0"
        :indeterminate="isIndeterminate"
        :value-comparator="() => true"
        hide-details
      ></v-checkbox>
    </th>
    <template v-for="(header, index) in data.headers">
      <th
        class="text-xs-left"
        :key="index"
        @click="setSortBy(header);"
        :class="[
          'column',
          header.sortable ? 'sortable' : '',
          sortOrder ? 'desc' : 'asc',
          header.value === sortField ? 'active' : '',
        ]"
      >
        {{ header.text }}
        <v-icon small v-show="header.sortable === true">arrow_upward</v-icon>
      </th>
    </template>
    <th v-if="selected.length"><DataTableMenu :actions="actions" /></th>
    <LdConfirmation
      :value="dialog"
      width="580"
      risk="high"
      @click:cancel="dialog = false;"
      @click:ok="deleteSelected"
      :confirmText="`Delete ${type}${plural}`"
    >
      <template slot="header">
        Delete {{ selected.length }} item{{ plural }}
      </template>
      <template slot="body">
        Are you sure you want to permanently delete the {{ selected.length }}
        {{ type }}{{ plural }} selected?
      </template>
    </LdConfirmation>
  </tr>
</template>

<script>
import { LdConfirmation } from 'lion-vue';
import DataTableMenu from '@/Core/components/DataTableMenu';

export default {
  components: {
    DataTableMenu,
    LdConfirmation,
  },
  props: {
    data: {
      type: Object,
    },
    selected: {
      type: Array,
    },
    pageLimit: {
      type: Number,
    },
    setSortBy: {
      type: Function,
    },
    sortField: {
      type: String,
    },
    sortOrder: {
      type: Boolean,
    },
    total: {
      type: Number,
    },
    plans: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      actions: [
        {
          title: 'Delete Selected',
          icon: 'delete',
          func: this.openDialog,
        },
      ],
    };
  },
  computed: {
    type() {
      return this.plans ? 'plan' : 'transaction';
    },
    plural() {
      return this.selected.length === 1 ? '' : 's';
    },
    isIndeterminate() {
      if (!this.selected.length) return false;
      if (this.pageLimit > this.total) {
        return this.selected.length < this.total;
      }
      return this.selected.length < this.pageLimit;
    },
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    deleteSelected() {
      this.dialog = false;
      this.$emit('click:delete-select');
    },
    selectAll() {
      this.$emit('click:select-all');
    },
  },
};
</script>
