import { Plan, Contacts, TransactionTypes, Teams } from 'api';
import router from 'router';
import initialState from './initialState';

/**
 * @param {Array} requiredParams - an array of the names of the required fields
 * @param {Object} payload - the params that were submitted from the frontend
 * @param {Function} commit - vuex function to call a mutation
 */
const checkRequiredFields = (requiredParams, payload, commit) => {
  if (payload.template_type === 'Email') requiredParams.push('subject');

  const missingFields = requiredParams.filter(param => {
    return !payload[param];
  });
  if (missingFields.length) {
    const error = new Error(`Missing ${missingFields.join(', ')}`);

    commit('setActionError', error);
    throw error;
  }
};

export default {
  namespaced: true,
  state: {
    ...initialState,
  },
  getters: {},
  mutations: {
    updateTask(state, value) {
      state.task = value;
    },
    reset(state) {
      Object.assign(state, initialState);
    },
    setKCD(state, value) {
      state.kcds = value;
    },
    // Sets the editing state
    setEditing(state, isEditing) {
      state.isEditing = isEditing;
    },
    // Template viewer-related mutations
    setTemplateFolders(state, templateFolders) {
      templateFolders.forEach(folder => {
        folder.children = [];
      });
      state.templateFolders = JSON.parse(JSON.stringify(templateFolders));
      state.displayTemplates = templateFolders;
    },
    // Assigns templates to the desired folder
    assignTemplatesToFolder(state, { desiredFolder, templates }) {
      state.displayTemplates.forEach(folder => {
        // Finds the correct folder to append the templates
        if (folder.id === desiredFolder.id) {
          let processedTemplates = [];
          if (templates && Array.isArray(templates)) {
            // Sets the display name to be the description of each template
            processedTemplates = templates.map(template => {
              template.name = template.description || template.subject;
              return template;
            });
          }
          folder.children = processedTemplates;
        }
      });
    },
    // Clears the error message
    clearErrors(state) {
      state.templatesError = false;
      state.templatesErrorMessage = '';
    },
    // Clears the success message
    clearSuccess(state) {
      state.templatesSuccess = false;
      state.templatesSuccessMessage = '';
    },
    // Sets the error message
    templatesError(state, { code, message }) {
      state.templatesErrorMessage = `Templates: ${code || ''} ${message}`;
      state.templatesInProgress = false;
      state.templatesError = true;
    },
    // Indicates a HTTP Request is still ongoing
    templatesInProgress(state) {
      state.templatesInProgress = true;
    },
    // Accepts templates that matches the search criteria and assigns it to folders
    // TODO NA: Rename to setSearchTemplates
    setTemplates(state, templates) {
      const folders = JSON.parse(JSON.stringify(state.templateFolders));
      templates.forEach(template => {
        template.name = template.description || template.subject;
        const assignedFolder = folders.find(
          folder => folder.id === template.template_folder_id
        );
        assignedFolder.children.push(template);
      });
      state.displayTemplates = folders.filter(folder => folder.children.length);
    },
    // Sets the template
    setFiles(state, files) {
      state.files = files;
    },
    setRoles(state, roles) {
      state.roles = roles;
    },
    setStakeholders(state, stakeholders) {
      state.stakeholders = stakeholders;
    },
    setAction(state, action) {
      state.action = action;
    },
    setTeamMembers(state, teamMembers) {
      state.teamMembers = teamMembers;
    },
  },

  actions: {
    async reset({ commit }) {
      await commit('reset');
    },
    updateTask({ commit }, value) {
      commit('updateTask', value);
    },
    returnToTable() {
      router.push('/plans');
    },
    // eslint-disable-next-line
    goTo({}, id) {
      router.push(`/plans/${id}`);
    },
    async saveTask({ dispatch, state, rootState, commit }, planId) {
      if (planId) {
        // Create the task
        const task = await Plan.createTask({ ...state.task, plan_id: planId });
        // Get the plan
        const plan = await Plan.get(planId);
        // Associate the task
        plan.tasks = [...plan.tasks, task.id];
        // Update the plan
        await Plan.update(plan);
        dispatch('goTo', planId);
        return;
      } else {
        // Create the plan and get id
        const { title, type, stage } = rootState.plans;
        let plan;
        try {
          plan = await Plan.create(title, type, stage);
        } catch(err) {
          dispatch('returnToTable');
          commit('transactions/setErrorMessage', err, { root: true });
          commit('transactions/setShowErrorMessage', true, { root: true });
          return;
        }
        
        // Create the task and get id
        const plan_id = plan.id;
        const task = await Plan.createTask({ ...state.task, plan_id });
        // Add task id to plan's tasks
        if (plan.tasks) {
          plan.tasks = [...plan.tasks, task.id];
        } else {
          plan.tasks = [task.id];
        }
        // Update the plan
        await Plan.update(plan);
      }
      dispatch('returnToTable');
    },
    async patchTask({ dispatch, state }, id) {
      await Plan.updateTask(id, { ...state.task });
      const { plan_id } = state.action;
      if (plan_id) {
        dispatch('goTo', plan_id);
        return;
      }
      dispatch('returnToTable');
    },
    async getTransactionType({ commit }, type) {
      const templates = await TransactionTypes.getTemplates(1, type);
      commit(
        'setKCD',
        templates.find(t => t.name === 'Key Contract Dates').template_fields
      );
    },
    setEditing({ commit }, isEditing) {
      commit('setEditing', isEditing);
    },
    /**
     * @returns {Array} The list of template folders
     * Obtains a list of all template folders.
     */
    async getTemplateFolders({ dispatch }) {
      try {
        const response = await Plan.getTemplateFolders();
        if (response && response.data) return response.data;
      } catch (error) {
        return dispatch('setTemplatesError', error);
      }
    },
    /**
     * @param {Object} templateFolder, The desired template folder to search on
     * @returns {Array} the templates in that folder
     * Calls the feathers backend to obtain the templates based on the current desiredFolder
     */
    async getTemplatesInFolder({ dispatch }, { templateFolder, type }) {
      try {
        const response = await Plan.getTemplates(type, templateFolder.id, '');
        return response.data;
      } catch (error) {
        return dispatch('setTemplatesError', error);
      }
    },
    /**
     * Function to filter the templates by search string and add assign
     * the desired templates
     *
     * @param {String} searchValue, The desired string to be searched with
     */
    async searchTemplates({ commit, dispatch }, { searchValue, type }) {
      commit('templatesInProgress');
      try {
        // const response = await feathers.service('templates').find({ query });
        const response = await Plan.getTemplates(type, '', searchValue);
        commit('setTemplates', response.data);
      } catch (error) {
        dispatch('setTemplatesError', error);
      }
    },
    /**
     * Function to create a new template folder
     *
     * @param {Object} payload, The input parameters to this function
     * @param {String} payload.name, The desired name of the new template folder
     * @param {String} payload.description, The desired description of the new template folder
     * @returns {Error} The error returns
     */
    async createTemplateFolder({ dispatch }, { name, description }) {
      try {
        await dispatch('planTasks/checkDuplicateFolder', name);
      } catch (error) {
        dispatch('setTemplatesError', error);
        return error;
      }
      const query = { name };
      if (description) query.description = description;
      try {
        const response = await Plan.createTemplateFolder(query);
        if (response && response.id) {
          dispatch(
            'planTasks/setTemplatesSuccess',
            `Successfully created template folder ${response.name}`
          );
          return null;
        }
      } catch (error) {
        dispatch('setTemplatesError', error);
        return error;
      }
    },
    /*
     * Sets the Error state
     */
    async setTemplatesError({ commit }, payload) {
      await commit('clearErrors');
      await commit('clearSuccess');
      commit('templatesError', payload);
    },

    /**
     * @param {String} [description] The description that we want to check the duplicate of
     * @returns {Error} Returns an error if one is thrown, otherwise
     *
     * checkDuplicateTemplate checks whether a template of the same name / description exists.
     * Since we're ensuring uniqueness on description, we have to make sure one doesn't exist yet.
     */
    // eslint-disable-next-line
    async checkDuplicateTemplate({}, description) {
      let response;
      try {
        response = await Plan.getTemplates('', '', '', description);
      } catch (error) {
        throw error;
      }
      // If there is already a data with the intended description, construct own error object
      if (response.total > 0) {
        throw new Error(
          `A template with the name ${description} already exists!`
        );
      }
    },
    /*
     * Updates a template with the current data the user inputs
     */
    async updateTemplate({ commit, dispatch }, payload) {
      commit('clearErrors');
      commit('clearSuccess');

      // Destructure the payload and assign necessary variables
      const {
        id,
        template_folder_id: templateFolderId,
        body,
        subject,
        file_id,
      } = payload;
      const requiredParams = [
        'id',
        'template_folder_id',
        'body',
        'description',
      ];
      checkRequiredFields(requiredParams, payload, commit);

      const description = payload.description || subject;
      // If the desired desceription is changed, check it's validity
      if (description !== payload.oldDescription) {
        // Check if a template with the same name already exists
        try {
          await dispatch('checkDuplicateTemplate', description);
        } catch (error) {
          throw error;
        }
      }

      // Call the /templates endpoint to PATCH a template.
      let response;
      try {
        response = await Plan.patchTemplate(id, {
          template_folder_id: templateFolderId,
          subject,
          description,
          body,
          file_id,
        });
        return response;
      } catch (error) {
        // commit('setActionError', error);
      }
    },

    /**
     * Creates the template
     *
     * @param {Number} payload Contains template_folder_id, body, and description
     */
    async createTemplate({ commit, dispatch }, payload) {
      commit('clearErrors');
      commit('clearSuccess');

      const requiredParams = ['template_folder_id', 'body', 'description'];
      checkRequiredFields(requiredParams, payload, commit);

      const description = payload.description || payload.subject;
      // Check if a template with the same name already exists
      try {
        await dispatch('checkDuplicateTemplate', description);
      } catch (error) {
        throw error;
      }

      let response;
      try {
        response = await Plan.createTemplate(payload);
        // return response for the front end
        return response;
      } catch (error) {
        // commit('setActionError', error);
      }
    },
    setFiles({ commit }, files) {
      if (files && Array.isArray(files)) {
        commit('setFiles', files);
      }
    },
    // eslint-disable-next-line
    async getAccessToken({}) {
      const token = await Plan.getAccessToken();
      return token;
    },
    async getRoles({ commit }) {
      const roles = await Contacts.getRoles();
      commit('setRoles', roles);
    },
    async getAction({ commit }, { planId, taskId }) {
      const tasks = await Plan.getTasks(planId);
      const action = tasks.find(t => t.id === +taskId);
      commit('setAction', action);
    },
    async findAllTeamMembers({ commit }) {
      const teamMembers =  await Teams.findTeamMembers();
      commit('setTeamMembers', teamMembers);
    },
  },
};
