<template>
  <v-layout class="tx-tasks" column>
    <TitleBar style="padding-left: 28px;">Transaction Plan Actions</TitleBar>
    <v-select
      class="plan-select"
      outline
      :items="planOptions"
      v-model="selectedPlan"
    />
    <TxDataTable
      :headers="headers"
      :items="items"
      :isLoading="isLoading"
      :pagination="pagination"
      :handlePagination="handlePagination"
      taskActions
      :toggleComplete="toggleComplete"
      :deleteTask="deleteTask"
      :deleteCommunication="deleteCommunication"
    />
  </v-layout>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import TitleBar from '@/Core/components/TitleBar';
import TxDataTable from './TxDataTable';
import { PLAN_BY_STAGE_FLAG } from '@/Core/config/constants';

const { mapState: mapViewState } = createNamespacedHelpers('viewTransaction');

export default {
  components: {
    TitleBar,
    TxDataTable,
  },
  props: {
    tasks: {
      type: Array,
      required: true,
    },
    toggleComplete: {
      type: Function,
      required: true,
    },
    deleteTask: {
      type: Function,
      required: true,
    },
    deleteCommunication: {
      type: Function,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    stakeholders: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Type',
          align: 'left',
          sortable: 'true',
        },
        {
          text: 'Due Date',
          align: 'left',
          sortable: 'true',
        },
        {
          text: 'Assignee',
          align: 'left',
          sortable: 'true',
        },
        {
          text: 'Recipient',
          align: 'left',
          sortable: 'true',
        },
        {
          text: 'Description',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Status',
          align: 'left',
          sortable: true,
        },
        {
          text: 'Plan',
          align: 'left',
          sortable: 'false',
        },
      ],
      pagination: {
        page: 1,
        rowsPerPage: 5,
        totalItems: this.tasks.length,
        sortBy: '',
        descending: true,
      },
      selectedPlan: null,
    };
  },
  computed: {
    items() {
      return this.tasks
        .map(task => {
          switch (task.type) {
            case 'task':
              return this.handleTasks(task);
            case 'email':
              return this.handleCommunications(task);
            case 'sms':
              return this.handleCommunications(task);
            default:
              return null;
          }
        })
        .filter(task => {
          return this.selectedPlan
            ? task.plan.planId === this.selectedPlan
            : true;
        })
        .slice(
          (this.pagination.page - 1) * this.pagination.rowsPerPage,
          this.pagination.page * this.pagination.rowsPerPage
        );
    },
    ...mapViewState(['plans']),
    planOptions() {
      return [
        { text: 'All', value: null },
        ...this.plans
          .map(plan => {
            return { text: plan.title, value: plan.id };
          })
          .sort((a, b) => a.value - b.value),
      ];
    },
  },
  methods: {
    handleTasks(task) {
      let date;
      let taskAssignee;
      if (task.assigned_user) {
        taskAssignee = `${task.assigned_user.first_name} ${task.assigned_user.last_name}`;
      } else {
        taskAssignee = 'Me';
      }
      if (task.due_at) {
        const d = new Date(task.due_at);
        const month = d.toLocaleString('en-us', { month: 'long' });
        const day = d.getDate();
        const year = d.getFullYear();
        date = `${month} ${day}, ${year}`;
      }
      const planTitle = task.plan ? task.plan.plan_title : '';
      const taskPlanId = task.plan ? task.plan.plan_id : null;

      return {
        id: task.id,
        type: {
          key: 'Type',
          text: 'Task',
        },
        due: {
          key: 'Due Date',
          text: date,
        },
        assignee: {
          key: 'Assignee',
          text: taskAssignee,
        },
        recipient: {
          key: 'Recipient',
          text: 'N/A',
        },
        description: {
          key: 'Description',
          text: task.description,
        },
        status: {
          key: 'Status',
          text: task.status,
        },
        plan: {
          key: 'Plan',
          text: planTitle,
          planId: taskPlanId,
        },
      };
    },
    handleCommunications(comm) {
      let date;
      const d = new Date(comm.send_at);
      const month = d.toLocaleString('en-us', { month: 'long' });
      const day = d.getDate();
      const year = d.getFullYear();
      date = `${month} ${day}, ${year}`;

      const recipient = this.stakeholders.find(s => s.id === +comm.recipient);
      const planTitle = comm.plan ? comm.plan.plan_title : '';
      const taskPlanId = comm.plan ? comm.plan.plan_id : null;

      return {
        id: comm.id,
        type: {
          key: 'Type',
          text: comm.type === 'sms' ? 'Text' : 'Email',
        },
        due: {
          key: 'Due Date',
          text: date,
        },
        assignee: {
          key: 'Assignee',
          text: 'Me',
        },
        recipient: {
          key: 'Recipient',
          text: `${recipient.first_name} ${recipient.last_name}`,
        },
        description: {
          key: 'Description',
          text: comm.communication_template.description,
        },
        status: {
          key: 'Status',
          text: comm.sent ? 'Sent' : 'Awaiting delivery',
        },
        plan: {
          key: 'Plan',
          text: planTitle,
          planId: taskPlanId,
        },
      };
    },
    async handlePagination(data) {
      this.pagination = data;
      this.pagination.totalItems = this.tasks.length;
    },
  },
};
</script>
<style lang="scss" scoped>
.plan-select {
  width: 180px;
  font-weight: bold;
  font-size: 14px;
}
</style>
