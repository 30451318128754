import mlsStore from '../Mls/store';
import sharedStore from '../Shared/store';

import { HC_PROPS, initHCProps } from './index';

export const mls = {
  ...mlsStore,
};

export const shared = {
  ...sharedStore,
};

export default {
  ...mlsStore,
  ...sharedStore,
};

export { initHCProps, HC_PROPS };
