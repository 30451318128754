<!--
@Author: Kleber Pinel <kleberdasilva>
@Date:   2019-03-19T10:16:40-07:00
@Email:  kleber@hackcapital.com
@Last modified by:   kleberdasilva
@Last modified time: 2019-03-25T15:12:14-07:00
-->

<template lang="pug">
  .wrapper(:class="readyOnly")
    rich-text-editor-toolbar(
      :tools="allowedTools"
      :toggleToolbarItemVisible="toggleToolbarItemVisible"
      @insert-emoji="insertEmoji"
      @insert-ld-content="insertLdContent"
      @insert-url-image="insertImageURL"
      @insert-link-url="insertLinkUrl"
      @edit-html="saveNewSource"
      @exec-command="execCommand"
      :textValue="value"
      :disabled="disabled"
      :toolType="toolType"
    )

    div.richtexteditor(
      placeholder="Insert text here..."
      @input="onUserUpdate"
      @click="computeToolState"
      @keyup="computeToolState"
      @focus="computeToolState"
      @blur="saveCaretData(editor)"
      :contenteditable="!disabled"
      ref="editor"
    )
</template>

<script>
import TitleBar from '@/Core/components/TitleBar';
import RichTextEditorToolbar from './RichTextEditorToolbar';
import RichTextTools from '../../config/rich-text-tools';
import caretPosition from '@/Core/mixins/caret-positioning';

export default {
  components: {
    TitleBar,
    RichTextEditorToolbar,
  },
  props: {
    toolType: {
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    value: {
      default: null,
      type: String,
    },
  },
  mixins: [caretPosition],
  data() {
    return {
      editor: null,
      toggleToolbarItemVisible: false,

      // each editor instance gets its own copy of tools
      tools: RichTextTools.map(tool =>
        Object.assign({ isActive: false }, tool)
      ),
    };
  },
  mounted() {
    this.$refs.editor.innerHTML = this.value;
    this.editor = this.$refs.editor;
  },
  watch: {
    value(newValue) {
      // Only update if non-editable or input is not focused
      if (this.disabled || document.activeElement !== this.$refs.editor) {
        // sync editor with state
        this.$refs.editor.innerHTML = newValue;
      }
      this.saveCaretData(this.editor);
    },
  },
  computed: {
    readyOnly() {
      return this.disabled ? 'read-only' : '';
    },
    allowedTools() {
      return this.tools.filter(
        tool => !this.toolType || tool.typeFor.includes(this.toolType)
      );
    },
  },
  methods: {
    insertInCurrentValue(newTextToBeInserted) {
      this.restoreCaretPosition(this.editor);
      this.execCommand('insertText', newTextToBeInserted);
    },

    computeToolState() {
      if (this.$refs.editor.contains(window.getSelection().focusNode)) {
        const parent = window.getSelection().focusNode.parentElement;
        const style = window.getComputedStyle(parent);
        this.tools.forEach(tool => {
          tool.isActive = tool.getActive(style, parent);
        });
      }
    },

    onUserUpdate(value) {
      if (typeof value === 'object') {
        this.$emit('input', this.editor.innerHTML);
        return;
      }
      this.$emit('input', value);
    },

    saveNewSource(sourceEditing) {
      this.onUserUpdate(sourceEditing);
      this.close();
    },

    insertLdContent(selectedValue) {
      this.insertInCurrentValue(selectedValue);
      this.close();
    },

    insertEmoji(emoji) {
      this.insertInCurrentValue(emoji);
      this.close();
    },

    insertImageURL(imageURL) {
      const image = `<img src='${imageURL}' /><br/>`;
      this.restoreCaretPosition();
      this.execCommand('insertHtml', image);
      this.close();
    },

    insertLinkUrl(linkText, linkValue) {
      if (linkText === '') {
        const link = `<a href='${linkValue}' target="_blank">Link</a>`;
        this.restoreCaretPosition();
        this.execCommand('insertHtml', link);
      } else {
        const link = `<a href='${linkValue}' target="_blank">${linkText}</a>`;
        const newValue = this.value ? this.value.replace(linkText, link) : link;
        this.onUserUpdate(newValue);
      }

      this.close();
    },

    execCommand(command, value) {
      // Set this to run at the end of the execution queue, after caret restore
      window.setTimeout(() => {
        document.execCommand(command, false, value);
      }, 0);
    },

    close() {
      this.toggleToolbarItemVisible = !this.toggleToolbarItemVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
[contenteditable='true']:empty:before {
  content: attr(placeholder);
  display: block; /* For Firefox */
  font-style: italic;
  color: var(--v-background-lighten2);
}
.wrapper {
  border: solid 1px rgba(0, 0, 0, 0.35);
  &.read-only {
    border-color: rgba(0, 0, 0, 0.1);
  }
}
.richtexteditor {
  font-family: sans-serif;
  padding: 8px;
  outline: none;
  white-space: initial;
  overflow: auto;
  min-height: 200px;
}
.richtexteditor /deep/ blockquote {
  margin: 0 0 0 40px; // FireFox indents
}
.editor-tools /deep/ .v-toolbar__content {
  padding: 0 4px;
}
</style>
