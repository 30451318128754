<template lang="pug">
  v-flex(xs12 md4)
    v-layout(row wrap)
      v-flex(xs12)
        div.header.pb-5 Write the Instructions
      v-flex(xs12)
        v-textarea(
          label="Insert the task instructions here"
          v-model="textInstructions"
          rows=20
          box
          no-resize
          @keydown="limit( $event, 'textInstructions', 2000 )"
        )
      v-snackbar(
        v-model="showLimitError"
        color="error"
      )
        span Please limit your instructions to 2000 characters
</template>
<script>
export default {
  data() {
    return {
      textInstructions: this.value,
      showLimitError: false,
    };
  },
  props: {
    /**
     * The task instructions
     */
    value: {
      type: String,
    },
    updateInstructions: {
      type: Function,
    },
  },
  watch: {
    textInstructions() {
      this.$emit('input', this.textInstructions);
      this.updateInstructions(this.textInstructions);
    },
  },
  methods: {
    // Force a limit of 2000 characters and inform the user
    limit(evt, dataProp, limit) {
      const len = this[dataProp].length;
      if (len >= limit) {
        this[dataProp] = this[dataProp].substring(0, len - 1);
        this.showLimitError = true;
        evt.preventDefault();
      }
    },
  },
};
</script>
