<template>
  <div>
    <v-dialog v-model="value" persistent :width="width">
      <v-card class="ld-confirmation">
        <v-card-title>
          <slot name="header" />
        </v-card-title>
        <v-card-text>
          <div class="emphasis">
            <slot name="criticalBody" />
          </div>
          <slot name="body" />
          <v-text-field
            :placeholder='`Type ${parrotText} to confirm`'
            v-model="parrot"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click="emitCancel">{{ cancelText }}</v-btn>
          <v-btn color="error" :disabled="disabled" @click="emitConfirm">{{ confirmText }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    confirmText: String,
    cancelText: String,
    parrotText: String,
    width: String,
  },
  data() {
    return {
      parrot: '',
      disabled: true,
    };
  },
  watch: {
    parrot(value) {
      if (value === this.parrotText) {
        this.disabled = false;
        return;
      }
      this.disabled = true;
    },
  },
  methods: {
    clearParrot() {
      this.parrot = '';
    },
    emitCancel() {
      this.clearParrot();
      this.$emit('click:cancel');
    },
    emitConfirm() {
      this.clearParrot();
      this.$emit('click:ok');
    },
  },
};
</script>

<style scoped>
  .emphasis {
    color: var(--v-error-base);
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 13px;
  }
</style>
