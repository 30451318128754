/**
 * Mark each wizard step as 'done' to enable
 * the edit button when 'Next' is clicked
 */
import transactionWizardSteps from '@/Core/constants/transactionWizardSteps';

const currentStage = {
  handler(value) {
    const index = this.summaryValues.findIndex(item => item.key === value);
    if (index === 0) return;
    if (value === transactionWizardSteps.TRANSACTION_CREATION_LOOP) return;
    if (!this.summaryValues[index - 1].form) {
      this.summaryValues[index - 1].done = true;
      return;
    }
    this.setFormDone(value);
    this.reAssignSummaryValues();
  },
};

// Update the summary as title is being typed
const title = {
  handler(value) {
    this.transactionSetupSummary.data[0] = value;
    this.reAssignSummaryValues();
  },
};

// Update the summary when type is selected
const type = {
  async handler(value) {
    if (!value) return;
    this.transactionSetupSummary.data[1] = `Type - ${this.typeName}`;
    await this.getStages(value);
    this.stageOptions = this.selectFormat(
      [...this.stages].sort((a, b) => a.level - b.level)
    );
    this.reAssignSummaryValues();
  },
};

const templates = {
  async handler(value) {
    if (!value.length) return;
    // method exists in create transaction only
    if (this.setFormSummaries) {
      this.setFormSummaries({
          transaction_forms: value,
      });
    }
  }
};

const formSummaries = {
  async handler(value) {
    if (!value.length) return;
    this.reAssignSummaryValues();
  },
};

// Update the summary when stage is selected
const stage = {
  handler(value) {
    if (!value) return;
    this.transactionSetupSummary.data[2] = `Stage - ${this.stageName}`;
    this.reAssignSummaryValues();
  },
};

const property = {
  handler(value) {
    if (!value) return;
    const info = value._source;
    this.transactionPropertySummary.data[0] = `MLS - ${info.ListingId}`;
    this.transactionPropertySummary.data[1] = `${info.UnparsedAddress}, ${
      info.City
    }, ${info.StateOrProvince}`;
    this.transactionPropertySummary.data[2] = this.formatCurrency(
      info.ListPrice
    );
    this.reAssignSummaryValues();
  },
};

// Update the summary contact list when contact selected
const selectedContacts = {
  handler() {
    this.transactionContactsSummary.data = this.allContacts;
    this.selectedContactsOptions = this.selectFormat(this.allContacts, true);
  },
};

// Update the summary contact list when new contact created
const newContacts = {
  handler() {
    this.transactionContactsSummary.data = this.allContacts;
    this.selectedContactsOptions = this.selectFormat(this.allContacts, true);
  },
};

// Disable the next button if title, type, and stage invalid
const setupValid = {
  handler(value) {
    this.nextDisabled = value;
  },
};

export default {
  currentStage,
  title,
  type,
  templates,
  stage,
  property,
  selectedContacts,
  newContacts,
  setupValid,
  formSummaries,
};
