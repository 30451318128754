<template>
  <v-toolbar-items class="ml-2 hidden-sm-and-down">
    <v-menu offset-y left min-width="200">
      <v-btn slot="activator" flat="flat"
        ><span class="user">{{ username }}</span>
        <v-icon small="small">arrow_drop_down</v-icon>
        <v-avatar class="ml-1" :color="userInitials | generateRandomColor('dark')" size="38px"
          ><span class="white--text subheading">{{ userInitials }}</span></v-avatar
        >
      </v-btn>
      <v-list>
        <v-list-tile :href="profileUrl">
          <v-list-tile-title>Profile</v-list-tile-title>
        </v-list-tile>
        <v-list-tile :href="settingsUrl">
          <v-list-tile-title>Settings</v-list-tile-title>
        </v-list-tile>
        <v-list-tile v-if="showPaymentsSettings" :href="paymentsUrl">
          <v-list-tile-title>Payments & Subscription</v-list-tile-title>
        </v-list-tile>
        <v-list-tile @click="$emit('logout')">
          <v-list-tile-title>Logout</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-menu>
  </v-toolbar-items>
</template>

<script>
import generateRandomColor from 'lion-vue-src/utilities/randomColorGenerator';

export default {
  props: {
    items: Array,
    username: String,
    userInitials: String,
    profileUrl: String,
    settingsUrl: String,
    paymentsUrl: String,
    showPaymentsSettings: Boolean,
  },
  filters: {
    generateRandomColor,
  },
};
</script>

<style lang="scss" scoped>
.user {
  font-weight: bold;
  font-size: 16px;
  text-transform: capitalize;
}

.v-menu__activator {
  z-index: 1;

  button {
    z-index: -1;
  }
}
</style>
