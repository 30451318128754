/**
 * How It Works
 */
import axios from 'axios';

export default {
  namespaced: true,
  state: {
    videos: [],
    error: null,
    isLoading: false,
  },
  mutations: {
    setHowItWorksVideos(state, videoList) {
      state.videos = [...videoList];
    },
    setHowItWorksVideosError(state, error) {
      state.error = error;
    },
    setHowitWorksVideosLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  actions: {
    async fetchHowItWorksVideos({ commit }) {
      commit('setHowitWorksVideosLoading', true);
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_COMMUNICATIONS_API_BASE_URL}/how-to-videos`,
        );
        commit('setHowItWorksVideos', response.data);
      } catch (error) {
        commit('setHowItWorksVideosError', error.data);
      } finally {
        commit('setHowitWorksVideosLoading', false);
      }
    },
  },
};
